import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  groupes: state.screensHospData.groupes,
});

export default connect(
  mapStateToProps,
  null,
)(
  forwardRef(function UfsSelector(
    { ufs, groups, onSelect, isSearchable = false, isMulti = false, groupes, ...props },
    ref,
  ) {
    const { t } = useTranslation("cockpit");

    const ufOptions = useMemo(
      () =>
        Object.fromEntries(
          Object.entries(groupes)
            .flatMap(function ([groupId, { data }]) {
              if (groups.length > 0 && !groups.includes(groupId)) {
                return [];
              } else {
                return data.map(([id, name]) => ({
                  value: id,
                  label: `UF ${id} - ${name}`,
                }));
              }
            })
            .map((option) => [option.value, option]),
        ),
      [groupes, groups],
    );

    return (
      <Select
        ref={ref}
        value={ufs.map((value) => ufOptions[value])}
        className="selectJ1"
        isSearchable={isSearchable}
        isMulti={isMulti}
        styles={{ fontSize: 20 }}
        placeholder={t("uf")}
        onChange={onSelect}
        options={Object.values(ufOptions)}
      />
    );
  }),
);
