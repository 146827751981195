import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import AddType from "./addType";
import axios from "axios";
import { API } from "../../../utils/api";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";

const ListTypes = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listtype");
  const [globalTypesData, setGlobalTypesData] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [denomination, setDenomination] = useState("");
  const [duration, setDuration] = useState("");
  const [color, setColor] = useState("#fff");
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLTYPES}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalTypesData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  const addType = (type) => {
    let data = [...globalTypesData, type];
    setGlobalTypesData(data);
  };
  const confirmationDeleteType = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this type?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteType(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteType = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETETYPE}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let types = globalTypesData.filter((t) => t.index !== id);
        setShowMessage(true);
        setMessage({ edit: "the type was deleted successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalTypesData(types);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
      });
  };
  const onSave = (type) => {
    let newType = {
      index: type.index,
      denomination: denomination === "" ? type.denomination : denomination,
      duree: duration === "" || !duration.toString().match(/^[1-9][0-9]?$/) ? type.duree : duration,
      couleur: color,
    };

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_TYPE}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: newType,
    })
      .then((response) => {
        let types = {};
        types = globalTypesData.map((t) => (t.index === type.index ? newType : t));
        setShowMessage(true);
        setMessage({ edit: "the type has been successfully modified" });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalTypesData(types);
        onCancel();
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          setError(errors);
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const onEdit = (type) => {
    setInEditMode({
      status: true,
      rowKey: type.index,
    });
    setDenomination(type.denomination);
    setColor(type.couleur);
    setDuration(type.duree);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setDenomination("");
    setColor("#fff");
    setDuration("");
    setIsDisable(false);
  };

  const renderList = () => {
    if (!globalTypesData) return null;

    return globalTypesData.map((type, index) => (
      <tr key={index}>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === type.index ? (
            <input
              value={denomination}
              required
              className="form-control"
              onChange={(event) => setDenomination(event.target.value)}
            />
          ) : (
            type.denomination
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === type.index ? (
            <input
              value={duration}
              required
              className="form-control"
              onChange={(event) => setDuration(event.target.value)}
            />
          ) : (
            type.duree + t("j")
          )}
        </td>
        <td
          className={`${inEditMode.status && inEditMode.rowKey === type.index ? "" : "padding-0"} `}
          bgcolor={inEditMode.status && inEditMode.rowKey === type.index ? "" : type.couleur}
        >
          {inEditMode.status && inEditMode.rowKey === type.index ? (
            <div className="color-picker">
              <div
                className="d-flex justify-content-between w-100"
                style={showColorPicker ? { marginBottom: 10 } : {}}
              >
                <span className="bg-color-picker" style={{ backgroundColor: color }}></span>
                <button
                  type="button"
                  className="btn-color-picker"
                  onClick={() => setshowColorPicker((showColorPicker) => !showColorPicker)}
                >
                  {showColorPicker ? t("apply the choice") : t("pick up a color")}
                </button>
              </div>
              {showColorPicker && (
                <ChromePicker
                  color={color}
                  style={{ marginTop: 5 }}
                  onChange={(updateColor) => setColor(updateColor.hex)}
                />
              )}
            </div>
          ) : (
            <div style={{ backgroundColor: `${type.couleur}` }}></div>
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === type.index ? (
            <>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onSave(type)}>
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEdit(type)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => confirmationDeleteType(type.index)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };
  if (page === "listtype") {
    return (
      <div className="row">
        <div className="container-user">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end flex-col"
              style={{ textAlign: "end", marginTop: 20 }}
            >
              <a
                onClick={() => setPage("addtype")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("add type")}
              </a>
            </div>
          </div>
          <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
          <div className="overflow" style={{ marginTop: 10 }}>
            {load && globalTypesData ? (
              <table
                className="table table-translation table-striped table-bordered table-scroll"
                style={{ width: "100%" }}
              >
                <thead className="fix-header">
                  <tr>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("denomination")}</span>
                    </th>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("default duration")}</span>
                    </th>
                    <th className="header" style={{ width: "11%" }}>
                      <span style={{ display: "block" }}> {t("colour")}</span>
                    </th>
                    <th className="header" style={{ width: "8%" }}>
                      <span style={{ display: "block" }}>{t("action")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderList()}</tbody>
              </table>
            ) : (
              <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <AddType addType={addType} setPage={setPage} />;
  }
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListTypes);
