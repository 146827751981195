import { LOG_T } from "../actions/mlcLog/actions";

const initialState = { loaded: false, log: null };
export default function reducerMlcLog(state = initialState, action) {
  switch (action.type) {
    case LOG_T.ADD:
      return { loaded: true, log: action.payload };

    case "RESET":
      return initialState;

    default:
      return state;
  }
  return initialState;
}
