export const USER_T = {
  READ: "READ_USER",
  READ_ALL: "READ_ALL_USER",
  DELETE: "DELETE_USER",
  CREATE: "CREATE_USER",
  LOGIN: "LOGIN_USER",
  CHANGEPWD: "CHANGE_PWD_USER",
  UPDATED: "UPDATED_USER",
  EDIT: "EDIT_USER",
  SETERROR: "SET_ERROR_SOCKET",
  LOGOUT: "LOGOUT_USER",
};
