import React, { useState, useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../../utils/api";
import { ChromePicker } from "react-color";
import Select from "react-select";
import { connect } from "react-redux";

const NewPost = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [denomination, setDenomination] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [color, setColor] = useState("#fff");
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [duration, setDuration] = useState();
  const [daysChecked, setDaysChecked] = useState([]);
  const [message, setMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState(null);
  const [load, setLoad] = useState(false);
  const [optionCategories, setOptionCategories] = useState(props.categories);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({
    value: 0,
    label: t("all categories").toUpperCase(),
  });
  const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  const addPost = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);

    if (selectedCategoryOption.value === 0) {
      setErrorBorder(["choiceCategory"]);
      setShowError(true);
      setError({ function: "please select a category" });
      return;
    }

    let post = {
      denomination: denomination,
      abreviation: abbreviation,
      couleur: color,
      duree: duration,
      category_id: selectedCategoryOption.value,
    };
    let availablity = {
      monday: daysChecked.includes("monday") ? 1 : 0,
      tuesday: daysChecked.includes("tuesday") ? 1 : 0,
      wednesday: daysChecked.includes("wednesday") ? 1 : 0,
      thursday: daysChecked.includes("thursday") ? 1 : 0,
      friday: daysChecked.includes("friday") ? 1 : 0,
      saturday: daysChecked.includes("saturday") ? 1 : 0,
      sunday: daysChecked.includes("sunday") ? 1 : 0,
      member_id: null,
      post_id: null,
    };
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_POST}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },

      data: { post, availablity },
    })
      .then((response) => {
        setMessage(true);
        setDenomination("");
        setColor("#fff");
        setAbbreviation("");
        setTimeout(() => setMessage(false), 3000);
        props.addPost(response.data);
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          setError(errors);
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const handleCheckBox = (e) => {
    let days;
    if (e.target.checked) {
      days = [...daysChecked, e.target.id];
    } else {
      days = daysChecked.filter((day) => day !== e.target.id);
    }
    if (days.length == 0) {
      days = [daysOfWeek[0]];
    }

    setDaysChecked(days);
  };
  return (
    <div className="row" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("add post")}</h1>
        <div className="row">
          <div className="col-10 offset-1">
            <form onSubmit={addPost} className="form-user-new">
              <div style={{ textAlign: "center", height: "50px" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => (
                    <div className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the post was successfully saved.")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <Select
                  value={selectedCategoryOption}
                  className={`selectJ1 form-select ${
                    showError && errorBorder && errorBorder.includes("choiceCategory")
                      ? "error"
                      : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedCategoryOption) =>
                    setSelectedCategoryOption(selectedCategoryOption)
                  }
                  options={optionCategories}
                />
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  required
                  value={denomination}
                  onChange={(e) => setDenomination(e.target.value)}
                  id="denomination"
                  className="fadeIn form-control"
                  name="_denomination"
                  placeholder={t("denomination")}
                />
              </div>
              <div className="form-group ">
                <div className="color-picker">
                  <div
                    className="d-flex justify-content-between w-100"
                    style={showColorPicker ? { marginBottom: 10 } : {}}
                  >
                    <span className="bg-color-picker" style={{ backgroundColor: color }}></span>
                    <button
                      type="button"
                      className="btn-color-picker"
                      onClick={() => setshowColorPicker((showColorPicker) => !showColorPicker)}
                    >
                      {showColorPicker ? t("apply the choice") : t("pick up a color")}
                    </button>
                  </div>
                  {showColorPicker && (
                    <ChromePicker
                      color={color}
                      style={{ marginTop: 5 }}
                      onChange={(updateColor) => setColor(updateColor.hex)}
                    />
                  )}
                </div>
              </div>

              <div className="form-group ">
                <input
                  type="text"
                  value={abbreviation}
                  onChange={(e) => setAbbreviation(e.target.value)}
                  id="duree"
                  className={`fadeIn form-control `}
                  name="_abbreviation"
                  placeholder={t("abbreviation")}
                />
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  required
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  id="duration "
                  className="fadeIn form-control"
                  name="_duration "
                  placeholder={t("duration") + " ( " + t("in hour") + " )"}
                />
              </div>
              <div className="form-group ">
                <div className="cat-custom">
                  <p className="title-modules">{t("categories")}:</p>

                  <div className="custom-control d-flex custom-checkbox">
                    {daysOfWeek.map((day) => {
                      return (
                        <div
                          className="d-flex justify-content-center"
                          style={{ width: "40%", flexWrap: "wrap" }}
                        >
                          <input
                            onChange={handleCheckBox}
                            type="checkbox"
                            checked={daysChecked.includes(day) ? true : false}
                            className="custom-control-input"
                            id={day}
                          />
                          <label
                            style={{ fontSize: 16 }}
                            className="custom-control-label"
                            htmlFor={day}
                          >
                            {t(day.charAt(0))}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  onClick={() => props.setPage("listpost")}
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  // disabled={ (denomination !=="" )  ? false : true }
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(NewPost);
