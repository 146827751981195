import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import { ImCheckmark, ImCross } from "react-icons/im";
import moment from "moment/min/moment-with-locales";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";

const ScreenValidation = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [planningData, setPlanningData] = useState([]);
  const [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [Data, setData] = useState(props.validations);

  useEffect(() => {
    let plannings = [];
    props.validations.map((validation, i) => {
      validation.Planning = validation.Planning.filter((p) => p.status === "in_progress");
      plannings[i] = validation;
    });

    setPlanningData(plannings);
  }, []);

  const confirmationCancelPlanning = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("Are you sure you want to cancel this planning ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deletePlanning(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const validPlanning = (planning) => {
    let validPlan = {
      index: planning.index,
      status: "validé",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: validPlan,
    })
      .then((response) => {
        let _plan = response.data;

        let plannings = [];
        planningData.map((plan, i) => {
          if (plan.index === planning.member_id) {
            plan.Planning = plan.Planning.filter((p) => p.index !== _plan.index);
          }
          plannings[i] = plan;
        });

        setShowMessage(true);
        setMessage({ edit: "the planning was successfully validated." });
        setTimeout(() => setShowMessage(false), 3000);

        props.editPlanning(_plan);
        setPlanningData(plannings);
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const deletePlanning = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETEPLANNING}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let _plan = res.data;
        let plannings = [];
        planningData.map((planning, i) => {
          if (planning.index === _plan.member_id) {
            planning.Planning = planning.Planning.filter((p) => p.index !== _plan.index);
          }
          plannings[i] = planning;
        });
        setShowMessage(true);
        setMessage({ edit: "the planning was successfully canceled." });
        setTimeout(() => setShowMessage(false), 3000);
        props.deletePlanning(res.data);
        setLoad(false);
        setPlanningData(plannings);
      })
      .catch((e) => {
        setLoad(false);
        //    console.log(e)
      });
  };

  const renderList = () => {
    if (!planningData) return null;

    return planningData.map((planning) => {
      return (
        planning.Planning &&
        planning.Planning.length > 0 &&
        planning.Planning.map((p, i) => (
          <tr key={i}>
            <td>{planning.nom}</td>
            <td>{planning.prenom}</td>
            <td>{planning.Functions && planning.Functions.name}</td>
            <td>{p.Types.denomination}</td>
            <td>{moment(p.startDate).format("YYYY-MM-DD")}</td>
            <td>{moment(p.endDate).format("YYYY-MM-DD")}</td>
            <td>
              <a className="icon-size" onClick={() => validPlanning(p)}>
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a onClick={() => confirmationCancelPlanning(p.index)} className="btn icon-size">
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </td>
          </tr>
        ))
      );
    });
  };

  return (
    <div className="row" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title">{t("validation of wishes")}</h1>
        <div
          className="message"
          style={{
            marginTop: 20,
            marginBottom: 20,
            height: "50px",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showError &&
            error &&
            Object.entries(error).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          <table
            className="table table-translation table-striped table-bordered table-scroll"
            style={{ width: "100%" }}
          >
            <thead className="fix-header">
              <tr>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("name")}</span>
                </th>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("first name")}</span>
                </th>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("function")}</span>
                </th>
                <th className="header" style={{ width: "8%" }}>
                  <span style={{ display: "block" }}>{t("wish")}</span>
                </th>
                <th className="header" style={{ width: "11%" }}>
                  <span style={{ display: "block" }}>{t("from")}</span>
                </th>
                <th className="header" style={{ width: "11%" }}>
                  <span style={{ display: "block" }}>{t("to")}</span>
                </th>
                <th className="header" style={{ width: "8%" }}>
                  <span style={{ display: "block" }}>{t("action")}</span>
                </th>
              </tr>
            </thead>
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenValidation);
