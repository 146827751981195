import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import AddDesiderata from "./AddDesiderata";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import moment from "moment/min/moment-with-locales";
import Select from "react-select";
import { Capitalize } from "../../../utils/functions";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import ReactLoading from "react-loading";

const ListDesideratas = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listDesideratas");
  const [myDesideratas, setMyDesideratas] = useState([]);
  const [load, setLoad] = useState(false);
  const [isLength, setisLength] = useState(false);
  const [optionsShift, setOptionsShift] = useState([
    { value: "day", key: "day", label: t("day") },
    { value: "night", key: "night", label: t("night") },
  ]);
  const [selectedOptionStartShift, setOptionSelectedStartShift] = useState({
    value: 0,
    key: "start shift",
    label: t("start shift"),
  });
  const [selectedOptionEndShift, setOptionSelectedEndShift] = useState({
    value: 0,
    key: "end shift",
    label: t("end shift"),
  });
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [isDisable, setIsDisable] = useState(false);
  const [optionsType, setOptionsType] = useState([]);
  const [optionsDoctors, setOptionsDoctors] = useState([]);
  const [selectedOptionType, setOptionSelectedType] = useState({
    value: 0,
    label: t("types"),
  });
  const [selectedOptionDoctors, setOptionSelectedDoctors] = useState({
    value: 0,
    label: "doctors",
  });
  const [days, setDays] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorBorder, setErrorBorder] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const member_id = props.member_id;

  useEffect(() => {
    let page = "listDesideratas";
    setPage(page);
    let startMonth = moment().startOf("month").format("YYYY-MM-DD");
    let startYear = moment().startOf("year").format("YYYY-MM-DD");
    let endYear = moment().endOf("year").format("YYYY-MM-DD");
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLMYDESIDERATAS}`,
        {
          startDateMonth: startMonth,
          startDateYear: startYear,
          endDateYear: endYear,
          index: selectedOptionDoctors.value,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        if (res.data.Myplannings && res.data.Myplannings.length === 0) {
          setisLength(false);
          setMyDesideratas(res.data.Myplannings);
          setDays(res.data.Days);
        } else {
          setMyDesideratas(res.data.Myplannings);
          setDays(res.data.Days);
          setisLength(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [selectedOptionDoctors]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLTYPES}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        loadingOption(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLMEMBERS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        loadingMembers(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const loadingMembers = (data) => {
    let optionsDoctors = [];

    setOptionSelectedDoctors({ value: data[0].index, label: data[0].nom + " " + data[0].prenom });
    data.map((doctor) => {
      optionsDoctors = [
        ...optionsDoctors,
        { value: doctor.index, label: doctor.nom + " " + doctor.prenom },
      ];
    });
    setOptionsDoctors(optionsDoctors);
  };

  const loadingOption = (data) => {
    let optionsTypes = [{ value: 0, label: t("types") }];
    data.map((type) => {
      optionsTypes = [...optionsTypes, { value: type.index, label: type.denomination }];
    });
    setOptionsType(optionsTypes);
  };

  const onEdit = (desiderata) => {
    setInEditMode({
      status: true,
      rowKey: desiderata.index,
    });
    desiderata.Types &&
      setOptionSelectedType({
        value: desiderata.Types.index,
        label: desiderata.Types.denomination,
      });
    desiderata.startShift === null || desiderata.startShift === ""
      ? setOptionSelectedStartShift({
          value: 0,
          label: t("start shift"),
        })
      : setOptionSelectedStartShift({
          value: desiderata.startShift,
          label: desiderata.startShift,
        });
    desiderata.endShift === null || desiderata.endShift === ""
      ? setOptionSelectedEndShift({
          value: 0,
          label: t("end shift"),
        })
      : setOptionSelectedEndShift({
          value: desiderata.endShift,
          label: desiderata.endShift,
        });
    desiderata.startDate && setStartDate(new Date(desiderata.startDate));
    desiderata.endDate && setEndDate(new Date(desiderata.endDate));
  };

  const updatedesiderata = (desiderata) => {
    setShowError(false);

    if (selectedOptionStartShift.value === 0) {
      setErrorBorder(["choiceStartShift"]);
      setShowError(true);
      setError({ type: "please select a Shift day/night" });
      return;
    }
    if (selectedOptionEndShift.value === 0) {
      setErrorBorder(["choiceEndShift"]);
      setShowError(true);
      setError({ type: "please select a Shift day/night" });
      return;
    }
    setLoad(true);

    let newDesiderata = {
      index: desiderata.index,
      startDate:
        startDate === "" || startDate === null
          ? desiderata.startDate
          : selectedOptionStartShift.value === "day"
          ? moment(startDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(startDate).set("hour", 20).format("YYYY-MM-DD HH:mm"),
      endDate:
        endDate === "" || endDate === null
          ? desiderata.endDate
          : selectedOptionEndShift.value === "day"
          ? moment(endDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(endDate).set("hour", 20).format("YYYY-MM-DD HH:mm"),
      description: desiderata.description,
      type_id: selectedOptionType.value == 0 ? null : selectedOptionType.value,
      member_id: desiderata.member_id,
      status: desiderata.status,
      startShift: selectedOptionStartShift.value,
      endShift: selectedOptionEndShift.value,
    };

    let newMyDesideratas = [];
    newMyDesideratas = myDesideratas.filter((plan) => plan.index !== newDesiderata.index);
    let exist = newMyDesideratas.some(
      (plan, i) =>
        moment(newDesiderata.startDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        moment(newDesiderata.endDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        (moment(plan.startDate).isSameOrAfter(
          moment(newDesiderata.startDate).format("YYYY-MM-DD"),
        ) &&
          moment(plan.endDate).isSameOrAfter(
            moment(newDesiderata.startDate).format("YYYY-MM-DD"),
          ) &&
          moment(plan.startDate).isSameOrBefore(
            moment(newDesiderata.endDate).format("YYYY-MM-DD"),
          ) &&
          moment(plan.endDate).isSameOrBefore(moment(newDesiderata.endDate).format("YYYY-MM-DD"))),
    );
    if (exist) {
      setShowError(true);
      setError({
        date: "the date you have selected is already booked, please enter another one",
      });
      setTimeout(() => setError(false), 4000);
      return;
    }
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_PLANNING}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: newDesiderata,
    })
      .then((response) => {
        let desideratas = [];

        myDesideratas.map((desiderata) => {
          if (desiderata.index === newDesiderata.index) {
            desideratas = [...desideratas, response.data];
            return;
          }
          desideratas = [...desideratas, desiderata];
        });
        setMyDesideratas(desideratas);
        setShowMessage(true);
        setMessage({ edit: "the desiderata has been successfully modified" });
        setTimeout(() => setShowMessage(false), 3000);
        onCancel();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            setError(errors);
          } else if (error.response.status === "401") {
            setError({ date: "impossible to modify this desiderata" });
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setEndDate(null);
    setStartDate(null);
    setOptionSelectedType(optionsType[0]);
  };
  const addDesiderata = (desiderata) => {
    let desideratas = [...myDesideratas, desiderata];
    setMyDesideratas(desideratas);
  };

  const renderList = () => {
    if (!myDesideratas) return null;
    if (myDesideratas && myDesideratas.length === 0)
      return (
        <tr>
          <td colspan="5">{t("no data available")}</td>
        </tr>
      );

    return myDesideratas.map((desiderata, index) => (
      <tr key={index} style={{ backgroundColor: "whitesmoke" }}>
        <td className="text-capitalize txt-center-middle">
          {inEditMode.status && inEditMode.rowKey === desiderata.index ? (
            <Select
              value={selectedOptionType}
              className="selectJ1 form-select"
              isSearchable={false}
              onChange={(selectedOptionType) => setOptionSelectedType(selectedOptionType)}
              options={optionsType}
            />
          ) : desiderata.Types ? (
            Capitalize(desiderata.Types.denomination)
          ) : (
            "-"
          )}
        </td>
        <td className="text-capitalize txt-center-middle">
          {inEditMode.status && inEditMode.rowKey === desiderata.index ? (
            <>
              <DatePicker
                style={{ borderColor: "" }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isClearable
                placeholderText={t("from")}
                dateFormat="dd/MM/yyyy"
                maxDate={endDate ? endDate : new Date(moment().subtract(1, "day"))}
              />
              <Select
                value={selectedOptionStartShift}
                className={`selectJ1 form-select ${
                  showError && errorBorder.includes("choiceStartShift") ? "error" : ""
                }`}
                isSearchable={false}
                onChange={(selectedOptionStartShift) =>
                  setOptionSelectedStartShift(selectedOptionStartShift)
                }
                options={optionsShift}
              />
            </>
          ) : desiderata.startDate === "" || desiderata.startDate === null ? (
            "-"
          ) : (
            <>
              {moment(desiderata.startDate).format("YYYY-MM-DD")}
              <br />
              {desiderata.startShift === null || desiderata.startShift === ""
                ? "-"
                : desiderata.startShift}
            </>
          )}
        </td>
        <td className="text-capitalize txt-center-middle">
          {inEditMode.status && inEditMode.rowKey === desiderata.index ? (
            <>
              <DatePicker
                style={{ borderColor: "" }}
                selected={endDate}
                isClearable
                placeholderText={t("to")}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
              />
              <Select
                value={selectedOptionEndShift}
                className={`selectJ1 form-select ${
                  showError && errorBorder.includes("choiceEndShift") ? "error" : ""
                }`}
                isSearchable={false}
                onChange={(selectedOptionEndShift) =>
                  setOptionSelectedEndShift(selectedOptionEndShift)
                }
                options={optionsShift}
              />
            </>
          ) : (
            <>
              {moment(desiderata.endDate).format("YYYY-MM-DD")}
              <br />
              {desiderata.endShift === null || desiderata.endShift === ""
                ? "-"
                : desiderata.endShift}
            </>
          )}
        </td>
        <td
          data-tip
          data-for={`tooltip-desiderata-${desiderata.index}`}
          className="status-plan text-capitalize txt-center-middle"
          bgcolor={
            desiderata.status === "in_progress"
              ? "white"
              : desiderata.status === "validated"
              ? "green"
              : "red"
          }
        >
          {inEditMode.status && inEditMode.rowKey === desiderata.index ? (
            <></>
          ) : (
            t(desiderata.status)
          )}
          {desiderata.status === "canceled" && setDesiderataTooltip(desiderata)}
        </td>
        <td className="txt-center-middle">
          {inEditMode.status && inEditMode.rowKey === desiderata.index ? (
            <>
              <a
                style={{ margin: 5 }}
                className={"icon-size"}
                onClick={() => updatedesiderata(desiderata)}
              >
                <ImCheckmark
                  className="icon-action"
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  className="icon-action"
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              {desiderata.status === "in_progress" && (
                <a
                  onClick={() => onEdit(desiderata)}
                  className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
                >
                  <BsPencilSquare
                    className="icon-action"
                    style={{ color: "", fontSize: 24, cursor: "pointer" }}
                  />
                </a>
              )}
            </>
          )}
        </td>
      </tr>
    ));
  };
  const setDesiderataTooltip = (desiderata) => {
    return (
      <ReactTooltip id={`tooltip-desiderata-${desiderata.index}`} effect="solid">
        <ul className="tooltip-list " style={{ color: "white", zIndex: 9 }}>
          <li style={{ color: "white" }}>
            {`${t("reason for not accepting desiderata")} : ${
              desiderata.cause === "" || desiderata.cause === null
                ? t("no reason")
                : desiderata.cause
            }`}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  if (page == "listDesideratas") {
    return (
      <div className="row">
        <div className="container-user">
          <div className="row">
            <div
              className="desiderataHeader"
              style={{ textAlign: "end", marginTop: 20, padding: 0 }}
            >
              <div className="d-flex border-primary box-vacation" style={{ marginBottom: 20 }}>
                <span className="text-left" style={{ backgroundColor: "#dde9f5", color: "black" }}>
                  {t("vacation days validated during this year")}
                </span>
                <span
                  className="text-center"
                  style={{ backgroundColor: "#c1d0f1", color: "black" }}
                >
                  {days} {days > 1 ? t("days") : t("day")}
                </span>
              </div>
              <Select
                value={selectedOptionDoctors}
                className="selectJ1 form-select medcinSelect"
                isSearchable={false}
                onChange={(selectedOptionDoctors) =>
                  setOptionSelectedDoctors(selectedOptionDoctors)
                }
                options={optionsDoctors}
              />
              <a
                onClick={() => setPage("add desideratat")}
                style={{ marginLeft: 20, textAlign: "end", height: "fit-content" }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("add desiderata")}
              </a>
            </div>
          </div>
          <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
          <div className="overflow" style={{ marginTop: 20 }}>
            {myDesideratas && load ? (
              <table
                className="table table-translation table-striped table-bordered table-scroll"
                style={{ width: "100%" }}
              >
                <thead className="fix-header">
                  <tr style={{ background: "#007bff", color: "white" }}>
                    <th className="header" style={{ width: "25%", background: "#007bff" }}>
                      <span style={{ display: "block" }}>{t("types")}</span>
                    </th>
                    <th className="header" style={{ width: "25%", background: "#007bff" }}>
                      <span style={{ display: "block" }}>{t("date start desiderata")}</span>
                    </th>
                    <th className="header" style={{ width: "25%", background: "#007bff" }}>
                      <span style={{ display: "block" }}> {t("date end desiderata")}</span>
                    </th>
                    <th className="header" style={{ width: "12%", background: "#007bff" }}>
                      <span style={{ display: "block" }}> {t("status")}</span>
                    </th>
                    <th className="header" style={{ width: "12%", background: "#007bff" }}>
                      <span style={{ display: "block" }}>{t("action")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                  {myDesideratas && myDesideratas.ength === 0 && isLength === false && (
                    <tr>
                      <td colspan="5">{t("no data available")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <AddDesiderata
        setPage={setPage}
        desideratas={myDesideratas}
        types={optionsType}
        addDesiderata={addDesiderata}
        member_id={member_id}
        doctors={optionsDoctors}
      />
    );
  }
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListDesideratas);
