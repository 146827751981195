import { USER_T } from "../actions/users/action-user-types";
import { dateLogout } from "../utils/functions";

const initialState = { isAuthenticated: false, user: null, dateLogout: null, isErrorSocket: false };
export default function reducerActiveUser(state = initialState, action) {
  switch (action.type) {
    case USER_T.LOGIN:
      return { isAuthenticated: true, ...action.payload, isErrorSocket: false };

    case USER_T.UPDATED:
      return {
        isAuthenticated: true,
        user: { ...action.payload },
        dateLogout,
        isErrorSocket: false,
      };

    case USER_T.SETERROR:
      return { ...action.payload };

    case USER_T.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
