import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { sumBy } from "lodash";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { API } from "../../../utils/api";
import { Capitalize } from "../../../utils/functions";
import { useRequest } from "../../../hooks/use-api";

export function Indicator({ ufs, selectedUfs, anomalies }) {
  const request = useRequest();
  const { t } = useTranslation(["hospitalisation"]);

  const {
    data: [[fullCapacitaire], [fullPrevisionnel]],
  } = useQuery(
    ["hospitalisation", "in-progress", "indicator", ...ufs],
    () =>
      Promise.all([
        // capacitaire
        request({
          method: "POST",
          path: API.V5_CAPACITAIRE,
          data: { ufs },
        }),
        // previsionnel
        request({
          method: "POST",
          path: API.V5_PREVISIONNEL,
          data: { ufs },
        }),
      ]),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  // Filter data
  const capacitaire = useMemo(
    () => fullCapacitaire.filter(({ uf }) => selectedUfs.includes(uf)),
    [fullCapacitaire, selectedUfs],
  );
  const previsionnel = useMemo(
    () => fullPrevisionnel.filter(({ uf }) => selectedUfs.includes(uf)),
    [fullPrevisionnel, selectedUfs],
  );

  const occupes = sumBy(capacitaire, (uf) => uf.occupes);
  const disponibles = sumBy(capacitaire, (uf) => uf.disponibles);
  const dms = sumBy(previsionnel, (uf) => uf.dms) / previsionnel.length;
  const sortie24 = sumBy(previsionnel, (uf) => uf.sortie24);
  const sortie48 = sumBy(previsionnel, (uf) => uf.sortie48);

  return (
    <div className="row">
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{Capitalize(t("anomalies"))}</p>
        <p
          className={clsx(
            "indicator-value",
            Object.keys(anomalies).length < 0 ? "color-anomalie" : "",
          )}
          style={{ color: "#fb7809" }}
        >
          {Object.keys(anomalies).length}
        </p>
      </div>
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{Capitalize(t("occupied beds"))}</p>
        <p className={clsx("indicator-value", occupes < 0 ? "color-anomalie" : "")}>{occupes}</p>
      </div>
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{t("alos")}</p>
        <p className={clsx("indicator-value", dms < 0 ? "color-anomalie" : "")}>{dms.toFixed(1)}</p>
      </div>
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{Capitalize(t("free beds"))}</p>
        <p className={clsx("indicator-value", disponibles < 0 ? "color-anomalie" : "")}>
          {disponibles}
        </p>
      </div>
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{Capitalize(t("24-hour discharge"))}</p>
        <p className={clsx("indicator-value", sortie24 < 0 ? "color-anomalie" : "")}>{sortie24}</p>
      </div>
      <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
        <p className="indicator-label">{Capitalize(t("48-hour discharge"))}</p>
        <p className={clsx("indicator-value", sortie48 < 0 ? "color-anomalie" : "")}>{sortie48}</p>
      </div>
    </div>
  );
}
