/**
 * By : Theo Bensaci
 * Date : 19.04.2022
 * Desc : manage block drag down interaction
 */
import React, { useState, useEffect, useRef } from "react";
import * as ReactDOM from "react-dom";
import { Contraints } from "./Contraints";
export class BlockDragDown {
  constructor(Container) {
    this.OnMove = false;
    this.Inventory = null; // use for store a contraints
    this.curosPos = { x: 0, y: 0 };
    this.currenTarget = null;
    this.movementUpdate = null;
    this.mainContainer = Container;
    this.Spots = [];
    this.SpotFrom = null;
  }

  //#region Contraints Block
  grabBlock(element, contraint, className, spot = null) {
    if (this.Inventory == null) {
      this.Inventory = { contraint: contraint, className: className };
      //console.log(this.Inventory);
      this.startMove(element);
      this.switchSpotState(true);
      this.SpotFrom = spot;
    }
  }

  relaseBlock(element) {
    if (this.Inventory != null && (element == null || element == this.currenTarget)) {
      this.setLastPosOnCss();
      this.currenTarget.className = this.Inventory.className;
      this.Inventory = null;
      this.endMove();
      this.switchSpotState(false);
      this.SpotFrom = null;
    }
  }

  destroyCurrentBlock() {
    if (this.Inventory != null) {
      //this.setLastPosOnCss();
      this.currenTarget.className = this.Inventory.className;
      this.mainContainer.dellBlock(this.Inventory.contraint);
      this.Inventory = null;
      this.SpotFrom = null;
      this.endMove();
      this.switchSpotState(false);
    }
  }

  createContraintsBlocks(contraints) {
    this.mainContainer.addBlocks(contraints);
  }

  createContraintBlock(contraints) {
    this.mainContainer.addBlocks([contraints]);
  }

  setLastPosOnCss() {
    let r = document.querySelector(":root");
    r.style.setProperty("--transX", this.curosPos.x);
    r.style.setProperty("--transY", this.curosPos.y);
  }

  /**
   * return if a contraint is on inventory
   * @param {Contraints} contraint
   * @returns {bool} is include
   */
  containeContraint(contraint) {
    return this.mainContainer.includeContraint(contraint);
  }
  //#endregion

  //#region Contraints Spot
  addSpot(spot) {
    if (!this.Spots.includes(spot)) {
      this.Spots.push(spot);
      for (const iterator of this.mainContainer.data) {
        if (iterator.active && iterator.bloc == spot.index) {
          spot.addToInventory(
            new Contraints(
              iterator.constraint_id,
              iterator.name.replaceAll("_", " "),
              iterator.weight,
              !iterator.to_balance,
            ),
          );
        }
      }
    }
  }

  switchSpotState(state) {
    for (const element of this.Spots) {
      element.switchEnabelSpotState(state);
    }
  }

  clearLastSpotInv() {
    if (this.SpotFrom != null) {
      this.SpotFrom.removeFromInventory(this.Inventory.contraint);
    }
  }

  getSpotsInventory() {
    let list = [];
    for (const iterator of this.Spots) {
      if (iterator.Inventory.length > 0)
        list.push({ index: iterator.index, Inventory: iterator.Inventory });
    }
    return list;
  }

  //#endregion

  //#region Movement

  /**
   * Start movement update for a contraints block
   * @param {DOM element} element contraints block DOM element
   */
  startMove(element) {
    if (!this.OnMove) {
      this.currenTarget = element;
      this.OnMove = true;
      this.movementUpdate = setInterval(() => {
        this.move();
      }, 0);
    }
  }

  /**
   * movement update
   */
  move() {
    let e = this.currenTarget;
    e.style.position = "absolute";
    e.style.top = this.curosPos.y - 20 + "px";
    e.style.left = this.curosPos.x - e.offsetWidth / 2 + "px";
  }

  /**
   * End movement update
   */
  endMove() {
    if (this.OnMove) {
      this.OnMove = false;
      this.currenTarget.style.position = "relative";
      this.currenTarget.style.top = null;
      this.currenTarget.style.left = null;
      clearInterval(this.movementUpdate);
    }
  }
  //#endregion

  /**
   * Function for setup the position of the cursor
   * @param {DOM element} element container div
   */
  posCurosUpdateSetUp(element) {
    let t = this;
    function updateDisplay(event, owner) {
      owner.curosPos.x = event.pageX;
      owner.curosPos.y = event.pageY;
    }

    element.addEventListener(
      "mousemove",
      (event) => {
        updateDisplay(event, t);
      },
      false,
    );
    element.addEventListener(
      "mouseenter",
      (event) => {
        updateDisplay(event, t);
      },
      false,
    );
    element.addEventListener(
      "mouseleave",
      (event) => {
        updateDisplay(event, t);
      },
      false,
    );
  }
}
