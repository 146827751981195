import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenTransit from "../screens/screenJourneyTransit";
import ScreenUrgence from "../screens/screenJourneyEmergency";
import Performance from "../screens/screenJourneyPerformance";
import HeaderHopitalisation from "../homeScreenHospitalisation";
import Screen1A from "../screens/screenJourneyPatients";
import ScreenHospitalisationToCome from "../screens/screenHospitalisationToCome";
import HomeParameterBed from "../homeParameterBed";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../utils/api";
import ReactLoading from "react-loading";
import * as Permission from "../../utils/permissions";
import AccesDenied from "../../components/not-found/accesDenied";
import NoBedNotification from "../../components/NoBedNotification";

const HeaderJourney = (props) => {
  const [screen, setScreen] = useState("emergency");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState(null);

  const { t, i18n } = useTranslation(["journey"]);

  const redirection = () => {
    let screen = "screen journey emergency";
    if (Permission.hasRole("JOURNEY EMERGENCY", props.roles)) screen = "screen journey emergency";
    else if (Permission.hasRole("JOURNEY TRANSIT", props.roles)) screen = "screen journey transit";
    else if (Permission.hasRole("JOURNEY PATIENTS", props.roles))
      screen = "screen journey patients";
    else if (Permission.hasRole("JOURNEY PERFORMANCE", props.roles))
      screen = "screen journey performance";

    setScreen(screen);
  };
  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      if (props.location.state === undefined) {
        redirection();
      } else setScreen(props.location.state && props.location.state.hash);
    } else {
      if (
        props.location.state === null ||
        props.location.state === "" ||
        props.location.state === undefined
      )
        redirection();
      else setScreen(props.location.state);
    }
    redirection();
  }, [props.location.state]);

  function loadData(){
    try {
      axios
        .get(`${API.ENDPOINT}${API.SCREENPARCOURS}`, {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setLoad(false);
        });
    } catch (e) {
      //  console.log('=====error:',e)
    }

  }

  useEffect(() => {
    loadData();
  }, [props.screensData]);

  const renderScreen = (name = "") => {
    /*
        I'm sorry, I was forced to correct the thing like this

        btw me future, really sorry
    */
    if (props.screensData.loaded && data && load === false) {
      let useName = name == "" ? screen : name;
      switch (useName) {
        case "screen journey transit":
          return <ScreenTransit data={data["values"]} roles={props.roles}/>;
        // case "screen journey transit2":
        //   return <ScreenTransit data={data["values2"]} />;
        case "screen journey emergency":
          return <ScreenUrgence data={props.screensData} />;
        case "screen journey patients":
          return <Screen1A data={props.screensData} />;
        case "screen journey performance":
          return <Performance />;
        case "screen hospitalisation to come":
          return (
            <div className="main container-fluid">
              <HeaderHopitalisation setScreen="screen hospitalisation to come" {...props} />
            </div>
          );
        case "screenBedManagementCells":
          return (
            <div className="main container-fluid">
              <HomeParameterBed setScreen="screenBedManagementCells" {...props} />
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignscreens: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  if (
    Permission.hasRole("JOURNEY TRANSIT", props.roles) ||
    Permission.hasRole("JOURNEY EMERGENCY", props.roles) ||
    Permission.hasRole("JOURNEY PATIENTS", props.roles) ||
    Permission.hasRole("JOURNEY PERFORMANCE", props.roles)
  ) {
    return props.setScreen != undefined ? (
      renderScreen(props.setScreen)
    ) : (
      <>
        <div className="main-screen container-rh">
          <h2 className="h2">{t("journey")}</h2>

          <ul className="nav nav-tabs">
            {Permission.hasRole("JOURNEY EMERGENCY", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen journey emergency")}
                  className={`nav-link ${
                    screen === "screen journey emergency" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("emergency").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("JOURNEY TRANSIT", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen journey transit")}
                  className={`nav-link ${
                    screen === "screen journey transit" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("transit").toUpperCase()}
                </a>
              </li>
            )}
            {/* {Permission.hasRole("ROLE JOURNEY TRANSIT2", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen journey transit2")}
                  className={`nav-link ${
                    screen === "screen journey transit2" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("transit2").toUpperCase()}
                </a>
              </li>
            )}
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen hospitalisation to come")}
                className={`nav-link ${
                  screen === "screen hospitalisation to come" ? "active disabled" : ""
                }`}
                href="#"
              >
                {t("screen hospitalisation to come").toUpperCase()}
              </a>
            </li>*/}
            <li className="nav-item">
              <NoBedNotification element={
                <a
                  onClick={() => setScreen("screenBedManagementCells")}
                  className={`nav-link ${
                    screen === "screenBedManagementCells" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("screenBedManagementCells_title").toUpperCase()}
                </a>
              } hide={screen === "screenBedManagementCells"}/>
            </li>
            {Permission.hasRole("JOURNEY PERFORMANCE", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen journey performance")}
                  className={`nav-link ${
                    screen === "screen journey performance" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("performance").toUpperCase()}
                </a>
              </li>
            )}
          </ul>

          {renderScreen()}
        </div>
      </>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  screensHospData: state.screensHospData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderJourney);
