import clsx from "clsx";
import {
  addDays,
  startOfDay,
  endOfDay,
  addHours,
  addMilliseconds,
  getMilliseconds,
  differenceInHours,
  differenceInMilliseconds,
  isWithinInterval,
} from "date-fns";
import { ArrowSmallRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import { last, first } from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUfLabels } from "../../../../hooks/use-uf-labels";
import { useQuery, useMutation } from "@tanstack/react-query";
import { API } from "../../../../utils/api";
import { useRequest } from "../../../../hooks/use-api";
import { useUfs } from "../../../../hooks/use-ufs";
import { Capitalize } from "../../../../utils/functions";
import { lowestDate, highestDate } from "../../../../utils/date";
import { useNow } from "../../../../hooks/use-now";
import { AccommodationFilters } from "./filters";
import "./style.scss";
import { useSelector } from "react-redux";
import { p2pStatus } from "../../../../utils/p2p-status";
import { bemPatientName } from "../../../../utils/bem-patient-name";
import {
  Gantt,
  colorFromSexe,
  cellFromLos,
  paginateRows,
  item,
} from "../../../../components/gantt";
import { P2PModal } from "../../../p2p-modal";
import LoadingOverlay from "react-loading-overlay";
import { useFormat } from "../../../../hooks/use-format";
import { Pagination } from "./pagination";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { linesFromPoints } from "../../../../utils/lines-from-points";
import { useUfDms } from "../../../../hooks/use-uf-dms";
import { Search } from "./search";

export function Journey() {
  const { t } = useTranslation(["hospitalisation"]);
  const now = useNow(60 * 60 * 1000);
  const request = useRequest();
  const ufLabels = useUfLabels();
  const [p2pModal, setP2PModal] = useState(null);
  const [page, setPage] = useState(0);
  const [ieps, setIeps] = useState([]);

  const { data: [api] = [], refetch } = useQuery(
    ["bedManager", "journey", ...ieps],
    () =>
      // accommodation
      request({
        method: "POST",
        path: API.V5_JOURNEYS,
        data: {
          ieps,
        },
      }),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
      enabled: Boolean(ieps.length > 0),
    },
  );

  return (
    <div className={clsx("jny", api?.length > 0 ? "jny--with-result" : "")}>
      <div className="jny--search">
        <Search onChange={setIeps} size={Boolean(api) ? undefined : "lg"} />
      </div>
      {api ? (
        <div className="jny--results">
          {api.length > 0 ? (
            api.map((result) => <Result key={result.iep} result={result} refetch={refetch} />)
          ) : (
            <p className="p jny--no-result">Aucun parcours pour cette personne</p>
          )}
        </div>
      ) : null}
    </div>
  );
}

function linkWithArrow(rows) {
  return rows.map(function (row, index, rows) {
    const nextCell = rows[index + 1]?.cells[0];

    return {
      ...row,
      cells: row.cells.map(function (cell, index, cells) {
        const isLast = index === cells.length - 1;

        if (!isLast) {
          return cell;
        }

        if (!nextCell) {
          return cell;
        }

        return {
          ...cell,
          arrowTo: nextCell.from,
        };
      }),
    };
  });
}

function Result({ result, refetch }) {
  const { t } = useTranslation(["hospitalisation"]);
  const format = useFormat();
  const now = useNow(60 * 60 * 1000);
  const [p2pModal, setP2PModal] = useState(null);
  const ufLabels = useUfLabels();
  const ufs = useUfs();
  const ufDms = useUfDms(ufs);

  const journey = useMemo(
    function () {
      const { iep, bemPatient, seqBeds = [], losPred } = result;
      const events = seqBeds.sort((a, b /* INFO: urg event will always be the first event */) =>
        a.uf === "urg" && b.uf === "urg" ? 0 : a.uf === "urg" ? -1 : b.uf === "urg" ? 1 : 0,
      );
      const lines = linesFromPoints(events);

      const rows = lines.flatMap(function ({ left, right }, index, lines) {
        const key = `${iep}-${left.index}-${right?.index ?? "now"}`;
        const data = {
          sector: left.uf === "urg" ? "URGENCE" : ufLabels[left.uf]?.toUpperCase() ?? left.uf,
          uf: left.uf === "urg" ? "-" : left.uf,
          origin: index === 0 ? (left.uf === "urg" ? "URGENCE" : "PRÉ-ADMISSION") : "MUTATION",
          bed: left.Bed.index ? `${left.Bed.lit}/${left.Bed.chambre}` : `${left.bed}/-`,
        };

        const isLast = index === lines.length - 1;
        const isLos =
          !right &&
          losPred &&
          losPred.uf == left.uf &&
          losPred.lit == left.bed &&
          losPred.iep === iep;

        if (isLos && !losPred.date_sortie) {
          const losCell = cellFromLos(losPred, {
            now,
            t,
            ufLabels,
            ufDms,
            format,
            actions: isLast ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() =>
                    setP2PModal({
                      iep: losPred.iep,
                      bemPatient: bemPatient,
                      age: losPred.age,
                      sex: losPred.sexe,
                      uf: losPred.uf,
                    })
                  }
                >
                  Transport
                </Button>
              </div>
            ) : undefined,
          });

          const cell = {
            ...losCell,
            label: data.sector,
          };

          return [
            {
              key: `${key}-row`,
              data: {
                ...data,
                from: format(cell.from, "dd/MM HH:mm"),
                to: format(cell.to, "dd/MM HH:mm"),
              },
              cells: [cell],
            },
          ].filter(Boolean);
        }

        const from = new Date(left.datetime);
        const to = new Date(right?.datetime ?? (isLast ? losPred?.date_sortie : null) ?? now);

        return [
          {
            key: `${key}-row`,
            data: {
              ...data,
              from: format(from, "dd/MM HH:mm"),
              to: format(to, "dd/MM HH:mm"),
            },
            cells: [
              {
                key: `${key}-cell`,
                label: data.sector,
                from,
                to,

                isLeave24h: false,
                isLeave48h: false,
                isAccommodation: false,
                isRestitution: false,
                isMoving: false,
                isBedblocker: false,
                isDoubleRoom: false,

                border: "normal",
                background: colorFromSexe(bemPatient?.sexe),
              },
            ],
          },
          isLast && Boolean(losPred?.date_sortie)
            ? {
                key: `${key}-cell`,
                data: {
                  sector: "SORTIE",
                  uf: "",
                  origin: "",
                  bed: "",
                  from: format(to, "dd/MM HH:mm"),
                  to: "-",
                },
                meta: {
                  journeyEnd: true,
                },
                cells: [
                  {
                    key: `${key}-cell-exit`,
                    from: to,
                    to: to,
                    background: "transparent",
                    children: (
                      <HomeIcon
                        style={{
                          width: 33,
                          height: 33,
                          borderRadius: 6,
                          color: "white",
                          background: "#71ba51",
                          padding: 5,
                          fontWeight: "900",
                        }}
                      />
                    ),
                  },
                ],
              }
            : undefined,
        ].filter(Boolean);
      });

      const start = lowestDate(...rows.flatMap((row) => row.cells.map((cell) => cell.from)));
      const end = highestDate(
        ...rows.flatMap((row) => row.cells.map((cell) => cell.gradientTo ?? cell.to)),
      );
      const ended = rows.some((row) => row.meta?.journeyEnd ?? false);

      return {
        iep,
        bemPatient,
        start,
        end,
        rows,
        ended,
      };
    },
    [result, now, ufLabels],
  );

  return journey.rows.length > 0 ? (
    <div className="jny--result">
      <div className="jny--details">
        {journey.bemPatient ? (
          <>
            <h5>{bemPatientName(journey.bemPatient)}</h5>
            <table style={{ width: "auto" }}>
              <tbody>
                <tr>
                  <td>IPP:</td>
                  <td>{journey.bemPatient.ipp}</td>
                </tr>
                <tr>
                  <td>IEP:</td>
                  <td>{journey.bemPatient.iep}</td>
                </tr>
                <tr>
                  <td>Sexe:</td>
                  <td>{journey.bemPatient.sexe}</td>
                </tr>
                <tr>
                  <td>Anniversaire:</td>
                  <td>{journey.bemPatient.ddn}</td>
                </tr>
                <tr>
                  <td>Début du parcours:</td>
                  <td>{format(journey.start, "Pp")}</td>
                </tr>
                {journey.ended ? (
                  <tr>
                    <td>Fin du parcours:</td>
                    <td>{format(journey.end, "Pp")}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </>
        ) : (
          <h5>{journey.iep}</h5>
        )}
      </div>
      <Gantt
        now={now}
        from={startOfDay(journey.start)}
        to={endOfDay(addDays(highestDate(addDays(journey.start, 7), journey.end), 1))}
        columns={{
          sector: "UF",
          uf: "#UF",
          origin: "Provenance",
          bed: "Lit/Chambre",
          from: "De",
          to: "A",
        }}
        rows={linkWithArrow(journey.rows)}
      />
      {p2pModal ? (
        <P2PModal
          open
          patient={{
            iep: p2pModal.iep,
            bemPatient: p2pModal.bemPatient,
            age: p2pModal.age,
            sex: p2pModal.sex,
            originUf: p2pModal.uf,
          }}
          onClose={() => setP2PModal(null)}
          refresh={() => refetch()}
        />
      ) : null}
    </div>
  ) : null;
}
