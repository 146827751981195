import React, { useState, useEffect } from "react";
import "./index.css";
import { bindActionCreators } from "redux";
import Jump from "react-reveal/Jump";
import { useTranslation } from "react-i18next";
import { changePassword, logoutUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import * as Permission from "../../utils/permissions";
import { Capitalize } from "../../utils/functions";
import { UtilsRegex } from "../../utils/regex";

const ScreenChangePassword = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };
  const handleSelect = (e) => {
    changeLanguage(e.target.value);
  };

  const [local] = useState(i18n.language);
  const [repeatPassword, setConfirmation] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (props.activeUSer.isAuthenticated === true) {
      const roles = props.activeUSer.user.group && props.activeUSer.user.group.Roles;
      const screenDefault = props.activeUSer.user.screen_default;

      if (screenDefault === null || screenDefault === "") {
        if (
          Permission.isSuperAdmin(roles) ||
          Permission.isAdmin(roles) ||
          Permission.hasAtLeastOneRole(Permission.roles["emergency"], roles)
        ) {
          history.push({ pathname: "/Emergency" });
        } else if (Permission.hasRole("ROLE DEMO GLOBAL", roles)) {
          history.push({ pathname: "/Demo" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["journey"], roles)) {
          history.push({ pathname: "/Journey" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["hospitalisation"], roles)) {
          history.push({ pathname: "/Hospitalisation" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["cockpit"], roles)) {
          history.push({ pathname: "/Cockpit" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["rh"], roles)) {
          history.push({ pathname: "/Rh" });
        } else {
          history.push("/");
        }
      } else {
        if (Permission.hasRole(screenDefault.toUpperCase(), roles)) {
          history.push({
            pathname: "/" + Capitalize(screenDefault.split(" ")[0]),
            state: "screen " + screenDefault,
          });
        } else {
          history.push({ pathname: "/" });
        }
      }
    }
  });

  const changePassword = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    let test = false;
    let errors = {};

    const strongRegex = new RegExp(UtilsRegex.Password);

    if (password !== "" && !strongRegex.test(password)) {
      errors = {
        password:
          "your password must contain at least 8 characters, with at least one upper case letter, one lower case letter, one number and one special character",
      };
      test = true;
    }

    if (repeatPassword !== "" && password !== repeatPassword) {
      errors = {
        ...errors,
        repeatPassword: "your confirmation password does not match the registered password",
      };
      test = true;
    }

    if (test) {
      setError(errors);
      setLoad(false);
      setShowError(true);
      return;
    }

    props
      .changePassword({ password, repeatPassword }, props.activeUSer.user, history)
      .catch((error) => {
        setError(error.msg);
        setLoad(false);
        setShowError(true);
      });
  };

  return (
    <div className="wrapper fadeInDown ">
      <div id="formContent-change">
        <LoadingOverlay active={load} spinner>
          <div className="fadeIn first ">
            <div className="">
              <div className="form-group form-select">
                <select
                  onChange={handleSelect}
                  defaultValue={local}
                  className="form-control"
                  id="select_local"
                >
                  <option value="fr">{t("fr").toUpperCase()}</option>
                  <option value="en">{t("en").toUpperCase()}</option>
                  <option value="de">{t("de").toUpperCase()}</option>
                  <option value="it">{t("it").toUpperCase()}</option>
                </select>
              </div>
            </div>
            <Jump duration={1500}>
              <div className="row">
                <h4
                  style={{
                    color: "#1384bd",
                    marginBottom: 50,
                    marginTop: 30,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {t("your password must be changed. please change it")}
                </h4>
              </div>
            </Jump>
          </div>

          <Jump duration={1500}>
            <form onSubmit={changePassword}>
              <div style={{ textAlign: "center" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => <div className="errors"> {t(err)} </div>)}
              </div>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                className="fadeIn form-control textInput"
                name="_password"
                placeholder={t("new password")}
              />
              <input
                type="password"
                onChange={(e) => setConfirmation(e.target.value)}
                id="password"
                className="fadeIn form-control textInput"
                name="_confirmation"
                placeholder={t("confirm new password")}
              />

              <input
                type="submit"
                disabled={repeatPassword !== "" && password !== "" ? false : true}
                className="fadeIn fourth btn"
                value={t("register")}
              />

              <div>
                <a href onClick={() => props.logoutUser()}>
                  {t("login to another account")}
                </a>
              </div>
            </form>
          </Jump>
        </LoadingOverlay>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ changePassword, logoutUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenChangePassword);
