/**
 * By : Theo Bensaci
 * Date : 11.04.2022
 * Desc : Screen Rh Contraints
 */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import ReactLoading from "react-loading";
import { ContraintBlock, ContraintSpot } from "./ContraintsBlock";
import { BlockDragDown } from "./DragDownBlock";
import { Contraints } from "./Contraints";
import { ComfirmBox } from "../../ComfirmBox/ComfirmBox";
import { FaLessThanEqual } from "react-icons/fa";

export class ContraintsHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.dragDownManager = new BlockDragDown(this);
    this.state = { Contents: [] };
    this.inventory = [];
    this.spots = [];
    this.data = props.data;
    this.containerRef = React.createRef();
    let nMaxSpot = 5;
    for (let index = 0; index < nMaxSpot; index++) {
      this.spots.push(<ContraintSpot dragDownManager={this.dragDownManager} index={index} />);
    }
  }

  createList() {
    let actualList = [];
    for (let index = 0; index < 20; index++) {
      actualList.push(new Contraints(index + 100, "test N°" + (index + 1), 1, index % 2));
    }
    return actualList;
  }

  generateContraints() {
    let list = [];
    for (const iterator of this.data) {
      if (!iterator.active) {
        list.push(
          new Contraints(
            iterator.constraint_id,
            iterator.name.replaceAll("_", " "),
            iterator.weight,
            !iterator.to_balance,
          ),
        );
      }
    }
    this.inventory = list;
  }

  showInventory() {
    let blockList = [];
    for (const iterator of this.inventory) {
      let i = <ContraintBlock dragDownManager={this.dragDownManager} contraint={iterator} />;
      blockList.push(i);
    }
    this.setState({
      Contents: blockList,
    });
  }

  dellBlock(contraint) {
    let index = this.inventory.indexOf(contraint);
    if (index > -1) {
      this.inventory.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.showInventory();
  }

  /**
   * return if a contraint is on inventory
   * @param {Contraints} contraint
   * @returns {bool} is include
   */
  includeContraint(contraint) {
    return this.inventory.includes(contraint);
  }

  /**
   * Add
   * @param {contraint[]} contraint
   */
  addBlocks(contraint) {
    //console.log(contraint);
    for (const iterator of contraint) {
      this.inventory.unshift(iterator);
    }
    this.showInventory();
  }

  getAllInventory() {
    let list = this.inventory.concat(this.dragDownManager.getSpotsInventory());
    let newlist = [];
    for (const iterator of list) {
      if (iterator.Inventory != null) {
        for (const subIterator of iterator.Inventory) {
          newlist.push(this.genNewContraintObject(iterator.index, true, subIterator));
        }
      } else {
        newlist.push(this.genNewContraintObject(-1, false, iterator));
      }
    }
    return newlist;
  }

  genNewContraintObject(blocId, active, contraint) {
    return { id: contraint.Id, bloc: blocId, active: active, weight: contraint.Weight };
  }

  updateContraints() {
    let data = this.getAllInventory();
    //console.log( JSON.stringify(data));
    axios
      .get(`${API.ENDPOINT + API.RH_UPDATE_CONSTRAINTS_HIERARCHY}`, {
        params: { contraints: JSON.stringify(data) },
        headers: {
          "x-auth-token": this.props.activeUSer.user.token,
        },
      })
      .then((res) => {
        //console.log(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  //#region Confirm

  /**
   * End the comfirme box
   */
  endConfirmBox() {
    let newcontent = this.state.Contents;
    newcontent.pop();
    this.setState({
      Contents: newcontent,
    });
  }

  /**
   * Start a comfirme box
   */
  startConfirmBox() {
    let owner = this;
    let al = (
      <ComfirmBox
        text={this.props.t("Save Contraint Comfirm Message")}
        cancelText={this.props.t("Save Contraint Comfirm Cancel")}
        okText={this.props.t("Save Contraint Comfirm OK")}
        okCallBack={() => {
          owner.updateContraints();
        }}
        closeCallBack={() => {
          owner.endConfirmBox();
        }}
      />
    );
    let newcontent = this.state.Contents;
    newcontent.push(al);
    this.setState({
      Contents: newcontent,
    });
  }
  //#endregion

  componentDidMount() {
    this.generateContraints();
    this.dragDownManager.posCurosUpdateSetUp(this.containerRef.current);
    //this.inventory=this.createList();
    this.showInventory();
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="Container">
        <button
          className="actionBnt"
          onClick={() => {
            this.startConfirmBox();
          }}
        >
          {this.props.t("Save Actual Contraints").toUpperCase()}
        </button>
        <div class="ContraintsHierarchy" ref={this.containerRef}>
          <div className="contraintHerarchyPart">
            <h1>{this.props.t("Active Block")}</h1>
            <div class="blockContainer" id="spotContainer">
              {this.spots}
            </div>
          </div>
          <div className="contraintHerarchyPart">
            <h1>{this.props.t("Not Active Block")}</h1>
            <div class="blockContainer" id="blockContainer">
              {this.state.Contents}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ScreenContraintsHierarchy = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const data = useRef([]);

  //#region Data base request
  function getData() {
    axios
      .get(`${API.ENDPOINT + API.RH_CONSTRAINTS_HIERARCHY}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        data.current = res.data;
        //console.log(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  //#endregion

  useEffect(() => {
    getData();
  });

  return (
    <div className="ContraintsHierarchyContainer">
      {loading ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <ContraintsHierarchy t={t} activeUSer={props.activeUSer} data={data.current} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null)(ScreenContraintsHierarchy);
