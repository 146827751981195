import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { generateArrayOfRandom } from "../../utils/randomSeeded";

const ChartBar = (props) => {
  const [data, setData] = useState(null);
  const [annotations, setAnnotation] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionsPie, setoptionsPie] = useState(null);
  const { t, i18n } = useTranslation([`${props.traduction}`]);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    if (!props.data && !props.grav) return;
    let labels;
    let preds ;
    let color;
    if(props.grav){
      labels = [t("1"), t("2"), t("3A"),t("3B"), t("4"), t("5")];

      // gen data by the date
      const flautingPourcent=20;
      let d=[0,1.5,20.5,40,3,15]
      let date=new Date();
      let seed = date.getDate() + date.getMonth() + date.getDay();
      let numbers=generateArrayOfRandom(seed,d.length);
      let total = 0;
      for (let index = 0; index < numbers.length; index++) {
        total+=numbers[index];
      }
      for (let index = 0; index < d.length; index++) {
        console.log(((numbers[index]/total)))
        d[index]=d[index]+((numbers[index]/total)*flautingPourcent);
      }
      


      preds = [
        Math.round(d[0]),
        Math.round(d[1]),
        Math.round(d[2]),
        Math.round(d[3]),
        Math.round(d[4]),
        Math.round(d[5]),
      ];
      color=["#8F0C00", "#B80F00", "#CE1200","#FB1600","#FA5C40","#FF7E68"];
    }
    else{
      labels = [t("medecine"), t("chirurgie"), t("autres")];
      preds = [
        Math.round(props.data.medecine_ratio * 100),
        Math.round(props.data.chirurgie_ratio * 100),
        Math.round(props.data.autres_ratio * 100),
      ];
      color=["#2a4a67", "#447cad", "#5ca9ec","#2F5679","#8BB1F7","#6A87BD"];
    }
    setData({
      labels,
      datasets: [
        {
          data: preds,
          backgroundColor: color,
          hoverBackgroundColor: color,
        },
      ],
    });

    setoptionsPie({
      maintainAspectRatio: false,
      title: {
        display: true,
        text: props.title + " " + date.format("DD.MM"),
        fontSize: props.titleSize,
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        responsive: true,
        datalabels: {
          formatter: (value, ctx) => {
            let percentage;
            let datasets = ctx.chart.data.datasets;

            if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              let sum = datasets[0].data.reduce(
                (a, b) => parseFloat(a === null ? 0 : a) + parseFloat(b === null ? 0 : b),
                0,
              );
              let s = Math.round((value / sum) * 100);

              percentage = s >= 6 ? s + "%" : s > 0 && s <= 5 ? s : "";

              return percentage;
            } else {
              return percentage;
            }
          },
          font: {
            size: 18,
          },
          color: "black",
        },
      },
    });
    setUpdate(true);
  }, [props.data, i18n.language]);

  useEffect(() => {
    if (update === true) setUpdate(false);
  }, [update]);

  const renderChart = () => {
    let size = props.titleSize ? props.titleSize : 20;

    return (
      <Pie
        width={props.width}
        height={props.height}
        redraw={update}
        data={data}
        options={optionsPie}
      />
    );
  };

  return (
    <div className={data && props.box ? "row row-chart-box" : "row row-chart"}>
      {data && renderChart()}
    </div>
  );
};

export default ChartBar;
