/**
 * @ By: Theo Bensaci
 * @ Date: 11:56:13 19.09.2022
 * @ Description: react component for export d3 diagrame
 * doc : https://github.com/react-d3-library/react-d3-library
 */

import React, { useState, useEffect, useRef } from "react";
import * as BoxPlot from "./PlotBoxDiagrame";
import rd3 from "react-d3-library";
import { ReactSankey } from "./MySankey/Sankey";
const RD3Component = rd3.Component;

/**
 * Export a d3 diagrame to react
 * @param {json} props
 *        @param {string} props.type type of digramme
 *                  - "PlotBox" = Plot Box diagrme or in french, une boit a moustach
 */
export function D3Export(props) {
  // graph use
  const [content, setContent] = useState(null);

  /**
   * Chose a diagram
   * @param {string} name diagrame name
   * @returns d3 compenent of the diagrame
   */
  function ChoseGraph(name) {
    switch (name) {
      case "PlotBox":
        let boxPlot = BoxPlot.boxPlot(
          props.data,
          props.width,
          props.height,
          props.minScale ? props.minScale : 0,
          props.maxScale ? 0 : props.maxScale,
          props.mainColor,
          props.secondColor,
        );
        return <RD3Component data={boxPlot} />;
      case "Sankey":
        return (
          <ReactSankey
            data={props.data}
            nodeColors={props.nodeColors}
            width={props.width}
            height={props.height}
          />
        );
    }
  }

  useEffect(() => {
    setContent(ChoseGraph(props.type));
  }, [props]);

  return <div>{content}</div>;
}
