import React, { Fragment, useMemo, useState } from "react";
import { differenceInMinutes } from "date-fns";
import { Capitalize } from "../../../../../utils/functions";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import moment from "moment";
import { useSelector } from "react-redux";
import { P2PModal } from "../../../../p2p-modal";
import { useUfLabels } from "../../../../../hooks/use-uf-labels";
import { bemPatientName } from "../../../../../utils/bem-patient-name";
import { p2pStatus } from "../../../../../utils/p2p-status";

const originUf = 994; // FIXME: Guess the origin by the data

function ToTreat({ ufs, patientsToTreat, refresh, param }) {
  const today = useMemo(() => moment(), []);

  const filteredPatients = patientsToTreat.filter((patient) => ufs.includes(patient.uf));

  return (
    <>
      <Summary date={today} patients={filteredPatients} />
      <Data date={today} patients={filteredPatients} param={param} refresh={refresh} />
    </>
  );
}

function Summary({ date, patients }) {
  const summary = useMemo(
    () => ({
      nbToTreat: patients.filter((patient) => !patient.bedDispatchBedManagementStatus.status).length,
      nbPending: patients.filter((patient) => patient.bedDispatchBedManagementStatus.status === "REQUESTED").length,
      nbUHCD: patients.filter((patient) => patient.uf == 994).length,
      nbUTM: patients.filter((patient) => patient.uf == 999).length,
    }),
    [patients],
  );

  return (
    <div
      className="row capacitaire-summary"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "30px",
        marginBottom: "10px",
        paddingTop: "10px",
      }}
    >
      <fieldset>
        <legend className="titleIndicators">
          Patients gérés par la cellule / {date.format("DD.MM.YYYY")}
        </legend>
        <div
          className="col-12 decompte-indicators"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "15px",
          }}
        >
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>A traiter</span>
            </h5>
            <p className="screen3-indicator">{summary.nbToTreat}</p>
          </div>
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>En attente</span>
            </h5>
            <p className="screen3-indicator">0</p>
          </div>
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>En cours</span>
            </h5>
            <p className="screen3-indicator">{summary.nbPending}</p>
          </div>
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>Placés</span>
            </h5>
            <p className="screen3-indicator">0</p>
          </div>
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>UHCD</span>
            </h5>
            <p className="screen3-indicator">{summary.nbUHCD}</p>
          </div>
          <div className="d-flex flex-column indicator-gray-title-black">
            <h5 className="screen3-title-indicator">
              <span style={{ fontSize: 16 }}>UTM</span>
            </h5>
            <p className="screen3-indicator">{summary.nbUTM}</p>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

function Data({ date, patients, refresh, param }) {
  const ufLabels = useUfLabels();
  const [selectedIep, setSelectedIep] = useState(null);
  const [p2pOpenModal, setP2POpenModal] = useState(null);

  let addMin=param.branc_time_bef_appo.minutes;
  const now = moment().add(addMin,"minute");

  return (
    <div
      className="col-12 tableFixHead"
      style={{ overflow: "auto", height: "43vh", marginTop: 10, paddingLeft: 0, paddingRight: 0 }}
    >
      <table
        className="table table-bordered table-patients-to-treat"
        style={{ alingSelf: "center" }}
      >
        <thead className="thead-theme">
          <tr>
            <th scope="col" className="thLits"></th>
            <th className="thLits" scope="col">
              NOM Prenom
            </th>
            <th className="thLits" scope="col">
              Age
            </th>
            <th className="thLits" scope="col">
              Sexe
            </th>
            <th className="thLits" scope="col">
              Provenance
            </th>
            <th className="thLits" scope="col">
              UF D'orientation ideale
            </th>
            <th className="thLits" scope="col">
              Attente
            </th>
            <th className="thLits" scope="col">
              Status
            </th>
            <th className="thLits" scope="col">
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          {Object.values(patients).length === 0 ? (
            <tr className="trLits">
              <td colSpan={10}>Aucun patient</td>
            </tr>
          ) : (
            Object.values(patients).map((patient) => {
              const selected = patient.iep === selectedIep;

              const dateIn = moment(patient.date_in);
              const status = p2pStatus(patient);

              return (
                <Fragment key={patient.iep}>
                  <tr className="trLits" style={{ backgroundColor: selected ? "#dae3f3" : null }}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedIep(selected ? null : patient.iep)}
                    >
                      <XCircleIcon
                        style={{
                          height: 30,
                          width: 25,
                          cursor: "pointer",
                          color: selected ? "blue" : "gray",
                        }}
                        onClick={() => setSelectedIep(selected ? null : patient.iep)}
                      />
                    </td>
                    <td>
                      {bemPatientName(patient.bemPatient)} <br /> {patient.iep}
                    </td>
                    <td>{patient.age}</td>
                    <td>{patient.sex}</td>
                    <td>{ufLabels[originUf] ?? originUf}</td>
                    <td>
                      {patient.uf}
                      {ufLabels[patient.uf] ? ` - ${ufLabels[patient.uf]}` : ""}
                    </td>
                    <td>{moment.utc(now.diff(dateIn,"hours")).format("HH")}h{ moment.utc(moment(now, "HH:mm:ss").diff(moment(dateIn, "HH:mm:ss"))).format("mm")}</td>
                    <td>{status?.label ?? "A traiter"}</td>
                    <td
                      style={{
                        padding: 0,
                      }}
                    >
                      {status ? (
                        "-"
                      ) : (
                        <button
                          disabled={!selected}
                          style={{
                            background: selected ? "blue" : "gray",
                            width: "100%",
                            height: "60px",
                            color: "white",
                            cursor: "pointer",
                            fontWeight: "bold",
                            padding: "5px 10px",
                            border: 0,
                          }}
                          onClick={() => setP2POpenModal(patient.iep)}
                        >
                          TRANSPORT
                        </button>
                      )}
                    </td>
                  </tr>
                  <P2PModal
                    patient={{
                      iep: patient.iep,
                      bemPatient: patient.bemPatient,
                      age: patient.age,
                      sex: patient.sexe,
                      originUf,
                    }}
                    open={p2pOpenModal === patient.iep}
                    onClose={() => setP2POpenModal(null)}
                    refresh={refresh}
                  />
                </Fragment>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ToTreat;
