import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import Line from "../../../components/chartV2/linePerfD";
import axios from "axios";
import { connect } from "react-redux";
import { calculateDeviation, Capitalize } from "../../../utils/functions";

import { API } from "../../../utils/api";

const ScreenPerformanceDay = (props) => {
  const { t, i18n } = useTranslation(["emergency"]);
  const [dataPerformanceDay, setDataPerformanceDay] = useState(null);
  const [load, setLoad] = useState(false);

  const [rateData, setRateData] = useState({
    relativePrecision: {
      prediction: 0,
      ideal: 0,
    },
    r2Score: {
      prediction: 0,
      ideal: 0,
    },
    absoluteError: {
      prediction: 0,
      ideal: 0,
    },
  });

  let types = [
    ["Admissions", t("admission")],
    ["Discharges", t("discharges")],
    ["Hospitalisations", t("hospitalizations")],
    ["Imageries", t("imageries")],
    ["Seniors", t("seniors")],
    //["Patients", t("patients")],
    ["Stress", t("stress")],
  ];

  useEffect(() => {
    if (load) return;

    axios
      .get(`${API.ENDPOINT + API.SCREEN_EMERGENCY_PERF_DAY}`, {
        headers: {
          "x-auth-token": props.activeUser.user.token,
        },
      })
      .then((res) => {
        setDataPerformanceDay(res.data);
        setLoad(true);
        let rateData = [];
        types.map((type) => {
          rateData[type[0]] = [];

          if (type[0] === "Patients" || type[0] === "Stress") {
            rateData[type[0]] = {
              relativePrecision: {
                prediction: (
                  (res.data[type[0]]["relativePrecisionMaxPerf"]["sum"] /
                    res.data[type[0]]["relativePrecisionMaxPerf"]["length"]) *
                  100
                ).toFixed(0),
                ideal: (
                  (res.data[type[0]]["bestRelativePrecisionMaxPerf"]["sum"] /
                    res.data[type[0]]["bestRelativePrecisionMaxPerf"]["length"]) *
                  100
                ).toFixed(0),
              },
              r2Score: {
                prediction: (
                  (res.data[type[0]]["r2PredictionPerf"]["sum"] /
                    res.data[type[0]]["r2PredictionPerf"]["length"]) *
                  100
                ).toFixed(0),
                ideal: (
                  (res.data[type[0]]["r2BestPerf"]["sum"] /
                    res.data[type[0]]["r2BestPerf"]["length"]) *
                  100
                ).toFixed(0),
              },
              absoluteError: {
                prediction: (
                  res.data[type[0]]["maePredictionPerf"]["sum"] /
                  res.data[type[0]]["maePredictionPerf"]["length"]
                ).toFixed(2),
                ideal: (
                  res.data[type[0]]["maeBestPerf"]["sum"] /
                  res.data[type[0]]["maeBestPerf"]["length"]
                ).toFixed(2),
              },
            };
          } else {
            rateData[type[0]] = {
              relativePrecision: {
                prediction: (
                  (res.data[type[0]]["relativePrecisionSumPerf"]["sum"] /
                    res.data[type[0]]["relativePrecisionSumPerf"]["length"]) *
                  100
                ).toFixed(0),
                ideal: (
                  (res.data[type[0]]["bestRelativePrecisionSumPerf"]["sum"] /
                    res.data[type[0]]["bestRelativePrecisionSumPerf"]["length"]) *
                  100
                ).toFixed(0),
              },
              r2Score: {
                prediction: (
                  (res.data[type[0]]["r2PredictionPerf"]["sum"] /
                    res.data[type[0]]["r2PredictionPerf"]["length"]) *
                  100
                ).toFixed(0),
                ideal: (
                  (res.data[type[0]]["r2BestPerf"]["sum"] /
                    res.data[type[0]]["r2BestPerf"]["length"]) *
                  100
                ).toFixed(0),
              },
              absoluteError: {
                prediction: (
                  res.data[type[0]]["maePredictionPerf"]["sum"] /
                  res.data[type[0]]["maePredictionPerf"]["length"]
                ).toFixed(2),
                ideal: (
                  res.data[type[0]]["maeBestPerf"]["sum"] /
                  res.data[type[0]]["maeBestPerf"]["length"]
                ).toFixed(2),
              },
            };
          }
        });

        setRateData(rateData);
      })
      .catch((err) => {
        // console.log('err:',err)
      });
  }, [load]);

  const renderCharts = () => {
    let values = dataPerformanceDay;
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.15 : "350px";

    return types.map((type, i) => {
      return (
        <>
          <div className="row-charts-s11 row" key={i}>
            <Line
              traduction="emergency"
              LineHour={false}
              type={type}
              rateData={rateData && rateData[type[0]] ? rateData[type[0]] : null}
              language={i18n.language}
              box={true}
              titleSize={35}
              label={true}
              J0={false}
              data={values[type[0]]}
              type={type[0]}
              title={Capitalize(t(type[1]))}
              annotations={false}
              height={height}
            />
          </div>
          <hr className="hr-day"></hr>
        </>
      );
    });
  };

  return (
    <div className="row">
      <div className="col-12 margin-title" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-12 col-md-8">
            <h1 className="page-title" style={{ textAlign: "left" }}>
              {Capitalize(t("prediction performance"))}
            </h1>
            <p className="secondetitle">
              {Capitalize(
                t(
                  "the performance is only calculated from the date of commissioning of the medical platform.",
                ),
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="col-12" style={{ marginTop: 25 }}>
        <div className="col-12 row-line" style={{ marginTop: 50 }}>
          {load && dataPerformanceDay ? (
            renderCharts()
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenPerformanceDay);
