import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenTransit from "../screens/screenDemoTransit";
import ScreenEmergency from "../screens/screenDemoEmergency";
import ScreenPrediction7 from "../screens/screenDemoPred7";
import ScreenLifeLine from "../screens/screenDemoRH_Planning/planningMember";

// import Screen1A from '../screens/screen1A'
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../utils/api";
import ReactLoading from "react-loading";
import * as Permission from "../../utils/permissions";
import AccesDenied from "../../components/not-found/accesDenied";

const HeaderDemo = (props) => {
  const [screen, setScreen] = useState("emergency");
  const [load, setLoad] = useState(false);
  const [dataDemoTransit, setDataDemoTransit] = useState(null);
  const [dataDemoPred, setDataDemoPred] = useState(null);
  const [dataDemoStaff, setDataDemoStaff] = useState(null);

  const { t, i18n } = useTranslation(["journey", "emergency", "rh"]);

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      setScreen(props.location.state && props.location.state.hash);
      setScreen("screen demo emergency");
    } else {
      let screenDefault = "screen demo emergency";

      setScreen(screenDefault);
    }
  }, [props.location.state]);
  useEffect(() => {
    try {
      axios
        .get(`${API.ENDPOINT}${API.SCREENPARCOURSDEMO}`, {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        })
        .then((res) => {
          setDataDemoTransit(res.data);
        })
        .catch((err) => {
          setLoad(false);
        });
    } catch (e) {}
  }, [props.screensData]);

  useEffect(() => {
    try {
      axios
        .get(`${API.ENDPOINT}${API.SCREENPREDDEMO}`, {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        })
        .then((res) => {
          setDataDemoPred(res.data);
          setDataDemoStaff(res.data.StaffDemo);
        })
        .catch((err) => {
          setLoad(false);
        });
    } catch (e) {}
  }, [props.screensData]);

  const renderScreen = () => {
    if (
      props.screensData.loaded &&
      dataDemoTransit &&
      load == false &&
      dataDemoPred &&
      dataDemoStaff
    ) {
      switch (screen) {
        case "screen demo transit":
          return <ScreenTransit data={dataDemoTransit} />;
          break;
        case "screen demo emergency":
          return <ScreenEmergency data={dataDemoPred} />;
          break;
        case "screen demo pred 7":
          return <ScreenPrediction7 data={dataDemoPred} />;
          break;
        case "screen demo lifeline":
          return <ScreenLifeLine data={dataDemoStaff} />;
          break;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  if (Permission.hasRole("ROLE DEMO GLOBAL", props.roles)) {
    return (
      <>
        <div className="main-screen container-rh">
          <div className="demo">
            <h2 className="h2">{t("saniia platform demo")}</h2>
          </div>

          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen demo emergency")}
                className={`nav-link ${screen == "screen demo emergency" ? "active disabled" : ""}`}
                href="#"
              >
                {t("emergency").toUpperCase()}
              </a>
            </li>

            <li className="nav-item">
              <a
                onClick={() => setScreen("screen demo transit")}
                className={`nav-link ${screen == "screen demo transit" ? "active disabled" : ""}`}
                href="#"
              >
                {t("transit").toUpperCase()}
              </a>
            </li>

            <li className="nav-item">
              <a
                onClick={() => setScreen("screen demo pred 7")}
                className={`nav-link ${screen == "screen demo pred 7" ? "active disabled" : ""} `}
                href="#"
              >
                {t("emergency:prediction 7").toUpperCase()}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen demo lifeline")}
                className={`nav-link ${screen == "screen demo lifeline" ? "active disabled" : ""} `}
                href="#"
              >
                {t("rh:lifeline").toUpperCase()}
              </a>
            </li>
          </ul>

          {renderScreen()}
        </div>
      </>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  screensHospData: state.screensHospData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderDemo);
