import { SCREEN } from "./actions";

export function AddDataEmerg(data) {
  return (dispatch) => {
    let values = [];
    let i = 0;

    data &&
      data.length > 0 &&
      data.map((item, index) => {
        for (let [key, value] of Object.entries(item)) {
          values[key] = value;
        }
        i++;
        if (i === data.length) {
          dispatch({ type: SCREEN.ADD_S_EMERG, payload: values });
        }
      });
  };
}

export function AddDataHosp(data) {
  return (dispatch) => {
    dispatch({ type: SCREEN.ADD_S_HOSP, payload: data });
  };
}
