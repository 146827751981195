import React, { useState, useEffect } from "react";
import "./index.css";
import PerformanceDay from "../screenEmergencyPerformanceDay";
import PerformanceHour from "../screenEmergencyPerformanceHour";
import { useTranslation } from "react-i18next";

const ScreenEmergencyPerformance = (props) => {
  const [page, setPage] = useState("hour");

  const { t, i18n } = useTranslation(["emergency"]);
  const a = 1;

  const renderScreen = () => {
    switch (page) {
      case "day":
        return <PerformanceDay />;
      case "hour":
        return <PerformanceHour />;
    }
  };

  return (
    <div className="main-screen container-urgence-performance">
      <div
        className="col-md-8 d-flex justify-content-start"
        style={{ textAlign: "end", marginTop: 20 }}
      >
        {page == "day" ? (
          <>
            <a
              onClick={() => setPage("hour")}
              style={{ marginLeft: 10, alignSelf: "center" }}
              className="btn btn-user-1 btn-primary"
            >
              {t("hour")}
            </a>
          </>
        ) : (
          <>
            <a
              onClick={() => setPage("day")}
              style={{ marginLeft: 10, alignSelf: "center" }}
              className="btn btn-user-1 btn-primary"
            >
              {t("day")}
            </a>
          </>
        )}
      </div>
      {renderScreen()}
    </div>
  );
};

export default ScreenEmergencyPerformance;
