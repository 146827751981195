import React, { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import Bounce from "react-reveal/Bounce";
import Jump from "react-reveal/Jump";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as Permission from "../../utils/permissions";
import LoadingOverlay from "react-loading-overlay";
import { Capitalize } from "../../utils/functions";

const ScreenLogin = (props) => {
  let history = useHistory();

  useEffect(() => {
    props.logoutUser(history);
  }, []);

  return null;
};

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ logoutUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenLogin);
