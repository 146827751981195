import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function optionsFromValues(values) {
  return values.reduce(function (acc, [key, value]) {
    if (!key) {
      return acc;
    }

    if (acc[key]) {
      return acc;
    }

    acc[key] = {
      value: key,
      label: value,
    };

    return acc;
  }, {});
}

export function Filters({ filters, setFilters, rows, activeFilter=[true,true,true,true,true,true,true,true] }) {
  const { t } = useTranslation(["hospitalisation"]);

  const sectorOptions = useMemo(
    () => optionsFromValues(rows.map((row) => [row.data.sector, row.data.sector?.toUpperCase()])),
    [rows],
  );
  const originOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap(
          (row) =>
            row.cells.map((cell) => [cell.meta.origin, cell.meta.origin?.toUpperCase()]) ?? [],
        ),
      ),
    [rows],
  );
  const diagOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap((row) => row.cells.map((cell) => [cell.meta.diag, cell.meta.diag]) ?? []),
      ),
    [rows],
  );
  const iepOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap((row) => row.cells.map((cell) => [cell.meta.iep, cell.label]) ?? []),
      ),
    [rows],
  );
  const bedStatusOptions = optionsFromValues([
    ["OL", "Ouvert / Libre"],
    ["OO", "Ouvert / Occupé"],
    ["FT", "Fermé / Temporaire"],
    ["FD", "Fermé / Définitif"],
  ]);

  const stayTypeOptions = optionsFromValues([
    ["ongoing", t("current patients")],
    ["incoming", t("patients to come")],
  ]);

  const sortingOptions = optionsFromValues([
    ["byEntryDateDesc", t("sort by entry time")],
    ["byBedAsc", t("sort by bed")],
  ]);

  const anomalie = optionsFromValues([
    ["all", t("all")],
    ["anomalie", t("only anomalie")],
  ]);


  return (
    <div className="hip-filters">
      <div>
        {activeFilter[0]?
        <Select
          value={filters.sectors.map((sector) => sectorOptions[sector])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={"Secteur"}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              sectors: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(sectorOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[1]?
        <Select
          value={filters.origins.map((origin) => originOptions[origin])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={"Origine"}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              origins: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(originOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[2]?
        <Select
          value={filters.diags.map((diag) => diagOptions[diag])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={"Diagnostique"}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              diags: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(diagOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[3]?
        <Select
          value={filters.ieps.map((iep) => iepOptions[iep])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={"Nom"}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              ieps: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(iepOptions)}
        />:null}
      </div>
      <div>
      {activeFilter[4]?
        <Select
          value={filters.bedStatus.map((bedStatus) => bedStatusOptions[bedStatus])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={"Lits"}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              bedStatus: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(bedStatusOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[5]?
        <Select
          value={filters.stayTypes.map((stayType) => stayTypeOptions[stayType])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder={t("all patients")}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              stayTypes: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(stayTypeOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[6]?
        <Select
          value={sortingOptions[filters.sorting]}
          className="selectJ1"
          styles={{ fontSize: 20 }}
          onChange={(option) =>
            setFilters((filters) => ({
              ...filters,
              sorting: option?.value,
            }))
          }
          options={Object.values(sortingOptions)}
        />:null}
      </div>
      <div>
        {activeFilter[7]?
        <Select
          value={anomalie[filters.anomalie]}
          className="selectJ1"
          styles={{ fontSize: 20 }}
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              anomalie: options?.value,
            }))
          }
          options={Object.values(anomalie)}
        />:null}
      </div>
    </div>
  );
}
