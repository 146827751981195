import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import Select from "react-select";

const ListStaff = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [globalStaffData, setGlobalStaffData] = useState(props.data);
  const [filterStaffData, setFilterStaffData] = useState([]);
  const [selectedOptionFuntions, setOptionSelectedFunctions] = useState({
    value: null,
    label: t("all functions"),
  });
  const [optionFunctions, setOptionFunctions] = useState(props.functions);

  useEffect(() => {
    setFilterStaffData(props.data);

    setOptionFunctions([{ value: null, label: t("all functions") }, ...optionFunctions]);
  }, []);

  useEffect(() => {
    let staffs = [];

    globalStaffData.map((staff) => {
      if (
        selectedOptionFuntions &&
        selectedOptionFuntions.value != null &&
        selectedOptionFuntions.value != staff.fonction
      )
        return;
      staffs = [...staffs, staff];
    });

    setFilterStaffData(staffs);
  }, [selectedOptionFuntions, globalStaffData]);

  const renderDataStaffs = () => {
    if (!filterStaffData) return null;

    return filterStaffData.map((staff, i) => (
      <tr key={i}>
        <td className="text-capitalize">{staff.jourDeLaSemaine}</td>
        <td className="text-capitalize">{staff.heure}</td>
        <td className="text-capitalize">{staff.plage}</td>
        <td className="text-capitalize">{staff.debut}</td>
        <td className="text-capitalize">{staff.fin}</td>
        <td className="text-capitalize">
          {staff.demoStaffAttributes && staff.demoStaffAttributes.nomPrenom.replace("|", "")}
        </td>
        <td className="text-capitalize">{staff.fonction}</td>
        <td className="text-capitalize">{staff.nom}</td>
        <td className="text-capitalize">{staff.prenom}</td>
        <td className="text-capitalize">{staff.competence == " " ? "-" : staff.competence}</td>
      </tr>
    ));
  };

  const renderTable = () => {
    return (
      <div className="overflow" style={{ marginTop: 10 }}>
        <table
          className="table table-translation table-striped table-bordered table-scroll"
          style={{ width: "100%" }}
        >
          <thead className="fix-header">
            <tr>
              <th className="header" style={{ width: "9%" }}>
                <span style={{ display: "block" }}>{t("days")}</span>
              </th>
              <th className="header" style={{ width: "6%" }}>
                <span style={{ display: "block" }}>{t("hour")}</span>
              </th>
              <th className="header" style={{ width: "9%" }}>
                <span style={{ display: "block" }}>{t("plage")}</span>
              </th>
              <th className="header" style={{ width: "9%" }}>
                <span style={{ display: "block" }}>{t("start date")}</span>
              </th>
              <th className="header" style={{ width: "9%" }}>
                <span style={{ display: "block" }}>{t("end date")}</span>
              </th>
              <th className="header" style={{ width: "12%" }}>
                <span style={{ display: "block" }}>{t("caregiver")}</span>
              </th>
              <th className="header" style={{ width: "11%" }}>
                <span style={{ display: "block" }}>{t("function")}</span>
              </th>
              <th className="header" style={{ width: "10%" }}>
                <span style={{ display: "block" }}>{t("first name")}</span>
              </th>
              <th className="header" style={{ width: "10%" }}>
                <span style={{ display: "block" }}>{t("last name")}</span>
              </th>
              <th className="header" style={{ width: "9%" }}>
                <span style={{ display: "block" }}>{t("skill")}</span>
              </th>
            </tr>
          </thead>
          <tbody>{renderDataStaffs()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-end flex-col"
            style={{ textAlign: "end", marginTop: 20, flexWrap: "wrap" }}
          >
            <div
              className="col-12 col-lg-3 text-left col-md-6 mrg-10 padding-0"
              style={{ zIndex: 5 }}
            >
              <Select
                value={selectedOptionFuntions}
                isSearchable={true}
                styles={{ fontSize: 20 }}
                placeholder={t("all functions")}
                className="selectJ1 form-select"
                isSearchable={false}
                onChange={(selectedOption) => setOptionSelectedFunctions(selectedOption)}
                options={optionFunctions}
              />
            </div>
          </div>
        </div>

        {filterStaffData ? (
          renderTable()
        ) : (
          <div
            className="row"
            style={{
              justifyContent: "center",
              minHeight: 250,
              alignItems: "flex-end",
            }}
          >
            <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
          </div>
        )}
      </div>
    </div>
  );
};
export default ListStaff;
