import React, { useState, useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import ReactLoading from "react-loading";
import axios from "axios";
import moment from "moment/min/moment-with-locales";
import { API } from "../../../utils/api";
import { connect } from "react-redux";

const AddDesedirata = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [load, setLoad] = useState(false);
  const [optionsType, setOptionsType] = useState(props.types);
  const [optionsDoctors, setOptionsDoctors] = useState(props.doctors);
  const [selectedOptionType, setOptionSelectedType] = useState({
    value: 0,
    label: t("types"),
  });
  const [selectedOptionDoctor, setOptionSelectedDoctor] = useState({
    value: 0,
    label: t("all doctors"),
  });
  const [optionsShift, setOptionsShift] = useState([
    { value: "day", key: "day", label: t("day") },
    { value: "night", key: "night", label: t("night") },
  ]);
  const [selectedOptionStartShift, setOptionSelectedStartShift] = useState({
    value: 0,
    key: "day / night shift",
    label: t("start shift"),
  });
  const [selectedOptionEndShift, setOptionSelectedEndShift] = useState({
    value: 0,
    key: "day / night shift",
    label: t("end shift"),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState([]);
  const [message, setMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [myDesideratas, setMyDesideratas] = useState(props.desideratas);
  const member_id = props.member_id;

  const addDesiderata = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    if (selectedOptionType.value === 0) {
      setErrorBorder(["choiceType"]);
      setShowError(true);
      setError({ type: "please select a type" });
      setTimeout(() => setError(false), 3000);
      return;
    }
    if (selectedOptionDoctor.value === 0) {
      setErrorBorder(["choiceDoctor"]);
      setShowError(true);
      setError({ type: "please select a doctor" });
      setTimeout(() => setError(false), 3000);
      return;
    }
    if (selectedOptionStartShift.value === 0) {
      setErrorBorder(["choiceStartShift"]);
      setShowError(true);
      setError({ type: "please select a shift day/night" });
      setTimeout(() => setError(false), 3000);
      return;
    }
    if (selectedOptionEndShift.value === 0) {
      setErrorBorder(["choiceEndShift"]);
      setShowError(true);
      setError({ type: "please select a shift day/night" });
      setTimeout(() => setError(false), 3000);
      return;
    }

    let desiderata = {
      startDate:
        selectedOptionStartShift.value === "day"
          ? moment(startDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(startDate).set("hour", 20).format("YYYY-MM-DD HH:mm"),
      endDate:
        selectedOptionEndShift.value === "day"
          ? moment(endDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(endDate).set("hour", 20).format("YYYY-MM-DD HH:mm"),
      description: description === "" ? "" : description,
      type_id: selectedOptionType.value == 0 ? null : selectedOptionType.value,
      startShift: selectedOptionStartShift.value,
      endShift: selectedOptionEndShift.value,
      member_id: selectedOptionDoctor.value == 0 ? null : selectedOptionDoctor.value,
      status: "in_progress",
    };
    let exist = myDesideratas.some(
      (plan) =>
        moment(desiderata.startDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        moment(desiderata.endDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        (moment(plan.startDate).isSameOrAfter(moment(desiderata.startDate).format("YYYY-MM-DD")) &&
          moment(plan.endDate).isSameOrAfter(moment(desiderata.startDate).format("YYYY-MM-DD")) &&
          moment(plan.startDate).isSameOrBefore(moment(desiderata.endDate).format("YYYY-MM-DD")) &&
          moment(plan.endDate).isSameOrBefore(moment(desiderata.endDate).format("YYYY-MM-DD"))),
    );
    if (exist) {
      setShowError(true);
      setError({ date: "the date you have selected is already booked, please enter another one" });
      setTimeout(() => setError(false), 4000);
      return;
    }

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_PLANNING}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderata,
    })
      .then((response) => {
        setShowMessage(true);
        setMessage({ add: "the desiderata was added successfully." });
        onCancel();
        setTimeout(() => setShowMessage(false), 3000);
        props.addDesiderata(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            setError(errors);
          } else if (error.response.status === "401") {
            setError({ date: "impossible to add this desiderata" });
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const onCancel = () => {
    setEndDate(null);
    setStartDate(null);
    setOptionSelectedType(optionsType[0]);
    setDescription("");
  };
  return (
    <div className="row" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("add desiderata")}</h1>
        <div className="row">
          <div className="col-lg-6  col-md-9 col-12" style={{ margin: "0 auto" }}>
            <form onSubmit={addDesiderata} className="form-user-new">
              <div style={{ textAlign: "center", height: "50px" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err, i) => (
                    <div key={i} className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message && showMessage ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the desiderata has been added successfully.")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group select">
                <Select
                  value={selectedOptionDoctor}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceDoctor") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionDoctor) => setOptionSelectedDoctor(selectedOptionDoctor)}
                  options={optionsDoctors}
                />
                <br />
                <Select
                  value={selectedOptionType}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceType") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionType) => setOptionSelectedType(selectedOptionType)}
                  options={optionsType}
                />
              </div>
              <div
                className="form-group inline-block col-md-12 form-desiderata"
                style={{ display: "inline-flex" }}
              >
                <DatePicker
                  style={{ borderColor: "" }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  isClearable
                  placeholderText={t("from")}
                  dateFormat="dd/MM/yyyy"
                />
                <Select
                  value={selectedOptionStartShift}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceStartShift") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionStartShift) =>
                    setOptionSelectedStartShift(selectedOptionStartShift)
                  }
                  options={optionsShift}
                />
              </div>

              <div
                className="form-group inline-block col-md-12 form-desiderata"
                style={{ display: "inline-flex" }}
              >
                <DatePicker
                  selected={endDate}
                  isClearable
                  placeholderText={t("to")}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={startDate}
                />
                <Select
                  value={selectedOptionEndShift}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceEndShift") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionEndShift) =>
                    setOptionSelectedEndShift(selectedOptionEndShift)
                  }
                  options={optionsShift}
                />
              </div>
              <div className="form-group">
                <textarea
                  class="form-control w-m-95"
                  id="description"
                  rows="3"
                  placeholder={t("description")}
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  onClick={() => props.setPage("listDesideratas")}
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(AddDesedirata);
