import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import AddPost from "./addPost";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import { ChromePicker } from "react-color";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import { Capitalize } from "../../../utils/functions";

const ListPostes = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listpost");
  const [globalPostsData, setGlobalPostsData] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [color, setColor] = useState("#fff");
  const [abbreviation, setAbbreviation] = useState("");
  const [abbreviationCategory, setAbbreviationCategory] = useState("");
  const [duration, setDuration] = useState();
  const [denomination, setDenomination] = useState("");
  const [daysChecked, setDaysChecked] = useState([]);
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [optionCategories, setOptionCategories] = useState([]);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({
    value: 0,
    label: t("all categories").toUpperCase(),
  });
  const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLCATEGORIES}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        loadingOptions(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  useEffect(() => {
    if (selectedCategoryOption.value !== 0) {
      setAbbreviationCategory(selectedCategoryOption.abbreviationCategory);
    }
  }, [selectedCategoryOption, optionCategories]);

  const loadingOptions = (categories) => {
    let optionCategories = [];
    categories.map((category) => {
      optionCategories.push({
        value: category.index,
        label: category.denomination.toUpperCase(),
        abbreviationCategory: category.abreviation,
      });
    });
    setOptionCategories(optionCategories);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLPOSTS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalPostsData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const submit = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this post?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deletePost(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deletePost = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETEPOST}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let data = globalPostsData.filter((t) => t.index !== id);
        setShowMessage(true);
        setMessage({ edit: "the post was deleted successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalPostsData(data);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
      });
  };
  const updatePost = (post) => {
    let newPost, category, availablity;
    newPost = {
      index: post.index,
      denomination: denomination === "" ? post.denomination : denomination,
      abreviation: abbreviation === "" ? post.abreviation : abbreviation,
      couleur: color,
      duree: duration === "" ? (post.duree === null ? "" : post.duree) : duration,
      category_id: selectedCategoryOption.value,
    };
    availablity = {
      index: post.Availablity && post.Availablity.index,
      monday: daysChecked.includes("monday") ? 1 : 0,
      tuesday: daysChecked.includes("tuesday") ? 1 : 0,
      wednesday: daysChecked.includes("wednesday") ? 1 : 0,
      thursday: daysChecked.includes("thursday") ? 1 : 0,
      friday: daysChecked.includes("friday") ? 1 : 0,
      saturday: daysChecked.includes("saturday") ? 1 : 0,
      sunday: daysChecked.includes("sunday") ? 1 : 0,
      member_id: null,
      post_id: post.index,
    };

    if (
      (post.Categories && post.Categories.abreviation !== abbreviationCategory) ||
      selectedCategoryOption.value !== 0
    ) {
      category = {
        index: selectedCategoryOption.value !== 0 && selectedCategoryOption.value,
        denomination: selectedCategoryOption.value === 0 ? "-" : selectedCategoryOption.label,
        abreviation: selectedCategoryOption.value === 0 ? "-" : abbreviationCategory,
      };
    }
    axios({
      method: "put",
      url: `${API.ENDPOINT + API.RH_POST}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: { newPost, category, availablity },
    })
      .then((response) => {
        let posts = [];

        globalPostsData.map((p) => {
          if (p.index === post.index) {
            posts = [...posts, response.data];
            return;
          }
          posts = [...posts, p];
        });

        setShowMessage(true);
        setMessage({ edit: "the post was successfully modified." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalPostsData(posts);
        onCancel();
      })
      .catch((error) => {
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const setPostAvailibility = (post) => {
    let days = [];
    post.Availablity &&
      Object.entries(post.Availablity).map((day, value) => {
        if (day[1] === 1 && day[0] !== "index") days = [...days, day[0]];
      });
    setDaysChecked(days);
  };

  const onEdit = (post) => {
    setInEditMode({
      status: true,
      rowKey: post.index,
    });
    setColor(post.couleur);
    setDenomination(post.denomination);
    setAbbreviation(post.abreviation);
    setDuration(post.duree);
    setPostAvailibility(post);
    post.Categories &&
      setSelectedCategoryOption({
        value: post.Categories.index,
        label: post.Categories.denomination,
      });
    setAbbreviationCategory(post.Categories && post.Categories.abreviation);

    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setDuration("");
    setColor("#fff");
    setAbbreviation("");
    setAbbreviationCategory("");
    setDenomination("");
    setSelectedCategoryOption({ value: 0, label: t("choose category") });

    setIsDisable(false);
  };
  const handleCheckBox = (e) => {
    let days;
    if (e.target.checked) {
      days = [...daysChecked, e.target.id];
    } else {
      days = daysChecked.filter((day) => day !== e.target.id);
    }
    if (days.length == 0) {
      days = [daysOfWeek[0]];
    }

    setDaysChecked(days);
  };
  const addPost = (post) => {
    let posts = [...globalPostsData, post];
    setGlobalPostsData(posts);
  };

  const renderListPosts = () => {
    if (!globalPostsData) return null;

    return globalPostsData.map((post, index) => (
      <tr key={index}>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <Select
              value={selectedCategoryOption}
              className="selectJ1 form-select"
              isSearchable={false}
              onChange={(selectedCategoryOption) =>
                setSelectedCategoryOption(selectedCategoryOption)
              }
              options={optionCategories}
            />
          ) : post.Categories ? (
            Capitalize(post.Categories.denomination)
          ) : (
            "-"
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <input
              value={abbreviationCategory}
              required
              className="form-control"
              onChange={(event) => setAbbreviationCategory(event.target.value)}
            />
          ) : post.Categories ? (
            post.Categories.abreviation
          ) : (
            "-"
          )}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <input
              value={denomination}
              required
              className="form-control"
              onChange={(event) => setDenomination(event.target.value)}
            />
          ) : (
            Capitalize(post.denomination)
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <input
              value={abbreviation}
              required
              className="form-control"
              onChange={(event) => setAbbreviation(event.target.value)}
            />
          ) : (
            post.abreviation
          )}
        </td>
        <td
          className={`${inEditMode.status && inEditMode.rowKey === post.index ? "" : "padding-0"} `}
          bgcolor={inEditMode.status && inEditMode.rowKey === post.index ? "" : post.couleur}
        >
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <div className="color-picker">
              <div
                className="d-flex justify-content-between w-100"
                style={showColorPicker ? { marginBottom: 10 } : {}}
              >
                <span className="bg-color-picker" style={{ backgroundColor: color }}></span>
                <button
                  type="button"
                  className="btn-color-picker"
                  onClick={() => setshowColorPicker((showColorPicker) => !showColorPicker)}
                >
                  {showColorPicker ? t("apply the choice") : t("pick up a color")}
                </button>
              </div>
              {showColorPicker && (
                <ChromePicker
                  color={color}
                  style={{ marginTop: 5 }}
                  onChange={(updateColor) => setColor(updateColor.hex)}
                />
              )}
            </div>
          ) : (
            <div style={{ backgroundColor: `${post.couleur}` }}></div>
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <input
              value={duration}
              required
              className="form-control"
              onChange={(event) => setDuration(event.target.value)}
            />
          ) : (
            post.duree
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === post.index
            ? daysOfWeek.map((day, i) => {
                return (
                  <div
                    style={{ display: "flex" }}
                    className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox"
                    key={i}
                  >
                    <input
                      onChange={handleCheckBox}
                      type="checkbox"
                      checked={daysChecked.includes(day) ? true : false}
                      className="custom-control-input"
                      id={day}
                    />
                    <label style={{ fontSize: 16 }} className="custom-control-label" htmlFor={day}>
                      {t(day.charAt(0))}
                    </label>
                  </div>
                );
              })
            : post.Availablity && post.Availablity.post_id == post.index
            ? daysOfWeek.map((day) => {
                return (
                  post.Availablity[day] === 1 && (
                    <>
                      <span className="label primary">{t(day)}</span>
                      <br />
                    </>
                  )
                );
              })
            : "-"}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === post.index ? (
            <>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => updatePost(post)}>
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEdit(post)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => submit(post.index)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };

  if (page === "listpost") {
    return (
      <div className="row">
        <div className="container-user">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end flex-col"
              style={{ textAlign: "end", marginTop: 20 }}
            >
              <a
                onClick={() => setPage("addpost")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("add post")}
              </a>
            </div>
          </div>
          <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
          <div className="overflow" style={{ marginTop: 10 }}>
            {load && globalPostsData ? (
              <table
                className="table table-translation table-striped table-bordered table-scroll"
                style={{ width: "100%" }}
              >
                <thead className="fix-header">
                  <tr>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("denomination of category")}</span>
                    </th>
                    <th className="header" style={{ width: "10%" }}>
                      <span style={{ display: "block" }}>{t("abbreviation of category")}</span>
                    </th>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("denomination of post")}</span>
                    </th>
                    <th className="header" style={{ width: "10%" }}>
                      <span style={{ display: "block" }}>{t("abbreviation of post")}</span>
                    </th>
                    <th className="header" style={{ width: "13%" }}>
                      <span style={{ display: "block" }}> {t("colour of post")}</span>
                    </th>
                    <th className="header" style={{ width: "10%" }}>
                      <span style={{ display: "block" }}>{t("duration post")}</span>
                    </th>
                    <th className="header" style={{ width: "14%" }}>
                      <span style={{ display: "block" }}>{t("availability")}</span>
                    </th>
                    <th className="header" style={{ width: "8%" }}>
                      <span style={{ display: "block" }}>{t("action")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderListPosts()}</tbody>
              </table>
            ) : (
              <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <AddPost addPost={addPost} setPage={setPage} categories={optionCategories} />;
  }
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListPostes);
