import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenMember from "../screenRhMembers/listMembers";
import ScreenPosts from "./listPostes";
import ScreenTypes from "./listTypes";
import ScreenPlanning from "./Plannings";
import ScreenCategories from "./listCategories";
import ScreenPostAvaibility from "../screenRhAssignment/PostsAvaibility";
import { useTranslation } from "react-i18next";

const SwitchMenuLifeLine = (props) => {
  const { t, i18n } = useTranslation(["rh"]);

  const [headerBnt, setHeaderBnt] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  function renderScreen(newPage) {
    if (props.functions && props.status) {
      genPageContent(newPage);
      genHeaderBnt(newPage);
    }
  }

  function genPageContent(page) {
    switch (page) {
      case "lifeline":
        setPageContent(<ScreenPlanning functions={props.functions} status={props.status} />);
        return;
      case "member":
        setPageContent(<ScreenMember status={props.status} functions={props.functions} />);
        return;
      case "types":
        setPageContent(<ScreenTypes />);
        return;
      case "postes":
        setPageContent(<ScreenPosts />);
        return;
      case "categories":
        setPageContent(<ScreenCategories />);
        return;
      case "post_avability":
        setPageContent(<ScreenPostAvaibility setPage={renderScreen} />);
        return;
      default:
        return null;
    }
  }

  function genHeaderBnt(page) {
    let newHeaderBnt = [];
    switch (page) {
      case "member":
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("lifeline")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("manage planning")}
          </a>,
        );
        break;
      case "post_avability":
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("lifeline")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("manage planning")}
          </a>,
        );
        break;
      default:
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("member")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("manage members")}
          </a>,
        );
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("types")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("types")}
          </a>,
        );
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("postes")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("postes")}
          </a>,
        );
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("categories")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("categories")}
          </a>,
        );
        newHeaderBnt.push(
          <a
            onClick={() => renderScreen("post_avability")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("Post_Avaibility")}
          </a>,
        );
        break;
    }
    setHeaderBnt(newHeaderBnt);
  }

  useEffect(() => {
    renderScreen("lifeline");
  }, []);

  return (
    <div className="main-screen container-planning-member">
      <div
        className="w-100 d-flex justify-content-start"
        style={{ textAlign: "end", marginTop: 20, flexWrap: "wrap" }}
      >
        {headerBnt}
      </div>
      <div>{pageContent}</div>
    </div>
  );
};

export default SwitchMenuLifeLine;
