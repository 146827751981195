import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API } from "../utils/api";

export function useRequest() {
  const token = useSelector((state) => state.activeUser.user.token);

  return async function request({ method, data, params, path, responseType }) {
    let response;
    try{
      response = await axios({
        method,
        data,
        params,
        url: `${API.ENDPOINT}${path ?? "/"}`,
        headers: {
          "x-auth-token": token,
        },
        responseType,
      });
    }
    catch (e){
      return null;
    } 

    return [response.data, response];
  };
}

export function useApi({ method, endpoint, data, params }, deps) {
  const [atempt, setAtempt] = useState(1);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = useSelector((state) => state.activeUser.user.token);

  useEffect(
    function () {
      const abortController = new AbortController();

      setLoading(true);

      axios({
        method,
        data,
        params,
        url: `${API.ENDPOINT}${endpoint}`,
        headers: {
          "x-auth-token": token,
        },
        signal: abortController.signal,
      })
        .then(function (response) {
          setError(null);
          setResponse(response);
        })
        .catch(function (error) {
          setResponse(null);
          setError(error);
        })
        .finally(function () {
          setLoading(false);
        });

      return () => abortController.abort();
    },
    [atempt, ...deps],
  );

  return {
    data: response?.data ?? null,
    error,
    loading,
    retry() {
      setAtempt((n) => n + 1);
    },
  };
}

const wait = (t) => new Promise((r) => setTimeout(r, t));

export function useApiAction({ method, endpoint }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = useSelector((state) => state.activeUser.user.token);

  async function action({ params, data }) {
    setLoading(true);
    try {
      const [result] = await Promise.all([
        axios({
          method,
          data,
          params,
          url: `${API.ENDPOINT}${endpoint}`,
          headers: {
            "x-auth-token": token,
          },
        }),
        wait(1500),
      ]);

      setError(null);
      return result;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return [
    action,
    {
      error,
      loading,
    },
  ];
}
