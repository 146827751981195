/**
 * @ By: Theo Bensaci
 * @ Date: 11:10:25 27.06.2022
 * @ Description: Screen Emerger stats
 */

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment/min/moment-with-locales";
import LineArea from "../../../components/chartV2/lineArea";
import BarChart from "../../../components/chartV2/barChart";
import { D3Export } from "../../../d3/d3Component";
import { SankeyData } from "../../../d3/MySankey/SankeyData";
import Select from "react-select";
import { contains } from "jquery";

const ScreenEmergerStats = (props) => {
  //#region variable
  const { t, i18n } = useTranslation("cockpit");
  const [isLoadingEmerge, setIsLoadingEmerge] = useState(false);
  const [isLoadingBjml, setIsLoadingBjml] = useState(false);
  const [isLoadingEmergeHospitByUf, setIsLoadingEmergeHospitByUf] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const optionDate = useRef({ start: null, end: null });

  const [emegerStatsGraphs, setEmegerStatsGraphs] = useState(null);
  const [selectBjml, setSelectbjml] = useState("UwU");
  const [selectBjmlOption, setSelectBjmlOption] = useState(null);
  const [selectBjmlYear, setSelectBjmlYear] = useState(null);
  const [selectBjmlOptionYear, setSelectBjmlOptionYear] = useState(null);
  const [selectedBjmlData, setSelectedBjmlData] = useState(null);
  const [selectedTimeBjmlData, setSelectedTimeBjmlData] = useState(null);
  const [graphDataEmergeHospitByUf, setGraphDataEmergeHospitByUf] = useState(null);
  const dataEmergeStats = useRef();
  const dataEmergeBjml = useRef();
  const dataEmergeHospitByUf = useRef();
  const actualSort = useRef("dateAsc");
  const samplingOptions = [
    { value: "d", label: t("by day") },
    { value: "w", label: t("by week") },
    { value: "m", label: t("by month") },
    { value: "y", label: t("by year") },
  ];
  const [samplingSelectOption, setSamplingSelectOption] = useState(samplingOptions[0]);
  const [isUrgenceZSTCD, setIsUrgenceZSTCD] = useState(true);

  // sankey
  const [isLoadingSankey, setIsLoadingSankey] = useState(false);
  const [sankeyFluxUrgData, setSankeyFluxUrgData] = useState(null);
  const [sankeyHospSpeData, setSankeyHospSpeData] = useState(null);
  const [sankey_urgences_entry_discharge_types, setSankey_urgences_entry_discharge_types] = useState(null);
  const [sankeyFluxUrgColorPalette, setSankeyFluxUrgColorPalette] = useState(null);

  // sampling
  const ListOfSubArray = [
    "Admissions",
    "Hospitalisations",
    "Sorties_de_soins",
    "Admissions_des_seniors",
    "Hospitalisations_des_seniors",
    "Sorties_de_soins_des_seniors",
    "tension_max"
  ];

  const emegerStatsGraphsOption = [
    [{ value: "", label: t("-") }],
    [
      { value: "", label: t("-") },
      { value: "_URGENCES_SMUR_POMPIERS_AMB", label: t("URGENCES SMUR POMPIERS AMB") },
      { value: "_URGENCE_DOMICILE", label: t("URGENCE DOMICILE") },
      { value: "_Inconue", label: t("inconue") },
      { value: "_URGENCE_ACCID_DU_TRAVAIL", label: t("URGENCE ACCID DU TRAVAIL") },
      { value: "_URGENCE_ACCID_VOIE_PUBLIQUE", label: t("URGENCE ACCID VOIE PUBLIQUE") },
      { value: "_TRANSFERT_AUTRE_CH", label: t("TRANSFERT AUTRE CH") },
      { value: "_HEBERGEMENT_MEDICO_SOCIALE", label: t("HEBERGEMENT MEDICO SOCIALE") },
    ],
    [
      { value: "", label: t("-") },
      { value: "_transfert", label: t("transfert") },
      { value: "_retour", label: t("retour") },
      { value: "_deces", label: t("deces") },
      { value: "_maison_medicale_de_garde", label: t("maison medicale de garde") },
    ],
    [{ value: "", label: t("-") }],
    [
      { value: "", label: t("-") },
      { value: "_URGENCES_SMUR_POMPIERS_AMB", label: t("URGENCES SMUR POMPIERS AMB") },
      { value: "_URGENCE_DOMICILE", label: t("URGENCE DOMICILE") },
      { value: "_Inconue", label: t("inconue") },
      { value: "_URGENCE_ACCID_DU_TRAVAIL", label: t("URGENCE ACCID DU TRAVAIL") },
      { value: "_URGENCE_ACCID_VOIE_PUBLIQUE", label: t("URGENCE ACCID VOIE PUBLIQUE") },
      { value: "_TRANSFERT_AUTRE_CH", label: t("TRANSFERT AUTRE CH") },
      { value: "_HEBERGEMENT_MEDICO_SOCIALE", label: t("HEBERGEMENT MEDICO SOCIALE") },
    ],
    [
      { value: "", label: t("-") },
      { value: "_transfert", label: t("transfert") },
      { value: "_retour", label: t("retour") },
      { value: "_deces", label: t("deces") },
      { value: "_maison_medicale_de_garde", label: t("maison medicale de garde") },
    ],
    [
      { value: "_0", label: t("0h - 6h") },
      { value: "_6", label: t("6h - 12h") },
      { value: "_12", label: t("12h - 18h") },
      { value: "_18", label: t("18h - 0h") },

    ],
  ];

  const emegerStatsGraphsSelectOption = useRef([]);

  //#endregion

  function genChart() {
    let t = [];
    for (let index = 0; index < 5; index++) {
      t.push(
        <div className="lineChart">
          <LineArea />
        </div>,
      );
    }
    setEmegerStatsGraphs(t);
  }

  //#region Api Function

  /**
   * request emerge Stats data to api
   */
  function requestEmergeStatsData() {
    setIsLoadingEmerge(true);

    let endDte = endDate != null ? moment(endDate) : moment();
    let srtDte = startDate != null ? moment(startDate) : moment(endDte).subtract(6, "months");

    // request
    axios
      .post(
        `${API.ENDPOINT + API.SCREEN_EMERG_STATS_BY_DATE}`,
        {
          startDate: new Date(srtDte.format("YYYY-MM-DD")),
          endDate: new Date(endDte.format("YYYY-MM-DD")),
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        console.log(res.data);
        dataEmergeStats.current = res.data;

        for (let index = 0; index < ListOfSubArray.length; index++) {
          emegerStatsGraphsSelectOption.current[index] = emegerStatsGraphsOption[index][0];
        }

        filtrerEmegerStats();
        setIsLoadingEmerge(false);
        requestEmergeBjml();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  /**
   * request emerge bjml data to api
   */
  function requestEmergeBjml() {
    setIsLoadingBjml(true);
    setIsLoadingEmergeHospitByUf(true);
    // request
    axios
      .get(`${API.ENDPOINT + API.SCREEN_EMERG_BJML}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        dataEmergeBjml.current = res.data;
        // set up all option
        let options = [];
        for (const iterator of dataEmergeBjml.current) {
          if (options.length == 0 || !options.some((e) => e.label === iterator.group))
            options.push({ value: iterator.group, label: t(iterator.group) });
        }
        options.sort((a, b) => a.label.localeCompare(b.label));

        setSelectBjmlOption(options);
        setSelectbjml(options[0]);

        let year = genYearOption(res.data);

        generateBjmlBoxPlotGraph(options[0].value);
        console.log(year);
        generateTimeBjmlGraph(options[0].value, year.value);

        setIsLoadingBjml(false);
        requestEmergeFlow();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function requestEmergeHospitByUf() {
    // request
    axios
      .get(`${API.ENDPOINT + API.SCREEN_EMERG_HOSPIT_BY_UF}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        dataEmergeHospitByUf.current = res.data;
        generateEmergeHospitByUfGraphData();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function requestEmergeFlow() {
    setIsLoadingSankey(true);
    // request
    axios
      .get(`${API.ENDPOINT + API.SCREEN_EMERG_FLOW}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let data = res.data;
        let sankeyFluxUrgData = genSankeyGraphData(
          data.filter((el) => {
            return el.graph == "flux_urgences";
          }),
        );
        setSankeyFluxUrgData(sankeyFluxUrgData);
        setSankeyFluxUrgColorPalette(genSankeyColorPalet(sankeyFluxUrgData));
        setSankeyHospSpeData(
          genSankeyGraphData(
            data.filter((el) => {
              return el.graph == "hospitalisations_par_specialites";
            }),
          ),
        );

        setSankey_urgences_entry_discharge_types(
          genSankeyGraphData(
            data.filter((el) => {
              return el.graph == "flux_urgences_entry_discharge_types";
            }),
          ),
        );
        setIsLoadingSankey(false);
        requestEmergeHospitByUf();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function generateBjmlGraph(groupName) {
    //#region Constance

    const daySorter = {
      Lundi: 1,
      Mardi: 2,
      Mercredi: 3,
      Jeudi: 4,
      Vendredi: 5,
      Samdedi: 6,
      Samedi: 6,
      Dimanche: 7,
    };
    let year = (new Date().getFullYear() - 1).toString();
    //#endregion
    let data = dataEmergeBjml.current.filter((a) => {
      return a.group == groupName && a.year == year;
    });

    let dataBurte = [];
    let label = [];

    for (const iterator of data) {
      dataBurte.push(iterator.BJML);
      label.push(iterator.jour);
    }

    label.sort((a, b) => {
      return daySorter[a] - daySorter[b];
    });

    for (let index = 0; index < label.length; index++) {
      label[index] = t(label[index]);
    }
    setSelectedBjmlData({
      labels: label,
      datasets: [
        {
          data: dataBurte,
          label: t("bjml"),
          backgroundColor: "rgb(68, 160, 199)",
        },
      ],
    });
  }

  /**
   * Actualise year option
   * @param {BJML} data bjml to use for get year range
   * @returns actual year
   */
  function genYearOption(data = null) {
    let actualYear = new Date().getFullYear() - 1;
    let startYear = actualYear - 2;
    if (data != null) {
      for (const iterator of data) {
        if (iterator.year.match(/^\d*$/)) {
          let date = parseInt(iterator.year, 10);
          startYear = !isNaN(date) && startYear > iterator.year ? iterator.year : startYear;
        }
      }
    }

    let year = [];
    for (let index = 0; index <= actualYear - startYear; index++) {
      let val = (actualYear - index).toString();
      year.push({ value: val, label: val });
    }
    setSelectBjmlOptionYear(year);
    setSelectBjmlYear(year[0]);
    return year[0];
  }

  /**
   * Generate data for boxplot bjml
   * @param {*} groupName
   */
  function generateBjmlBoxPlotGraph(groupName) {
    // update year option
    genYearOption(
      dataEmergeBjml.current.filter((a) => {
        return a.group == groupName;
      }),
    );

    //#region Constance

    const daySorter = {
      Lundi: 1,
      Mardi: 2,
      Mercredi: 3,
      Jeudi: 4,
      Vendredi: 5,
      Samdedi: 6,
      Samedi: 6,
      Dimanche: 7,
    };
    let year = "12_dernier_mois"; // wtf, mais c'est quoi cette valeur (c'est sensé être une anné), la fameuse année "12 dernier mois"
    //#endregion
    let data = dataEmergeBjml.current.filter((a) => {
      return a.group == groupName && a.year == year;
    });

    let newData = [];

    for (const iterator of data) {
      let q1 = iterator.quantile_25;
      let q3 = iterator.quantile_75;
      let median = iterator.median;
      let calmin = iterator.minimum;
      let calmax = iterator.maximum;

      newData.push(createBoxPlotItem(iterator.jour, q1, median, q3, calmin, calmax));
    }

    newData.sort((a, b) => {
      return daySorter[a.key] - daySorter[b.key];
    });

    for (const iterator of newData) {
      iterator.key = t(iterator.key);
    }

    setSelectedBjmlData(newData);
  }

  function generateTimeBjmlGraph(groupName, year) {
    //#region Constance
    const daySorter = {
      Lundi: 1,
      Mardi: 2,
      Mercredi: 3,
      Jeudi: 4,
      Vendredi: 5,
      Samdedi: 6,
      Samedi: 6,
      Dimanche: 7,
    };
    //#endregion
    let data = dataEmergeBjml.current.filter((a) => {
      return a.group == groupName && a.year == year;
    });

    let newData = [];

    for (const iterator of data) {
      let q1 = iterator.quantile_25;
      let q3 = iterator.quantile_75;
      let median = iterator.median;
      let calmin = iterator.minimum;
      let calmax = iterator.maximum;

      newData.push(createBoxPlotItem(iterator.jour, q1, median, q3, calmin, calmax));
    }

    newData.sort((a, b) => {
      return daySorter[a.key] - daySorter[b.key];
    });

    for (const iterator of newData) {
      iterator.key = t(iterator.key);
    }

    setSelectedTimeBjmlData(newData);
  }

  function generateEmergeHospitByUfGraphData(useUrgenceZSTCD = true) {
    let label = [];
    let data = [];
    for (const iterator of dataEmergeHospitByUf.current) {
      if (!useUrgenceZSTCD && iterator.uf_name == "URGENCES  ZSTCD") continue;
      label.push(t(iterator.uf_name));
      data.push(iterator.count);
    }
    setGraphDataEmergeHospitByUf({
      labels: label,
      datasets: [
        {
          data: data,
          label: t("EmergeHospitByUfGraph"),
          backgroundColor: "rgb(68, 160, 199)",
        },
      ],
    });
    setIsLoadingEmergeHospitByUf(false);
  }

  /**
   * Filtre les donner emeger stats pour prendre uniquement les donner entre les deux date
   * @param {string} filter filtrer de donné
   * @param {*} newSrtDate
   * @param {*} newEndDate
   * @returns void
   */
  function filtrerEmegerStats(newSrtDate = null, newEndDate = null) {
    if (dataEmergeStats.current == null) {
      requestEmergeStatsData();
      return;
    }

    let endDte = newEndDate != null ? moment(newEndDate) : moment();
    let srtDte = newSrtDate != null ? moment(newSrtDate) : moment(endDte).subtract(6, "months");

    optionDate.current = { start: srtDte, end: endDte };

    const newData = dataEmergeStats.current;
    // filtre data by date (change soon, because loading all data is obviusly not a good idea)
    let filltredData = newData.filter((i) => {
      return moment(i.date).isAfter(srtDte) && moment(i.date).isBefore(endDte);
    });

    // sort data by date because idk why isnt the case before
    filltredData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    // generate graph
    generateEmegerStatsGraphs(
      emegerStatsDataSampling(
        filltredData,
        samplingSelectOption != null ? samplingSelectOption.value : null,
      ),
    );
  }

  /**
   * Sampling data
   * there is 3 type :
   * 'd' = pare day
   * 'w' = pare week
   * 'm' = pare month
   * 'y' = pare year
   * @param {char} type
   */
  function emegerStatsDataSampling(data, type) {
    if (data == null || data.length == 0) return [];

    if (type == "w" || type == "m" || type == "y") {
      let typeCorr = type.toUpperCase();
      let newDataSampl = [];
      let index = 0;
      let isOk = false;

      while (!isOk) {
        let i = 0;
        let item = { ...data[index] };
        let ref = { refPoint: "", refDate: "" };
        let isFinish = false;

        ref.refPoint = moment(item.date).add(1, type).format(typeCorr);
        ref.refDate = item.date;

        let filltredDataProps = Object.getOwnPropertyNames(item);

        while (!isFinish) {
          i++;
          let newItem = data[index + i];

          // add value
          for (const iterator of filltredDataProps) {
            if (parseInt(item[iterator]) && newItem[iterator] != null) {
              item[iterator] = parseInt(item[iterator]) + parseInt(newItem[iterator]);
            }
          }
          isFinish =
            ref.refPoint != moment(newItem.date).add(1, type).format(typeCorr) ||
            index + i >= data.length - 1;
        }

        // divide
        for (const iterator of filltredDataProps) {
          if (parseInt(item[iterator])) {
            item[iterator] = parseInt(item[iterator]) / i;
          }
        }

        item.date = ref.refDate;

        index += i;
        newDataSampl.push(item);

        isOk = index >= data.length - 1;
      }
      return newDataSampl;
    } else {
      return data;
    }
  }
  //#endregion

  //#region Chart
  function generateEmegerStatsGraphs(data) {

    console.log("-----------");
    console.log(data);


    //#region Constance
    const baseDataSet = {
      fill: true,
      borderWidth: 2,
      pointRadius: 0,
      pointStyle: "rectRot",
    };
    const DataSet_0 = {
      label: t("0h-6h"),
      borderColor: "#FA3119",
      backgroundColor: "#FA3119",
    };
    const DataSet_6 = {
      label: t("6h-12h"),
      borderColor: "#FAAD19",
      backgroundColor: "#FAAD19",
    };
    const DataSet_12 = {
      label: t("12h-18h"),
      borderColor: "#00EFFA",
      backgroundColor: "#00EFFA",
    };
    const DataSet_18 = {
      label: t("18h-0h"),
      borderColor: "#0C71FA",
      backgroundColor: "#0C71FA",
    };

    const DataSetColLab=[
      {
        label: t("0h-6h"),
        borderColor: "#FA3119",
        backgroundColor: "#FA3119",
      },
      {
        label: t("6h-12h"),
        borderColor: "#FAAD19",
        backgroundColor: "#FAAD19",
      },
      {
        label: t("12h-18h"),
        borderColor: "#00EFFA",
        backgroundColor: "#00EFFA",
      },
      {
        label: t("18h-0h"),
        borderColor: "#0C71FA",
        backgroundColor: "#0C71FA",
      }
    ]

    /*
        for (const iterator of ListOfSubArray) {
            subArray.push(iterator+filter.value);
        }*/

    const HoursSubtiviser = 6;
    const newData = data;
    console.log(newData);

    //#endregion

    let listGraphs = [];
    let label = [];

    // generate label
    for (let index = 0; index < newData.length; index++) {
      label.push(moment(newData[index].date).format("YYYY-MM-DD"));
    }

    // create data for all of graph
    for (const name of ListOfSubArray) {
      //#region get select data
      let value;
      let option;
      let onChangeFunction;

      for (let index = 0; index < ListOfSubArray.length; index++) {
        if (ListOfSubArray[index] == name) {
          value = emegerStatsGraphsSelectOption.current[index];
          option = emegerStatsGraphsOption[index];
          onChangeFunction = (value) => {
            emegerStatsGraphsSelectOption.current[index] = value;
          };
        }
      }
      //#endregion

      let subData = [[], [], [], []];
      for (const iterator of newData) {
        let name_sample=name + value.value;



        // si le name est déja une donné, l'afficher en simple et pas en empiler
        if(iterator[name_sample]!=undefined){
          let i = 0;

          // si le name se termine par un nombre genre 0,6,12,18, c'est qu'il s'agit d'une tranche horrair, donc l'afficher avec la bonne couleur, 0 = 0-6, 6 = 6-12, 2 = 12 = 12-18, 8 = 18 = 18-0
          // je sais que c'est une douille, mais j'ai pas trop le temps de le faire proprement
          switch(name_sample[name_sample.length-1]){
            case "6":
              i=1;
              break;
            case "2":
              i=2;
              break;
            case "8":
              i=3
              break;
          }
          subData[i].push(parseFloat(iterator[name_sample]));
        }
        else{
          for (let index = 0; index < subData.length; index++) {
            // empile data
            let n = 0;
            for (let i = 0; i <= index; i++) {
              n += parseFloat(iterator[name_sample + "_" + i * HoursSubtiviser]);
            }
            subData[index].push(n);
          }
        }
      }

      let dataSet=[];

      for (let index = 0; index < subData.length; index++) {
        if(subData[index]==[])continue;
        dataSet.push(
          {
            ...baseDataSet,
            ...DataSetColLab[index],
            data: subData[index],
          },
        )
      }

      listGraphs.push(
        <div className="lineChart">
          <h3>{t(name.replaceAll("_", " "))}</h3>
          <div id="option">
            {option.length < 2 ? null : (
              <Select
                value={value}
                classNamePrefix="selectJ-S11"
                isSearchable={false}
                onChange={(d) => {
                  onChangeFunction(d);
                  filtrerEmegerStats(optionDate.current.start, optionDate.current.end);
                }}
                options={option}
              />
            )}
          </div>
          <LineArea
            data={{
              labels: label,
              datasets: dataSet,
            }}
          />
        </div>,
      );
    }
    setEmegerStatsGraphs(listGraphs);
  }
  //#endregion

  //#region Box Plot

  /**
   * Create a boc plot data item
   * @param {string} key key of the box plot
   * @param {number} q1 box min value
   * @param {number} median box median
   * @param {number} q3 box max value
   * @param {number} min line min value
   * @param {number} max line max value
   * @returns
   */
  function createBoxPlotItem(key, q1, median, q3, min, max) {
    return {
      key: key,
      q1: q1,
      median: median,
      q3: q3,
      min: min,
      max: max,
    };
  }

  function genFakeBoxPoltData(number, min, max) {
    let data = [];
    for (let index = 0; index < number; index++) {
      let q1 = Math.random() * (max - min) + min;
      let q3 = Math.random() * (max - q1) + q1;
      let median = Math.random() * (q3 - q1) + q1;
      let calmin = Math.random() * (q1 - min) + min;
      let calmax = Math.random() * (max - q3) + q3;

      data.push(createBoxPlotItem(index.toString(), q1, median, q3, calmin, calmax));
    }

    return data;
  }

  /**
   * Gen a box plot graph
   * @param {array} data data to use
   */
  function genBoxPlot(data) {
    let useData = data ? data : genFakeBoxPoltData(5, 0, 40);
  }

  //#endregion

  //#region Sankey
  function genSankeyGraphData(data) {
    let newData = [];
    for (const iterator of data) {
      newData.push([t(iterator.source), t(iterator.target), iterator.flow * 60]);
    }
    return newData;
  }

  function genSankeyColorPalet(data) {
    let colorlist = [
      "#00FFD4",
      "#0DA5FF",
      "#6500FF",
      "#FF004F",
      "#E8170C",
      "#FFA40D",
      "#4EE80C",
      "#FA3119",
      "#FAAD19",
      "#00EFFA",
      "#0C71FA",
    ];
    let sankeyData = new SankeyData();
    for (const iterator of data) {
      sankeyData.createLink(t(iterator[0]), iterator[2] * 60, t(iterator[1]));
    }

    let colorPalet = [];

    let d = 0;
    for (let index = 0; index < sankeyData.nodes.length; index++) {
      colorPalet[sankeyData.nodes[index].name] = colorlist[d];
      d++;
      if (d >= colorlist.length) {
        d = 0;
      }
    }

    return colorPalet;
  }
  //#endregion

  useEffect(() => {
    setIsLoadingSankey(true);
    setIsLoadingBjml(true);
    setIsLoadingEmergeHospitByUf(true);
    requestEmergeStatsData();
  }, []);

  // show the page or the loading page if loading
  return (
    <div className="emergerStats">
      {isLoadingEmerge ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <div id="emergecontainer">
          <div id="datePiker">
            <DatePicker
              style={{ borderColor: "red" }}
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                filtrerEmegerStats(date, optionDate.current.end);
              }}
              isClearable
              placeholderText={t("from")}
              dateFormat="dd/MM/yyyy"
              maxDate={endDate ? endDate : ""}
            />
            <DatePicker
              selected={endDate}
              isClearable
              placeholderText={t("to")}
              onChange={(date) => {
                setEndDate(date);
                filtrerEmegerStats(optionDate.current.start, date);
              }}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
            />
          </div>
          <div id="samplingOption">
            <Select
              value={samplingSelectOption}
              classNamePrefix="selectJ-S11"
              isSearchable={false}
              placeholderText={t("samplingOption")}
              onChange={(d) => {
                setSamplingSelectOption(d);
                samplingSelectOption.value = d.value;
                filtrerEmegerStats(optionDate.current.start, optionDate.current.end);
              }}
              options={samplingOptions}
            />
          </div>
          <div id="dataContainer">
            {emegerStatsGraphs}
            {isLoadingBjml ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            ) : (
              <div className="barChartContainer">
                <div className="Bjmlcontainer">
                  <div id="head">
                    <h2>{t("bjml_Graph_Title")}</h2>
                  </div>
                  <div className="barChart">
                    <div id="selectGroup">
                      <div className="big">
                        <Select
                          value={selectBjml}
                          classNamePrefix="selectJ-S11"
                          isSearchable={false}
                          onChange={(data) => {
                            setSelectbjml(data);
                            generateBjmlBoxPlotGraph(data.value);
                            generateTimeBjmlGraph(data.value, selectBjmlYear.value);
                          }}
                          options={selectBjmlOption}
                        />
                      </div>
                    </div>
                    <div className="ChartOlder">
                      <D3Export
                        type="PlotBox"
                        data={selectedBjmlData}
                        width={500}
                        height={400}
                        mainColor="#44a0c7"
                        secondColor="#275C73"
                      />
                    </div>
                  </div>
                </div>
                <div className="Bjmlcontainer">
                  <div id="head">
                    <h2>{t("bjml_Graph_Title_TimeStamp")}</h2>
                  </div>
                  <div className="barChart">
                    <div id="selectGroup">
                      <Select
                        value={selectBjmlYear}
                        classNamePrefix="selectJ-S11"
                        isSearchable={false}
                        onChange={(data) => {
                          setSelectBjmlYear(data);
                          generateTimeBjmlGraph(selectBjml.value, data.value);
                        }}
                        options={selectBjmlOptionYear}
                      />
                    </div>
                    <div className="ChartOlder">
                      <D3Export
                        type="PlotBox"
                        data={selectedTimeBjmlData}
                        width={500}
                        height={400}
                        mainColor="#44a0c7"
                        secondColor="#275C73"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isLoadingSankey ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            ) : (
              <div className="sankeysOlder">
                <div className="graphSankey">
                  <h3>{t("SankeyGraph_fluxUrgences_title")}</h3>
                  <D3Export
                    type="Sankey"
                    width="400"
                    height="700"
                    data={sankeyFluxUrgData}
                    nodeColors={sankeyFluxUrgColorPalette}
                  />
                </div>
                <div className="graphSankey">
                  <h3>{t("SankeyGraph_hospitalisationsParSpecialites_title")}</h3>
                  <D3Export type="Sankey" width="400" height="700" data={sankeyHospSpeData} />
                </div>
                <div className="graphSankey">
                  <h3>{t("SankeyGraph_flux_urgences_entry_discharge_types_title")}</h3>
                  <D3Export type="Sankey" width="600" height="700" data={sankey_urgences_entry_discharge_types} />
                </div>
              </div>
            )}
            {isLoadingEmergeHospitByUf ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            ) : (
              <div className="barChartContainer">
                <div className="Bjmlcontainer HospitByUf">
                  <div id="head">
                    <h2>{t("EmergeHospitByUf_graph_title")}</h2>
                  </div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isUrgenceZSTCD}
                      onChange={(e) => {
                        setIsUrgenceZSTCD(!isUrgenceZSTCD);
                        setIsLoadingEmergeHospitByUf(true);
                        generateEmergeHospitByUfGraphData(!isUrgenceZSTCD);
                      }}
                    />
                    {t("UsUrgenceZSTCD")}
                  </label>
                  <div className="barChart">
                    <BarChart data={graphDataEmergeHospitByUf} orientation={"y"} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenEmergerStats);
