/**
 * @ By: Theo Bensaci
 * @ Date: 10:00:50 17.10.2022
 * @ Description: scripte use for map + use sankey data
 */

import { link } from "d3";

export class SankeyDataNode {
  constructor(name) {
    this.name = name;
    this.leftLinks = []; // oder node <- node
    this.rightLinks = []; // node -> oder node
  }

  getAllLinks() {
    let link = [];

    for (let index = 0; index < this.leftLinks.length; index++) {
      const element = this.leftLinks[index];
      if (!link.includes(element)) {
        link.push(element);
      }
    }

    for (let index = 0; index < this.rightLinks.length; index++) {
      const element = this.rightLinks[index];
      if (!link.includes(element)) {
        link.push(element);
      }
    }

    return link;
  }

  getWieght() {
    let weightA = 0;
    let weightB = 0;
    for (let index = 0; index < this.leftLinks.length; index++) {
      weightA += this.leftLinks[index].weight;
    }
    for (let index = 0; index < this.rightLinks.length; index++) {
      weightB += this.rightLinks[index].weight;
    }
    return weightA > weightB ? weightA : weightB;
  }
}

export class SankeyDataLink {
  constructor(nodeAName, weight, nodeBName) {
    this.nodeA = nodeAName;
    this.weight = weight;
    this.nodeB = nodeBName;
  }
}

export class SankeyData {
  constructor() {
    this.nodes = [];
  }

  /**
   * Create a sankey link
   * @param {string} nodeA node A name
   * @param {number} weight weight of the link
   * @param {string} nodeB node B name
   */
  createLink(nodeA, weight, nodeB) {
    let newLink = new SankeyDataLink(nodeA, weight, nodeB);

    let nodeElementA = this.getNode(nodeA);
    if (nodeElementA == null) {
      this.nodes.push(new SankeyDataNode(nodeA));
      nodeElementA = this.nodes[this.nodes.length - 1];
    }

    let nodeElementB = this.getNode(nodeB);
    if (nodeElementB == null) {
      this.nodes.push(new SankeyDataNode(nodeB));
      nodeElementB = this.nodes[this.nodes.length - 1];
    }

    nodeElementA.rightLinks.push(newLink);
    nodeElementB.leftLinks.push(newLink);
  }

  /**
   * Get node element if it exist, else return null
   * @param {string} name node name
   * @returns node element
   */
  getNode(name) {
    for (let index = 0; index < this.nodes.length; index++) {
      if (name == this.nodes[index].name) {
        return this.nodes[index];
      }
    }
    return null;
  }

  /**
   * Get all link
   * @returns
   */
  getAllLinks() {
    let links = [];
    for (let index = 0; index < this.nodes.length; index++) {
      const element = this.nodes[index];
      let nodeLinks = element.getAllLinks();
      for (let x = 0; x < nodeLinks.length; x++) {
        if (!links.includes(nodeLinks[x])) {
          links.push(nodeLinks[x]);
        }
      }
    }
    return links;
  }
}
