import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import moment from "moment/min/moment-with-locales";
import { confirmAlert } from "react-confirm-alert";
import ShowModal from "./modalCanceld";

const DetailDesiderata = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [load, setLoad] = useState(false);
  const [daysValidated, setDaysValidated] = useState(props.daysValidated);
  const [memberDesideratas, setMemberDesideratas] = useState(props.plannings.member);
  const [myDesiderata, setMyDesideratas] = useState(props.plannings.desiderata);
  const [mystatusData, setMyStatusData] = useState([]);
  const [desideratasData, setDesideratasData] = useState([]);
  const [desiderata, setDesiderata] = useState([]);
  const [dateToday, setDateToday] = useState(moment().format("YYYY-MM-DD"));
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("isoWeek"));

  const [incrementMonth, setIncrementMonth] = useState(1);
  const [doctorsLength, setDoctorsLength] = useState(1);
  const [cause, setCause] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [daysofMonth, setDayOfMonth] = useState(null);
  const ref = useRef(null);
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  useEffect(() => {
    const startDay = moment(dateToday).clone().startOf("month").startOf("week");
    const endDay = moment(dateToday).clone().endOf("month").endOf("week");
    var calendar = [];
    var index = startDay.clone();
    while (index.isBefore(endDay, "day")) {
      calendar.push(
        days.fill(0).map((i) => {
          return {
            day: index.add(1, "day").date(),
            date: index.format("YYYY-MM-DD"),
            dayName: index.format("dddd"),
          };
        }),
      );
    }
    if (calendar[calendar.length - 1][0].day === 1) {
      setDayOfMonth(calendar.slice(0, calendar.length - 1));
    } else if (calendar[0][0].day === 2) {
      let days = [];
      for (let i = 6; i >= 0; i--) {
        days = [
          ...days,
          {
            day: moment(startDay).subtract(i, "days").date(),
            date: moment(startDay).subtract(i, "days").format("YYYY-MM-DD"),
            dayName: index.format("dddd"),
          },
        ];
      }
      calendar.unshift(days);
      setDayOfMonth(calendar);
    } else {
      setDayOfMonth(calendar);
    }
  }, [dateToday]);

  useEffect(() => {
    let start = moment(dateToday).startOf("month").format("YYYY-MM-DD");
    let end = moment(dateToday).endOf("month").format("YYYY-MM-DD");

    let member_id = props.plannings.member.index;
    axios
      .post(
        `${API.ENDPOINT + API.RH_NUMBERDOCTORVALIDATED}`,
        {
          startDate: new Date(start),
          endDate: new Date(end),
          member_id: member_id,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        // console.log("ddd",res.data)
        setMyStatusData(res.data.planningsByDay);
        setDoctorsLength(res.data.doctorsLenght);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [dateToday]);

  useEffect(() => {
    let desideratasByMember = {};
    let date = moment(myDesiderata.startDate).startOf("day").format("YYYY-MM-DD");
    let end = moment(myDesiderata.endDate).startOf("day").diff(moment(date), "days");

    let i = 0;
    if (date)
      if (
        desideratasByMember[moment(date).format("YYYY-MM-DD") + "-" + moment(date).format("dddd")]
      )
        return;
    if (end <= 0) {
      desideratasByMember[moment(date).format("YYYY-MM-DD") + "-" + moment(date).format("dddd")] =
        myDesiderata;
    } else {
      while (i <= end) {
        desideratasByMember[moment(date).format("YYYY-MM-DD") + "-" + moment(date).format("dddd")] =
          myDesiderata;
        date = moment(date).add(1, "day");
        i++;
      }
    }
    setDesideratasData(desideratasByMember);
  }, [myDesiderata]);

  const daysHead = () => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, i];
    }
    return days.map((day) => (
      <th className="header bg-cl" style={{ width: "12%" }}>
        {t(moment(startHeadDate).add(day, "days").format("dddd"))}
      </th>
    ));
  };

  const getColor = (valid, unvailablity) => {
    let total = parseFloat((doctorsLength - valid - unvailablity) / doctorsLength) * 100;
    let color = "white";
    if (total <= 70) color = "red";
    if (total > 70 && total <= 80) color = "orange";
    if (total > 80 && total <= 90) color = "yellow";
    if (total > 90) color = "green";

    return color;
  };
  const renderList = () => {
    return (
      daysofMonth &&
      daysofMonth.map((month, i) => {
        return (
          <tr key={i} style={{ color: "black" }}>
            {month.map((day, j) => {
              return desideratasData && desideratasData[day.date + "-" + day.dayName] ? (
                <td
                  style={{
                    color: "black",
                    backgroundColor:
                      mystatusData[day.date + "-" + day.dayName] &&
                      getColor(
                        mystatusData[day.date + "-" + day.dayName].valid,
                        mystatusData[day.date + "-" + day.dayName].unvailablity,
                      ),
                  }}
                  className="padding-0"
                >
                  <div className="postion-dayMonth">
                    <span className="txt-day">{day.day}</span>
                  </div>
                  <div>
                    <span className="txt-daylenght">
                      {mystatusData[day.date + "-" + day.dayName]
                        ? mystatusData[day.date + "-" + day.dayName].valid +
                          mystatusData[day.date + "-" + day.dayName].unvailablity +
                          "/" +
                          (mystatusData[day.date + "-" + day.dayName].valid +
                            mystatusData[day.date + "-" + day.dayName].unvailablity +
                            mystatusData[day.date + "-" + day.dayName].inprogess)
                        : "0/0"}
                    </span>
                  </div>
                  <div
                    className="dediderata-waiting"
                    style={{
                      backgroundColor: `${
                        desideratasData[day.date + "-" + day.dayName].status === "in_progress"
                          ? "blue"
                          : ""
                      }`,
                    }}
                  ></div>
                </td>
              ) : (
                <td
                  style={{
                    color: "black",
                    backgroundColor:
                      mystatusData[day.date + "-" + day.dayName] &&
                      getColor(
                        mystatusData[day.date + "-" + day.dayName].valid,
                        mystatusData[day.date + "-" + day.dayName].unvailablity,
                      ),
                  }}
                  className="padding-0"
                >
                  <div className="postion-dayMonth">
                    <span className="txt-day">{day.day}</span>
                  </div>
                  <div>
                    <span className="txt-daylenght">
                      {mystatusData[day.date + "-" + day.dayName]
                        ? mystatusData[day.date + "-" + day.dayName].valid +
                          mystatusData[day.date + "-" + day.dayName].unvailablity +
                          "/" +
                          (mystatusData[day.date + "-" + day.dayName].valid +
                            mystatusData[day.date + "-" + day.dayName].unvailablity +
                            mystatusData[day.date + "-" + day.dayName].inprogess)
                        : "0/0"}
                    </span>
                  </div>
                  <div className="dediderata-waiting"></div>
                </td>
              );
            })}
          </tr>
        );
      })
    );
  };

  const beforeMonth = () => {
    setIncrementMonth(incrementMonth - 1);
    let beforeMonth = moment(dateToday).subtract(1, "months");
    setDateToday(beforeMonth);
  };
  const nextMonth = () => {
    let nextMonth = moment(dateToday).add(1, "months");
    setIncrementMonth(incrementMonth + 1);
    setDateToday(nextMonth);
  };

  const confirmationCancelDesiderata = (desiderata) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to cancel this desiderata ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => PopUpCancelDesiderata(desiderata),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const PopUpCancelDesiderata = (desiderata) => {
    setDesiderata(desiderata);
    ref.current.PopUpCancelDesiderata();
  };
  const confirmationValidDesiderata = (desiderata) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to valid this desiderata ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => validDesiderata(desiderata),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const validDesiderata = (desiderata) => {
    let desiderataValidated = {
      index: desiderata.index,
      cause: "",
      status: "validated",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderataValidated,
    })
      .then((response) => {
        let _plan = response.data;
        if (_plan.Types.denomination === "Vacance") {
          daysValidated[desiderata.member_id] =
            daysValidated[desiderata.member_id] +
            moment(_plan.endDate).diff(moment(_plan.startDate), "days") +
            1;

          setDaysValidated(daysValidated);
        }
        setShowMessage(true);
        setMyDesideratas(_plan);
        props.editStatusDesiderata(_plan);
        setMessage({ edit: "the desiderata was successfully validated." });
        setTimeout(() => setShowMessage(false), 3000);
        props.setPage("listDesideratasValidated");
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const cancelDesiderata = (desiderata) => {
    let desiderataCanceled = {
      index: desiderata.index,
      cause: desiderata.cause,
      status: "canceled",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderataCanceled,
    })
      .then((response) => {
        let _plan = response.data;
        setMyDesideratas(_plan);
        setShowMessage(true);
        setMessage({ edit: "the desiderata was successfully canceled." });
        setTimeout(() => setShowMessage(false), 3000);
        props.editStatusDesiderata(_plan);
        props.setPage("listDesideratasCanceled");
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div className="row">
          <div className="w-100 col-9 d-flex justify-content-between" style={{ marginTop: 20 }}>
            <a
              onClick={() => props.setPage(props.page)}
              className="btn btn-user-1 btn-primary d-flex align-items-center"
            >
              {t("back")}
            </a>
          </div>
        </div>
        <div className="row">
          <div
            className="w-100 col-md-9 col-12 d-flex justify-content-between"
            style={{ marginTop: 20 }}
          >
            <button
              disabled={incrementMonth == 1}
              onClick={beforeMonth}
              className="btn btn-user-1 btn-primary d-flex align-items-center align-self-center"
            >
              {" "}
              {t("before month")}
            </button>
            <span className="text-month">
              {t(moment(dateToday).format("MMMM")) + " " + moment(dateToday).format("YYYY")}
            </span>
            <button
              disabled={incrementMonth >= 4}
              onClick={nextMonth}
              style={{ marginLeft: 20 }}
              className="btn btn-user-1 btn-primary d-flex align-items-center align-self-center"
            >
              {t("next month")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="overflow col-md-9 col-12" style={{ marginTop: 10 }}>
            <table className="table table-translation table-striped table-bordered table-scroll ">
              <thead className="fix-header">{daysHead()}</thead>
              <tbody>{renderList()}</tbody>
              <div style={{ zIndex: 7 }}>
                <ShowModal
                  ref={ref}
                  cancelDesiderata={cancelDesiderata}
                  desiderata={desiderata}
                  setCause={setCause}
                />
              </div>
            </table>
          </div>
          <div className="col-md-3 col-12" style={{ padding: 15 }}>
            <div
              className="message"
              style={{
                marginTop: 20,
                marginBottom: 20,
                height: "50px",
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              {showMessage &&
                message &&
                Object.entries(message).map((msg) => (
                  <div className="alert alert-success w-85" role="alert">
                    <h3>{t(msg)}</h3>
                  </div>
                ))}
              {showError &&
                error &&
                Object.entries(error).map((err) => (
                  <div className="alert alert-danger w-85" role="alert">
                    <h3>{t(err)} </h3>
                  </div>
                ))}
            </div>
            <div>
              <span className="text-detail-desiderata">{t("types")}</span> :{" "}
              <span className="text-detail-desiderata2">{myDesiderata.Types.denomination}</span>
            </div>
            <hr />
            <div>
              <span className="text-detail-desiderata">{t("doctors")}</span> :{" "}
              <span className="text-detail-desiderata2">
                {memberDesideratas.nom + " " + memberDesideratas.prenom}
              </span>
            </div>
            <hr />
            <div>
              <span className="text-detail-desiderata">
                {t("days of leave already taken during the year")}
              </span>{" "}
              :{" "}
              <span className="text-detail-desiderata2">
                {daysValidated[memberDesideratas.index]
                  ? daysValidated[memberDesideratas.index]
                  : 0}
              </span>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12 d-flex justify-content-between flex-col"
                style={{ textAlign: "end", marginTop: 20, flexWrap: "wrap" }}
              >
                <button
                  style={{ marginTop: 10 }}
                  onClick={() => confirmationValidDesiderata(myDesiderata)}
                  className="btn btn-user-1 btn-success"
                >
                  {t("valid")}
                </button>
                <button
                  onClick={() => confirmationCancelDesiderata(myDesiderata)}
                  style={{ marginTop: 10 }}
                  className="btn btn-user-1 btn-danger"
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
            <div className="row" style={{ marginTop: "40%" }}>
              <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                <span className="text-detail-desiderata">{t("requested range")}</span>
                <span style={{ height: 20, width: 60, backgroundColor: "#007bff" }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(DetailDesiderata);
