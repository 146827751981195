import React, { useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useUfLabels } from "../../../../hooks/use-uf-labels";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../../utils/api";
import { useRequest } from "../../../../hooks/use-api";
import { useUfs } from "../../../../hooks/use-ufs";
import { format, differenceInHours } from "date-fns";
import { Capitalize } from "../../../../utils/functions";
import { useNow } from "../../../../hooks/use-now";
import UfsSelector from "../../../../components/ufs-selector";
import LoadingOverlay from "react-loading-overlay";
import Button from "react-bootstrap/Button";

import "./style.scss";

export function PrintLabel() {
  const request = useRequest();
  const [selectedUf, setSelectedUf] = useState(null);

  const downloadPdf = useMutation(async function (uf) {
    try {
      const [result, response] = await request({
        method: "GET",
        path: API.V5_UF_STRUCTURE_PDF,
        params: {
          uf,
        },
        responseType: "blob",
      });

      const now = new Date();

      const url = URL.createObjectURL(result);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `structure-${uf}-${format(now, "yyyy-MM-dd")}.pdf`);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (e) {
      window.alert("Une erreur est survenue, veuillez ressayer");
    }
  });

  return (
    <div className="print-label container">
      <p className="p">
        Pour imprimer une liste d'étiquettes pour les lits d'une UF, veuillez sélectionner l'UF
        désirée. Une fois sélectionnée, le bouton "Télécharger le document" s'active et vous pouvez
        cliquer dessus pour récupérer le document en pdf.
      </p>

      <div style={{ maxWidth: 450 }}>
        <UfsSelector
          ufs={[selectedUf]}
          groups={[]}
          isSearchable
          onSelect={(action) => setSelectedUf(action.value)}
        />
      </div>
      <div>
        <Button
          variant={selectedUf ? "primary" : "secondary"}
          size="xl"
          type="button"
          disabled={!selectedUf || downloadPdf.isLoading}
          onClick={() => downloadPdf.mutate(selectedUf)}
        >
          {downloadPdf.isLoading ? "Téléchargement ..." : "Télécharger le document"}
        </Button>
      </div>
    </div>
  );
}
