import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";
import Line from "../../../components/chartV2/lineBar";
import { sortieText, percentageToHslGreenToBlack } from "../../../utils/functions";
import Clock from "react-live-clock";
import { Capitalize } from "../../../utils/functions";

Moment.globalTimezone = "Europe/Paris";

const ScreenEmergencyTrend = (props) => {
  const [hour, setHour] = useState(m().tz("Europe/Paris").format("HH"));
  const { t, i18n } = useTranslation(["emergency"]);
  const [patient, setPatient] = useState("");
  const [discharge, setDischarge] = useState("");
  const [admission, setAdmission] = useState("");
  const [load, setLoad] = useState(false);
  const [ColorAdmission, setColorAdmission] = useState(null);
  const [ColorPatient, setColorPatient] = useState(null);
  const [numberRedColorAdmission, setNumberRedColorAdmission] = useState(0);
  const [numberRedColorPatient, setNumberRedColorPatient] = useState(0);
  const handleHour = (date) => {
    if (date.split(":")[0] !== hour) setHour(date.split(":")[0]);
  };
  useEffect(() => {
    if (hour && props.data) {
      if (parseInt(hour) <= 21) {
        setPatient(props.data["J0"]["Patients"]["prediction"][parseInt(hour) + 2]);
        setDischarge(props.data["J0"]["Discharges"]["prediction"][parseInt(hour) + 2]);
        setAdmission(props.data["J0"]["Admissions"]["prediction"][parseInt(hour) + 2]);
      } else {
        setPatient(props.data["J1"]["Patients"]["prediction"][parseInt(hour) - 22]);
        setDischarge(props.data["J1"]["Discharges"]["prediction"][parseInt(hour) - 22]);
        setAdmission(props.data["J1"]["Admissions"]["prediction"][parseInt(hour) - 22]);
      }
      setLoad(true);
    }
  }, [hour, props.data]);

  useEffect(() => {
    let DataColorAdmission = props.data["ColorBound"].filter(
      (color) => color.variableName == "Admissions",
    );
    let DataColorPatient = props.data["ColorBound"].filter(
      (color) => color.variableName == "Patients",
    );

    setColorAdmission(DataColorAdmission);
    setColorPatient(DataColorPatient);
    let colorRedAdmission =
      DataColorAdmission[0].hourUpperations -
      (DataColorAdmission[0].hourUpperations - DataColorAdmission[0].hourLower) * 0.2;
    let colorRedPatient =
      DataColorPatient[0].hourUpperations -
      (DataColorPatient[0].hourUpperations - DataColorPatient[0].hourLower) * 0.2;
    setNumberRedColorAdmission(colorRedAdmission);
    setNumberRedColorPatient(colorRedPatient);
  }, []);

  const renderHours = () => {
    let startHour;
    let endHour;
    let currentHour = parseInt(hour);
    if (currentHour <= 21) startHour = currentHour + 2;
    else startHour = currentHour - 22;

    if (currentHour <= 20) endHour = currentHour + 3;
    else endHour = currentHour - 21;

    if (endHour < 10) endHour = "0" + endHour;
    if (startHour < 10) startHour = "0" + startHour;
    return startHour + ":00 - " + endHour + ":00";
  };

  const calculateEstimateTime = () => {
    let time = "";

    if (admission < 5) {
      time = `15 ${t("minutes")}`;
    } else if (admission < 10) {
      time = `30 ${t("minutes")}`;
    } else if (admission < 15) {
      time = `45 ${t("minutes")}`;
    } else if (admission < 20) {
      time = `60 ${t("minutes")}`;
    } else {
      time = `> 60 ${t("minutes")}`;
    }
    return time;
  };

  return (
    <>
      <div className="">
        <div className="text-center w-100 div-header-s2">
          <h1 className="page-header" style={{ color: "black" }}>
            {" "}
            <Clock
              format={"HH:mm"}
              onChange={(date) => handleHour(date.output)}
              ticking={true}
              timezone={"Europe/Paris"}
            />
          </h1>
        </div>

        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-12 col-lg-3 display-flex" style={{ textAlign: "center" }}>
            <div className="col-12 col-lg-12 col-md-6 part">
              <h3
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginBottom: 30,
                  fontSize: "1.36vw",
                }}
              >
                {t("waiting time before medical exam")}
              </h3>

              {props.ErWaitTime["avg_hours_to_exam_real"] && (
                <>
                  <p
                    style={{
                      fontSize: "0.75vw",
                      fontStyle: "italic",
                      color: "black",
                      color: "black",
                    }}
                  >
                    {t("observed since midnight")}​
                  </p>
                  <p className="font-minute" style={{ color: "#666666" }}>
                    {" "}
                    {`${Math.round(props.ErWaitTime["avg_hours_to_exam_real"] * 60)} ${t(
                      "minutes",
                    )}`}{" "}
                  </p>
                </>
              )}

              {props.ErWaitTime["avg_hours_to_exam_pred"] ? (
                <>
                  <p
                    style={{
                      fontSize: "0.75vw",
                      fontStyle: "italic",
                      color: "black",
                    }}
                  >
                    {t("estimated for future stays")}
                  </p>

                  <p className="font-minute" style={{ color: "#04aaf0" }}>
                    {" "}
                    {`${Math.round(props.ErWaitTime["avg_hours_to_exam_pred"] * 60)} ${t(
                      "minutes",
                    )}`}{" "}
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "0.75vw",
                      fontStyle: "italic",
                      color: "black",
                    }}
                  >
                    {t("estimated for future stays")}​
                  </p>

                  <p className="font-minute" style={{ color: "#04aaf0" }}>
                    {" "}
                    {`${calculateEstimateTime()}`}{" "}
                  </p>
                </>
              )}
            </div>

            <div className="line"> </div>

            <div className="col-12 col-lg-12 col-md-6 part">
              <h3
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginBottom: 30,
                  fontSize: "1.36vw",
                }}
              >
                {t("time in emergency ward")}
              </h3>

              {props.ErWaitTime["avg_hours_to_exit_real"] && (
                <>
                  <p
                    style={{
                      fontSize: "0.75vw",
                      fontStyle: "italic",
                      color: "black",
                    }}
                  >
                    {t("observed since midnight")}​
                  </p>
                  <p className="font-minute" style={{ color: "#666666" }}>
                    {" "}
                    {props.ErWaitTime["avg_hours_to_exit_real"] >= 1
                      ? `${props.ErWaitTime["avg_hours_to_exit_real"].toFixed(1)} ${t("hours")}`
                      : `${Math.round(props.ErWaitTime["avg_hours_to_exit_real"] * 60)} ${t(
                          "minutes",
                        )}`}{" "}
                  </p>
                </>
              )}

              {props.ErWaitTime["avg_hours_to_exit_pred"] && (
                <>
                  <p
                    style={{
                      fontSize: "0.75vw",
                      fontStyle: "italic",
                      color: "black",
                    }}
                  >
                    {t("estimated for future stays")}​
                  </p>
                  <p className="font-minute" style={{ color: "#04aaf0" }}>
                    {" "}
                    {props.ErWaitTime["avg_hours_to_exit_pred"] >= 1
                      ? `${props.ErWaitTime["avg_hours_to_exit_pred"].toFixed(1)} ${t("hours")}`
                      : `${Math.round(props.ErWaitTime["avg_hours_to_exit_pred"] * 60)} ${t(
                          "minutes",
                        )}`}{" "}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-9">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-sm-8  col-md-6  col-lg-4 ">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-8  prediction-index">
                    <div className="prediction-header">{renderHours()}</div>

                    {load ? (
                      <p
                        id="current-admissions"
                        style={{
                          background: percentageToHslGreenToBlack(
                            admission,
                            ColorAdmission && ColorAdmission[0].hourLower,
                            ColorAdmission && ColorAdmission[0].hourUpperations,
                          ),
                        }}
                      >
                        {" "}
                        <span
                          className="day-prediction-indicator "
                          style={{
                            color: admission > numberRedColorAdmission ? "white" : "black",
                          }}
                        >
                          {Capitalize(t("admissions"))}
                        </span>
                      </p>
                    ) : (
                      <div id="current-admissions" className="w-100" style={{ marginLeft: "40%" }}>
                        {" "}
                        <ReactLoading
                          className=""
                          type={"balls"}
                          color="#2fa2d9"
                          width="20%"
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                    <div className="row">
                      <div className="col-md-4 col-4  col-lg-2 p-color">
                        <div className="color-bar"></div>
                      </div>
                      <div className=" p-color-number" style={{ marginTop: 2, textAlign: "left" }}>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourUpperations)}</p>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourMedian)}</p>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourLower)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-8 col-md-6 col-lg-4">
                <div className="row">
                  <div className="col-8  prediction-index">
                    <div className="prediction-header">{renderHours()}</div>

                    {load ? (
                      <p
                        id="current-admissions"
                        style={{
                          background: percentageToHslGreenToBlack(
                            patient,
                            ColorPatient && ColorPatient[0].hourLower,
                            ColorPatient && ColorPatient[0].hourUpperations,
                          ),
                        }}
                      >
                        {" "}
                        <span
                          className="day-prediction-indicator "
                          style={{
                            color: patient > numberRedColorPatient ? "white" : "black",
                          }}
                        >
                          {Capitalize(t("present"))}
                        </span>
                      </p>
                    ) : (
                      <div id="current-admissions" className="w-100" style={{ marginLeft: "40%" }}>
                        {" "}
                        <ReactLoading
                          className=""
                          type={"balls"}
                          color="#2fa2d9"
                          width="20%"
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                    <div className="row">
                      <div className="col-md-4 col-4  col-lg-2 p-color">
                        <div className="color-bar"></div>
                      </div>
                      <div className=" p-color-number" style={{ marginTop: 2, textAlign: "left" }}>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourUpperations)}</p>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourMedian)}</p>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourLower)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-4 col-md-12">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-12 col-md-8 prediction-index">
                    <div className="prediction-header">{renderHours()}</div>
                    <div className="row">
                      <div className="col-lg-4 col-5 col-md-3">
                        {/*load && patient >= 50 && discharge <= 10 && (
                          <img
                            id="event_sortie"
                            className="event_icon2"
                            src="./assets/images/danger.png"
                          />
                        )*/}
                      </div>
                      {load ? (
                        <>
                          {" "}
                          <div className="col-lg-8 col-7 col-md-8" id="current-sorties-prime">
                            {discharge != "" ? sortieText(discharge) : ""}
                          </div>
                          <div className="row row-sortie">{Capitalize(t("discharges"))}</div>
                        </>
                      ) : (
                        <div
                          id="current-admissions"
                          className="w-100"
                          style={{ marginLeft: "40%" }}
                        >
                          {" "}
                          <ReactLoading
                            className=""
                            type={"balls"}
                            color="#2fa2d9"
                            width="30%"
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-12 row-line">
                {props.data ? (
                  <Line
                    traduction="emergency"
                    box={true}
                    timeSeries={true}
                    showAnnoation={true}
                    titleSize={26}
                    language={i18n.language}
                    J0={true}
                    data={props.data["J0"]["Patients"]}
                    hours={props.data["Hours_3"]["Patients"]}
                    latestReal={props.data["LatestReal"]["Patients"]}
                    hour={hour}
                    type={"Patients"}
                    title={Capitalize(t("patients present in emergency ward"))}
                    annotations={true}
                  />
                ) : (
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      minHeight: 150,
                      alignItems: "flex-end",
                    }}
                  >
                    <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScreenEmergencyTrend;
