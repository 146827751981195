import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { Line, Bar } from "react-chartjs-2";
import "./index.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import moment from "moment";
import m from "moment-timezone";
import Clock from "react-live-clock";

const ChartLine = (props) => {
  const [data, setData] = useState(null);
  const [annotations, setAnnotation] = useState(false);
  const [update, setUpdate] = useState(false);
  const [labels, setLabels] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ]);

  const labels_img = [0, 3, 6, 9, 12, 15, 18, 21];

  const [min, setMin] = useState(props.demo ? 36 : m().tz("Europe/Paris").format("mm"));

  const handleHour = (date) => {
    if (props.demo) return;
    if (m().tz("Europe/Paris").format("mm") !== min && min % 2 === 0)
      setMin(m().tz("Europe/Paris").format("mm"));
  };

  let myRef = React.createRef();

  const { t, i18n } = useTranslation([`${props.traduction}`]);

  useEffect(() => {
    if (props.annotations === true) {
      const testValue = [props.hour + "h"];
      let adjust = 0,
        sizeLabel = 20;

      if (props.hour === "0" || props.hour === "1" || props.hour === "2") {
        adjust = 220;
      }
      if (props.hour === "22" || props.hour === "23" || props.hour === "21") {
        adjust = -220;
      }

      setAnnotation({
        type: "line",
        id: "vline" + 0,
        mode: "vertical",
        scaleID: "x-axis-0",
        value: props.hour - 0.5 + parseInt(min) / 60,
        borderColor: "black",
        borderWidth: 1.5,

        label: {
          xPadding: 50,
          enabled: props.showAnnoation,
          position: "right",
          fontSize: window.innerWidth >= 2400 ? sizeLabel : 14,
          xAdjust: adjust,
          content:
            testValue +
            "  /  " +
            `${
              props.latestReal[props.latestReal.length - 1] !== null
                ? props.latestReal[props.latestReal.length - 1]
                : "-"
            }` +
            " " +
            t(`patients-data`),
        },
      });
      setUpdate(true);
    }
  }, [props.hour, props.language, min]);

  useEffect(() => {
    if (update === true) setUpdate(false);
  }, [update]);

  useEffect(() => {
    let color = props.J0 ? "#ccc9c9" : "#909090";

    let data = {
      labels: props.labels_img ? labels_img : labels,
      datasets: [],
    };
    if (props.data["predictionUpper"] && props.data["predictionUpper"].length !== 0) {
      data["datasets"].push({
        label: t(`max`),
        data: props.data["predictionUpper"],
        borderColor: "rgb(0,65,120)",
        borderWidth: 2,
        fill: false,
        type: "line",
        pointRadius: 0,
        fillBetweenSet: 1,
        fillBetweenColor: "rgb(0,65,120)",
        //  pointStyle: 'rect',
      });
    }
    if (props.data["prediction"] && props.data["prediction"].length !== 0) {
      data["datasets"].push({
        label: t("predictions"),
        data: props.data["prediction"],
        borderColor: "#3aa8fb",
        strokeColor: "#3aa8fb",
        borderWidth: 2,
        fill: false,
        type: "line",
        pointRadius: 0,
        //  pointStyle: 'rect',
      });
    }

    if (props.data["predictionLower"] && props.data["predictionLower"].length !== 0) {
      data["datasets"].push({
        label: t(`min`),
        data: props.data["predictionLower"],
        borderColor: "rgb(0,65,120)",
        fill: 0,
        type: "line",
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 0,
      });
    }

    if (
      props.data &&
      props.data["bestForecastValues"] &&
      props.data["bestForecastValues"].length !== 0
    ) {
      data["datasets"].push({
        label: t("ideal"),
        data: props.data && props.data["bestForecastValues"],
        borderColor: "green",
        borderWidth: 2,
        fillColor: color,
        backgroundColor: "green",
        fill: false,
        hidden: true,
        type: "line",
        lineTension: 0,
        pointRadius: 0,
      });
    }
    if (props.data["lowerOutlier"] && props.data["lowerOutlier"].length !== 0) {
      data["datasets"].push({
        label: t("lower outlier"),
        data: props.data["lowerOutlier"],
        borderColor: "green",
        fillColor: "green",
        backgroundColor: "green",
        fill: false,
        type: "line",
        showLine: false,
        pointRadius: 5,
        pointStyle: "rectRot",
        pointBackgroundColor: "green",
        pointBorderColor: "black",
      });
    }

    if (props.data["upperOutlier"] && props.data["upperOutlier"].length !== 0) {
      data["datasets"].push({
        label: t("upper outlier"),
        data: props.data["upperOutlier"],
        borderColor: "red",
        fillColor: "red",
        backgroundColor: "red",
        fill: false,
        type: "line",
        showLine: false,
        pointRadius: 5,
        pointStyle: "rectRot",
        pointBackgroundColor: "red",
        pointBorderColor: "black",
      });
    }
    if (props.data["predictionCorrect"] && props.data["predictionCorrect"].length !== 0) {
      data["datasets"].push({
        label: t("prediction correct"),
        data: props.data["predictionCorrect"],
        borderColor: "bleu",
        fillColor: "bleu",
        backgroundColor: "bleu",
        fill: false,
        type: "line",
        showLine: false,
        pointRadius: 6,
        borderWidth: 1,
        pointBackgroundColor: "blue",
        pointBorderColor: "blue",
      });
    }
    if (props.data["measuredValues"] && props.data["measuredValues"].length !== 0) {
      data["datasets"].push({
        label: t("real"),
        data: props.data["measuredValues"],
        borderColor: "#909090",
        borderWidth: 2,
        fillColor: "#909090",
        backgroundColor: "#909090",
        fill: true,
        type: "bar",
        pointRadius: 0,
        pointBackgroundColor: "blue",
        pointBorderColor: "blue",
        multiKeyBackground: "red",
        barPercentage: 1.15,
        pointStyle: "rect",
        lineTension: 0,
      });
    }

    if (props.timeSeries === true && props.latestReal) {
      data["datasets"].push({
        label: t("ongoing"),
        data: props.latestReal,
        borderColor: "#ccc9c9",
        borderWidth: 2,
        fillColor: "#ccc9c9",
        backgroundColor: "#ccc9c9",
        fill: false,
        pointRadius: 0,
        lineTension: 0,
        pointStyle: "rect",
        barPercentage: 1.15,
        type: "bar",
      });
    }
    if (props.data3H === true && props.hours) {
      data["datasets"].push({
        label: t("3 hours predictions"),
        data: props.hours,
        borderColor: "#3aa8fb",
        borderWidth: 2,
        fillColor: "#3aa8fb",
        backgroundColor: "#3aa8fb",
        fill: true,
        pointRadius: 0,
        lineTension: 0,
        barPercentage: 1.15,
        type: "bar",
        pointStyle: "rect",
      });
    }

    setData(data);

    setUpdate(true);
  }, [props.data, props.language]);

  const renderChart = () => {
    let size,
      sizeLabel = 16;
    window.innerWidth >= 2400
      ? (size = 30)
      : window.innerWidth <= 767
      ? (size = 16)
      : (size = props.titleSize);

    return (
      <Bar
        redraw={update}
        ref={(reference) => (myRef = reference)}
        data={data}
        // height={100}
        options={{
          plugins: {
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          // responsive :true,
          title: {
            display: true,
            text: props.title,
            fontSize: size,
          },
          legend: {
            labels: {
              usePointStyle: true,
              fontSize: window.innerWidth >= 2400 ? sizeLabel : 12,
            },
            display: true,
            position: "top",
          },
          annotation: props.annotations ? { annotations: [annotations] } : {},
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                offset: true,
                stacked: true,

                ticks: {
                  callback: function (value, index, values) {
                    return value < 10 ? `0${value}h` : `${value}h`;
                  },
                  labelOffset:
                    props.hours_3 === true
                      ? -window.innerWidth * 0.0074 * 3
                      : -window.innerWidth * 0.0074,
                },
              },
            ],
          },

          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === t("real")) {
                  return {
                    borderColor: "#909090",
                    backgroundColor: "#909090",
                  };
                } else {
                  return {
                    borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                    backgroundColor:
                      chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                  };
                }
              },
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (tooltipItem.yLabel === null || isNaN(tooltipItem.yLabel)) return null;
                else if (
                  label === t("prediction correct") ||
                  label === t("upper outlier") ||
                  label === t("lower outlier")
                ) {
                  return label;
                } else if (label) {
                  label += ": ";
                  label +=
                    tooltipItem.yLabel % 1 === 0
                      ? tooltipItem.yLabel
                      : tooltipItem.yLabel.toFixed(1);
                }
                return label;
              },
            },
          },
          elements: {
            point: {
              // radius : customRadius,
              // display:true,
              // backgroundColor:'blue'
            },
          },
        }}
      />
    );
  };

  const customRadius = (context) => {
    let index = context.dataIndex;
    let max = context.chart.tooltip._data.datasets[0]["data"][index];
    let min = context.chart.tooltip._data.datasets[2]["data"][index];
    let reel = context.chart.tooltip._data.datasets[3]["data"][index];

    if (reel === null || max === null || min === null) return 0;
    return reel <= max && reel >= min ? 4 : 0;
  };

  return (
    <div
      className={data && props.box ? "row row-chart-box" : "row row-chart"}
      style={!data ? { justifyContent: "center", minHeight: 150, alignItems: "flex-end" } : {}}
    >
      <h4 style={{ display: "none" }}>
        <Clock
          format={"HH:mm"}
          onChange={(date) => handleHour(date.output)}
          ticking={true}
          timezone={"Europe/Paris"}
        />
      </h4>
      {data && renderChart()}
    </div>
  );
};

export default ChartLine;
