import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import "./index.css";
import AddMember from "./addMember";
import AddFunction from "./addFunction";
import AddParticlier from "./addStatus";
import List from "./listStatusFunctions";
import axios from "axios";
import { API } from "../../../utils/api";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import { Capitalize } from "../../../utils/functions";
import { connect } from "react-redux";

const ScreenListMembers = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listmembers");
  const [globalMembersData, setGlobalMembersData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesID, setCategoriesID] = useState([]);
  const [load, setLoad] = useState(false);
  const [optionsStatus, setOptionsStatus] = useState(props.status);
  const [selectedOptionStatus, setOptionSelectedStatus] = useState({
    value: 0,
    label: t("choose function"),
  });
  const [optionsFunctions, setOptionsFunctions] = useState(props.functions);
  const [selectedOptionF, setOptionSelectedFunction] = useState({
    value: 0,
    label: t("choose status"),
  });
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rate, setRate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [daysChecked, setDaysChecked] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [maxHours, setMaxHours] = useState(null);
  const [maxSuccGarde, setMaxSuccGarde] = useState(null);
  const [maxWeekEndWorked, setMaxWeekEndWorked] = useState(null);
  const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  useEffect(() => {
    axios
      .get(`${API.ENDPOINT + API.RH_ALLMEMBERS}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let resSortedByName = res.data.sort(function (a, b) {
          return a.nom > b.nom ? 1 : a.nom < b.nom ? -1 : 0;
        });
        console.log(resSortedByName);
        setLoad(true);
        setGlobalMembersData(resSortedByName);
      })
      .catch((error) => {
        setLoad(false);
        console.error("There was an error!", error);
      });
  }, []);
  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLCATEGORIES}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setCategories(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  const setMemberCategories = (member) => {
    let categoriesID = [],
      days = [];
    member.Categories &&
      member.Categories.map((cat) => {
        categoriesID = [...categoriesID, `${cat.index}`];
      });
    member.Availablity &&
      Object.entries(member.Availablity).map((day, value) => {
        if (day[1] === 1 && day[0] !== "index") days = [...days, day[0]];
      });
    setDaysChecked(days);
    setCategoriesID(categoriesID);
  };

  const confirmationDelete = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this member?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteMember(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteMember = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETEMEMBER}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let members = globalMembersData.filter((member) => member.index !== id);
        setShowMessage(true);
        setMessage({ edit: "the member has been deleted successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalMembersData(members);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
        // console.log(e)
      });
  };
  const onEdit = (member) => {
    console.log(member);
    setInEditMode({
      status: true,
      rowKey: member.index,
    });
    setName(member.prenom);
    setLastName(member.nom);
    setRate(member.taux);
    setMemberCategories(member);
    setMaxHours(member.max_hours);
    setMaxWeekEndWorked(member.max_week_end_worked);
    setMaxSuccGarde(member.max_succ_garde);
    member.Functions &&
      setOptionSelectedFunction({
        value: member.Functions.index,
        label: member.Functions.name,
      });
    member.Status &&
      setOptionSelectedStatus({
        value: member.Status.index,
        label: member.Status.name,
      });
    member.startDate === null || member.startDate === ""
      ? setStartDate(null)
      : setStartDate(new Date(member.startDate));

    member.endDate === null || member.endDate === ""
      ? setEndDate(null)
      : setEndDate(new Date(member.endDate));

    setIsDisable(true);
  };
  const updateMember = (member) => {
    let newMember = {
      index: member.index,
      nom: lastName === "" ? member.lastName : lastName,
      prenom: name === "" ? member.name : name,
      description:
        description === "" ? (member.description === null ? "" : member.description) : description,
      taux: rate === "" || parseInt(rate) < 0 || parseInt(rate) > 100 ? member.taux : rate,
      startDate: startDate === "" ? member.startDate : startDate,
      endDate: endDate === "" ? member.endDate : endDate,
      max_hours: maxHours == null ? member.max_hours : maxHours,
      max_week_end_worked: maxWeekEndWorked == null ? member.max_week_end_worked : maxWeekEndWorked,
      max_succ_garde: maxSuccGarde == null ? member.max_succ_garde : maxSuccGarde,
      status_id: selectedOptionStatus.value !== 0 ? selectedOptionStatus.value : null,
      function_id: selectedOptionF.value,
    };
    console.log(newMember);

    let availablity = {
      index: member.Availablity && member.Availablity.index,
      monday: daysChecked.includes("monday") ? 1 : 0,
      tuesday: daysChecked.includes("tuesday") ? 1 : 0,
      wednesday: daysChecked.includes("wednesday") ? 1 : 0,
      thursday: daysChecked.includes("thursday") ? 1 : 0,
      friday: daysChecked.includes("friday") ? 1 : 0,
      saturday: daysChecked.includes("saturday") ? 1 : 0,
      sunday: daysChecked.includes("sunday") ? 1 : 0,
      member_id: member.index,
      post_id: null,
    };
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_MEMBER}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: { newMember, availablity, categoriesID },
    })
      .then((response) => {
        let members = [];
        globalMembersData.map((m) => {
          if (m.index === member.index) {
            members = [...members, response.data];
            return;
          }

          members = [...members, m];
        });

        setShowMessage(true);
        setMessage({ edit: "the member have been successfully modified" });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalMembersData(members);
        onCancel();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "401") {
            setError(error.response.data);
          }
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            //    console.log(errors)
            setError(errors);
          }
        }
        console.error("There was an error!", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setIsDisable(false);
    setName("");
    setLastName("");
    setRate("");
    setEndDate(null);
    setStartDate(null);
    setDaysChecked([]);
    setOptionSelectedStatus({ value: 0, label: t("choose status") });
    setOptionSelectedFunction({ value: 0, label: t("choose function") });
  };

  const addMember = (member) => {
    let data = [...globalMembersData, member];
    setGlobalMembersData(data);
  };
  const addFunction = (func) => {
    let data = [...optionsFunctions, { value: func.index, label: func.name }];
    setOptionsFunctions(data);
  };
  const addStatus = (status) => {
    let data = [...optionsStatus, { value: status.index, label: status.name }];
    setOptionsStatus(data);
  };

  const handleCheckBox = (e) => {
    let days;
    if (e.target.checked) {
      days = [...daysChecked, e.target.id];
    } else {
      days = daysChecked.filter((day) => day !== e.target.id);
    }
    if (days.length == 0) {
      days = [daysOfWeek[0]];
    }

    setDaysChecked(days);
  };
  const handleCheckBoxCategories = (e) => {
    let catID;

    if (e.target.checked) {
      catID = [...categoriesID, e.target.id];
    } else {
      catID = categoriesID.filter((cat) => cat !== e.target.id);
    }
    if (catID.length == 0) {
      catID = [`${categoriesID[0]}`];
    }

    setCategoriesID(catID);
  };

  const renderListMembers = () => {
    if (!globalMembersData) return null;

    return globalMembersData.map((member, i) => (
      <tr key={i}>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={lastName}
              required
              className="form-control"
              onChange={(event) => setLastName(event.target.value)}
            />
          ) : (
            member.nom
          )}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={name}
              required
              className="form-control"
              onChange={(event) => setName(event.target.value)}
            />
          ) : (
            member.prenom
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <Select
              value={selectedOptionStatus}
              className="selectJ1 form-select"
              isSearchable={false}
              onChange={(selectedOptionStatus) => setOptionSelectedStatus(selectedOptionStatus)}
              options={optionsStatus}
            />
          ) : member.Status ? (
            Capitalize(member.Status.name)
          ) : (
            "-"
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <Select
              value={selectedOptionF}
              className="selectJ1 form-select"
              isSearchable={false}
              onChange={(selectedOption) => setOptionSelectedFunction(selectedOption)}
              options={optionsFunctions.slice(1)}
            />
          ) : member.Functions ? (
            Capitalize(member.Functions.name)
          ) : (
            "-"
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={rate}
              required
              style={{ maxWidth: "60px", textAlign: "center" }}
              className="form-control"
              onChange={(event) => setRate(event.target.value)}
            />
          ) : (
            member.taux + "%"
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <DatePicker
              style={{ borderColor: "" }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              isClearable
              placeholderText={t("from")}
              dateFormat="dd/MM/yyyy"
            />
          ) : member.startDate === "" || member.startDate === null ? (
            "-"
          ) : (
            moment(member.startDate).format("YYYY-MM-DD")
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <DatePicker
              style={{ borderColor: "" }}
              selected={endDate}
              isClearable
              placeholderText={t("to")}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
            />
          ) : member.endDate === "" || member.endDate === null ? (
            "-"
          ) : (
            moment(member.endDate).format("YYYY-MM-DD")
          )}
        </td>
        <td
          className={
            "checkBoxContainer" + (inEditMode.status && inEditMode.rowKey === member.index)
              ? " edit small"
              : ""
          }
        >
          {inEditMode.status && inEditMode.rowKey === member.index
            ? daysOfWeek.map((day, i) => {
                return (
                  <div
                    className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox"
                    key={i}
                    style={{ display: "flex" }}
                  >
                    <input
                      onChange={handleCheckBox}
                      type="checkbox"
                      checked={daysChecked.includes(day) ? true : false}
                      className="custom-control-input"
                      id={day}
                    />
                    <label style={{ fontSize: 16 }} className="custom-control-label" htmlFor={day}>
                      {t(day.charAt(0))}
                    </label>
                  </div>
                );
              })
            : member.Availablity && member.Availablity.member_id == member.index
            ? daysOfWeek.map((day) => {
                return (
                  member.Availablity[day] === 1 && (
                    <>
                      <span className="label primary">{t(day)}</span>
                      <br />
                    </>
                  )
                );
              })
            : "-"}
        </td>
        <td
          className={
            "checkBoxContainer" + (inEditMode.status && inEditMode.rowKey === member.index)
              ? " edit"
              : ""
          }
        >
          {inEditMode.status && inEditMode.rowKey === member.index
            ? categories &&
              categories.map((cat, i) => {
                return (
                  <div
                    style={{ display: "flex" }}
                    className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox"
                    key={i}
                  >
                    <input
                      onChange={handleCheckBoxCategories}
                      type="checkbox"
                      checked={categoriesID.includes(`${cat.index}`) ? true : false}
                      className="custom-control-input"
                      id={cat.index}
                    />
                    <label
                      style={{ fontSize: 16 }}
                      className="custom-control-label"
                      htmlFor={cat.index}
                    >
                      {cat.abreviation}
                    </label>
                  </div>
                );
              })
            : member.Categories
            ? member.Categories.map((category, index) => {
                return (
                  <>
                    <span className="label primary">{category.denomination}</span>
                    <br />
                  </>
                );
              })
            : "-"}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={maxHours}
              required
              className="form-control maxInput"
              onChange={(event) => setMaxHours(event.target.value)}
            />
          ) : member.max_hours == null ? (
            "-"
          ) : (
            member.max_hours
          )}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={maxWeekEndWorked}
              required
              className="form-control maxInput"
              onChange={(event) => setMaxWeekEndWorked(event.target.value)}
            />
          ) : member.max_week_end_worked == null ? (
            "-"
          ) : (
            member.max_week_end_worked
          )}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={maxSuccGarde}
              required
              className="form-control maxInput"
              onChange={(event) => setMaxSuccGarde(event.target.value)}
            />
          ) : member.max_succ_garde == null ? (
            "-"
          ) : (
            member.max_succ_garde
          )}
        </td>
        <td className="text-capitalize">
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <input
              value={description}
              required
              className="form-control"
              onChange={(event) => setDescription(event.target.value)}
            />
          ) : (
            member.description
          )}
        </td>

        <td>
          {inEditMode.status && inEditMode.rowKey === member.index ? (
            <>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => updateMember(member)}>
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEdit(member)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => confirmationDelete(member.index, member)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };

  const renderscreenMember = () => {
    return (
      <div className="overflow" style={{ marginTop: 10 }}>
        {load && globalMembersData ? (
          <table
            className={
              "table table-translation table-striped table-bordered table-scroll" +
              (inEditMode.status ? " editTable" : "")
            }
            style={{ width: "100%" }}
          >
            <thead className="fix-header">
              <tr>
                <th className="header">
                  <span style={{ display: "block" }}>{t("first name")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("last name")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("status")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("function")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("rate")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("from")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("to")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("availablity")}</span>
                </th>
                <th className="header" style={{ width: "10px" }}>
                  <span style={{ display: "block" }}>{t("roles")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("max hours")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("max week")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("max suc gard")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("description")}</span>
                </th>
                <th className="header">
                  <span style={{ display: "block" }}>{t("action")}</span>
                </th>
              </tr>
            </thead>
            <tbody>{renderListMembers()}</tbody>
          </table>
        ) : (
          <div
            style={{
              marginTop: "6%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ReactLoading type={"bars"} color="#2fa2d9" width="70px" />
          </div>
        )}
      </div>
    );
  };

  const renderScreen = () => {
    switch (page) {
      case "listmembers":
        return renderscreenMember();
      case "addMembre":
        return (
          <AddMember
            addMember={addMember}
            setPage={setPage}
            status={optionsStatus}
            functions={optionsFunctions}
            categories={categories}
          />
        );
      case "addfunction":
        return <AddFunction addFunction={addFunction} setPage={setPage} />;
      case "addStatus":
        return <AddParticlier addStatus={addStatus} setPage={setPage} />;
      case "listFunctionStatus":
        return <List status={optionsStatus} functions={optionsFunctions} />;
    }
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-end flex-col"
            style={{ textAlign: "end", marginTop: 20, flexWrap: "wrap" }}
          >
            {page === "listmembers" ? (
              <a
                onClick={() => setPage("addMembre")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary btn-plan"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("add membre")}
              </a>
            ) : (
              ""
            )}
            {page === "listFunctionStatus" || page === "listmembers" ? (
              <>
                {" "}
                <a
                  onClick={() => setPage("addfunction")}
                  style={{ marginLeft: 20 }}
                  className="btn btn-user-1 btn-primary btn-plan"
                >
                  <ImPlus
                    style={{
                      color: "white",
                      fontSize: 15,
                      marginTop: -2,
                      paddingRight: 5,
                    }}
                  />
                  {t("add function")}
                </a>
                <a
                  onClick={() => setPage("addStatus")}
                  style={{ marginLeft: 20 }}
                  className="btn btn-user-1 btn-primary btn-plan"
                >
                  <ImPlus
                    style={{
                      color: "white",
                      fontSize: 15,
                      marginTop: -2,
                      paddingRight: 5,
                    }}
                  />
                  {t("add particular")}
                </a>
              </>
            ) : (
              ""
            )}
            {page === "listmembers" ? (
              <a
                onClick={() => setPage("listFunctionStatus")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary btn-plan"
              >
                {t("list of functions and particular")}
              </a>
            ) : (
              ""
            )}
            {page === "listFunctionStatus" ? (
              <a
                onClick={() => setPage("listmembers")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary btn-plan"
              >
                {t("list of members")}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        {page === "listmembers" ? (
          <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
        {renderScreen()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenListMembers);
