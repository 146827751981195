import React, { useState, useEffect } from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import { useTranslation } from "react-i18next";
import { editProfil } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import ReactLoading from "react-loading";
import { API } from "../../utils/api";
import { Capitalize } from "../../utils/functions";
import * as Permission from "../../utils/permissions";
import Select from "react-select";
import { UtilsRegex } from "../../utils/regex";

const EditProfil = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };
  const [local] = useState(i18n.language);
  const [email, setEmail] = useState(props.activeUSer.user.email);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);
  const [errorGlobal, setErrorGlobal] = useState(null);

  const [showError, setShowError] = useState(true);
  const [load, setLoad] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setOptionSelected] = useState({
    value: "",
    label: t("select a default screen"),
  });

  const loadingOptions = (Data) => {
    let screensName = [],
      screenDefault = { value: "", label: t("select a default screen") };
    Data.map((screen) => {
      if (
        screen.name.toUpperCase() != "HOME PAGE" &&
        screen.name.toUpperCase() != "ADMIN" &&
        screen.name.toUpperCase() != "SUPER ADMIN"
      )
        screensName = [
          ...screensName,
          { value: screen.id, label: ("screen " + screen.name).toUpperCase() },
        ];
      if (
        props.activeUSer.user.screen_default !== "" &&
        props.activeUSer.user.screen_default === screen.name
      ) {
        screenDefault = {
          value: screen.id,
          label: ("screen " + screen.name).toUpperCase(),
        };
      }
    });
    setOptions(screensName);
    setOptionSelected(screenDefault);
  };
  useEffect(() => {
    axios
      .get(API.ENDPOINT + API.USER_ALLROLES, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((response) => {
        loadingOptions(response.data);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);

  const editProfil = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    let errors = {};
    let test = false;
    const strongRegex = new RegExp(UtilsRegex.Password);

    if (password !== "" && !strongRegex.test(password)) {
      errors = {
        password: t(
          "your password must contain at least 8 characters, with at least one upper case letter, one lower case letter, one number and one special character",
        ),
      };
      test = true;
    }

    if (repeatPassword !== "" && password !== repeatPassword) {
      errors = {
        ...errors,
        repeatPassword: t("your confirmation password does not match the registered password"),
      };
      test = true;
    }

    if (test) {
      setError(errors);
      setLoad(false);
      setShowError(true);
      return;
    }
    const screen_default =
      !selectedOption || selectedOption.label == "select a default screen"
        ? ""
        : selectedOption.label.replace("SCREEN ", "").toLowerCase();

    props
      .editProfil({ email, password, repeatPassword, screen_default }, props.activeUSer, history)
      .catch((error) => {
        // console.log('erorrr',error)
        if (error.response) {
          if (error.response.status === "401") {
            setError({ email: "email already used" });
          }
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            console.log(errors);
            setError(errors);
          }
          setErrorGlobal(t("we are sorry, but an error has occurred"));
        }
        setLoad(false);
        setShowError(true);
      });
  };

  const renderForm = () => {
    return (
      <LoadingOverlay active={load} spinner>
        <div className="row mrg-user" style={{ marginTop: 20 }}>
          <div className="container-user">
            <h1 className="page-title">{t("edit my profile")}</h1>

            <div className="row">
              <div className="col-10 offset-1 mainContainer">
                <hr></hr>
                <form onSubmit={editProfil} className="form-user-new">
                  <div style={{ textAlign: "center" }}>
                    {showError &&
                      error &&
                      Object.entries(error).map((err) => <div className="errors">{t(err)} </div>)}
                  </div>
                  <div className="form-group block">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      className="fadeIn form-control textInput"
                      name="_username"
                      placeholder={t("email")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="password"
                      value={password}
                      autocomplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="fadeIn form-control textInput"
                      name="_password"
                      placeholder={t("password")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="password"
                      value={repeatPassword}
                      autocomplete="off"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      id="repeatPassword"
                      className="fadeIn form-control textInput"
                      name="_repeatPassword"
                      placeholder={t("confirm new password")}
                    />
                  </div>
                  {Permission.isSuperAdmin(
                    props.activeUSer.user.group && props.activeUSer.user.group.Roles,
                  ) ||
                  Permission.isAdmin(
                    props.activeUSer.user.group && props.activeUSer.user.group.Roles,
                  ) ? (
                    <>
                      <p style={{ fontSize: 20, textAlign: "center", marginTop: 70 }}>
                        {t("default screen")}:
                      </p>

                      <div className="form-group block" style={{ marginTop: 20 }}>
                        <Select
                          value={selectedOption}
                          className="selectJ1 form-select"
                          isSearchable={false}
                          onChange={(selectedOption) => setOptionSelected(selectedOption)}
                          // onChange={(selectedOption) =>change(selectedOption)}
                          options={options}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form-group" style={{ marginTop: 20 }}>
                    <a
                      title="retour"
                      className="btn"
                      style={{ textDecoration: "underline" }}
                      href="/users"
                    >
                      {Capitalize(t("back"))}
                    </a>

                    <input
                      type="submit"
                      disabled={email !== "" ? false : true}
                      style={{ textAlign: "center" }}
                      className="fadeIn fourth btn"
                      value={Capitalize(t("save"))}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  };
  const renderWait = () => {
    return (
      <div
        className="row"
        style={{
          justifyContent: "center",
          minHeight: 150,
          alignItems: "flex-end",
        }}
      >
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      </div>
    );
  };

  return renderForm();
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ editProfil }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfil);
