import React, { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../../utils/api";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";

const Newtype = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [denomination, setDenomination] = useState("");
  const [duree, setDuree] = useState("");
  const [color, setColor] = useState("#fff");
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [message, setMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [errorBorder, setErrorBorder] = useState(null);

  const addType = (event) => {
    event.preventDefault();

    setShowError(false);
    setLoad(true);
    if (!duree.match(/^[1-9][0-9]?$/)) {
      setErrorBorder(["duree"]);
      setShowError(true);
      setError({ type: "incorrect duration" });
      return;
    }
    let type = {
      denomination: denomination,
      duree: duree,
      couleur: color,
    };
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_TYPE}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: type,
    })
      .then((response) => {
        setMessage(true);
        setDenomination("");
        setColor("#fff");
        setDuree("");
        setTimeout(() => setMessage(false), 3000);
        props.addType(response.data);
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          // console.log(errors)
          setError(errors);
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  return (
    <div className="row" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("add type")}</h1>
        <div className="row">
          <div className="col-10 offset-1">
            <form onSubmit={addType} className="form-user-new">
              <div style={{ textAlign: "center", height: "50px" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => (
                    <div className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the type has been successfully saved")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  required
                  value={denomination}
                  onChange={(e) => setDenomination(e.target.value)}
                  id="denomination"
                  className="fadeIn form-control"
                  name="_denomination"
                  placeholder={t("denomination")}
                />
              </div>
              <div className="form-group ">
                <div className="color-picker">
                  <div
                    className="d-flex justify-content-between w-100"
                    style={showColorPicker ? { marginBottom: 10 } : {}}
                  >
                    <span className="bg-color-picker" style={{ backgroundColor: color }}></span>
                    <button
                      type="button"
                      className="btn-color-picker"
                      onClick={() => setshowColorPicker((showColorPicker) => !showColorPicker)}
                    >
                      {showColorPicker ? t("apply the choice") : t("pick up a color")}
                    </button>
                  </div>
                  {showColorPicker && (
                    <ChromePicker
                      color={color}
                      style={{ marginTop: 5 }}
                      onChange={(updateColor) => setColor(updateColor.hex)}
                    />
                  )}
                </div>
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  value={duree}
                  onChange={(e) => setDuree(e.target.value)}
                  id="duree"
                  className={`fadeIn form-control ${
                    showError && errorBorder.includes("duree") ? "error" : ""
                  }`}
                  name="_duree"
                  placeholder={t("default duration")}
                />
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  onClick={() => props.setPage("listtype")}
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(Newtype);
