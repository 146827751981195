import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenPlanning from "../screens/screenRhLifeline/planningMember";
import ScreenPrevision from "../screens/screenRhStaffing";
import ScreenTimeTable from "../screens/screenRhTimeTable";
import ScreenRecommandation from "../screens/screenRhAssignment/recommandations";
import ScreenDesederataFollowUp from "../screens/screenRhDesiderataFollowUp/listDesederata";
import ScreenDesederataManagement from "../screens/screenRhDesiderataManagement/listDesiderataOnGoing";
import ScreenRhContraints_Hierarchy from "../screens/screenRhContraints_Hierarchy";
import AccesDenied from "../../components/not-found/accesDenied";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Permission from "../../utils/permissions";
import axios from "axios";
import { API } from "../../utils/api";
import ReactLoading from "react-loading";
import ScreenPlanningsLaunch from "../screens/screenRhPlanningLaunch";

const HeaderRh = (props) => {
  const [screen, setScreen] = useState("");
  const [load, setload] = useState(false);
  const [data, setData] = useState(null);
  const [optionsFunctions, setOptionsFunctions] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const { t, i18n } = useTranslation(["rh"]);
  const member_id = 12;

  const redirection = () => {
    let screen = "";
    if (Permission.hasRole("RH LIFELINE", props.roles)) screen = "screen rh lifeline";
    else if (Permission.hasRole("RH ASSIGNMENT", props.roles)) screen = "screen rh assignment";
    else if (Permission.hasRole("RH TIMETABLE", props.roles)) screen = "screen rh timetable";
    else if (Permission.hasRole("RH DESIDERATA FOLLOWUP", props.roles))
      screen = "screen rh desiderata follow-up";
    else if (Permission.hasRole("RH DESIDERATA MANAGEMENT", props.roles))
      screen = "screen rh desiderata management";
    else if (Permission.hasRole("RH PLANNING LAUNCH", props.roles))
      screen = "screen rh planning launch";
    else if (Permission.hasRole("RH PLANNING CONTRAINTS", props.roles))
      screen = "screen rh planning contraints";
    setScreen(screen);
  };

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      if (props.location.state === undefined) {
        redirection();
      } else setScreen(props.location.state && props.location.state.hash);
    } else {
      if (
        props.location.state === null ||
        props.location.state === "" ||
        props.location.state === undefined
      )
        redirection();
      else setScreen(props.location.state);
    }
  }, [props.location.state]);

  useEffect(() => {
    let status = [],
      functions = [];
    axios
      .get(`${API.ENDPOINT + API.RH_GETFUNCTIONSSTATUS}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        setload(true);
        res.data &&
          res.data.functions &&
          res.data.functions.map((f, index) => {
            functions = [...functions, { value: f.index, label: f.name }];
          });
        res.data &&
          res.data.status &&
          res.data.status.map((s, index) => {
            status = [...status, { value: s.index, label: s.name }];
          });

        setOptionsFunctions([{ value: 0, label: t("choose function") }, ...functions]);
        setOptionsStatus([{ value: 0, label: t("choose particular") }, ...status]);
      })
      .catch((error) => {
        setload(false);
        console.error("There was an error!", error);
      });
  }, []);

  const renderScreen = () => {
    if (load === true && optionsFunctions.length > 0 && optionsStatus.length > 0) {
      switch (screen) {
        case "screen rh assignment":
          return <ScreenRecommandation functions={optionsFunctions} />;
        case "screen rh timetable":
          return <ScreenTimeTable member_id={member_id} />;
        case "screen rh desiderata follow-up":
          return <ScreenDesederataFollowUp member_id={member_id} />;
        case "screen rh desiderata management":
          return <ScreenDesederataManagement member_id={member_id} />;
        case "screen rh planning launch":
          return <ScreenPlanningsLaunch />;
        case "screen rh lifeline":
          return <ScreenPlanning status={optionsStatus} functions={optionsFunctions} />;
        case "screen rh planning contraints":
          return <ScreenRhContraints_Hierarchy />;
        default:
          return null;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  if (
    Permission.hasRole("RH LIFELINE", props.roles) ||
    Permission.hasRole("RH ASSIGNMENT", props.roles) ||
    Permission.hasRole("RH TIMETABLE", props.roles) ||
    Permission.hasRole("RH DESIDERATA FOLLOWUP", props.roles) ||
    Permission.hasRole("RH DESIDERATA MANAGEMENT", props.roles) ||
    Permission.hasRole("RH PLANNING LAUNCH", props.roles) ||
    Permission.hasRole("RH PLANNING CONTRAINTS", props.roles)
  ) {
    return (
      <>
        <div className="main-screen container-rh">
          <h2 className="h2">{t("hr planner")}</h2>

          <ul className="nav nav-tabs rh-menu">
            {Permission.hasRole("RH LIFELINE", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh lifeline")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh lifeline" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("lifeline").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH ASSIGNMENT", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh assignment")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh assignment" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("assignment").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH DESIDERATA FOLLOWUP", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh desiderata follow-up")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh desiderata follow-up" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("desiderata follow-up").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH DESIDERATA MANAGEMENT", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh desiderata management")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh desiderata management" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("desiderata management").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH TIMETABLE", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh timetable")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh timetable" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("timetable").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH PLANNING LAUNCH", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh planning launch")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh planning launch" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("planning launch").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("RH PLANNING CONTRAINTS", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("screen rh planning contraints")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "screen rh planning contraints" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("planning contraints").toUpperCase()}
                </a>
              </li>
            )}
          </ul>

          {renderScreen()}
        </div>
      </>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderRh);
