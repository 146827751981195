import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "42%",
  },
};

const PopUpCauseCancelDesiderata = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [load, setLoad] = useState(false);
  const [cause, setCause] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [desiderata, setdesiderata] = useState(props.desiderata);

  const PopUpCancelDesiderata = () => {
    setIsOpen(true);
  };
  const hidePopup = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      PopUpCancelDesiderata: PopUpCancelDesiderata,
    };
  });

  const onCancel = () => {
    setCause("");
  };

  const CancelDesiderata = (e) => {
    e.preventDefault();
    props.desiderata.cause = cause;
    props.cancelDesiderata(props.desiderata);
    setIsOpen(false);
    onCancel();
  };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={hidePopup} ariaHideApp={false} style={customStyles}>
      <div className="popup">
        <div className="d-flex justify-content-end">
          <button onClick={hidePopup} className="btn-close">
            X
          </button>
        </div>
        <form onSubmit={CancelDesiderata} className="form-user-new">
          <div className="text-center" style={{ marginBottom: "20px" }}>
            <input type="submit" className="action" value={t("valid")} />
          </div>
          <div className="d-flex">
            <textarea
              class="form-control w-m-85"
              id="description"
              rows="3"
              placeholder={t("reason")}
              onChange={(e) => setCause(e.target.value)}
            >
              {cause}
            </textarea>
          </div>
        </form>
      </div>
    </Modal>
  );
});
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PopUpCauseCancelDesiderata,
);
