import clsx from "clsx";
import { SuspenseLoader } from "../../../components/loader";
import React, { Fragment, useEffect, useState } from "react";
import * as Permission from "../../../utils/permissions";
import PatientsToTreatScreen from "./patientsToTreatScreen";
import { Accommodation } from "./accommodation";
import { PrintLabel } from "./print-label";
import { BedManagement } from "./bed-management";
import { Journey } from "./journey";
import NoBedNotification from "../../../components/NoBedNotification";
import { useTranslation } from "react-i18next";

export default function BedManagerScreen({ roles }) {
  const { t } = useTranslation(["hospitalisation"]);
  const [currentScreen, setCurrentScreen] = useState("patientsToTreat");
  const [currentScreenContent, setCurrentScreenContent] = useState("patientsToTreat");

  const screens = {
    patientsToTreat: {
      requiredRole: "BED_MANAGER PATIENTS WITH NO BED",
      label: "PATIENTS TRAIT.",
      content: <PatientsToTreatScreen />,
    },
    accommodation: {
      requiredRole: "BED_MANAGER ACCOMODATION",
      label: "HÉBERGEMENT",
      content: <Accommodation/>,
    },
    journey: {
      requiredRole: "BED_MANAGER JOURNEY",
      label: "PARCOURS",
      content: <Journey />,
    },
    bedManagement: {
      requiredRole: "BED_MANAGER BED MANAGEMENT",
      label: "GESTION LITS",
      content: <BedManagement />,
    },
    patientPlacement: {
      requiredRole: "BED_MANAGER BED MANAGEMENT",
      label: "PATIEN_EN_PLACEMENT",
      content: <BedManagement specialFliter={2}/>,
    },
    annomalie: {
      requiredRole: "BED_MANAGER BED MANAGEMENT",
      label: "ANNOMALIE",
      content: <BedManagement specialFliter={1}/>,
    },
    printLabel: {
      requiredRole: "BED_MANAGER PRINT LABEL",
      label: "IMPR. ETIQUETTE",
      content: <PrintLabel />,
    },
  };

  useEffect(()=>{
    setCurrentScreenContent(null);
    setCurrentScreenContent(screens[currentScreen].content);
  },[currentScreen])


  return (
    <div className="main-screen">
      <h2 className="h2">BED MANAGEMENT</h2>
      <ul className="nav nav-tabs">
        {Object.entries(screens).map(([id, { requiredRole, label, disabled }]) => (
          <Fragment key={id}>
            {Permission.hasRole(requiredRole, roles) ? (
              <li className="nav-item">
                {label=="PATIENTS TRAIT."?
                <NoBedNotification element={
                  <a
                    href="#"
                    type="button"
                    onClick={() => (disabled ? null : setCurrentScreen(id))}
                    className={clsx(
                      "nav-link",
                      currentScreen === id ? "active disabled" : "",
                      disabled ? "disabled" : "",
                    )}
                  >
                    {t(label.toUpperCase()).toUpperCase()}
                  </a>
                } hide={currentScreen === id} />
                :(
                  <a
                    href="#"
                    type="button"
                    onClick={() => (disabled ? null : setCurrentScreen(id))}
                    className={clsx(
                      "nav-link",
                      currentScreen === id ? "active disabled" : "",
                      disabled ? "disabled" : "",
                    )}
                  >
                    {t(label.toUpperCase()).toUpperCase()}
                  </a>
                )}
              </li>
            ) : null}
          </Fragment>
        ))}
      </ul>
      <SuspenseLoader>{currentScreenContent}</SuspenseLoader>
    </div>
  );
}
