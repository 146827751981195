import React, { useState, useEffect } from "react";
import "./index.css";
import { API } from "../../utils/api";
import axios from "axios";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare, BsFillPersonPlusFill } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import { Capitalize } from "../../utils/functions";
import "react-confirm-alert/src/react-confirm-alert.css";

const ListGroups = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [globalGroupData, setGlobalGroupData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.USER_ALLGROUPS}`,
      data: {},
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalGroupData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error inshow !", error);
      });
  }, []);

  const confirmationDelete = (group) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this group?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteGroup(group),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteGroup = (group) => {
    setLoad(false);
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.USER_DELETEGROUP}`,
      data: { group },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        let groups = globalGroupData.filter((groupData) => groupData.index !== group.index);
        setGlobalGroupData(groups);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
        // console.log(e)
      });
  };

  const renderListGroups = () => {
    return globalGroupData.map((group, i) => {
      return (
        <tr key={group.index}>
          <th scope="row">{i}</th>
          <td>{group.name}</td>
          <td>
            {group.Roles &&
              group.Roles.map((role, index) => {
                if (window.innerWidth <= 991.98) {
                  return (
                    <>
                      <span className="label primary">
                        {role.name.toLowerCase() == "admin" ||
                        role.name.toLowerCase() === "super admin"
                          ? t(role.name).toUpperCase()
                          : t("screen " + role.name).toUpperCase()}
                      </span>
                      <br />
                    </>
                  );
                }
                if (window.innerWidth <= 1400) {
                  if (index % 2 == 0)
                    return (
                      <>
                        <br />
                        <span className="label primary">
                          {role.name.toLowerCase() === "admin" ||
                          role.name.toLowerCase() === "super admin"
                            ? t(role.name).toUpperCase()
                            : t("screen " + role.name).toUpperCase()}
                        </span>
                      </>
                    );
                } else if (index % 3 == 0)
                  return (
                    <>
                      <span className="label primary">
                        {role.name.toLowerCase() === "admin" ||
                        role.name.toLowerCase() === "super admin"
                          ? t(role.name).toUpperCase()
                          : t("screen " + role.name).toUpperCase()}
                      </span>
                      <br />
                    </>
                  );
                return (
                  <span className="label primary">
                    {role.name.toLowerCase() === "admin" ||
                    role.name.toLowerCase() === "super admin"
                      ? t(role.name).toUpperCase()
                      : t("screen " + role.name).toUpperCase()}
                  </span>
                );
              })}
          </td>
          <td>
            <a href={`/users/group/${group.index}/edit`} title="Editer" className="btn">
              <BsPencilSquare style={{ color: "", fontSize: 24 }} />
            </a>
            <a onClick={() => confirmationDelete(group)} title="Delete" className="btn">
              <BsTrash style={{ color: "red", fontSize: 24 }} />
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="row mrg-user" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("list of groups")}</h1>
        <div className="row">
          <div className="col-12" style={{ textAlign: "end", marginTop: 20 }}>
            <a
              href="/users/newGroup"
              style={{ marginLeft: 20 }}
              className="btn btn-user-1 btn-primary"
            >
              <BsFillPersonPlusFill style={{ color: "white", fontSize: 18, marginTop: -2 }} />
              {t("new group")}
            </a>
          </div>
        </div>
        {load ? (
          <div>
            <table
              className={`table  table-user table-striped table-bordered   ${
                window.innerWidth <= 991.98 && "table-responsive"
              }`}
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col" style={{ width: "5%" }}>
                    #
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    {Capitalize(t("name of Group"))}
                  </th>
                  <th scope="col" style={{ width: "50%" }}>
                    {Capitalize(t("roles"))}
                  </th>
                  <th scope="col" style={{ width: "25%" }}>
                    {Capitalize(t("actions"))}
                  </th>
                </tr>
              </thead>
              {globalGroupData.length >= 1 && <tbody>{renderListGroups()}</tbody>}
            </table>
            {globalGroupData.length === 0 && !load && (
              <p style={{ textAlign: "center", fontSize: 16 }}>{t("no groups found")}</p>
            )}
          </div>
        ) : (
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListGroups);
