import React, { useMemo, Fragment, useState, useEffect } from "react";
import { some, groupBy } from "lodash";
import {
  format,
  differenceInMilliseconds,
  startOfDay,
  addDays,
  addMilliseconds,
  addHours,
  differenceInHours,
  isWithinInterval,
} from "date-fns";
import ReactTooltip from "react-tooltip";
import "./style.scss";
import { Capitalize } from "../../utils/functions";
import { p2pStatus } from "../../utils/p2p-status";
import { bemPatientName } from "../../utils/bem-patient-name";
import { FaArrowAltCircleRight, FaArrowRight, FaMemory } from "react-icons/fa";
import clsx from "clsx";
import moment from "moment";

const D_TO_MS = 24 * 60 * 60 * 1000;

const tooltipEventTypes = {
  click: {
    button: {
      "data-tip": "custom show and hide",
      "data-event": "click focus",
    },
    tooltip: {
      globalEventOff: "click",
      clickable: true,
    },
  },
  mouseover: {
    button: {
      "data-tip": true,
    },
    tooltip: {},
  },
  "mouseover-delayed": {
    button: {
      "data-tip": true,
    },
    tooltip: {
      clickable: true,
      eventOff: "",
      globalEventOff: "click",
      delayHide: 50,
      delayShow: 150,
    },
  },
};

export function Gantt({ from, to, now, columns, rows, anomalies }) {
  const cellPerc = useMemo(() => makeCellPerc(from, to), [from, to]);
  const days = useMemo(() => Array.from(rangeDates(from, to)), [from, to]);

  const [showRowsObject,setShowRowsObject]=useState(null);
  const [showRows,setShowRows]=useState([])

  useEffect(()=>{
    let returnValue=[];
    rows.map((row) => (
      returnValue.push(
        <tr key={row.key} className={clsx(row.isAccommodated ? "gtt--accommodated" : "") + (row.backgroundVariant?"gtt-colomnVariant":"")}>
          {row.head ? (
            <td colSpan={Object.keys(columns).length + 1}>
              <div
                className="gtt--truncate gtt--h"
                style={{ fontWeight: "900", fontSize: 15, color: "black" }}
                title={row.head}
              >
                {row.head}
              </div>
            </td>
          ) : (
            <>
              {Object.keys(columns).map(function (key) {
                const data =
                  typeof row.data[key] === "function" ? row.data[key](row) : row.data[key];
                return (
                  <td key={key}>
                    <div className="gtt--truncate gtt--h" title={data}>
                      {data}
                    </div>
                  </td>
                );
              })}
              <td>
                <div className="gtt--h gtt--data-badges">
                  {row.data.actions
                    ? typeof row.data.actions === "function"
                      ? row.data.actions(row)
                      : row.data.actions
                    : null}
                </div>
              </td>
            </>
          )}
        </tr>
      ))
    );
    setShowRows(rows);
    
    setShowRowsObject(returnValue);
  },[rows]);


  return (
    <div className="gtt">
      <table className="gtt--data">
        <thead>
          <tr>
            {Object.entries(columns).map(([key, label]) => (
              <th key={key} className="gtt--header">
                <div className="gtt--truncate gtt--h">{label}</div>
              </th>
            ))}
            <th className="gtt--header"></th>
          </tr>
        </thead>
        <tbody>
          {showRowsObject}
        </tbody>
      </table>
      <div className="gtt--cells">
        <div className="gtt--header gtt--h">
          {days
            .filter((day, index, days) => !(index % Math.ceil(days.length / 18)))
            .map((day) => (
              <div key={day.getTime()+" "} style={{ left: `${cellPerc(day)}%` }}>
                {format(day, "dd.MM")}
              </div>
            ))}
        </div>
        <div className="gtt--draw">
          {days

            .filter((day, index, days) => !(index % Math.ceil(days.length / 200)))

            .map((day, dayIndex) => (
              <div
                key={day.getTime()+" "+dayIndex}
                className="gtt--day-separator"
                style={{ left: `${cellPerc(day)}%` }}
              />
            ))}
          {days

            .filter((day, index, days) => !(index % Math.ceil(days.length / 200)))
            .map((day) => (
              <div
                key={day.getTime()}
                className="gtt--mid-day-separator"
                style={{ left: `${cellPerc(addHours(day, 12))}%` }}
              />
            ))}
          {showRows.map(function (row) {
            return (
              <div
                key={row.key}
                className={clsx("gtt--h", row.isAccommodated ? "gtt--accommodated" : "",(row.backgroundVariant?"gtt-colomnVariantCell":""))}
              >
                {row.cells?.map(function (cell, cellIndex, cells) {
                  const nextCell = cells[cellIndex + 1];
                  const gradientTo = cell.gradientTo ?? cell.to;
                  const gradientConf = cell.gradientConf ?? 1;
                  const anomalieErrors = anomalies?.[cell.key] ?? [];

                  let hasAnomalie=anomalieErrors.length > 0;

                  let key=cell.key + " " + cellIndex;

                  return (
                    <Fragment key={key}>
                      <div
                        className="gtt--cell-background"
                        style={
                          hasAnomalie
                            ? {
                                left: `${cellPerc(cell.from, false)}%`,
                                right: `${100 - cellPerc(cell.to, false)}%`,
                                background: "#fb7809",
                              }
                            : {
                                left: `${cellPerc(cell.from, false)}%`,
                                right: `${100 - cellPerc(gradientTo, false)}%`,
                                background: `linear-gradient(to right, ${cell.background}, ${
                                  gradientConf * 100
                                }%, transparent)`,
                              }
                        }
                        onClick={() => console.log(cell)}
                      />
                      <div
                        className={`gtt--cell-border gtt--cell-border-${cell.border ?? "none"}`}
                        style={{
                          left: `${cellPerc(cell.from, false)}%`,
                          right: `${100 - cellPerc(cell.to, false)}%`,
                        }}
                        onClick={() => console.log(cell)}
                      />
                      {cell.children ? (
                        <div
                          className="gtt--cell-children"
                          style={{
                            left: `${cellPerc(cell.from, true)}%`,
                            right: `${100 - cellPerc(nextCell?.from ?? to, false)}%`,
                          }}
                        >
                          {cell.children}
                        </div>
                      ) : (
                        <div
                          className="gtt--cell-content"
                          style={{
                            left: `${cellPerc(cell.from, true)}%`,
                            right: `${100 - cellPerc(nextCell?.from ?? to, false)}%`,
                          }}
                          data-for={cell.tooltip ? `tooltip-${key}` : undefined}
                          {...(tooltipEventTypes[cell.tooltip?.event]?.button ?? {})}
                          onClick={() => console.log(cell)}
                        >
                          {cell.isAccommodation ? (
                            <div className="gtt--badge-accommodation">H</div>
                          ) : null}
                          {cell.isRestitution ? (
                            <div className="gtt--badge-restitution">R</div>
                          ) : null}
                          {cell.isDoubleRoom ? (
                            <div className="gtt--badge-doubleroom">
                              {cell.isDoubleRoom.toUpperCase()}
                            </div>
                          ) : null}
                          {cell.isBedblocker ? (
                            <div className="gtt--badge-bedblocker">!</div>
                          ) : null}
                          {cell.isMoving ? <div className="gtt--badge-moving">➡</div> : null}
                          {cell.isLeave24h ? <div className="gtt--badge-leave24h">24</div> : null}
                          {cell.isLeave48h ? <div className="gtt--badge-leave48h">48</div> : null}
                          <div className="gtt--cell-content-truncate">{cell.label}</div>
                        </div>
                      )}
                      {cell.arrowTo ? (
                        <div
                          className="gtt--cell-arrow-to"
                          style={{
                            // INFO: the center of the current cell
                            left: `${cellPerc(
                              addMilliseconds(
                                cell.to,
                                (cell.to.getTime() - cell.from.getTime()) * -0.5,
                              ),
                              true,
                            )}%`,
                            // INFO: to the next cell
                            right: `${100 - cellPerc(cell.arrowTo, false)}%`,
                          }}
                        >
                          <span></span>
                        </div>
                      ) : null}
                      {cell.tooltip ? (
                        <ReactTooltip
                          id={`tooltip-${key}`}
                          className="gtt--tooltip"
                          place="left"
                          effect="solid"
                          {...(tooltipEventTypes[cell.tooltip?.event]?.tooltip ?? {})}
                        >
                          <ul>
                            {cell.tooltip.data?.map(({ label, value }, index) => (
                              <li key={index}>
                                {label}: {value}
                              </li>
                            )) ?? null}
                            {anomalieErrors.length > 0 ? (
                              <li>Anomalie: {anomalieErrors.join(", ")}</li>
                            ) : null}
                          </ul>
                          {cell.tooltip.actions ? (
                            <div className="gtt--tooltip-actions">{cell.tooltip.actions}</div>
                          ) : null}
                        </ReactTooltip>
                      ) : null}
                    </Fragment>
                  );
                }) ?? null}
              </div>
            );
          })}
          <div className="gtt--now-separator" style={{ left: `${cellPerc(now)}%` }} />
        </div>
      </div>
    </div>
  );
}

/**
 * Returns a function that calculates the percentage of time between two dates for a given date.
 *
 * @function makeCellPerc
 * @param {Date} from - The from date of the time range.
 * @param {Date} to - The to date of the time range.
 * @returns {Function} - A function that calculates the percentage of time between `from` and `to` for a given date.
 */
function makeCellPerc(from, to) {
  const fromTimestamp = from.getTime();
  const toTimestamp = to.getTime();

  /**
   * Calculates the percentage of time between two dates for a given date.
   *
   * @function cellPerc
   * @param {Date} date - The date to calculate the percentage for.
   * @returns {number} - The percentage of time between `from` and `to` for the given `date`.
   */
  return function (date, limit) {
    const dateTimestamp = date.getTime();

    const perc = ((dateTimestamp - fromTimestamp) / (toTimestamp - fromTimestamp)) * 100;

    if (perc > 100) {
      if (limit) {
        return 100;
      } else if (perc < 200) {
        return perc;
      } else {
        return 200;
      }
    } else if (perc < 0) {
      if (limit) {
        return 0;
      } else if (perc > -100) {
        return perc;
      } else {
        return -100;
      }
    } else {
      return perc;
    }
  };
}

/**
 * Generates a range of dates from a from date to an to date.
 *
 * @generator
 * @function rangeDates
 * @param {Date} from - The from date of the range.
 * @param {Date} to - The to date of the range.
 * @yields {Date} - The next date in the range.
 */
function* rangeDates(from, to) {
  let current = from;

  while (differenceInMilliseconds(to, current) > 0) {
    yield startOfDay(current);
    current = addDays(current, 1);
  }
}

export function colorFromSexe(sexe) {
  switch (sexe?.toLowerCase()) {
    case "f": {
      return "#eba1f5";
    }
    case "m": {
      return "#00c3f5";
    }
    default: {
      return "#e2e2e2";
    }
  }
}

export function item(label, value) {
  return { label, value };
}

function gradientPerc(from, to, max) {
  const B = to.getTime() - from.getTime();
  const C = Math.abs(max.getTime() - from.getTime()) || 1;

  // A             a             B    b     C
  // |---------------------------|----------|
  //                  |----------|
  //                  D    b
  //
  //  D = B - b;
  //  b = C - B
  //  D = B - (C - B)
  //  D% = D / C
  //  D% = (B - (C - B)) / C

  const perc = (B - (C - B)) / C;

  if (perc < 0.01) {
    return 0.01;
  } else if (perc > 1) {
    return 1;
  } else {
    return perc;
  }
}

export function cellFromLos(los, params) {
  if (los.date_sortie) {
    return cellFromEndedLos(los, params);
  } else {
    return cellFromOngoingLos(los, params);
  }
}

export function cellFromEndedLos(los, { now, t, ufLabels, ufDms, format, actions }) {
  const date_entree = new Date(los.date_entree);
  const date_sortie = new Date(los.date_sortie);
  console.log(date_sortie );

  const isRestitution = los.bedDispatchBedManagementStatus?.status === "RESTITUED";
  const isAccommodation = !isRestitution && Boolean(los.uf_admin);
  return {
    key: los.iep,
    label: `${los.bemPatient ? bemPatientName(los.bemPatient) : los.iep} / ${los.age}`,
    background: colorFromSexe(los.sexe),
    from: date_entree,
    to: date_sortie,
    name:los.bemPatient ? bemPatientName(los.bemPatient) : los.iep,
    sex:los.sexe,
    age:los.age,
    iep:los.iep,
    gradientTo: undefined,
    gradientConf: undefined,
    isLeave24h: false,
    isLeave48h: false,
    isAccommodation: isAccommodation,
    isRestitution: false,
    isMoving: false,
    isBedblocker: false,
    isDoubleRoom: los.lit?.match(/.*(f|p)/i)?.[1].toLowerCase(),
    border: "normal",
    tooltip: {
      event: actions ? "mouseover-delayed" : "mouseover",
      actions,
      data: [
        item("IEP", los.iep),
        item("Prénom", Capitalize(los.bemPatient?.prenom ?? "-")),
        item("Nom", los.bemPatient?.nom_mar.toUpperCase() ?? "-"),
        item("Nom Marital", los.bemPatient?.nom?.toUpperCase()),
        item("Sexe", los.sexe?.toLowerCase()),
        item("Age", `${los.age}`),
        item("Code Diagnostic", los.diag || "-"),
        ...(isAccommodation
          ? [
              item(
                "UF IDEALE",
                ufLabels[los.uf_admin]
                  ? `${ufLabels[los.uf_admin]} (${los.uf_admin})`
                  : los.uf_admin,
              ),
              item("UF HEB", ufLabels[los.uf] ? `${ufLabels[los.uf]} (${los.uf})` : los.uf),
            ]
          : [item("UF", ufLabels[los.uf] ? `${ufLabels[los.uf]} (${los.uf})` : los.uf)]),
        item("Lit", los.Beds?.lit),
        item("Chambre", los.Beds?.chambre),
        item("Date d'entrée", format(date_entree, "Pp")),
        item(
          "Durée de séjour",
          `${(differenceInHours(date_sortie, date_entree) / 24).toFixed(1)} j.`,
        ),
        item("Date de sortie", format(date_sortie, "Pp")),
      ].filter((los) => Boolean(los.value)),
    },
  };
}

export function cellFromOngoingLos(los, { now, t, ufLabels, ufDms, format, actions }) {
  // date_entree                  -> quand il est arrive
  // date_entree + los_moy [day]  -> quand on predit qu'il va partir
  // los_dev                      -> marge d'erreur [+ / -]


  const los_moy = los.los_moy < 0 ? 0 : los.los_moy;
  const los_dev = los.los_dev < 0 ? 0 : los.los_dev;

  const losMoyInMs = los_moy * D_TO_MS;
  const losDevInMs = los_dev * D_TO_MS;

  const date_entree = new Date(los.date_entree);
  const date_sortie = new Date(los.date_sortie);
  const date_sortie_predite = addMilliseconds(date_entree, losMoyInMs);
  const date_sortie_predite_max = addMilliseconds(date_sortie_predite, losDevInMs);

  const ongoing = isWithinInterval(now, { start: date_entree, end: date_sortie_predite });
  const currentDuration = now.getTime() - date_entree.getTime();

  // FIXME: use the technic used bellow for bedDispatching
  const conf = gradientPerc(date_entree, date_sortie_predite, date_sortie_predite_max);

  const bedBlockerDuration = ufDms[los.uf] ? ufDms[los.uf] * (1 + 1) * D_TO_MS : null;

  const movingStatus = p2pStatus(los);

  const isRestitution = los.bedDispatchBedManagementStatus?.status === "RESTITUED";
  const isAccommodation = !isRestitution && Boolean(los.uf_admin);

  return {
    key: los.iep,
    label: `${los.bemPatient ? bemPatientName(los.bemPatient) : los.iep} / ${los.age}`,
    background: colorFromSexe(los.sexe),
    name:los.bemPatient ? bemPatientName(los.bemPatient) : los.iep,
    sex:los.sexe,
    age:los.age,
    iep:los.iep,
    from: date_entree,
    to: date_sortie_predite,
    gradientTo: date_sortie_predite_max,
    gradientConf: conf,
    isLeave24h: isWithinInterval(date_sortie_predite, { start: now, end: addHours(now, 24) }),
    isLeave48h: isWithinInterval(date_sortie_predite, {
      start: addHours(now, 24),
      end: addHours(now, 48),
    }),
    isAccommodation: isAccommodation,
    isRestitution: isRestitution,
    isMoving: Boolean(movingStatus),
    isBedblocker: bedBlockerDuration ? ongoing && currentDuration > bedBlockerDuration : false,
    isDoubleRoom: los.lit?.match(/.*(f|p)/i)?.[1].toLowerCase(),
    isPres24h: (los_dev == 0 && 24>moment(los.date_sortie_predite).diff(moment(),"hours")),
    border: "normal",
    tooltip: {
      event: actions ? "mouseover-delayed" : "mouseover",
      actions,
      data: [
        item("IEP", los.iep),
        item("Prénom", Capitalize(los.bemPatient?.prenom ?? "-")),
        item("Nom", los.bemPatient?.nom_mar.toUpperCase() ?? "-"),
        item("Nom Marital", los.bemPatient?.nom?.toUpperCase()),
        item("Sexe", los.sexe?.toLowerCase()),
        item("Age", `${los.age}`),
        item("Code Diagnostic", los.diag || "-"),
        ...(isAccommodation
          ? [
              item(
                "UF IDEALE",
                ufLabels[los.uf_admin]
                  ? `${ufLabels[los.uf_admin]} (${los.uf_admin})`
                  : los.uf_admin,
              ),
              item("UF HEB", ufLabels[los.uf] ? `${ufLabels[los.uf]} (${los.uf})` : los.uf),
            ]
          : [item("UF", ufLabels[los.uf] ? `${ufLabels[los.uf]} (${los.uf})` : los.uf)]),
        item("Lit", los.Beds?.lit),
        item("Chambre", los.Beds?.chambre),
        item("Date d'entrée", format(date_entree, "Pp")),
        item(
          "Durée de séjour actuelle",
          `${(differenceInHours(now, date_entree) / 24).toFixed(1)} j.`,
        ),
        item("Date de sortie prédite", format(date_sortie_predite, "Pp")),
        item("Marge d'erreur", los.los_dev ? `+/- ${los.los_dev.toFixed(1)} jours` : undefined),
        item("Score 48", `${Math.trunc(los.pred_48h * 100)}%`),
        item("Brancardage", movingStatus?.label),
      ].filter((los) => Boolean(los.value)),
    },
  };
}

export function cellFromBedDispatching(bedDispatching, { t, now, format, actions }) {
  const date_in = new Date(bedDispatching.date_in);
  const date_out = new Date(bedDispatching.date_out);

  const losInHours = differenceInHours(date_out, date_in);

  const conf =
    (losInHours - bedDispatching.los_dev_hours) / (losInHours + bedDispatching.los_dev_hours);

  return {
    key: bedDispatching.iep,
    label: `${
      bedDispatching.bemPatient ? bemPatientName(bedDispatching.bemPatient) : bedDispatching.iep
    } / ${bedDispatching.age}`,
    background: colorFromSexe(bedDispatching.sex),
    sex:bedDispatching.sex,
    age:bedDispatching.age,
    iep:bedDispatching.iep,
    name:bedDispatching.bemPatient ? bemPatientName(bedDispatching.bemPatient) : bedDispatching.iep,
    from: date_in,
    to: date_out,
    gradientTo: undefined,
    gradientConf: conf,
    isLeave24h: false,
    isLeave48h: false,
    isAccommodation: false,
    isRestitution: false,
    isMoving: false,
    isBedblocker: false,
    isDoubleRoom: bedDispatching.lit?.match(/.*(f|p)/i)?.[1].toLowerCase(),
    border: bedDispatching.preadm_flag == 1 ? "black-dotted" : "pink-dotted",
    tooltip: {
      event: actions ? "mouseover-delayed" : "mouseover",
      actions,
      data: [
        item("IEP", bedDispatching.iep),
        item("Prénom", Capitalize(bedDispatching.bemPatient?.prenom ?? "-")),
        item("Nom", bedDispatching.bemPatient?.nom_mar.toUpperCase() ?? "-"),
        item("Nom Marital", bedDispatching.bemPatient?.nom?.toUpperCase()),
        item("Sexe", bedDispatching.sex?.toLowerCase()),
        item("Age", `${bedDispatching.age}`),

        item("Lit", bedDispatching.Beds?.lit),
        item("Chambre", bedDispatching.Beds?.chambre),
        item("Date d'entrée", format(date_in, "Pp")),
        // item("Durée de séjour", `${(differenceInHours(now, date_in) / 24).toFixed(1)} j.`),
        item("Marge d'erreur", `+/- ${bedDispatching.los_dev.toFixed(1)} jours`),
      ].filter((bedDispatching) => Boolean(bedDispatching.value)),
    },
  };
}

export function paginateRows(rows, page, nbPerPage = 30) {
  return [
    rows.slice(page * nbPerPage, (page + 1) * nbPerPage),
    { pageMax: Math.trunc(rows.length / nbPerPage) },
  ];
}
