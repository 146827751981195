import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import { Capitalize } from "../../../utils/functions";

Moment.globalTimezone = "Europe/Paris";

let orderT = { A: 1, T: 2, E: 3, L: 4, I: 5, D: 6, S: 7, exit: 8 };
let orderType = {
  Arrivée: 1,
  Triage: 2,
  "Examen médical": 3,
  Laboratoire: 4,
  Imagerie: 5,
  "Fin de PEC Médicale": 6,
  avisdemande: 7,
  out: 8,
};
let types = [
  ["A", "Arrivée"],
  ["T", "Triage"],
  ["E", "Examen médical"],
  ["L", "Laboratoire"],
  ["I", "Imagerie"],
  ["D", "Fin de PEC Médicale"],
  ["S", "avisdemande"],
  ["exit", "out"],
];

const ScreenJourneyPatients = (props) => {
  const { t, i18n } = useTranslation(["journey"]);
  const [selectedOption, setSelected] = useState({
    value: "heure_entree",
    label: Capitalize(t("admission time")),
  });

  const [globalDataPatients, setGlobalDataPatients] = useState(null);
  const dataPatients = props.data.Prediction_Flux;

  useEffect(() => {
    renderData();
    setInterval(() => {
      renderData();
    }, 60000);
  }, [selectedOption.value]);

  const renderData = () => {
    let patients = [...dataPatients];

    if (selectedOption.value === "heure_sortie") {
      patients.sort((a, b) => tri(a, b, "heure_sortie"));
      setGlobalDataPatients(patients);
      return;
    } else if (selectedOption.value === "heure_entree") {
      patients.sort((a, b) => tri(a, b, "heure_entree"));
      setGlobalDataPatients(patients);

      return;
    } else if (selectedOption.value === "hospitalisation") {
      patients.sort((a, b) => tri(a, b, "heure_sortie"));
      let data = patients.filter((e) => {
        let event = getEvents(e.SeqPredEvent)["event"];
        return event["type_out"];
      });

      setGlobalDataPatients(data);
      return;
    } else if (selectedOption.value === "sortie") {
      patients.sort((a, b) => tri(a, b, "heure_sortie"));
      let data = patients.filter((e) => {
        let event = getEvents(e.SeqPredEvent)["event"];
        return !event["type_out"] && event["out"];
      });

      setGlobalDataPatients(data);
      return;
    } else setGlobalDataPatients(patients);
  };

  const tri = (a, b, type) => {
    let e1 = getEvents(a.SeqPredEvent);
    let e2 = getEvents(b.SeqPredEvent);
    if (type === "heure_sortie") {
      if (!e1["event"]["out"]) return -1;
      if (!e2["event"]["out"]) return -1;

      return moment(e2["event"]["out"]["event_time"]).diff(
        moment(e1["event"]["out"]["event_time"]),
      ) > 0
        ? -1
        : 1;
    } else if (type === "heure_entree") {
      if (!e1["event"]["Arrivée"]) return -1;
      if (!e2["event"]["Arrivée"]) return -1;

      return moment(e1["event"]["Arrivée"]["event_time"]).diff(
        moment(e2["event"]["Arrivée"]["event_time"]),
      ) > 0
        ? 1
        : -1;
    }
  };

  const renderHeader = () => {
    return (
      <div className="row fix-header bg">
        <div className="col-12 col-lg-4">
          <div className="row div-title">
            <div className="col-6 offset-6 font-table-title">
              {Capitalize(t("estimated time to"))}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row div-title" style={{ textAlign: "center" }}>
                <div className="col-4 font-table-title">{t("iep")}</div>

                <div className="col-4 font-table-title">{Capitalize(t("next step"))}</div>
                <div className="col-4 font-table-title">{Capitalize(t("discharge"))}</div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row table-div-2" style={{ textAlign: "center" }}>
                <div className="font-table-title first-col">{Capitalize(t("admission"))}</div>
                <div className="font-table-title ">{Capitalize(t("sorting"))}</div>
                <div className="font-table-title ">{Capitalize(t("exam"))}</div>
                <div className="font-table-title ">{Capitalize(t("labo"))}</div>
                <div className="font-table-title ">{Capitalize(t("radio"))}</div>
                <div className="font-table-title ">{Capitalize(t("diag"))}</div>
                <div className="font-table-title ">{Capitalize(t("notice"))}</div>
                <div className="font-table-title ">{Capitalize(t("estimated discharge time"))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTableResponsive = () => {
    let pred = globalDataPatients;
    return pred.map((patient, i) => {
      if (patient.anomaly === true) return;

      let data = getEvents(patient.SeqPredEvent);
      let firstEvent = data["firstEvent"] ? roundNearest15M(data["firstEvent"], "15") : null;
      let outDate = data["event"]["out"]
        ? moment(data["event"]["out"]["event_time"]).add("1", "hours").format("YYYY-MM-DD HH:00")
        : null;

      return (
        <div
          key={i}
          className="row row-0-table"
          style={{
            backgroundColor: i % 2 === 0 ? "white" : "rgb(251 250 250)",
          }}
        >
          <div className="col-12 col-lg-4">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-4 font-table-body">
                {patient.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
              </div>

              <div className="col-4 font-table-body" style={{ color: "#00b0f0" }}>
                {firstEvent
                  ? moment(firstEvent).diff(moment(), "minutes") >= 0
                    ? moment(firstEvent).diff(moment(), "minutes") + "'"
                    : "0"
                  : outDate
                  ? moment(outDate).diff(moment(), "minutes") >= 0
                    ? moment(outDate).diff(moment(), "minutes") + "'"
                    : "0"
                  : "indisponible"}
              </div>
              <div className="col-4 font-table-body" style={{ color: "#00b0f0" }}>
                {outDate
                  ? moment(outDate).diff(moment(), "minutes") >= 0
                    ? Math.round(moment(outDate).diff(moment(), "minutes") / 60) +
                      `${
                        Math.round(moment(outDate).diff(moment(), "minutes") / 60) > 1
                          ? ` ${t("hours")}`
                          : ` ${t("hour")}`
                      }`
                    : "0"
                  : "indisponible"}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div
              className="row table-div-2"
              style={{
                textAlign: "center",
                marginTop: "25px",
                height: "60px",
                position: "relative",
              }}
            >
              {types.map((t, index) => {
                let event = data["event"];
                let prog = data["prog"];

                if (t[0] === "exit" && event["out"]) {
                  return (
                    <div key={index} className={`two-div-out-plage`}>
                      <div className={`div-1`}>
                        <span style={{ fontWeight: "bold" }}>
                          {moment(event["out"]["event_time"]).format("HH:00")}
                          <br />
                          {moment(event["out"]["event_time"]).add("1", "hours").format("HH:00")}
                        </span>
                        <br></br>
                      </div>
                    </div>
                  );
                }

                if (event[t[1]] && t[0] !== "exit") {
                  let date =
                    t[0] === "A"
                      ? moment(event[t[1]]["event_time"]).subtract("10", "minutes")
                      : roundNearest15M(event[t[1]]["event_time"], "15");
                  let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                  return (
                    <div key={index} className="two-div">
                      <div className={`${moment(date).diff(m(startOfday)) < 0 && "Anomalie"} `}>
                        {moment(date).format("HH:mm")}
                      </div>

                      <div>
                        <span
                          className={`span-1 span-A${
                            event[t[1]]["confidence"] < 1
                              ? event[t[1]]["confidence"] >= 0.9
                                ? "1"
                                : event[t[1]]["confidence"] >= 0.8
                                ? "2"
                                : "3"
                              : ""
                          }`}
                        >
                          {t[0]}
                        </span>
                        {index < 7 && <span className="span-prog-dashed"></span>}
                        {orderT[t[0]] < prog && <span className="span-prog"></span>}
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} className="two-div">
                    <div></div>
                    <div>
                      <span className={`span-1`}></span>
                      {index < 7 && <span className="span-prog-dashed-second"></span>}
                      {orderT[t[0]] < prog && <span className="span-prog"></span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderTable = () => {
    let pred = globalDataPatients;
    return pred.map((patient, i) => {
      if (patient.anomaly === true) return;

      let data = getEvents(patient.SeqPredEvent);
      let firstEvent = data["firstEvent"] ? roundNearest15M(data["firstEvent"], "15") : null;
      let outDate = data["event"]["out"]
        ? moment(data["event"]["out"]["event_time"]).add("1", "hours").format("YYYY-MM-DD HH:00")
        : null;

      return (
        <div
          key={i}
          className="row row-0-table"
          style={{
            backgroundColor: i % 2 === 0 ? "white" : "rgb(251 250 250)",
          }}
        >
          <div className="col-4">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-4">
                {patient.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
              </div>

              <div
                className="col-4"
                style={{
                  color: "#00b0f0",
                  fontWeight: "bold",
                  fontSize: "1vw",
                }}
              >
                {firstEvent
                  ? moment(firstEvent).diff(moment(), "minutes") >= 0
                    ? moment(firstEvent).diff(moment(), "minutes") + "'"
                    : "0"
                  : outDate
                  ? moment(outDate).diff(moment(), "minutes") >= 0
                    ? moment(outDate).diff(moment(), "minutes") + "'"
                    : "0"
                  : "indisponible"}
              </div>
              <div
                className="col-4"
                style={{
                  color: "#00b0f0",
                  fontWeight: "bold",
                  fontSize: "1vw",
                }}
              >
                {outDate
                  ? moment(outDate).diff(moment(), "minutes") >= 0
                    ? Math.round(moment(outDate).diff(moment(), "minutes") / 60) +
                      `${
                        Math.round(moment(outDate).diff(moment(), "minutes") / 60) > 1
                          ? ` ${Capitalize(t("hours"))}`
                          : ` ${Capitalize(t("hour"))}`
                      }`
                    : "0"
                  : Capitalize("indisponible")}
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row table-div-2" style={{ textAlign: "center" }}>
              {/* <p className="span-prog" style={{position:'absolute',width : `10%`}}>  </p> */}

              {types.map((t, index) => {
                let event = data["event"];
                let prog = data["prog"];

                if (t[0] === "exit" && event["out"]) {
                  return (
                    <div key={index} className={`two-div-out-plage`}>
                      <div className={`div-1`}>
                        <span style={{ fontWeight: "bold" }}>
                          {moment(event["out"]["event_time"]).format("HH:00")}
                          <br />
                          {moment(event["out"]["event_time"]).add("1", "hours").format("HH:00")}
                        </span>
                        <br></br>
                      </div>
                    </div>
                  );
                }

                if (event[t[1]] && t[0] !== "exit") {
                  let date =
                    t[0] === "A"
                      ? moment(event[t[1]]["event_time"]).subtract("10", "minutes")
                      : roundNearest15M(event[t[1]]["event_time"], "15");
                  let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                  return (
                    <div key={index} className="two-div">
                      <div className={`${moment(date).diff(m(startOfday)) < 0 && "Anomalie"} `}>
                        {moment(date).format("HH:mm")}
                      </div>

                      <div>
                        <span
                          className={`span-1 span-A${
                            event[t[1]]["confidence"] < 1
                              ? event[t[1]]["confidence"] >= 0.9
                                ? "1"
                                : event[t[1]]["confidence"] >= 0.8
                                ? "2"
                                : "3"
                              : ""
                          }`}
                        >
                          {t[0]}
                        </span>
                        {index < 7 && <span className="span-prog-dashed"></span>}
                        {orderT[t[0]] < prog && <span className="span-prog"></span>}
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} className="two-div">
                    <div></div>
                    <div>
                      <span className={`span-1`}></span>
                      {index < 7 && <span className="span-prog-dashed-second"></span>}
                      {orderT[t[0]] < prog && <span className="span-prog"></span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const roundNearest15M = (date, min) => {
    const roundedUp = Math.ceil(moment(date).minute() / min) * min;

    return moment(date).minute(roundedUp).second(0);
  };

  const getEvents = (events) => {
    let data = events.filter(
      (patient) =>
        patient.event_type.includes("RX standard") ||
        patient.event_type.includes("Fin de PEC Médicale") ||
        patient.event_type.includes("Arrivée") ||
        patient.event_type.includes("uf") ||
        patient.event_type.includes("avisdemande") ||
        patient.event_type.includes("out") ||
        patient.event_type.includes("Triage") ||
        patient.event_type.includes("Imagerie autre") ||
        patient.event_type.includes("Examen médical") ||
        patient.event_type.includes("Laboratoire"),
    );

    let prog = 0;
    let firstEvent;
    let test = false;

    const arrayToObject = (data) =>
      data.reduce((obj, patient) => {
        if (patient.event_type.includes("out") && !test) {
          obj["out"] = { ...patient };

          if (patient.confidence === 1) prog = orderType["out"];
        } else if (patient.event_type.includes("uf")) {
          obj["out"] = { ...patient };
          obj["type_out"] = patient.event_type;
          test = true;
          if (patient.confidence === 1) prog = orderType["out"];
        } else if (patient.event_type.includes("Arrivée")) {
          obj[patient.event_type] = patient;
          if (patient.confidence === 1 && orderType["Arrivée"] > prog) prog = orderType["Arrivée"];
        } else if (patient.event_type.includes("Triage")) {
          obj[patient.event_type] = patient;
          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }
          if (patient.confidence === "1" && orderType["Triage"] > prog) prog = orderType["Triage"];
        } else if (patient.event_type.includes("Examen médical")) {
          obj[patient.event_type] = patient;
          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }
          if (patient.confidence === 1 && orderType["Examen médical"] > prog)
            prog = orderType["Examen médical"];
          if (patient.confidence < 1 && orderType["Examen médical"] === prog) prog = prog - 1;
        } else if (patient.event_type.includes("Laboratoire")) {
          obj[patient.event_type] = patient;
          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }
          if (patient.confidence === 1 && orderType["Laboratoire"] > prog)
            prog = orderType["Laboratoire"];
          if (patient.confidence < 1 && orderType["Laboratoire"] === prog) prog = prog - 1;
        } else if (
          patient.event_type.includes("RX standard") ||
          patient.event_type.includes("Imagerie autre")
        ) {
          obj["Imagerie"] = patient;
          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }
          if (patient.confidence === 1 && orderType["Imagerie"] > prog)
            prog = orderType["Imagerie"];
          if (patient.confidence < 1 && orderType["Imagerie"] === prog) prog = prog - 1;
        } else if (patient.event_type.includes("Fin de PEC Médicale")) {
          obj[patient.event_type] = patient;

          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }

          if (patient.confidence === 1 && orderType["Fin de PEC Médicale"] > prog)
            prog = orderType["Fin de PEC Médicale"];
          if (patient.confidence < 1 && orderType["Fin de PEC Médicale"] === prog) prog = prog - 1;
        } else if (patient.event_type.includes("avisdemande")) {
          obj[patient.event_type] = patient;

          if (patient.confidence < "1" && moment(patient.event_time).diff(moment()) > 0) {
            if (!firstEvent) {
              firstEvent = patient.event_time;
            } else if (moment(patient.event_time).diff(moment(firstEvent)) < 0) {
              firstEvent = patient.event_time;
            }
          }
          if (patient.confidence === 1 && orderType["avisdemande"] > prog)
            prog = orderType["avisdemande"];
          if (patient.confidence < 1 && orderType["avisdemande"] === prog) prog = prog - 1;
        }

        return obj;
      }, {});

    let result = arrayToObject(data);

    return { event: result, events: data, prog: prog, firstEvent: firstEvent };
  };

  return (
    <div className="row" style={{ paddingTop: "2%" }}>
      <div className="w-100 first-row">
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -10,
            paddingBottom: 30,
          }}
        >
          <p className="title-1">{t("confidence Interval")}</p>
        </div>
      </div>
      <div className="w-100 table-g table-scroll w-fixe ">
        {globalDataPatients && renderHeader()}
        <div className="w-100 w-fixe" style={{ height: "63vh" }}>
          {globalDataPatients
            ? window.innerWidth <= 991.98
              ? renderTableResponsive()
              : renderTable()
            : ""}
        </div>
      </div>
    </div>
  );
};
export default ScreenJourneyPatients;
