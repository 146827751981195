import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useRequest } from "../../../../hooks/use-api";
import { useDebounce } from "../../../../hooks/use-debounce";
import { API } from "../../../../utils/api";
import { bemPatientName } from "../../../../utils/bem-patient-name";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";

const encodeIeps = (ieps) => ieps.join(",");
const decodeIeps = (str) => String(str || "").split(",");

export function Search({ onChange, size }) {
  const request = useRequest();
  const [inputValue, setInputValue] = useState("");
  const searchValue = useDebounce(inputValue, 400);

  const { data: options = [], isLoading } = useQuery(
    ["bem-patients", "search", searchValue],
    async function () {
      if (!searchValue) {
        return [];
      }

      const [bemPatients = []] = await request({
        method: "GET",
        path: API.V5_BEM_PATIENTS_SEARCH(searchValue),
      });

      return bemPatients.map(({ ieps, bemPatient }) => ({
        value: encodeIeps(ieps),
        label: `${bemPatientName(bemPatient)} / ${bemPatient.sexe} (${ieps.length} Parcours)`,
      }));
    },
    {
      suspense: false,
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  return (
    <div style={{ width: "100%" }}>
      <div style={{fontWeight: 'bold', color: 'black', paddingLeft: 2, paddingBottom: 2,}}>Chercher un parcours</div>
      <Select
        autoFocus
        filterOption={() => true}
        placeholder="Prénom, Nom, Nom Marital, IEP ou IPP"
        inputValue={inputValue}
        onInputChange={setInputValue}
        isLoading={isLoading}
        onChange={({ value }) => onChange(decodeIeps(value))}
        options={options || []}
        noOptionsMessage={() => searchValue ? "Aucun patient ne correspond à la recherche" : "Tapez les informations du patient" }
      />
    </div>
  );
}
