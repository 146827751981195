import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useUfLabels() {
  const groupes = useSelector((state) => state.screensHospData.groupes);
  const labels = useMemo(
    () =>
      Object.fromEntries(
        Object.values(groupes).flatMap(({ data }) => data.map(([id, name]) => [id, name])),
      ),
    [groupes],
  );

  return labels;
}
