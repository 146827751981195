import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "chartjs-plugin-annotation";
import Select from "react-select";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import ReactTooltip from "react-tooltip";
import { Triangle } from "react-shapes";
import LogoCovid from "../../../images/covid-19-icon.png";
import LogoAnomalie from "../../../images/anomalie.png";
import "./index.css";

import { Capitalize } from "../../../utils/functions";
import { bemPatientName } from "../../../utils/bem-patient-name";

Moment.globalTimezone = "Europe/Paris";

let orderT = { A: 1, T: 2, E: 3, L: 4, I: 5, D: 6, S: 7, exit: 8 };
let orderType = {
  Arrivée: 1,
  Triage: 2,
  "Examen médical": 3,
  Laboratoire: 4,
  Imagerie: 5,
  "Fin de PEC Médicale": 6,
  avisdemande: 7,
  out: 8,
};
let types = [
  ["A", "Arrivée"],
  ["T", "Triage"],
  ["E", "Examen médical"],
  ["L", "Laboratoire"],
  ["I", "Imagerie"],
  ["D", "Fin de PEC Médicale"],
  ["S", "avisdemande"],
  ["exit", "out"],
];

let actualLoad = 0;
const nDataPareLoad = 100;

const ScreenJourneyEmergency = (props) => {
  const { t, i18n } = useTranslation(["journey"]);
  const labelWithIcon = (
    <span>
      {Capitalize(t("covid"))}
      <img src={LogoCovid} className="icon-select" />
    </span>
  );
  const labelwithLogoAnomalie = (
    <span>
      {Capitalize(t("anomaly"))}
      <img src={LogoAnomalie} className="icon-select" />
    </span>
  );
  const [selectedOptionFilter, setSelectedFilterOption] = useState({
    value: "heure_entree",
    label: Capitalize(t("admission time")),
    key: "admission time",
  });
  const [hasAnomalie, setAnomalie] = useState(false);
  const [optionsFilter, setoptionsFilter] = useState([
    {
      value: "heure_sortie",
      label: Capitalize(t("discharge time")),
      key: "discharge time",
    },
    {
      value: "heure_entree",
      label: Capitalize(t("admission time")),
      key: "admission time",
    },
    {
      value: "hospitalisation",
      label: Capitalize(t("hospitalisation")),
      key: "hospitalisation",
    },
    { value: "sortie", label: Capitalize(t("discharge")), key: "discharge" },
    { value: "covid", label: labelWithIcon, key: "covid" },
  ]);

  const [data, setDataPredictionGlobal] = useState(null);
  const predictions = props.data.Prediction_Flux;

  useEffect(() => {
    let option = {
      value: selectedOptionFilter.value,
      label: t(selectedOptionFilter.key),
      key: selectedOptionFilter.key,
    };
    setSelectedFilterOption(option);
  }, [i18n.language]);

  useEffect(() => {
    let isAnomalie = false;
    predictions.map((item) => {
      if (item.anomaly) {
        isAnomalie = true;
        return;
      }
    });
    let optionsFilter = [
      {
        value: "heure_sortie",
        label: Capitalize(t("discharge time")),
        key: "discharge time",
      },
      {
        value: "heure_entree",
        label: Capitalize(t("admission time")),
        key: "admission time",
      },
      {
        value: "hospitalisation",
        label: Capitalize(t("hospitalisation")),
        key: "hospitalisation",
      },
      { value: "sortie", label: Capitalize(t("discharge")), key: "discharge" },
      { value: "covid", label: labelWithIcon, key: "covid" },
    ];
    let options = isAnomalie
      ? [...optionsFilter, { value: "anomalie", label: labelwithLogoAnomalie, key: "anomalie" }]
      : optionsFilter;
    setoptionsFilter(options);
    setAnomalie(isAnomalie);
  }, [predictions, i18n.language]);

  useEffect(() => {
    sortData();
  }, [selectedOptionFilter.value]);

  const sortData = () => {
    let allPredictions = [...predictions];

    if (selectedOptionFilter.value == "heure_sortie") {
      allPredictions.sort((a, b) => sort(a, b, "heure_sortie"));
      setDataPredictionGlobal(allPredictions);
      return;
    } else if (selectedOptionFilter.value == "heure_entree") {
      allPredictions.sort((a, b) => sort(a, b, "heure_entree"));
      setDataPredictionGlobal(allPredictions);

      return;
    } else if (selectedOptionFilter.value == "hospitalisation") {
      allPredictions.sort((a, b) => sort(a, b, "heure_sortie"));
      let dataPrediction = allPredictions.filter((e) => {
        let event = getEvents(e.SeqPredEvent)["event"];
        return event["type_out"];
      });

      setDataPredictionGlobal(dataPrediction);
      return;
    } else if (selectedOptionFilter.value == "sortie") {
      allPredictions.sort((a, b) => sort(a, b, "heure_sortie"));
      let dataPrediction = allPredictions.filter((e) => {
        let event = getEvents(e.SeqPredEvent)["event"];
        return !event["type_out"] && event["out"];
      });

      setDataPredictionGlobal(dataPrediction);
      return;
    } else if (selectedOptionFilter.value == "covid") {
      let dataPrediction = allPredictions.filter((e) => {
        return e.covid_flag === true;
      });
      setDataPredictionGlobal(dataPrediction);

      return;
    } else if (selectedOptionFilter.value == "anomalie") {
      let dataPrediction = allPredictions.filter((e) => {
        return e.anomaly == true;
      });
      setDataPredictionGlobal(dataPrediction);

      return;
    } else setDataPredictionGlobal(allPredictions);
  };

  const sort = (a, b, type) => {
    let e1 = getEvents(a.SeqPredEvent);
    let e2 = getEvents(b.SeqPredEvent);
    if (type == "heure_sortie") {
      if (!e1["event"]["out"]) return 1;
      if (!e2["event"]["out"]) return -1;

      return moment(e2["event"]["out"]["event_time"]).diff(
        moment(e1["event"]["out"]["event_time"]),
      ) > 0
        ? -1
        : 1;
    } else if (type == "heure_entree") {
      if (!e1["event"]["Arrivée"]) return 1;
      if (!e2["event"]["Arrivée"]) return -1;

      return moment(e1["event"]["Arrivée"]["event_time"]).diff(
        moment(e2["event"]["Arrivée"]["event_time"]),
      ) > 0
        ? -1
        : 1;
    }
  };

  const renderHeader = () => {
    return (
      <div className="row fix-header bg">
        <div className="col-12 col-lg-5 col-md-12">
          <div className="row div-title" style={{ textAlign: "center" }}>
            <div className="col-3 col-lg-3 col-md-3 font-table-title tabel-title">
              {t("prenom")} {t("nom")} / {t("iep")}
            </div>
            <div className="col-2 col-lg-2 col-md-1 font-table-title tabel-title">{Capitalize(t("age"))}</div>
            <div className="col-1 col-lg-1 col-md-1 font-table-title tabel-title">{Capitalize(t("sex"))}</div>
            <div className="col-2 col-lg-2 col-md-3 font-table-title tabel-title">
              {Capitalize(t("reason"))}
            </div>
            <div className="col-1 col-lg-1 col-md-1 font-table-title tabel-title">
              {Capitalize(t("gravity"))}
            </div>
            <div className="col-3 col-lg-3 col-md-3 font-table-title tabel-title">
              {Capitalize(t("emergency doctor"))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 col-md-12">
          <div className="row table-div-2" style={{ textAlign: "center" }}>
            <div className="font-table-title "> {Capitalize(t("admission"))}</div>
            <div className="font-table-title ">{Capitalize(t("sorting"))}</div>
            <div className="font-table-title ">{Capitalize(t("exam"))}</div>
            <div className="font-table-title ">{Capitalize(t("labo"))}</div>
            <div className="font-table-title ">{Capitalize(t("radio"))}</div>
            <div className="font-table-title ">{Capitalize(t("diag"))}</div>
            <div className="font-table-title ">{Capitalize(t("notice"))}</div>
            <div className="font-table-title ">{Capitalize(t("discharge type"))}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderTableResponsive = () => {
    let pred = data;
    return pred.map((item, i) => {
      if (selectedOptionFilter.value !== "anomalie" && item.anomaly == true) return;
      let data = getEvents(item.SeqPredEvent);

      return (
        <div
          key={i}
          className="row row-0-table"
          style={{ backgroundColor: i % 2 == 0 ? "white" : "rgb(251 250 250)" }}
        >
          <div className="col-12">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-3 col-lg-3 col-md-3 font-table-body">
                {bemPatientName(item.bemPatient)}
                <br />
                {item.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
                {item.covid_flag == true ? (
                  <>
                    <br></br> <img className="covid-png" src={LogoCovid} />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-1 col-lg-1 col-md-1 font-table-body">{item.age}</div>
              <div className="col-1 col-lg-1 col-md-1 font-table-body">
                {item.sex ? item.sex : "-"}
              </div>
              <div className="col-3 col-lg-3 col-md-3 font-table-body">
                {item.french_adm ? item.french_adm : "-"}
              </div>
              <div className="col-1 col-lg-2 col-md-2 font-table-body">
                {item.french_triage ? item.french_triage : "-"}
              </div>
              <div className="col-1 col-lg-2 col-md-2 font-table-body">
                {item.med_personnel ? item.med_personnel.replace(",", "") : "-"}
              </div>
            </div>
          </div>
          <div className="col-12" style={{ marginTop: 20 }}>
            <div className="row table-div-2" style={{ textAlign: "center" }}>
              {types.map((type, index) => {
                let E = data["events"];
                let event = data["event"];
                let prog = data["prog"];
                if (type[0] == "exit" && event["out"]) {
                  let ev = event["out"];
                  let date = roundNearest15M(ev["event_time"], "30");
                  let d = parseInt(
                    moment(date).startOf("day").diff(moment().startOf("day"), "days"),
                  );
                  let day = d == 0 ? "" : d > 0 ? `/${d * 24}` : `/${d * 24}`;

                  return (
                    <div
                      key={index}
                      data-tip
                      data-for={`seq-${item.iep}`}
                      className={
                        event["type_out"] || item.orient_ideale_code != ""
                          ? `two-div-out${
                              item.orient_ideale_code == ""
                                ? event["out"]["confidence"] >= 1
                                  ? ""
                                  : event["out"]["confidence"] >= 0.9
                                  ? "1"
                                  : "2"
                                : ""
                            }`
                          : `smart-triangle responsive-triangle`
                      }
                      style={
                        item.orient_ideale_code !== ""
                          ? {}
                          : event["out"]["confidence"] >= 0.9 && event["out"]["confidence"] < 1
                          ? { border: "2.5px solid rgb(0, 0, 41)" }
                          : {}
                      }
                    >
                      <div className={`div-1`}>
                        <span
                          className={
                            event["type_out"] || item.orient_ideale_code != ""
                              ? "span-out"
                              : `span-out${
                                  event["out"]["confidence"] >= 1
                                    ? ""
                                    : event["out"]["confidence"] >= 0.8
                                    ? "1"
                                    : "2"
                                }`
                          }
                        >
                          {moment(date).format("HH:mm" + day)}
                        </span>
                        <br></br>
                        <span>
                          {(event["type_out"] || item.orient_ideale_code != "") && (
                            <span
                              className={
                                event["type_out"] || item.orient_ideale_code != ""
                                  ? "span-out"
                                  : `span-out${
                                      event["out"]["confidence"] >= 1
                                        ? ""
                                        : event["out"]["confidence"] >= 0.8
                                        ? "1"
                                        : "2"
                                    }`
                              }
                            >
                              {item.orient_ideale_code == ""
                                ? event["type_out"]
                                : "UF " + item.orient_ideale_code}
                            </span>
                          )}
                          {(event["type_out"] || item.orient_ideale_code != "") &&
                            (item.orient_ideale_code != "" || item.uf_pred_name) && (
                              <ReactTooltip place="left" id={`seq-${item.iep}`} effect="solid">
                                <span>
                                  {item.orient_ideale_code != ""
                                    ? item.orient_ideale_name
                                    : item.uf_pred_name}
                                </span>
                                <br />
                                {item.bedDispatching && item.bedDispatching[0] && (
                                  <span>
                                    {t("lit")} : {item.bedDispatching[0]["lit"]}
                                  </span>
                                )}
                              </ReactTooltip>
                            )}
                        </span>
                      </div>
                    </div>
                  );
                }

                if (event[type[1]] && type[0] !== "exit") {
                  let date =
                    type[0] == "A"
                      ? moment(event[type[1]]["event_time"]).subtract("10", "minutes")
                      : event[type[1]]["confidence"] == 1
                      ? event[type[1]]["event_time"]
                      : roundNearest15M(event[type[1]]["event_time"], "15");
                  let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                  return (
                    <div
                      data-tip
                      data-for={type[0] == "I" ? `I-${item.iep}` : null}
                      className="two-div"
                    >
                      <div className={`${moment(date).diff(m(startOfday)) < 0 && "Anomalie"} `}>
                        {moment(date).format("HH:mm")}
                      </div>
                      <div>
                        <span
                          className={`span-1 span-A${
                            event[type[1]]["confidence"] < 1
                              ? event[type[1]]["confidence"] >= 0.9
                                ? "1"
                                : event[type[1]]["confidence"] >= 0.8
                                ? "2"
                                : "3"
                              : ""
                          }`}
                        >
                          {type[0]}

                          {type[0] == "I" && (
                            <ReactTooltip place="left" id={`I-${item.iep}`} effect="solid">
                              <span>{event[type[1]]["event_type"]}</span>
                            </ReactTooltip>
                          )}
                        </span>
                        {index < 7 && <span className="span-prog-dashed"></span>}
                        {orderT[type[0]] < prog && <span className="span-prog"></span>}
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="two-div">
                    <div></div>
                    <div>
                      <span className={`span-1`}></span>
                      {index < 7 && <span className="span-prog-dashed-second"></span>}
                      {orderT[type[0]] < prog && <span className="span-prog"></span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderTable = () => {
    let pred = data.slice(0, 150);

    let time = moment().format("YYYY-MM-DD") + " 00:00";
    return pred.map((item, i) => {
      if (selectedOptionFilter.value !== "anomalie" && item.anomaly == true) {
        return;
      }
      let data = getEvents(item.SeqPredEvent);
      return (
        <div
          key={i}
          className="row row-0-table"
          style={{ backgroundColor: i % 2 == 0 ? "white" : "rgb(251 250 250)" }}
        >
          <div className="col-5">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-3">
                {bemPatientName(item.bemPatient)}
                <br />
                {item.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
                {item.covid_flag == true ? (
                  <>
                    <br></br> <img className="covid-png" src={LogoCovid} />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-2">{item.age}</div>
              <div className="col-1">{item.sex ? item.sex : "-"}</div>
              <div className="col-2">{item.french_adm ? item.french_adm : "-"}</div>
              <div className="col-1">{item.french_triage ? item.french_triage : "-"}</div>
              <div className="col-3">{item.med_personnel ? item.med_personnel : "-"}</div>
            </div>
          </div>
          <div className="col-7">
            <div
              className="row table-div-2"
              style={{ textAlign: "center", alignItems: "center", height: "100%" }}
            >
              {types.map((type, index) => {
                let E = data["events"];
                let event = data["event"];
                let prog = data["prog"];
                if (type[0] == "exit" && event["out"]) {
                  let ev = event["out"];
                  let date = roundNearest15M(ev["event_time"], "30");
                  let d = parseInt(
                    moment(date).startOf("day").diff(moment().startOf("day"), "days"),
                  );
                  let day = d == 0 ? "" : d > 0 ? `/${d * 24}` : `/${d * 24}`;
                  return (
                    <div
                      key={index}
                      data-tip
                      data-for={`seq-${item.iep}`}
                      className={
                        event["type_out"] || item.orient_ideale_code != ""
                          ? `two-div-out${
                              item.orient_ideale_code == ""
                                ? event["out"]["confidence"] >= 1
                                  ? ""
                                  : event["out"]["confidence"] >= 0.9
                                  ? "1"
                                  : "2"
                                : ""
                            }`
                          : `smart-triangle`
                      }
                      style={
                        item.orient_ideale_code !== ""
                          ? {}
                          : event["out"]["confidence"] >= 0.9 && event["out"]["confidence"] < 1
                          ? { border: "2.5px solid rgb(0, 0, 41)" }
                          : {}
                      }
                    >
                      <div className={`div-1`}>
                        <span
                          className={
                            event["type_out"] || item.orient_ideale_code != ""
                              ? "span-out"
                              : `span-out${
                                  event["out"]["confidence"] >= 1
                                    ? ""
                                    : event["out"]["confidence"] >= 0.8
                                    ? "1"
                                    : "2"
                                }
                            ${d == 0 ? "" : "div-margin-left"}
                            `
                          }
                        >
                          {moment(date).format("HH:mm" + day)}
                        </span>
                        <br></br>
                        <span>
                          {(event["type_out"] || item.orient_ideale_code != "") && (
                            <span
                              className={
                                event["type_out"] || item.orient_ideale_code != ""
                                  ? "span-out"
                                  : `span-out${
                                      event["out"]["confidence"] >= 1
                                        ? ""
                                        : event["out"]["confidence"] >= 0.8
                                        ? "1"
                                        : "2"
                                    }`
                              }
                            >
                              {item.orient_ideale_code == ""
                                ? event["type_out"]
                                : (item.orient_ideale_code=="TRANSFERT")?"TRAN.":"UF " + item.orient_ideale_code}
                            </span>
                          )}

                          <ReactTooltip place="left" id={`seq-${item.iep}`} effect="solid">
                            {(event["type_out"] || item.orient_ideale_code != "") &&
                            (item.orient_ideale_code != "" || item.uf_pred_name) ? (
                              <>
                                {" "}
                                <span>
                                  {item.orient_ideale_code != ""
                                    ? item.orient_ideale_name
                                    : item.uf_pred_name}
                                </span>
                                <br />
                                {item.bedDispatching && item.bedDispatching[0] && (
                                  <span>
                                    {t("lit")} : {item.bedDispatching[0]["lit"]}
                                    <br />
                                    <span>{`${t("taux de confiance")}:${(
                                      event["out"]["confidence"] * 100
                                    ).toFixed(2)}%`}</span>
                                  </span>
                                )}
                              </>
                            ) : (
                              <span>{`${t("taux de confiance")} : ${(
                                event["out"]["confidence"] * 100
                              ).toFixed(2)}%`}</span>
                            )}
                          </ReactTooltip>
                        </span>
                      </div>
                    </div>
                  );
                }

                if (event[type[1]] && type[0] !== "exit") {
                  let date =
                    type[0] == "A"
                      ? moment(event[type[1]]["event_time"]).subtract("10", "minutes")
                      : event[type[1]]["confidence"] == 1
                      ? event[type[1]]["event_time"]
                      : roundNearest15M(event[type[1]]["event_time"], "15");
                  let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                  return (
                    <div
                      key={index}
                      data-tip={true}
                      data-for={`${type[0]}-${item.iep}`}
                      className="two-div"
                    >
                      <div className={`${moment(date).diff(m(startOfday)) < 0 && "Anomalie"} `}>
                        {moment(date).format("HH:mm")}
                      </div>
                      <div>
                        <span
                          className={`span-1 span-A${
                            event[type[1]]["confidence"] < 1
                              ? event[type[1]]["confidence"] >= 0.9
                                ? "1"
                                : event[type[1]]["confidence"] >= 0.8
                                ? "2"
                                : "3"
                              : ""
                          }`}
                        >
                          {type[0]}

                          <ReactTooltip place="left" id={`${type[0]}-${item.iep}`} effect="solid">
                            {type[0] == "I" && (
                              <>
                                <span>{event[type[1]]["event_type"]}</span> <br />
                              </>
                            )}
                            <span>{`${t("taux de confiance")}${" : "}${(
                              event[type[1]]["confidence"] * 100
                            ).toFixed(2)}%`}</span>
                          </ReactTooltip>
                        </span>
                        {index < 7 && <span className="span-prog-dashed"></span>}
                        {orderT[type[0]] < prog && <span className="span-prog"></span>}
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} className="two-div">
                    <div></div>
                    <div>
                      <span className={`span-1`}></span>
                      {index < 7 && <span className="span-prog-dashed-second"></span>}
                      {orderT[type[0]] < prog && <span className="span-prog"></span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const roundNearest15M = (date, min) => {
    const rounded = Math.round(moment(date).minute() / min) * min;
    const roundedDown = Math.floor(moment(date).minute() / min) * min;
    const roundedUp = Math.ceil(moment(date).minute() / min) * min;

    return moment(date).minute(roundedUp).second(0);
  };

  const getEvents = (events) => {
    let data = events.filter(
      (item) =>
        item.event_type.includes("RX standard") ||
        item.event_type.includes("Fin de PEC Médicale") ||
        item.event_type.includes("Arrivée") ||
        item.event_type.includes("uf") ||
        item.event_type.includes("avisdemande") ||
        item.event_type.includes("out") ||
        item.event_type.includes("Triage") ||
        item.event_type.includes("Imagerie autre") ||
        item.event_type.includes("Examen médical") ||
        item.event_type.includes("Laboratoire"),
    );

    let prog = 0;
    let test = false;

    const arrayToObject = (data) =>
      data.reduce((obj, item) => {
        if (item.event_type.includes("out") && !test) {
          obj["out"] = { ...item };

          if (item.confidence == 1) prog = orderType["out"];
        } else if (item.event_type.includes("uf")) {
          obj["out"] = { ...item };
          let split = item.event_type.split("_");
          obj["type_out"] = split[1] == "other" ? "UF Autre" : `UF ${split[1]}`;
          test = true;
        } else if (item.event_type.includes("Arrivée")) {
          obj[item.event_type] = item;
          if (item.confidence == 1 && orderType["Arrivée"] > prog) prog = orderType["Arrivée"];
        } else if (item.event_type.includes("Triage")) {
          obj[item.event_type] = item;

          if (item.confidence == "1" && orderType["Triage"] > prog) prog = orderType["Triage"];
        } else if (item.event_type.includes("Examen médical")) {
          obj[item.event_type] = item;
          if (item.confidence == 1 && orderType["Examen médical"] > prog)
            prog = orderType["Examen médical"];
          if (item.confidence < 1 && orderType["Examen médical"] == prog) prog = prog - 1;
        } else if (item.event_type.includes("Laboratoire")) {
          obj[item.event_type] = item;
          if (item.confidence == 1 && orderType["Laboratoire"] > prog)
            prog = orderType["Laboratoire"];
          if (item.confidence < 1 && orderType["Laboratoire"] == prog) prog = prog - 1;
        } else if (
          item.event_type.includes("RX standard") ||
          item.event_type.includes("Imagerie autre")
        ) {
          obj["Imagerie"] = item;
          if (item.confidence == 1 && orderType["Imagerie"] > prog) prog = orderType["Imagerie"];
          if (item.confidence < 1 && orderType["Imagerie"] == prog) prog = prog - 1;
        } else if (item.event_type.includes("Fin de PEC Médicale")) {
          obj[item.event_type] = item;
          if (item.confidence == 1 && orderType["Fin de PEC Médicale"] > prog)
            prog = orderType["Fin de PEC Médicale"];
          if (item.confidence < 1 && orderType["Fin de PEC Médicale"] == prog) prog = prog - 1;
        } else if (item.event_type.includes("avisdemande")) {
          obj[item.event_type] = item;
          if (item.confidence == 1 && orderType["avisdemande"] > prog)
            prog = orderType["avisdemande"];
          if (item.confidence < 1 && orderType["avisdemande"] == prog) prog = prog - 1;
        }

        return obj;
      }, {});

    let result = arrayToObject(data);

    return { event: result, events: data, prog: prog };
  };

  return (
    <div className="row" style={{ paddingTop: "1%" }}>
      <div className="w-100 first-row">
        <div className="row row0">
          <div
            className="col-5 col-xl-5 col-md-5 col-12 container-select"
            style={{
              paddingRight: 0,
              marginBottom: 50,
              zIndex: 9,
              display: "flex",
              alignContent: "center",
            }}
          >
            <Select
              value={selectedOptionFilter}
              className="selectJ"
              menuColor="red"
              isSearchable={false}
              openMenuOnClick={true}
              onChange={(selectedOptionFilter) => setSelectedFilterOption(selectedOptionFilter)}
              options={optionsFilter}
            />
            {hasAnomalie && (
              <img src={LogoAnomalie} className="icon-select" style={{ width: "1.6vw" }} />
            )}
          </div>
          <div
            className="col-5 col-xl-5 col-md-5 col-12"
            style={{ paddingRight: 0, marginBottom: 50 }}
          >
            <p className="title-1">{Capitalize(t("confidence interval"))}</p>
          </div>
        </div>
      </div>

      <div className="w-100 table-g table-scroll w-fixe">
        {data && renderHeader()}
        <div className="w-100 w-fixe" style={{ height: "63vh" }}>
          {data ? (window.innerWidth <= 991.98 ? renderTableResponsive() : renderTable()) : ""}
        </div>
      </div>
    </div>
  );
};
export default ScreenJourneyEmergency;
