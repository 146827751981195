import React, { useState, useEffect } from "react";

import "./index.css";
import Ehl from "../screens/ehl";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import axios from "axios";
import { API } from "../../utils/api";
import LogoEhl from "../../images/CALYPS_Screen.png";

const ScreenEhl = (props) => {
  const [item, setItem] = useState("");
  const [load, setload] = useState(false);
  const [data, setData] = useState(null);

  const a = 1;

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      setItem(props.location.state && props.location.state.hash);
      // setItem('encours')
    }
  }, [props.location.state]);

  useEffect(() => {
    axios
      .get(`${API.ENDPOINT + API.EHL_SCHOOL}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        setload(true);
        setData(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const renderScreen = () => {
    if (data && load) {
      return <Ehl data={data} />;
    } else {
      return (
        <div
          className="row"
          style={{ justifyContent: "center", minHeight: 250, alignItems: "flex-end" }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  return (
    <div className="main-screen flux-urg ehl">
      <div className="col-6">
        <img className="logo-ehl" src={LogoEhl} />
      </div>
      <h2 className="h2" style={{ color: "#3c0069" }}>
        Student Path Tracking & Forecast
      </h2>
      {renderScreen()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenEhl);
