/**
 * By : Theo Bensaci
 * Date : 19.04.2022
 * Desc : Js script for manage all contraint block (equilibre, optimisation) + all block spot
 */

import React, { useState, useEffect, useRef } from "react";
import "./ContraintsBlock.css";
import { useTranslation } from "react-i18next";
import { Contraints } from "./Contraints";
/**
 * Contraint block
 * @param {string} contraintName name of the contraint
 * @param {int} weight weight of the contraint
 * @param {int} type type of the contraint (0 = équilibre, 1 = optimisation)
 * @returns JSX Contraint block
 */
export const ContraintBlock = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const containerRef = useRef(null);
  const className = useRef("");
  const [weight, setWeight] = useState(0);
  let onWeightChange = false;

  //#region function
  function startMove() {
    if (onWeightChange) {
      return;
    }
    containerRef.current.className = className.current + " GrabBlock";
    props.dragDownManager.grabBlock(containerRef.current, props.contraint, getClass(), props.spot);
  }

  function endMove() {
    if (onWeightChange) {
      onWeightChange = false;
      return;
    }

    if (!props.dragDownManager.containeContraint(props.contraint)) {
      props.dragDownManager.createContraintBlock(props.contraint);
      props.dragDownManager.destroyCurrentBlock();
    } else {
      props.dragDownManager.relaseBlock();
    }

    // if was on spot
    if (props.spot != null) {
      props.spot.removeFromInventory(props.contraint);
      props.dragDownManager.createContraintBlock(props.contraint);
      props.dragDownManager.destroyCurrentBlock();
    }
  }

  function getClass() {
    return "ContraintBlock " + (props.contraint.Type == 0 ? "" : "HalfBlock");
  }

  //#endregion

  useEffect(() => {
    className.current = getClass();
    setWeight(props.contraint.Weight);
  });

  function handelChange(e) {
    let newtext = e.target.value.replace(/[^0-9]*/g, "");
    newtext = newtext == "" ? "0" : newtext;
    newtext = newtext.slice(0, 2);
    props.contraint.Weight = parseInt(newtext);
    setWeight(newtext);
  }

  function unFocus() {
    var tmp = document.createElement("input");
    containerRef.current.appendChild(tmp);
    tmp.focus();
    containerRef.current.removeChild(tmp);
  }

  return (
    <div ref={containerRef} onMouseDown={startMove} onMouseUp={endMove} className={getClass()}>
      <div className="container">
        <div className="left">
          <h1>{t(props.contraint.Name)}</h1>
        </div>
        <div className="right">
          <div id="weight">
            <input
              type="text"
              value={weight}
              onChange={handelChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  unFocus();
                }
              }}
              onMouseDown={() => {
                onWeightChange = true;
              }}
            />
            <span class="tooltiptext">{t("Contraint Block Weight Tool Tips")}</span>
          </div>
        </div>
        <div className="last">
          <div id="type">{t(props.contraint.Type == 0 ? "balance" : "optimisation")}</div>
        </div>
      </div>
    </div>
  );
};

export class ContraintSpot extends React.Component {
  //#region html function
  getClass(halfBlock = false, animation = false) {
    return (
      "ContraintSpot" +
      (halfBlock ? " HalfSpot" : "") +
      (animation ? " ContraintSpot" + (this.actualState ? "-Enabel" : "-Disabel") : "")
    );
  }

  setClass(val) {
    this.setState({
      className: val,
    });
  }

  onMouseEnter(owner) {
    if (owner.props.dragDownManager.OnMove && this.Inventory == null) {
      owner.setClass(owner.getClass() + " ContraintSpot-Enabel ContraintSpot-Hover");
    }
  }
  onMouseLeave(owner) {
    if (owner.props.dragDownManager.OnMove && this.Inventory == null) {
      owner.setClass(owner.getClass() + " ContraintSpot-Enabel ContraintSpot-UnHover");
    }
  }
  //#endregion

  constructor(props) {
    super(props);
    this.index = this.props.index;
    this.containerRef = React.createRef();
    this.Inventory = [];
    this.actualState = false;
    this.state = {
      className: this.getClass(),
      detectionSpot: "DetectionSpot-disabel",
      contents: [],
    };
    this.props.dragDownManager.addSpot(this);
  }

  //#region Spote function

  switchEnabelSpotState(state) {
    this.actualState = state;
    this.showInventory(true);
    this.getDetexionSpotState(state);
  }

  getDetexionSpotState(state) {
    this.setState({
      detectionSpot: !state ? "DetectionSpot-disabel" : "DetectionSpot",
    });
  }

  getContraintBlock() {
    let d = this.props.dragDownManager;
    if (d.Inventory != null && !this.Inventory.includes(d.Inventory.contraint)) {
      this.addToInventory(d.Inventory.contraint);
      d.clearLastSpotInv();
    }
    d.destroyCurrentBlock();
  }

  getType() {
    if (this.Inventory.length == 0) return false;

    for (const iterator of this.Inventory) {
      if (iterator.Type == 1) return true;
    }
    return false;
  }

  //#endregion

  //#region Inventory Function

  /**
   * Add a contraint to Inventory
   * @param {Contraints} contraint
   */
  addToInventory(contraint) {
    if (contraint.Type == 0 || !this.getType()) {
      this.clearInventory();
    }
    this.Inventory.push(contraint);
    this.getDetexionSpotState(false);
    this.showInventory();
  }
  /**
   * Add a contraint to Inventory
   * @param {Contraints} contraint
   */
  removeFromInventory(contraint) {
    let index = this.Inventory.indexOf(contraint);
    if (index > -1) {
      this.Inventory.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.showInventory();
  }
  clearInventory() {
    this.props.dragDownManager.createContraintsBlocks(this.Inventory);
    this.Inventory = [];
    this.showInventory();
  }

  /**
   * Show Inventory in the spot
   * @param {bool} animation if spot is on animation
   */
  showInventory(animation = false) {
    let contents = [];
    if (this.getType()) {
      let subContent = [];
      for (const iterator of this.Inventory) {
        subContent.push(
          <div className={this.getClass(iterator.Type == 1)}>
            <ContraintBlock
              dragDownManager={this.props.dragDownManager}
              contraint={iterator}
              spot={this}
            />
          </div>,
        );
      }

      subContent.push(
        <div className={this.getClass(this.getType(), animation)}>
          <div className="plus"></div>
        </div>,
      );
      contents.push(<div className={this.getClass() + " UpperContainer"}>{subContent}</div>);
    } else if (this.Inventory.length == 0) {
      contents.push(
        <div className={this.getClass(false, animation)}>
          <div className="plus"></div>
        </div>,
      );
    } else {
      for (const iterator of this.Inventory) {
        contents.push(
          <div className={this.getClass(iterator.Type == 1)}>
            <ContraintBlock
              dragDownManager={this.props.dragDownManager}
              contraint={iterator}
              spot={this}
            />
          </div>,
        );
      }
    }

    this.setState({
      contents: contents,
    });
  }

  //#endregion

  //#region React
  componentDidMount() {
    let o = this;
    //console.log(this.containerRef);
    this.containerRef.current.childNodes.item("DetectionSpot").addEventListener(
      "mouseenter",
      () => {
        o.onMouseEnter(o);
      },
      false,
    );
    this.containerRef.current.childNodes.item("DetectionSpot").addEventListener(
      "mouseleave",
      () => {
        o.onMouseLeave(o);
      },
      false,
    );
    this.showInventory();
    //this.addToInventory(new Contraints("UwU",1,0));
  }

  componentWillUnmount() {}

  render() {
    return (
      <div ref={this.containerRef} id={this.props.index} className="ContraintSpotContainer">
        <div
          id={this.state.detectionSpot}
          onMouseUp={() => {
            this.getContraintBlock();
          }}
        ></div>
        <div id="container">{this.state.contents}</div>
      </div>
    );
  }

  //#endregion
}
