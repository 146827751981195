import React, { useState, useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../utils/api";
import Select from "react-select";
import { connect } from "react-redux";

const NewTranslate = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [key, setKey] = useState("");
  const [keyFr, setKeyFr] = useState("");
  const [keyEng, setKeyEng] = useState("");
  const [keyDe, setKeyDe] = useState("");
  const [keyIt, setKeyIt] = useState("");
  const [optionScreens, setOptionScreens] = useState([]);
  const [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState(null);
  const [selectedOptionScreen, setOptionScreenSelected] = useState({
    value: 0,
    label: t("select a screen"),
  });
  const [message, setMessage] = useState(false);

  useEffect(() => {
    axios
      .get(`${API.ENDPOINT + API.TRANSLATIONS_ALLSCREENS}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let optionScreens = [{ value: 0, label: t("select a screen") }];
        let screensName = {};

        res.data.map((screen) => {
          screensName[screen.id] = screen;
          optionScreens = [...optionScreens, { value: screen.id, label: screen.name }];
        });
        setOptionScreens(optionScreens);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const setTimeOut = () => {
    setMessage(false, 3000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    let errors = {};
    let error = false;

    let token = props.activeUSer.user.token;

    if (selectedOptionScreen.value === 0) {
      setErrorBorder(["choiceScreen"]);
      setShowError(true);
      return;
    }

    let translation = {
      key: key,
      fr: keyFr,
      en: keyEng,
      de: keyDe,
      it: keyIt,
      id_screens: selectedOptionScreen.value,
    };

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.TRANSLATIONS_NEWTRANSLATE}`,
      data: translation,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        setMessage(true);
        setKeyDe("");
        setKeyEng("");
        setKey("");
        setKeyFr("");
        setKeyIt("");
        setTimeout(() => setMessage(false), 3000);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "401") {
            setError({ email: "the key is already used" });
            setErrorBorder(["email"]);
            error = true;
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  return (
    <div className="row mrg-user" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("new key")}</h1>

        <div className="row">
          <div className="col-10 offset-1">
            <hr></hr>
            <form onSubmit={handleSubmit} className="form-user-new">
              <div style={{ textAlign: "center" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => (
                    <div className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the key was successfully saved")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group block">
                <Select
                  value={selectedOptionScreen}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceScreen") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionScreen) => setOptionScreenSelected(selectedOptionScreen)}
                  options={optionScreens}
                />
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  id="keyWord"
                  className="fadeIn form-control"
                  name="_keyWord"
                  placeholder={t("key")}
                />
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={keyFr}
                  onChange={(e) => setKeyFr(e.target.value)}
                  id="keyFr"
                  className="fadeIn form-control"
                  name="_keyFr"
                  placeholder={t("french key")}
                />
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={keyEng}
                  onChange={(e) => setKeyEng(e.target.value)}
                  id="keyEng"
                  className="fadeIn form-control"
                  name="_keyEng"
                  placeholder={t("english key")}
                />
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={keyDe}
                  onChange={(e) => setKeyDe(e.target.value)}
                  id="keyDe"
                  className="fadeIn form-control"
                  name="_keyDe"
                  placeholder={t("german key")}
                />
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={keyIt}
                  onChange={(e) => setKeyIt(e.target.value)}
                  id="keyIt"
                  className="fadeIn form-control"
                  name="_keyIt"
                  placeholder={t("italien key")}
                />
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  href="/translations"
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  disabled={
                    (key !== "" && keyFr !== "" && keyEng !== "" && keyDe !== "") || keyIt !== ""
                      ? false
                      : true
                  }
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(NewTranslate);
