import React, { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import Select from "react-select";
import Gardes from "./GuardPlannings";
import moment from "moment/min/moment-with-locales";
import ShowModal from "./popupRecommandation";
import { Capitalize } from "../../../utils/functions";
import { connect } from "react-redux";
import ScreenPostAvaibility from "./PostsAvaibility";

const ScreenRocommandations = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [globalRecommandationData, setGlobalRecommandationData] = useState([]);
  const [recommandationsData, setRecommandationsData] = useState([]);
  const [page, setPage] = useState("recommandations");
  const [optionDoctors, setOptionDoctors] = useState([]);
  const [optionDoctorsFilter, setOptionDoctorsFilter] = useState([]);
  const [selectedOptionDoctor, setOptionDoctorselected] = useState({
    value: null,
    label: t("all doctors"),
  });
  const [optionFunctions, setOptionFunctions] = useState([
    { value: null, label: t("all functions") },
    ...props.functions.slice(1),
  ]);
  const [selectedOptionFunctions, setOptionFunctionsSelected] = useState({
    value: null,
    label: t("all functions"),
  });
  const [optionPosts, setOptionPosts] = useState([]);
  const [selectedOptionPosts, setOptionPostsSelected] = useState({
    value: null,
    label: t("all post"),
  });
  const [incrementWeek, setIncrementWeek] = useState(1);
  const [filterRecommandationData, setFilterRecommandationData] = useState([]);
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("isoWeek"));

  const [weekPages, setWeekPages] = useState(null);
  const [medecin, setMedecin] = useState([]);
  const [recom, setRecom] = useState([]);
  const [load, setLoad] = useState(false);
  const [dates, setDates] = useState([]);
  const [listId, setListId] = useState([]);
  const ref = useRef(null);
  const allPost = useRef(null);

  const N_WEEK_BNT_PAGES = 12;

  //#region api function
  /**
   * Request recomendations to the server
   * @param {Date} startDate
   * @param {Date} endDate
   */
  function requestRecommendation(startDate, endDate) {
    setStartHeadDate(moment(startDate).startOf("day"));
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLRECOMMANDATIONS}`,
        {
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let dataFiltred = [];

        // find max interation
        let max_iter = 0;
        for (const iter of res.data) {
          for (const iterator of iter.Recommandations) {
            max_iter = iterator.iteration > max_iter ? iterator.iteration : max_iter;
          }
        }

        // filter of all recommandation
        for (const iter of res.data) {
          let member = iter;
          // find max iter
          let newreco = [];
          for (const iterator of iter.Recommandations) {
            if (iterator.iteration == max_iter) newreco.push(iterator);
          }
          member.Recommandations = newreco;
          if (newreco.length == 0) continue;
          dataFiltred.push(member);
        }

        loadingData(dataFiltred);
        setFilterRecommandationData(dataFiltred);
        setGlobalRecommandationData(dataFiltred);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  //#endregion

  //#region Week page

  /**
   * generate a page number button
   * @param {int} weekNumber week year number
   * @param {bool} isActive is this button the current selected week
   * @param {function} onClick onclick event
   */
  function createWeekPageBnt(weekNumber, isActive, onClick) {
    return (
      <button className={"weekPageBnt " + (isActive ? "weekPageBnt-Active" : "")} onClick={onClick}>
        {weekNumber}
      </button>
    );
  }

  function getActualWeeknumber() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    return Math.ceil(days / 7);
  }

  /**
   * Retrun a week formated for not going under 0 and upper 52
   * @param {int} actualWeek weekNumber to format
   * @returns week number formated
   */
  function formatWeek(actualWeek) {
    let i = actualWeek - 52;
    return actualWeek <= 0 ? 52 + actualWeek : actualWeek > 52 ? i : actualWeek;
  }

  /**
   * Generate all week page bnts
   * @param {int} nPages number of buttons
   * @param {int} actualIncrementWeek actual Incremenetweek
   */
  function genAllWeekPagesBnt(nPages, actualIncrementWeek) {
    let plusWeeksBnt = [];
    let minusWeeksBnt = [];

    let actualWeek = formatWeek(getActualWeeknumber() + (actualIncrementWeek - 1));
    console.log(actualIncrementWeek);

    // get n bnt to gen beffor select week and after
    let nSideBnt = nPages % 2 == 0 ? nPages / 2 : (nPages - 1) / 2;

    for (let index = 1; index <= nSideBnt; index++) {
      let plusWeek = formatWeek(actualWeek + index);
      let minusWeek = formatWeek(actualWeek - index);

      let plusWeekBnt = createWeekPageBnt(plusWeek, false, () =>
        nextWeek(index, actualIncrementWeek + index),
      );
      let minusWeekBnt = createWeekPageBnt(minusWeek, false, () =>
        beforeWeek(index, actualIncrementWeek - index),
      );

      plusWeeksBnt.push(plusWeekBnt);
      minusWeeksBnt.unshift(minusWeekBnt);
    }

    // add current week bnt
    plusWeeksBnt.unshift(createWeekPageBnt(actualWeek, true, () => {}));

    let weeksBnt = minusWeeksBnt.concat(plusWeeksBnt);
    setWeekPages(weeksBnt);
  }

  //#endregion

  useEffect(() => {
    let endHeadDate = moment().endOf("isoWeek");
    requestRecommendation(new Date(startHeadDate), new Date(endHeadDate));
    genAllWeekPagesBnt(N_WEEK_BNT_PAGES, incrementWeek);
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLPOSTS}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        allPost.current = res.data;
        initialOption(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const initialOption = (data) => {
    let optionPosts = [];
    data.map((post) => {
      optionPosts = [...optionPosts, { value: post.index, label: post.denomination }];
    });
    setOptionPosts([{ value: null, label: t("all posts") }, ...optionPosts]);
  };

  useEffect(() => {}, [globalRecommandationData, startHeadDate]);

  const loadingData = (Data) => {
    let optionDoctors = [];
    let recommandationsByMember = {};
    Data.map((member) => {
      member.Recommandations.map((r) => {
        let date = moment(r.startDate).startOf("day").format("YYYY-MM-DD");
        let end = moment(r.endDate).startOf("day").diff(moment(date), "days");
        let i = 0;
        if (recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")]) return;
        if (end <= 0) {
          recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
        } else {
          while (i <= end) {
            recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
            date = moment(date).add(1, "day");
            i++;
          }
        }
      });
      optionDoctors.push({
        value: member.index,
        label: member.nom.toUpperCase() + " " + member.prenom.toUpperCase(),
        indexF: member.function_id,
      });

      //sort docotors by lable (lastname + firstname)
      optionDoctors.sort((a, b) => a.label.localeCompare(b.label));
    });

    setRecommandationsData(recommandationsByMember);
    setOptionDoctors([{ value: null, label: t("all doctors") }, ...optionDoctors]);
    setOptionDoctorsFilter([{ value: null, label: t("all doctors") }, ...optionDoctors]);
  };

  useEffect(() => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, moment(startHeadDate).add(i, "days").format("YYYY-MM-DD")];
    }
    setDates(days);
  }, [startHeadDate]);

  useEffect(() => {
    let recommandationsByMember = {};
    let dataRecommandations =
      filterRecommandationData.length === 0 ? globalRecommandationData : filterRecommandationData;
    dataRecommandations.map((item) => {
      item.Recommandations.map((r) => {
        let date = moment(r.startDate).startOf("day").format("YYYY-MM-DD");
        let end = moment(r.endDate).startOf("day").diff(moment(date), "days");
        let i = 0;
        if (recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")]) return;
        if (end <= 0) {
          recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
        } else {
          while (i <= end) {
            recommandationsByMember[r.member_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
            date = moment(date).add(1, "day");
            i++;
          }
        }
      });
    });
    setRecommandationsData(recommandationsByMember);
  }, [filterRecommandationData, startHeadDate]);

  useEffect(() => {
    if (
      (selectedOptionDoctor && selectedOptionDoctor.length === 0) ||
      selectedOptionDoctor === null
    ) {
      setFilterRecommandationData(globalRecommandationData);
      setListId([]);
    } else {
      let listIndex = optionDoctors.map((doctor) => {
        return doctor.value;
      });
      setListId(listIndex);
      let recommandations = [];
      recommandations = globalRecommandationData.filter((recommandation) =>
        listIndex.includes(recommandation.index),
      );
      setFilterRecommandationData(recommandations);
    }
  }, [selectedOptionDoctor, globalRecommandationData]);

  useEffect(() => {
    let recommandations = [];
    let doctors = [];

    optionDoctors.slice(1).map((m) => {
      if (
        selectedOptionFunctions &&
        selectedOptionFunctions.value !== null &&
        selectedOptionFunctions.value !== m.indexF
      )
        return;
      if (selectedOptionPosts && selectedOptionPosts.value !== null) {
      }
      doctors = [...doctors, m];
    });

    globalRecommandationData.map((d) => {
      if (
        selectedOptionFunctions &&
        selectedOptionFunctions.value !== null &&
        selectedOptionFunctions.value !== d.function_id
      )
        return;
      if (
        selectedOptionDoctor !== undefined &&
        selectedOptionDoctor &&
        selectedOptionDoctor.value !== null &&
        selectedOptionDoctor.value !== d.index
      )
        return;
      if (selectedOptionPosts && selectedOptionPosts.value !== null) {
        if (!d.Recommandations || d.Recommandations.length === 0) return;
        if (!d.Recommandations.some((p) => p.post_id === selectedOptionPosts.value)) return;
      }
      recommandations = [...recommandations, d];
    });

    // sort recommandation by last name
    recommandations.sort((a, b) => a.nom.localeCompare(b.nom));

    setFilterRecommandationData(recommandations);
    setOptionDoctorsFilter([{ value: null, label: t("all doctors") }, ...doctors]);
  }, [
    selectedOptionFunctions,
    selectedOptionDoctor,
    selectedOptionPosts,
    globalRecommandationData,
  ]);

  const ShowPopUpAdd = (medecin) => {
    setMedecin(medecin);
    ref.current.PopupAdd();
  };
  const ShowPopUpEdit = (medecin, r) => {
    setMedecin(medecin);
    setRecom(r);
    ref.current.PopupEdit();
  };
  const dates1 = () => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <th key={i} className="header bg-cl" style={{ width: "12%" }}>
        {moment(startHeadDate).add(day, "days").locale("Fr").format("ddd")}{" "}
        {moment(startHeadDate).add(day, "days").format("DD.MM")}{" "}
      </th>
    ));
  };

  const renderList = () => {
    if (!filterRecommandationData) return null;
    return filterRecommandationData.map((member, index) => (
      <tr key={index}>
        <td className={"name-doctor " + (member.fictional ? "fictional-doctor" : "")}>
          {Capitalize(member.nom) + " " + Capitalize(member.prenom)}
        </td>
        {dates.map((d, i) => {
          return recommandationsData && recommandationsData[member.index + "_" + d] ? (
            <td
              key={i}
              onClick={() => ShowPopUpEdit(member, recommandationsData[member.index + "_" + d])}
              className="td-rec"
              style={{
                backgroundColor: `${
                  recommandationsData[member.index + "_" + d].Posts &&
                  recommandationsData[member.index + "_" + d].Posts.couleur
                }`,
                cursor: "pointer",
              }}
            >
              {recommandationsData[member.index + "_" + d].Posts &&
                recommandationsData[member.index + "_" + d].Posts.abreviation}
            </td>
          ) : (
            <td key={i} onClick={() => ShowPopUpAdd(member)} className={"td-rec "} />
          );
        })}
      </tr>
    ));
  };
  const renderRecommandations = () => {
    return (
      <div style={{ marginTop: "50px" }} className="over-t">
        <div
          className={`${
            filterRecommandationData && filterRecommandationData.length >= 12 && "overflow-rec"
          } `}
          style={{ marginTop: 10 }}
        >
          {load && filterRecommandationData ? (
            <table className="table table-scroll table-rec t-8" style={{ borderColor: "black" }}>
              <thead className="fix-header " style={{ zIndex: 0 }}>
                <tr>
                  <th className="header bg-cl padding-0" style={{ width: "16%" }}>
                    <div style={{ zIndex: 6 }}>
                      <Select
                        value={selectedOptionDoctor}
                        className="selectJ1 form-select"
                        isSearchable={false}
                        onChange={(selectedOptionDoctor) =>
                          setOptionDoctorselected(selectedOptionDoctor)
                        }
                        options={optionDoctorsFilter}
                        isSearchable={true}
                        styles={{ fontSize: 24 }}
                        placeholder={t("all doctors")}
                      />
                    </div>
                  </th>
                  {dates1()}
                </tr>
              </thead>
              <tbody>
                {renderList()}

                <ShowModal
                  recommandations={recom}
                  ref={ref}
                  addRecommandation={addRecommandation}
                  medecin={medecin}
                  editRecom={editRecom}
                  deleteRecommandation={deleteRecom}
                />
              </tbody>
            </table>
          ) : (
            <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
          )}
        </div>
      </div>
    );
  };
  const addRecommandation = (recommandation) => {
    let recommandations = [];
    globalRecommandationData.map((item, i) => {
      if (item.index === medecin.index) {
        item.Recommandations = [...item.Recommandations, recommandation];
      }
      recommandations[i] = item;
    });
    setGlobalRecommandationData(recommandations);
  };
  const editRecom = (recommandation) => {
    let recommandations = [];
    globalRecommandationData.map((item, i) => {
      if (item.index === recommandation.member_id) {
        let _recom = [];

        item.Recommandations.map((p) => {
          if (p.index === recommandation.index) {
            _recom = [..._recom, recommandation];
          } else _recom = [..._recom, p];
        });
        item.Recommandations = _recom;
        recommandations = [...recommandations, item];
      } else {
        item.Recommandations = item.Recommandations.sort(
          (a, b) => moment(a.startDate).format("YYYYMMDD") - moment(b.startDate).format("YYYYMMDD"),
        );
        recommandations = [...recommandations, item];
      }
    });
    setGlobalRecommandationData(recommandations);
  };

  const deleteRecom = (recommandation) => {
    let recommandations = [];

    globalRecommandationData.map((item, i) => {
      if (item.index === recommandation.member_id) {
        item.Recommandations = item.Recommandations.filter((p) => p.index !== recommandation.index);
      }
      recommandations[i] = item;
    });
    setGlobalRecommandationData(recommandations);
  };

  const nextWeek = (m = 1, newIncrementWeek = null) => {
    let addDay = 7 * m;
    let endDate = moment(startHeadDate)
      .add(addDay + 7, "days")
      .startOf("day");
    let startDate = moment(startHeadDate).add(addDay, "days").startOf("day");

    startHeadDate.add(addDay, "days").startOf("day");
    requestRecommendation(startDate, endDate);
    let i = newIncrementWeek != null ? newIncrementWeek : incrementWeek + 1 * m;
    console.log(i);
    setIncrementWeek(i);
    genAllWeekPagesBnt(N_WEEK_BNT_PAGES, i);
  };
  const beforeWeek = (m = 1, newIncrementWeek = null) => {
    let addDay = 7 * m;
    let endDate = moment(startHeadDate)
      .subtract(addDay - 7, "days")
      .startOf("day");
    let startDate = moment(startHeadDate).subtract(addDay, "days").startOf("day");
    startHeadDate.subtract(addDay, "days").startOf("day");
    requestRecommendation(startDate, endDate);
    let i = newIncrementWeek != null ? newIncrementWeek : incrementWeek - 1 * m;
    console.log(i);
    setIncrementWeek(i);
    genAllWeekPagesBnt(N_WEEK_BNT_PAGES, i);
  };

  function nextMonth() {
    let endDate = moment(startHeadDate).add(42, "days").startOf("day");
    let startDate = moment(startHeadDate).add(35, "days").startOf("day");
    requestRecommendation(startDate, endDate);
    setIncrementWeek(incrementWeek + 4);
  }
  function priviuseMonth() {
    let endDate = moment(startHeadDate).subtract(42, "days").startOf("day");
    let startDate = moment(startHeadDate).subtract(35, "days").startOf("day");
    requestRecommendation(startDate, endDate);
    setIncrementWeek(incrementWeek - 4);
  }

  if (page === "recommandations") {
    return (
      <div>
        <div className="row mrg-t">
          <div className="container-user">
            <div>
              <div className="w-100 justify-content-start" style={{ marginTop: 20 }}>
                <div className="selectOption" style={{ zIndex: 6, marginBottom: 10 }}>
                  <div id="selContainer">
                    <Select
                      value={selectedOptionFunctions}
                      className="selectJ1 form-select"
                      isSearchable={false}
                      onChange={(selectedOptionDoctor) =>
                        setOptionFunctionsSelected(selectedOptionDoctor)
                      }
                      options={optionFunctions}
                      // isMulti={true}
                      isSearchable={true}
                      styles={{ fontSize: 20 }}
                      placeholder={t("all functions")}
                    />
                    <Select
                      value={selectedOptionPosts}
                      className="selectJ1 form-select"
                      isSearchable={false}
                      onChange={(selectedOptionDoctor) =>
                        setOptionPostsSelected(selectedOptionDoctor)
                      }
                      options={optionPosts}
                      // isMulti={true}
                      isSearchable={true}
                      styles={{ fontSize: 20 }}
                      placeholder={t("all posts")}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                  <button
                    style={{ marginRight: 10 }}
                    onClick={() => beforeWeek()}
                    className="btn btn-user-1 btn-primary"
                  >
                    {t("befor week")}
                  </button>
                  <div className="week-pages">{weekPages}</div>
                  <button
                    style={{ marginLeft: 10 }}
                    onClick={() => nextWeek()}
                    className="btn btn-user-1 btn-primary"
                  >
                    {t("next week")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderRecommandations()}
      </div>
    );
  } else if (page == "guard") {
    return <Gardes listMedecin={listId} setPage={setPage} medecin={optionDoctors} />;
  } else {
    return <ScreenPostAvaibility setPage={setPage} />;
  }
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenRocommandations);
