import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";
import Select from "react-select";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import ReactTooltip from "react-tooltip";
import { Triangle } from "react-shapes";
import LogoAlert from "../../../images/Alert-Ehl.png";
Moment.globalTimezone = "Europe/Paris";

let orderT = {
  application: 0,
  ap1: 1,
  ap2: 2,
  bosc1: 3,
  bosc2: 4,
  bosc3: 5,
  bosc4: 6,
  bosc5: 7,
  bosc6: 8,
  mgh1: 9,
  mgh2: 10,
  mgh3: 11,
  mgh4: 12,
};
let orderType = {
  Arrivée: 1,
  Triage: 2,
  "Examen médical": 3,
  Laboratoire: 4,
  Imagerie: 5,
  "Fin de PEC Médicale": 6,
  avisdemande: 7,
  out: 8,
};
let types = [
  ["A", "Arrivée"],
  ["T", "Triage"],
  ["E", "Examen médical"],
  ["L", "Laboratoire"],
  ["I", "Imagerie"],
  ["D", "Fin de PEC Médicale"],
  ["S", "avisdemande"],
  ["exit", "out"],
];
let ehlToSchool = {
  application: "app",
  ap1: "p1",
  ap2: "p2",
  bosc1: "b1",
  bosc2: "b2",
  bosc3: "b3",
  bosc4: "b4",
  bosc5: "b5",
  bosc6: "b6",
  mgh1: "m1",
  mgh2: "m2",
  mgh3: "m3",
  mgh4: "m4",
};

let start = {
  application: false,
  ap1: true,
  ap2: false,
  bosc1: true,
  bosc2: false,
  bosc3: false,
  bosc4: false,
  bosc5: false,
  bosc6: false,
  mgh1: true,
  mgh2: false,
  mgh3: false,
  mgh4: false,
};
let borderstart = {
  application: true,
  ap1: true,
  ap2: false,
  bosc1: true,
  bosc2: false,
  bosc3: false,
  bosc4: false,
  bosc5: false,
  bosc6: false,
  mgh1: true,
  mgh2: false,
  mgh3: false,
  mgh4: false,
};

let borderend = {
  application: true,
  ap1: false,
  ap2: true,
  bosc1: false,
  bosc2: false,
  bosc3: false,
  bosc4: false,
  bosc5: false,
  bosc6: true,
  mgh1: false,
  mgh2: false,
  mgh3: false,
  mgh4: true,
};

let colorType = {
  application: "#570098",
  ap1: "#6619a0",
  ap2: "#7030a0",
  bosc1: "#773aa5",
  bosc2: "#7e49a7",
  bosc3: "#8558a9",
  bosc4: "#8e6bab",
  bosc5: "#9678af",
  bosc6: "#9b82b1",
  mgh1: "#9f8ab1",
  mgh2: "#a396af",
  mgh3: "#a9a0b1",
  mgh4: "#ada8b1",
};

let backColorType = {
  application: "#570098",
  ap1: "#6619a0",
  ap2: "#7030a0",
  bosc1: "#773aa5",
  bosc2: "#7e49a7",
  bosc3: "#8558a9",
  bosc4: "#8e6bab",
  bosc5: "#9678af",
  bosc6: "#9b82b1",
  mgh1: "#9f8ab1",
  mgh2: "#a396af",
  mgh3: "#a9a0b1",
  mgh4: "#ada8b1",
};

const ScreenEhl = (props) => {
  const [hour, setHour] = useState(m().tz("Europe/Paris").format("HH"));
  const [data, setData] = useState(props.data);
  const [echecs, setEchecs] = useState({});
  const [datafilter, setDataFilter] = useState(props.data);
  const [options, setOptions] = useState([]);
  const [selectedOption, setOptionSelected] = useState({ value: 0, label: "Application Date" });

  useEffect(() => {
    let options = [
      { value: 0, label: "Application Date", key: 0 },
      { value: 1, label: "Failure", key: 1 },
      { value: 2, label: "Success", key: 2 },
    ];
    setOptions(options);
  }, []);

  useEffect(() => {
    let val = {};
    props.data.map((item) => {
      let test = false;
      Object.keys(orderT).map((key) => {
        if (key !== "application" && item[key + "_grade"] !== null && item[key + "_grade"] < 4) {
          test = true;
          return;
        }
      });
      if (test === true) val[item.id] = item;
    });
    setEchecs(val);
  }, [props.data]);

  useEffect(() => {
    if (selectedOption.value === 0) {
      setDataFilter(props.data);
    } else {
      let dataEchecs = {};
      dataEchecs =
        selectedOption.value === 1
          ? Object.values(echecs)
          : props.data.filter((item) => !echecs[item.id]);
      setDataFilter(dataEchecs);
    }
  }, [props.data, selectedOption]);

  const renderHeader = () => {
    return (
      <div className="row fix-header">
        <div className="col-3">
          <div className="row div-title " style={{ textAlign: "center" }}>
            <div className="col-4 font-table-title">Id</div>
            <div className="col-2  font-table-title">GPA</div>
            <div className="col-2  font-table-title">Country</div>
            <div className="col-1  font-table-title">Age</div>
            <div className="col-3  font-table-title">Gender</div>
          </div>
        </div>
        <div className="col-9">
          <div className="row table-div-2" style={{ textAlign: "center" }}>
            {Object.keys(orderT).map((type, index) => {
              return (
                <div
                  keys={index}
                  className={`font-table-title`}
                  style={{
                    background: `linear-gradient(to left,  #f2f2f2
                  ${start[type] ? "90%" : "100%"}, white 10%)`,
                    padding: "10px 0px",
                    borderBottomLeftRadius: `${borderstart[type] ? "10px" : 0} `,
                    borderTopLeftRadius: `${borderstart[type] ? "10px" : 0} `,
                    borderBottomRightRadius: `${borderend[type] ? "10px" : 0} `,
                    borderTopRightRadius: `${borderend[type] ? "10px" : 0} `,
                  }}
                >
                  {" "}
                  {ehlToSchool[type].charAt(0).toUpperCase() + ehlToSchool[type].slice(1)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return datafilter.map((event, i) => {
      let prog = getEvents(event);
      let Color = i % 2 === 0 ? "#f3f1fc" : "#dff2f6";
      return (
        <div key={i} className="row row-0-table">
          <div className="col-3">
            <div
              className="row table-div-1"
              style={{
                textAlign: "center",
                backgroundColor: i % 2 === 0 ? "#f3f1fc" : "#dff2f6",
                height: "110%",
              }}
            >
              <div className="col-4">
                {event.id}
                {echecs[event.id] && event.id === echecs[event.id].id ? (
                  <>
                    <br></br> <img className="covid-png" src={LogoAlert} />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-2">{event.gpa}</div>
              <div className="col-2">{event.school_country}</div>
              <div className="col-1">{event.age}</div>
              <div className="col-3">{event.sex ? event.sex : "-"}</div>
            </div>
          </div>
          <div className="col-9">
            <div className="row table-div-2 mrg-t-10" style={{ textAlign: "center" }}>
              {/* <p className="span-prog" style={{position:'absolute',width : `10%`}}>  </p> */}

              {Object.keys(orderT).map((type, index) => {
                if (event[type + "_dt"] || type === "application") {
                  let color =
                    event[type + "_grade"] && event[type + "_grade"] < 4
                      ? "#00b0f0"
                      : colorType[type];
                  return (
                    <div
                      style={{
                        background: `linear-gradient(to left, ${Color}
                               ${start[type] ? "90%" : "100%"}, white 10%)`,
                        borderBottomRightRadius: `${borderend[type] ? "10px" : 0} `,
                        borderTopRightRadius: `${borderend[type] ? "10px" : 0} `,
                      }}
                      key={index}
                      data-tip={true}
                      data-for={`${type}-${event.id}`}
                      className="two-div"
                    >
                      <div className="date-ehl">{moment(event[type + "_dt"]).format("MMM YY")}</div>
                      <div>
                        <span className={`span-1 span-A`} style={{ backgroundColor: color }}>
                          {type === "application"
                            ? "App"
                            : ehlToSchool[type].charAt(0).toUpperCase() +
                              ehlToSchool[type].slice(1)}

                          {type !== "application" && (
                            <ReactTooltip place="left" id={`${type}-${event.id}`} effect="solid">
                              <span>{`${"confidence"}${" : "}${(
                                event[type + "_conf"] * 100
                              ).toFixed(2)}%`}</span>
                            </ReactTooltip>
                          )}
                        </span>
                        {index > 0 && orderT[type] <= prog && (
                          <span className="span-prog-left"></span>
                        )}

                        {index < 12 && <span className="span-prog-dashed"></span>}
                        {index > 0 && index < 12 && <span className="span-prog-dashed-left"></span>}

                        {orderT[type] < prog && <span className="span-prog"></span>}
                      </div>
                      .
                      <div style={{ width: "auto" }} className="grade-ehl">
                        {event[type + "_grade"] &&
                          event[type + "_grade"].toFixed(1) +
                            `${event[type + "_grade"] < 4 ? " F" : " P"}`}
                      </div>
                      {index > 0 && <span className="transparentt" />}
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className="two-div"
                    style={{
                      background: `linear-gradient(to left, ${Color}
                          ${start[type] ? "90%" : "100%"}, white 10%)`,
                      borderBottomRightRadius: `${borderend[type] ? "10px" : 0} `,
                      borderTopRightRadius: `${borderend[type] ? "10px" : 0} `,
                    }}
                  >
                    <div></div>
                    <div>
                      <span className={`span-1`}></span>
                      {index < 12 && <span className="span-prog-dashed"></span>}
                      {index > 0 && index < 12 && <span className="span-prog-dashed-left"></span>}

                      {index > 0 && orderT[type] <= prog && (
                        <span className="span-prog-left"></span>
                      )}
                      {orderT[type] < prog && <span className="span-prog"></span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const getEvents = (events) => {
    let prog = 0;

    Object.keys(orderT).map((key) => {
      if (events[`${key}_dt`] && events[`${key}_conf`] === 1) prog = orderT[key];
    });

    return prog;
  };

  return (
    <div className="row" style={{ paddingTop: "5%" }}>
      <div className="w-100 first-row">
        <div className="row row0">
          <div
            className="col-5 col-xl-5 col-md-5 col-12 container-select"
            style={{ paddingRight: 0, marginBottom: 50, zIndex: 9 }}
          >
            <Select
              value={selectedOption}
              className="selectJ"
              menuColor="red"
              isSearchable={false}
              openMenuOnClick={true}
              onChange={(selectedOption) => setOptionSelected(selectedOption)}
              options={options}
            />
          </div>
        </div>
      </div>

      <div className="w-100 table-g table-scroll w-fixe border-t">
        {data && renderHeader()}
        <div className="w-100 w-fixe" style={{ height: "75vh" }}>
          {data && renderTable()}
        </div>
      </div>
    </div>
  );
};

export default ScreenEhl;
