import React, { useMemo } from "react";
import "./style.scss";
import moment from "moment";
import { sumBy } from "lodash";

function Previsionnel({ ufs, previsionnel }) {
  const today = useMemo(() => moment(), []);

  const filteredUfs = useMemo(
    () => previsionnel.filter(({ uf }) => ufs.includes(uf) ?? false),
    [ufs, previsionnel],
  );

  const summary = useMemo(
    () => ({
      litsReserves: sumBy(filteredUfs, (uf) => uf.litsReserves),
      admNonProgr60: sumBy(filteredUfs, (uf) => uf.admNonProgr60),
      admNonProgr120: sumBy(filteredUfs, (uf) => uf.admNonProgr120),
      admProgr24: sumBy(filteredUfs, (uf) => uf.admProgr24),
      admProgr48: sumBy(filteredUfs, (uf) => uf.admProgr48),
      dms: sumBy(filteredUfs, (uf) => uf.dms) / filteredUfs.length,
      sortie24: sumBy(filteredUfs, (uf) => uf.sortie24),
      sortie48: sumBy(filteredUfs, (uf) => uf.sortie48),
    }),
    [filteredUfs],
  );

  return (
    <>
      <div
        className="row"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: "-10px",
        }}
      >
        <fieldset>
          <legend className="titleIndicators">
            Previsionnel du {today.format("DD.MM.YYYY")} (pour 48h)
          </legend>
          <div
            className="col-12 decompte-indicators"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Lits réservés</span>
              </h5>
              <p className="screen3-indicator">{summary.litsReserves}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Adm. Non Prog. 60'</span>
              </h5>
              <p className="screen3-indicator">{summary.admNonProgr60}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Adm. Non Prog. 120'</span>
              </h5>
              <p className="screen3-indicator">{summary.admNonProgr120}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Adm. Prog. 24h</span>
              </h5>
              <p className="screen3-indicator">{summary.admProgr24}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Adm. Prog. 48h</span>
              </h5>
              <p className="screen3-indicator">{summary.admProgr48}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>DMS</span>
              </h5>
              <p className="screen3-indicator">{summary.dms?.toFixed(1) ?? "0"}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Sorties 24h</span>
              </h5>
              <p className="screen3-indicator">{summary.sortie24}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>Sorties 48h</span>
              </h5>
              <p className="screen3-indicator">{summary.sortie48}</p>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default Previsionnel;
