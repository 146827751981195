import { format } from "date-fns";

const green = "#00ff00";
const yellow = "#ef8616";

export function p2pStatus(entity) {
  if (entity.latestPtahAck?.event) {
    const date = new Date(entity.latestPtahAck.event_date);
    const timecode = format(date, "HH:mm");

    switch (entity.latestPtahAck.event) {
      case "TFRDV": {
        return {
          label: `CONF / ${timecode}`,
          color: green,
        };
      }
      case "ACPAT": {
        return {
          label: `PEC / ${timecode}`,
          color: green,
        };
      }
      case "DPPAT": {
        return {
          label: `DEP / ${timecode}`,
          color: green,
        };
      }
      case "ARBLC": {
        /* INFO: if the patient has arrived, he's not moving anymore
          return {
            label: `ARR / ${timecode}`,
            color: green,
          };
        */
        return null;
      }
      default: {
        return {
          label: "CHK PTAH",
          color: yellow,
        };
      }
    }
  } else if (entity.bedDispatchBedManagementStatus?.status === "REQUESTED") {
    return { label: "Demande en cours", color: green };
  } else {
    return null;
  }
}
