/**
 * @ By: Theo Bensaci
 * @ Date: 13:38:59 06.10.2022
 * @ Description: creat my own tool tips
 */

import { partialRight } from "lodash";
import "./index.css";

export class MyToolTips {
  static instance = null;
  /**
   * Create a tooltips
   * @param {float} offsetX offset x
   * @param {float} offsetY offset y
   */
  constructor(offsetX, offsetY) {
    this.instance = this;
    this.htmlElement = null; // html ellement of the tool tips
    this.pos = { x: 0, y: 0 }; // position x y of the tool tips
    this.offset = { x: offsetX, y: offsetY }; // offset of the tool tips
    this.parent = null; // DOMElement how call the tooltips
    this.onDestroy = false;
    this.timeout = null;
  }

  /**
   * Show tool tips
   * @param {string} text text use on tool tips
   * @param {DOMElement} parent object how call the tooltips
   * @returns
   */
  show(text, parent) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    if (this.htmlElement != null) return;

    // create element
    this.htmlElement = document.createElement("div");

    this.htmlElement.className = "myToolTips";

    let container = document.createElement("div");
    container.id = "container";
    let textReplace = text.replaceAll("\n", "<br>");
    container.innerHTML = textReplace;
    /*
        let textContent=document.createTextNode(text);
        container.appendChild(textContent);*/

    this.htmlElement.appendChild(container);
    let el = this;
    this.htmlElement.addEventListener("mouseover", (event) => {
      el.updatePos(event);
    });
    this.htmlElement.addEventListener("mousemove", (event) => {
      el.updatePos(event);
    });

    document.body.appendChild(this.htmlElement);
    this.parent = parent;
  }

  /**
   * Update pos of tool tips
   * @param {event} event event use for get mous pose
   * @returns
   */
  updatePos(event) {
    if (this.htmlElement == null || event == null || !this.checkIfOnBox(event)) {
      this.unShow(event);
      return;
    }
    let newPos = {
      x: event.pageX - this.htmlElement.offsetWidth / 2 - this.offset.x,
      y: event.pageY - this.htmlElement.offsetHeight - this.offset.y,
    };
    this.pos = newPos;

    this.htmlElement.style.top = this.pos.y + "px";
    this.htmlElement.style.left = this.pos.x + "px";
  }

  /**
   * unShow the tooltips
   * @param {event} event event how provoque this
   * @returns
   */
  unShow(event) {
    if (this.htmlElement == null) return;

    // check if cusor is out of box
    if (event != null && this.checkIfOnBox(event)) {
      this.updatePos(event);
      return;
    }
    let el = this;
    el.htmlElement.remove();
    el.htmlElement = null;
    el.parent = null;
  }

  forceUnShow() {
    if (this.htmlElement == null) return;
    let el = this;
    el.htmlElement.remove();
    el.htmlElement = null;
    el.parent = null;
  }

  /**
   * regturn the first parent with certain type
   * @param {DOMElement} el ellement
   * @param {string} nodeName type to search
   * @returns parent ellement
   */
  getParent(el, nodeName) {
    if (el.nodeName == nodeName) return el;
    return this.getParent(el.parentElement, nodeName);
  }

  /**
   * Check si
   * @param {*} event
   * @returns
   */
  checkIfOnBox(event) {
    if (this.parent == null) return false;
    let rect = this.parent.getBoundingClientRect();
    let isOutX =
      event.pageX < rect.left + window.scrollX + rect.width &&
      event.pageX > rect.left + window.scrollX;
    let isOutY =
      event.pageY < rect.top + window.scrollY + rect.height &&
      event.pageY > rect.top + window.scrollY;
    return isOutX && isOutY;
  }

  static getInstance(offsetX, offsetY) {
    if (this.instance == null) {
      this.instance = new MyToolTips(offsetX, offsetY);
    }
    this.instance.offset = { x: offsetX, y: offsetY };
    return this.instance;
  }
}
