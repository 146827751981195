import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";

import Line from "../../../components/chartV2/lineBar";
import StackedBar from "../../../components/chartV2/barStacked";

import Clock from "react-live-clock";
import Select from "react-select";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import { Capitalize } from "../../../utils/functions";

Moment.globalTimezone = "Europe/Paris";

const ScreenEmergencyRealise = (props) => {
  const [hour, setHour] = useState(m().tz("Europe/Paris").format("HH"));
  const { t, i18n } = useTranslation(["emergency"]);
  const [day, setDay] = useState("J0");
  const [selectedOptionDayList, setSelectedDayList] = useState({
    value: "list1",
    label: t("list-1"),
    key: "list-1",
  });
  const [selectedOptionDay, setSelectedDay] = useState({
    value: "J1",
    label: t("d - 1"),
    key: t("d - 1"),
  });
  const optionsDays = [
    { value: "J1", label: t("d - 1"), key: "J - 1" },
    { value: "J2", label: t("d - 2"), key: "J - 2" },
    { value: "J3", label: t("d - 3"), key: "J - 3" },
    { value: "J4", label: t("d - 4"), key: "J - 4" },
    { value: "J5", label: t("d - 5"), key: "J - 5" },
    { value: "J6", label: t("d - 6"), key: "J - 6" },
  ];
  const [stackBarData, setStackBarData] = useState(null);
  const [stackBarDataDay, setStackBarDataDay] = useState(null);

  useEffect(() => {
    setSelectedDay({
      value: selectedOptionDay.value,
      key: selectedOptionDay.key,
      label: t(selectedOptionDay.key),
    });
    setSelectedDayList({
      value: selectedOptionDayList.value,
      key: selectedOptionDayList.key,
      label: t(selectedOptionDayList.key),
    });
  }, [i18n.language]);

  let types = [
    ["Admissions", t("admission")],
    ["Patients", t("patients")],
    ["Hospitalisations", t("hospitalizations")],
    ["Imageries", t("imageries")],
    ["Discharges", t("discharges")],
    ["Seniors", t("seniors")],
    ["Stress", t("stress")],
  ];

  const chartTypes = [
    ["Admissions", t("admission")],
    ["Patients", t("patients")],
    ["Hospitalisations", t("hospitalizations")],
    ["Imageries", t("imageries")],
    ["Discharges", t("discharges")],
    ["Seniors", t("seniors")],
    ["Stress", t("stress")],
  ];

  const handleHour = (date) => {
    if (date.split(":")[0] !== hour) setHour(date.split(":")[0]);
  };
  useEffect(() => {
    let dataStackBar = {};
    const color = ["#823d09", "#c65a0f", "#f4b47d", "#fdc9a4", "#fde4d7"];
    Object.values(props.data["PredictionJ0ToJ4_V3"]["currentAttention"]).map((type, index) => {
      dataStackBar[type["targetName"]] = {};
      for (let i = 1; i <= 5; i++) {
        dataStackBar[type["targetName"]][type["topFeature" + i]] = {
          label: t(type["topFeature" + i].toLowerCase()),
          backgroundColor: color[i - 1],
          borderWidth: 2,
          borderColor: "white",
          data: [type["topScore" + i].toFixed(2) * 100],
        };
      }
    });
    setStackBarData(dataStackBar);
  }, []);

  useEffect(() => {
    let dataStackBarDay = {};
    const color = ["#823d09", "#c65a0f", "#f4b47d", "#fdc9a4", "#fde4d7"];
    let dataRealise = props.data["PredictionJ_1ToJ_7_V3"][selectedOptionDay.value];

    types.map((type, index) => {
      dataRealise[type[0]] &&
        dataRealise[type[0]]["currentAttention"] &&
        dataRealise[type[0]]["currentAttention"].length > 0 &&
        Object.values(dataRealise[type[0]]["currentAttention"]).map((realise, index) => {
          dataStackBarDay[realise["targetName"]] = {};
          for (let i = 1; i <= 5; i++) {
            dataStackBarDay[realise["targetName"]][realise["topFeature" + i]] = {
              label: t(realise["topFeature" + i].toLowerCase()),
              backgroundColor: color[i - 1],
              borderWidth: 2,
              borderColor: "white",
              data: [realise["topScore" + i].toFixed(2) * 100],
            };
          }
        });
    });
    setStackBarDataDay(dataStackBarDay);
  }, [selectedOptionDay, props.data]);

  const renderValuesMeasures = () => {
    let dataRealiseByDay = props.data["PredictionJ_1ToJ_7_V3"][selectedOptionDay.value];

    return types.map((type, index) => {
      return (
        <div
          key={index}
          className="row row-minus col-lg-12 col-md-6 col-s12"
          style={{ marginBottom: 10 }}
        >
          {/*  //<div className="index-label row">{Capitalize(t(type[1]))} {m(data[type[0]]['PredDate'][0]).tz('Europe/Paris').format('DD.MM')}</div> */}
          <div className="index-label row">
            {Capitalize(t(type[1]))}{" "}
            {(dataRealiseByDay[type[0]]["measuredValuesMax"] !== null &&
              dataRealiseByDay[type[0]]["measuredValuesMax"].length !== 0) ||
            (dataRealiseByDay[type[0]]["measuredValuesSum"] !== null &&
              dataRealiseByDay[type[0]]["measuredValuesSum"].length !== 0)
              ? m(dataRealiseByDay[type[0]]["dateTimePrediction"][0])
                  .tz("Europe/Paris")
                  .format("DD.MM")
              : "-"}
          </div>

          <div className="index-value w-100 row  col-lg-12">
            <div className="col-lg-6 col-md-6  col-sm-6 col-6 real-value">
              {(dataRealiseByDay[type[0]]["measuredValuesMax"] !== null &&
                dataRealiseByDay[type[0]]["measuredValuesMax"].length !== 0) ||
              (dataRealiseByDay[type[0]]["measuredValuesSum"] !== null &&
                dataRealiseByDay[type[0]]["measuredValuesSum"].length !== 0)
                ? type[0] === "Patients" || type[0] === "Stress"
                  ? Math.round(dataRealiseByDay[type[0]]["measuredValuesMax"][0])
                  : Math.round(
                      dataRealiseByDay[type[0]]["measuredValuesSum"][
                        dataRealiseByDay[type[0]]["measuredValuesSum"].length - 1
                      ],
                    )
                : "-"}
            </div>
            <div className="col-lg-6 col-md-6  col-sm-6 col-6 prediction-ratio-wrapper">
              <p className="prediction-ratio">
                {(dataRealiseByDay[type[0]]["predictionMax"] !== null &&
                  dataRealiseByDay[type[0]]["predictionMax"].length !== 0) ||
                (dataRealiseByDay[type[0]]["predictionSum"] !== null &&
                  dataRealiseByDay[type[0]]["predictionSum"].length !== 0)
                  ? type[0] === "Patients" || type[0] === "Stress"
                    ? Math.round(dataRealiseByDay[type[0]]["predictionMax"][0])
                    : Math.round(
                        dataRealiseByDay[type[0]]["predictionSum"][
                          dataRealiseByDay[type[0]]["predictionSum"].length - 1
                        ],
                      )
                  : "-"}
                /{" "}
                {(dataRealiseByDay[type[0]]["relativePrecisionMax"] !== null &&
                  dataRealiseByDay[type[0]]["relativePrecisionMax"].length !== 0) ||
                (dataRealiseByDay[type[0]]["relativePrecisionSum"] !== null &&
                  dataRealiseByDay[type[0]]["relativePrecisionSum"].length !== 0)
                  ? type[0] === "Patients" || type[0] === "Stress"
                    ? Math.round(
                        dataRealiseByDay[type[0]]["relativePrecisionMax"][0] &&
                          dataRealiseByDay[type[0]]["relativePrecisionMax"][0] * 100,
                      )
                    : Math.round(
                        dataRealiseByDay[type[0]]["relativePrecisionSum"][0] &&
                          dataRealiseByDay[type[0]]["relativePrecisionSum"][0] * 100,
                      )
                  : "-"}
                %
              </p>
              <p className="prediction-label">{Capitalize(t("prediction"))}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPredDate = () => {
    let dataRealiseByDay = props.data["PredictionJ_1ToJ_7_V3"][selectedOptionDay.value];

    return {
      pred: dataRealiseByDay["Patients"]["dateTimePrediction"][2].split("T")[0],
    };
  };
  const renderChartDayMinus = () => {
    let dataRealiseByDay = props.data["PredictionJ_1ToJ_7_V3"][selectedOptionDay.value];
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.15 : "280";

    return chartTypes.map((type, i) => {
      if (
        dataRealiseByDay[type[0]]["measuredValues"] &&
        dataRealiseByDay[type[0]]["measuredValues"].length !== 0
      )
        return (
          <>
            <div
              key={i}
              className="j-minus-elements row col-11 col-lg-11"
              style={{ display: "inline-block", height: height + "px" }}
            >
              <Line
                traduction="emergency"
                key={i}
                language={i18n.language}
                box={false}
                reference={true}
                ideal={true}
                titleSize={18}
                label={true}
                J0={false}
                data={dataRealiseByDay[type[0]]}
                type={type[0]}
                title={
                  Capitalize(t(type[1])) +
                  " " +
                  m(dataRealiseByDay[type[0]]["dateTimePrediction"][2])
                    .tz("Europe/Paris")
                    .format("DD.MM")
                }
                annotations={false}
              />
            </div>
            <div
              className="col-2 padding-0"
              style={{
                display: "inline-block",
                height: height * 0.8,
                marginLeft: "-10%",
                alignSelf: "flex-end",
              }}
            >
              {stackBarData && (
                <StackedBar traduction="emergency" data={stackBarDataDay[type[0]]} key={i} />
              )}
            </div>
          </>
        );
      else {
        return (
          <div style={{ paddingTop: "3%", height: 280, marginBottom: "1.5%" }}>
            <h4 className="title-chart-type">{Capitalize(t(type[1]))}</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h4 style={{ marginTop: 20 }}>{t("data not available")} </h4>
            </div>
          </div>
        );
      }
    });
  };
  const renderChartImgJMinus = () => {
    let data = props.data["PredictionImgJ_1ToJ_7"][selectedOptionDay.value];

    let dataOld = props.data["Yesterday"];
    if (selectedOptionDay.value !== "J1") {
      dataOld = props.data["PredictionJ_1ToJ_7_V3"][selectedOptionDay.value];
    }
  };
  const renderChartDayPlus = () => {
    let dataRealiseDay = props.data["PredictionJ0ToJ4_V3"][day];
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.15 : "280";

    return chartTypes.map((type, i) => {
      if (
        dataRealiseDay[type[0]]["measuredValues"] &&
        dataRealiseDay[type[0]]["measuredValues"].length !== 0
      ) {
        return (
          <>
            <div
              key={i}
              className="j-plus-elements row col-11 col-lg-11"
              style={{ display: "inline-block", height: height + "px" }}
            >
              <Line
                traduction="emergency"
                key={i}
                timeSeries={true}
                showAnnoation={false}
                label={false}
                language={i18n.language}
                box={false}
                J0={true}
                titleSize={18}
                hour={hour}
                annotations={true}
                data={dataRealiseDay[type[0]]}
                hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"][type[0]]}
                latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"][type[0]]}
                type={type[0]}
                title={
                  Capitalize(t(type[1])) +
                  " " +
                  m(
                    dataRealiseDay[type[0]]["dateTimePrediction"][
                      dataRealiseDay[type[0]]["dateTimePrediction"].length - 1
                    ].split("T")[0],
                  )
                    .tz("Europe/Paris")
                    .format("DD.MM")
                }
              />
            </div>
            <div
              className="col-2 padding-0"
              style={{
                display: "inline-block",
                height: height * 0.8,
                marginLeft: "-10%",
                alignSelf: "flex-end",
              }}
            >
              {stackBarData && (
                <StackedBar traduction="emergency" data={stackBarData[type[0]]} key={i} />
              )}
            </div>
          </>
        );
      }
      return (
        <div style={{ paddingTop: 20, height: 280 }}>
          <h4 className="title-chart-type">
            {Capitalize(t(type[1])) +
              " " +
              m(
                dataRealiseDay[type[0]]["dateTimePrediction"][
                  dataRealiseDay[type[0]]["dateTimePrediction"].length - 1
                ].split("T")[0],
              )
                .tz("Europe/Paris")
                .format("DD.MM")}
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginTop: 20 }}>{t("data not available")} </h4>
          </div>
        </div>
      );
    });
  };

  const renderChartImgJPlus = () => {
    let data = props.data["PredictionImgJ_1ToJ_7"][day];

    let dataOld = props.data["PredictionJ0ToJ4_V3"][day];
  };

  return (
    <div className="row" style={{ paddingTop: "1%" }}>
      <div style={{ display: "none" }}>
        <Clock
          format={"HH:mm:ss"}
          onChange={(date) => handleHour(date.output)}
          timezone={"Europe/Paris"}
        />
      </div>
      <div className="w-100 first-row">
        <div className="row row0">
          <div className="col-12" style={{ paddingRight: 0, marginBottom: 50 }}>
            <Select
              value={selectedOptionDay}
              className="selectJ"
              isSearchable={false}
              onChange={(selectedOptionDay) => setSelectedDay(selectedOptionDay)}
              options={optionsDays}
            />
          </div>
          <div className="col-lg-5 col-12"></div>
        </div>
      </div>

      {window.innerWidth <= 991.98 ? (
        <div className="row">
          <div className="row">
            <div className="w-100 row title-pred">
              <h4
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {Capitalize(t("the measures of the"))}
                {" " + m(renderPredDate()["pred"]).tz("Europe/Paris").format("DD.MM")}
              </h4>
            </div>
            <fieldset className="fieldset-b">
              <legend className="title-leg">{Capitalize(t("measures"))}</legend>
              <div className="row" id="j-minus-indexes">
                {renderValuesMeasures()}
              </div>
            </fieldset>
          </div>

          <div className="row ">{renderChartDayPlus()}</div>
          <div className=" row ">
            <div className="w-100 " style={{ marginBottom: 20 }}></div>

            {selectedOptionDayList.value !== "list1"
              ? renderChartImgJMinus()
              : renderChartDayMinus()}
          </div>
        </div>
      ) : (
        <div className="row w-100">
          <div className="col-lg-2 ">
            <fieldset className="fieldset-b">
              <legend>{Capitalize(t("measures"))}</legend>
              <div className="row" id="j-minus-indexes" className="v2">
                <div className="row row-minus" style={{ marginTop: "20px" }}>
                  <div className="index-label row">
                    {t("dms")}{" "}
                    {m(
                      props.data["PredictionJ_1ToJ_7_V3"]["DMS"][selectedOptionDay.value][
                        "dateHeureMinDebUrg"
                      ],
                    )
                      .tz("Europe/Paris")
                      .format("DD.MM")}
                  </div>
                  <div className="index-value w-100 row ">
                    <div style={{ textAlign: "center", t }} className=" w-100 real-value">
                      {props.data["PredictionJ_1ToJ_7_V3"]["DMS"][selectedOptionDay.value][
                        "losHour"
                      ].toFixed(1)}
                      h
                    </div>
                  </div>
                </div>
                {renderValuesMeasures()}
              </div>
            </fieldset>
          </div>

          <div className=" col-lg-5 ">
            {selectedOptionDayList.value !== "list1"
              ? renderChartImgJMinus()
              : renderChartDayMinus()}
          </div>

          <div className="col-lg-5">
            {selectedOptionDayList.value !== "list1" ? renderChartImgJPlus() : renderChartDayPlus()}
          </div>
        </div>
      )}
    </div>
  );
};
export default ScreenEmergencyRealise;
