import React, { useState, useEffect, useRef } from "react";
import { API } from "../../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Capitalize } from "../../../utils/functions";
import { useRequest } from "../../../hooks/use-api";
import { useUfLabels } from "../../../hooks/use-uf-labels";
import { useQuery, useMutation } from "@tanstack/react-query";
import { pick } from "lodash";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import "./index.css";

function UfRelatedParameters() {
  const { t, i18n } = useTranslation(["administration"]);
  const [message, setMessage] = useState(null);
  const [selectedUf, setSelectedUf] = useState(null);
  const request = useRequest();

  const {
    data: [[allParameters]],
    isLoading,
    refetch,
  } = useQuery(
    ["parameters-uf"],
    () =>
      Promise.all([
        request({
          method: "GET",
          path: API.PARAMETERBED_ALLPARAMETERSUF,
        }),
      ]),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  console.log(allParameters);

  return (
    <div className="row bedParamaterUfScreen" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title-translation">{t("uf related parameters")}</h1>
        <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
          {message
            ? Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))
            : null}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          {isLoading || !allParameters ? (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          ) : (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr style={{ background: "#007bff", color: "white" }}>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("uf concerned"))}</span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("buffer beds"))}</span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("deadlines for making beds available"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("start of the preferred admission time slot for patients"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("end of the preferred admission time slot for patients"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("auto"))}</span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("hebergementReturnParameter_title"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("HoursNotifBedManagementParameter_title"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("Quota"))}
                    </span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {allParameters.map((parameter) =>
                  selectedUf === parameter.uf ? (
                    <EditUfParameter
                      key={parameter.uf}
                      parameter={parameter}
                      closeEdit={() => setSelectedUf(null)}
                      refresh={refetch}
                      t={t}
                    />
                  ) : (
                    <ViewUfParameter
                      key={parameter.uf}
                      parameter={parameter}
                      disabled={selectedUf !== null && selectedUf !== parameter.uf}
                      openEdit={() => setSelectedUf(parameter.uf)}
                    />
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

function timeConvert(time){
  if(isNaN(time)){
    // si time stamp
    let a = time.split(":");
    if(a.length>1){
      return a[0]+":"+a[1];
    }
    return time;
  }

  let m= (time)%60;
  let h = (time-m)/60;
  return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

function hhmmToTime(value, fullTime=false){
  if(fullTime){
    console.log(value+":00");
    return value+":00";
  }
  let split=value.split(":");
  let time=parseInt(split[0])*60+parseInt(split[1]);
  return time;
}

function EditUfParameter({ parameter, closeEdit, refresh, t}) {
  const request = useRequest();
  const ufLabels = useUfLabels();

  const updateParameter = useMutation(async function (data) {
    await request({
      method: "PUT",
      path: API.PARAMETERBED_PARAMETERUF,
      data,
    });
  });


  const { control, register, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      uf: parameter.uf,
      bufferBeds: parameter.bufferBeds ?? [],
      bedAvailabilityDelay: timeConvert(parameter.DeadlineBedsAvailable) ?? null,
      start_best_period_brancar: timeConvert(parameter.start_best_period_brancar) ?? null,
      end_best_period_brancar:timeConvert(parameter.end_best_period_brancar) ?? null,
      auto: parameter.auto ?? false,
      hebergementReturn: parameter.hebergement_return ?? null,
      rapatriationPercentageDms: parameter.rapatriation_percentage_dms ?? null,
      quota_acomod_in_perc: parameter.quota_acomod_in_perc ?? false,
      quota_acomod_percen: parameter.quota_acomod_percen ?? null,
      quota_acomod_int: parameter.quota_acomod_int ?? null,
    },
  });

  const values = {
    auto: watch("auto"),
    quota_perc:watch("quota_acomod_in_perc")
  };

  async function save(data) {
    await toast.promise(
      updateParameter.mutateAsync(data).then(() => refresh()),
      {
        pending: "Paramètres en cours de sauvegarde...",
        success: "Paramètres sauvegardés avec succès !",
        error: "Erreur lors de la sauvegarde des paramètres",
      },
    );
    close();
  }

  function close() {
    reset();
    closeEdit();
  }


  return (
    <tr>
      <td className="bedParamater">
        {ufLabels[parameter.uf] ? `${ufLabels[parameter.uf]} (${parameter.uf})` : parameter.uf}
      </td>
      <td className="bedParamater" style={{ minWidth: 250 }}>
        <div>
          <Controller
            name="bufferBeds"
            control={control}
            rules={{ }}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value.map((bed) => ({ value: bed, label: bed }))}
                styles={{ fontSize: 20, paddingBottom: -20 }}
                placeholder="Sélectionner des lits"
                isSearchable
                isMulti
                onChange={(options) => field.onChange(options?.map((o) => o.value) ?? [])}
                options={parameter.ufBeds.map(([chambre, beds]) => ({
                  label: `Chambre ${chambre}`,
                  options: beds.map((bed) => ({
                    value: bed,
                    label: `Lit ${bed}`,
                  })),
                }))}
              />
            )}
          />
        </div>
      </td>
      <td className="bedParamater">
        <input type="time" {...register("bedAvailabilityDelay", { required: true,setValueAs:(val)=>hhmmToTime(val) })} className="form-control" />
      </td>
      <td className="bedParamater">
        {values.auto ? (
          timeConvert(parameter.start_best_period_brancar_set)
        ) : (
          <input type="time" {...register("start_best_period_brancar", { required: true,setValueAs:(val)=>hhmmToTime(val,true)})} className="form-control" />
        )}
      </td>
      <td className="bedParamater">
        {values.auto ? (
          timeConvert(parameter.end_best_period_brancar_set)
        ) : (
          <input type="time" {...register("end_best_period_brancar", { required: true,setValueAs:(val)=>hhmmToTime(val,true)})} className="form-control" />
        )}
      </td>
      <td className="bedParamater">
        <input type="checkbox" {...register("auto")} className="form-control" />
      </td>
      <td className="bedParamater">
        <input type="checkbox" {...register("hebergementReturn")} className="form-control" />
      </td>
      <td className="bedParamater">
        <input
          {...register("rapatriationPercentageDms", { required: true })}
          className="form-control"
        />
      </td>
      <td className="bedParamater quota">
        {values.quota_perc?
            <input
            {...register("quota_acomod_percen", { required: true })}
            className="form-control"
          />
        :
          <input
            {...register("quota_acomod_int", { required: true })}
            className="form-control"
          />
        }
        <div>
        {t("pourcentageCheckBox")}
        <input type="checkbox" {...register("quota_acomod_in_perc")} className="form-control" />
        </div>
      </td>
      <td>
        <>
          <form onSubmit={handleSubmit(save)} style={{ display: "inline-block" }}>
            <button
              type="submit"
              style={{
                margin: 5,
                border: 0,
                padding: 0,
                display: "inline-block",
                background: "none",
              }}
              className="icon-size"
            >
              <ImCheckmark
                style={{
                  color: "green",
                  fontSize: 18,
                  marginTop: -2,
                  cursor: "pointer",
                }}
              />
            </button>
          </form>
          <a style={{ margin: 5 }} className="icon-size" onClick={close}>
            <ImCross
              style={{
                color: "red",
                fontSize: 18,
                marginTop: -2,
                cursor: "pointer",
              }}
            />
          </a>
        </>
      </td>
    </tr>
  );
}

function ViewUfParameter({ parameter, disabled, openEdit }) {
  const ufLabels = useUfLabels();

  function open() {
    openEdit();
  }

  let quota = (parameter.quota_acomod_in_perc)?(parameter.quota_acomod_percen*100):parameter.quota_acomod_int;

  return (
    <tr>
      <td className="bedParamater">
        {ufLabels[parameter.uf] ? `${ufLabels[parameter.uf]} (${parameter.uf})` : parameter.uf}
      </td>
      <td className="bedParamater" style={{ minWidth: 250 }}>
        {parameter.bufferBeds?.length > 0 ? parameter.bufferBeds?.join(", ") : "-"}
      </td>
      <td className="bedParamater">{timeConvert(parameter.DeadlineBedsAvailable)}</td>
      <td className="bedParamater">
        {timeConvert(parameter.auto ? parameter.start_best_period_brancar_set: parameter.start_best_period_brancar)}
      </td>
      <td className="bedParamater">
        {timeConvert(parameter.auto ? parameter.end_best_period_brancar_set : parameter.end_best_period_brancar)}
      </td>
      <td className="bedParamater">
        <input type="checkbox" checked={parameter.auto} disabled className="form-control" />
      </td>
      <td className="bedParamater">
        <input
          type="checkbox"
          checked={parameter.hebergement_return}
          disabled
          className="form-control"
        />
      </td>
      <td className="bedParamater">{parameter.rapatriation_percentage_dms*100}%</td>
      <td className="bedParamater">{
        (quota==null)?"-":quota+((parameter.quota_acomod_in_perc)?"%":"")
      }</td>
      <td>
        <a onClick={open} className={clsx(disabled ? "disable icon-size" : "icon-size")}>
          <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
        </a>
      </td>
    </tr>
  );
}

export default UfRelatedParameters;
