import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./index.scoped.css";
import "chartjs-plugin-annotation";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import "moment/locale/fr";
import { Capitalize } from "../../../utils/functions";
import "moment/locale/de";
import "moment/locale/nl";
import { FaArrowAltCircleRight, FaArrowRight } from "react-icons/fa";
import { Button } from "react-bootstrap";

const ScreenHospitalisationProgress = (props) => {
  const { t, i18n } = useTranslation(["hospitalisation"]);
  const [data, setData] = useState(props.data);
  const [loss, setLoss] = useState([]);
  const [Height, setHeight] = useState(null);
  const [leaving24H, setLeaving24H] = useState(null);
  const [leaving48H, setLeaving48H] = useState(null);
  const [selectedTypeOriginsOption, setTypeOriginsOptioSelected] = useState({
    value: null,
    key: "all origins",
    label: t("all origins"),
  });
  const [selectedSectorOption, setSectorOptionSelected] = useState({
    value: null,
    key: "all sectors",
    label: t("all sectors"),
  });
  const [selectedDiagOption, setDiagOptionSelected] = useState({
    value: null,
    key: "all diagnostics",
    label: t("all diagnostics"),
  });
  const [selectedIepOption, setIepOptionSelected] = useState({
    value: null,
    key: "all ieps",
    label: t("all ieps"),
  });
  const [selectedBedOption, setBedOptionSelected] = useState({
    value: null,
    key: "all beds",
    label: t("all beds"),
  });
  const [selectedPredAdmOption, setPredAdmOptionSelected] = useState({
    value: null,
    key: "all patients",
    label: t("all patients"),
  });
  const [selectedSortOption, setSelectedSortOption] = useState({
    value: 0,
    key: "sort by entry time",
    label: t("sort by entry time"),
  });

  const [sectorOptions, setsectorOptions] = useState([]);
  const [bedOptions, setBedOptions] = useState([]);
  const [predAdmOptions, setPredAdmOptions] = useState([
    { value: null, key: "all patients", label: t("all patients") },
    { value: 3, key: "current patients", label: t("current patients") },
    { value: 2, key: "patients to come", label: t("patients to come") },
    { value: 0, key: "emergencies to come", label: t("emergencies to come") },
    {
      value: 1,
      key: "upcoming pre-admissions",
      label: t("upcoming pre-admissions"),
    },
  ]);
  const [sortOptions, setsortOption] = useState([
    { value: 0, key: "sort by entry time", label: t("sort by entry time") },
    { value: 1, key: "sort by bed", label: t("sort by bed") },
  ]);
  const [IepsOptions, setIepsOptions] = useState([]);
  const [iepsPred, setIepsPred] = useState(null);
  const [typeOriginsOptions, settypeOriginsOptions] = useState([]);
  const [diagOptions, setdiagOptions] = useState([]);
  const [lossPreAdm, setLossPredAdm] = useState([]);
  const [lossPreAdmArray, setLossPredAdmArray] = useState([]);

  const [page, setPage] = useState(0);
  const [nb, setNB] = useState(null);
  const pas = 30;
  const [numPage, setNumPage] = useState(null);
  const myRef = React.createRef();
  const [zindexD, setZIndexD] = useState(1);
  const [zindexI, setZIndexI] = useState(1);
  const [zindexP, setZIndexP] = useState(1);

  const [searchDropDown, setSearchDropDown] = useState(false);
  useEffect(() => {
    let preAdm;
    if (selectedBedOption.value === null) {
      preAdm = [...lossPreAdm, ...loss];
    } else if (selectedBedOption.value === "free") {
      let lossPreAdmArray = [];
      lossPreAdm.map((item) => {
        lossPreAdmArray.push(item.lit + item.uf);
      });
      setLossPredAdmArray(lossPreAdmArray);

      let i = lossPreAdm.length;
      data["losOfEmergenciesFree"].map((item) => {
        if (!lossPreAdmArray.includes(item.lit + item.uf)) {
          i++;
        }
      });

      setNumPage(1);
      let h = i * 50 + "px";
      setPage(0);
      setNB(i);
      let h2 = "75vh";
      setHeight({ h1: h, h2: h2 });
      return;
    } else if (selectedBedOption.value === "occuped" || selectedPredAdmOption.value === 3) {
      preAdm = [...loss];
    }

    const pages =
      preAdm.length % pas === 0 ? parseInt(preAdm.length / pas) : Math.ceil(preAdm.length / pas);
    setNumPage(pages);

    let h = preAdm.slice(pas * page, pas * (page + 1)).length * 50 + "px";
    setPage(0);
    setNB(preAdm.length);
    let h2 = "75vh";
    setHeight({ h1: h, h2: h2 });
  }, [loss, lossPreAdm]);

  useEffect(() => {
    let values = [];
    setPage(0);
    if (selectedBedOption.value === "occuped" || selectedPredAdmOption.value === 3) {
      setLossPredAdm(values);
      return;
    }

    if (!data) return;
    selectedBedOption &&
      (selectedBedOption.value === null || selectedBedOption.value === "free") &&
      props.preAdm &&
      Object.values(props.preAdm).length > 0 &&
      Object.values(props.preAdm).map((item) => {
        if (selectedIepOption && selectedIepOption.value) return;
        if (selectedTypeOriginsOption && selectedTypeOriginsOption.value) return;
        if (selectedDiagOption && selectedDiagOption.value) return;
        if (
          selectedSectorOption &&
          selectedSectorOption.value &&
          item[0]["ideal_area"] !== selectedSectorOption.value
        )
          return;
        if (
          selectedPredAdmOption &&
          (selectedPredAdmOption.value === 0 || selectedPredAdmOption.value === 1)
        ) {
          if (item.some((l) => l.preadm_flag !== `${selectedPredAdmOption.value}`)) return;
        }
        if (
          !item.some((l) =>
            data.losIndicators["occupedBed"] && data.losIndicators["occupedBed"][l.lit + l.uf]
              ? true
              : false,
          )
        ) {
          values = [...values, { ...item[0], free: true }];
        }
      });
    if (selectedSortOption.value === 0) {
      values.sort((a, b) => (moment(a["date_in"]).diff(moment(b["date_in"])) >= 0 ? 1 : -1));
    } else if (selectedSortOption.value === 1) {
      values.sort((a, b) =>
        parseInt(a.lit.replace(/[^\d.-]/g, "")) >= parseInt(b.lit.replace(/[^\d.-]/g, "")) ? 1 : -1,
      );
    }

    setLossPredAdm(values);
  }, [
    props.data,
    selectedTypeOriginsOption,
    selectedSectorOption,
    selectedDiagOption,
    selectedIepOption,
    selectedPredAdmOption,
    selectedSortOption,
    selectedBedOption,
  ]);

  const renderFilters = () => {
    return (
      <>
        {" "}
        <div className="row">
          <div className="col-lg-2 col-md-4 col-12" style={{ zIndex: 8, marginBottom: 10 }}>
            <Select
              value={selectedSectorOption}
              className="selectJ1"
              isSearchable={false}
              onChange={(selectedOption) => setSectorOptionSelected(selectedOption)}
              options={sectorOptions}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ zIndex: zindexP, marginBottom: 10 }}>
            <Select
              value={selectedTypeOriginsOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setTypeOriginsOptioSelected(selectedOption)}
              options={typeOriginsOptions}
              onMenuOpen={() => setZIndexP(7)}
              onMenuClose={() => setZIndexP(5)}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ zIndex: zindexD, marginBottom: 10 }}>
            <Select
              value={selectedDiagOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setDiagOptionSelected(selectedOption)}
              options={diagOptions}
              onMenuOpen={() => setZIndexD(7)}
              onMenuClose={() => setZIndexD(5)}
            />
          </div>
          <div className="col-lg-1 col-md-4 col-12" style={{ zIndex: zindexI, marginBottom: 10 }}>
            <Select
              value={selectedIepOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setIepOptionSelected(selectedOption)}
              options={IepsOptions}
              onMenuOpen={() => setZIndexI(7)}
              onMenuClose={() => setZIndexI(5)}
            />
          </div>
          <div className="col-lg-1 col-md-4 col-12" style={{ zIndex: 6, marginBottom: 10 }}>
            <Select
              value={selectedBedOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setBedOptionSelected(selectedOption)}
              options={bedOptions}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ zIndex: 6, marginBottom: 10 }}>
            <Select
              value={selectedPredAdmOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setPredAdmOptionSelected(selectedOption)}
              options={predAdmOptions}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ zIndex: 5, marginBottom: 10 }}>
            <Select
              value={selectedSortOption}
              className="selectJ1"
              isSearchable={true}
              onChange={(selectedOption) => setSelectedSortOption(selectedOption)}
              options={sortOptions}
            />
          </div>
        </div>
        <div
          className="row w-100"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          {
            <p className="d-flex">
              {Capitalize(t("results"))} : {nb} , {Capitalize(t("pages"))} : {numPage}{" "}
            </p>
          }
          <div className=" d-flex  " style={{ justifyContent: "flex-start", marginLeft: 10 }}>
            <Button
              onClick={() => move(-1)}
              disabled={page === 0}
              style={{ width: "50px" }}
              variant="outline-primary"
            >{`<<`}</Button>

            <Button disabled style={{ marginLeft: "2%" }} variant="primary">
              {page + 1}
            </Button>

            <Button
              onClick={() => move(+1)}
              disabled={page === numPage - 1}
              style={{ marginLeft: "2%", width: "50px" }}
              variant="outline-primary"
            >{`>>`}</Button>
          </div>
        </div>
      </>
    );
  };

  const move = (i) => {
    setPage(page + i);
    myRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    let diag;
    let sectorOptions = [],
      typeOriginsOptions = [],
      diagOptions = [],
      iepsPred = {},
      ieps = [];
    let test = { sector: [], diag: [], Origins: [] };

    props.data["losOfEmergencies"].map((losOfEmergencies) => {
      let iepPred = [];

      if (losOfEmergencies["Beds"] && losOfEmergencies["Beds"].length > 0) {
        if (!test.sector.includes(losOfEmergencies["Beds"][0].secteur.trim()))
          sectorOptions = [
            ...sectorOptions,
            {
              value: losOfEmergencies["Beds"][0].secteur.trim(),
              key: losOfEmergencies["Beds"][0].secteur.trim(),
              label: losOfEmergencies["Beds"][0].secteur.trim(),
            },
          ];
        test.sector = [...test.sector, losOfEmergencies["Beds"][0].secteur.trim()];
      }

      if (losOfEmergencies["ModeEntree"]) {
        if (!test.Origins.includes(losOfEmergencies["ModeEntree"].code)) {
          typeOriginsOptions = [
            ...typeOriginsOptions,
            {
              value: losOfEmergencies["ModeEntree"].code,
              key: losOfEmergencies["ModeEntree"].description,
              label: losOfEmergencies["ModeEntree"].description,
            },
          ];
          test.Origins = [...test.Origins, losOfEmergencies["ModeEntree"].code];
        }
      }

      if (losOfEmergencies.diag !== "") {
        diag = losOfEmergencies.diag.split(",");
        diag.length > 0 &&
          diag.map((val) => {
            if (!test.diag.includes(val.trim())) {
              test.diag = [...test.diag, val.trim()];
              diagOptions = [
                ...diagOptions,
                { value: val.trim(), label: val.trim(), key: val.trim() },
              ];
            }
          });
      }
      ieps = [...ieps, { value: losOfEmergencies.iep, label: losOfEmergencies.iep }];
      iepsPred[losOfEmergencies.iep] = [];
      if (props.preAdm && props.preAdm[losOfEmergencies.lit + losOfEmergencies.uf]) {
        props.preAdm[losOfEmergencies.lit + losOfEmergencies.uf].map((l) => {
          if (moment(moment.utc().add(2, "days").endOf("day")).diff(l["date_in"]) > 0) {
            iepsPred[losOfEmergencies.iep] = [...iepsPred[losOfEmergencies.iep], l.iep];
            ieps = [...ieps, { value: l.iep, label: l.iep, key: l.iep }];
          }
        });
      }
    });

    setIepOptionSelected({
      value: selectedIepOption.value,
      key: selectedIepOption.key,
      label: t(selectedIepOption.key),
    });
    setBedOptionSelected({
      value: selectedBedOption.value,
      key: selectedBedOption.key,
      label: t(selectedBedOption.key),
    });
    setPredAdmOptionSelected({
      value: selectedPredAdmOption.value,
      key: selectedPredAdmOption.key,
      label: t(selectedPredAdmOption.key),
    });
    setSelectedSortOption({
      value: selectedSortOption.value,
      key: selectedSortOption.key,
      label: t(selectedSortOption.key),
    });
    setTypeOriginsOptioSelected({
      value: selectedTypeOriginsOption.value,
      key: selectedTypeOriginsOption.key,
      label: t(selectedTypeOriginsOption.key),
    });
    setSectorOptionSelected({
      value: selectedSectorOption.value,
      key: selectedSectorOption.key,
      label: t(selectedSectorOption.key),
    });
    setDiagOptionSelected({
      value: selectedDiagOption.value,
      key: selectedDiagOption.key,
      label: t(selectedDiagOption.key),
    });

    setsectorOptions([
      { value: null, key: "all sectors", label: t("all sectors") },
      ...sectorOptions,
    ]);
    settypeOriginsOptions([
      { value: null, key: "all origins", label: t("all origins") },
      ...typeOriginsOptions,
    ]);
    setdiagOptions([
      { value: null, key: "all diagnostics", label: t("all diagnostics") },
      ...diagOptions,
    ]);
    setIepsOptions([{ value: null, key: "all ieps", label: t("all ieps") }, ...ieps]);
    setBedOptions([
      { value: null, key: "all beds", label: t("all beds") },
      { value: "occuped", key: "occupied beds", label: t("occupied beds") },
      { value: "free", key: "free beds", label: t("free beds") },
    ]);

    setPage(0);
    setIepsPred(iepsPred);
  }, [data, i18n.language]);

  useEffect(() => {
    setData(props.data);
    let loss = [];
    props.data["losOfEmergencies"].map((los) => {
      //if (los.lit === 0) return;

      // if(iep!=='' && iep.length>=3 ){
      //     if(!(`${los.iep}`).includes(iep) ){
      //    if(!props.preAdm[los.lit] || props.preAdm[los.lit].length <= 0 || !props.preAdm[los.lit].some(l =>
      //     (`${l.iep}`).includes(iep) && (moment(moment.utc().add(7,'days').endOf('day')).diff(l['date_in']) >0 ) ) )
      //     return
      //     }
      // }

      let test = false;
      if (selectedBedOption.value === "free") return;

      if (selectedIepOption && selectedIepOption.value && los["iep"] !== selectedIepOption.value) {
        if (iepsPred[los.iep].length === 0) return;
        if (!iepsPred[los.iep].includes(selectedIepOption.value)) return;
      }

      if (
        selectedSectorOption &&
        selectedSectorOption.value &&
        los.Beds &&
        los.Beds[0].secteur.trim() !== selectedSectorOption.value
      )
        return;
      if (
        selectedTypeOriginsOption &&
        selectedTypeOriginsOption.value &&
        los.type_entree == selectedTypeOriginsOption.value
      )
        return;
      if (
        selectedDiagOption &&
        selectedDiagOption.value &&
        !los.diag.includes(selectedDiagOption.value)
      )
        return;

      if (
        selectedPredAdmOption &&
        (selectedPredAdmOption.value === 0 || selectedPredAdmOption.value === 1)
      ) {
        if (
          !props.preAdm ||
          !props.preAdm[los.lit + los.uf] ||
          Object.values(props.preAdm[los.lit + los.uf]).length === 0
        )
          return;
        if (
          Object.values(props.preAdm[los.lit + los.uf]).some(
            (l) => l.preadm_flag !== `${selectedPredAdmOption.value}`,
          )
        )
          return;
      }
      if (selectedPredAdmOption && selectedPredAdmOption.value === 2) {
        if (
          !props.preAdm ||
          !props.preAdm[los.lit + los.uf] ||
          Object.values(props.preAdm[los.lit + los.uf]).length === 0
        )
          return;
      }
      loss = [...loss, los];
    });

    if (selectedSortOption.value === 1) {
      loss.sort((a, b) =>
        parseInt(a.lit.replace(/[^\d.-]/g, "")) >= parseInt(b.lit.replace(/[^\d.-]/g, "")) ? 1 : -1,
      );
    }

    setLoss(loss);
    setSearchDropDown(true);
    let leaving24 = [];
    let leaving48 = [];
    let day = moment.utc().startOf("day");
    let tomorrow = moment.utc().startOf("day").add(1, "days");
    loss.map((los) => {
      if (loss.includes(los.iep)) return;

      let relativePredictedDuration24 =
        parseFloat(moment.utc(los["date_sortie_predite"]).diff(day, "minutes")) / 1440;

      if (relativePredictedDuration24 > 0 && relativePredictedDuration24 < 1) {
        leaving24 = [...leaving24, los.iep];
      }
      if (relativePredictedDuration24 >= 1 && relativePredictedDuration24 < 2) {
        leaving48 = [...leaving48, los.iep];
      }
    });
    setLeaving24H(leaving24);
    setLeaving48H(leaving48);
  }, [
    props.data,
    selectedTypeOriginsOption,
    selectedSectorOption,
    selectedDiagOption,
    selectedIepOption,
    selectedPredAdmOption,
    selectedSortOption,
    selectedBedOption,
  ]);

  const TypeOriginsUrgence = (type) => {
    switch (type) {
      case "0":
        return t("TRANSFERT INTERNE");
      case "1":
        return t("CONSULTATION");
      case "2":
        return t("MEDECIN EXT SANS PASSAGE URG");
      case "3":
        return t("TRANSFERT D'UN AUTRE CH");
      case "4":
        return t("URGENCE ACCID.VOIE PUBLIQUE");
      case "5":
        return t("URGENCE ACCID.DU TRAVAIL");
      case "6":
        return t("URGENCE DOMICILE");
      case "7":
        return t("URGENCES SMUR,POMPIERS,AMB");
      case "8":
        return t("CONVOCATION PAR L'HOPITAL");
      case "9":
        return t("HOSPITALISATION A DOMICILE");
      case "10":
        return t("HEBERGEMENT MEDICO-SOCIALE");
      case "11":
        return t("URGENCES MEDECIN EXTERIEUR");
      case "12":
        return t("NOUVEAU-NE OBSTETRIE");
      default:
        return "";
    }
  };

  const renderIndicators = () => {
    let occupedBeds = data.losIndicators["occupedBeds"];

    return (
      <div className="row">
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{Capitalize(t("anomalies"))}</p>
          <p
            className={`indicator-value ${
              props.data.losIndicators["duplicatedBeds"] < 0 && "color-anomalie"
            }`}
            style={{ color: "#fb7809" }}
          >
            {props.data.losIndicators["duplicatedBeds"] &&
              props.data.losIndicators["duplicatedBeds"].length}
          </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{Capitalize(t("occupied beds"))}</p>
          <p
            className={`indicator-value ${
              props.data.losIndicators["occupedBeds"] < 0 && "color-anomalie"
            }`}
          >
            {occupedBeds}
          </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{t("alos")}</p>
          <p
            className={`indicator-value ${props.data.losIndicators["DMS"] < 0 && "color-anomalie"}`}
          >
            {props.data.losIndicators["DMS"].toFixed(1)}
          </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{Capitalize(t("free beds"))}</p>
          <p
            className={`indicator-value ${
              props.data.losIndicators["freeBeds"] < 0 && "color-anomalie"
            }`}
          >
            {props.data.losIndicators["freeBeds"]}
          </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{Capitalize(t("24-hour discharge"))}</p>
          <p
            className={`indicator-value  ${
              props.data.losIndicators["todayLeavingPatients"] < 0 && "color-anomalie"
            }`}
          >
            {leaving24H && leaving24H.length}
          </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ color: "#1a9ad7" }}>
          <p className="indicator-label">{Capitalize(t("48-hour discharge"))}</p>
          <p
            className={`indicator-value  ${
              props.data.losIndicators["tomorrowLeavingPatients"] < 0 && "color-anomalie"
            }`}
          >
            {leaving48H && leaving48H.length}
          </p>
        </div>
      </div>
    );
  };

  const setPreAdmTooltip = (bedDispatch) => {
    return (
      <ReactTooltip id={`tooltip-${bedDispatch["iep"]}`} place="left" effect="solid">
        <ul className="tooltip-list" style={{ color: "white", zIndex: 5 }}>
          <li style={{ color: "white" }}>{`${t("iep")} : ${bedDispatch.iep}`} </li>
          <li style={{ color: "white" }}>
            {`${t("sex")} : ${
              bedDispatch.sex === "M"
                ? t("m").toUpperCase()
                : bedDispatch.sex === "F"
                ? t("f").toUpperCase()
                : "-"
            }  `}{" "}
          </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${bedDispatch.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>
            {`${t("origin")} : ${
              bedDispatch.preadm_flag == "0"
                ? Capitalize(t("emergency"))
                : Capitalize(t("pre-admission"))
            } `}{" "}
          </li>
          {bedDispatch.preadm_flag == "0" && (
            <li style={{ color: "white" }}>{`${t("reason")} : ${bedDispatch["motif"]} `} </li>
          )}
          <li style={{ color: "white" }}>{`${t("sector")} : ${bedDispatch.ideal_area}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${bedDispatch.lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(bedDispatch["date_in"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("discharge date")} : ${moment(bedDispatch["date_out"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("los")} : ${Math.round(bedDispatch["los"])} ${
              Math.round(bedDispatch["los"]) > 1 ? t("day") : t("days")
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of error`)} : +/- ${bedDispatch.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const setLOSTooltip = (los, losWithAnomalies, losWithAnomaliesCauses) => {
    return (
      <ReactTooltip id={`tooltip-${los["iep"]}`} place="left" effect="solid">
        <ul className="tooltip-list">
          <li style={{ color: "white" }}>{`${t("iep")} : ${los.iep}`} </li>
          <li style={{ color: "white" }}>{`${t("sex")} : ${t(los.sexe)}`} </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${los.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>
            {`${t("origin")} : ${TypeOriginsUrgence(los["type_entree"])} `}{" "}
          </li>
          <li style={{ color: "white" }}>{`${t("diagnostic code")} : ${
            los.diag !== null && los.diag !== "" ? los.diag : "-"
          }`}</li>
          <li style={{ color: "white" }}>{`${t("sector")} : ${los["Beds"][0].secteur}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${los["Beds"][0].lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(los["date_entree"]).format("DD.MM.YY A")}`}{" "}
          </li>

          {losWithAnomalies.includes(`${los.iep}`) ? (
            <li style={{ color: "white" }}>
              {`${t("cause of anomaly")} : ${
                losWithAnomaliesCauses[los.iep] === "used"
                  ? t("deja utilisé")
                  : losWithAnomaliesCauses[los.iep] === "bed0"
                  ? t("lit 0")
                  : losWithAnomaliesCauses[los.iep]
              }`}{" "}
            </li>
          ) : (
            <>
              <li style={{ color: "white" }}>{`${t("lit")} : ${los["Beds"][0].secteur}`} </li>
              <li style={{ color: "white" }}>
                {`${t("iep")} : ${los.iep.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}{" "}
              </li>

              <li style={{ color: "white" }}>
                {`${t("expected duration")} : ${Math.round(los["duree_attendue"])} ${t("days")}`}{" "}
              </li>

              {los.change_flag === true ? (
                <>
                  <li style={{ color: "white" }}>
                    {`${t("predicted duration")} : ${Math.round(
                      los["los_moy"] - los["change_flag"],
                    )} ${t("days")}`}{" "}
                  </li>
                  <li style={{ color: "white" }}>
                    {`${t("màj durée prédite")} : ${Math.round(los["los_moy"])} ${t("days")}`}{" "}
                  </li>
                </>
              ) : (
                <li style={{ color: "white" }}>
                  {`${t("predicted duration")} : ${Math.round(los["los_moy"])} ${t("days")}`}{" "}
                </li>
              )}

              <li style={{ color: "white" }}>
                {`${t("current length of stay")} : ${Math.round(
                  Math.abs(parseInt(moment(los["date_entree"]).diff(moment.utc(), "hours")) / 24),
                )} ${t("days")}`}{" "}
              </li>

              <li style={{ color: "white" }}>
                {`${los["change_flag"] === true ? t("màj") : ""} ${t(
                  "predicted release date",
                )} ${moment.utc(los["date_sortie_predite"]).format("DD.MM.YY A")} `}{" "}
              </li>

              <li style={{ color: "white" }}>
                {`${t(`margin of error`)} : +/- ${los.los_dev.toFixed(1)} ${t("days")}`}
              </li>
            </>
          )}
        </ul>
      </ReactTooltip>
    );
  };

  const losToGanttFormatter = (los, today, losWithAnomalies, losWithAnomaliesCauses) => {
    today = moment().startOf("day").format("YYYY-MM-DD HH:mm");

    let sector =
      los.lit !== "0" && los.Beds.length > 0 && los.Beds[0]["secteur"]
        ? los.Beds[0]["secteur"]
        : "";
    let todayMarkerPoint = 6;

    let ganttStartPoint = moment.utc().subtract(2, "day").startOf("day");

    let stay_started_earlier;
    let los_in_progress;
    let los_longer_than_gantt;
    let dateSortie = los["date_sortie"];
    if (dateSortie === null) {
      dateSortie = today;
      los_in_progress = true;
    }

    if (moment.utc(los["date_entree"]).diff(moment.utc().subtract(2, "day")) < 0) {
      stay_started_earlier = true;
    }
    let flatDateOrigins =
      stay_started_earlier === true
        ? moment.utc(ganttStartPoint).startOf("day")
        : moment.utc(los["date_entree"]).startOf("day");

    let flatDateSortiePredite = moment
      .utc(los["date_sortie_predite"])
      .startOf("day")
      .format("YYYY-MM-DD HH:mm");
    let flatDateSortie = moment.utc(dateSortie).startOf("day").format("YYYY-MM-DD HH:mm");
    let expectedLeavingDate = moment
      .utc(los["date_entree"])
      .add(Math.round(los["duree_attendue"] * 24 * 60 * 60), "seconds");

    let maxFlatDate = moment.max(
      moment.utc(dateSortie),
      moment.utc(los["date_sortie_predite"]),
      moment.utc(expectedLeavingDate),
    );
    maxFlatDate = maxFlatDate.add(1, "days");

    if (maxFlatDate.diff(moment.utc().startOf("day").add(2, "days")) > 0) {
      maxFlatDate = moment.utc().add(2, "days").startOf("day");
      los_longer_than_gantt = true;
    }

    let realLOSHoursDiff =
      Math.abs(parseInt(moment.utc(flatDateOrigins).diff(moment.utc(dateSortie), "days"))) * 24 +
      parseInt(moment.utc(dateSortie).format("H")) +
      parseInt(moment.utc(dateSortie).format("m")) / 60;

    let realLOSRatio =
      (realLOSHoursDiff /
        (Math.abs(parseInt(moment.utc(flatDateOrigins).diff(maxFlatDate, "days"))) * 24)) *
      100;

    let predictedLOSHoursDiff =
      Math.abs(
        parseInt(flatDateOrigins.diff(moment.utc(los["date_sortie_predite"]), "days")) * 24,
      ) +
      parseInt(moment.utc(los["date_sortie_predite"]).format("HH")) +
      parseInt(moment.utc(los["date_sortie_predite"]).format("mm")) / 60;

    let predictedLOSRatio =
      (predictedLOSHoursDiff /
        (Math.abs(parseInt(flatDateOrigins.diff(maxFlatDate, "days"))) * 24)) *
      100;

    let diffPLOSDays = moment
      .utc(los["date_sortie_predite"])
      .diff(moment.utc(los["date_entree"]), "hours");

    let predictedLOSDays = Math.round(parseInt(diffPLOSDays) / 24);

    let diffLOSDays = moment.utc(dateSortie).diff(moment.utc(los["date_entree"]), "hours");

    let realLOSDays = Math.round(parseInt(diffLOSDays) / 24);

    let relativePredictedDuration =
      parseInt(
        moment.utc(los["date_sortie_predite"]).diff(moment.utc().startOf("day"), "minutes"),
      ) / 1440;

    if (
      moment.utc(los["date_entree"]).diff(ganttStartPoint) > 0 &&
      (dateSortie === null || los_in_progress)
    ) {
      let startMarkerPoint =
        parseInt(moment.utc(los["date_entree"]).diff(moment.utc(ganttStartPoint), "days")) + 1;
      let per = 100 / (15 - startMarkerPoint);
      let realLOSRatio =
        startMarkerPoint >= 3
          ? 0
          : (parseInt(moment.utc().startOf("day").diff(moment.utc(los["date_entree"]), "minutes")) /
              1440) *
            per;
      let predictedLOSRatio =
        (parseInt(
          moment.utc(los["date_sortie_predite"]).diff(moment.utc(los["date_entree"]), "minutes"),
        ) /
          1440) *
        per;
      let startHourRatio =
        startMarkerPoint >= 3
          ? (parseInt(
              moment
                .utc(los["date_entree"])
                .diff(moment.utc(los["date_entree"]).startOf("day"), "minutes"),
            ) /
              1440) *
            per
          : (parseInt(
              moment.utc(los["date_entree"]).diff(
                moment
                  .utc()
                  .startOf("day")
                  .subtract(3 - startMarkerPoint, "day"),
                "minutes",
              ),
            ) /
              1440) *
            per;
      let mark_now =
        (parseInt(moment.utc().diff(moment.utc(flatDateOrigins).startOf("day"), "minutes")) /
          1440) *
        per;

      let LoS = parseInt(
        moment.utc(los["date_sortie_predite"]).diff(moment.utc(los["date_entree"]), "hours"),
      );
      let devDeg = losWithAnomalies.includes(`${los.iep}`)
        ? 100
        : (LoS - los.los_dev * 24) / (LoS + los.los_dev * 24);
      let predictedLOSRatio_dev = predictedLOSRatio + los.los_dev * per;

      return (
        <div className="gantt__row bedOccuped w-100" style={{ minHeight: 50 }}>
          <div className="gantt__row-first">
            <div className="row">
              <div className="col-4">{sector}</div>
              <div className="col-1">{los["uf"]}</div>
              <div className="col-5">{TypeOriginsUrgence(los["type_entree"])}</div>
              <div className="col-2">
                {los["lit"]} / {los["Beds"][0]["chambre"]}
                {startMarkerPoint <= 1 && leaving24H && leaving24H.includes(los.iep) && (
                  <FaArrowRight color="black" size={18} style={{ float: "right" }} />
                )}
                {startMarkerPoint <= 1 && leaving48H && leaving48H.includes(los.iep) && (
                  <FaArrowAltCircleRight color="black" size={18} style={{ float: "right" }} />
                )}
              </div>
            </div>
          </div>
          <ul className="gantt__row-bars">
            {startMarkerPoint > 1 &&
              relativePredictedDuration < 2 &&
              !losWithAnomalies.includes(`${los.iep}`) && (
                <li
                  style={{
                    gridColumn: `${startMarkerPoint} /1`,
                    display: "flex",
                  }}
                >
                  {leaving24H && leaving24H.includes(los.iep) && (
                    <FaArrowRight color="black" size={20} style={{ float: "right" }} />
                  )}

                  {leaving48H && leaving48H.includes(los.iep) && (
                    <FaArrowAltCircleRight color="black" size={20} style={{ float: "right" }} />
                  )}

                  <span
                    style={{ color: "black", fontSize: 20, float: "right" }}
                    className="glyphicon glyphicon-circle-arrow-right "
                  />
                </li>
              )}

            <li
              style={{
                gridColumn: `${startMarkerPoint} / 15`,
                display: "flex",
              }}
            >
              <div
                className="real-los-wrapper"
                data-tip
                data-for={`tooltip-${los["iep"]}`}
                style={{
                  paddingLeft: 2,
                  background: `linear-gradient(to right, ${
                    losWithAnomalies.includes(`${los.iep}`)
                      ? "#fb7809"
                      : los.sexe === "M"
                      ? "green"
                      : "#2fa2d9"
                  }, ${devDeg * 100}%, rgba(0, 0, 0, 0)`,
                  marginLeft: startHourRatio + "%",
                  width: losWithAnomalies.includes(`${los.iep}`)
                    ? predictedLOSRatio + "%"
                    : predictedLOSRatio_dev + "%",
                }}
              >
                {/* { los.sexe  === 1 && t("h")  }  { los.sexe  === 2 && t("f")  } {` ${los.age} / ${predictedLOSDays} / ${realLOSDays}`} */}
              </div>

              <div
                className={`predicted-los-wrapper ${
                  los.change_flag === true && "re-estimated-prediction"
                }`}
                style={
                  losWithAnomalies.includes(`${los["iep"]}`)
                    ? {
                        border: "transparent !important",
                        marginLeft: startHourRatio + "%",
                        width: predictedLOSRatio + "%",
                        backgroundColor: "#fb7809",
                      }
                    : {
                        marginLeft: startHourRatio + "%",
                        width: predictedLOSRatio + "%",
                      }
                }
              ></div>

              {getLosPreAdmission(los["lit"], startMarkerPoint, los["uf"])}
              <div
                className="real-los-wrapper-2"
                data-tip
                data-for={`tooltip-${los["iep"]}`}
                style={{ marginLeft: startHourRatio + "%" }}
              >
                <span>{`${los.iep} / ${los.age}   `}</span>
              </div>
              <div className="mark-now" style={{ marginLeft: mark_now + "%" }} />
            </li>
          </ul>
          {setLOSTooltip(los, losWithAnomalies, losWithAnomaliesCauses)}
        </div>
      );
    } else {
      let per = 100 / 14;
      let predictedLOSRatio =
        (parseInt(
          moment
            .utc(los["date_sortie_predite"])
            .diff(moment.utc().subtract("2", "day").startOf("day"), "minutes"),
        ) /
          1440) *
        per;
      let mark_now =
        (parseInt(moment.utc().diff(moment.utc().subtract(2, "days").startOf("day"), "minutes")) /
          1440) *
        per;
      let LoS = parseInt(
        moment
          .utc(los["date_sortie_predite"])
          .diff(moment.utc().subtract("2", "day").startOf("day"), "hours"),
      );

      let devDeg = losWithAnomalies.includes(`${los.iep}`)
        ? 100
        : (LoS - los.los_dev * 24) / (LoS + los.los_dev * 24);
      let predictedLOSRatio_dev = predictedLOSRatio + los.los_dev * per;

      return (
        <div className="gantt__row w-100" style={{ minHeight: 50 }}>
          <div className="gantt__row-first">
            <div className="row">
              <div className="col-4">{sector}</div>
              <div className="col-2">{los["uf"]}</div>
              <div className="col-4">{TypeOriginsUrgence(los["type_entree"])}</div>
              <div className="col-2">
                {los["lit"]}

                {leaving24H && leaving24H.includes(los.iep) && (
                  <FaArrowRight color="black" size={18} style={{ float: "right" }} />
                )}

                {leaving48H && leaving48H.includes(los.iep) && (
                  <FaArrowAltCircleRight color="black" size={18} style={{ float: "right" }} />
                )}
              </div>
            </div>
          </div>
          <ul className="gantt__row-bars">
            <li style={{ gridColumn: `1/15`, display: "flex" }}>
              <div
                className="real-los-wrapper"
                data-tip
                data-for={`tooltip-${los["iep"]}`}
                style={{
                  background: `linear-gradient(to right, ${
                    losWithAnomalies.includes(`${los.iep}`)
                      ? "#fb7809"
                      : los.sexe === "M"
                      ? "green"
                      : "#2fa2d9"
                  }, ${devDeg * 100}%, rgba(0, 0, 0, 0)`,
                  width: losWithAnomalies.includes(`${los.iep}`)
                    ? predictedLOSRatio + "%"
                    : predictedLOSRatio_dev + "%",
                }}
              >
                {/* { los.sexe  === 1 && t("h")  }  { los.sexe  === 2 && t("f")  } {` ${los.age} / ${predictedLOSDays} / ${realLOSDays}`} */}
              </div>
              {parseInt(moment.utc(los["date_sortie_predite"]).diff(ganttStartPoint)) >= 0 && (
                <div
                  className={`predicted-los-wrapper ${
                    los["change_flag"] === true ? t("re-estimated-prediction") : ""
                  }`}
                  style={
                    losWithAnomalies.includes(`${los.iep}`)
                      ? {
                          border: "transparent",
                          borderLeft: "none",
                          width: predictedLOSRatio + "%",
                          backgroundColor: "#fb7809",
                        }
                      : { borderLeft: "none", width: predictedLOSRatio + "%" }
                  }
                >
                  &nbsp;
                </div>
              )}
              {getLosPreAdmission(los["lit"], 1, los["uf"])}
              <div
                className="real-los-wrapper-2"
                data-tip
                data-for={`tooltip-${los["iep"]}`}
                style={{ marginLeft: 0 + "%" }}
              >
                {`${los.iep} / ${los.age}  `}
              </div>
              <div className="mark-now-2" style={{ marginLeft: mark_now + "%" }} />
            </li>
          </ul>
          {setLOSTooltip(los, losWithAnomalies, losWithAnomaliesCauses)}
        </div>
      );
    }
  };

  const freeBedsToLos = (item) => {
    let per = 100 / 14;

    let mark_now =
      (parseInt(moment.utc().diff(moment.utc().subtract(2, "days").startOf("day"), "minutes")) /
        1440) *
      per;

    return (
      <div className="gantt__row bedOccuped w-100" style={{ minHeight: 50 }}>
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-4">{item.secteur}</div>
            <div className="col-1">{item.uf}</div>
            <div className="col-5"></div>
            <div className="col-2">
              {item["lit"]} / {item["chambre"]}
            </div>
          </div>
        </div>
        <ul className="gantt__row-bars">
          <li style={{ gridColumn: `1/15`, display: "flex" }}>
            <div className="mark-now-empty" style={{ marginLeft: mark_now + "%" }}></div>
          </li>
        </ul>
      </div>
    );
  };

  const losPredToGanttFormatter = (bedDispatch) => {
    let per = 100 / 14;

    let mark_now =
      (parseInt(moment.utc().diff(moment.utc().subtract(2, "days").startOf("day"), "minutes")) /
        1440) *
      per;

    return (
      <div className="gantt__row bedOccuped w-100" style={{ minHeight: 50 }}>
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-4">{bedDispatch.ideal_area}</div>
            <div className="col-1">{bedDispatch.uf}</div>
            <div className="col-5"></div>
            <div className="col-2">
              {bedDispatch["lit"]} / {bedDispatch["Beds"][0]["chambre"]}
            </div>
          </div>
        </div>
        <ul className="gantt__row-bars">
          <li style={{ gridColumn: `1/15`, display: "flex" }}>
            <div className="mark-now-empty" style={{ marginLeft: mark_now + "%" }}></div>

            {getLosPreAdmission(bedDispatch.lit, 0, bedDispatch.uf, "-empty")}
          </li>
        </ul>
      </div>
    );
  };

  const getSexe = (val) => {
    if (val === 1) {
      return t("h");
    } else if (val === 2) return t("f");
    else return "";
  };

  const getLosPreAdmission = (lit, startMarkerPoint = 0, uf, Class = "") => {
    if (!props.preAdm || !props.preAdm[lit + uf]) return null;
    return props.preAdm[lit + uf].map((item) => {
      let startHourRatioAdm = 0;
      let realLOSRatioAdm = 0;
      let diffDay = parseInt(
        moment(item["date_in"]).diff(
          moment
            .utc()
            .subtract(startMarkerPoint === 0 ? 2 : 2 - startMarkerPoint + 1, "days")
            .startOf("day"),
          "hours",
        ),
      );
      let min = diffDay / 24;
      let nb = startMarkerPoint === 0 ? 14 : 14 - startMarkerPoint + 1;
      nb = 100 / nb;
      startHourRatioAdm = min > 0 ? min * nb : 0;
      item["date_out"] = moment.utc(item["date_in"]).add(item["los_hours"], "hours");
      diffDay = parseInt(moment.utc(item["date_out"]).diff(moment.utc(item["date_in"]), "hours"));
      min = diffDay / 24;
      realLOSRatioAdm = min > 0 ? min * nb : 0;

      let LoS = diffDay;
      let devDeg = (LoS - item.los_dev * 24) / (LoS + item.los_dev_hours);
      let predictedLOSRatio_dev = realLOSRatioAdm + item.los_dev * nb;

      if (startHourRatioAdm === 0 && realLOSRatioAdm === 0) return null;
      return (
        <>
          <div
            className={`los-preadmission-flag${Class}`}
            style={{
              background: `linear-gradient(to right, ${item.sex === "M" ? "green" : "#2fa2d9"}, ${
                devDeg * 100
              }%, rgba(0, 0, 0, 0)`,
              width: predictedLOSRatio_dev + "%",
              marginLeft: startHourRatioAdm + "%",
            }}
          />
          <div
            className={`los-preadmission-flag${item.preadm_flag === 0 ? "0" : "1"}${Class}`}
            data-tip
            data-for={`tooltip-${item["iep"]}`}
            style={{
              marginLeft: startHourRatioAdm + "%",
              width: realLOSRatioAdm + "%",
            }}
          >
            {item ? `${item["iep"]} / ${item.age}` : ""}
            {setPreAdmTooltip(item)}
          </div>
        </>
      );
    });
  };

  const losToGanttFormatterFree = (bed) => {
    return (
      <div className="gantt__row bedFree w-100">
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-4">{bed["secteur"]}</div>
            <div className="col-5"></div>
            <div className="col-2">{bed.lit}</div>
          </div>
        </div>
        <ul className="gantt__row-bars" title="">
          <li style={{ gridColumn: "1 / 2", display: "flex" }}></li>
        </ul>
      </div>
    );
  };

  const getFreeBedsSortByBeds = () => {
    return data["losOfEmergenciesFree"].sort((a, b) =>
      parseInt(a.lit.replace(/[^\d.-]/g, "")) >= parseInt(b.lit.replace(/[^\d.-]/g, "")) ? 1 : -1,
    );
  };

  const renderGantt = () => {
    let m = moment.utc();

    return (
      <div className="row div-gantt" id="div-gantt2">
        <div className="gantt__row gantt__row--months">
          <div className="gantt__row-first">
            <div className="row">
              <div className="col-4">{Capitalize(t("sector"))}</div>
              <div className="col-1">{t("uf")}</div>
              <div className="col-5">{Capitalize(t("origin"))}</div>
              <div className="col-2">
                {Capitalize(t("bed"))} / {Capitalize(t("room"))}
              </div>
            </div>
          </div>

          <span>{m.clone().subtract(2, "days").format("DD.MM")} </span>
          <span>{m.clone().subtract(1, "days").format("DD.MM")} </span>
          <span>{m.clone().format("DD.MM")} </span>
          <span>{m.clone().add(1, "days").format("DD.MM")} </span>
          <span>{m.clone().add(2, "days").format("DD.MM")} </span>
          <span>{m.clone().add(3, "days").format("DD.MM")} </span>
          <span>{m.clone().add(4, "days").format("DD.MM")} </span>
          <span>{m.clone().add(5, "days").format("DD.MM")} </span>
          <span>{m.clone().add(6, "days").format("DD.MM")} </span>
          <span>{m.clone().add(7, "days").format("DD.MM")} </span>
          <span>{m.clone().add(8, "days").format("DD.MM")} </span>

          <span>{m.clone().add(9, "days").format("DD.MM")} </span>
          <span>{m.clone().add(10, "days").format("DD.MM")} </span>
          <span>{m.clone().add(11, "days").format("DD.MM")} </span>
        </div>
        <div
          className="gantt w-100"
          ref={myRef}
          style={Height ? { maxHeight: Height.h2, overflow: "scroll" } : {}}
        >
          <div></div>
          <div
            className="gantt__row gantt__row--lines"
            data-month="5"
            style={Height ? { height: Height.h1 } : {}}
          >
            <span className=""></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
          </div>
          {[...lossPreAdm, ...loss].slice(pas * page, pas * (page + 1)).map((item) => {
            if (item.free === true) {
              return losPredToGanttFormatter(item);
            } else {
              return losToGanttFormatter(
                item,
                m.clone(),
                Object.keys(data["losWithAnomaliesCauses"]),
                data["losWithAnomaliesCauses"],
              );
            }
          })}
          {selectedBedOption.value === "free" &&
            getFreeBedsSortByBeds().map((item) => {
              if (!lossPreAdmArray.includes(item.lit + item.uf)) {
                return freeBedsToLos(item);
              }
            })}
        </div>
      </div>
    );
  };

  if (props.Wait === true) {
    return (
      <div className="row-0" style={{ marginTop: "6%" }}>
        <div className="col-12  text-center">
          <h2 className="message">
            {t("medical is generating new predictions. please try again in a few minutes ....")}
          </h2>
        </div>
        <div className="col-12 text-center">
          <a className="btn btn-primary row-0" onClick={props.getValuesByUf}>
            {t("reset")}
          </a>
        </div>
      </div>
    );
  } else
    return (
      <div className="row-0" style={{ paddingTop: "40px" }}>
        <div className="col-12">{renderIndicators()}</div>
        <div className="col-12" style={{ marginTop: 50 }}>
          {renderFilters()}
        </div>

        <div className="col-12 scroll-gant" style={{ marginTop: "2%" }}>
          {renderGantt()}
        </div>
      </div>
    );
};
export default ScreenHospitalisationProgress;
