import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { CONFIG } from "./config";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// Change the Backend (I18next data load) loadPath from 'localhost' to a endpoint accurend version
const BackendConfig = new Backend(null, {
  loadPath: CONFIG.ENDPOINT + "/locales/{{lng}}/{{ns}}.json",
});

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(BackendConfig)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: "fr",
      whitelist: ["en", "fr", "it", "de"],
      order: ["htmlTag"],
      debug: false,
      keySeparator: ":",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        wait: true,
        bindI18n: "languageChanged loaded",
      },
    },
    (err, t) => {
      // console.log("eroor :",err)
    },
  );
export default i18n;
