/**
 * @ By: Theo Bensaci
 * @ Date: 15:30:01 06.05.2022
 * @ Description: Custome comfirme box, use for call back some thing with a confirmation
 */
import React, { useState, useEffect, useRef } from "react";
import "./index.css";

/**
 *
 * @param {object} props
 *      @param {string} text text show on the top of the box
 *      @param {string} cancelText text on cancel button
 *      @param {string} okText text on confirm button
 *      @param {function} okCallBack callback function on confime
 *      @param {function} closeCallBack callback function on confime
 * @returns {JSX} Confirme box
 */
export const ComfirmBox = (props) => {
  const containerRef = useRef(null); // container ref
  const [supClassName, setSupClassName] = useState(""); // actual add class of the confirm box
  const isDead = useRef(false); // is the confirm box on close or not

  //#region Data base request

  /**
   * Cancel the comfirme box
   */
  function cancel() {
    if (isDead.current) return;
    containerRef.current.style.setProperty("--EndBackgroundColor", `var(--CancelColor)`);
    close();
  }

  /**
   * Valide the confirme box
   */
  function ok() {
    if (isDead.current) return;
    props.okCallBack();
    containerRef.current.style.setProperty("--EndBackgroundColor", `var(--OkColor)`);
    close();
  }

  /**
   * Close the confirme box
   */
  function close() {
    setSupClassName("customComfirmBox-Close");
    containerRef.current.addEventListener("animationend", () => {
      if (!isDead.current) {
        props.closeCallBack();
        isDead.current = true;
      }
    });
  }

  /**
   * Use for set-up the style of the confirme box and adapte it to the page height
   */
  function setStyle() {
    let vh = document.body.clientHeight * 0.01;
    containerRef.current.style.setProperty("--vh", `${vh}px`);
  }

  //#endregion
  useEffect(() => {
    setStyle();
  });

  return (
    <div className={"customComfirmBox " + supClassName} ref={containerRef}>
      <div id="comfirmBox">
        <h3 id="title">{props.text}</h3>
        <div id="bntDiv">
          <button
            id="cancel"
            onClick={() => {
              cancel();
            }}
          >
            {props.cancelText}
          </button>
          <button
            id="ok"
            onClick={() => {
              ok();
            }}
          >
            {props.okText}
          </button>
        </div>
      </div>
    </div>
  );
};
