import React, { useState, useEffect, useMemo } from "react";
import { isSameDay, isBefore, startOfDay, isWithinInterval, addMinutes, addHours } from "date-fns";
import moment from "moment";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import "./index.css";
import { Capitalize } from "../../../utils/functions";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import ReactLoading from "react-loading";
import { bemPatientName } from "../../../utils/bem-patient-name";
import { useUfs } from "../../../hooks/use-ufs";
import { useRequest } from "../../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";

const ScreenHospitalisationComing = (props) => {
  const { t, i18n } = useTranslation(["hospitalisation"]);

  const [loading, setLoading] = useState(false);

  const head = [
    `${t("prenom")} ${t("nom")} / ${t("iep")}`,
    t("uf"),
    t("sex"),
    t("age"),
    t("origin"),
    t("reason"),
    //t("delay (h)"),
    t("length of stay (days)"),
    t("date of arrival"),
    t("time range"),
    //t("planned sector"),
    t("recommended bed"),
    t("accommodation"),
  ];
  const head_pred = [
    `${t("prenom")} ${t("nom")} / ${t("iep")}`,
    t("uf"),
    t("sex"),
    t("age"),
    //t("delay (h)"),
    t("length of stay"),
    t("date of arrival"),
    t("time range"),
    //t("planned sector"),
    t("recommended bed"),
    t("accommodation"),
  ];

  const [dataHospitalisation, setDataHospitalisation] = useState(props.data);
  const [indicator, setIndicator] = useState(null);

  //#region api

  const getUfsByGroup = (group) => {
    let groups = group;
    let uf = [];

    Object.entries(props.screensHospData.groupes).map((item) => {
      if (item[0] === groups) {
        item[1].data.map((d) => {
          uf.push(d[0]);
        });
      }
    });
    return uf;
  };

  function getData(callback) {
    setLoading(true);
    // get uf
    const group = "SERVICE MÉDECINE";
    let options = [];
    let uf = [];
    let option;
    let optionReserve;
    Object.entries(props.screensHospData.groupes).map((item) => {
      if (item[0] === group) {
        option = { value: item[0], label: item[1]["name"] };
      }
      if (!optionReserve) optionReserve = { value: item[0], label: item[1]["name"] };

      options.push({ value: item[0], label: item[1]["name"] });

      item[1].data.map((d) => {
        uf.push({ value: d[0], label: "UF " + d[0] + " - " + d[1] });
      });
    });

    let data = { uf: getUfsByGroup(group) };
    axios
      .post(
        `${API.ENDPOINT + API.BED_DISPATCHING_HOSP_BY_UF}`,
        {
          data,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        callback(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  //#endregion

  useEffect(() => {
    if (props.data == undefined || props.data == null) {
      getData((data) => {
        setDataHospitalisation(data);
        setUpBase();
      });
    } else {
      setDataHospitalisation(props.data);
      setUpBase();
    }
  }, [props.data]);

  function setUpBase() {
    const now = new Date();
    let values = {
      "60m": 0,
      hospPred: 0,
      "120m": 0,
      "6h": 0,
      "12h": 0,
      "24h": 0,
      "noProg":0,
      P24h: 0,
      predAdmission: [],
      ScheduledAdmissions: [],
    };
    let predAdmission = [];
    let ScheduledAdmissions = [];

    for (const bed of dataHospitalisation["bedDispatching"]) {

      if(props.us.length>0 && (bed.Beds!=null || !props.us.includes(bed.Beds[0].secteur)))continue;

      const dateIn = new Date(bed.date_in);
      const preAdm = bed.preadm_flag === "1";

      if (isSameDay(dateIn, now) && !preAdm) {
        values.hospPred++;
      }

      if (isBefore(dateIn, startOfDay(now))) {
        continue;
      }

      if (preAdm) {
        ScheduledAdmissions.push(bed);

        if (
          isWithinInterval(dateIn, {
            start: now,
            end: addHours(now, 24),
          })
        ) {
          values["24h"]++;
        } else if (
          isWithinInterval(dateIn, {
            start: addHours(now, 24),
            end: addHours(now, 48),
          })
        ) {
          values["P24h"]++;
        }
      } else {
        let date=moment(dateIn);
        predAdmission.push(bed);

        if (bed.orient_ideale_name === "") {
          continue;
        }
        values["noProg"]++;     // admistion non programmer
        
        /*
        if (
          date.isBetween(moment(now),moment(now).add(60, 'm'))
        ) {
          values["60m"]++;
        } else if (
          isWithinInterval(dateIn, {
            start: moment(now).add(60, 'm').toDate(),
            end: moment(now).add(120, 'm').toDate(),
          })
        ) {
          values["120m"]++;
        }
        */
      }
    }

    values["predAdmission"] = predAdmission.sort(
      (a, b) => new Date(b.date_in).getTime() - new Date(b.date_in).getTime(),
    );
    values["ScheduledAdmissions"] = ScheduledAdmissions.sort(
      (a, b) => new Date(b.date_in).getTime() - new Date(b.date_in).getTime(),
    );
    setIndicator(values);
  }

  const renderIndicators = () => {
    let predHosp =
      props.screensData["DistributionHosp"] && props.screensData["DistributionHosp"]["J0"]
        ? props.screensData["DistributionHosp"]["J0"][props.group]["todayPrediction"]
        : "-";
    return (
      <div className="row w-100 data-header" style={{ marginTop: 30, marginBottom: 10 }}>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          <h4 className="screen3-title-indicator">
            {t("hosp").toUpperCase() + " " + moment().format("DD.MM")}
          </h4>
          <p className="screen3-indicator" style={{ color: "gray" }}>
            {dataHospitalisation && dataHospitalisation["todayLoss"].length}
          </p>
        </div>
        {/*
        <div
          className="d-flex flex-column col-md-2 col-4"
          style={{ paddingTop: 30 }}
        >
          
          <h4 className="screen3-title-indicator">{`${t(
            "hosp predictions"
          ).toUpperCase()} ${moment().format("DD.MM")}`}</h4>
          <p className="screen3-indicator"> {predHosp}</p>
          </div>*/}
        <div className="adminision-box">
          <div>
            <h4 className="screen3-title-indicator">{Capitalize(t("adm. non programmées"))}</h4>
            <p className="screen3-indicator">{indicator && indicator["noProg"]}</p>
          </div>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          <h4 className="screen3-title-indicator">{Capitalize(t("adm. scheduled (24h)"))}</h4>
          <p className="screen3-indicator"> {indicator && indicator["24h"]} </p>
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          <h4 className="screen3-title-indicator">{Capitalize(t("Adm. scheduled (48h)"))}</h4>
          <p className="screen3-indicator"> {indicator && indicator["P24h"]}</p>
        </div>
      </div>
    );
  };
  const renderAdmissionToCome = () => {
    return (
      <div className="col-12 title-lit" style={{ marginTop: 30 }}>
        <h3 style={{ color: "black" }}>{Capitalize(t(`upcoming pre-admissions`))}</h3>
        {indicator["predAdmission"].length <= 0 && (
          <p style={{ marginBottom: 50 }}> {Capitalize(t("no admission"))}</p>
        )}
        {indicator["predAdmission"].length > 0 && (
          <table
            className={`table table-bordered ${window.innerWidth <= 992 && "table-responsive"}`}
            style={{ alingSelf: "center" }}
          >
            <thead className="thead-theme">
              <tr>
                {head.map((item, i) => {
                  return (
                    <th className="s3th" scope="col" key={i}>
                      {item.toUpperCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {indicator["predAdmission"].map((predAdmission, index) => {
                //           return <tr className={` trS3 ${index<2 && 'trS3M1'}  ${index>=2 && index <5 && 'trS3M2'} `}>
                let nextHour = moment().add("1", "hours").format("YYYY-MM-DD HH");
                let next2Hour = moment().add("2", "hours").format("YYYY-MM-DD HH");
                let test = moment(predAdmission["date_in"])
                  .add(predAdmission["delay_hours"], "hours")
                  .format("YYYY-MM-DD HH");

                return (
                  <tr
                    className={` trS3 ${test === nextHour && "trS3M1"}  ${
                      test === next2Hour && "trS3M2"
                    } `}
                    key={index}
                  >
                    <td>
                      {bemPatientName(predAdmission.bemPatient)}
                      <br />
                      {predAdmission.iep
                        .toString()
                        .replace(/\s?/g, "")
                        .replace(/(\d{3})/g, "$1 ")
                        .trim()}
                    </td>
                    <td>
                      {props.screensHospData["ufs"][predAdmission.uf]} ({predAdmission.uf})
                    </td>
                    <td>{`${
                      predAdmission.sex === "M"
                        ? t("m").toUpperCase()
                        : predAdmission.sex === "F"
                        ? t("f").toUpperCase()
                        : "-"
                    }`}</td>
                    <td>{predAdmission.age ? predAdmission.age : "NAN"}</td>
                    <td>{t("emergency")}</td>
                    <td>{predAdmission.motif && predAdmission.motif.split("*")[1]}</td>
                    {/*<td>{predAdmission.delay_hours}</td>*/}

                    <td>{Math.round(predAdmission["los_hours"] / 24)}</td>
                    <td>{moment(predAdmission["date_in"]).format("DD-MM-YYYY")}</td>
                    <td>{`${moment(predAdmission["date_in"]).format("HH")}:00 / ${moment(
                      predAdmission["date_in"],
                    )
                      .add(1, "hours")
                      .format("HH")}:00`}</td>
                    {/*<td>{predAdmission["ideal_area"]}</td>*/}
                    <td>{predAdmission["lit"]}</td>
                    <td>
                      {predAdmission["hebergement_flag"] === "0" && t("no")}
                      {predAdmission["hebergement_flag"] === "1" && t("yes")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };
  const renderAdmissionPreAdmission = () => {
    return (
      <div className="col-12 title-lit" style={{ marginTop: 30 }}>
        <h3 style={{ color: "black" }}>{Capitalize(t(`scheduled admissions`))}</h3>
        {indicator["ScheduledAdmissions"].length <= 0 && <p> {Capitalize(t("no admission"))}</p>}
        {indicator["ScheduledAdmissions"].length > 0 && (
          <table
            className={`table table-bordered ${window.innerWidth <= 992 && "table-responsive"}`}
            style={{ alingSelf: "center" }}
          >
            <thead className="thead-theme">
              <tr>
                {head_pred.map((item, i) => {
                  return (
                    <th className="s3th2" scope="col" key={i}>
                      {item.toUpperCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {indicator["ScheduledAdmissions"].map((ScheduledAdmissions, index) => {
                return (
                  <tr className={` trS32`} key={index}>
                    <td>
                      {bemPatientName(ScheduledAdmissions.bemPatient)}
                      <br />
                      {ScheduledAdmissions.iep
                        .toString()
                        .replace(/\s?/g, "")
                        .replace(/(\d{3})/g, "$1 ")
                        .trim()}
                    </td>
                    <td>
                      {props.screensHospData["ufs"][ScheduledAdmissions.uf]} (
                      {ScheduledAdmissions.uf})
                    </td>
                    <td>{`${
                      ScheduledAdmissions.sex === "M"
                        ? t("m").toUpperCase()
                        : ScheduledAdmissions.sex === "F"
                        ? t("f").toUpperCase()
                        : "-"
                    }`}</td>
                    <td>{ScheduledAdmissions.age ? ScheduledAdmissions.age : "NAN"}</td>

                    {/*
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        backgroundColor: backgroundColor(ScheduledAdmissions.delay_hours),
                      }}
                    >
                      {ScheduledAdmissions.delay_hours}
                    </td>
                    */}
                    <td>{Math.round(ScheduledAdmissions["los_hours"] / 24)}</td>
                    <td>{moment(ScheduledAdmissions["date_in"]).format("DD.MM.YYYY")}</td>
                    <td>{`${moment(ScheduledAdmissions["date_in"]).format("HH")}:00 / ${moment(
                      ScheduledAdmissions["date_in"],
                    )
                      .add(1, "hours")
                      .format("HH")}:00`}</td>
                    {/*<td>{ScheduledAdmissions["ideal_area"]}</td>*/}
                    <td>{ScheduledAdmissions["lit"]}</td>
                    <td>
                      {ScheduledAdmissions["hebergement_flag"] === "0" && t("no")}
                      {ScheduledAdmissions["hebergement_flag"] === "1" && t("yes")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  return (
    <div className="row">
      {loading ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <div>
          {renderIndicators()}
          {indicator && renderAdmissionToCome()}
          {indicator && renderAdmissionPreAdmission()}
        </div>
      )}
    </div>
  );
};
export default ScreenHospitalisationComing;
