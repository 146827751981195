import React, { useMemo } from "react";
import Routes from "./routes";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers/index";
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default function App() {
  const { reset } = useQueryErrorResetBoundary();
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            suspense: true,
          },
        },
      }),
    [],
  );
  const store = useMemo(
    () =>
      createStoreWithMiddleware(
        reducer,
        process.env.NODE_ENV === "development"
          ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
          : undefined,
      ),
    [],
  );
  const persistor = useMemo(() => persistStore(store), [store]);

  return (
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={<div />} persistor={persistor}>
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <button onClick={() => resetErrorBoundary()}>Try again</button>
              </div>
            )}
          >
            <Routes />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              pauseOnHover
              pauseOnFocusLoss
              closeOnClick
              hideProgressBar={false}
              pauseOnHover
              pauseOnFocusLoss
              rtl={false}
              closeOnClick
              newestOnTop={false}
              draggable
              draggablePercent={80}
              draggableDirection="x"
              role="alert"
              theme="light"
            />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
