import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import Line from "../../../components/chartV2/linePerf";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import { Capitalize } from "../../../utils/functions";
import moment from "moment";

import { API } from "../../../utils/api";

const ScreenPerformanceHour = (props) => {
  const { t, i18n } = useTranslation(["emergency"]);
  const [dataPerformanceHour, setDataPerformanceHour] = useState(null);

  const [load, setLoad] = useState(false);
  const [selectedOptionWeek, setSelectedWeek] = useState({
    label: t("w-1"),
    key: "w-1",
    value: "w-1",
  });
  const [rateData, setRateData] = useState({
    relativePrecision: {
      prediction: 0,
      ideal: 0,
    },
    r2Score: {
      prediction: 0,
      ideal: 0,
    },
    absoluteError: {
      prediction: 0,
      ideal: 0,
    },
  });
  const [ratePeriodData, setRatePeriodData] = useState({
    relativePrecision: {
      prediction: 0,
      ideal: 0,
    },
    r2Score: {
      prediction: 0,
      ideal: 0,
    },
    absoluteError: {
      prediction: 0,
      ideal: 0,
    },
  });

  const [optionWeeks, setOptionWeeks] = useState([
    { label: t("w-0"), key: "w-0", value: "w-0" },
    { label: t("w-1"), key: "w-1", value: "w-1" },
    { label: t("w-2"), key: "w-2", value: "w-2" },
    { label: t("w-3"), key: "w-3", value: "w-3" },
    { label: t("w-4"), key: "w-4", value: "w-4" },
    { label: t("w-5"), key: "w-5", value: "w-5" },
    { label: t("w-6"), key: "w-6", value: "w-6" },
    { label: t("w-7"), key: "w-7", value: "w-7" },
    { label: t("w-8"), key: "w-8", value: "w-8" },
    { label: t("w-9"), key: "w-9", value: "w-9" },
    { label: t("w-10"), key: "w-10", value: "w-10" },
    { label: t("w-11"), key: "w-11", value: "w-11" },
  ]);

  let types = [
    ["Admissions", t("admission")],
    ["Discharges", t("discharges")],
    ["Hospitalisations", t("hospitalizations")],
    ["Imageries", t("imageries")],
    ["Seniors", t("seniors")],
    ["Patients", t("patients")],
    // ["Stress", t("stress")],
  ];

  useEffect(() => {
    let option = {
      value: selectedOptionWeek.value,
      label: t(selectedOptionWeek.key),
      key: selectedOptionWeek.key,
    };
    setSelectedWeek(option);
  }, [i18n.language]);

  useEffect(() => {
    if (load) return;

    axios
      .get(`${API.ENDPOINT + API.SCREEN_EMERGENCY_PERF_HOUR}`, {
        headers: {
          "x-auth-token": props.activeUser.user.token,
        },
      })
      .then((res) => {
        // if (
        //   moment().format("DD") == moment().isoWeekday(1).format("DD") ||
        //   !res.data["w-0"]
        // ) {
        //   setSelectedWeek({ label: t("w-1"), key: "w-1", value: "w-1" });
        // }
        setDataPerformanceHour(res.data);
        setLoad(true);

        let ops = optionWeeks;
        for (let [key, value] of Object.entries(res.data)) {
          if (value !== null && Object.keys(value).length === 0 && value.constructor === Object) {
            ops = ops.filter((op) => op.label != key);
          }
        }

        setOptionWeeks(ops);
        let rate = [],
          rateData = [],
          sum = [];

        types.map((type) => {
          rate[type[0]] = [];
          sum[type[0]] = [];
          rateData[type[0]] = [];
          sum[type[0]] = Object.values(res.data[type[0]]).reduce((previous, current) => ({
            relativePrecisionSum:
              parseFloat(current.relativePrecisionSum) + parseFloat(previous.relativePrecisionSum),
            bestRelativePrecisionSum:
              parseFloat(current.bestRelativePrecisionSum) +
              parseFloat(previous.bestRelativePrecisionSum),
            relativePrecisionMax:
              parseFloat(current.relativePrecisionMax) + parseFloat(previous.relativePrecisionMax),
            bestRelativePrecisionMax:
              parseFloat(current.bestRelativePrecisionMax) +
              parseFloat(previous.bestRelativePrecisionMax),
            r2Prediction: parseFloat(current.r2Prediction) + parseFloat(previous.r2Prediction),
            r2Best: parseFloat(current.r2Best) + parseFloat(previous.r2Best),
            maePrediction: parseFloat(current.maePrediction) + parseFloat(previous.maePrediction),
            maeBest: parseFloat(current.maeBest) + parseFloat(previous.maeBest),
          }));
          if (type[0] == "Patients" || type[0] == "Stress") {
            rateData[type[0]] = {
              relativePrecision: {
                prediction: Math.round(
                  (
                    (sum[type[0]]["relativePrecisionMax"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
                ideal: Math.round(
                  (
                    (sum[type[0]]["bestRelativePrecisionMax"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
              },
              r2Score: {
                prediction: Math.round(
                  (
                    (sum[type[0]]["r2Prediction"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
                ideal: Math.round(
                  (
                    (sum[type[0]]["r2Best"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
              },
              absoluteError: {
                prediction: (
                  sum[type[0]]["maePrediction"] / Object.values(res.data[type[0]]).length
                ).toFixed(2),
                ideal: (sum[type[0]]["maeBest"] / Object.values(res.data[type[0]]).length).toFixed(
                  2,
                ),
              },
            };
          } else {
            rateData[type[0]] = {
              relativePrecision: {
                prediction: Math.round(
                  (
                    (sum[type[0]]["relativePrecisionSum"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
                ideal: Math.round(
                  (
                    (sum[type[0]]["bestRelativePrecisionSum"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
              },
              r2Score: {
                prediction: Math.round(
                  (
                    (sum[type[0]]["r2Prediction"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
                ideal: Math.round(
                  (
                    (sum[type[0]]["r2Best"] * 100) /
                    Object.values(res.data[type[0]]).length
                  ).toFixed(0),
                ),
              },
              absoluteError: {
                prediction: (
                  sum[type[0]]["maePrediction"] / Object.values(res.data[type[0]]).length
                ).toFixed(2),
                ideal: (sum[type[0]]["maeBest"] / Object.values(res.data[type[0]]).length).toFixed(
                  2,
                ),
              },
            };
          }
        });

        setRateData(rateData);
      })
      .catch((err) => {
        // console.log(err,'err')
      });
  }, [load]);

  useEffect(() => {
    let ratePeriodData = [];

    if (!load) return;
    types.map((type) => {
      ratePeriodData[type[0]] = [];
      if (type[0] == "Patients" || type[0] == "Stress") {
        ratePeriodData[type[0]] = {
          relativePrecision: {
            prediction: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "relativePrecisionMax"
                ].s /
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                    "relativePrecisionMax"
                  ].i,
              ) * 100
            ).toFixed(0),
            ideal: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "bestRelativePrecisionMax"
                ].s /
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                    "bestRelativePrecisionMax"
                  ].i,
              ) * 100
            ).toFixed(0),
          },
          r2Score: {
            prediction: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Prediction"]
                  .s /
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                    "r2Prediction"
                  ].i,
              ) * 100
            ).toFixed(0),
            ideal: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Best"].s /
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Best"].i,
              ) * 100
            ).toFixed(0),
          },
          absoluteError: {
            prediction: parseFloat(
              dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["maePrediction"]
                .s /
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "maePrediction"
                ].i,
            ).toFixed(2),
            ideal: parseFloat(
              dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["maeBest"].s /
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["maeBest"].i,
            ).toFixed(2),
          },
        };
      } else {
        ratePeriodData[type[0]] = {
          relativePrecision: {
            prediction: (
              (dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                "relativePrecisionSum"
              ].s /
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "relativePrecisionSum"
                ].i) *
              100
            ).toFixed(0),
            ideal: (
              (parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "bestRelativePrecisionSum"
                ].s,
              ) /
                parseFloat(
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                    "bestRelativePrecisionSum"
                  ].i,
                )) *
              100
            ).toFixed(0),
          },
          r2Score: {
            prediction: (
              (parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Prediction"]
                  .s,
              ) /
                parseFloat(
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                    "r2Prediction"
                  ].i,
                )) *
              100
            ).toFixed(0),
            ideal: (
              (parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Best"].s,
              ) /
                parseFloat(
                  dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["r2Best"].i,
                )) *
              100
            ).toFixed(0),
          },
          absoluteError: {
            prediction: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "maePrediction"
                ].s,
              ) /
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]][
                  "maePrediction"
                ].i,
              )
            ).toFixed(2),
            ideal: (
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["maeBest"].s,
              ) /
              parseFloat(
                dataPerformanceHour[selectedOptionWeek["value"]]["perfSum"][type[0]]["maeBest"].i,
              )
            ).toFixed(2),
          },
        };
      }
    });

    setRatePeriodData(ratePeriodData);
  }, [selectedOptionWeek, load]);

  const renderCharts = () => {
    let values = dataPerformanceHour[selectedOptionWeek["value"]];
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.15 : "350px";

    return types.map((type, i) => {
      if (ratePeriodData && ratePeriodData[type[0]] && rateData && rateData[type[0]]) {
        return (
          <>
            <div className="row-charts-s11 row" key={i}>
              <Line
                traduction="emergency"
                LineHour={true}
                height={height}
                rateData={rateData && rateData[type[0]] ? rateData[type[0]] : null}
                ratePeriodData={
                  ratePeriodData && ratePeriodData[type[0]] ? ratePeriodData[type[0]] : null
                }
                language={i18n.language}
                box={true}
                titleSize={35}
                label={true}
                J0={false}
                perf={parseFloat(values["perfSum"][type[0]]["perf"])}
                data={values["values"][type[0]]}
                type={type[0]}
                title={Capitalize(t(type[1]))}
                annotations={false}
              />
            </div>
            <hr className="hr-day"></hr>
          </>
        );
      }
    });
  };
  useEffect(() => {
    if (!dataPerformanceHour) return;

    let ops = [
      { label: t("w-0"), key: "w-0", value: "w-0" },
      { label: t("w-1"), key: "w-1", value: "w-1" },
      { label: t("w-2"), key: "w-2", value: "w-2" },
      { label: t("w-3"), key: "w-3", value: "w-3" },
      { label: t("w-4"), key: "w-4", value: "w-4" },
      { label: t("w-5"), key: "w-5", value: "w-5" },
      { label: t("w-6"), key: "w-6", value: "w-6" },
      { label: t("w-7"), key: "w-7", value: "w-7" },
      { label: t("w-8"), key: "w-8", value: "w-8" },
      { label: t("w-9"), key: "w-9", value: "w-9" },
      { label: t("w-10"), key: "w-10", value: "w-10" },
      { label: t("w-11"), key: "w-11", value: "w-11" },
    ];

    if (moment().format("DD") == moment().isoWeekday(1).format("DD")) {
      setSelectedWeek({ label: t("w-1"), key: "w-1", value: "w-1" });
      ops.shift();
    }

    let _ops = [];
    ops.map((item) => {
      if (dataPerformanceHour[item["value"]] !== null) _ops.push(item);
    });
    setOptionWeeks(_ops);
  }, [i18n.language, dataPerformanceHour]);

  return (
    <div className="row">
      <div className="col-12 margin-title" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-12 col-md-8"></div>
          <div className="col-12 col-md-4 mrg-select-perf" style={{ marginTop: 20 }}>
            <Select
              value={selectedOptionWeek}
              className="selectJ-S11"
              isSearchable={false}
              onChange={(selectedOptionWeek) => setSelectedWeek(selectedOptionWeek)}
              options={optionWeeks}
            />
          </div>
        </div>
      </div>

      <div className="col-12" style={{ marginTop: 25 }}>
        <div className="col-12 row-line" style={{ marginTop: 50 }}>
          {dataPerformanceHour ? (
            renderCharts()
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenPerformanceHour);
