import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import Select from "react-select";
import { Capitalize } from "../../../utils/functions";
import axios from "axios";
import { API } from "../../../utils/api";
import moment from "moment";
import { connect } from "react-redux";

const ScreenRHStaffing = (props) => {
  const { t, i18n } = useTranslation("rh");
  const [options, setOptions] = useState([]);
  const [selectedOption, setOptionSelected] = useState({
    value: 0,
    key: "caregivers",
    label: t("caregivers"),
  });
  const [optionsWeek, setOptionsWeek] = useState([]);
  const [selectedOptionWeek, setOptionSelectedWeek] = useState({
    value: moment().week(),
    key:
      "week of [" +
      moment().startOf("week").format("DD.MM") +
      " - " +
      moment().endOf("week").format("DD.MM") +
      "]",
    label:
      t("week of") +
      " [" +
      moment().startOf("week").format("DD.MM") +
      " - " +
      moment().endOf("week").format("DD.MM") +
      "]",
  });
  const [dataStaffing, setDataStaffing] = useState(null);
  const [dataStaffingFilter, SetDataStaffingFilter] = useState(null);
  const [dataStaffingGroup, setDataStaffingGroup] = useState(null);
  const [load, setload] = useState(false);

  useEffect(() => {
    let options = [{ value: 0, key: "caregivers", label: t("caregivers") }];
    let optionsWeek = [];
    options = [
      ...options,
      { value: 1, key: "medicins", label: t("medicins") },
      { value: 2, key: "nurses", label: t("nurses") },
    ];
    let week = moment().week();

    for (let i = week; i < week + 4; i++) {
      optionsWeek = [
        ...optionsWeek,
        {
          value: i,
          key:
            "week of [" +
            moment()
              .startOf("week")
              .add((i - 4) * 7, "day")
              .format("DD.MM") +
            " - " +
            moment()
              .endOf("week")
              .add((i - 4) * 7, "day")
              .format("DD.MM") +
            "]",
          label:
            t("week of") +
            " [" +
            moment()
              .startOf("week")
              .add((i - 4) * 7, "day")
              .format("DD.MM") +
            " - " +
            moment()
              .endOf("week")
              .add((i - 4) * 7, "day")
              .format("DD.MM") +
            "]",
        },
      ];
    }
    setOptions(options);
    setOptionsWeek(optionsWeek);
  }, [i18n.language]);

  useEffect(() => {
    setload(false);
    axios
      .post(
        `${API.ENDPOINT}${API.RH_PREVISION_WEEK}`,
        {
          week: selectedOptionWeek.value,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setDataStaffing(res.data);
        setload(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [selectedOptionWeek]);

  useEffect(() => {
    if (dataStaffing === null) return;
    if (selectedOption.value === 0) {
      SetDataStaffingFilter(dataStaffing.weekSaffingAideSoingnant);

      groupByTimeRange();
    } else if (selectedOption.value === 1) {
      SetDataStaffingFilter(dataStaffing.weekSaffingMedecine);

      groupByTimeRange();
    } else {
      SetDataStaffingFilter(dataStaffing.weekSaffingInfermier);

      groupByTimeRange();
    }
  }, [selectedOption, dataStaffingFilter, selectedOptionWeek, dataStaffing]);

  const groupByTimeRange = () => {
    if (dataStaffing === null) return;
    let group = dataStaffingFilter
      ? dataStaffingFilter.reduce((range, a) => {
          range[a.PlageHoraire] = [...(range[a.PlageHoraire] || []), a];
          return range;
        }, {})
      : [];

    setDataStaffingGroup(group);
  };

  const backgroundColor = (num, min, max) => {
    let val = ((num - min) / (max - min)) * 10;

    let color = "green";
    if (val >= 2) color = "#6eb700";
    if (val >= 3) color = "#daec00";
    if (val >= 4) color = "yellow";
    if (val >= 5) color = "#ffc700";
    if (val >= 6) color = "orange";
    if (val >= 7) color = "red";
    if (val >= 8) color = "#bf0000";
    if (val >= 9) color = "black";

    return color;
  };
  const colorFont = (num, min, max) => {
    let val = ((num - min) / (max - min)) * 10;
    let color = "black";

    if (val >= 7) color = "white";

    return color;
  };
  const ActiveTimeRange = (heure) => {
    let heur = moment().format("HH");
    let startHeure = heure.split("-")[0].split("[")[1].split(":")[0];
    let endHeure = heure.split("-")[1].split("]")[0].split(":")[0];
    if (heur < 23) {
      if (heur < endHeure && heur >= startHeure) return "darkgrey";
    }
    if (heur === 23) {
      if (heur <= endHeure && heur > startHeure) return "darkgrey";
    }
  };
  const ActiveDay = (day) => {
    let getDay = moment().isoWeekday();
    if (day === getDay) {
      return "darkgrey";
    }
  };
  const renderListStaffing = () => {
    if (!dataStaffingGroup) return;

    return Object.entries(dataStaffingGroup).map((dataStaffing, index) => {
      return (
        <tr key={index}>
          <td
            className="td-prev"
            style={{
              backgroundColor: ActiveTimeRange(dataStaffing[0].split(" ")[1]),
            }}
          >
            {dataStaffing[0].split(" ")[0] === "Nuit" && Capitalize(t("night")) + " [00:00-06:00]"}
            {dataStaffing[0].split(" ")[0] === "Matin" &&
              Capitalize(t("morning")) + " [06:00-12:00]"}
            {dataStaffing[0].split(" ")[0] === "Après-Midi" &&
              Capitalize(t("afternoon")) + " [12:00-18:00]"}
            {dataStaffing[0].split(" ")[0] === "Soir" &&
              Capitalize(t("evening")) + " [18:00-23:59]"}
          </td>

          {dataStaffing[1].map((staffing, i) => (
            <td className="td-noborder " key={i}>
              <div className="d-flex justify-content-center pos-ralative">
                <span
                  className="ress-prev"
                  style={{
                    backgroundColor: backgroundColor(
                      staffing.NombreIdéal,
                      staffing.NombreMin,
                      staffing.NombreMax,
                    ),
                    color: colorFont(staffing.NombreIdéal, staffing.NombreMin, staffing.NombreMax),
                  }}
                >
                  {staffing.NombreIdéal}
                </span>
                <div className=" ress-plan" style={{ backgroundColor: "white", color: "red" }}>
                  <div className="position-abs">
                    <span>{staffing.Deviation}</span>
                  </div>
                </div>
              </div>
            </td>
          ))}
        </tr>
      );
    });
  };

  const renderTable = () => (
    <table className="table table-prevision  table-scroll" style={{ width: "100%" }}>
      <thead className="fix-header">
        <tr>
          <th style={{ width: "16%", backgroundColor: "white", border: "none" }}></th>

          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(1) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("monday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(2) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("tuesday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(3) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("wednesday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(4) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("thursday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(5) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("friday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(6) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("saturday"))}</span>
          </th>
          <th
            className="header th-prevision"
            style={{ width: "12%", backgroundColor: ActiveDay(7) }}
          >
            <span style={{ display: "block" }}>{Capitalize(t("sunday"))}</span>
          </th>
        </tr>
      </thead>
      <tbody>{renderListStaffing()}</tbody>
    </table>
  );

  return (
    <div className="row mrg-t">
      <div className="container-user page-plan">
        <div
          className="form-group inline-block col-lg-4 col-md-6 col-12 filter"
          style={{ zIndex: 8, marginBottom: 10, marginTop: "20px" }}
        >
          <Select
            value={selectedOption}
            className="selectJ1 form-select"
            isSearchable={false}
            onChange={(selectedOption) => setOptionSelected(selectedOption)}
            options={options}
          />
        </div>
        <div
          className="form-group inline-block col-lg-4 col-md-6 col-12 filter"
          style={{ zIndex: 7, marginBottom: 10 }}
        >
          <Select
            value={selectedOptionWeek}
            className="selectJ1 form-select"
            isSearchable={false}
            onChange={(selectedOptionWeek) => setOptionSelectedWeek(selectedOptionWeek)}
            options={optionsWeek}
          />
        </div>

        <div className="t-resp" style={{ marginTop: 50 }}>
          {load && dataStaffing ? (
            renderTable()
          ) : (
            <div
              style={{
                marginTop: "6%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactLoading type={"bars"} color="#2fa2d9" width="70px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenRHStaffing);
