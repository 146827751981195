import React, { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import { API } from "../../../utils/api";

const AddFunction = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(false);

  const addFunction = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);

    let _function = {
      name: name,
    };

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_FUNCTION}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: _function,
    })
      .then((response) => {
        setMessage(true);
        setName("");
        props.addFunction(response.data);
        setTimeout(() => setMessage(false), 3000);
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          setError(errors);
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  return (
    <div className="row" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("new function")}</h1>

        <div className="row">
          <div className="col-10 offset-1">
            <hr></hr>
            <form onSubmit={addFunction} className="form-user-new">
              <div style={{ textAlign: "center" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => (
                    <div className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the function has been successfully saved")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  className="fadeIn form-control"
                  name="_name"
                  placeholder={t("function name")}
                />
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  onClick={() => props.setPage("listFunctionStatus")}
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  disabled={name !== "" ? false : true}
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(AddFunction);
