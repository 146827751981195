import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";
import ReactLoading from "react-loading";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import { Capitalize } from "../../../utils/functions";

Moment.globalTimezone = "Europe/Paris";

let orderT = { A: 1, T: 2, E: 3, L: 4, I: 5, D: 6, S: 7, exit: 8 };
let orderType = {
  Arrivée: 1,
  Triage: 2,
  "Examen médical": 3,
  Laboratoire: 4,
  Imagerie: 5,
  "Fin de PEC Médicale": 6,
  avisdemande: 7,
  out: 8,
};
let types = [
  ["A", "Arrivée"],
  ["T", "Triage"],
  ["E", "Examen médical", "exam_time"],
  ["L", "Laboratoire", "lab_time"],
  ["I", "Imagerie", "img_time"],
  ["D", "Fin de PEC Médicale", "diag_time"],
  ["S", "avisdemande", "avis_time"],
  ["exit", "out", "er_out_type"],
];

const Table = (props) => {
  const { t, i18n } = useTranslation(["journey"]);

  const [dataPerformance, setDataPerformance] = useState(props.data);
  const [indicator, setIndicator] = useState(props.indicator);
  const [page, setPage] = useState(0);
  const itemPerPage = 100;
  const pages =
    props.data.length % itemPerPage === 0
      ? parseInt(props.data.length / itemPerPage)
      : Math.ceil(props.data.length / itemPerPage);
  const renderHeader = () => {
    if (!dataPerformance)
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 150,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );

    return (
      <div className="row fix-header-perf bg">
        <div className="col-12 col-lg-5 col-md-12">
          <div className="row div-title" style={{ textAlign: "center" }}>
            <div className="col-3 col-lg-3 col-md-3 font-table-title">{t("iep")}</div>
            <div className="col-1 col-lg-1 col-md-1 font-table-title">{Capitalize(t("age"))}</div>
            <div className="col-1 col-lg-1 col-md-1 font-table-title">{Capitalize(t("sex"))}</div>
            <div className="col-3 col-lg-3 col-md-3 font-table-title">
              {Capitalize(t("reason"))}
            </div>
            <div className="col-2 col-lg-2 col-md-1 font-table-title margin-minus">
              {Capitalize(t("gravity"))}
            </div>
            <div className="col-2 col-lg-2 col-md-1 font-table-title">
              {Capitalize(t("emergency doctor"))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 col-md-12">
          <div className="row table-div-2" style={{ textAlign: "center" }}>
            <div className="font-table-title first-col" style={{ width: "11.5%" }}>
              {Capitalize(t("admission"))}
            </div>
            <div className="font-table-title " style={{ width: "10%" }}>
              {Capitalize(t("sorting"))}
            </div>
            <div className="font-table-title " style={{ width: "10.5%" }}>
              {Capitalize(t("exam"))}
            </div>
            <div className="font-table-title " style={{ width: "10%" }}>
              {Capitalize(t("labo"))}
            </div>
            <div className="font-table-title " style={{ width: "10%" }}>
              {Capitalize(t("radio"))}
            </div>
            <div className="font-table-title " style={{ width: "10%" }}>
              {Capitalize(t("diag"))}
            </div>
            <div className="font-table-title " style={{ width: "10%" }}>
              {Capitalize(t("notice"))}
            </div>
            <div className="font-table-title " style={{ width: "10.5%" }}>
              {Capitalize(t("discharge type"))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getTypeOut = (type) => {
    if (type === "out") return t("discharge");
    if (type.includes("uf")) {
      let split = type.split("_");
      let tt = split[1] === "other" ? `${t("uf other")}` : `${t("uf").toUpperCase()} ${split[1]}`;
      return `${tt}`;
    }
  };

  const renderTable = () => {
    if (!dataPerformance) return null;
    let performances;
    if (page <= 0) performances = dataPerformance.slice(0, itemPerPage - 1);
    else performances = dataPerformance.slice(page * itemPerPage, itemPerPage * (page + 1) - 1);

    return performances.map((item, i) => {
      let data = getEvents(item.SeqPredEvent);

      return (
        <div
          className="row row-0-table"
          style={{
            backgroundColor: i % 2 === 0 ? "white" : "rgb(251 250 250)",
          }}
          key={i}
        >
          <div className="col-5">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-3">
                {item.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
                {item.covid_flag === true ? (
                  <>
                    <br></br> <img className="covid-png" src="./assets/images/covid-19-icon.png" />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-1">{item.age}</div>
              <div className="col-1">{item.sex ? item.sex : "-"}</div>
              <div className="col-3">{item.french_adm ? item.french_adm : "-"}</div>
              <div className="col-1">{item.french_triage ? item.french_triage : "-"}</div>
              <div className="col-3">{item.med_personnel ? item.med_personnel : "-"}</div>
            </div>
          </div>
          <div className="col-7" style={{ marginLeft: "-1%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="row   table-div-2" style={{ width: "95%", textAlign: "center" }}>
                {/* <p className="span-prog" style={{position:'absolute',width : `10%`}}>  </p> */}

                {types.map((type, index) => {
                  let E = data["events"];
                  let event = data["event"];
                  let prog = data["prog"];
                  let perfEvent = getPErfEvents(item.SeqPredPerf);
                  if (type[0] === "exit" && event["out"]) {
                    let ev = event["out"];
                    let date = ev["event_time"];
                    let d = parseInt(
                      moment(date).startOf("day").diff(moment().startOf("day"), "days"),
                    );

                    return (
                      <>
                        <div
                          data-tip
                          data-for={`seq-${item.iep}`}
                          className={
                            event["type_out"] || item.orient_ideale_code !== ""
                              ? `two-div-out perf-out`
                              : `two-div-out-triangle perf-triangle`
                          }
                        >
                          <div className={`div-1`}>
                            <span className="span-out">
                              {/* className={`span-out${ item.orient_ideale_code==="" ||  event['out']['confidence']>=1  ?  ( event['out']['confidence'] >= 0.9 ?  '1'  : '2' )  : ''}`}> */}
                              {moment(date).format("HH:mm")}
                            </span>
                            <br></br>
                            <span>
                              {(event["type_out"] || item.orient_ideale_code !== "") && (
                                <span className="span-out">
                                  {item.orient_ideale_code === ""
                                    ? event["type_out"]
                                    : "UF " + item.orient_ideale_code}
                                </span>
                              )}
                              {(event["type_out"] || item.orient_ideale_code !== "") &&
                                (item.orient_ideale_code !== "" || item.uf_pred_name) && (
                                  <ReactTooltip id={`seq-${item.iep}`} effect="solid">
                                    <span>
                                      {item.orient_ideale_code !== ""
                                        ? item.orient_ideale_name
                                        : item.uf_pred_name}
                                    </span>
                                    <br />
                                    {item.bedDispatching && item.bedDispatching[0] && (
                                      <span>
                                        {t("Lit")} : {item.bedDispatching[0]["ideal_bed"]}
                                      </span>
                                    )}
                                  </ReactTooltip>
                                )}
                            </span>
                          </div>
                          {!event["type_out"] && item.orient_ideale_code === "" && (
                            <div className={`triangle-right1`}></div>
                          )}
                          <br />
                          {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                            <span
                              className={`${
                                getTypeOut(perfEvent[type[2]]) === "sortie" ? " " : "perfevent"
                              }`}
                              style={{
                                color: "#00b0f0",
                                marginLeft: getTypeOut(perfEvent[type[2]]).includes("Hosp")
                                  ? "-40px"
                                  : "",
                                marginTop: "10px !important",
                              }}
                            >
                              {getTypeOut(perfEvent[type[2]]) === "sortie" ? (
                                <div class="out-triangle">
                                  <div class="div-1">
                                    <span class="l-span-out">Sortie</span>
                                    <span></span>
                                  </div>
                                  <div class="triangle-right2"></div>
                                </div>
                              ) : (
                                getTypeOut(perfEvent[type[2]])
                              )}
                            </span>
                          )}
                        </div>
                      </>
                    );
                  }

                  if (event[type[1]] && type[0] !== "exit") {
                    let date = event[type[1]]["event_time"];
                    let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                    return (
                      <div
                        data-tip
                        data-for={type[0] === "I" ? `I-${item.iep}` : null}
                        className="two-div"
                        key={index}
                      >
                        <div>{moment(date).format(`${type[0] === "A" ? "DD.MM " : ""}HH:mm`)}</div>
                        <div style={{ marginBottom: "-13px" }}>
                          <span className={`span-1 span-${type[0]}`}>
                            {type[0]}
                            {type[0] === "I" && (
                              <ReactTooltip id={`I-${item.iep}`} effect="solid">
                                <span>{event[type[1]]["event_type"]}</span>
                              </ReactTooltip>
                            )}
                          </span>
                          {index < 7 && <span className="span-prog-dashed"></span>}
                          {orderT[type[0]] < prog && <span className="span-prog"></span>}
                        </div>
                        <br></br> <br />
                        {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                          <span
                            style={{
                              color: "#00b0f0",
                              marginTop: "10px !important",
                            }}
                          >
                            {moment(perfEvent[type[2]]).format("HH:mm")}
                          </span>
                        )}
                      </div>
                    );
                  }
                  return (
                    <div className="two-div" key={index}>
                      <div></div>
                      <div>
                        <span className={`span-1`}></span>
                        {index < 7 && <span className="span-prog-dashed-second"></span>}
                        {orderT[type[0]] < prog && <span className="span-prog"></span>}
                        <br></br> <br />
                        {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                          <span
                            style={{
                              color: "#00b0f0",
                              marginTop: "10px !important",
                            }}
                          >
                            {moment(perfEvent[type[2]]).format("HH:mm")}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div style={{ width: "10%", textAlign: "right", marginTop: "-18px" }}>
                <h5 className="font-title-info">
                  {getTime(item.SeqPredPerf.timing_score).toFixed(1)} (h)
                </h5>
                <h5 className="font-title-info" style={{ marginTop: "14px" }}>
                  {item.SeqPredPerf.seq_score * 100}% (s)
                </h5>
                <h5 className="font-title-info" style={{ marginTop: "14px" }}>
                  {item.SeqPredPerf.er_out_score * 100}% (t)
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  const renderTableResponsive = () => {
    if (!dataPerformance) return null;
    let performances;
    if (page <= 0) performances = dataPerformance.slice(0, itemPerPage - 1);
    else performances = dataPerformance.slice(page * itemPerPage, itemPerPage * (page + 1) - 1);

    return performances.map((item, i) => {
      let data = getEvents(item.SeqPredEvent);

      return (
        <div
          className="row row-0-table"
          style={{
            backgroundColor: i % 2 === 0 ? "white" : "rgb(251 250 250)",
          }}
        >
          <div className="col-12">
            <div className="row table-div-1" style={{ textAlign: "center" }}>
              <div className="col-3 col-xl-3 col-md-3 font-table-body">
                {item.iep
                  .toString()
                  .replace(/\s?/g, "")
                  .replace(/(\d{3})/g, "$1 ")
                  .trim()}
                {item.covid_flag === true ? (
                  <>
                    <br></br> <img className="covid-png" src="./assets/images/covid-19-icon.png" />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-1 col-xl-1 col-md-1 font-table-body">{item.age}</div>
              <div className="col-1 col-xl-1 col-md-1 font-table-body">
                {item.sex ? item.sex : "-"}
              </div>
              <div className="col-3 col-xl-3 col-md-3 font-table-body3">
                {item.french_adm ? item.french_adm : "-"}
              </div>
              <div className="col-2 col-xl-2 col-md-1 font-table-body">
                {item.french_triage ? item.french_triage : "-"}
              </div>
              <div className="col-2 col-xl-2 col-md-1 font-table-body3">
                {item.med_personnel ? item.med_personnel : "-"}
              </div>
            </div>
          </div>
          <div className="col-12" style={{ marginLeft: "-1%", marginTop: "15px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
              }}
            >
              <div
                className="row   table-div-2"
                style={{
                  width: "95%",
                  textAlign: "center",
                  height: window.innerWidth <= 767 ? 200 : "auto",
                }}
              >
                {types.map((type, index) => {
                  let E = data["events"];
                  let event = data["event"];
                  let prog = data["prog"];
                  let perfEvent = getPErfEvents(item.SeqPredPerf);
                  if (type[0] === "exit" && event["out"]) {
                    let ev = event["out"];
                    let date = ev["event_time"];
                    let d = parseInt(
                      moment(date).startOf("day").diff(moment().startOf("day"), "days"),
                    );

                    return (
                      <>
                        <div
                          data-tip
                          data-for={`seq-${item.iep}`}
                          className={
                            event["type_out"] || item.orient_ideale_code !== ""
                              ? `two-div-out perf-out`
                              : `two-div-out-triangle perf-triangle`
                          }
                        >
                          <div className={`div-1`}>
                            <span className="span-out">{moment(date).format("HH:mm")}</span>
                            <br></br>
                            <span>
                              {(event["type_out"] || item.orient_ideale_code !== "") && (
                                <span className="span-out">
                                  {item.orient_ideale_code === ""
                                    ? event["type_out"]
                                    : "UF " + item.orient_ideale_code}
                                </span>
                              )}
                              {(event["type_out"] || item.orient_ideale_code !== "") &&
                                (item.orient_ideale_code !== "" || item.uf_pred_name) && (
                                  <ReactTooltip id={`seq-${item.iep}`} effect="solid">
                                    <span>
                                      {item.orient_ideale_code !== ""
                                        ? item.orient_ideale_name
                                        : item.uf_pred_name}
                                    </span>
                                    <br />
                                    {item.bedDispatching && item.bedDispatching[0] && (
                                      <span>
                                        {t("lit")} : {item.bedDispatching[0]["ideal_bed"]}
                                      </span>
                                    )}
                                  </ReactTooltip>
                                )}
                            </span>
                          </div>
                          {!event["type_out"] && item.orient_ideale_code === "" && (
                            <div className={`triangle-right1`}></div>
                          )}
                          <br />
                          {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                            <span
                              className={`${
                                getTypeOut(perfEvent[type[2]]) === "sortie" ? " " : "perfevent"
                              }`}
                              style={{
                                color: "#00b0f0",
                                marginLeft: getTypeOut(perfEvent[type[2]]).includes("Hosp")
                                  ? "-40px"
                                  : "",
                                marginTop: "10px !important",
                              }}
                            >
                              {getTypeOut(perfEvent[type[2]]) === "sortie" ? (
                                <div class="out-triangle">
                                  <div class="div-1">
                                    <span class="l-span-out">Sortie</span>
                                    <span></span>
                                  </div>
                                  <div class="triangle-right2"></div>
                                </div>
                              ) : (
                                getTypeOut(perfEvent[type[2]])
                              )}
                            </span>
                          )}
                        </div>
                      </>
                    );
                  }

                  if (event[type[1]] && type[0] !== "exit") {
                    let date = event[type[1]]["event_time"];
                    let startOfday = m().tz("Europe/Paris").format("YYYY-MM-DD 00:00:00");

                    return (
                      <div
                        data-tip
                        data-for={type[0] === "I" ? `I-${item.iep}` : null}
                        className="two-div"
                      >
                        <div>{moment(date).format("HH:mm")}</div>
                        <div style={{ marginBottom: "-13px" }}>
                          <span className={`span-1 span-${type[0]}`}>
                            {type[0]}
                            {type[0] === "I" && (
                              <ReactTooltip id={`I-${item.iep}`} effect="solid">
                                <span>{event[type[1]]["event_type"]}</span>
                              </ReactTooltip>
                            )}
                          </span>
                          {index < 7 && <span className="span-prog-dashed"></span>}
                          {orderT[type[0]] < prog && <span className="span-prog"></span>}
                        </div>
                        <br></br> <br />
                        {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                          <span
                            style={{
                              color: "#00b0f0",
                              marginTop: "10px !important",
                            }}
                          >
                            {moment(perfEvent[type[2]]).format("HH:mm")}
                          </span>
                        )}
                      </div>
                    );
                  }
                  return (
                    <div className="two-div">
                      <div></div>
                      <div>
                        <span className={`span-1`}></span>
                        {index < 7 && <span className="span-prog-dashed-second"></span>}
                        {orderT[type[0]] < prog && <span className="span-prog"></span>}
                        <br></br> <br />
                        {perfEvent[type[2]] && perfEvent[type[2]] !== "" && (
                          <span
                            style={{
                              color: "#00b0f0",
                              marginTop: "10px !important",
                            }}
                          >
                            {moment(perfEvent[type[2]]).format("HH:mm")}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{ width: "10%", textAlign: "right", marginTop: "-15px" }}
                className="font-h5 mr-font"
              >
                <h5>{getTime(item.SeqPredPerf.timing_score).toFixed(1)} (h)</h5>
                <h5 style={{ marginTop: "14px" }}>{item.SeqPredPerf.seq_score * 100}% (s)</h5>
                <h5 style={{ marginTop: "14px" }}>{item.SeqPredPerf.er_out_score * 100}% (t)</h5>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const getTime = (time) => {
    if (!time) return 0;
    let hour = 0;
    hour +=
      (time.days ? time.days * 24 : 0) +
      (time.hours ? time.hours : 0) +
      (time.minutes ? time.minutes / 60 : 0) +
      (time.seconds ? time.seconds / 3600 : 0);
    return hour;
  };

  const getPErfEvents = (data) => {
    let val = {};

    types.map((t) => {
      if (t[2] && t[2] !== "") {
        val[t[2]] = data[t[2]];
      }
    });

    return val;
  };

  const getEvents = (events) => {
    let data = events.filter(
      (item) =>
        item.event_type.includes("RX standard") ||
        item.event_type.includes("Fin de PEC Médicale") ||
        item.event_type.includes("Arrivée") ||
        item.event_type.includes("uf") ||
        item.event_type.includes("avisdemande") ||
        item.event_type.includes("out") ||
        item.event_type.includes("Triage") ||
        item.event_type.includes("Imagerie autre") ||
        item.event_type.includes("Examen médical") ||
        item.event_type.includes("Laboratoire"),
    );

    let prog = 0;
    let test = false;

    const arrayToObject = (data) =>
      data.reduce((obj, item) => {
        if (item.event_type.includes("out") && !test) {
          obj["out"] = { ...item };

          if (item.confidence === 1) prog = orderType["out"];
        } else if (item.event_type.includes("uf")) {
          obj["out"] = { ...item };
          let split = item.event_type.split("_");
          obj["type_out"] = split[1] === "other" ? "UF Autre" : `UF ${split[1]}`;
          test = true;
        } else if (item.event_type.includes("Arrivée")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Arrivée"] > prog) prog = orderType["Arrivée"];
        } else if (item.event_type.includes("Triage")) {
          obj[item.event_type] = item;

          if (item.confidence === "1" && orderType["Triage"] > prog) prog = orderType["Triage"];
        } else if (item.event_type.includes("Examen médical")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Examen médical"] > prog)
            prog = orderType["Examen médical"];
          if (item.confidence < 1 && orderType["Examen médical"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("Laboratoire")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Laboratoire"] > prog)
            prog = orderType["Laboratoire"];
          if (item.confidence < 1 && orderType["Laboratoire"] === prog) prog = prog - 1;
        } else if (
          item.event_type.includes("RX standard") ||
          item.event_type.includes("Imagerie autre")
        ) {
          obj["Imagerie"] = item;
          if (item.confidence === 1 && orderType["Imagerie"] > prog) prog = orderType["Imagerie"];
          if (item.confidence < 1 && orderType["Imagerie"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("Fin de PEC Médicale")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Fin de PEC Médicale"] > prog)
            prog = orderType["Fin de PEC Médicale"];
          if (item.confidence < 1 && orderType["Fin de PEC Médicale"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("avisdemande")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["avisdemande"] > prog)
            prog = orderType["avisdemande"];
          if (item.confidence < 1 && orderType["avisdemande"] === prog) prog = prog - 1;
        }

        return obj;
      }, {});

    let result = arrayToObject(data);

    return { event: result, events: data, prog: prog };
  };

  const renderIndicators = () => {
    if (!indicator) return;

    return (
      <div className="row row container-titles">
        <div style={{ textAlign: "center" }}>
          <h3>{t("relevance discharge time (h)")}</h3>
          <h1>{indicator && Math.round(indicator["h"])}</h1>
        </div>
        <div style={{ textAlign: "center" }}>
          <h3>{t("relevance sequence (s)")}</h3>
          <h1>{indicator && Math.round(indicator["s"] * 100)}%</h1>
        </div>
        <div style={{ textAlign: "center" }}>
          <h3>{t("relevance type of discharge (t)")}</h3>
          <h1>{indicator && Math.round(indicator["t"] * 100)}%</h1>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderIndicators()}

      <div
        className="row w-100"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        {dataPerformance && pages && (
          <p className="col-md-3">
            {Capitalize(t("results"))} : {dataPerformance.length} , {Capitalize(t("pages"))} :{" "}
            {pages}{" "}
          </p>
        )}
        <div className="col-md-9 d-flex justify-content-center">
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page === 0}
            style={{ width: "130px" }}
            variant="outline-success"
          >
            {`<< `}
            {Capitalize(t("previous"))}
          </Button>

          <Button disabled style={{ marginLeft: "2.5%" }} variant="primary">
            {page + 1}
          </Button>

          <Button
            onClick={() => setPage(page + 1)}
            disabled={page === pages - 1}
            style={{ marginLeft: "2.5%", width: "130px" }}
            variant="outline-success"
          >
            {Capitalize(t("next"))}
            {` >>`}
          </Button>
        </div>
      </div>
      <hr />
      <div className="w-100 table-g table-scroll w-fixe-perf" style={{ marginTop: "30px" }}>
        {renderHeader()}
        <div className="w-100 w-fixe-perf" style={{ height: "56vh" }}>
          {dataPerformance
            ? window.innerWidth <= 991.98
              ? renderTableResponsive()
              : renderTable()
            : ""}
        </div>
      </div>
    </>
  );
};

export default React.memo(Table);
