import React, { useState, useEffect } from "react";
import "./index.css";
import { API } from "../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Capitalize } from "../../utils/functions";

const ListRoles = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [globalRolesData, setGlobalRolesData] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [name, setName] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    axios({
      method: "get",
      url: `${API.ENDPOINT + API.USER_ALLROLES}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalRolesData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const actionDelete = (id, role) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this role?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteRole(id, role),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteRole = (id, role) => {
    setLoad(false);
    axios
      .post(`${API.ENDPOINT + API.USER_DELETEROLE}`, role, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let dataRole = globalRolesData.filter((role) => role.id != id);

        setShowMessage(true);
        setMessage({ edit: "the role was successfully deleted." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalRolesData(dataRole);
        setLoad(true);
      })
      .catch((e) => {
        //    console.log(e)
      });
  };
  const onSave = ({ id, name }) => {
    let token = props.activeUSer.user.token;
    let role = {
      id: id,
      name: name,
    };
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.USER_EDITROLE}`,
      data: role,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        let dataRole = {};
        dataRole = globalRolesData.map((roleData) => (roleData.id == id ? role : roleData));
        setShowMessage(true);
        setMessage({ edit: "the role has been changed successfully" });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalRolesData(dataRole);
        onCancel();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const onEdit = (role) => {
    setInEditMode({
      status: true,
      rowKey: role.id,
    });
    setName(role.name);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setName("");
    setIsDisable(false);
  };

  const renderList = () => {
    if (!globalRolesData) return null;

    return globalRolesData.map((role, i) => (
      <tr key={i}>
        <td> {role.id}</td>
        <td>
          {" "}
          {inEditMode.status && inEditMode.rowKey === role.id ? (
            <input
              value={name}
              required
              className="form-control"
              onChange={(event) => setName(event.target.value)}
            />
          ) : (
            role.name.toUpperCase()
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === role.id ? (
            <>
              <a
                style={{ margin: 5 }}
                className={"icon-size"}
                onClick={() => onSave({ id: role.id, name: name })}
              >
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEdit(role)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => actionDelete(role.id, role)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };
  return (
    <div className="row" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title-translation">{t("list of roles")}</h1>
        <div className="row">
          {
            <div
              className="col-12 d-flex justify-content-between flex-col"
              style={{ textAlign: "end", marginTop: 20 }}
            >
              <a
                href="/users/newRole"
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("new role")}
              </a>
            </div>
          }
        </div>
        <div className="message" style={{ marginTop: 20, marginBottom: 20 }}>
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}{" "}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          {load && globalRolesData ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{t("#")}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("name role"))}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListRoles);
