import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "./index.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const ChartLine = (props) => {
  const { t, i18n } = useTranslation([`${props.traduction}`]);

  const [data, setData] = useState(null);
  const [annotations, setAnnotation] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "relativePrecision",
    key: "relative precision",
    label: t("relative precision"),
  });
  const [options, setOptions] = useState([
    {
      value: "relativePrecision",
      key: "relative precision",
      label: t("relative precision"),
    },
    { value: "r2Score", key: "r2 score", label: t("r2 score") },
    {
      value: "absoluteError",
      key: "absolute error",
      label: t("absolute error"),
    },
  ]);

  const labels = [
    "00h",
    "01h",
    "02h",
    "03h",
    "04h",
    "05h",
    "06h",
    "07h",
    "08h",
    "09h",
    "10h",
    "11h",
    "12h",
    "13h",
    "14h",
    "15h",
    "16h",
    "17h",
    "18h",
    "19h",
    "20h",
    "21h",
    "22h",
    "23h",
  ];

  let myRef = React.createRef();

  useEffect(() => {
    if (update === true) setUpdate(false);
  }, [update]);

  useEffect(() => {
    let options = [
      {
        value: "relativePrecision",
        key: "relative precision",
        label: t("relative precision"),
      },
      { value: "r2Score", key: "r2 score", label: t("r2 score") },
      {
        value: "absoluteError",
        key: "absolute error",
        label: t("absolute error"),
      },
    ];
    setOptions(options);
    let option = {
      value: selectedOption.value,
      label: t(selectedOption.key),
      key: selectedOption.key,
    };
    setSelectedOption(option);
  }, [i18n.language]);

  useEffect(() => {
    let color = props.J0 ? "#ccc9c9" : "#909090";
    if ((props.type && props.type === "Stress") || props.type === "Patients")
      setData({
        labels: props.data ? props.data["dateTimePrediction"] : labels,
        datasets: [
          {
            label: t(`max`),
            data: props.data && props.data["predictionUpperMax"],
            borderColor: "rgb(0,65,120)",
            borderWidth: 2,
            fill: false,
            type: "line",
          },
          {
            label: t("predictions"),
            data: props.data && props.data["predictionMax"],
            borderColor: "#3aa8fb",
            strokeColor: "grey",
            borderWidth: 2,
            fill: false,
            type: "line",
            borderDash: [5, 5],
          },
          {
            label: t(`min`),
            data: props.data && props.data["predictionLowerMax"],
            backgroundColor: "rgb(0,65,120)",
            fill: false,
            type: "line",
            borderColor: "#3aa8fb",
            fillColor: "#3aa8fb",
            strokeColor: "#3aa8fb",
            borderWidth: 3,
          },
          {
            label: t("real"),
            data: props.data && props.data["measuredValuesMax"],
            borderColor: color,
            borderWidth: 2,
            fillColor: color,
            backgroundColor: color,
            fill: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
            pointBackgroundColor: "blue",
            pointBorderColor: "blue",
          },
          {
            label: t("reference"),
            data: props.data && props.data["baselineValuesMax"],
            borderColor: "black",
            borderWidth: 2,
            fillColor: color,
            backgroundColor: "black",
            fill: true,
            hidden: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
            pointBackgroundColor: "blue",
            pointBorderColor: "blue",
          },
          {
            label: t("ideal"),
            data: props.data && props.data["bestForecastValuesMax"],
            borderColor: "green",
            borderWidth: 2,
            fillColor: color,
            backgroundColor: "green",
            fill: false,
            hidden: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
          },
        ],
      });
    else {
      setData({
        labels: props.data ? props.data["dateTimePrediction"] : labels,
        datasets: [
          {
            label: t(`max`),
            data: props.data && props.data["predictionUpperSum"],
            borderColor: "rgb(0,65,120)",
            borderWidth: 2,
            fill: false,
            type: "line",
          },
          {
            label: t("predictions"),
            data: props.data && props.data["predictionSum"],
            borderColor: "#3aa8fb",
            strokeColor: "grey",
            borderWidth: 2,
            fill: false,
            type: "line",
            borderDash: [5, 5],
          },
          {
            label: t(`min`),
            data: props.data && props.data["predictionLowerSum"],
            backgroundColor: "rgb(0,65,120)",
            fill: false,
            type: "line",
            borderColor: "#3aa8fb",
            fillColor: "#3aa8fb",
            strokeColor: "#3aa8fb",
            borderWidth: 3,
          },
          {
            label: t("real"),
            data: props.data && props.data["measuredValuesSum"],
            borderColor: color,
            borderWidth: 2,
            fillColor: color,
            backgroundColor: color,
            fill: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
            pointBackgroundColor: "blue",
            pointBorderColor: "blue",
          },
          {
            label: t("reference"),
            data: props.data && props.data["baselineValuesSum"],
            borderColor: "black",
            borderWidth: 2,
            fillColor: color,
            backgroundColor: "black",
            fill: false,
            hidden: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
          },
          {
            label: t("ideal"),
            data: props.data && props.data["bestForecastValuesSum"],
            borderColor: "green",
            borderWidth: 2,
            fillColor: color,
            backgroundColor: "green",
            fill: false,
            hidden: true,
            type: "line",
            lineTension: 0,
            pointRadius: 0,
          },
        ],
      });
    }

    setUpdate(true);
  }, [props.data, props.language]);

  const customRadius = (context) => {
    let index = context.dataIndex;
    let max = context.chart.tooltip._data.datasets[0]["data"][index];
    let min = context.chart.tooltip._data.datasets[2]["data"][index];
    let reel = context.chart.tooltip._data.datasets[3]["data"][index];

    if (reel === null || max === null || min === null) return 0;
    return reel <= max && reel >= min ? 2 : 0;
  };

  const renderChart = () => {
    let size,
      sizeLabel = 15;
    window.innerWidth <= 767 ? (size = 16) : (size = props.titleSize);

    return (
      <Line
        redraw={update}
        ref={(reference) => (myRef = reference)}
        data={data}
        height={100}
        options={{
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          title: {
            display: true,
            text: "",
            fontSize: "",
          },
          legend: {
            display: true,
            position: "top",
            labels: {
              usePointStyle: true,
              fontSize: window.innerWidth >= 2400 ? sizeLabel : 12,
            },
          },

          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: props.label
              ? [
                  {
                    barThickness: 15,
                    ticks: {
                      callback: function (value, index, values) {
                        return value;
                      },
                    },
                  },
                ]
              : [
                  {
                    barThickness: 15,
                  },
                ],
          },
          tooltips: {
            events: ["click", "mouseout"],
            mode: "index",
            intersect: false,
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === t("real")) {
                  return {
                    borderColor: "#909090",
                    backgroundColor: "#909090",
                  };
                } else {
                  return {
                    borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                    backgroundColor:
                      chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                  };
                }
              },
            },
          },

          elements: {
            point: {
              radius: 0,
            },
          },
        }}
      />
    );
  };

  return (
    <div
      className={data && props.box ? "row row-chart-box" : "row row-chart"}
      style={!data ? { justifyContent: "center", minHeight: 150, alignItems: "flex-end" } : {}}
    >
      <div className="col-12">
        <h4 colSpan="2" className="title-type" style={{ fontSize: "2.6vh" }}>
          {t(props.title)}
        </h4>
        {props.rateData && props.type && (
          <table className="table table-bordered perf-chart">
            <thead>
              {window.innerWidth <= 991 ? (
                <tr>
                  <th colSpan="3" style={{ width: "33.33%" }}>
                    <div className="col-12 text-center select-table-perf">
                      <Select
                        value={selectedOption}
                        className="selectJ1 form-select"
                        isSearchable={false}
                        onChange={(selectedOption) => setSelectedOption(selectedOption)}
                        options={options}
                      />
                    </div>
                  </th>
                </tr>
              ) : (
                <tr>
                  <th style={{ width: "33.33%" }}></th>
                  <th style={{ width: "33.33%" }}>
                    <div className="col-12 text-center select-table-perf">
                      <Select
                        value={selectedOption}
                        className="selectJ1 form-select"
                        isSearchable={false}
                        onChange={(selectedOption) => setSelectedOption(selectedOption)}
                        options={options}
                      />
                    </div>
                  </th>
                  <th style={{ width: "33.33%" }}></th>
                </tr>
              )}
            </thead>

            <tbody>
              <tr>
                <td className="text-center text-table-perf">{t("prediction")}</td>
                <td className="text-center text-table-perf">{t("ideal")}</td>
                <td className="text-center text-table-perf2">{t("performance")}</td>
              </tr>
              <tr>
                <td className="text-center text-table-perf">
                  {props.rateData[selectedOption.value].prediction}
                  {selectedOption.value === "absoluteError" ? "" : "%"}
                </td>
                <td className="text-center text-table-perf">
                  {props.rateData[selectedOption.value].ideal}{" "}
                  {selectedOption.value === "absoluteError" ? "" : "%"}
                </td>
                <td className="text-center text-table-perf2">
                  {props.rateData[selectedOption.value].prediction !== 0 &&
                  props.rateData[selectedOption.value].ideal !== 0
                    ? selectedOption.value === "absoluteError"
                      ? Math.round(
                          (props.rateData[selectedOption.value].ideal /
                            props.rateData[selectedOption.value].prediction) *
                            100,
                        )
                      : Math.round(
                          (props.rateData[selectedOption.value].prediction /
                            props.rateData[selectedOption.value].ideal) *
                            100,
                        )
                    : 0}
                  {"%"}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div className="col-12" style={{ height: props.height, marginBottom: 40 }}>
        {" "}
        {data && renderChart()}
      </div>
    </div>
  );
};

export default ChartLine;
