import React, { useState, useEffect } from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { API } from "../../utils/api";
import { Capitalize } from "../../utils/functions";
import * as Permission from "../../utils/permissions";

const EditGroup = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };
  const handleSelect = (e) => {
    changeLanguage(e.target.value);
  };

  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [errorGlobal, setErrorGlobal] = useState(null);
  const [role, setRole] = useState([]);
  const [showError, setShowError] = useState(true);
  const [load, setLoad] = useState(false);
  const [roles, setRoles] = useState(null);
  const [group, setGroup] = useState(null);
  const [idGroup, setIDGroup] = useState(useParams().group);

  useEffect(() => {
    axios
      .get(API.ENDPOINT + API.USER_ALLROLES, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((response) => {
        loadingRoles(response.data);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);

  useEffect(() => {
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.USER_GETGROUP}`,
      data: { index: idGroup },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((response) => {
        setGroup(response.data);
        let roles = [];
        setName(response.data.name);
        response.data.Roles.map((r) => {
          roles = [...roles, `${r.id}`];
        });

        setRole(roles);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);
  const loadingRoles = (roles) => {
    let listRoles = [];
    listRoles = roles.filter((role) => role.name !== "admin" && role.name !== "super admin");
    listRoles = listRoles.sort((a, b) => a.name.localeCompare(b.name));
    console.log(listRoles);
    setRoles(listRoles);
  };

  const UpdateGroup = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    let errors = {};
    let test = false;
    if (test) {
      setError(errors);
      setLoad(false);
      setShowError(true);
      return;
    }

    let newGroup = {
      index: group.index,
      name: name === "" ? group.name : name,
    };

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.USER_EDITGROUP}`,
      data: { role, newGroup },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((response) => {
        history.push("/users/groups");
      })

      .catch((error) => {
        // console.log('erorrr',error)
        if (error.response) {
          if (error.response.status === "401") {
            setError(error.response.data);
          }

          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });

            setError(errors);
          }
          setErrorGlobal(t("we are sorry, but an error has occurred"));
        }
        setLoad(false);
        setShowError(true);
      });
  };

  const handleCheckBox = (e) => {
    let rolesId;

    if (e.target.checked) {
      rolesId = [...role, e.target.id];
    } else {
      rolesId = role.filter((role) => role !== e.target.id);
    }
    if (rolesId.length == 0) {
      let roleUSer = [...roles].filter((role) => role.name.toUpperCase() == "HOME PAGE");

      rolesId = [`${roleUSer[0].id}`];
    }

    setRole(rolesId);
  };

  return (
    <div className="row mrg-user" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("edit a group")}</h1>

        <div className="row">
          <div className="col-10 offset-1">
            <hr></hr>
            <form onSubmit={UpdateGroup} className="form-user-new">
              <div style={{ textAlign: "center" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => <div className="errors">{t(err)} </div>)}
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  className="fadeIn form-control"
                  name="_name"
                  placeholder={t("name")}
                />
              </div>
              <div style={{ width: "85%", marginLeft: "10%" }}>
                <p style={{ fontSize: 20, textAlign: "center" }}>{t("modules")}:</p>
                <div className="row">
                  {roles &&
                    roles.map((r) => {
                      if (Permission.isSuperAdmin(props.roles)) {
                        return (
                          <div className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox">
                            <input
                              onChange={handleCheckBox}
                              type="checkbox"
                              checked={role.includes(`${r.id}`) ? true : false}
                              className="custom-control-input"
                              id={r.id}
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className="custom-control-label"
                              htmlFor={r.id}
                            >
                              {r.name.toLowerCase() === "admin" ||
                              r.name.toLowerCase() === "super admin"
                                ? t(r.name).toUpperCase()
                                : t("screen " + r.name).toUpperCase()}
                            </label>
                          </div>
                        );
                      } else if (Permission.isAdmin(props.roles)) {
                        if (r.name.toUpperCase() != "SUPER ADMIN")
                          return (
                            <div className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox">
                              <input
                                onChange={handleCheckBox}
                                type="checkbox"
                                checked={role.includes(`${r.id}`) ? true : false}
                                className="custom-control-input"
                                id={r.id}
                              />
                              <label
                                style={{ fontSize: 16 }}
                                className="custom-control-label"
                                htmlFor={r.id}
                              >
                                {r.name.toLowerCase() === "admin" ||
                                r.name.toLowerCase() === "super admin"
                                  ? t(r.name).toUpperCase()
                                  : t("screen " + r.name).toUpperCase()}
                              </label>
                            </div>
                          );
                      }
                    })}
                </div>
              </div>

              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  href="/users/groups"
                >
                  {Capitalize(t("back"))}
                </a>

                <input
                  type="submit"
                  disabled={name !== "" ? false : true}
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={Capitalize(t("save"))}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(EditGroup);
