import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import Moment from "react-moment";
import Line from "../../../components/chartV2/lineBar";
import Pie from "../../../components/chartV2/pie";
import StackedBar from "../../../components/chartV2/barStacked";
import Clock from "react-live-clock";
import { useTranslation } from "react-i18next";
import { calculateDeviation, percentageToHslGreenToBlack } from "../../../utils/functions";
import Circle from "../../../components/cirle-bar";
import ReactTooltip from "react-tooltip";
import m from "moment";
import { Capitalize } from "../../../utils/functions";
import axios from "axios";
import { API } from "../../../utils/api";
import { connect } from "react-redux";

import "chartjs-plugin-datalabels";

Moment.globalTimezone = "Europe/Paris";

const ScreenEmergencyPred7 = (props) => {
  const [hour, setHour] = useState(m().tz("Europe/Paris").format("HH"));
  const { t, i18n } = useTranslation("emergency");
  const [dayJ, setDayJ] = useState("J0");
  const [dataColor, setDataColor] = useState({
    Admissions: null,
    Hospitalisations: null,
  });
  const [numRedColor, setNumRedColor] = useState({
    Admissions: 0,
    Hospitalisations: 0,
  });

  const [isWeek, setIsWeek] = useState(0);
  const [valueAdmission, setValueAdmission] = useState(0);
  const [valueHospitalisation, setvalueHospitalisation] = useState(0);
  const [dateWeek, setDateWeek] = useState(null);
  const [stackBarData, setStackBarData] = useState(null);
  const [isBar, setBar] = useState(true);
  const [isBarAdmision, setBarAdmision] = useState(true);
  const data = {};

  // bed prediction
  const [isBedPredictionLoading, setIsBedPredictionLoading] = useState(true);
  const bedPredictionData=useRef([]);
  const [enableBedPrediction, setEnableBedPrediction] = useState(false);

  const days = ["J0", "J1", "J2", "J3", "J4"];

  let types = [
    ["Admissions", t("admission")],
    ["Discharges", t("discharges")],
    ["Hospitalisations", t("hospitalizations")],
    ["Seniors", t("seniors")],
    ["Patients", t("patients")],
  ];
  const handleHour = (date) => {
    if (date.split(":")[0] !== hour) setHour(date.split(":")[0]);
  };

  /*
  function requestData() {
    setIsLoading(true);
    // request
    axios
      .get(`${API.ENDPOINT + API.PARAMETERBED_ALLBEDDISPATCHBEDMANAGEMENT}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        setData(res.data.length);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
}*/

  useEffect(() => {
    let DataColorAdmissions = props.data["PredictionJ0ToJ4_V3"]["ColorBound"].filter(
      (d) => d.variableName == "Admissions",
    );
    let colorRedAdmissions =
      DataColorAdmissions[0].daySumUpperations -
      (DataColorAdmissions[0].daySumUpperations - DataColorAdmissions[0].daySumLower) * 0.2;

    let DataColorHospitalisation = props.data["PredictionJ0ToJ4_V3"]["ColorBound"].filter(
      (d) => d.variableName == "Hospitalisations",
    );
    let colorRedHospitalisation =
      DataColorHospitalisation[0].daySumUpperations -
      (DataColorHospitalisation[0].daySumUpperations - DataColorHospitalisation[0].daySumLower) *
        0.2;

    setDataColor({
      Admissions: DataColorAdmissions,
      Hospitalisations: DataColorHospitalisation,
    });
    setNumRedColor({
      Admissions: Math.round(colorRedAdmissions),
      Hospitalisations: Math.round(colorRedHospitalisation),
    });

    axios
      .get(`${API.ENDPOINT + API.BED_PREDICTION_MIDNIGHT}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        bedPredictionData.current=res.data;
        setIsBedPredictionLoading(false);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });


  }, []);
  useEffect(() => {
    let dataStackBar = {};
    const color = ["#823d09", "#c65a0f", "#f4b47d", "#fdc9a4", "#fde4d7"];
    Object.values(props.data["PredictionJ0ToJ4_V3"]["currentAttention"]).map((type, index) => {
      dataStackBar[type["targetName"]] = {};

      for (let i = 1; i <= 5; i++) {
        dataStackBar[type["targetName"]][type["topFeature" + i]] = {
          label: t(type["topFeature" + i].toLowerCase()),
          backgroundColor: color[i - 1],
          borderWidth: 2,
          borderColor: "white",
          data: [type["topScore" + i].toFixed(2) * 100],
        };
      }
    });
    setStackBarData(dataStackBar);
  }, []);


  useEffect(() => {
    let value = 0;
    let admissionPredSum = 0;
    let hospPredSum = 0;

    Object.entries(props.data["PredictionJ0ToJ4_V3"]).map((day) => {
      if (day[0] == "J0" || day[0] == "J1" || day[0] == "J2" || day[0] == "J3" || day[0] == "J4") {
        if (day[1].dayOftheWeek == "Saturday") {
          value++;
          let pred = day[0] == "J0" ? "currentDaySum" : "predictionSum";
          admissionPredSum = Math.round(
            day[1]["Admissions"][pred][day[1]["Admissions"][pred].length - 1],
          );
          hospPredSum = Math.round(
            day[1]["Hospitalisations"][pred][day[1]["Hospitalisations"][pred].length - 1],
          );
          setDateWeek(
            day[1]["Admissions"]["dateTimePrediction"][
              day[1]["Admissions"]["dateTimePrediction"].length - 1
            ].split("T")[0],
          );
        }
        if (day[1].dayOftheWeek == "Sunday") {
          value++;
          admissionPredSum =
            admissionPredSum +
            Math.round(
              day[1]["Admissions"]["predictionSum"][
                day[1]["Admissions"]["predictionSum"].length - 1
              ],
            );
          hospPredSum =
            hospPredSum +
            Math.round(
              day[1]["Hospitalisations"]["predictionSum"][
                day[1]["Hospitalisations"]["predictionSum"].length - 1
              ],
            );
        }
      }
    });
    setIsWeek(value);
    setValueAdmission(admissionPredSum);
    setvalueHospitalisation(hospPredSum);
  }, [props.data["PredictionJ0ToJ4_V3"]]);

  function getColorForBedPRediction(value,bed){
    const color=["#00E23C","#FFC700","#E60021"];
    let n = value/bed;
    return (n>0.75)?color[2]:(n>0.25)?color[1]:color[0];
  }

  const renderCircleData = () => {
    let width = window.innerWidth >= "2400" ? window.innerWidth * 0.052 : "100px";

    return days.map((day, index) => {
      let predDateClac = props.data["PredictionJ0ToJ4_V3"][day]["Admissions"]["dateTimePrediction"];
      let PredDate = (predDateClac.length==0)?null:(predDateClac[predDateClac.length - 1].split("T")[0]);

      let valueOfDay = Math.round(
        props.data["PredictionJ0ToJ4_V3"][day]["Admissions"]["predictionSum"][0],
      );
      let confidence = parseFloat(
        props.data["PredictionJ0ToJ4_V3"][day]["Admissions"]["meanConfidenceIndex"][
          props.data["PredictionJ0ToJ4_V3"][day]["Admissions"]["meanConfidenceIndex"].length - 1
        ],
      ).toFixed(2);
      
      let isBandDay=false;


      return (
        <div key={index}>
          <div
            className={`perCircInner circleActive`}
            data-tip
            data-for={`confidence${day}`}
            style={{ width }}
          >
            <Circle
              color={percentageToHslGreenToBlack(
                valueOfDay,
                dataColor["Admissions"] && dataColor["Admissions"][0].daySumLower,
                dataColor["Admissions"] && dataColor["Admissions"][0].daySumUpperations,
              )}
              textColor={valueOfDay <= numRedColor["Admissions"] ? "black" : "white"}
              percentage={confidence * 100}
              text={valueOfDay}
            />
            {enableBedPrediction?
            <div style={(isBandDay || isBedPredictionLoading)?null:{backgroundColor:getColorForBedPRediction(valueOfDay,bedPredictionData.current[index].bed_at_midnight)}} className={"bedPrediction" + (isBandDay?" bedPrediction-disabel":((isBedPredictionLoading?" bedPrediction-loading":"")))}>
              {(isBandDay || isBedPredictionLoading)?"":bedPredictionData.current[index].bed_at_midnight}
            </div>
            :null}
            <div className="dayDate">
              {" "}
              <Moment locale={i18n && i18n.language} format="dddd" date={PredDate} />
            </div>
            <div className="dayDate">
              {" "}
              <Moment format="DD.MM" date={PredDate} />
            </div>
          </div>
          <ReactTooltip id={`confidence${day}`} effect="solid">
            <span>{Math.round(confidence * 100)}%</span>
          </ReactTooltip>
        </div>
      );
    });
  };

  const renderCircleData2 = () => {
    let width = window.innerWidth >= "2400" ? window.innerWidth * 0.052 : "100px";

    return (
      <>
        <div>
          <div className={`perCircInner circleActive`} style={{ width }}>
            <Circle
              week={true}
              color={percentageToHslGreenToBlack(
                valueAdmission,
                dataColor["Admissions"] && dataColor["Admissions"][0].daySumLower * 2,
                dataColor["Admissions"] && dataColor["Admissions"][0].daySumUpperations * 2,
              )}
              textColor={valueAdmission >= numRedColor["Admissions"] * 2 ? "white" : ""}
              percentage={0}
              text={valueAdmission}
            />
            <div className="dayDate">{t("admission")}</div>
          </div>
        </div>
        <div>
          <div className={`perCircInner circleActive`} style={{ width }}>
            <Circle
              week={true}
              color={percentageToHslGreenToBlack(
                valueHospitalisation,
                dataColor["Hospitalisations"] && dataColor["Hospitalisations"][0].daySumLower * 2,
                dataColor["Hospitalisations"] &&
                  dataColor["Hospitalisations"][0].daySumUpperations * 2,
              )}
              textColor={valueHospitalisation >= numRedColor["Hospitalisations"] * 2 ? "white" : ""}
              percentage={0}
              text={valueHospitalisation}
            />
            <div className="dayDate">{t("hospitalizations")}</div>
          </div>
        </div>
      </>
    );
  };

  const renderTrendValues = () => {
    let dataByDays = props.data["PredictionJ0ToJ4_V3"][dayJ];
    let dataLatestReal = props.data["PredictionJ0ToJ4_V3"]["LatestReal"];

    return types.map((type, index) => (
      <div key={index} className="row row-minus-s1 col-12 col-md-6 col-lg-12">
        <div className="index-label  row">
          {Capitalize(type[1])}{" "}
          {m(
            dataByDays[type[0]]["dateTimePrediction"][
              dataByDays[type[0]]["dateTimePrediction"].length - 1
            ],
          )
            .tz("Europe/Paris")
            .format("DD.MM")}
        </div>
        <div className="index-value w-100 row ">
          <div className="w-100 text-center j-plus real-value">
            {type[0] == "Patients" ? (
              <>
                <span style={{ color: "gray" }}>
                  {Math.max(dataByDays[type[0]]["measuredValuesMax"]) >
                  dataLatestReal[type[0]][dataLatestReal[type[0]].length - 1]
                    ? Math.max(dataByDays[type[0]]["measuredValuesMax"])
                    : dataLatestReal[type[0]][dataLatestReal[type[0]].length - 1]}
                </span>
                <span style={{ color: "gray" }}> / </span>
                <span>
                  {Math.round(
                    dataByDays[type[0]]["predictionMax"][
                      dataByDays[type[0]]["predictionMax"].length - 1
                    ],
                  )}
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "gray" }}>
                  {Math.round(
                    dataByDays[type[0]]["realSinceMidnight"][
                      dataByDays[type[0]]["realSinceMidnight"].length - 1
                    ],
                  ) + dataLatestReal[type[0]][dataLatestReal[type[0]].length - 1]}
                </span>
                <span style={{ color: "gray" }}> / </span>
                <span>
                  {Math.round(
                    dataByDays[type[0]]["currentDaySum"][
                      dataByDays[type[0]]["currentDaySum"].length - 1
                    ],
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const renderPageResponsive = () => {
    let dataByDay = props.data["PredictionJ0ToJ4_V3"][dayJ];
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.8 : "300";

    return (
      <>
        <div className="col-12">
          <div className="w-100 title-pred" style={{ marginTop: 40 }}>
            <h4 style={{ textAlign: "center", marginTop: 20 }}>
              {Capitalize(t("predictions of the"))}
              {" " + m().tz("Europe/Paris").format("DD.MM")}
            </h4>
          </div>
          <fieldset className="fieldset-b" style={{ marginTop: 35, height: "auto" }}>
            <legend className="title-leg">{Capitalize(t("trend"))}</legend>
            <div className="row s-2 " id="j-minus-indexes" style={{ marginTop: 35 }}>
              {renderTrendValues()}
            </div>
          </fieldset>
        </div>

        <div className="col-12" style={{ height: 380, marginTop: 60 }}>
          <div className="">
            <div className="col-11" style={{ height: height + "px", display: "inline-block" }}>
              <Line
                traduction="emergency"
                timeSeries={true}
                box={false}
                language={i18n.language}
                annotations={true}
                hour={hour}
                data={dataByDay["Admissions"]}
                hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Admissions"]}
                latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Admissions"]}
                titleSize={18}
                title={
                  t("admission") +
                  " " +
                  m(
                    dataByDay["Admissions"]["dateTimePrediction"][
                      dataByDay["Admissions"]["dateTimePrediction"].length - 1
                    ],
                  )
                    .tz("Europe/Paris")
                    .format("DD.MM")
                }
              />
            </div>
            <div
              className="col-2 padding-0"
              style={{
                display: "inline-block",
                height: height * 0.8,
                marginLeft: "-10%",
              }}
            >
              {stackBarData && (
                <StackedBar traduction="emergency" data={stackBarData["Admissions"]} />
              )}
            </div>
          </div>
          <div className="" style={{ marginTop: "20px" }}>
            {isBar ? (
              <div>
                <button className="btn btn-primary position-btn" onClick={() => setBar(false)}>
                  {t("pie")}
                </button>
                <div className="col-11" style={{ height: height + "px", display: "inline-block" }}>
                  <Line
                    traduction="emergency"
                    timeSeries={true}
                    box={false}
                    language={i18n.language}
                    annotations={true}
                    hour={hour}
                    data={dataByDay["Hospitalisations"]}
                    hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Hospitalisations"]}
                    latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Hospitalisations"]}
                    titleSize={18}
                    title={
                      t("hospitalisation") +
                      " " +
                      m(
                        dataByDay["Hospitalisations"]["dateTimePrediction"][
                          dataByDay["Hospitalisations"]["dateTimePrediction"].length - 1
                        ],
                      )
                        .tz("Europe/Paris")
                        .format("DD.MM")
                    }
                  />
                </div>
                <div
                  className="col-2 padding-0"
                  style={{
                    display: "inline-block",
                    height: height * 0.8,
                    marginLeft: "-10%",
                  }}
                >
                  {stackBarData && (
                    <StackedBar traduction="emergency" data={stackBarData["Hospitalisations"]} />
                  )}
                </div>
              </div>
            ) : (
              <div>
                <button className="btn btn-primary position-btn" onClick={() => setBar(true)}>
                  {t("bar")}
                </button>
                <div>
                  <Pie
                    width="350px"
                    height="250px"
                    traduction="emergency"
                    titleSize={16}
                    data={
                      props.data["PredictionJ0ToJ4_V3"]["GroupsPrediction"].length > 0 &&
                      props.data["PredictionJ0ToJ4_V3"]["GroupsPrediction"][0]
                    }
                    title={t("hospitalisations")}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-12" style={{ height: 380, marginTop: 20 }}>
            <div className="">
              <div className="col-11" style={{ height: height + "px", display: "inline-block" }}>
                <Line
                  traduction="emergency"
                  timeSeries={true}
                  box={false}
                  language={i18n.language}
                  annotations={true}
                  hour={hour}
                  data={dataByDay["Patients"]}
                  hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Patients"]}
                  latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Patients"]}
                  titleSize={18}
                  title={
                    t("strain") +
                    " " +
                    m(
                      dataByDay["Patients"]["dateTimePrediction"][
                        dataByDay["Patients"]["dateTimePrediction"].length - 1
                      ],
                    )
                      .tz("Europe/Paris")
                      .format("DD.MM")
                  }
                />
              </div>
              <div
                className="col-2 padding-0"
                style={{
                  display: "inline-block",
                  height: height * 0.8,
                  marginLeft: "-10%",
                }}
              >
                {stackBarData && (
                  <StackedBar traduction="emergency" data={stackBarData["Patients"]} />
                )}
              </div>
            </div>
            <div className="" style={{ marginTop: "20px" }}>
              <div className="col-11" style={{ height: height + "px", display: "inline-block" }}>
                <Line
                  traduction="emergency"
                  timeSeries={true}
                  box={false}
                  language={i18n.language}
                  annotations={true}
                  hour={hour}
                  data={dataByDay["Discharges"]}
                  hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Discharges"]}
                  latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Discharges"]}
                  titleSize={18}
                  title={
                    t("discharges") +
                    " " +
                    m(
                      dataByDay["Discharges"]["dateTimePrediction"][
                        dataByDay["Discharges"]["dateTimePrediction"].length - 1
                      ],
                    )
                      .tz("Europe/Paris")
                      .format("DD.MM")
                  }
                />
              </div>
              <div
                className="col-2 padding-0"
                style={{
                  display: "inline-block",
                  height: height * 0.8,
                  marginLeft: "-10%",
                }}
              >
                {stackBarData && (
                  <StackedBar traduction="emergency" data={stackBarData["Discharges"]} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2" style={{ marginBottom: 40 }}>
          <div className="row s-2" id="j-minus-indexes"></div>
        </div>
      </>
    );
  };
  const renderPage = () => {
    let dataByDay = props.data["PredictionJ0ToJ4_V3"][dayJ];
    let height = window.innerWidth >= "2400" ? window.innerWidth * 0.2 : "380";

    return (
      <>
        <div className="col-lg-2">
          <fieldset className="fieldset-b" style={{ marginTop: 35 }}>
            <legend>{Capitalize(t("trend"))}</legend>
            <div
              className="row s-2 v2"
              id="j-minus-indexes"
              style={{ color: "black", paddingTop: "10%" }}
            >
              {renderTrendValues()}
            </div>
          </fieldset>
        </div>
        <div className="col-lg-5" style={{ marginTop: "2%" }}>
          <div className="">
            {isBarAdmision ? (
              <div>
                {/*
                <button
                  className="btn btn-primary position-btn"
                  style={{ zIndex: 10 }}
                  onClick={() => setBarAdmision(false)}
                >
                  {t("grav")}
                </button>
                */}
                <div
              className="col-md-9 col-lg-11"
              style={{ height: height + "px", display: "inline-block" }}
            >
              <Line
                  traduction="emergency"
                  timeSeries={true}
                  box={false}
                  language={i18n.language}
                  annotations={true}
                  hour={hour}
                  data={dataByDay["Admissions"]}
                  hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Admissions"]}
                  latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Admissions"]}
                  titleSize={18}
                  title={
                    t("admission") +
                    " " +
                    m(
                      dataByDay["Admissions"]["dateTimePrediction"][
                        dataByDay["Admissions"]["dateTimePrediction"].length - 1
                      ],
                    )
                      .tz("Europe/Paris")
                      .format("DD.MM")
                  }
                />
              </div>
              <div
                className="col-md-3 col-lg-2 padding-0"
                style={{
                  display: "inline-block",
                  height: height * 0.8 + "px",
                  marginLeft: "-10%",
                }}
              >
                {stackBarData && (
                  <StackedBar traduction="emergency" data={stackBarData["Admissions"]} />
                )}
              </div>
              </div>
            ) : (
              <div>
                <button className="btn btn-primary position-btn" onClick={() => setBarAdmision(true)}>
                  {t("bar")}
                </button>
                <div>
                  <Pie
                    width="500px"
                    height="300px"
                    traduction="emergency"
                    titleSize={18}
                    grav={true}
                    title={t("admission")}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="" style={{ marginTop: "20px" }}>
            {isBar ? (
              <div>
                <button
                  className="btn btn-primary position-btn"
                  style={{ zIndex: 10 }}
                  onClick={() => setBar(false)}
                >
                  {t("pie")}
                </button>
                <div
                  className="col-md-9 col-lg-11"
                  style={{ height: height + "px", display: "inline-block" }}
                >
                  <Line
                    traduction="emergency"
                    timeSeries={true}
                    box={false}
                    language={i18n.language}
                    annotations={true}
                    hour={hour}
                    data={dataByDay["Hospitalisations"]}
                    hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Hospitalisations"]}
                    latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Hospitalisations"]}
                    titleSize={18}
                    title={
                      t("hospitalisation") +
                      " " +
                      m(
                        dataByDay["Hospitalisations"]["dateTimePrediction"][
                          dataByDay["Hospitalisations"]["dateTimePrediction"].length - 1
                        ],
                      )
                        .tz("Europe/Paris")
                        .format("DD.MM")
                    }
                  />
                </div>
                <div
                  className="col-md-3 col-lg-2 padding-0"
                  style={{
                    display: "inline-block",
                    height: height * 0.8,
                    marginLeft: "-10%",
                  }}
                >
                  {stackBarData && (
                    <StackedBar traduction="emergency" data={stackBarData["Hospitalisations"]} />
                  )}
                </div>
              </div>
            ) : (
              <div>
                <button className="btn btn-primary position-btn" onClick={() => setBar(true)}>
                  {t("bar")}
                </button>
                <div>
                  <Pie
                    width="500px"
                    height="300px"
                    traduction="emergency"
                    titleSize={18}
                    data={
                      props.data["PredictionJ0ToJ4_V3"]["GroupsPrediction"].length > 0 &&
                      props.data["PredictionJ0ToJ4_V3"]["GroupsPrediction"][0]
                    }
                    title={t("hospitalisations")}
                  />
                </div>
              </div>
            )}{" "}
          </div>
        </div>
        <div className="col-lg-5" style={{ marginTop: "2%" }}>
          <div className="">
            <div
              className="col-md-9 col-lg-11"
              style={{ height: height + "px", display: "inline-block" }}
            >
              <Line
                traduction="emergency"
                timeSeries={true}
                box={false}
                language={i18n.language}
                annotations={true}
                hour={hour}
                data={dataByDay["Patients"]}
                hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Patients"]}
                latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Patients"]}
                titleSize={18}
                title={
                  t("strain") +
                  " " +
                  m(
                    dataByDay["Patients"]["dateTimePrediction"][
                      dataByDay["Patients"]["dateTimePrediction"].length - 1
                    ],
                  )
                    .tz("Europe/Paris")
                    .format("DD.MM")
                }
              />
            </div>
            <div
              className="col-md-3 col-lg-2 padding-0"
              style={{
                display: "inline-block",
                height: height * 0.8,
                marginLeft: "-10%",
              }}
            >
              {stackBarData && (
                <StackedBar traduction="emergency" data={stackBarData["Patients"]} />
              )}
            </div>
          </div>
          <div className="" style={{ marginTop: "20px" }}>
            <div
              className="col-md-9 col-lg-11"
              style={{ height: height + "px", display: "inline-block" }}
            >
              <Line
                traduction="emergency"
                timeSeries={true}
                box={false}
                language={i18n.language}
                annotations={true}
                hour={hour}
                data={dataByDay["Discharges"]}
                hours={props.data["PredictionJ0ToJ4_V3"]["Hours_3"]["Discharges"]}
                latestReal={props.data["PredictionJ0ToJ4_V3"]["LatestReal"]["Discharges"]}
                titleSize={18}
                title={
                  t("discharges") +
                  " " +
                  m(
                    dataByDay["Discharges"]["dateTimePrediction"][
                      dataByDay["Discharges"]["dateTimePrediction"].length - 1
                    ],
                  )
                    .tz("Europe/Paris")
                    .format("DD.MM")
                }
              />
            </div>
            <div
              className="col-md-3 col-lg-2 padding-0"
              style={{
                display: "inline-block",
                height: height * 0.8,
                marginLeft: "-10%",
              }}
            >
              {stackBarData && (
                <StackedBar traduction="emergency" data={stackBarData["Discharges"]} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderTitle = () => {
    let date = m(dateWeek);
    return `${t("we").toUpperCase() + " " + t("from")} ${date.format("DD.MM")} ${t("to")} ${date
      .add("1", "day")
      .format("DD.MM")}`;
  };

  console.log(props.data["PredictionJ0ToJ4_V3"]["GroupsPrediction"][0]);
  return (
    <>
      <div className="">
        <div style={{ display: "none" }}>
          <Clock
            format={"HH:mm:ss"}
            onChange={(date) => handleHour(date.output)}
            timezone={"Europe/Paris"}
            title="Tet"
          />
        </div>

        <div className="row row0-pastille">
          <div className="row col-lg-9 col-12">
            <div id="predictions-index-1" className="col-lg-1 col-2 d-inl">
              <div className="color-bar">
                <div className="bar" />
                <div className="info">
                  <p>
                    {dataColor &&
                      Math.round(
                        dataColor["Admissions"] && dataColor["Admissions"][0].daySumUpperations,
                      )}
                  </p>
                  <p>
                    {dataColor &&
                      Math.round(
                        dataColor["Admissions"] && dataColor["Admissions"][0].daySumMedian,
                      )}
                  </p>
                  <p>
                    {dataColor &&
                      Math.round(dataColor["Admissions"] && dataColor["Admissions"][0].daySumLower)}
                  </p>
                </div>
              </div>
            </div>
            <div id="predictions-highlights" className="col-lg-11 col-10 d-inl">
            {/*<input type="checkbox" className="debugCheckbox" checked={enableBedPrediction} onClick={()=>{setEnableBedPrediction(!enableBedPrediction);}}/>*/}
              <div className="row row-pastille">{renderCircleData()}</div>
            </div>
          </div>
          {isWeek === 2 && (
            <div className="col-lg-3 col-12">
              <fieldset className="pastetille-leg">
                <legend className="title-leg">{renderTitle()}</legend>
                <div className="row row-pastille">{renderCircleData2()}</div>
              </fieldset>
            </div>
          )}
        </div>

        <div className="row">
          {window.innerWidth <= "991.98" ? renderPageResponsive() : renderPage()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null)(ScreenEmergencyPred7);
