/**
 * @ By: Theo Bensaci
 * @ Date: 14:59:12 28.04.2022
 * @ Description: Class use for manage all contraints
 */
export class Contraints {
  constructor(id, name, weight, type) {
    this.Id = id;
    this.Name = name;
    this.Weight = weight;
    this.Type = type;
  }
}
