import React, { useState, useEffect } from "react";
import "./index.css";
import { Redirect } from "react-router-dom";
import { API } from "../../utils/api";
import { bindActionCreators } from "redux";
import Bounce from "react-reveal/Bounce";
import Jump from "react-reveal/Jump";
import { useTranslation } from "react-i18next";
import { getUsers, deleteUser, editUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import moment from "moment";
import { BsTrash, BsPencilSquare, BsPencil, BsFillPersonPlusFill } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdBlock } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import LoadingOverlay from "react-loading-overlay";
import * as Permission from "../../utils/permissions";
import { Capitalize } from "../../utils/functions";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";

const ListUser = (props) => {
  let history = useHistory();

  const { t, i18n } = useTranslation(["administration"]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    props.getUsers(props.activeUSer.user.token);
  }, [props.users.loaded]);

  const submit = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this user?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteUser(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteUser = (id) => {
    setLoad(true);
    setTimeout(() => {
      props
        .deleteUser(id, props.activeUSer.user, props.users.data)
        .then((res) => {
          setLoad(false);
        })
        .catch((e) => {
          setLoad(false);
          // console.log(e)
        });
    }, 0);
  };
  const confirmationBlockUser = (user) => {
    let active = 0;

    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to block this user?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => blockUser(user, active),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const confirmationUnBlockUser = (user) => {
    let active = 1;
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to unblock this user?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => blockUser(user, active),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const blockUser = (user, active) => {
    let newUser = {
      email: user.email,
      id: user.id,
      id_group: user.id_group,
      active: active,
      screen_default: user.screen_default,
    };
    let id = user.id;

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.USER_STATUS}`,
      data: { newUser, id },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then(function (response) {
        let users = props.users.data.map((user) => (user.id == id ? response.data : user));

        props.users.data = users;

        history.push("/users");
      })
      .catch(function (error) {
        console.log("error:", error);
      });
  };

  const renderListUsers = () => {
    let users = [];
    if (Permission.isSuperAdmin(props.activeUSer.user.group && props.activeUSer.user.group.Roles)) {
      users = props.users.data;
    } else {
      users = props.users.data.filter((u) => {
        return !Permission.isSuperAdmin(u.Group && u.Group.Roles);
      });
    }

    return users.map((user, i) => {
      if (user.id !== props.activeUSer.user.id)
        return (
          <tr key={user.id}>
            <th scope="row">{i}</th>
            <td>{user.email}</td>
            <td>{user.Group && user.Group.name.toUpperCase()}</td>
            <td>
              {user.Group &&
                user.Group.Roles.map((role, index) => {
                  if (window.innerWidth <= 991.98) {
                    return (
                      <>
                        <span className="label primary">
                          {role.name.toLowerCase() == "admin" ||
                          role.name.toLowerCase() == "super admin"
                            ? t(role.name).toUpperCase()
                            : t("screen " + role.name).toUpperCase()}
                        </span>
                        <br />
                      </>
                    );
                  }
                  if (window.innerWidth <= 1400) {
                    if (index % 2 == 0)
                      return (
                        <>
                          <span className="label primary">
                            {role.name.toLowerCase() == "admin" ||
                            role.name.toLowerCase() == "super admin"
                              ? t(role.name).toUpperCase()
                              : t("screen " + role.name).toUpperCase()}
                          </span>
                          <br />
                        </>
                      );
                  } else if (index % 3 == 0)
                    return (
                      <>
                        <span className="label primary">
                          {role.name.toLowerCase() == "admin" ||
                          role.name.toLowerCase() == "super admin"
                            ? t(role.name).toUpperCase()
                            : t("screen " + role.name).toUpperCase()}
                        </span>
                        <br />
                      </>
                    );
                  return (
                    <span className="label primary">
                      {role.name.toLowerCase() == "admin" ||
                      role.name.toLowerCase() == "super admin"
                        ? t(role.name).toUpperCase()
                        : t("screen " + role.name).toUpperCase()}
                    </span>
                  );
                })}
            </td>
            <td>{user.screen_default == "" ? "-" : "screen " + user.screen_default}</td>
            <td>{user.last_login && moment(user.last_login).format("YYYY-MM-DD HH:mm")}</td>
            <td>
              <a href={`/users/${user.id}/edit`} title="Editer" className="btn">
                <BsPencilSquare style={{ color: "", fontSize: 24 }} />
              </a>
              <a onClick={() => submit(user.id)} title="Delete" className="btn">
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
              {user.active == 1 ? (
                <a onClick={() => confirmationBlockUser(user)} title="Block" className="btn">
                  <AiOutlineCheckCircle style={{ color: "green", fontSize: 26 }} />
                </a>
              ) : (
                <a onClick={() => confirmationUnBlockUser(user)} title="Unblock" className="btn">
                  <MdBlock style={{ color: "red", fontSize: 25 }} />
                </a>
              )}
            </td>
          </tr>
        );
    });
  };
  return (
    <div className="row mrg-user" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("list of users")}</h1>
        <div className="row">
          <div className="col-12" style={{ textAlign: "end", marginTop: 20 }}>
            <a href="/users/edit" className="btn btn-user-1 btn-primary">
              <BsPencil style={{ color: "white", fontSize: 18, marginTop: -2 }} />
              {t("edit my profile")}
            </a>
            <a href="/users/new" style={{ marginLeft: 20 }} className="btn btn-user-1 btn-primary">
              <BsFillPersonPlusFill style={{ color: "white", fontSize: 18, marginTop: -2 }} />
              {t("new user")}
            </a>
          </div>
        </div>
        {props.users.loaded ? (
          <LoadingOverlay active={load} spinner>
            <table
              className={`table  table-user table-striped table-bordered   ${
                window.innerWidth <= 991.98 && "table-responsive"
              }`}
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{Capitalize(t("email"))}</th>
                  <th scope="col">{Capitalize(t("name of groups"))}</th>
                  <th scope="col">{Capitalize(t("access"))}</th>
                  <th scope="col">{Capitalize(t("default screen"))}</th>
                  <th scope="col" style={{ minWidth: "160px" }}>
                    {Capitalize(t("last login"))}
                  </th>
                  <th scope="col">{Capitalize(t("actions"))}</th>
                </tr>
              </thead>
              {props.users.data.length >= 2 && <tbody>{renderListUsers()}</tbody>}
            </table>

            {props.users.data.length < 2 && (
              <p style={{ textAlign: "center", fontSize: 16 }}>{t("no user found")}</p>
            )}
          </LoadingOverlay>
        ) : (
          <div
            className="row"
            style={{
              justifyContent: "center",
              minHeight: 150,
              alignItems: "flex-end",
            }}
          >
            <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ getUsers, deleteUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);
