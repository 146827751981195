export const roles = {
  user: ["HOME PAGE"],
  BedManager: ["EMERGENCY PRED 7", "EMERGENCY PRED 3"],
  emergency: [
    "EMERGENCY PRED 7",
    "EMERGENCY PRED 3",
    "EMERGENCY COVID",
    "EMERGENCY ACHIEVED",
    "EMERGENCY TREND 24",
    "EMERGENCY PERFORMANCE",
  ],
  journey: [
    "JOURNEY TRANSIT",
    "JOURNEY EMERGENCY",
    "JOURNEY PATIENTS",
    "JOURNEY SOINS",
    "JOURNEY PERFORMANCE",
  ],
  hospitalisation: [
    "HOSPITALISATION TO COME",
    "HOSPITALISATION IN PROGRESS",
    "HOSPITALISATION ACHIEVED",
    "HOSPITALISATION PERFORMANCE",
  ],
  cockpit: ["COCKPIT DECOMPTE", "COCKPIT COVID", "COCKPIT PERFORMANCE"],
  demo: ["DEMO GLOBAL"],

  administration: ["ADMIN", "SUPER ADMIN"],

  rh: ["RH LIFELINE", "RH STAFFING", "RH ASSIGNMENT", "RH PERFORMANCE"],
  bedManager: [
    "BED_MANAGER PARAMETER",
    "BED_MANAGER ACCOMODATION ALTERNATIVES",
    "BED_MANAGER UFS RELATED PARAMETERS",
  ],
  info: ["INFO TENDANCE 24H", "INFO PATIENTS"],
  screenTranslate: ["SCREEN TRANSLATE"],
};

export const hasRole = (role, roles) => {
  if (!roles) return false;

  if (isAdmin(roles) || isSuperAdmin(roles)) return true;
  if (roles.some((r) => r.name.toUpperCase() === role.trim())) return true;
  return false;
};

export const hasAtLeastOneRole = (Roles, roles, type) => {
  if (!roles) return false;
  if (!Roles || Roles.length === 0) return true;

  if (isAdmin(roles) || isSuperAdmin(roles)) return true;

  if (roles.some((r) => Roles.includes(r.name.toUpperCase()))) return true;
  return false;
};

export const isAdmin = (roles) => {
  if (!roles) return false;

  if (roles.some((r) => r.name.toUpperCase() === "ADMIN")) return true;
  return false;
};

export const isSuperAdmin = (roles) => {
  if (!roles) return false;
  if (roles.some((r) => r.name.toUpperCase() === "SUPER ADMIN")) return true;
  return false;
};
