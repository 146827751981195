import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { API } from "../../../utils/api";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import { bemPatientName } from "../../../utils/bem-patient-name";
import { Capitalize } from "../../../utils/functions";

const ScreenHospitalisationAchieved = (props) => {
  const { t, i18n } = useTranslation(["hospitalisation"]);

  const [selectedDiagOption, setDiagOptionSelected] = useState({
    value: null,
    label: t("all diagnostics").toUpperCase(),
  });
  const [selectedTypeOriginsOption, setTypeOriginsOptionSelected] = useState({
    value: null,
    label: t("all origins").toUpperCase(),
  });
  const [selectedTypeDischargesOption, setTypeDischargesOptionSelected] = useState({
    value: null,
    label: t("all discharges").toUpperCase(),
  });

  const [selectedSectorsOption, setSectorsOptionSelected] = useState({
    value: null,
    label: t("all sectors").toUpperCase(),
  });
  const [SectorsOptions, setSectorsOptions] = useState([]);
  const [diagOptions, setdiagOptions] = useState([]);
  const [typeOriginsOptions, settypeOriginsOptions] = useState([]);
  const [typeDischargesOptions, settypeDischargesOptions] = useState([]);
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("day").subtract(2, "days"));
  const [dataLossEnded, setDataLossEnded] = useState(props.data["lossEnded"]);
  const [Height, setHeight] = useState(null);
  const [nbDay, setNbDay] = useState(14);
  const [loss, setLoss] = useState([]);
  const [lossEnded, setLossEnded] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadPagination, setLoadPagination] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableBefore, setDisableBefore] = useState(true);
  const [zindexS, setZIndexS] = useState(1);
  const [page, setPage] = useState(0);
  const [nb, setNB] = useState(null);
  const nombreLignes = 100;
  const [numCurrentPage, setNumCurrentPage] = useState(null);
  const myRef = React.createRef();
  //#region Color
  // i gonna pute that here cause i don't want to rewrite all of that shit
  const MaleColor = "#00C3F5";
  const FemaleColor = "#EBA1F5";

  //#endregion

  useEffect(() => {
    if (!search) setDataLossEnded(props.data["lossEnded"]);
  }, [props.data]);

  useEffect(() => {
    let diag;
    let sectorsOptions = [],
      typeOriginsOptions = [],
      typeDischargesOptions = [],
      diagOptions = [];
    let test = { Sectors: [], diag: [], Origins: [], Discharges: [] };
    dataLossEnded.map((lossEnded) => {
      if (lossEnded["Beds"] && lossEnded["Beds"].length > 0) {
        if (!test.Sectors.includes(lossEnded["Beds"][0].secteur.trim()))
          sectorsOptions = [
            ...sectorsOptions,
            {
              value: lossEnded["Beds"][0].secteur.trim(),
              label: lossEnded["Beds"][0].secteur.trim(),
            },
          ];
        test.Sectors = [...test.Sectors, lossEnded["Beds"][0].secteur.trim()];
      }

      if (lossEnded["ModeEntree"]) {
        if (!test.Origins.includes(lossEnded["ModeEntree"].code)) {
          typeOriginsOptions = [
            ...typeOriginsOptions,
            { value: lossEnded["ModeEntree"].code, label: lossEnded["ModeEntree"].description },
          ];
          test.Origins = [...test.Origins, lossEnded["ModeEntree"].code];
        }
      }

      if (lossEnded["ModeSortie"]) {
        if (!test.Discharges.includes(lossEnded["ModeSortie"].code)) {
          typeDischargesOptions = [
            ...typeDischargesOptions,
            { value: lossEnded["ModeSortie"].code, label: lossEnded["ModeSortie"].description },
          ];
          test.Discharges = [...test.Discharges, lossEnded["ModeSortie"].code];
        }
      }
      if (lossEnded.diag !== "") {
        diag = lossEnded.diag.split(",");
        diag.length > 0 &&
          diag.map((val) => {
            if (!test.diag.includes(val.trim())) {
              test.diag = [...test.diag, val.trim()];
              diagOptions = [...diagOptions, { value: val.trim(), label: val.trim() }];
            }
          });
      }
    });
    setSectorsOptions([{ value: null, label: t(" all sectors") }, ...sectorsOptions]);
    settypeOriginsOptions([{ value: null, label: t("all origins") }, ...typeOriginsOptions]);
    settypeDischargesOptions([
      { value: null, label: t("all discharges").toUpperCase() },
      ...typeDischargesOptions,
    ]);
    setdiagOptions([{ value: null, label: t("all diagnostics") }, ...diagOptions]);
  }, [dataLossEnded]);

  useEffect(() => {
    setDiagOptionSelected({ value: null, label: t("all diagnostics") });
    setTypeOriginsOptionSelected({ value: null, label: t("all origins") });
    setTypeDischargesOptionSelected({ value: null, label: t("all discharges").toUpperCase() });
    setSectorsOptionSelected({ value: null, label: t("all sectors") });
  }, [i18n.language]);

  const resetSelect = () => {
    setLossEnded(null);
    setLoad(false);
    setSearch(false);
    setStartDate(null);
    setEndDate(null);
    setStartHeadDate(moment().subtract("2", "days"));
    setDataLossEnded(props.data["lossEnded"]);
    setDiagOptionSelected({ value: null, label: t("all diagnostics") });
    setTypeOriginsOptionSelected({ value: null, label: t("all origins") });
    setSectorsOptionSelected({ value: null, label: t("all sectors") });
    settypeDischargesOptions({ value: null, label: t("all discharges").toUpperCase() });
  };

  const resetDropDown = () => {
    setDiagOptionSelected({ value: null, label: t("all diagnostics") });
    setTypeOriginsOptionSelected({ value: null, label: t("all origins") });
    setSectorsOptionSelected({ value: null, label: t("all sectors") });
    setTypeDischargesOptionSelected({ value: null, label: t("all discharges").toUpperCase() });
  };

  useEffect(() => {
    let loss = [];
    dataLossEnded.map((los) => {
      //if (los.lit === 0) return;
      if (
        selectedSectorsOption &&
        selectedSectorsOption.value &&
        los.Beds &&
        los.Beds.length > 0 &&
        los.Beds[0]["secteur"].trim() !== selectedSectorsOption.value
      )
        return;
      if (
        selectedTypeOriginsOption &&
        selectedTypeOriginsOption.value &&
        los.type_entree !== selectedTypeOriginsOption.value
      )
        return;
      if (
        selectedTypeDischargesOption &&
        selectedTypeDischargesOption.value &&
        los.type_sortie !== selectedTypeDischargesOption.value
      )
        return;
      if (
        selectedDiagOption &&
        selectedDiagOption.value &&
        !los.diag.includes(selectedDiagOption.value)
      )
        return;
      if (
        search &&
        moment
          .utc(los["date_entree"])
          .diff(moment.utc(startHeadDate).startOf("day").add(13, "day")) >= 0
      )
        return;
      if (
        search &&
        moment.utc(los["date_entree"]).diff(moment.utc(startHeadDate).startOf("day")) < 0
      )
        return;
      loss = [...loss, los];
    });

    setLoss(loss);
    setSearchDropDown(true);
  }, [
    dataLossEnded,
    selectedTypeOriginsOption,
    selectedDiagOption,
    selectedSectorsOption,
    selectedTypeDischargesOption,
    search,
    startHeadDate,
  ]);

  useEffect(() => {
    let h = 0;
    let h2;
    if (loss) h = loss.length * 50 + "px";
    h2 = "65vh";
    setHeight({ h1: h, h2: h2 });
  }, [loss]);

  useEffect(() => {
    let _dataLossEnded;

    _dataLossEnded = [...loss];

    const pages =
      _dataLossEnded.length % nombreLignes === 0
        ? parseInt(_dataLossEnded.length / nombreLignes)
        : Math.ceil(_dataLossEnded.length / nombreLignes);
    setNumCurrentPage(pages);

    let h =
      _dataLossEnded.slice(nombreLignes * page, nombreLignes * (page + 1) - 1).length * 50 + "px";
    setPage(0);
    setNB(_dataLossEnded.length);
    let h2 = "75vh";
    setHeight({ h1: h, h2: h2 });
  }, [loss]);

  const LOSTypeToColorMapper = (los, losWithAnomalies) => {
    if (losWithAnomalies.includes(los.iep)) return "#fb7809";
    else if (["1", "2", "8", "9", "10", "12"].includes(los["type_entree"])) return "#dae4f3";
    else if (los["type_entree"] === "0") return "#ffefcb";
    else if (los["type_entree"] === "3") return "#e5edd9";
    else if (["4", "5", "6", "7", "11"].includes(los["type_entree"])) return "#dedcdc";
    else return "#cad6e8";
  };

  const getLossByDates = () => {
    if (!startDate && !endDate) return;
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    let data = { startDate: start, endDate: end, uf: props.uf };
    setLoad(true);

    axios
      .post(
        `${API.ENDPOINT + API.SCREEN_HOSP_BY_DATES}`,
        {
          data,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setDisableBefore(true);
        setDataLossEnded(res.data);
        setLossEnded(res.data);
        setStartHeadDate(moment(startDate));
        setSearch(true);
        setLoad(false);
      })
      .catch((err) => {
        //   console.log('err:',err)
        setLoad(false);
      });
  };

  useEffect(() => {
    if (moment(endDate).diff(moment(startHeadDate).startOf("day"), "days") + 1 > 12)
      setDisableNext(false);
    else setDisableNext(true);

    if (moment(startHeadDate).startOf("day").diff(moment(startDate), "days") + 1 > 7)
      setDisableBefore(false);
    else setDisableBefore(true);
  }, [startHeadDate]);

  const next = () => {
    setLoadPagination(true);
    setPage(0);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).startOf("day").add(14, "days"));
      setLoadPagination(false);
    }, 500);
  };
  const before = () => {
    setLoadPagination(true);
    setPage(0);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).startOf("day").subtract(14, "days"));
      setLoadPagination(false);
    }, 500);
  };

  useEffect(() => {
    if (startDate && endDate) {
      getLossByDates();
    } else {
      setLossEnded(null);
      setSearch(false);
      setDataLossEnded(props.data["lossEnded"]);
    }
  }, [props.uf]);

  const renderDateFilter = () => {
    return (
      <div className="row margin-top">
        <div
          className="col-lg-1 col-md-3 col-1 offset-9 margin-l"
          style={{ zIndex: 20, padding: "0px 5px" }}
        >
          <DatePicker
            style={{ borderColor: "red" }}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            isClearable
            placeholderText={t("from")}
            dateFormat="dd/MM/yyyy"
            maxDate={endDate ? endDate : new Date()}
          />
        </div>
        <div className="col-lg-2 col-md-3 col-1" style={{ zIndex: 8, padding: "0px 5px" }}>
          <DatePicker
            selected={endDate}
            isClearable
            placeholderText={t("to")}
            onChange={(date) => setEndDate(date)}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            minDate={startDate}
          />
        </div>
        {startDate && endDate && (
          <div
            className="col-md-1 col-3"
            style={{
              zIndex: 2,
              display: "flex",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {load ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="50px" />
            ) : (
              <button
                onClick={getLossByDates}
                style={{ width: "100px", height: "40px" }}
                type="button"
                className="btn btn-secondary"
              >
                {t("go")}
              </button>
            )}
          </div>
        )}
        {search && (
          <div className="col-md-1 col-3" style={{ zIndex: 2 }}>
            <button
              onClick={resetSelect}
              style={{ width: "100px", height: "40px" }}
              type="button"
              className="btn btn-light"
            >
              {t("reset")}
            </button>
          </div>
        )}
      </div>
    );
  };

  const getSexe = (val) => {
    if (val === 1) {
      return t("h");
    } else if (val === 2) return t("f");
    else return "";
  };

  const renderFilterDates2 = () => (
    <div className="col-12">
      <div className="col-12" style={{ zIndex: 2, padding: "0px 5px" }}>
        {search && (
          <>
            <div
              className="col-12 d-flex justify-content-center"
              style={{ zIndex: 6, marginTop: 20 }}
            >
              {loadPagination ? (
                <ReactLoading className="" type={"bars"} color="#2fa2d9" width="50px" />
              ) : (
                <>
                  <button
                    onClick={before}
                    style={{ width: "autp", height: "40px", margin: 5 }}
                    type="button"
                    disabled={disableBefore}
                    className="btn btn-secondary"
                  >
                    {t("2 previous weeks")}
                  </button>
                  <button
                    onClick={next}
                    style={{ width: "auto", height: "40px", margin: 5 }}
                    type="button"
                    disabled={disableNext}
                    className="btn btn-secondary"
                  >
                    {t("next 2 weeks")}
                  </button>
                </>
              )}
            </div>
            <div
              className="row w-100"
              style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}
            >
              <div
                className="col-12 d-flex justify-content-center "
                style={{ justifyContent: "center" }}
              >
                <Button
                  onClick={() => move(-1)}
                  disabled={page === 0}
                  style={{ width: "50px" }}
                  variant="outline-primary"
                >{`<<`}</Button>

                <Button disabled style={{ marginLeft: "2%" }} variant="primary">
                  {page + 1}
                </Button>

                <Button
                  onClick={() => move(+1)}
                  disabled={page === numCurrentPage - 1}
                  style={{ marginLeft: "2%", width: "50px" }}
                  variant="outline-primary"
                >{`>>`}</Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const move = (i) => {
    setPage(page + i);
    myRef.current.scrollTo(0, 0);
  };
  const renderFilters = () => {
    return (
      <div className="row">
        <div className="col-lg-2 col-md-6 col-12" style={{ zIndex: 8, marginBottom: 10 }}>
          <Select
            value={selectedSectorsOption}
            className="selectJ1"
            isSearchable={false}
            placeholder="all sectors"
            onChange={(selectedOption) => setSectorsOptionSelected(selectedOption)}
            options={SectorsOptions}
          />
        </div>
        <div className="col-lg-2 col-md-6 col-12" style={{ zIndex: 7, marginBottom: 10 }}>
          <Select
            value={selectedTypeOriginsOption}
            className="selectJ1"
            isSearchable={true}
            placeholder="all origins"
            onChange={(selectedOption) => setTypeOriginsOptionSelected(selectedOption)}
            options={typeOriginsOptions}
          />
        </div>
        <div className="col-lg-2 col-md-6 col-12" style={{ zIndex: 6, marginBottom: 10 }}>
          <Select
            value={selectedDiagOption}
            className="selectJ1"
            isSearchable={true}
            placeholder="all diagnostics"
            onChange={(selectedOption) => setDiagOptionSelected(selectedOption)}
            options={diagOptions}
          />
        </div>
        <div className="col-lg-2 col-md-6 col-12" style={{ zIndex: zindexS, marginBottom: 10 }}>
          {
            <Select
              value={selectedTypeDischargesOption}
              className="selectJ1"
              isSearchable={true}
              placeholder="all discharges"
              onChange={(selectedOption) => setTypeDischargesOptionSelected(selectedOption)}
              options={typeDischargesOptions}
              onMenuOpen={() => setZIndexS(7)}
              onMenuClose={() => setZIndexS(2)}
            />
          }
        </div>
        <div
          className="col-lg-2 col-md-12 col-12 text-center"
          style={{ zIndex: 3, marginBottom: 10 }}
        >
          {searchDropDown && (
            <button
              onClick={resetDropDown}
              style={{ width: "100px", height: "40px" }}
              type="button"
              className="btn btn-light"
            >
              {t("reset")}
            </button>
          )}
        </div>
      </div>
    );
  };

  const losToGanttFormatter = (los, today, losWithAnomalies, losWithAnomaliesCauses, index) => {
    //if (moment.utc(los['date_entree']).diff(moment(startHeadDate).startOf('day').add(13,'day'),'days') > 0) return
    if(props.us.length>0 && (los.Beds!=null || !props.us.includes(los.Beds[0].secteur)))return null;

    today = moment(startHeadDate)
      .startOf("day")
      .add(2, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm");

    let sector = los.lit !== 0 && los.Beds[0]["secteur"] ? los.Beds[0]["secteur"] : "";

    let ganttStartPoint = moment.utc(startHeadDate).startOf("day");
    let stay_started_earlier = false;
    let flatDate = moment.utc(los["date_entree"]);

    if (
      parseInt(moment(los["date_sortie"]).diff(moment(los["date_entree"]), "minutes")) <= 0 ||
      parseInt(moment(los["date_sortie_predite"]).diff(moment(los["date_entree"]), "minutes")) <= 0
    )
      return;

    if (moment.utc(los["date_entree"]).diff(moment.utc(ganttStartPoint).startOf("day")) < 0) {
      flatDate = moment.utc(ganttStartPoint).startOf("day");
    }

    let per = 100 / 14;
    let start =
      parseInt(moment.utc(flatDate).diff(moment.utc(ganttStartPoint).startOf("day"), "minutes")) /
      1440;
    if (search) start--;
    let startHourRatio = start > 0 ? start * per : 0;

    let predictedLOSVal =
      parseInt(moment.utc(los["date_sortie_predite"]).diff(moment(flatDate), "minutes")) / 1440;
    let predictedLOSRatio = predictedLOSVal < 0 ? 0 : predictedLOSVal * per;

    let realLosVal =
      parseInt(moment.utc(los["date_sortie"]).diff(moment(flatDate), "minutes")) / 1440;
    let realLOSRatio = realLosVal < 0 ? 0 : realLosVal * per;

    let expectedLOSVal =
      parseInt(
        moment
          .utc(los["date_entree"])
          .add(los["duree_attendue"] * 24, "hours")
          .diff(moment(flatDate), "minutes"),
      ) / 1440;
    let expectedLOSRatio = expectedLOSVal < 0 ? 0 : expectedLOSVal * per;

    let LoS = parseInt(
      moment.utc(los["date_sortie_predite"]).diff(moment.utc(los["date_entree"]), "hours"),
    );
    los.los_dev = los.los_dev <= 0 ? 0 : los.los_dev;
    let devDeg = (LoS - los.los_dev * 24) / (LoS + los.los_dev * 24);
    let predictedLOSRatio_dev =
      (parseInt(
        moment.utc(los["date_sortie_predite"]).diff(moment.utc(los["date_entree"]), "minutes"),
      ) /
        1440) *
        per +
      los.los_dev * per;

    return (
      <div className="gantt__row w-100" style={{ minHeight: 50 }} key={index}>
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-4">{sector}</div>
            <div className="col-1">{los["uf"]}</div>
            <div className="col-5">{los["ModeEntree"] ? los["ModeEntree"]["description"] : ""}</div>
            <div className="col-2">
              {los["lit"]} / {los["Beds"][0]["chambre"]}
              {/* 
                        {(!losWithAnomalies.includes(los.iep)) && relativePredictedDuration <2 &&
                         relativePredictedDuration >=0 && (
                            relativePredictedDuration < 1  ?  <FaArrowRight  color="black" size={20} style={{float:'right'}} /> :
                            <FaArrowAltCircleRight  color="black" style={{float:'right'}} size={20} />
                        )
                       
                        } */}
            </div>
          </div>
        </div>
        <ul className="gantt__row-bars">
          <li
            style={{ gridColumn: `1/15`, display: "flex" }}
            data-tip
            data-for={`tooltip-${los["iep"]}`}
          >
            <div
              data-tip
              data-for={`tooltip-${los["iep"]}`}
              className={`predicted-los-wrapper-done ${
                los["change_flag"] === true ? "re-estimated-prediction" : ""
              }`}
              style={
                predictedLOSRatio > 0
                  ? {
                      background: `linear-gradient(to right, ${
                        los.sexe === "M" ? MaleColor : FemaleColor
                      }, ${devDeg * 100}%, ${(los.sexe === "M" ? MaleColor : FemaleColor) + "00"}`,
                      marginLeft: startHourRatio + "%",
                      width: `${predictedLOSRatio_dev}%`,
                    }
                  : { marginLeft: startHourRatio + "%", border: "none", width: 0 }
              }
            />

            <div
              className="real-los-wrapper-done"
              data-tip
              data-for={`tooltip-${los["iep"]}`}
              style={
                realLOSRatio > 0
                  ? { marginLeft: startHourRatio + "%", width: realLOSRatio + "%" }
                  : { marginLeft: startHourRatio + "%", width: 0 + "%", border: "none" }
              }
            ></div>

            <div
              className={`${!losWithAnomalies.includes(los.iep) ? "expected-los-wrapper" : ""}`}
              style={
                expectedLOSRatio > 0
                  ? { marginLeft: startHourRatio + "%", width: `${expectedLOSRatio}%` }
                  : { marginLeft: startHourRatio + "%", border: "none", width: `0%` }
              }
            />

            <div
              className="real-los-wrapper-2"
              data-tip
              data-for={`tooltip-${los["iep"]}`}
              style={{ marginLeft: startHourRatio + "%" }}
            >
              {los.bemPatient ? bemPatientName(los.bemPatient) : los.iep} / {los.age}
            </div>
          </li>
        </ul>
        {setLOSTooltip(los, losWithAnomalies, losWithAnomaliesCauses)}
      </div>
    );
  };

  const setLOSTooltip = (los, losWithAnomalies, losWithAnomaliesCauses) => {
    return (
      <ReactTooltip id={`tooltip-${los["iep"]}`} effect="solid">
        <ul className="tooltip-list" style={{ color: "white", zIndex: 5 }}>
          <li style={{ color: "white" }}>
            {`${t("prenom")} : ${Capitalize(los.bemPatient?.prenom ?? "-")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("nom")} : ${los.bemPatient?.nom.toUpperCase() ?? "-"}`}{" "}
          </li>
          {los.bemPatient?.nom_mar ? (
            <li style={{ color: "white" }}>
              {`${t("nom_mar")} : ${los.bemPatient.nom_mar.toUpperCase()}`}{" "}
            </li>
          ) : null}
          <li style={{ color: "white" }}>{`${t("iep")} : ${los.iep}`} </li>

          <li style={{ color: "white" }}>{`${t("sex")} : ${t(los.sexe)}`} </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${los.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>
            {`${t("origin")} : ${los.ModeEntree && los.ModeEntree.description} `}{" "}
          </li>
          <li style={{ color: "white" }}>{`${t("diagnostic code")} : ${
            los.diag !== null && los.diag !== "" ? los.diag : "-"
          }`}</li>
          <li style={{ color: "white" }}>{`${t("Sector")} : ${los["Beds"][0].secteur}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${los["Beds"][0].lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(los["date_entree"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("discharge date")} : ${moment(los["date_sortie"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("effective duration")} : ${
              parseInt(moment(los["date_sortie"]).diff(moment(los["date_entree"]), "hours") / 24) <=
              0
                ? parseInt(
                    moment(los["date_sortie"]).diff(moment(los["date_entree"]), "hours") / 24,
                  )
                : parseInt(
                    moment(los["date_sortie"]).diff(moment(los["date_entree"]), "hours") / 24,
                  ).toFixed(1)
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted release date")} : ${moment(los["date_sortie_predite"]).format(
              "DD.MM.YY A",
            )}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted duration")} : ${Math.round(los["los_moy"])} ${t("days")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("expected duration")} : ${los["duree_attendue"].toFixed(1)} ${t("days")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of error`)} : +/- ${los.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderSpanEnded = (test) => {
    var foo = Array(lossEnded["loss"]["maxDuration"] + 1).fill(5);
    let i = 0;
    return foo.map((index) => {
      i++;
      return (
        <span style={{ marginLeft: test ? -100 + (i - 1) * 9 + "%" : 0 }}>{test && i - 1}</span>
      );
    });
  };

  const renderDates = () => {
    let days = [];
    for (let i = 0; i < nbDay; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <span key={i}>{moment(startHeadDate).startOf("day").add(day, "days").format("DD.MM")} </span>
    ));
  };

  const renderGantt = () => {
    return (
      <div className="row div-gantt" id="div-gantt2">
        <div className="gantt__row gantt__row--months">
          <div className="gantt__row-first">
            <div className="row">
              <div className="col-4">{t("sector")}</div>
              <div className="col-1">{t("uf")}</div>
              <div className="col-5">{t("origin")}</div>
              <div className="col-2">
                {t("bed")} / {t("room")}
              </div>
            </div>
          </div>

          {renderDates()}
        </div>
        <div
          className="gantt w-100"
          ref={myRef}
          style={Height ? { maxHeight: Height.h2, overflowY: "scroll" } : {}}
        >
          <div></div>
          <div
            className="gantt__row gantt__row--lines"
            data-month="5"
            style={Height ? { height: Height.h1 } : {}}
          >
            <span className=""></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
            <span className="marker2"></span>
          </div>

          {loss &&
            loss.slice(nombreLignes * page, nombreLignes * (page + 1) - 1).map((los, index) => {
              return losToGanttFormatter(los, moment.utc().clone(), [], [], index);
            })}
        </div>
      </div>
    );
  };

  if (props.Wait === true) {
    return (
      <div className="row-0" style={{ marginTop: "6%" }}>
        <div className="col-12 text-center">
          <h2 className="message">
            {t("medical is generating new predictions. please try again in a few minutes ....")}
          </h2>
        </div>
        <div className="col-12 text-center">
          <a className="btn btn-primary row-0" onClick={props.getValuesByUf}>
            {t("reset")}
          </a>
        </div>
      </div>
    );
  } else
    return (
      <div className="row-0">
        <div className="col-12">{renderDateFilter()}</div>
        <div className="col-12">{renderFilters()}</div>
        {search && renderFilterDates2()}
        <div className="col-12 scroll-gant" style={{ marginTop: "2%" }}>
          {renderGantt()}
          {loss && loss.length === 0 && props.Wait === false && (
            <div className="col-12  d-flex justify-content-center">
              <h2 className="message"> {t("data not available")}</h2>
            </div>
          )}
        </div>
      </div>
    );
};
export default ScreenHospitalisationAchieved;
