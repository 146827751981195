import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "72%",
  },
};

const PopUpPlanning = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [optionsType, setOptionsType] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedOptionType, setOptionSelectedType] = useState({
    value: 0,
    label: t("types"),
  });
  const [optionsShift, setOptionsShift] = useState([
    { value: "day", key: "day", label: t("day") },
    { value: "night", key: "night", label: t("night") },
  ]);
  const [selectedOptionStartShift, setOptionSelectedStartShift] = useState({
    value: 0,
    key: "start shift",
    label: t("start shift"),
  });
  const [selectedOptionEndShift, setOptionSelectedEndShift] = useState({
    value: 0,
    key: "end shift",
    label: t("end shift"),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [medecin, setMedecin] = useState(props.medecin);
  const [planning, setPlanning] = useState(props.planning);
  const [add, setAdd] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState([]);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLTYPES}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        loadingOption(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  useEffect(() => {
    setMedecin(props.medecin);
    setPlanning(props.planning);
    edit(props.planning);
  }, [props.medecin, props.planning]);

  const loadingOption = (data) => {
    let optionsTypes = [{ value: 0, label: t("types") }];
    data.map((type) => {
      optionsTypes = [...optionsTypes, { value: type.index, label: type.denomination }];
    });
    setOptionsType(optionsTypes);
  };

  const changeHeight = () => {
    customStyles.content.height = "470px";
  };

  // const PopupAdd = () => {
  //   setAdd(false);
  //   setIsOpen(true);

  //   setShowError(false);
  // };
  const PopupEdit = () => {
    setAdd(true);
    setIsOpen(true);
    onCancel();
    setShowError(false);
  };
  const hidePopup = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      // PopupAdd: PopupAdd,
      PopupEdit: PopupEdit,
    };
  });

  const edit = (planning) => {
    if (!add) {
      planning.Types &&
        setOptionSelectedType({
          value: planning.Types.index,
          label: planning.Types.denomination,
        });
      planning.startDate && setStartDate(new Date(planning.startDate));
      planning.endDate && setEndDate(new Date(planning.endDate));
    } else {
      onCancel();
    }
  };

  const confirmationDelete = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this planning ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deletePlanning(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deletePlanning = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETEPLANNING}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setShowMessage(true);
        setMessage({ edit: "the planning has been deleted successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        props.deletePlanning(res.data);
        setLoad(false);
        setIsOpen(false);
      })
      .catch((e) => {
        setLoad(false);
        //  console.log(e)
      });
  };

  const addPlanning = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    if (selectedOptionType.value === 0) {
      setErrorBorder(["choiceType"]);
      setShowError(true);
      setError({ date: "please select a type" });
      return;
    }
    if (selectedOptionStartShift.value === 0) {
      setErrorBorder(["choiceStartShift"]);
      setShowError(true);
      setError({ type: "please select a Shift day/night" });
      return;
    }
    if (selectedOptionEndShift.value === 0) {
      setErrorBorder(["choiceEndShift"]);
      setShowError(true);
      setError({ type: "please select a Shift day/night" });
      return;
    }
    let planning = {
      startDate:
        selectedOptionStartShift.value === "day"
          ? moment(startDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(startDate + "" + "20:00")
              .set("hour", 20)
              .format("YYYY-MM-DD HH:mm"),
      endDate:
        selectedOptionEndShift.value === "day"
          ? moment(endDate).set("hour", 8).format("YYYY-MM-DD HH:mm")
          : moment(endDate).set("hour", 20).format("YYYY-MM-DD HH:mm"),
      description: description === "" ? "" : description,
      type_id: selectedOptionType.value == 0 ? null : selectedOptionType.value,
      startShift: selectedOptionStartShift.value,
      endShift: selectedOptionEndShift.value,
      member_id: medecin.index,
      status: "in_progress",
    };
    let exist = props.medecin.Planning.some(
      (plan) =>
        moment(planning.startDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        moment(planning.endDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        (moment(plan.startDate).isSameOrAfter(moment(planning.startDate).format("YYYY-MM-DD")) &&
          moment(plan.endDate).isSameOrAfter(moment(planning.startDate).format("YYYY-MM-DD")) &&
          moment(plan.startDate).isSameOrBefore(moment(planning.endDate).format("YYYY-MM-DD")) &&
          moment(plan.endDate).isSameOrBefore(moment(planning.endDate).format("YYYY-MM-DD"))),
    );

    if (exist) {
      setShowError(true);
      setError({
        date: "the date you have selected is already booked, please enter another one",
      });
      return;
    }
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_PLANNING}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: planning,
    })
      .then((response) => {
        setShowMessage(true);
        setMessage({ add: "the planning was added successfully." });
        onCancel();
        setTimeout(() => setShowMessage(false), 3000);
        props.addPlanning(response.data);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            // console.log(errors)
            setError(errors);
          } else if (error.response.status === "401") {
            setError({ date: "impossible to add this planning" });
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const updatePlanning = (planning) => {
    let plan = {
      index: planning.index,
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm"),
      description: description === "" ? planning.description : description,
      type_id: selectedOptionType.value == 0 ? null : selectedOptionType.value,
      member_id: planning.member_id,
      status: planning.status,
    };
    let newPlanning = [];
    newPlanning = props.medecin.Planning.filter((plan) => plan.index !== plan.index);
    let exist = newPlanning.some(
      (plan) =>
        moment(plan.startDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        moment(plan.endDate).isBetween(
          moment(plan.startDate).format("YYYY-MM-DD"),
          moment(plan.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        (moment(plan.startDate).isSameOrAfter(moment(planning.startDate).format("YYYY-MM-DD")) &&
          moment(plan.endtDate).isSameOrAfter(moment(planning.startDate).format("YYYY-MM-DD")) &&
          moment(plan.startDate).isSameOrBefore(moment(planning.endDate).format("YYYY-MM-DD")) &&
          moment(plan.endtDate).isSameOrBefore(moment(planning.endDate).format("YYYY-MM-DD"))),
    );

    if (exist) {
      setShowError(true);
      setError({ date: "veuillez choisir d'autres dates" });
      return;
    }

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_PLANNING}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: plan,
    })
      .then((response) => {
        setShowMessage(true);
        setMessage({ edit: "	the planning has been successfully modified" });
        setTimeout(() => setShowMessage(false), 3000);
        onCancel();
        props.editPlanning(response.data);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            // console.log(errors)
            setError(errors);
          } else if (error.response.status === "401") {
            setError({ date: "impossible to modify this planning" });
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const onCancel = () => {
    setEndDate(null);
    setStartDate(null);
    setOptionSelectedType(optionsType[0]);
  };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={hidePopup} ariaHideApp={false} style={customStyles}>
      <div className="popup">
        <div className="d-flex justify-content-end">
          <button onClick={hidePopup} className="btn-close">
            X
          </button>
        </div>
        <div
          className="message"
          style={{
            marginTop: 20,
            marginBottom: 20,
            height: "50px",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showError &&
            error &&
            Object.entries(error).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        <form onSubmit={addPlanning} className="form-user-new">
          {!add ? (
            <div
              className="text-center"
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                type="button"
                href="#"
                className="action btn-red"
                onClick={() => confirmationDelete(planning.index)}
              >
                {t("supprimer")}{" "}
              </a>

              <a type="button" href className="action" onClick={() => updatePlanning(planning)}>
                {t("edit")}
              </a>
            </div>
          ) : (
            <div className="text-center" style={{ marginBottom: "20px" }}>
              <input type="submit" className="action" value={t("add")} />
            </div>
          )}
          <div className="d-flex flex-column mdl">
            <Select
              value={selectedOptionType}
              className={`selectJ1 form-select ${
                showError && errorBorder.includes("choiceType") ? "error" : ""
              }`}
              isSearchable={false}
              onChange={(selectedOptionType) => setOptionSelectedType(selectedOptionType)}
              onClick={changeHeight}
              options={optionsType}
            />
            <div className="d-flex flex-column" style={{ marginTop: 10 }}>
              <div className="d-flex w-m-85">
                <DatePicker
                  style={{ borderColor: "" }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  isClearable
                  placeholderText={t("from")}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date(moment(endDate))}
                  required
                />
                <Select
                  value={selectedOptionStartShift}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceStartShift") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionStartShift) =>
                    setOptionSelectedStartShift(selectedOptionStartShift)
                  }
                  options={optionsShift}
                />
              </div>
              <div className="d-flex w-m-85">
                <DatePicker
                  selected={endDate}
                  isClearable
                  placeholderText={t("to")}
                  onChange={(date) => setEndDate(date)}
                  // maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(moment(startDate))}
                  required
                />
                <Select
                  value={selectedOptionEndShift}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceEndShift") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOptionEndShift) =>
                    setOptionSelectedEndShift(selectedOptionEndShift)
                  }
                  options={optionsShift}
                />
              </div>
            </div>
            <div className="d-flex ">
              <input
                type="text"
                className="w-85"
                style={{ margin: "10px auto" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                className="fadeIn form-control"
                name="_description"
                placeholder={t("description")}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
});
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(PopUpPlanning);
