import React, { useState, useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../utils/api";
import { connect } from "react-redux";

const NewScreen = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(false);

  const addScreen = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);

    let token = props.activeUSer.user.token;

    let screen = {
      name: name,
    };

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.TRANSLATIONS_NEWSCREEN}`,
      data: screen,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        setMessage(true);
        setName("");
        setTimeout(() => setMessage(false), 3000);
      })
      .catch((error) => {
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  return (
    <div className="row mrg-user" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("nouvelle écran")}</h1>

        <div className="row">
          <div className="col-10 offset-1">
            <hr></hr>
            <form onSubmit={addScreen} className="form-user-new">
              <div style={{ textAlign: "center" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err) => (
                    <div className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("l'écran  a été enregistré avec succès ")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group block">
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  className="fadeIn form-control"
                  name="_name"
                  placeholder={t("nom d'écran")}
                />
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  href="/translations/allScreens"
                >
                  {t("retour")}
                </a>
                <input
                  type="submit"
                  disabled={name !== "" ? false : true}
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("valider")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(NewScreen);
