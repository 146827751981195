import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  startOfDay,
  addDays,
  addHours,
  endOfDay,
  isWithinInterval,
  addMilliseconds,
} from "date-fns";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./index.scoped.css";
import "./style.scss";
import "chartjs-plugin-annotation";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import "moment/locale/fr";
import { Capitalize } from "../../../utils/functions";
import "moment/locale/de";
import "moment/locale/nl";
import { FaArrowAltCircleRight, FaArrowRight, FaMemory } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { bemPatientName } from "../../../utils/bem-patient-name";
import { API } from "../../../utils/api";
import { sumBy, keyBy, groupBy, sortBy, head, compact, uniq } from "lodash";
import { useRequest } from "../../../hooks/use-api";
import { useUfs } from "../../../hooks/use-ufs";
import { useUfLabels } from "../../../hooks/use-uf-labels";
import {
  Gantt,
  colorFromSexe,
  tooltipDataFromLos,
  cellFromBedDispatching,
  cellFromLos,
  mergeRowsBy,
  paginateRows,
} from "../../../components/gantt";
import { p2pStatus } from "../../../utils/p2p-status";
import { useNow } from "../../../hooks/use-now";
import { useFormat } from "../../../hooks/use-format";
import clsx from "clsx";
import { Pagination } from "./pagination";
import { Indicator } from "./indicator";
import { Filters } from "./filters";
import { BedCardRender } from "./BedVue/bedCard";
import { getUS } from "../../../utils/calculUs";

const fixId = (n) => {return (n==null)?"null":n.toString().replace(/^0+/, "")};
const idFromLos = (los) => `${fixId(los.uf)}-${fixId(los.Beds.lit)}-${fixId(los.Beds.chambre)}`;
const idFromBed = (bed) => `${fixId(bed.uf)}-${fixId(bed.bed)}-${fixId(bed.room)}`;
const idFromBedDispatching = (bd) => `${fixId(bd.uf)}-${fixId(bd.Beds.lit)}-${fixId(bd.Beds.chambre)}`;


function ScreenHospitalisationProgress({ type, uf, ...props }) {
  const numberOfCardParePage=30;
  const format = useFormat();
  const { t } = useTranslation(["hospitalisation"]);
  const now = useNow(60 * 60 * 1000);
  const request = useRequest();
  const ufs = useUfs();
  const ufLabels = useUfLabels();
  const ufsPerGroups = useSelector((state) => {
    let a =Object.entries(state.screensHospData.groupes).map(([k, { uf }]) => {return [k, uf];});
    // ajoute de la catégorie all
    a.push(["ALL",[]])
    a.map((x)=>{a[a.length-1][1].push(...x[1])});
    return Object.fromEntries(a);
  });
  const [page, setPage] = useState(0);
  const [showRow,setShowRow]=useState([]);
  const [filters, setFilters] = useState({
    sorting: "byBedAsc",
    sectors: [],
    origins: [],
    diags: [],
    ieps: [],
    bedStatus: [],
    stayTypes: [],
    anomalie: "all",
  });

  const selectedGroup = type?.value;
  const realSelectedUfs = uf?.map((option) => option.value) ?? [];
  
  const selectedUfs = realSelectedUfs.length > 0 ? realSelectedUfs : ufsPerGroups[selectedGroup];

  const [bedVue,setBedVue]=useState(false);


  /**
   * Check si l'us est selecetioner
   * @param {*} uf uf a checker
   * @param {*} us us a checker
   * @returns si l'us est selectioner
   */
  function isUsSelected(uf,us){
    if(props.us==null || props.us.length==0)return true;
    for (const iterator of props.us) {
      if(iterator.value.uf==uf && iterator.value.secteur!=us)return false;
    }
    return true;

  }

  useEffect(()=>{
    props.SetUsLoading(false);
    if(window.location.href.endsWith("/bedvue")){
      setBedVue(true);
    }
  },[])

  useEffect(()=>{
    if(bedVue && !window.location.href.endsWith("/bedvue")){
      window.location.href+="/bedvue";
      return;
    }

    if(!bedVue && window.location.href.endsWith("/bedvue")){
      window.location.href=window.location.href.replace("/bedvue","");
    }
  },[bedVue])

  const {
    data: [api],
  } = useQuery(
    ["hospitalisation", "in-progress", ...ufs],
    () =>
      request({
        method: "POST",
        path: API.V5_HOSPITALISATION_IN_PROGRESS,
        data: { ufs },
      }),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  useEffect(()=>{
    props.SetUsLoading(false);
    props.AddUs(getUS(api.bedDispatchings))
  },[api])


  const bedDispatchings = useMemo(
    () =>
      api.bedDispatchings
        // We exclude bedDispatching without valid bed & room
        .filter((bedDispatching) => bedDispatching.Beds.lit && bedDispatching.Beds.chambre)
        // Include only selected ufs
        .filter((bedDispatching) => selectedUfs.includes(bedDispatching.uf.toString())),
    [api, selectedUfs],
  );

  const loss = useMemo(
    () =>
      api.loss
        // We exclude los without valid bed & room
        .filter((los) =>  ((los.Beds.lit==0) || los.Beds.lit && los.Beds.chambre))
        // Include only selected ufs
        .filter((los) => selectedUfs.includes(fixId(los.uf))),
    [api, selectedUfs],
  );

  //console.log(getUS([...bedDispatchings,...loss]))

  // all loss with no bed
  const lossWithNoBed = useMemo(
    () =>
      api.loss
        // Include only selected ufs
        .filter((los) => selectedUfs.includes(fixId(los.uf)))
        .filter((los) =>  (los.lit=="0")),
    [api, selectedUfs],
  );



  const beds = useMemo(
    () =>
    {
      let resultBeds=api.beds
        // Include only selected ufs
        .filter((bed) => selectedUfs.includes(fixId(bed.uf.toString())))
        .filter((bed)=>{
          if(bed.Beds==null)return true;
          return isUsSelected(bed.Beds.uf,bed.Beds.secteur)}
        );
      
      return resultBeds;
    },
    [api, selectedUfs],
  );

  const bedDispatchingsById = useMemo(
    () => groupBy(bedDispatchings, idFromBedDispatching),
    [bedDispatchings],
  );

  const lossById = useMemo(() => groupBy(loss, idFromLos), [loss]);

  const accommodatedLoss = useMemo(
    () =>
      realSelectedUfs.length > 0
        ? api.loss
            // We exclude los without valid bed & room
            .filter((los) => (los.Beds.lit && los.Beds.chambre))
            // Exclude restitued ones
            .filter((los) => los.bedDispatchBedManagementStatus?.status !== "RESTITUED")
            // Include only selected uf_admins
            .filter((los) => los.uf_admin && selectedUfs.includes(fixId(los.uf_admin)))
            // Exclude accomodated loss that are already present in loss
            .filter((los) => !lossById[idFromLos(los)])
        : [],
    [api, realSelectedUfs, lossById],
  );


  const accommodatedLossById = useMemo(
    () => groupBy(accommodatedLoss, idFromLos),
    [accommodatedLoss],
  );


  const accommodatedBeds = useMemo(
    () =>
      api.beds
        // Include only admin ufs
        .filter((bed) => Boolean(accommodatedLossById[idFromBed(bed)])),
    [api, accommodatedLoss],
  );

  const from = startOfDay(addDays(now, -1));
  const to = endOfDay(addDays(now, 8));



  const rows = useMemo(
    () => [
      ...beds.map(function (bed) {
        const id = idFromBed(bed);
        const cells = [
          // Los
          ...(lossById[id]?.map((los) => ({
            ...cellFromLos(los, { now, t, ufLabels, ufDms: api.dms, format }),
            meta: {
              type: "ongoing",
              iep: los.iep,
              origin: los.ModeEntree?.description,
              diag: los.diag,
            },
          })) ?? []),
          // Bed Dispatching
          ...(bedDispatchingsById[id]?.map((bd) => ({
            ...cellFromBedDispatching(bd, { now, t, format }),
            meta: {
              type: "incoming",
              iep: bd.iep,
              origin: t((bd.preadm_flag == 1 ?"pre-admission" : "urgence")).toUpperCase(),
              diag: undefined,
            },
          })) ?? []),
          
        ]
          // order cells by from ASC
          .sort((a, b) => a.from.getTime() - b.from.getTime());

        return {
          key: id,
          data: {
            sector: ufLabels[fixId(bed.uf)] ?? "",
            uf: bed.uf,
            origin: (row) => row.cells[0]?.meta.origin ?? "",
            bed: `${bed.bed}/${bed.room}`,
          },
          meta: {
            status: bed.status,
            bed: bed.bed,
          },
          cells,
        };
      }),
      ...accommodatedBeds.map(function (bed) {
        const id = idFromBed(bed);
        const cells = [
          // Accommodated Los
          ...(accommodatedLossById[id]?.map((los) => ({
            ...cellFromLos(los, { now, t, ufLabels, ufDms: api.dms, format }),
            isAccommodation: false,
            meta: {
              type: "ongoing",
              iep: los.iep,
              origin: los.ModeEntree?.description,
              diag: los.diag,
            },
          })) ?? []),
        ]
          // order cells by from ASC
          .sort((a, b) => a.from.getTime() - b.from.getTime());

        return {
          key: id,
          isAccommodated: true,
          data: {
            sector: ufLabels[fixId(bed.uf)] ?? "",
            uf: bed.uf,
            origin: (row) => row.cells[0]?.meta.origin ?? "",
            bed: `${bed.bed}/${bed.room}`,
          },
          meta: {
            status: bed.status,
            bed: bed.bed,
          },
          cells,
        };
      }),
      ...lossWithNoBed.map(function (los) {
        const id = idFromLos(los)+los.iep;;
        const cells = [
          {
            ...cellFromLos(los, { now, t, ufLabels, ufDms: api.dms, format }),
            meta: {
              type: "ongoing",
              iep: los.iep,
              origin: los.ModeEntree?.description,
              diag: los.diag,
            }
          }
        ]
          // order cells by from ASC
          .sort((a, b) => a.from.getTime() - b.from.getTime());

        return {
          key: id,
          data: {
            sector: ufLabels[fixId(los.uf)] ?? "",
            uf: los.uf,
            origin: (row) => row.cells[0]?.meta.origin ?? "",
            bed: t("in placement"),
          },
          meta: {
            status: "",
            bed: los.lit,
          },
          cells,
        };
      })
    ],
    [beds, lossById, bedDispatchingsById, accommodatedBeds, accommodatedLossById, api.dms],
  );

  const bedRow=useMemo(
    () => [
      ...beds.map(function (bed) {
        const id = idFromBed(bed);
        const cells = [
          // Los
          ...(lossById[id]?.map((los) => ({
            ...cellFromLos(los, { now, t, ufLabels, ufDms: api.dms, format }),
            meta: {
              type: "ongoing",
              iep: los.iep,
              origin: los.ModeEntree?.description,
              diag: los.diag,
            },
          })) ?? []),
          // Bed Dispatching
          ...(bedDispatchingsById[id]?.map((bd) => ({
            ...cellFromBedDispatching(bd, { now, t, format }),
            meta: {
              type: "incoming",
              iep: bd.iep,
              origin: t((bd.preadm_flag == 1 ?"pre-admission" : "urgence")).toUpperCase(),
              isPreAdmin:bd.preadm_flag,
              diag: undefined,
            },
          })) ?? []),
          
        ]
          // order cells by from ASC
          .sort((a, b) => a.from.getTime() - b.from.getTime());

        return {
          key: id,
          data: {
            sector: ufLabels[fixId(bed.uf)] ?? "",
            uf: bed.uf,
            cleaning: bed.Beds?bed.Beds.cleaning ?true:false:false,
            origin: (row) => row.cells[0]?.meta.origin ?? "",
            bed: `${bed.bed}/${bed.room}`,
          },
          meta: {
            status: bed.status,
            bed: bed.bed,
          },
          cells,
        };
      }),
      ...accommodatedBeds.map(function (bed) {
        const id = idFromBed(bed);
        const cells = [
          // Accommodated Los
          ...(accommodatedLossById[id]?.map((los) => ({
            ...cellFromLos(los, { now, t, ufLabels, ufDms: api.dms, format }),
            isAccommodation: false,
            meta: {
              type: "ongoing",
              iep: los.iep,
              origin: los.ModeEntree?.description,
              diag: los.diag,
            },
          })) ?? []),
        ]
          // order cells by from ASC
          .sort((a, b) => a.from.getTime() - b.from.getTime());

        return {
          key: id,
          isAccommodated: true,
          data: {
            sector: ufLabels[fixId(bed.uf)] ?? "",
            uf: bed.uf,
            cleaning: bed.Beds?bed.Beds.cleaning ?true:false:false,
            origin: (row) => row.cells[0]?.meta.origin ?? "",
            bed: `${bed.bed}/${bed.room}`,
          },
          meta: {
            status: bed.status,
            bed: bed.bed,
          },
          cells,
        };
      })
    ],
    [beds, lossById, bedDispatchingsById, accommodatedBeds, accommodatedLossById, api.dms],
  );
  

 /**
  * Convert statue to a number to represented there prioritie
  * @param {*} statue 
  * @returns {int} prioritie 
  */
  function convertStatue(statue){
    switch(statue){
      case "OL":
        return 0;
      case "OO":
        return 0;
      case "FD":
        return 1;
      case "FT":
        return 2;
    }
  }


  // order bed card vue data
  const bedRowOrder=useMemo(()=>{
    let order=bedRow
    .sort((a,b)=>{
      return a.meta.bed-b.meta.bed;
    }).sort((a,b)=>{
      return a.data.uf-b.data.uf;
    }).sort((a,b)=>{
      return convertStatue(a.meta.status)-convertStatue(b.meta.status);
    });

    return order;
  },[bedRow])

  const anomalies =
    // Each row
    rows
      .flatMap((row) =>
        // Each cells of rows
        row.cells.flatMap(function (cell, _, cells) {
          // For each cell, compare it with other cells of the row
          return cells.flatMap(function (subcell) {
            // We don't compare the same cell
            if (cell.meta.iep === subcell.meta.iep) {
              return [];
            }

            const subCellInterval = { start: subcell.from, end: subcell.to };
            if (
              isWithinInterval(cell.from, subCellInterval) ||
              isWithinInterval(cell.to, subCellInterval)
            ) {
              return [[cell.key, "Ce séjour chevauche un autre séjour"]];
            }

            // isWithinInterval(now, { start: cell.from, end: cell.to })
            return [];
          });
        }),
      )
      .reduce(function (acc, [key, error]) {
        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(error);

        return acc;
      }, {});
  const sortedRows = useMemo(
    () =>
      [...rows].sort(function (a, b) {
        switch (filters.sorting) {
          case "byEntryDateDesc": {
            return (head(b.cells)?.from.getTime() ?? 0) - (head(a.cells)?.from.getTime() ?? 0);
          }
          case "byBedAsc": {
            if (a.meta.bed > b.meta.bed) {
              return 1;
            } else if (a.meta.bed < b.meta.bed) {
              return -1;
            } else {
              return 0;
            }
          }
          default: {
            return 0;
          }
        }
      }),
    [rows, filters],
  );

  const filteredRows = useMemo(
    function () {
      const someCellFiltersActive =
        filters.origins.length > 0 ||
        filters.diags.length > 0 ||
        filters.ieps.length > 0 ||
        filters.stayTypes.length > 0 ||
        filters.anomalie=="anomalie";


      return compact(
        sortedRows.map(function (row) {
          if(row.meta.status =="FD")return undefined;
          // if y a US si select, only los with bed
          if(props.us!=null && props.us.length!=0 && (row.data.bed=="EN PLACEMENT")){
            return undefined;
          }

          // Sector
          if (filters.sectors.length > 0 && !filters.sectors.includes(row.data.sector)) {
            return undefined;
          }
          // Bed Status
          if (filters.bedStatus.length > 0 && !filters.bedStatus.includes(row.meta.status)) {
            return undefined;
          }
          const cells = compact(
            row.cells?.map(function (cell) {
              // Origin
              if (filters.origins.length > 0 && !filters.origins.includes(cell.meta.origin)) {
                return undefined;
              }
              // Diag
              if (filters.diags.length > 0 && !filters.diags.includes(cell.meta.diag)) {
                return undefined;
              }
              // Iep
              if (filters.ieps.length > 0 && !filters.ieps.includes(cell.meta.iep)) {
                return undefined;
              }
              // stayType
              if (
                filters.stayTypes.length > 0 &&
                !filters.stayTypes.some(function (stayType) {
                  switch (stayType) {
                    case "incoming": {
                      return cell.meta.type === "incoming";
                    }
                    case "ongoing": {
                      return cell.meta.type === "ongoing";
                    }
                    default: {
                      return false;
                    }
                  }
                })
              ) {
                return undefined;
              }

              if(filters.anomalie=="anomalie" && anomalies[cell.key]==undefined){
                return undefined;
              }

              return cell;
            }) ?? [],
          );

          if (someCellFiltersActive && cells.length === 0) {
            return undefined;
          } else {
            return { ...row, cells };
          }
        }),
      );
    },
    [sortedRows, filters, props.us],
  );

  /*const [paginatedRows, { pageMax }] = useMemo(
    () => paginateRows(filteredRows, page),
    [filteredRows, page],
  );

  useEffect(
    function () {
      if (paginatedRows.length === 0 && page !== 0) {
        setPage(0);
      }
    },
    [paginatedRows, page],
  );
  */

  const pageMax=useMemo(()=>{
    let a=filteredRows.length % numberOfCardParePage;
    return (filteredRows.length-a)/numberOfCardParePage;
  },[filteredRows])


  useEffect(()=>{
    let returnValue=[];
    for (let index = 0; index < numberOfCardParePage; index++) {
      let i = page*numberOfCardParePage+index;

      if(i>filteredRows.length)break;

      if(filteredRows[i]==null || filteredRows[i]==undefined)continue;

      returnValue.push(filteredRows[i]);
    }
    setShowRow(returnValue);
  },[page,filters, props.us])


  return (
    <div className="row-0" style={{ paddingTop: "40px" }}>
      <div className="col-12">
        <button className="switchVue" onClick={()=>{setBedVue(!bedVue)}}>{t(bedVue?"SwitchToClassicVue":"SwitchToBedvue")}</button>
        <Indicator ufs={ufs} selectedUfs={selectedUfs} anomalies={anomalies} />
      </div>
      {
      bedVue?<BedCardRender data={bedRowOrder} rows={rows} us={props.us}/>:
      <div>
        <div className="col-12" style={{ marginTop: 50 }}>
          <Filters filters={filters} setFilters={setFilters} rows={rows} />
        </div>
        <div
          className="col-12"
          style={{ marginTop: "2%", display: "flex", flexDirection: "column", gap: 30 }}
        >
          <Pagination rows={filteredRows} pageMax={pageMax} page={page} setPage={(a)=>{setPage(a);}} />
          <Gantt
            now={now}
            from={from}
            to={to}
            columns={{
              sector: "UF",
              uf: "#UF",
              origin: "Provenance",
              bed: "Lit / Chambre",
            }}
            rows={showRow}
            anomalies={anomalies}
          />
        </div>
      </div>
      }
    </div>
  );
}

export default ScreenHospitalisationProgress;
