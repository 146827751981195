import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import UfsSelector from "../../../components/ufs-selector";
import GroupsSelector from "../../../components/groups-selector";
import Capacitaire from "../bedManagerScreen/patientsToTreatScreen/capacitaire";
import "./style.scss";
import "./index.css";
import { useRequest } from "../../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../utils/api";

function ScreenCockpitDecompte({ groupes }) {
  const request = useRequest();
  const allUfs = useMemo(
    () => Object.values(groupes).flatMap(({ data }) => data.map(([id]) => id)),
    [groupes],
  );

  const {
    data: [capacitaire],
  } = useQuery(
    ["cockpit", "capacitaire", ...allUfs],
    () =>
      request({
        method: "POST",
        path: API.V5_CAPACITAIRE,
        data: {
          ufs: allUfs,
        },
      }),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUfs, setSelectedUfs] = useState([]);

  const ufs = useMemo(
    () =>
      selectedUfs.length > 0
        ? selectedUfs
        : Object.entries(groupes).flatMap(function ([groupId, { data }]) {
            if (selectedGroups.length > 0 && !selectedGroups.includes(groupId)) {
              return [];
            } else {
              return data.map(([id]) => id);
            }
          }),
    [groupes, selectedGroups, selectedUfs],
  );

  return (
    <div className="cockpit-capacitaire">
      <div className="ptt-section ptt-left">
        <div className="ptt-filters">
          <div className="ptt-filter-group">
            <GroupsSelector
              groups={selectedGroups}
              onSelect={(options) =>
                setSelectedGroups(options?.map((option) => option.value) ?? [])
              }
              isSearchable
              isMulti
            />
          </div>
          <div className="ptt-filter-uf">
            <UfsSelector
              groups={selectedGroups}
              ufs={selectedUfs}
              onSelect={(options) => setSelectedUfs(options?.map((option) => option.value) ?? [])}
              isMulti
              isSearchable
            />
          </div>
        </div>
      </div>
      <div className="ptt-section ptt-right">
        <Capacitaire ufs={ufs} capacitaire={capacitaire} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  groupes: state.screensHospData.groupes,
});

export default connect(mapStateToProps, null)(ScreenCockpitDecompte);
