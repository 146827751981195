import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import DatePicker from "react-datepicker";
import { API } from "../../../utils/api";
import { Capitalize } from "../../../utils/functions";
import axios from "axios";
import LineCovid from "../../../components/chartV2/lineCovid";
import Danger from "../../../images/danger-alert.png";
Moment.globalTimezone = "Europe/Paris";

const ScreenEmergencyCovid = (props) => {
  const { t, i18n } = useTranslation(["emergency"]);
  const [update, setUpdate] = useState(false);
  const [dataAdmission, setDataAdmission] = useState(null);
  const [dataHospCovid, setDataHospitalisationCovid] = useState(null);
  const [dataEmergency, setDataEmergency] = useState(null);
  const [dataHospvsHosp, setDataHospitalisationvsHosp] = useState(null);
  const [dataAdmissionHosp, setDataAdmissionHospitalisation] = useState(null);
  const [dataHosp, setDataHospitalisation] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [search, setSearch] = useState(false);
  const [load, setLoad] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [filterCovidData, setFilterCovidData] = useState(null);
  const [globalCovidData, setGlobalCovidData] = useState(props.data["Prediction_Covid"]);
  const [predictionData, setPredData] = useState(props.data["PredictionJ0ToJ4_V3"]);
  const [yesterdayData, setYesterday] = useState(props.data["Yesterday"]);
  useEffect(() => {
    if (update === true) setUpdate(false);
  }, [update]);

  const reset = () => {
    setLoad(true);
    setEndDate(null);
    setStartDate(null);
    setSearch(false);
    setLoad(false);
  };

  useEffect(() => {
    let Data = search === true ? filterCovidData : globalCovidData;

    // set all 0 to null
    Data["cases_admitted_pred"] = Data["cases_admitted_pred"].map((x) => (x == 0 ? null : x));

    setDataAdmission({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t(`admissions`)),
          data: Data["cases_admitted"],
          borderColor: "#909090",
          borderWidth: 2,
          fill: false,
          type: "line",
        },
        {
          label: Capitalize(t("previsions")),
          data: Data["cases_admitted_pred"],
          borderColor: "#3aa8fb",
          strokeColor: "#909090",
          borderWidth: 2,
          fill: false,
          type: "line",
        },
      ],
    });

    let someData = Data["stress_no_covid"];

    let moveMean = [];
    for (let i = 0; i < Data["stress"].length - 15; i++) {
      let mean =
        (someData[i] +
          someData[i - 1] +
          someData[i - 2] +
          someData[i + 2] +
          someData[i - 3] +
          someData[i + 3] +
          someData[i + 1]) /
        7;
      moveMean.push(mean);
    }

    setDataHospitalisationCovid({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t("previsions")),
          data: Data["cases_for_1000_district_pred"],
          borderColor: "#3aa8fb",
          borderWidth: 2,
          fill: false,
          type: "line",
          borderDash: [5, 5],
        },
        {
          label: t(`admissions covid urgences /100,000 people (department)`),
          data: Data["cases_for_1000_district"],
          borderColor: "#fc4b28",
          borderWidth: 2,
          fill: false,
          type: "line",
        },

        {
          label: t(`admissions covid urgences /100,000 people (france)`),
          data: Data["cases_for_1000_country"],
          borderColor: "#ffc000",
          borderWidth: 2,
          fill: false,
          type: "line",
        },
      ],
    });

    setDataEmergency({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t(`inpatients covid`)),
          data: Data["stress"],
          borderColor: "#909090",
          borderWidth: 2,
          fill: false,
          yAxisID: "first-y-axis",
          type: "line",
        },
        {
          label: Capitalize(t(`covid strain trend emergencies`)),
          data: moveMean,
          borderColor: "#1de81d",
          borderWidth: 3,
          fill: false,
          yAxisID: "second-y-axis",
          type: "line",
        },
        {
          label: Capitalize(t(`strain covid emmergencies`)),
          data: Data["stress_no_covid"],
          borderColor: "black",
          borderWidth: 2,
          fill: false,
          yAxisID: "second-y-axis",
          type: "line",
        },
      ],
    });

    setDataHospitalisationvsHosp({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t("measured")),
          data: Data["hospi"],
          borderColor: "#909090",
          borderWidth: 2,
          fillColor: "#909090",
          backgroundColor: "#909090",
          fill: true,
          type: "line",
          pointRadius: 0,
          lineTension: 0,
        },
      ],
    });

    setDataAdmissionHospitalisation({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t("measured")),
          data: Data["covid_vs_total_hosp"],
          borderColor: "#909090",
          borderWidth: 2,
          fillColor: "#909090",
          backgroundColor: "#909090",
          fill: true,
          type: "line",
          pointRadius: 0,
          lineTension: 0,
        },
      ],
    });

    setDataHospitalisation({
      labels: Data["datetimeCovid"],
      datasets: [
        {
          label: Capitalize(t("measured")),
          data: Data["stress_hospitalized"],
          borderColor: "#909090",
          borderWidth: 2,

          fillColor: "#909090",
          backgroundColor: "#909090",
          fill: true,
          type: "line",
          pointRadius: 0,
          lineTension: 0,
        },
      ],
    });

    setUpdate(true);
  }, [props.data, i18n.language, globalCovidData, filterCovidData, search]);

  const renderChartAdmission = () => {
    return (
      <LineCovid
        traduction="emergency"
        update={update}
        annotations={true}
        data={dataAdmission}
        title={Capitalize(t("admissions covid/ forecast"))}
        labelY={t("admissions")}
      />
    );
  };

  const renderChartEmergency = () => {
    return (
      <LineCovid
        traduction="emergency"
        hasTwoAxeY={true}
        annotations={true}
        update={update}
        data={dataEmergency}
        labelY1={Capitalize(t("Hospitalised"))}
        labelY2={t("strain")}
        title={t("Incidence rate of admissions vs. hospitalisations 1")}
        labelY={t("admissions")}
      />
    );
  };
  const renderChartHospitalisationCovid = () => {
    return (
      <LineCovid
        traduction="emergency"
        update={update}
        annotations={true}
        data={dataHospCovid}
        title={Capitalize(t("Incidence rate of emergency admissions 2"))}
        labelY={t("admissions")}
      />
    );
  };

  const renderChart = (data, title, labelY1 = Capitalize(t("beds"))) => {
    return (
      <LineCovid
        traduction="emergency"
        hasTwoAxeY={true}
        annotations={true}
        labelY2={false}
        update={update}
        data={data}
        title={title}
        labelY1={labelY1}
      />
    );
  };

  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const renderIndicators = () => {
    let data = globalCovidData;
    let admissions;
    let sortie;
    let tension;
    let suspicious;
    let from_ehpad;
    let pcr_ext;
    let pcr_int;
    let test_ant;
    let old_admitted;
    let hosp_covid;
    let cases_hospitalized;
    let date = data["datetime"][data["datetime"].length - 1];
    if (search) {
      admissions = data["admissions"].reduce(reducer);
      sortie = data["cases_discharged"].reduce(reducer);
      tension = data["stress_no_covid"].reduce(reducer);
      suspicious = data["suspicious"].reduce(reducer);
      from_ehpad = data["from_ehpad"].reduce(reducer);
      pcr_ext = data["pcr_ext"].reduce(reducer);
      pcr_int = data["pcr_int"].reduce(reducer);
      hosp_covid = data["stress"].reduce(reducer);
    } else {
      admissions = data["admissions"][data["admissions"].length - 1];
      sortie = data["cases_discharged"][data["cases_discharged"].length - 1];
      tension = data["stress_no_covid"][data["stress_no_covid"].length - 1];
      suspicious = data["suspicious"][data["suspicious"].length - 1];
      from_ehpad = data["from_ehpad"][data["from_ehpad"].length - 1];
      pcr_ext = data["pcr_ext"][data["pcr_ext"].length - 1];
      pcr_int = data["pcr_int"][data["pcr_int"].length - 1];
      hosp_covid = data["stress"][data["stress"].length - 1];
      test_ant = data["test_ant"][data["test_ant"].length - 1];
      old_admitted = data["old_admitted"][data["old_admitted"].length - 1];
      cases_hospitalized = data["cases_hospitalized"][data["cases_hospitalized"].length - 1];
    }

    return (
      <>
        <div className="row" style={{ marginTop: 25 }}>
          <div
            className={
              globalCovidData &&
              globalCovidData["seuil_critique"] &&
              globalCovidData["seuil_critique"] != ""
                ? "col-12 col-md-10"
                : "col-12"
            }
          >
            <table
              className={`table table2-covid${
                globalCovidData &&
                globalCovidData["seuil_critique"] &&
                globalCovidData["seuil_critique"] != ""
                  ? " table2covid2"
                  : ""
              }  ${window.innerWidth <= 992 && "table-responsive"}`}
            >
              <thead className="">
                <tr>
                  <th></th>
                  <th scope="">{Capitalize(t("admissions"))} </th>
                  <th scope="">{Capitalize(t("admissions ≥ 75 years old"))} </th>
                  <th scope="">{Capitalize(t("covid suspicions"))} </th>
                  <th scope="">{Capitalize(t("urg admission -> hosp uf covid"))} </th>
                  <th scope="">{t("hosp covid")} </th>
                  <th className="th_weight" scope="">
                    {t("hosp covid vs hosp")}{" "}
                  </th>
                  {/* <th  scope="">{t('Sorties Hosp COVID')} </th>   */}
                </tr>
              </thead>
              <tbody>
                <tr className="tr-today">
                  <td className="td-date">{moment().subtract(1, "days").format("DD.MM")}</td>
                  <td>{yesterdayData["entree"]["ReelSomme"]}</td>
                  <td>{yesterdayData["old"]["ReelSomme"]}</td>
                  <td>{Math.round(data["yesterday_cases_admitted"])}</td>
                  <td>
                    {data["yesterday_cases_hospitalized"] % 1 == 0
                      ? data["yesterday_cases_hospitalized"]
                      : data["yesterday_cases_hospitalized"].toFixed(1)}
                  </td>
                  <td>
                    {data["yesterday_stress"] % 1 == 0
                      ? data["yesterday_stress"]
                      : data["yesterday_stress"].toFixed(1)}
                  </td>
                  <td className="td_weight">
                    {(data["yesterday_covid_vs_total_hosp"] * 100).toFixed(2)}%
                  </td>
                </tr>

                <tr className="tr-tomorrow">
                  <td className="td-date">{moment().format("DD.MM")}</td>
                  <td>{predictionData["J0"]["entree"]["ReelPredSomme"]}</td>
                  <td>{predictionData["J0"]["old"]["ReelPredSomme"]}</td>
                  <td>
                    {Math.round(
                      data["today_cases_admitted_pred"] - data["today_cases_admitted_dev"],
                    )}{" "}
                    -{" "}
                    {Math.round(
                      data["today_cases_admitted_pred"] + data["today_cases_admitted_dev"],
                    )}
                  </td>

                  <td>
                    {data["today_cases_hospitalized_pred"] % 1 == 0
                      ? data["today_cases_hospitalized_pred"]
                      : data["today_cases_hospitalized_pred"].toFixed(1)}
                  </td>
                  <td>
                    {data["today_stress_pred"] % 1 == 0
                      ? data["today_stress_pred"]
                      : data["today_stress_pred"].toFixed(1)}
                  </td>
                  <td className="td_weight">
                    {" "}
                    {(data["today_covid_vs_total_hosp"] * 100).toFixed(2)}%
                  </td>
                </tr>

                <tr className="tr-tomorrow">
                  <td className="td-date">{moment().add("1", "days").format("DD.MM")}</td>
                  <td>{predictionData["J1"]["entree"]["Somme_0h"]}</td>{" "}
                  <td>{predictionData["J1"]["old"]["Somme_0h"]}</td>
                  <td>
                    {Math.round(
                      data["tomorrow_cases_admitted_pred"] - data["tomorrow_cases_admitted_dev"],
                    )}{" "}
                    -{" "}
                    {Math.round(
                      data["tomorrow_cases_admitted_pred"] + data["tomorrow_cases_admitted_dev"],
                    )}
                  </td>
                  <td>
                    {data["tomorrow_cases_hospitalized_pred"] % 1 == 0
                      ? data["tomorrow_cases_hospitalized_pred"]
                      : data["tomorrow_cases_hospitalized_pred"].toFixed(1)}
                  </td>
                  <td>
                    {data["tomorrow_stress_pred"] % 1 == 0
                      ? data["tomorrow_stress_pred"]
                      : data["tomorrow_stress_pred"].toFixed(1)}
                  </td>
                  <td className="td_weight">
                    {(data["tomorrow_covid_vs_total_hosp"] * 100).toFixed(2)}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {globalCovidData &&
            globalCovidData["seuil_critique"] &&
            globalCovidData["seuil_critique"] != "" && (
              <div
                className="col-12 col-md-2"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <img className="covid-alert-png" src={Danger} />
                <h4 className="h4-critique" style={{ color: "black" }}>
                  {globalCovidData["seuil_critique"]}
                </h4>
              </div>
            )}
        </div>
      </>
    );
  };

  const getFilterCovidData = () => {
    if (!startDate && !endDate) return;
    setLoad(true);
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    let data = { startDate: start, endDate: end };

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.SCREEN_COVID}`,
      data,
      headers: {
        "x-auth-token": props.activeUser.user.token,
      },
    })
      .then((res) => {
        setFilterCovidData(res.data["Prediction_Covid"]);
        setSearch(true);
        setLoad(false);
      })
      .catch((err) => {
        // console.log('err:',err)
        setLoad(false);
      });
  };

  const renderDateFilter = () => {
    return (
      <div className="row" style={{ marginTop: 20, marginLeft: 10, marginBottom: 50 }}>
        <div className="col-lg-2 col-sm-3 col-5" style={{ zIndex: 9 }}>
          <DatePicker
            style={{ borderColor: "red" }}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            isClearable
            placeholderText={t("from")}
            dateFormat="dd/MM/yyyy"
            maxDate={endDate ? endDate : ""}
          />
        </div>
        <div className="col-lg-2 col-sm-3 col-5" style={{ zIndex: 9 }}>
          <DatePicker
            selected={endDate}
            isClearable
            placeholderText={t("to")}
            onChange={(date) => setEndDate(date)}
            //  maxDate={new Date(moment().subtract(1,'day'))}
            dateFormat="dd/MM/yyyy"
            minDate={startDate}
          />
        </div>
        {startDate && endDate && (
          <div
            className="col-1"
            style={{
              zIndex: 20,
              display: "flex",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {load ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="50px" />
            ) : (
              <button
                onClick={getFilterCovidData}
                style={{ width: "100px", height: "40px" }}
                type="button"
                className="btn btn-secondary"
              >
                {t("go")}
              </button>
            )}
          </div>
        )}
        {startDate && endDate && search && (
          <div
            className="col-1"
            style={{
              zIndex: 20,
              display: "flex",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              onClick={reset}
              style={{ width: "100px", height: "40px" }}
              type="button"
              className="btn btn-info"
            >
              {t("reset")}
            </button>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="container-covid">
      {renderDateFilter()}

      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChartAdmission()}
          </div>
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChart(dataHospvsHosp, t("hosp covid"))}
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChartHospitalisationCovid()}
          </div>
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChart(dataAdmissionHosp, t("hosp covid vs hosp"), "%")}
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChartEmergency()}
          </div>
          <div className="row graph-pred" style={{ marginTop: 20 }}>
            {renderChart(dataHosp, t("hosp"))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScreenEmergencyCovid;
