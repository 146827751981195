import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "72%",
  },
};

const PopUpRecommandation = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [optionsPosts, setOptionsPosts] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedOptionPost, setSelectedOptionPost] = useState({
    value: 0,
    label: t("postes"),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [medecin, setMedecin] = useState(props.medecin);
  const [recommandations, setRecommandations] = useState(props.recommandations);
  const [add, setAdd] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState([]);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLPOSTS}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        lodaingOptions(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  useEffect(() => {
    setMedecin(props.medecin);
    setRecommandations(props.recommandations);
    edit(props.recommandations);
  }, [props.medecin, props.recommandations]);

  const lodaingOptions = (data) => {
    let optionsPosts = [{ value: 0, label: t("posts") }];
    data.map((post) => {
      optionsPosts = [...optionsPosts, { value: post.index, label: post.denomination }];
    });
    setOptionsPosts(optionsPosts);
  };

  const PopupAdd = () => {
    setAdd(true);
    setIsOpen(true);
    setShowError(false);
    onCancel();
  };
  const PopupEdit = () => {
    setAdd(false);
    setIsOpen(true);
    setShowError(false);
  };
  const hidePopup = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      PopupAdd: PopupAdd,
      PopupEdit: PopupEdit,
    };
  });

  const edit = (recommandation) => {
    if (!add) {
      recommandation.Posts &&
        setSelectedOptionPost({
          value: recommandation.Posts.index,
          label: recommandation.Posts.denomination,
        });
      recommandation.startDate && setStartDate(new Date(recommandation.startDate));
      recommandation.endDate && setEndDate(new Date(recommandation.endDate));
    } else {
      onCancel();
    }
  };

  const confirmationDelete = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this recommandation?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteRecommandation(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteRecommandation = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETERECOMMANDATION}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setShowMessage(true);
        setMessage({ edit: "the recommandation was deleted successfully" });
        setTimeout(() => setShowMessage(false), 3000);
        props.deleteRecommandation(res.data);
        setLoad(false);
        setIsOpen(false);
      })
      .catch((e) => {
        setLoad(false);
        //  console.log(e)
      });
  };

  const addRecommandation = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    if (selectedOptionPost.value === 0) {
      setErrorBorder(["choicePosts"]);
      setShowError(true);
      setError({ date: "please select a post" });
      return;
    }
    let recommandation = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      post_id: selectedOptionPost.value === 0 ? null : selectedOptionPost.value,
      member_id: medecin.index,
    };
    let exist = props.medecin.Recommandations.some(
      (item) =>
        moment(recommandation.startDate).isBetween(
          moment(item.startDate).format("YYYY-MM-DD"),
          moment(item.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        moment(recommandation.endDate).isBetween(
          moment(item.startDate).format("YYYY-MM-DD"),
          moment(item.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        (moment(item.startDate).isSameOrAfter(
          moment(recommandation.startDate).format("YYYY-MM-DD"),
        ) &&
          moment(item.endDate).isSameOrAfter(
            moment(recommandation.startDate).format("YYYY-MM-DD"),
          ) &&
          moment(item.startDate).isSameOrBefore(
            moment(recommandation.endDate).format("YYYY-MM-DD"),
          ) &&
          moment(item.endDate).isSameOrBefore(moment(recommandation.endDate).format("YYYY-MM-DD"))),
    );
    if (exist) {
      setShowError(true);
      setError({ date: "the date you have selected is already booked, please enter another one" });
      return;
    }

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_RECOMMANDATION}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },

      data: recommandation,
    })
      .then((response) => {
        setShowMessage(true);
        setMessage({ add: "recommandation was added successfully." });
        onCancel();
        setTimeout(() => setShowMessage(false), 3000);
        props.addRecommandation(response.data);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const updateRecommandation = (recom) => {
    let _newRecommandation = {
      index: recom.index,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      post_id: selectedOptionPost.value === 0 ? null : selectedOptionPost.value,
      member_id: recom.member_id,
    };
    let Recommandations = [];
    Recommandations = props.medecin.Recommandations.filter(
      (item) => item.index !== _newRecommandation.index,
    );
    let exist = Recommandations.some(
      (item) =>
        moment(_newRecommandation.startDate).isBetween(
          moment(item.startDate).format("YYYY-MM-DD"),
          moment(item.endDate).format("YYYY-MM-DD"),
          undefined,
          "(]",
        ) ||
        moment(_newRecommandation.endDate).isBetween(
          moment(item.startDate).format("YYYY-MM-DD"),
          moment(item.endDate).format("YYYY-MM-DD"),
          undefined,
          "[)",
        ) ||
        (moment(item.startDate).isSameOrAfter(
          moment(_newRecommandation.startDate).format("YYYY-MM-DD"),
        ) &&
          moment(item.endDate).isSameOrAfter(
            moment(_newRecommandation.startDate).format("YYYY-MM-DD"),
          ) &&
          moment(item.startDate).isSameOrBefore(
            moment(_newRecommandation.endDate).format("YYYY-MM-DD"),
          ) &&
          moment(item.endDate).isSameOrBefore(
            moment(_newRecommandation.endDate).format("YYYY-MM-DD"),
          )),
    );

    if (exist) {
      setShowError(true);
      setError({ date: "veuillez choisir d'autres dates" });
      return;
    }

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_RECOMMANDATION}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },

      data: _newRecommandation,
    })
      .then((response) => {
        setShowMessage(true);
        setMessage({ edit: "the recommendation was successfully modified." });
        setTimeout(() => setShowMessage(false), 3000);
        onCancel();
        props.editRecom(response.data);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const onCancel = () => {
    setEndDate(null);
    setStartDate(null);
    setSelectedOptionPost(optionsPosts[0]);
  };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={hidePopup} ariaHideApp={false} style={customStyles}>
      <div className="popup">
        <div className="d-flex justify-content-end">
          <button onClick={hidePopup} className="btn-close">
            X
          </button>
        </div>
        <div
          className="message"
          style={{
            marginTop: 20,
            marginBottom: 20,
            height: "50px",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showError &&
            error &&
            Object.entries(error).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        <form onSubmit={addRecommandation} className="form-user-new">
          {!add ? (
            <div
              className="text-center"
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                type="button"
                className="action btn-red"
                onClick={() => confirmationDelete(recommandations.index)}
              >
                {t("delete")}{" "}
              </a>

              <a
                type="button"
                className="action"
                onClick={() => updateRecommandation(recommandations)}
              >
                {t("edit")}
              </a>
            </div>
          ) : (
            <div className="text-center" style={{ marginBottom: "20px" }}>
              <input type="submit" className="action" value={t("add")} />
            </div>
          )}
          <div className="d-flex flex-column mdl">
            <Select
              value={selectedOptionPost}
              className={`selectJ1 form-select ${
                showError && errorBorder.includes("choiceType") ? "error" : ""
              }`}
              isSearchable={false}
              onChange={(selectedOptionPost) => setSelectedOptionPost(selectedOptionPost)}
              options={optionsPosts}
            />
            <div className="d-flex flex-column" style={{ marginTop: 10 }}>
              <DatePicker
                style={{ borderColor: "" }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isClearable
                placeholderText={t("from")}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date(moment(endDate).subtract(0, "day"))}
                required
              />
              <DatePicker
                selected={endDate}
                isClearable
                placeholderText={t("to")}
                onChange={(date) => setEndDate(date)}
                // maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                minDate={new Date(moment(startDate).add(0, "day"))}
                required
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
});
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(PopUpRecommandation);
