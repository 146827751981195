import {
  addDays,
  startOfDay,
  endOfDay,
  differenceInMilliseconds,
  addHours,
  differenceInHours,
  isWithinInterval,
} from "date-fns";
import { Button } from "react-bootstrap";
import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUfLabels } from "../../../../hooks/use-uf-labels";
import { useQuery, useMutation } from "@tanstack/react-query";
import { API } from "../../../../utils/api";
import { useRequest } from "../../../../hooks/use-api";
import { useUfs } from "../../../../hooks/use-ufs";
import { Capitalize } from "../../../../utils/functions";
import { useNow } from "../../../../hooks/use-now";
import { AccommodationFilters } from "./filters";
import "./style.scss";
import { useSelector } from "react-redux";
import { p2pStatus } from "../../../../utils/p2p-status";
import { bemPatientName } from "../../../../utils/bem-patient-name";
import { Gantt, colorFromSexe, cellFromLos} from "../../../../components/gantt";
import { P2PModal } from "../../../p2p-modal";
import LoadingOverlay from "react-loading-overlay";
import { useFormat } from "../../../../hooks/use-format";
import moment from "moment";
import Select from "react-select";
import { forIn } from "lodash";

export function Accommodation(props) {
  const format = useFormat();
  const { t } = useTranslation(["hospitalisation"]);
  const now = useNow(60 * 60 * 1000);
  const request = useRequest();
  const ufs = useUfs();
  const ufLabels = useUfLabels();
  const [p2pModal, setP2PModal] = useState(null);


  // flitre special qui permette de crée des variant de cette onglet, 0 = no flitre, 1 = annomalie, 2 = lit 0
  const specialFliter=(props.specialFliter!=undefined)?props.specialFliter:0;

  const {
    data: [{ loss, dms }],
    refetch,
  } = useQuery(
    ["bedManager", "accommodation", ...ufs],
    () =>
      // accommodation
      request({
        method: "POST",
        path: API.V5_ACCOMMODATIONS,
        data: {
          ufs,
        },
      }),

    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  


  
  // permette de faire un premier trie des loss, pour pouvoir crée les onglet pour les annmalie et lit 0
  const lossAlter=useMemo(()=>{

    // permette de mettre un special flitre
    switch(specialFliter){
      case 1 :
        return loss.filter((los)=>{
          return(los.lit=="0");
        })
      default:
        return loss;
    }
  },[loss]);


  const restitute = useMutation(async function (data) {
    try {
      if (
        !window.confirm(
          "Le patient ne sera plus considéré comme hébergé et restera dans l'UF d'hébergement jusqu'à la fin de son séjour.",
        )
      ) {
        return;
      }

      const [result] = await request({
        method: "POST",
        path: API.V5_RESTITUTE,
        data,
      });

      if (result?.ok) {
        await refetch();
      } else if (result?.message) {
        window.alert(result.message);
      } else {
        console.error(result);
      }
    } catch (e) {
      window.alert("Une erreur est survenue, veuillez ressayer");
    }
  });

  const iepOptions = useMemo(
    () =>
      Object.fromEntries(
        lossAlter.map((los) => [
          los.iep,
          {
            value: los.iep,
            label: los.bemPatient ? bemPatientName(los.bemPatient) : los.iep,
          },
        ]),
      ),
    [lossAlter],
  );

  const dateOptions = useMemo(
    () =>
      Object.fromEntries(
        Array.from(new Set(lossAlter.map((los) => los.date_entree))).map((date_entree) => {
          let val = moment(date_entree).format("DD/MM/YYYY");
          return [
            val,
            { value: val, label: val},
          ]
      }),
      ),
    [lossAlter],
  );

  const ageOptions = useMemo(
    () =>
      Object.fromEntries(
        Array.from(new Set(lossAlter.map((los) => los.age))).map((age) => [
          age,
          { value: age, label: age },
        ]),
      ),
    [lossAlter],
  );

  const [filters, setFilters] = useState({
    ieps: [],
    ages: [],
    sexes: [],
    groups: [],
    ufs: [],
    dates: [],
  });

  const groupes = useSelector((state) => state.screensHospData.groupes);

  const filteringUfs = useMemo(
    () =>
      filters.ufs.length > 0
        ? filters.ufs
        : Object.entries(groupes).flatMap(function ([groupId, { data }]) {
            if (filters.groups.length > 0 && !filters.groups.includes(groupId)) {
              return [];
            } else {
              return data.map(([id]) => id);
            }
          }),
    [groupes, filters],
  );

  


  const filteredLoss = useMemo(
    () =>
      lossAlter
        // Ieps
        .filter((los) => {
          if (filters.ieps.length === 0) {
            return true;
          }
          if (filters.ieps.includes(los.iep)) {
            return true;
          }
          return false;
        })
        // Ages
        .filter(function (los) {
          if (filters.ages.length === 0) {
            return true;
          }
          if (filters.ages.includes(los.age)) {
            return true;
          }
          return false;
        })
        // Sexe
        .filter(function (los) {
          if (filters.sexes.length === 0) {
            return true;
          }
          if (filters.sexes.includes(los.sexe)) {
            return true;
          }
          return false;
        })
        // Date
        .filter(function (los) {
          if (filters.dates.length === 0) {
            return true;
          }

          if (filters.dates.includes( moment(los.date_entree).format("DD/MM/YYYY"))) {
            return true;
          }
          return false;
        })
        // Groups & Ieps
        .filter((los) => filteringUfs.includes(los.uf.toString())),
    [lossAlter, filters, filteringUfs],
  );


  // sort loss
  filteredLoss.sort((a,b)=>{
    return moment(b.date_entree).isBefore(moment(a.date_entree));
  })
  .sort((a,b)=>{
    return a.uf-b.uf;
  });

  
  const from = startOfDay(addDays(now, -1));
  const to = endOfDay(addDays(now, 8));

  const rows=useMemo(()=>
    filteredLoss.map(function (los) {
      const cell = cellFromLos(los, {
        now,
        t,
        ufLabels,
        ufDms: dms,
        format,
      });
      const idealUf = (los.BedDispatchIntermediate?.IdealBed?.uf ?? los.uf_admin).toString();
      const idealBed =
        los.BedDispatchIntermediate?.idealBed && los.BedDispatchIntermediate?.idealRoom
          ? `${los.BedDispatchIntermediate.idealBed}/${los.BedDispatchIntermediate.idealRoom}`
          : "-/-";

      const status = p2pStatus(los);

      return {
        key: los.iep,
        data: {
          sector: los.Beds.secteur ?? "-",
          uf: los.uf,
          bed:
            los.Beds?.lit && los.Beds?.chambre
              ? `${los.Beds.lit}/${los.Beds.chambre}`
              : "-/-",
          origin: los.ModeEntree?.description,
          idealUf,
          idealBed,
        },
        cells: [
          {
            ...cell,
            tooltip: {
              ...(cell?.tooltip ?? {}),
              event: "mouseover-delayed",
              actions: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    //disabled={Boolean(status)}
                    variant={"primary"}//!status ? "primary" : "secondary"}
                    onClick={() =>{
                      setP2PModal({
                        iep: los.iep,
                        bemPatient: los.bemPatient,
                        age: los.age,
                        sexe: los.sexe,
                        uf: los.uf,
                        idealUf,
                        idealBed,
                        poid:los.poid,
                        particularites:los.branca_particularites,
                        precautions:los.branca_precautions,
                        type:los.branca_type
                      });
                    }
                    }
                  >
                    {t("Transport")}
                  </Button>
                  <Button
                    //disabled={Boolean(status)}
                    variant="link"
                    onClick={() => restitute.mutate({ iep: los.iep })}
                  >
                    {t("Restitution")}
                  </Button>
                </div>
              ),
            },
          },
        ],
      };
    }
  ),[filteredLoss]);

  
  const anomalies =
  // Each row
  rows
    .flatMap((row) =>
      // Each cells of rows
      row.cells.flatMap(function (cell, _, cells) {
        // For each cell, compare it with other cells of the row
        return cells.flatMap(function (subcell) {
          // We don't compare the same cell
          if (cell.iep === subcell.iep) {
            return [];
          }

          const subCellInterval = { start: subcell.from, end: subcell.to };
          if (
            isWithinInterval(cell.from, subCellInterval) ||
            isWithinInterval(cell.to, subCellInterval)
          ) {
            return [[cell.key, "Ce séjour chevauche un autre séjour"]];
          }

          // isWithinInterval(now, { start: cell.from, end: cell.to })
          return [];
        });
      }),
    )
    .reduce(function (acc, [key, error]) {
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(error);

      return acc;
    }, {});

  return (
    <div className="accommodation">
      <AccommodationFilters
        filters={filters}
        setFilters={setFilters}
        iepOptions={iepOptions}
        ageOptions={ageOptions}
        dateOption={dateOptions}
      />
      <LoadingOverlay active={restitute.isLoading} spinner>
        <Gantt
          now={now}
          from={from}
          to={to}
          columns={{
            sector: "UF",
            uf: "#UF",
            bed: "Lit",
            origin: "Provenance",
            idealUf: "UF idéale",
            idealBed: "Lit idéal",
          }}
          rows={rows}
        />
      </LoadingOverlay>
      {p2pModal ? (
        <P2PModal
          open
          patient={{
            iep: p2pModal.iep,
            bemPatient: p2pModal.bemPatient,
            age: p2pModal.age,
            sex: p2pModal.sexe,
            originUf: p2pModal.uf,
          }}
          defaultValues={{
            destination: p2pModal.idealUf,
            bed: p2pModal.idealBed,
            poid:p2pModal.poid,
            particularites:p2pModal.particularites,
            precautions:p2pModal.precautions,
            type:p2pModal.type
          }}
          onClose={() => setP2PModal(null)}
          refresh={() => refetch()}
        />
      ) : null}
    </div>
  );
}
