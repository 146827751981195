import React, { useState, useEffect } from "react";
import "./index.css";

import { bindActionCreators } from "redux";
import Bounce from "react-reveal/Bounce";
import Jump from "react-reveal/Jump";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as Permission from "../../utils/permissions";
import LoadingOverlay from "react-loading-overlay";
import { Capitalize } from "../../utils/functions";

const ScreenLogin = (props) => {
  let history = useHistory();

  useEffect(() => {
    if (props.activeUser.isAuthenticated === true) {
      const roles = props.activeUser.user.group && props.activeUser.user.group.Roles;
      const screenDefault = props.activeUser.user.screen_default;

      if (screenDefault === null || screenDefault === "") {
        if (Permission.hasAtLeastOneRole(Permission.roles["emergency"], roles)) {
          history.push({ pathname: "/Emergency" });
        } else if (Permission.hasRole("DEMO GLOBAL", roles)) {
          history.push({ pathname: "/Demo" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["journey"], roles)) {
          history.push({ pathname: "/Journey" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["hospitalisation"], roles)) {
          history.push({ pathname: "/Hospitalisation" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["cockpit"], roles)) {
          history.push({ pathname: "/Cockpit" });
        } else if (Permission.hasAtLeastOneRole(Permission.roles["rh"], roles)) {
          history.push({ pathname: "/Rh" });
        } else {
          history.push("/");
        }
      } else {
        if (Permission.hasRole(screenDefault.toUpperCase(), roles)) {
          history.push({
            pathname: "/" + Capitalize(screenDefault.split(" ")[0]),
            state: "screen " + screenDefault,
          });
        } else {
          history.push({ pathname: "/" });
        }
      }
    }
  }, [props.activeUser, history]);

  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };
  const handleSelect = (e) => {
    changeLanguage(e.target.value);
  };

  const [local] = useState(i18n.language);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("Invalid credentials");
  const [showError, setShowError] = useState(false);
  const [load, setLoad] = useState(false);

  const login = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    props.loginUser({ email, password }, history).catch((error) => {
      if (error.response && error.response.status !== "400") {
        setError(error.response.data.errors);
      } else {
        console.log(error);
        setError("we are sorry, but an error has occurred");
      }
      setLoad(false);
      setShowError(true);
    });
  };

  return (
    <Bounce top opposite cascade>
      <div className="wrapper fadeInDown ">
        <div id="formContent">
          <LoadingOverlay active={load} spinner>
            <div className="fadeIn first ">
              <div className="col-xs-2 col-xs-offset-10">
                <div className="form-group form-select">
                  <select
                    onChange={handleSelect}
                    defaultValue={local}
                    className="form-control"
                    id="select_local"
                  >
                    <option value="fr">{t("fr")}</option>
                    <option value="en">{t("en")}</option>
                    <option value="de">{t("de")}</option>
                    <option value="it">{t("it")}</option>
                  </select>
                </div>
              </div>
              <Jump duration={1500}>
                <div className="row">
                  <h2 id="title" alt="User Icon">
                    <strong>SANIIA</strong>
                  </h2>
                </div>
              </Jump>
            </div>
            <Jump duration={1500}>
              <form onSubmit={login}>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  id="login"
                  className="fadeIn form-control customInput"
                  name="_username"
                  placeholder={t("email")}
                />

                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  className="fadeIn form-control customInput"
                  name="_password"
                  placeholder={t("password")}
                />
                {showError && <div className="errors">{t(error)} </div>}

                <input
                  type="submit"
                  disabled={email !== "" && password !== "" ? false : true}
                  className="fadeIn fourth btn"
                  value={t("login")}
                />
              </form>
            </Jump>
          </LoadingOverlay>
        </div>
      </div>
    </Bounce>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ loginUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenLogin);
