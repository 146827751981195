import React, { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import { trimEnd } from "lodash";
import { chordTranspose, contourDensity } from "d3";

const ScreenTimeTable = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [load, setLoad] = useState(false);
  const [myRecommandationsData, setMyRecommandationsData] = useState([]);
  const [recommandationsData, setRecommandationsData] = useState([]);
  const [filterRecommandationData, setFilterRecommandationData] = useState([]);
  const [optionsDoctors, setoptionsDoctors] = useState([]);
  const [selectedOption, setoptionsDoctorselected] = useState({
    value: props.member_id,
    label: t("all doctors"),
  });
  const [dateToday, setDateToday] = useState(moment().format("YYYY-MM-DD"));
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("isoWeek"));
  const [startMonth, setStartMonth] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endMonth, setEndMonth] = useState(moment(dateToday).endOf("month").format("YYYY-MM-DD"));
  const [daysofMonth, setDayOfMonth] = useState(null);
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  function fillterData(data, startDate, endDate, id) {
    let data_alt = [];
    let isFinish = false;
    let start = moment(startDate).startOf("week").subtract(1, "d");
    let end = moment(startDate).endOf("week").add(1, "d");
    let i = 0;
    let testData = [];
    while (!isFinish) {
      let data_filtrer = data.filter((element) => {
        return moment(element.startDate).isAfter(start) && moment(element.endDate).isBefore(end);
      });
      // find max iterator
      let max_iter = 0;
      for (const iterator of data_filtrer) {
        max_iter = iterator.iteration > max_iter ? iterator.iteration : max_iter;
      }
      for (const iterator of data) {
        //if(iterator.member_id==id && iterator.startDate=="2022-11-13T23:00:00.000Z")testData.push(iterator);
        if (iterator.iteration == max_iter && iterator.member_id == id) data_alt.push(iterator);
      }
      i++;
      isFinish = end.isAfter(moment(endDate));
      start.add(1, "w");
      end.add(1, "w");
    }
    return data_alt;
  }

  useEffect(() => {
    setLoad(false);
    let start = moment(dateToday).startOf("month").startOf("week").add(1, "d").format("YYYY-MM-DD");
    let end = moment(dateToday)
      .endOf("month")
      .subtract(1, "d")
      .endOf("week")
      .add(1, "d")
      .format("YYYY-MM-DD");
    setStartMonth(moment(dateToday).startOf("month").format("YYYY-MM-DD"));
    setEndMonth(moment(dateToday).endOf("month").format("YYYY-MM-DD"));
    let member_id = selectedOption.value;
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLMYRECOMMANDATIONS}`,
        {
          startDate: new Date(start),
          endDate: new Date(end),
          index: member_id,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let data = fillterData(res.data, start, end, member_id);
        loadingData(data);
        setMyRecommandationsData(data);
        setFilterRecommandationData(data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [dateToday, selectedOption]);

  useEffect(() => {
    axios
      .get(`${API.ENDPOINT + API.RH_ALLMEMBERS}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        setLoad(true);
        loadingOptions(res.data);
      })
      .catch((error) => {
        setLoad(false);
        console.error("There was an error!", error);
      });
  }, []);

  const loadingOptions = (members) => {
    let optionMembers = [];
    members.map((member) => {
      optionMembers.push({
        value: member.index,
        label: member.nom.toUpperCase() + " " + member.prenom.toUpperCase(),
      });
    });

    // sort optionDoctors by nam
    optionMembers.sort((a, b) => a.label.localeCompare(b.label));

    // set base doctor option
    setoptionsDoctorselected(optionMembers[0]);

    setoptionsDoctors(optionMembers);
  };

  useEffect(() => {
    const startDay = moment(dateToday).clone().startOf("month").startOf("week");
    const endDay = moment(dateToday).clone().endOf("month").endOf("week");

    var calendar = [];
    var index = startDay.clone();
    while (index.isBefore(endDay, "day")) {
      calendar.push(
        days.fill(0).map((i) => {
          return {
            day: index.add(1, "day").date(),
            date: index.format("YYYY-MM-DD"),
            disabled: moment(index).isBetween(moment(startMonth), moment(endMonth), undefined, "[]")
              ? false
              : true,
          };
        }),
      );
    }
    if (calendar[calendar.length - 1][0].day === 1) {
      setDayOfMonth(calendar.slice(0, calendar.length - 1));
    } else if (calendar[0][0].day === 2) {
      let days = [];
      for (let i = 6; i >= 0; i--) {
        days = [
          ...days,
          {
            day: moment(startDay).subtract(i, "days").date(),
            date: moment(startDay).subtract(i, "days").format("YYYY-MM-DD"),
            disabled: moment(index).isBetween(moment(startMonth), moment(endMonth), undefined, "[]")
              ? false
              : true,
          },
        ];
      }
      calendar.unshift(days);
      setDayOfMonth(calendar);
    } else {
      setDayOfMonth(calendar);
    }
  }, [dateToday, startMonth, endMonth]);

  const loadingData = (Data) => {
    let recommandationsByMember = {};
    Data.map((r) => {
      console.log(r);
      let date = moment(r.startDate.split("T")[0]).startOf("day").format("YYYY-MM-DD");
      let end = moment(r.endDate).startOf("day").diff(moment(date), "days");
      let i = 0;
      /*
      if (date)
        if (recommandationsByMember["_" + moment(date).format("YYYY-MM-DD")])
          return;
      if (end <= 0) {
        recommandationsByMember["_" + moment(date).format("YYYY-MM-DD")] = r;
      } else {
        while (i <= end) {
          recommandationsByMember["_" + moment(date).format("YYYY-MM-DD")] = r;
          date = moment(date).add(1, "day");
          i++;
        }
      }
      */
      recommandationsByMember["_" + moment(r.startDate).startOf("day").format("YYYY-MM-DD")] = r;
    });
    setRecommandationsData(recommandationsByMember);
  };

  const daysHead = () => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, i];
    }

    return days.map((day) => (
      <th className="header bg-cl" style={{ width: "12%" }}>
        {t(moment(startHeadDate).add(day, "days").format("dddd"))}
      </th>
    ));
  };

  const renderTimeTable = () => {
    if (myRecommandationsData && myRecommandationsData.length >= 1 && load) {
      return (
        <div style={{ overflow: "auto" }}>
          <div className="mrg-t" style={{ marginTop: 10, overflow: "auto" }}>
            <table
              className="table table-scroll table-rec"
              style={{ width: "100%", borderColor: "black" }}
            >
              <thead className="fix-header " style={{ zIndex: 0 }}>
                <tr>
                  <th className="header bg-cl padding-0" style={{ width: "16%" }}></th>
                  {daysHead()}
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          </div>
        </div>
      );
    } else if (myRecommandationsData && myRecommandationsData.length === 0 && load)
      return (
        <div className="d-flex justify-content-center">
          <p className="msg-planning">{t("no schedule set for this month yet")}</p>
        </div>
      );
  };
  const renderList = () => {
    return (
      daysofMonth &&
      daysofMonth.map((month, i) => {
        return (
          <tr key={i}>
            <td className="border-dark border">
              {t("week")}
              {i + 1}
            </td>
            {month.map((m, i) => {
              return recommandationsData &&
                recommandationsData["_" + m.date] &&
                moment(m.date).isBetween(moment(startMonth), moment(endMonth), undefined, "[]") ? (
                <td
                  key={i}
                  className="td-rec"
                  style={{
                    backgroundColor: `${
                      recommandationsData["_" + m.date].Posts &&
                      recommandationsData["_" + m.date].Posts.couleur
                    }`,
                  }}
                >
                  <div className="">
                    <div id="date">
                      {m.date.split("-")[2] + " / "}
                      {t(
                        recommandationsData["_" + m.date].Posts &&
                          recommandationsData["_" + m.date].Posts.abreviation,
                      )}
                    </div>
                    <div id="postName"></div>
                  </div>
                </td>
              ) : m.disabled ? (
                <td key={i} className="td-rec" style={{ backgroundColor: "gray" }} />
              ) : (
                <td key={i} className="td-rec" style={{ backgroundColor: "white" }}>
                  <div className="">
                    <div id="date">{m.date.split("-")[2] + "   "}</div>
                    <div id="postName"></div>
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })
    );
  };

  const beforeMonth = () => {
    let beforeMonth = moment(dateToday).subtract(1, "months");

    setDateToday(beforeMonth);
  };
  const nextMonth = () => {
    let nextMonth = moment(dateToday).add(1, "months");

    setDateToday(nextMonth);
  };
  console.log(moment(dateToday).format("MMMM"));
  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div>
          <div className="col-md-6 col-12" style={{ zIndex: 6, marginTop: "15px" }}>
            <Select
              value={selectedOption}
              className="selectJ1 form-select"
              isSearchable={false}
              onChange={(selectedOption) => setoptionsDoctorselected(selectedOption)}
              options={optionsDoctors}
            />
          </div>
          <div className="w-100 col-12 d-flex justify-content-between" style={{ marginTop: 20 }}>
            <a
              onClick={beforeMonth}
              className="btn btn-user-1 btn-primary d-flex align-items-center align-self-center"
            >
              {" "}
              {t("before month")}
            </a>
            <span className="text-month">
              {t(moment(dateToday).format("MMMM")) + " " + moment(dateToday).format("YYYY")}
            </span>
            <a
              onClick={nextMonth}
              // style={{ marginLeft: 20 }}
              className="btn btn-user-1 btn-primary d-flex align-items-center align-self-center"
            >
              {t("next month")}
            </a>
          </div>
        </div>
        {renderTimeTable()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenTimeTable);
