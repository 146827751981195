import React, { useState, useEffect, Suspense } from "react";

import "./index.css";
import ScreenHospitalisationInProgress from "../screens/screenHospitalisationInProgress";
import AccesDenied from "../../components/not-found/accesDenied";
import { API } from "../../utils/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import ScreenHospitalisationAchieved from "../screens/screenHospitalisationAchieved";
import axios from "axios";
import Select from "react-select";
import ScreenHospitalisationToCome from "../screens/screenHospitalisationToCome";
import ScreenHospitalisationToCome2 from "../screens/screenHospitalisationToCome2";
import * as Permission from "../../utils/permissions";
import moment from "moment";
import ScreenHospitalisationInProgress2 from "../screens/screenHospitalisationInProgress2";
import {getUS,checkUsInclude} from "../../utils/calculUs"
import { result } from "lodash";

const HeaderHopitalisation = (props) => {
  const [screen, setScreen] = useState("");
  const { t, i18n } = useTranslation(["hospitalisation"]);

  const [options, setOptions] = useState([]);
  const [selectedType, setTypeSelected] = useState();
  const [selectedUf, setUfSelected] = useState();
  const [selectedUS, setSelectedUS] = useState([]);
  const [ufType, setUfType] = useState([]);
  const [usType, setUsType] = useState([]);
  const [actualUSList,setActualUSList]=useState(null);
  const [isUSLoaded,setIsUSLoaded]=useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);
  const [first, setFirst] = useState(true);
  const [preadmission, setPreadmission] = useState(null);
  const [preadmission2, setPreadmission2] = useState(null);
  const [Wait, setWait] = useState(false);
  const group = "SERVICE MÉDECINE";
  const redirection = () => {
    let screen = "";
    if (Permission.hasRole("HOSPITALISATION TO COME", props.roles))
      screen = "screen hospitalisation to come";
    // else if (Permission.isSuperAdmin(props.roles))
    //   screen = "screen hospitalisation to come2";
    // else if (Permission.isSuperAdmin(props.roles))
    //   screen = "screen hospitalisation in progress2";
    else if (Permission.hasRole("HOSPITALISATION IN PROGRESS", props.roles))
      screen = "screen hospitalisation in progress";
    else if (Permission.hasRole("HOSPITALISATION ACHIEVED", props.roles))
      screen = "screen hospitalisation achieved";

    setScreen(screen);
  };

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      if (props.location.state === undefined) {
        redirection();
      } else setScreen(props.location.state && props.location.state.hash);
    } else {
      if (
        props.location.state === null ||
        props.location.state === "" ||
        props.location.state === undefined
      )
        redirection();
      else setScreen(props.location.state);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (props.screensHospData.loaded !== true) return;
    if (first) {
      getDefaultValues();
      return;
    }
    getValuesByUf();
  }, [props.screensHospData.loaded]);

  const getDefaultValues = () => {
    let options = [];
    let uf = [];
    let option;
    let optionReserve;
    
    options.push({ value: "ALL", label: t("all") });

    Object.entries(props.screensHospData.groupes).map((item) => {
      if (item[0] === group) {
        option = { value: item[0], label: item[1]["name"] };
      }
      if (!optionReserve) optionReserve = { value: item[0], label: item[1]["name"] };

      options.push({ value: item[0], label: item[1]["name"] });

      item[1].data.map((d) => {
        uf.push({ value: d[0], label: "UF " + d[0] + " - " + d[1] });
      });
    });

    if (option) setTypeSelected(option);
    else setTypeSelected(options[0]);
    setOptions(options);

    setUfType(uf);

    setFirst(false);
  };

  useEffect(() => {
    if (!first) {
      getValuesByUf();
    }
  }, [selectedType, selectedUf]);

  useEffect(() => {
    let uf = [];
    if (first) return;

    props.screensHospData &&
      props.screensHospData.groupes &&
      Object.entries(props.screensHospData.groupes).map((item) => {
        if (selectedType.value=="ALL" || item[0] === selectedType.value) {
          item[1].data.map((d) => {
            uf.push({ value: d[0], label: "UF " + d[0] + " - " + d[1] });
          });
        }
      });
    
      setUfSelected(null);
      setSelectedUS([]);
      setUfType(uf);
      LoadUsType(actualUSList,uf);
  }, [selectedType]);


  /**
   * Add us to the selectable uf
   * @param {*} us array of us to add
   */
  function LoadUsType(us, uflist=null){
    let ufs=(uflist==null)?ufType:uflist;

    let result=[];
    for (let i = 0; i < ufs.length; i++) {
      const x = ufs[i];
      let addUs=getAllusWithUf(us,x.value);
      for (const iterator of addUs) {
        result.push({ value: iterator, label: "\tUS " + iterator.uf + " - " + iterator.secteur });
      }
    }
    setUsType(result);
  }

  function addAllUsForSpecificUF(uf){
    let result=[];
    let addUs=getAllusWithUf(actualUSList,uf);
    for (const iterator of addUs) {
      result.push({ value: iterator, label: "\tUS " + iterator.uf + " - " + iterator.secteur });
    }
    setUfType(result);
  }


  /**
   * Give all the us find in a array with the uf selected
   * @param {*} usList array of us
   * @param {*} uf uf to be found
   * @returns 
   */
  function getAllusWithUf(usList,uf){
    if(usList==null || usList==[])return [];
    let d = [];
    for (let index = 0; index < usList.length; index++) {
      const element = usList[index];
      if(element.uf==uf)d.push(element);
    }
    return d;
  }

  function AddUsList(uss){
    let newUss=(actualUSList!=null)?actualUSList:[];
    for (const iterator of uss) {
      if(!checkUsInclude(newUss,iterator))newUss.push(iterator);
    }
    LoadUsType(newUss,selectedUf);
    setActualUSList(newUss);
    setIsUSLoaded(true);
  }

  const getValuesByUf = () => {
    setData(null);
    setWait(false);
    let UF =
      selectedUf && selectedUf.length > 0
        ? selectedUf.map((item) => {
            return item.value;
          })
        : getUfsByGroup();
    let data = { uf: UF };

    try {
      axios
        .post(
          `${API.ENDPOINT + API.SCREEN_HOSP_BY_UF}`,
          {
            data,
          },
          {
            headers: {
              "x-auth-token": props.activeUSer.user.token,
            },
          },
        )
        .then((res) => {
          let data=res.data;
          setData(data);
          if(!isUSLoaded){
            let us=[];
            us=us.concat(getUS(data.bedDispatching));
            us=us.concat(getUS(data.bedDispatching2));
            us=us.concat(getUS(data.losOfEmergencies));
            us=us.concat(getUS(data.losUfAdmision));
            us=us.concat(getUS(data.todayLoss));

            let resultUs=[];
            for (const x of us) {
              if(!checkUsInclude(resultUs,x))resultUs.push(x);
            }
            AddUsList(resultUs);
          }
        })
        .catch((err) => {
          // console.log('err:',err)
          setLoad(false);
        });
    } catch (e) {
      // console.log('=====error:',e)
    }
  };

  useEffect(() => {
    if (data === null) return;
    if (
      data["losOfEmergencies"].length === 0 &&
      data["losOfEmergenciesFree"].length === 0 &&
      data["lossEnded"].length === 0 &&
      (data["globalData"]["diag"].length > 0 ||
        data["globalData"]["secteur"].length > 0 ||
        data["globalData"]["typeSortie"].length > 0 ||
        data["globalData"]["typeEntree"].length > 0)
    ) {
      setWait(true);
    } else {
      setWait(false);
    }
    let preAdm = {};
    data["bedDispatching"].map((item) => {
      if (moment().add("7", "days").diff(moment(item["date_in"])) > 0) {
        if (!preAdm[item.lit + item.uf]) {
          preAdm[item.lit + item.uf] = [item];
        } else {
          preAdm[item.lit + item.uf] = [...preAdm[item.lit + item.uf], item];
        }
      }
    });
    let preAdm2 = {};
    data["bedDispatching2"].map((item) => {
      if (moment().add("7", "days").diff(moment(item["date_in"])) > 0) {
        if (!preAdm2[item.lit + item.uf]) {
          preAdm2[item.lit + item.uf] = [item];
        } else {
          preAdm2[item.lit + item.uf] = [...preAdm2[item.lit + item.uf], item];
        }
      }
    });

    setPreadmission(preAdm);
    setPreadmission2(preAdm2);
  }, [data]);

  const getUfsByGroup = () => {
    let groups = selectedType ? selectedType.value : group;
    let uf = [];
    console.log(groups);

    Object.entries(props.screensHospData.groupes).map((item) => {
      if (groups=="ALL" || item[0] === groups) {
        item[1].data.map((d) => {
          uf.push(d[0]);
        });
      }
    });
    return uf;
  };
  const resetUf = () => {
    setUfSelected(null);
  };

  const renderFilters = () => {
    return (
      <div className="row">
        <div className="col-6 col-lg-2 mobile-display" style={{ zIndex: 8, marginTop: 50 }}>
          <Select
            value={selectedType}
            className="selectJ1"
            isSearchable={false}
            isDisabled={!isUSLoaded}
            onChange={(selectedOption) => setTypeSelected(selectedOption)}
            options={options}
          />
        </div>
        <div className="col-6 col-lg-3 mobile-display ufSelection" style={{ zIndex: 8, marginTop: 50 }}>
              <Select
                value={selectedUf}
                className="selectJ1"
                isSearchable={true}
                isMulti={true}
                isDisabled={!isUSLoaded}
                styles={{ fontSize: 20 }}
                placeholder={t("uf")}
                onChange={(selectedOption) => {
                  setUfSelected(selectedOption);
                  if(selectedOption==null){
                    setSelectedUS(null);
                  }
                  else if(selectedUS!=null){
                    let us=[];
                    for (const x of selectedUS) {
                      for (const y of selectedOption) {
                        if(x.value.uf==y.value){
                          us.push(x);
                        }
                      } 
                    }
                    setSelectedUS(us);
                  }
                  LoadUsType(actualUSList,selectedOption)
                }}
                options={ufType}
              />
            </div>
            <div className="col-6 col-lg-3 mobile-display ufSelection" style={{ zIndex: 8, marginTop: 50 }}>
              <Select
                value={selectedUS}
                className="selectJ1"
                isSearchable={true}
                isMulti={true}
                isDisabled={!isUSLoaded}
                styles={{ fontSize: 20 }}
                placeholder={t("us")}
                onChange={(selectedOption) => {
                  setSelectedUS(selectedOption);
                  let ufSelect=[];
                  if(selectedUf==null && selectedOption!=null){
                    //ufSelect.push({value: selectedOption[0].value.uf, label: selectedOption[0].label });
                    console.log(ufType);
                    for (let index = 0; index < ufType.length; index++) {
                      let el = ufType[index];
                      if(el.value==selectedOption[0].value.uf){
                        setUfSelected([el]);
                        LoadUsType(actualUSList,[el]);
                        return;
                      }
                    }
                    
                  }
                  /*
                  let uss=[];
                  if(selectedOption==null){
                    setSelectedUS([]);
                    return; 
                  }
                  for (let index = 0; index < selectedOption.length; index++) {
                    const element = selectedOption[index];
                    if(element.value.secteur!=undefined){
                      //uss.push(element.value.secteur);
                      ufSelect.push({value: element.value.uf, label: element.label });
                    }
                    else{
                      ufSelect.push(element);
                    }
                  }*/
                }}
                options={usType}
              />
                        {/*!isUSLoaded?<div className="loadingUsAnimation">{/*<ReactLoading className="" type={"bars"} color="#2fa2d9" /> }</div>:null*/}
            </div>
       
        <div className="col-6 col-lg-1 " style={{ marginTop: 50 }}>
          <button
            onClick={resetUf}
            style={{ width: "100px", height: "40px" }}
            className="btn btn-light"
          >
            {t("reset")}
          </button>
        </div>
      </div>
    );
  };

  const renderScreen = (name = "") => {
    if (data && Object.keys(data).length > 0 && options.length > 0) {
      let useName = name == "" ? screen : name;
      switch (useName) {
        case "screen hospitalisation to come":
          return (
            <ScreenHospitalisationToCome
              group={selectedType.value.trim().replace(" ", "_")}
              data={data}
              screensData={props.screensData}
              screensHospData={props.screensHospData}
              type={selectedType}
              uf={selectedUf}
              us={selectedUS}
            />
          );
        case "screen hospitalisation to come2":
          return (
            <ScreenHospitalisationToCome2
              group={selectedType.value.trim().replace(" ", "_")}
              data={data}
              screensData={props.screensData}
              screensHospData={props.screensHospData}
            />
          );
        case "screen hospitalisation in progress":
          return (
            <ScreenHospitalisationInProgress
              getValuesByUf={getValuesByUf}
              Wait={Wait}
              preAdm={preadmission}
              data={data}
              type={selectedType}
              uf={selectedUf}
              us={selectedUS}
              AddUs={AddUsList}
              SetUsLoading={setIsUSLoaded}
            />
          );
        case "screen hospitalisation in progress2":
          return (
            <ScreenHospitalisationInProgress2
              getValuesByUf={getValuesByUf}
              Wait={Wait}
              preAdm={preadmission2}
              data={data}
            />
          );
        case "screen hospitalisation achieved":
          return (
            <ScreenHospitalisationAchieved
              getValuesByUf={getValuesByUf}
              Wait={Wait}
              activeUSer={props.activeUSer}
              type={
                selectedType &&
                selectedType.value === group &&
                (!selectedUf || selectedUf.length === 0)
              }
              uf={
                selectedUf && selectedUf.length > 0
                  ? selectedUf.map((item) => {
                      return item.value;
                    })
                  : getUfsByGroup()
              }
              us={selectedUS}
              data={data}
            />
          );
        default:
          return null;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  if (
    Permission.hasRole("HOSPITALISATION TO COME", props.roles) ||
    Permission.hasRole("HOSPITALISATION IN PROGRESS", props.roles) ||
    Permission.hasRole("HOSPITALISATION ACHIEVED", props.roles)
  ) {
    return props.setScreen != undefined ? (
      <div>
        {props.screensHospData.loaded && renderFilters()}
        {renderScreen(props.setScreen)}
      </div>
    ) : (
      <div className="main-screen">
        <h2 className="h2">{t("hospitalisation")}</h2>
        <ul className="nav nav-tabs">
          {Permission.hasRole("HOSPITALISATION TO COME", props.roles) && (
            <li className="nav-item">
              <a
                type="button"
                onClick={() => setScreen("screen hospitalisation to come")}
                className={`nav-link ${
                  screen === "screen hospitalisation to come" ? "active disabled" : ""
                }`}
                href="#"
              >
                {t("coming soon").toUpperCase()}
              </a>
            </li>
          )}
          {/* {Permission.hasRole("HOSPITALISATION TO COME2", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen hospitalisation to come2")}
                className={`nav-link ${
                  screen === "screen hospitalisation to come2"
                    ? "active disabled"
                    : ""
                }`}
                href="#"
              >
                {t("coming soon 2").toUpperCase()}
              </a>
            </li>
          )}{" "} */}
          {Permission.hasRole("HOSPITALISATION IN PROGRESS", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen hospitalisation in progress")}
                className={`nav-link ${
                  screen === "screen hospitalisation in progress" ? "active disabled" : ""
                }`}
                href="#"
              >
                {t("in progress").toUpperCase()}
              </a>
            </li>
          )}
          {/* {Permission.hasRole("HOSPITALISATION IN PROGRESS2", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen hospitalisation in progress2")}
                className={`nav-link ${
                  screen === "screen hospitalisation in progress2"
                    ? "active disabled"
                    : ""
                }`}
                href="#"
              >
                {t("in progress 2").toUpperCase()}
              </a>
            </li>
          )} */}
          {Permission.hasRole("HOSPITALISATION ACHIEVED", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen hospitalisation achieved")}
                className={`nav-link ${
                  screen === "screen hospitalisation achieved" ? "active disabled" : ""
                }`}
                href="#"
              >
                {t("achieved").toUpperCase()}
              </a>
            </li>
          )}
        </ul>
        {props.screensHospData.loaded && renderFilters()}
        <Suspense
          fallback={
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 250,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          }
        >
          {renderScreen()}
        </Suspense>
      </div>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensHospData: state.screensHospData,
  screensData: state.screensEmergData,
  mlcLog: state.mlcLog,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderHopitalisation);
