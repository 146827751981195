import { USER_T } from "./action-user-types";
import { API } from "../../utils/api";
import axios from "axios";
import * as Permission from "../../utils/permissions";
import { Capitalize, dateLogout } from "../../utils/functions";

export function loginUser(user, history) {

  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = API.ENDPOINT + API.LOGIN;

      axios({
        method: "POST",
        url: endpoint,
        data: { email: user.email.toLowerCase(), password: user.password },
      })
        .then(function (response) {
          dispatch({
            type: USER_T.LOGIN,
            payload: { ...response.data, dateLogout, dateLogin: new Date() },
          });
          //history.push("/")
        })
        .catch(function (error) {
          reject(error);
          console.log(error);
        });
    });
  };
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch({ type: USER_T.UPDATED, payload: user });
  };
}

export function getUsers(token) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(API.ENDPOINT + API.USER_ALLUSERS, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((response) => {
          dispatch({ type: USER_T.READ_ALL, payload: response.data });
        })
        .catch((err) => {
          console.log("erreuuur:", err);
        });
    });
  };
}

export function deleteUser(id, user, list) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = API.ENDPOINT + API.USER_DELETEUSER;
      axios({
        method: "POST",
        url: endpoint,
        data: { id },
        headers: {
          "x-auth-token": user.token,
        },
      })
        .then(function (response) {
          let data = list.filter((u) => u.id !== id);
          dispatch({ type: USER_T.READ_ALL, payload: data });
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
          console.log(error);
        });
    });
  };
}

export function changePassword(values, user, history) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = API.ENDPOINT + API.USER_CHANGE_PASSWORD;
      axios({
        method: "POST",
        url: endpoint,
        data: { password: values.password, repeatPassword: values.repeatPassword, id: user.id },
        headers: {
          "x-auth-token": user.token,
        },
      })
        .then(function (response) {
          user.firstlogin = response.data.first_login;
          dispatch({ type: USER_T.LOGIN, payload: { user, dateLogout: dateLogout } });
          //history.push('/');
        })
        .catch(function (error) {
          reject(error);
          console.log(error);
        });
    });
  };
}

export function addUser(values, userActive, history) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = API.ENDPOINT + API.USER_USER;
      let user = {
        email: values.email,
        password: values.password,
        repeatPassword: values.repeatPassword,
        screen_default: values.screen_default.toLowerCase(),
        id_group: values.id_group,
      };
      axios({
        method: "POST",
        url: endpoint,
        data: { user },
        headers: {
          "x-auth-token": userActive.user.token,
        },
      })
        .then(function (response) {
          history.push("/users");
        })
        .catch(function (error) {
          reject(error);
          // console.log("error:",error);
        });
    });
  };
}

export function editUser(values, id, userActive, history) {
  console.log(values);
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      let email = values.email.toLowerCase();
      console.log(email);
      let user = {
        email: email,
        screen_default: values.screen_default.toLowerCase(),
        id_group: values.id_group,
        active: values.active,
      };
      if (values.password != "") user = { ...values };

      console.log("j");
      axios
        .post(
          `${API.ENDPOINT + API.USER_EDITUSER}`,
          {
            id: id,
            user: user,
          },
          {
            headers: {
              "x-auth-token": userActive.user.token,
            },
          },
        )
        .then((response) => {
          //dispatch({ type : USER_T.LOGIN , payload : {...response.data, dateLogout } })
          console.log(response);
          history.push("/users");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      /*
   axios({
     method : 'POST' , url: endpoint , data : {user ,id} ,
     headers: {
      'x-auth-token': userActive.user.token
    }
   }).then(function (response) {
    dispatch({ type : USER_T.LOGIN , payload : {...response.data, dateLogout } }) 
    history.push('/users');
  })
  .catch(function (error) {
    reject(error)
    console.log("error:",error);

  });*/
    });
  };
}

export function editProfil(values, userActive, history) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = API.ENDPOINT + API.USER_EDITPROFIL;
      let email = values.email.toLowerCase();
      console.log(email);
      let user = { email: values.email, screen_default: values.screen_default.toLowerCase() };
      if (values.password !== "") user = { ...values };

      axios({
        method: "PUT",
        url: endpoint,
        data: { user },
        headers: {
          "x-auth-token": userActive.user.token,
        },
      })
        .then(function (response) {
          dispatch({ type: USER_T.LOGIN, payload: { ...response.data, dateLogout } });

          let screenDefault = response.data.user && response.data.user.screen_default;
          let roles =
            response.data.user && response.data.user.group && response.data.user.group.Roles;

          if (Permission.hasRole(screenDefault.toUpperCase(), roles)) {
            history.push({
              pathname: "/" + Capitalize(screenDefault.split(" ")[0]),
              state: "screen " + screenDefault,
            });
          }
        })

        .catch(function (error) {
          reject(error);
          console.log("error:", error);
        });
    });
  };
}

export function logoutUser(history = null, push = true) {
  return function (dispatch) {
    dispatch({ type: USER_T.LOGOUT });
    dispatch({ type: "RESET" });
    if (push) history.push("/login");
  };
}

export function setErrorSocket(user, isErrorSocket) {
  return function (dispatch) {
    user.isErrorSocket = isErrorSocket;
    dispatch({ type: USER_T.SETERROR, payload: user });
  };
}
