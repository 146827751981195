import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import moment from "moment/min/moment-with-locales";
import { ImCheckmark, ImArrowRight } from "react-icons/im";
import { FaBed } from "react-icons/fa";
import arrow_right from "../../../images/send.png";
import { Capitalize } from "../../../utils/functions";
import ReactTooltip from "react-tooltip";

const ScreenDemoTransit = (props) => {
  const { t, i18n } = useTranslation(["journey"]);

  const [startHeadDate, setStartHeadDate] = useState(
    moment("2021-05-28 14:36").subtract(1, "days").startOf("day"),
  );
  const nb = 100 / 8;

  const renderDates = () => {
    let days = [];
    for (let i = 0; i < 8; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <span className="date txt-font" key={i}>
        {moment(startHeadDate).add(day, "days").format("DD.MM")}{" "}
      </span>
    ));
  };

  const renderEmergencies = (transit) => {
    if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) / 1440) *
      nb;
    let width =
      (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
        1440) *
      nb;
    let Los = parseInt(
      moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "hours"),
    );
    let devDeg = Math.abs(Los - transit.los_dev_hours) / (Los + transit.los_dev_hours);
    let width_dev = width + transit.los_dev * nb;

    return (
      <>
        <div
          className={`predicted-los-wrapper-dev b-radius text-font`}
          style={{
            background: `linear-gradient(to right, ${transit.sex == "M" ? "green" : "#2fa2d9"}, ${
              devDeg * 100
            }%,rgba(0,0,0,0))`,
            marginLeft: `${startLeft}%`,
            width: `${width_dev}%`,
          }}
        ></div>
        <div
          className={`predicted-los-wrapper padding-0 text-font`}
          style={{
            background: "transparent",
            border: "none",
            marginLeft: startLeft + `%`,
            width: `${100}%`,
          }}
        >
          {transit.ideal_uf_name}
        </div>
        <div
          data-tip
          data-for={`tooltip-${transit.iep}`}
          className={`predicted-los-wrapper b-radius text-font`}
          style={{ marginLeft: `${startLeft}%`, width: `${width}%` }}
        />
        {setPreAdmTooltip(transit)}
      </>
    );
  };

  const setLOSTooltip = (los) => {
    return (
      <ReactTooltip id={`tooltip-${los["iep"]}`} place="bottom" effect="solid">
        <ul className="tooltip-list">
          <li style={{ color: "white" }}>{`${t("iep")} : ${los.iep} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>{`${t("sex")} : ${t(los.sexe)}`} </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${los.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>{`${t("origin")} : ${los.ModeEntree.description} `} </li>
          <li style={{ color: "white" }}>{`${t("diagnostic code")} : ${
            los.diag !== null && los.diag !== "" ? los.diag : "-"
          }`}</li>
          <li style={{ color: "white" }}>{`${t("sector")} : ${los["Beds"][0].secteur}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${los["Beds"][0].lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(los["date_entree"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted release date")} : ${moment(los["date_sortie_predite"]).format(
              "DD.MM.YY A",
            )}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted duration")} : ${Math.round(los["los_moy"])} ${t("days")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of error`)} : +/- ${los.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderLosPred = (transit) => {
    if (parseInt(moment.utc(transit["date_entree"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_entree"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_entree"]).diff(moment.utc(startHeadDate), "minutes")) /
        1440) *
      nb;
    let width =
      (parseInt(
        moment
          .utc(transit["date_sortie_predite"])
          .diff(moment.utc(transit["date_entree"]), "minutes"),
      ) /
        1440) *
      nb;
    let LoS = parseInt(
      moment.utc(transit["date_sortie_predite"]).diff(moment.utc(transit["date_entree"]), "hours"),
    );
    let devDeg = (LoS - transit.los_dev * 24) / (LoS + transit.los_dev * 24);
    let width_dev = width + transit.los_dev * nb;
    return (
      <>
        <div
          className={`predicted-los-wrapper padding-0 text-font`}
          style={{
            background: `linear-gradient(to right, ${transit.sex == "M" ? "green" : "#2fa2d9"}, ${
              devDeg * 100
            }%,rgba(0,0,0,0))`,
            border: "none",
            marginLeft: `${startLeft}%`,
            width: `${width_dev}%`,
          }}
        >
          {transit.iep}
        </div>
        <div
          data-tip
          data-for={`tooltip-${transit.iep}`}
          className="predicted-los-wrapper b-radius text-font b-solid"
          style={{
            backgroundColor: "transparent",
            paddingLeft: 5,
            marginLeft: startLeft + "%",
            width: `${width}%`,
          }}
        />

        {setLOSTooltip(transit)}
      </>
    );
  };

  const renderPredAdm = (transits, iep) => {
    return transits.map((transit) => {
      if (transit.hebergement_flag != 0 || transit.iep == iep) return;
      if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
        transit["date_in"] = moment.utc(startHeadDate);
      }
      let startLeft =
        (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) /
          1440) *
        nb;
      let width =
        (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
          1440) *
        nb;

      return (
        <>
          <div
            data-tip
            data-for={`tooltip-${transit.iep}`}
            className={`predicted-los-wrapper b-radius b-dahed text-font`}
            style={{
              backgroundColor: "white",
              paddingLeft: 5,
              marginLeft: `${startLeft}%`,
              width: `${width}%`,
            }}
          >
            {transit.iep}
          </div>
          {setPreAdmTooltip(transit)}
        </>
      );
    });
  };

  const renderPassageEmergencies = (transit) => {
    if (parseInt(moment.utc(transit["date_out_urg"]).diff(moment.utc(startHeadDate))) < 0) return;

    if (parseInt(moment.utc(transit["date_in_urg"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in_urg"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in_urg"]).diff(moment.utc(startHeadDate), "minutes")) /
        1440) *
      nb;
    let width =
      (parseInt(
        moment.utc(transit["date_out_urg"]).diff(moment.utc(transit["date_in_urg"]), "minutes"),
      ) /
        1440) *
      nb;

    return (
      <li
        style={{ gridColumn: `1/15`, display: "flex" }}
        data-tip
        data-for={`tooltip-${transit.iep}-2`}
      >
        <div
          className={`real-los-wrapper b-radius text-font `}
          style={{
            backgroundColor: "rgb(237 57 255)",
            border: "solid 2px black",
            marginLeft: startLeft + `%`,
            width: `${width}%`,
          }}
        />

        <div
          className={`predicted-los-wrapper b-radius text-font `}
          style={{
            marginLeft: startLeft + `%`,
            width: `${100}%`,
            border: "none",
          }}
        >
          {transit.group4_flag == 1 ? t("urg g4").toUpperCase() : t("urg").toUpperCase()}
        </div>
        <img
          src={arrow_right}
          style={{ marginLeft: `${startLeft + width}%` }}
          className="icon-arrow"
        />
      </li>
    );
  };
  const renderHebergement = (transit, withoutStep2 = false) => {
    if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) / 1440) *
      nb;
    let width =
      (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
        1440) *
      nb;

    return (
      <li
        style={{ gridColumn: `1/15`, display: "flex" }}
        data-tip
        data-for={`tooltip-${transit.iep}-2`}
      >
        <div
          className={`real-los-wrapper b-radius text-font `}
          style={{
            background: "orange",
            marginLeft: startLeft + `%`,
            width: `${width}%`,
          }}
        ></div>
        <div
          className={`predicted-los-wrapper padding-0 text-font`}
          style={{
            background: "transparent",
            border: "none",
            marginLeft: startLeft + `%`,
            width: `${100}%`,
          }}
        >
          {transit.ideal_uf_name}
        </div>
        {!withoutStep2 && (
          <img
            src={arrow_right}
            style={{ marginLeft: `${startLeft + width}%` }}
            className="icon-arrow"
          />
        )}

        {setPreAdmTooltip(transit, true)}
      </li>
    );
  };

  const setPreAdmTooltip = (bedDispatch, test) => {
    return (
      <ReactTooltip
        id={`tooltip-${bedDispatch["iep"]}${test ? "-2" : ""}`}
        place="bottom"
        effect="solid"
      >
        <ul className="tooltip-list" style={{ color: "white", zIndex: 5 }}>
          <li style={{ color: "white" }}>{`${t("iep")} : ${bedDispatch.iep}`} </li>
          <li style={{ color: "white" }}>
            {`${t("sex")} : ${
              bedDispatch.sex == "M"
                ? t("m").toUpperCase()
                : bedDispatch.sex == "F"
                ? t("f").toUpperCase()
                : "-"
            }  `}{" "}
          </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${bedDispatch.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>
            {`${t("origin")} : ${
              bedDispatch.preadm_flag == 0
                ? Capitalize(t("urgence"))
                : Capitalize(t("préadmission"))
            } `}{" "}
          </li>
          {bedDispatch.preadm_flag == 0 && (
            <li style={{ color: "white" }}>{`${t("reason")} : ${bedDispatch["motif"]} `} </li>
          )}
          <li style={{ color: "white" }}>{`${t("sector")} : ${bedDispatch.ideal_area}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${bedDispatch.lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(bedDispatch["date_in"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("discharge date")} : ${moment(bedDispatch["date_out"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("los")} : ${Math.round(bedDispatch["los"])} ${
              Math.round(bedDispatch["los"]) > 1 ? t("days") : t("days")
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of erro`)} : +/- ${bedDispatch.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderdata = (transit) => {
    return (
      <div className="gantt__row w-100 parcours">
        <div className="gantt__row-first">
          <div className="row w-100">
            <div className="col-2">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.iep}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.sex}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.age}
              </span>
            </div>
            <div className="col-2 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.motif}
              </span>
            </div>

            <div className="col-4 ">
              <span style={{ color: "black", fontSize: 15 }}>
                {transit.orient_ideale_name != ""
                  ? transit.orient_ideale_name
                  : transit.spec_envisagee_name}{" "}
              </span>
            </div>
            <div className="col-2 ">
              <div className="rel">
                <div className="abso-icons">
                  <FaBed
                    className="bed"
                    style={{ color: "black", fontSize: 28, textAlign: "right" }}
                  ></FaBed>
                  <ImArrowRight
                    className="arrow2"
                    style={{ color: "black", fontSize: 28, textAlign: "left" }}
                  ></ImArrowRight>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="gantt__row-bars parcours">
          {renderPassageEmergencies(transit)}

          {transit["withoutStep2"] == true
            ? renderHebergement(transit, true)
            : props.data.iepUrgHeberg[transit.iep]
            ? renderHebergement(props.data.iepUrgHeberg[transit.iep])
            : null}
          {transit["withoutStep2"] !== true && (
            <li style={{ gridColumn: `1/15`, display: "flex" }}>
              {renderEmergencies(transit)}
              {props.data.bedUfLoss[transit.lit + "/" + transit.uf] &&
                renderLosPred(props.data.bedUfLoss[transit.lit + "/" + transit.uf])}
              {props.data.bedUfPreAdm[transit.lit + "/" + transit.uf] &&
                renderPredAdm(props.data.bedUfPreAdm[transit.lit + "/" + transit.uf], transit.iep)}
            </li>
          )}
        </ul>
      </div>
    );
  };
  const renderdata1 = (transit) => {
    return (
      <div className="gantt__row w-100 parcours">
        <div className="gantt__row-first mrg-2">
          <div className="row w-100">
            <div className="col-2">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.iep}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.sex}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.age}
              </span>
            </div>
            <div className="col-2 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.motif}
              </span>
            </div>

            <div className="col-4 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.orient_ideale_name != ""
                  ? transit.orient_ideale_name
                  : transit.ideal_uf_name}{" "}
              </span>
            </div>
            <div className="col-2 ">
              <div className="rel">
                <div className="abso-icons">
                  <FaBed
                    className="bed"
                    style={{ color: "black", fontSize: 28, textAlign: "right" }}
                  ></FaBed>
                  <ImArrowRight
                    className="arrow2"
                    style={{ color: "black", fontSize: 28, textAlign: "left" }}
                  ></ImArrowRight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderdata2 = (transit) => {
    return (
      <div className="gantt__row w-100 parcours">
        <ul className="gantt__row-bars parcours">
          {renderPassageEmergencies(transit)}

          {props.data.iepUrgHeberg[transit.iep] &&
            renderHebergement(props.data.iepUrgHeberg[transit.iep])}
          <li style={{ gridColumn: `1/15`, display: "flex" }}>
            {renderEmergencies(transit)}
            {props.data.bedUfLoss[transit.lit + "/" + transit.uf] &&
              renderLosPred(props.data.bedUfLoss[transit.lit + "/" + transit.uf])}
            {props.data.bedUfPreAdm[transit.lit + "/" + transit.uf] &&
              renderPredAdm(props.data.bedUfPreAdm[transit.lit + "/" + transit.uf], transit.iep)}
          </li>
        </ul>
      </div>
    );
  };

  const renderJourney = () => {
    let transitData = [
      ...props.data.bedDispatching_urgence,
      ...props.data.bedDispatching_urgence_pred,
      ...props.data.urg_herberg_without_step2,
    ];

    return transitData.map((transit, i) => {
      return (
        <div
          key={i}
          className="row div-gantt parcours"
          id="div-gantt2"
          style={{ backgroundColor: i % 2 == 0 ? "#f5f5f5" : "white" }}
        >
          <div className="gantt__row gantt__row--months-parcours">
            <div className="gantt__row-first">
              <div className="row w-100">
                <div className="col-2 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("iep")}{" "}
                  </span>
                </div>
                <div className="col-1 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("sex")}{" "}
                  </span>
                </div>
                <div className="col-1 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("age")}{" "}
                  </span>
                </div>
                <div className="col-2 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("urg adm reason")}{" "}
                  </span>
                </div>

                <div className="col-4 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("ideal ward")}{" "}
                  </span>
                </div>
              </div>
            </div>
            {renderDates()}
          </div>
          <div className="gantt w-100">
            <div className="gantt__row gantt__row--lines" data-month="5">
              <span className=""></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
            </div>
            {renderdata(transit)}
          </div>
        </div>
      );
    });
  };
  const renderJourneyResponsive = () => {
    let transitData = [
      ...props.data.bedDispatching_urgence,
      ...props.data.bedDispatching_urgence_pred,
      ...props.data.urg_herberg_without_step2,
    ];

    return transitData.map((transit, i) => {
      return (
        <div
          key={i}
          className="row div-gantt parcours"
          id="div-gantt2"
          style={{ backgroundColor: i % 2 == 0 ? "#f5f5f5" : "white" }}
        >
          <div className="gantt__row gantt__row--months-parcours">
            <div className="gantt__row-first">
              <div className="row w-100">
                <div className="col-2 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("iep")}{" "}
                  </span>
                </div>
                <div className="col-1 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("sex")}{" "}
                  </span>
                </div>
                <div className="col-1 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("age")}{" "}
                  </span>
                </div>
                <div className="col-2 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("urg adm reason")}{" "}
                  </span>
                </div>

                <div className="col-4 ">
                  <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                    {t("ideal ward")}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderdata1(transit)}

          <div className="gantt w-100">
            <div className="gantt__row gantt__row--dates"> {renderDates()}</div>
            <div className="gantt__row gantt__row--lines" data-month="5">
              <span className=""></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
              <span className="marker2"></span>
            </div>
            {renderdata2(transit)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="row mrg-t">
      <div className="container-user" style={{ marginTop: 20 }}>
        <div className="row"></div>
        <div className="col-12 scroll-gant" style={{ marginTop: "2%" }}>
          {props.data.bedDispatching_urgence.length == 0 &&
            props.data.bedDispatching_urgence_pred.length == 0 &&
            props.data.urg_herberg_without_step2.length == 0 && (
              <h2 className="text-center">{t("data not available")}</h2>
            )}
          {window.innerWidth <= 991.98 ? renderJourneyResponsive() : renderJourney()}
        </div>
      </div>
    </div>
  );
};
export default ScreenDemoTransit;
