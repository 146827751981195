import React, { useState, useEffect } from "react";
import "./index.css";
import { API } from "../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Capitalize } from "../../utils/functions";

const ListScreens = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [globalScreensData, setGlobalScreensData] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [name, setName] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const loadingData = (screens) => {
    let screensName = [];
    screens.map((screen) => {
      screensName = [...screensName, { id: screen.id, name: screen.name.toUpperCase() }];
    });
    setGlobalScreensData(screensName);
  };
  useEffect(() => {
    axios
      .get(
        `${API.ENDPOINT + API.TRANSLATIONS_ALLSCREENS}`,

        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setLoad(true);
        loadingData(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const confirmationDeleteScreen = (id, screen) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this screen ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteScreen(id, screen),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteScreen = (id, screen) => {
    setLoad(false);

    axios
      .post(`${API.ENDPOINT + API.TRANSLATIONS_DELETESCREEN}`, screen, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let screens = globalScreensData.filter((r) => r.id !== id);

        setShowMessage(true);
        setMessage({ edit: "the screen has been successfully deleted." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalScreensData(screens);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
        //    console.log(e)
      });
  };
  const updateScreen = ({ id, name }) => {
    let token = props.activeUSer.user.token;
    let screen = {
      id: id,
      name: name,
    };
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.TRANSLATIONS_UPDATESCREEN}`,
      data: screen,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        let screens = {};
        screens = globalScreensData.map((dataScreen) =>
          dataScreen.id === id ? screen : dataScreen,
        );
        setShowMessage(true);
        setMessage({ edit: "the screen has been modified successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalScreensData(screens);
        onCancel();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const editScreen = (screen) => {
    setInEditMode({
      status: true,
      rowKey: screen.id,
    });
    setName(screen.name);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setName("");
    setIsDisable(false);
  };

  const renderList = () => {
    if (!globalScreensData) return null;

    return globalScreensData.map((screen, i) => (
      <tr key={i}>
        <td> {screen.id}</td>
        <td>
          {" "}
          {inEditMode.status && inEditMode.rowKey === screen.id ? (
            <input
              value={name}
              required
              className="form-control"
              onChange={(event) => setName(event.target.value)}
            />
          ) : (
            screen.name.toUpperCase()
          )}
        </td>
        <td>
          {inEditMode.status && inEditMode.rowKey === screen.id ? (
            <>
              <a
                style={{ margin: 5 }}
                className={"icon-size"}
                onClick={() => updateScreen({ id: screen.id, name: name })}
              >
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => editScreen(screen)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => confirmationDeleteScreen(screen.id, screen)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };
  return (
    <div className="row mrg-user" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title-translation">{t("list of screens")}</h1>
        <div className="row">
          {
            <div
              className="col-12 d-flex justify-content-between flex-col"
              style={{ textAlign: "end", marginTop: 20 }}
            >
              <a
                href="/translations/newScreen"
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("new screen")}
              </a>
              <a
                href="/translations"
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("list of translations")}
              </a>
            </div>
          }
        </div>
        <div className="message" style={{ marginTop: 20, marginBottom: 20 }}>
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" Screen="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}{" "}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          {load && globalScreensData ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{t("#")}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("name of screen"))}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignscreens: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListScreens);
