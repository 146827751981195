import React, { useState, useEffect, Suspense } from "react";
import "./index.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import ScreenDecompte from "../screens/screenCockpitDecompte";
import ScreenEmergerStats from "../screens/screenCockpitEmegerStats";
import * as Permission from "../../utils/permissions";
import AccesDenied from "../../components/not-found/accesDenied";
import { SuspenseLoader } from "../../components/loader";

const HeaderBed = (props) => {
  const [screen, setScreen] = useState("screen cockpit decompte");
  const { t, i18n } = useTranslation("cockpit");

  const redirection = () => {
    let screenDefault = "";
    if (Permission.hasRole("COCKPIT DECOMPTE", props.roles))
      screenDefault = "screen cockpit decompte";
    setScreen(screenDefault);
  };
  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      if (props.location.state === undefined) {
        redirection();
      } else setScreen(props.location.state && props.location.state.hash);
    } else {
      if (
        props.location.state === null ||
        props.location.state === "" ||
        props.location.state === undefined
      )
        redirection();
      else setScreen(props.location.state);
    }
  }, [props.location.state]);

  const renderScreen = () => {
    if (props.screensData.loaded) {
      switch (screen) {
        case "screen cockpit decompte":
          return <ScreenDecompte data={props.screensData} />;
        case "screen Cockpit Emeger Stats":
          return <ScreenEmergerStats />;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width={"4%"} />
        </div>
      );
    }
  };

  if (Permission.hasRole("COCKPIT DECOMPTE", props.roles)) {
    return (
      <>
        <div className="main-screen">
          <h2 className="h2">{t("beds")}</h2>
          <ul className="nav nav-tabs">
            {Permission.hasRole("COCKPIT DECOMPTE", props.roles) && (
              <>
                <li className="nav-item">
                  <a
                    onClick={() => setScreen("screen cockpit decompte")}
                    className={`nav-link ${
                      screen === "screen cockpit decompte" ? "active disabled" : ""
                    }`}
                    href="#"
                  >
                    {t("decompte").toUpperCase()}
                  </a>
                </li>
              </>
            )}
            {Permission.hasRole("STATS URG", props.roles) && (
              <>
                <li className="nav-item">
                  <a
                    onClick={() => setScreen("screen Cockpit Emeger Stats")}
                    className={`nav-link ${
                      screen === "screen Cockpit Emeger Stats" ? "active disabled" : ""
                    }`}
                    href="#"
                  >
                    {t("Emeger Stats").toUpperCase()}
                  </a>
                </li>
              </>
            )}
          </ul>
          <SuspenseLoader>{renderScreen()}</SuspenseLoader>
        </div>
      </>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensData: state.screensHospData,
});

export default connect(mapStateToProps, null)(HeaderBed);
