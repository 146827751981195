import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ErrorBundler = (props) => {
  const { t, i18n } = useTranslation("error");
  let history = useHistory();

  return (
    <div role="alert">
      <h1 className="text-center" style={{ marginTop: "5%" }}>
        {t("we are sorry, but an error has occurred, please try again later")}
        <span
          style={{ cursor: "pointer", color: "#0077FF" }}
          onClick={props.resetErrorBoundary}
        ></span>
      </h1>
      <div className="col-12 text-center">
        <a
          className="btn btn-primary row-0"
          onClick={() => {
            history.push("/");
            window.location.reload();
          }}
        >
          {t("back")}
        </a>
      </div>
    </div>
  );
};

export default ErrorBundler;
