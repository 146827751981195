import { useForm, Controller } from "react-hook-form";
import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import Select from "react-select";
import "./style.scss";
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import LoadingOverlay from "react-loading-overlay";
import { API } from "../../utils/api";
import UfsSelector from "../../components/ufs-selector";
import { useRequest } from "../../hooks/use-api";
import { useUfLabels } from "../../hooks/use-uf-labels";
import { Capitalize } from "../../utils/functions";
import { bemPatientName } from "../../utils/bem-patient-name";
import BedsSelector from "../../components/beds-selector";

export function item(value, label) {
  return [value, { value, label }];
}

const options = {
  type: Object.fromEntries([
    item("Valide", "Valide"),
    item("Couché", "Couché"),
    item("Chaise", "Chaise"),
  ]),
  precaution: Object.fromEntries([
    item("Aucune", "Aucune"),
    item("Isolement préventif", "Isolement préventif"),
    item("Protecteur ou aplasie", "Protecteur ou aplasie"),
    item("BMR Contact", "BMR Contact"),
    item("BMR gouttelettes", "BMR Gouttelettes"),
    item("Contact clostridium", "Contact clostridium"),
    item("Covid", "Covid"),
    item("Contagieux air", "Contagieux air"),
    item("Contagieux contact", "Contagieux contact"),
    item("Contagieux gouttelettes", "Contagieux goutelettes"),
    item("Contagieux contact et air", "Contagieux contact et air"),
  ]),
  particularite: Object.fromEntries([
    item("Aucune", "Aucune"),
    item("Oxygénothérapie", "Oxygénothérapie"),
  ]),
};

export function P2PModal({ patient, defaultValues = {}, open, onClose, refresh }) {
  const request = useRequest();
  const ufLabels = useUfLabels();
  const { handleSubmit, control, formState, watch, setValue } = useForm({
    defaultValues: {
      iep: patient.iep,
      poids: defaultValues.poid ?? null,
      destination: defaultValues.destination ?? null,
      bed: defaultValues.bed ?? null,
      particularite: defaultValues.particularites ?? "AUCUN",
      precaution: defaultValues.precautions ?? "AUCUN",
      type: defaultValues.type ?? "VALIDE",
    },
  });

  const p2pMovement = useMutation(async function (data) {
    try {
      const [result] = await request({
        method: "POST",
        path: API.V5_P2PMOVEMENT,
        data,
      });

      if (result?.ok) {
        refresh();
        onClose();
      } else if (result?.message) {
        window.alert(result.message);
      } else {
        console.error(result);
      }
    } catch (e) {
      window.alert("Une erreur est survenue, veuillez ressayer");
    }
  });

  const destination = watch("destination");

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "none",
        },
        content: {
          zIndex: "999999!important",
          backgroundColor: "white",
          width: "100%",
          maxWidth: 500,
          height: "calc(100vh - 40px - 40px)",
          maxHeight: 800,
          border: "1px solid blue",
          borderRadius: 5,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          position: "relative",
        },
      }}
    >
      <LoadingOverlay active={p2pMovement.isLoading} spinner>
        <div className="p2p-modal">
          <h3 style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", marginTop: 15 }}>
            BRANCARDAGE
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid gray",
              borderRadius: 3,
              margin: "10px 20px",
              padding: 5,
            }}
          >
            <div style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>NOM Prenom</div>
              <div style={{ textAlign: "center" }}>{bemPatientName(patient.bemPatient)}</div>
            </div>
            <div style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Sexe</div>
              <div style={{ textAlign: "center" }}>{patient.sex}</div>
            </div>
            <div style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Age</div>
              <div style={{ textAlign: "center" }}>{patient.age}</div>
            </div>
          </div>
          <Form
            onSubmit={handleSubmit(async function ({
              destination,
              bed,
              poids,
              type,
              precaution,
              particularite,
            }) {
              await p2pMovement.mutateAsync({
                iep: patient.iep,
                destination,
                bed,
                poids,
                type,
                precaution,
                particularite,
              });
            })}
            style={{
              width: "100%",
              flexDirection: "column",
              display: "flex",
              padding: "0 20px",
              paddingBottom: "20px",
              gap: "16px",
            }}
          >
            <div>
              <Form.Label htmlFor="origin">Depart de</Form.Label>
              <InputGroup>
                <Form.Control
                  id="origin"
                  disabled
                  aria-describedby=""
                  value={ufLabels[patient.originUf] ?? patient.originUf}
                />
              </InputGroup>
            </div>
            <div>
              <Controller
                name="destination"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Form.Label htmlFor="destination">Destination</Form.Label>
                    <UfsSelector
                      {...field}
                      ufs={[field.value]}
                      onSelect={({ value }) => {
                        field.onChange(value);
                        // As UF change, reset the selected bed
                        setValue("bed", null);
                      }}
                      groups={[]}
                      isSearchable
                      isMulti={false}
                    />
                  </>
                )}
              />
            </div>

            <div>
              <Controller
                name="bed"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) =>
                  Boolean(destination) ? (
                    <>
                      <Form.Label htmlFor="bed">Lit</Form.Label>
                      <BedsSelector
                        {...field}
                        uf={destination}
                        beds={field.value ? [field.value] : []}
                        onSelect={([value]) => field.onChange(value || "")}
                      />
                    </>
                  ) : null
                }
              />
            </div>

            <div>
              <Controller
                name="poids"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Form.Label htmlFor="poids">Poids (KG)</Form.Label>
                    <InputGroup>
                      <Form.Control
                        {...field}
                        id="poids"
                        type="number"
                        step="0.5"
                        min="0"
                        aria-describedby=""
                      />
                    </InputGroup>
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Form.Label htmlFor="type">Type</Form.Label>
                    <Select
                      {...field}
                      value={options.type[field.value]}
                      onChange={(option) => field.onChange(option.value)}
                      className="selectJ1"
                      isSearchable
                      isMulti={false}
                      styles={{ fontSize: 20 }}
                      placeholder="Type"
                      options={Object.values(options.type)}
                    />
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="precaution"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Form.Label htmlFor="precaution">Précaution</Form.Label>
                    <Select
                      {...field}
                      value={options.precaution[field.value]}
                      onChange={(option) => field.onChange(option.value)}
                      menuPlacement="top"
                      className="selectJ1"
                      isSearchable
                      isMulti={false}
                      styles={{ fontSize: 20 }}
                      placeholder="Précaution"
                      options={Object.values(options.precaution)}
                    />
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="particularite"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <Form.Label htmlFor="particularite">Particularité</Form.Label>
                    <Select
                      {...field}
                      value={options.particularite[field.value]}
                      onChange={(option) => field.onChange(option.value)}
                      menuPlacement="top"
                      className="selectJ1"
                      isSearchable
                      isMulti={false}
                      styles={{ fontSize: 20 }}
                      placeholder="Particularité"
                      options={Object.values(options.particularite)}
                    />
                  </>
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button variant="primary" type="submit" disabled={!formState.isValid}>
                Commander
              </Button>
              <Button variant="link" type="button" onClick={onClose}>
                annuler
              </Button>
            </div>
          </Form>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}
