import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenMember from "../screenDemoRH_Member";
import ScreenPlanning from "./index";
import { useTranslation } from "react-i18next";

const SwitchPlanningMember = (props) => {
  const [page, setPage] = useState("lifeline");
  const [load, setload] = useState(false);

  const [optionsF, setOptionsF] = useState([]);
  const { t, i18n } = useTranslation(["rh"]);
  const a = 1;

  useEffect(() => {
    let functionsOptions = [];
    let options = { function: [] };
    props.data &&
      props.data.map((item) => {
        if (!options.function.includes(item.fonction.trim()))
          functionsOptions = [
            ...functionsOptions,
            { value: item.fonction.trim(), label: item.fonction.trim() },
          ];
        options.function = [...options.function, item.fonction.trim()];
      });

    setOptionsF(functionsOptions);
  }, []);

  const renderScreen = () => {
    if (optionsF.length > 0) {
      switch (page) {
        case "lifeline":
          return <ScreenPlanning functions={optionsF} data={props.data} />;
          break;
        case "member":
          return <ScreenMember functions={optionsF} data={props.data} />;
          break;
      }
    }
  };

  return (
    <div className="main-screen container-planning-member">
      <div
        className="w-100 d-flex justify-content-start"
        style={{ textAlign: "end", marginTop: 20 }}
      >
        {page === "lifeline" ? (
          <a
            onClick={() => setPage("member")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("manage members")}
          </a>
        ) : (
          <a
            onClick={() => setPage("lifeline")}
            style={{ marginLeft: 10 }}
            className="btn btn-user-1 btn-primary"
          >
            {t("manage planning")}
          </a>
        )}
      </div>
      <div>{renderScreen()}</div>
    </div>
  );
};

export default SwitchPlanningMember;
