import React, { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment/min/moment-with-locales";
import Validation from "./validation";
import Types from "./listTypes";
import Postes from "./listPostes";
import ShowModal from "./popupPlanning";
import Categories from "./listCategories";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";

const ScreenPlanning = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("list");
  const [globalPlanningsData, setGlobalPlanningsData] = useState([]);
  const [filterPlanningsData, setFilterPlanningsData] = useState([]);
  const [planningsData, setPlanningsData] = useState([]);
  const [medecin, setMedecin] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [load, setLoad] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [optionsMedecin, setOptionsMedecin] = useState([]);
  const [optionsMedecinFilter, setOptionsMedecinFilter] = useState([]);
  const [optionsFunctions, setOptionsFunction] = useState([]);
  const [selectedMedecinOption, setOptionMedecinSelected] = useState({
    value: null,
    label: t("all doctors").toUpperCase(),
  });
  const [selectedFunctionsOption, setOptionFunctionsSelected] = useState({
    value: null,
    label: t("all functions").toUpperCase(),
  });
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("isoWeek"));
  const [loadPagination, setLoadPagination] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableBefore, setDisableBefore] = useState(true);
  const [zindexFunction, setZIndexFunction] = useState(1);
  const [zindexDoctors, setZIndexDoctors] = useState(1);
  const [incrementWeek, setIncrementWeek] = useState(1);
  const ref = useRef(null);

  useEffect(() => {
    let plannings = [],
      medecins = [];

    if (startDate && endDate) {
      optionsMedecin.slice(1).map((m) => {
        if (
          selectedFunctionsOption &&
          selectedFunctionsOption.value !== null &&
          selectedFunctionsOption.value !== m.indexF
        )
          return;
        medecins = [...medecins, m];
      });

      filterPlanningsData.map((d) => {
        if (
          selectedFunctionsOption &&
          selectedFunctionsOption.value !== null &&
          selectedFunctionsOption.value !== d.function_id
        )
          return;
        if (
          selectedMedecinOption &&
          selectedMedecinOption.value !== null &&
          selectedMedecinOption.value !== d.index
        )
          return;
        plannings = [...plannings, d];
      });

      setPlanningsData(plannings);
      setOptionsMedecinFilter([{ value: null, label: t("all doctors") }, ...medecins]);
    } else {
      optionsMedecin.slice(1).map((m) => {
        if (
          selectedFunctionsOption &&
          selectedFunctionsOption.value !== null &&
          selectedFunctionsOption.value !== m.indexF
        )
          return;
        medecins = [...medecins, m];
      });

      globalPlanningsData.map((d) => {
        if (
          selectedFunctionsOption &&
          selectedFunctionsOption.value !== null &&
          selectedFunctionsOption.value !== d.function_id
        )
          return;
        if (
          selectedMedecinOption &&
          selectedMedecinOption.value !== null &&
          selectedMedecinOption.value !== d.index
        )
          return;
        plannings = [...plannings, d];
      });

      setPlanningsData(plannings);
      setOptionsMedecinFilter([{ value: null, label: t("all doctors") }, ...medecins]);
    }
  }, [selectedFunctionsOption, selectedMedecinOption, globalPlanningsData]);

  const loadingOptions = (Data) => {
    let doctors = [];
    Data.map((doctor) => {
      doctors.push({
        value: doctor.index,
        label: doctor.nom.toUpperCase() + " " + doctor.prenom.toUpperCase(),
        indexF: doctor.function_id,
      });
    });
    setOptionsMedecin([{ value: null, label: t("all doctors") }, ...doctors]);
    setOptionsMedecinFilter([{ value: null, label: t("all doctors") }, ...doctors]);
    setOptionsFunction([{ value: null, label: t("all functions") }, ...props.functions.slice(1)]);
  };
  useEffect(() => {
    let page = "planning";
    setPage(page);
    let endHeadDate = moment().add(7, "days").endOf("isoWeek");
    let start = moment(startHeadDate).format("YYYY-MM-DD");
    let end = moment(endHeadDate).format("YYYY-MM-DD");
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLPLANNINGS}`,
        {
          startDate: start,
          endDate: end,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        loadingOptions(res.data);
        setGlobalPlanningsData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  useEffect(() => {
    if (moment(endDate).diff(moment(startHeadDate), "days") + 1 > 12) setDisableNext(false);
    else setDisableNext(true);

    if (moment(startHeadDate).diff(moment(startDate), "days") + 1 > 7) setDisableBefore(false);
    else setDisableBefore(true);
  }, [startHeadDate]);

  const next = () => {
    setLoadPagination(true);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).add(7, "days"));
    }, 500);
  };
  const before = () => {
    setLoadPagination(true);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).subtract(7, "days"));
    }, 500);
  };

  const reset = () => {
    setLoad(true);
    setLoadPagination(false);
    setStartDate(null);
    setEndDate(null);
    setStartHeadDate(moment().startOf("isoWeek"));
    setPlanningsData(globalPlanningsData);
    loadingOptions(globalPlanningsData);
  };

  const getPlanningBetween2Dates = () => {
    if (!startDate && !endDate) return;
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    let data = { startDate: start, endDate: end };
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_PPLANNINGSBETWEEN2DATES}`,
        {
          data,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setStartHeadDate(moment(startDate));
        setDisableBefore(true);

        setPlanningsData(res.data);
        setFilterPlanningsData(res.data);
        loadingOptions(res.data);
        setLoadPagination(true);
        setLoad(true);
      })
      .catch((err) => {
        // console.log('err:',err)
        setLoad(false);
      });
  };

  const beforeWeek = () => {
    let start = moment(startHeadDate).subtract(7, "days").startOf("day").format("YYYY-MM-DD");
    let end = moment(start).add(14, "days").startOf("day").format("YYYY-MM-DD");

    let data = { startDate: start, endDate: end };
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_PPLANNINGSBETWEEN2DATES}`,
        {
          data,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setStartHeadDate(start);
        setPlanningsData(res.data);
        setFilterPlanningsData(res.data);
        loadingOptions(res.data);
        setIncrementWeek(incrementWeek - 1);
        setLoad(true);
      })
      .catch((err) => {
        // console.log('err:',err)
        setLoad(false);
      });
  };
  const nextWeek = () => {
    let start = moment(startHeadDate).add(7, "days").startOf("day").format("YYYY-MM-DD");
    let end = moment(start).add(14, "days").startOf("day").format("YYYY-MM-DD");

    let data = { startDate: start, endDate: end };
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_PPLANNINGSBETWEEN2DATES}`,
        {
          data,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setStartHeadDate(moment(start));
        setPlanningsData(res.data);
        setFilterPlanningsData(res.data);
        loadingOptions(res.data);
        setIncrementWeek(incrementWeek + 1);
        setLoad(true);
      })
      .catch((err) => {
        // console.log('err:',err)
        setLoad(false);
      });
  };

  const renderDates = () => {
    let days = [];
    for (let i = 0; i < 14; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <span key={i}>{moment(startHeadDate).add(day, "days").format("DD.MM")} </span>
    ));
  };
  // const showPopUpAdd = (item, p) => {
  //   setPlanning(p);
  //   setMedecin(item);
  //   ref.current.PopupAdd();
  // };
  const showPopUpEdit = (item) => {
    setMedecin(item);
    ref.current.PopupEdit();
  };

  const addPlanning = (planning) => {
    let plannings = [];

    globalPlanningsData.map((plan, i) => {
      if (plan.index === medecin.index) {
        plan.Planning = [...plan.Planning, planning];
      }
      plannings[i] = plan;
    });
    setGlobalPlanningsData(plannings);
  };

  const editPlanning = (planning) => {
    let plannings = [];
    globalPlanningsData.map((plan, i) => {
      if (plan.index === planning.member_id) {
        let _planning = [];

        plan.Planning.map((p) => {
          if (p.index === planning.index) {
            _planning = [..._planning, planning];
          } else _planning = [..._planning, p];
        });
        plan.Planning = _planning;
        plannings = [...plannings, plan];
      } else plannings = [...plannings, plan];
    });
    setGlobalPlanningsData(plannings);
  };

  const deletePlanning = (planning) => {
    let plannings = [];

    globalPlanningsData.map((plan, i) => {
      if (plan.index === planning.member_id) {
        plan.Planning = plan.Planning.filter((p) => p.index !== planning.index);
      }
      plannings[i] = plan;
    });
    setGlobalPlanningsData(plannings);
  };

  const marginJour = (startDate, endDate) => {
    let per = 100 / 14;
    let date =
      moment(startDate).diff(moment(startHeadDate), "days") >= 0
        ? moment(startDate)
        : moment(startHeadDate);

    let start = parseInt(moment(date).diff(moment(startHeadDate), "minutes")) / 1440;

    let width = parseInt(moment(endDate).diff(moment(date), "minutes")) / 1440;

    return { marginLeft: start * per + "%", width: width * per + "%" };
  };

  const renderPlanning = () => {
    let end = 15;
    if (!planningsData) return null;

    return planningsData.map((planning, index) => (
      <div className="gantt__row w-100 planning" key={index}>
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-6">
              {planning.nom.toUpperCase()} {planning.prenom.toUpperCase()}
            </div>
            <div className="col-6">
              {planning.Functions && planning.Functions.name.toUpperCase()}
            </div>
          </div>
        </div>
        <ul className="gantt__row-bars planning">
          <li style={{ gridColumn: `1/${end}` }}>
            <div
              className={`real-los-wrapper`}
              onClick={() => showPopUpEdit(planning)}
              key={index}
              style={{
                backgroundColor: "green",
                zIndex: 1,
                marginLeft: `0%`,
                width: `${100}%`,
              }}
            ></div>

            {planning.Planning &&
              planning.Planning.length > 0 &&
              planning.Planning.map((p, i) => (
                <>
                  {((moment(p.startDate).diff(moment(startHeadDate), "days") >= 0 &&
                    moment(p.startDate).diff(moment(startHeadDate).add(14, "days"), "days") < 0) ||
                    (moment(p.endDate).diff(moment(startHeadDate), "days") > 0 &&
                      moment(p.endDate).diff(moment(startHeadDate).add(14, "days"), "days") <=
                        0)) && (
                    <>
                      <div
                        className="predicted-los-wrapper text-font-plan"
                        data-tip
                        data-for={`tooltip-planning-${p.index}`}
                        // onClick={() => showPopUpAdd(planning, p)}
                        key={i}
                        style={{
                          backgroundColor: `${p.Types.couleur}`,
                          zIndex: i + 2,
                          paddingLeft: 5,
                          ...marginJour(p.startDate, p.endDate),
                        }}
                      >
                        {/* {p.Types.denomination} */}
                      </div>
                      <div
                        className="predicted-los-wrapper text-font-plan"
                        data-tip
                        data-for={`tooltip-planning-${p.index}`}
                        // onClick={() => showPopUpAdd(planning, p)}
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          zIndex: i + 2,
                          paddingLeft: 5,
                          ...marginJour(p.startDate, p.endDate),
                        }}
                      >
                        {p.Types.denomination}
                      </div>
                    </>
                  )}
                  {setPlanningTooltip(p)}
                </>
              ))}
          </li>
        </ul>
      </div>
    ));
  };
  const setPlanningTooltip = (planning) => {
    return (
      <ReactTooltip id={`tooltip-planning-${planning.index}`} effect="solid">
        <ul className="tooltip-list " style={{ color: "white", zIndex: 9 }}>
          <li style={{ color: "white" }}>
            {`${t("description")} : ${
              planning.description === "" || planning.description === null
                ? "-"
                : planning.description
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("start time")} : ${
              planning.startDate === "" ? "-" : moment(planning.startDate).format("HH:mm")
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("end time")} : ${
              planning.endDate === "" ? "-" : moment(planning.endDate).format("HH:mm")
            }`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderScreenPlannings = () => {
    if (load && planningsData) {
      return (
        <>
          <div className="row plan-screen">
            <div className="col-12" style={{ zIndex: 2, padding: "0px 5px" }}>
              {loadPagination && (
                <div
                  className="col-12 d-flex justify-content-center"
                  style={{ zIndex: 6, marginTop: 20 }}
                >
                  <button
                    onClick={before}
                    style={{ width: "100px", height: "40px", margin: 5 }}
                    type="button"
                    disabled={disableBefore}
                    className="btn btn-secondary"
                  >
                    {t("previous")}
                  </button>
                  <button
                    onClick={next}
                    style={{ width: "100px", height: "40px", margin: 5 }}
                    type="button"
                    disabled={disableNext}
                    className="btn btn-secondary"
                  >
                    {t("next")}
                  </button>
                  {/* </> } */}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 scroll-gant plan-screen" style={{ marginTop: "2%" }}>
            <div className="row div-gantt" id="div-gantt2">
              <div className=" gantt__row--months-planning" style={{ display: "grid" }}>
                <div className="gantt__row-first">
                  <div className="row">
                    <div className="col-6">{t("caregiver")}</div>
                    <div className="col-6">{t("function")}</div>
                  </div>
                </div>
                {renderDates()}
              </div>
              <div className="gantt w-100" style={{ maxHeight: 600, overflowY: "scroll" }}>
                <div></div>
                <div className="gantt__row gantt__row--lines" data-month="5">
                  <span className=""></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                </div>
                {renderPlanning()}
                <div style={{ zIndex: 7 }}>
                  <ShowModal
                    ref={ref}
                    addPlanning={addPlanning}
                    editPlanning={editPlanning}
                    deletePlanning={deletePlanning}
                    medecin={medecin}
                    planning={planning}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };

  const renderScreen = () => {
    switch (page) {
      case "planning":
        return renderScreenPlannings();
      case "types":
        return <Types />;
      case "validation":
        return (
          <Validation
            editPlanning={editPlanning}
            deletePlanning={deletePlanning}
            validations={globalPlanningsData}
          />
        );
      case "postes":
        return <Postes />;
      case "categories":
        return <Categories />;
    }
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div className="row">
          <div
            className="col-12  justify-content-between flx-col p-display-mobile padding-0"
            style={{ textAlign: "end", marginTop: 20 }}
          >
            {page === "planning" ? (
              <>
                <div className="d-flex flex-colu col-lg-5 col-12 padding-0 mobile">
                  <div className="col-12 col-md-4 col-lg-4 text-left date" style={{ zIndex: 8 }}>
                    <DatePicker
                      style={{ borderColor: "" }}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      isClearable
                      placeholderText={t("from")}
                      dateFormat="dd/MM/yyyy"
                      maxDate={endDate ? endDate : new Date(moment().subtract(5, "day"))}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4 text-left date" style={{ zIndex: 7 }}>
                    <DatePicker
                      selected={endDate}
                      isClearable
                      placeholderText={t("to")}
                      onChange={(date) => setEndDate(date)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                    />
                  </div>
                  {(startDate || endDate) && (
                    <div
                      className="col-12 col-md-4 margin-w col-lg-3 text-left"
                      style={{
                        zIndex: 6,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        marginTop: 5,
                      }}
                    >
                      {/* {loadB ?  <ReactLoading className="" type={"bars"} color="#2fa2d9" width="70px" /> :  */}
                      <>
                        <button
                          style={{ width: "120px", height: "40px" }}
                          type="button"
                          onClick={getPlanningBetween2Dates}
                          className="btn btn-primary"
                        >
                          {t("go")}
                        </button>
                        <button
                          style={{
                            width: "120px",
                            marginLeft: "20px",
                            height: "40px",
                          }}
                          type="button"
                          onClick={reset}
                          className="btn btn-secondary"
                        >
                          {t("reset")}
                        </button>
                      </>
                      {/* } */}
                    </div>
                  )}
                </div>
                <div
                  className="col-12 col-lg-3 col-md-6 text-left mrg-10 padding-0"
                  style={{ zIndex: zindexDoctors }}
                >
                  <Select
                    value={selectedMedecinOption}
                    isSearchable={true}
                    styles={{ fontSize: 20 }}
                    placeholder={t("all doctors")}
                    className="selectJ1 form-select"
                    isSearchable={false}
                    onChange={(selectedOption) => setOptionMedecinSelected(selectedOption)}
                    options={optionsMedecinFilter}
                    onMenuOpen={() => setZIndexDoctors(6)}
                    onMenuClose={() => setZIndexDoctors(2)}
                  />
                </div>
                <div
                  className="col-12 col-lg-3 text-left col-md-6 mrg-10 padding-0"
                  style={{ zIndex: zindexFunction }}
                >
                  <Select
                    value={selectedFunctionsOption}
                    isSearchable={true}
                    styles={{ fontSize: 20 }}
                    placeholder={t("all functions")}
                    className="selectJ1 form-select"
                    isSearchable={false}
                    onChange={(selectedOption) => setOptionFunctionsSelected(selectedOption)}
                    options={optionsFunctions}
                    onMenuOpen={() => setZIndexFunction(5)}
                    onMenuClose={() => setZIndexFunction(2)}
                  />
                </div>
              </>
            ) : (
              <div className="col-lg-10 padding-0"></div>
            )}
          </div>
        </div>
        <div className="row">
          {!loadPagination && (
            <div
              className="col-12 d-flex justify-content-between"
              style={{ zIndex: 3, marginTop: 20 }}
            >
              <button
                onClick={beforeWeek}
                style={{ width: "200px", height: "40px", margin: 5 }}
                type="button"
                disabled={incrementWeek === 1}
                className="btn btn-primary"
              >
                {t("previous week")}
              </button>
              <button
                onClick={nextWeek}
                style={{ width: "200px", height: "40px", margin: 5 }}
                type="button"
                className="btn btn-primary"
              >
                {t("next week")}
              </button>
            </div>
          )}
        </div>

        {renderScreen()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenPlanning);
