import { combineReducers, createStore } from "redux";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as reducerForm } from "redux-form";
import { persistStore, persistReducer } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import activeUser from "./reducer-activeUser";
import reducerMlcLog from "./reducer-mlcLog";
import reducerScreenEmerg from "./reducer-screensEmergency";
import reducerScreensHosp from "./reducer-screensHosp";

import reducerUsers from "./reducer-users";
const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["activeUser", "reducerMlcLog", "screensHospData", "screensEmergData"],
};
const combinedReducers = combineReducers({
  form: formReducer,
  activeUser,
  mlcLog: reducerMlcLog,
  screensEmergData: reducerScreenEmerg,
  users: reducerUsers,
  screensHospData: reducerScreensHosp,
});

const persistedReducers = persistReducer(rootPersistConfig, combinedReducers);

export default persistedReducers;
