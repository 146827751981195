import React, { useState } from "react";
import "./index.css";
import { API } from "../../../utils/api";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImCheckmark, ImCross } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import { Capitalize } from "../../../utils/functions";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";

const ScreenListStatusFunction = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [dataFunctions, setDataFunctions] = useState(props.functions.slice(1));
  const [dataStatus, setDataStatus] = useState(props.status.slice(1));
  const [load, setLoad] = useState(false);
  const [inEditFunction, setInEditFunction] = useState({
    status: false,
    rowKey: null,
  });
  const [inEditStatus, setInEditStatus] = useState({
    status: false,
    rowKey: null,
  });
  const [nameFunction, setNameFunction] = useState("");
  const [nameStatus, setNameStatus] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showMessageFunction, setShowMessageFunction] = useState(false);
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [messageFunction, setMessageFunction] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);
  const [showErrorFunction, setShowErrorFunction] = useState(false);
  const [showErrorStatus, setShowErrorStatus] = useState(false);
  const [errorFunction, setErrorFunction] = useState(null);
  const [errorsStatus, setErrorsStatus] = useState(null);

  const confirmationDeleteFunction = (id, func) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to remove this function ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteFunction(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const confirmationDeleteStatus = (id, status) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to remove this particular ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteStatus(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteFunction = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETEFUNCTION}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let functions = dataFunctions.filter((f) => f.value !== id);

        setShowMessageFunction(true);
        setMessageFunction({
          edit: "the function has been removed successfully.",
        });
        setTimeout(() => setShowMessageFunction(false), 3000);
        setDataFunctions(functions);
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        //    console.log(e)
      });
  };
  const deleteStatus = (id) => {
    setLoad(true);

    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETESTATUS}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let status = dataStatus.filter((s) => s.value !== id);

        setShowMessageFunction(true);
        setMessageStatus({
          edit: "the particular has been removed successfully.",
        });
        setTimeout(() => setShowMessageStatus(false), 3000);
        setDataStatus(status);
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        //    console.log(e)
      });
  };
  const updateFunction = (item) => {
    setShowErrorFunction(false);
    setLoad(true);
    let fonction = {
      value: item.value,
      label: nameFunction === "" ? item.label : nameFunction,
    };

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_FUNCTION}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: fonction,
    })
      .then((response) => {
        let data = {};
        data = dataFunctions.map((f) => (f.value === item.value ? fonction : f));
        setShowMessageFunction(true);
        setMessageFunction({
          edit: "function has been modified successfully.",
        });

        setDataFunctions(data);
        onCancel();
        setTimeout(() => setShowMessageFunction(false), 3000);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          //    console.log(errors)
          setErrorFunction(errors);
        }
        console.error("There was an error!", error);
        setLoad(false);
        setShowErrorFunction(true);
        setTimeout(() => setShowErrorFunction(false), setErrorFunction(null), 3000);
      });
  };
  const updateStatus = (item) => {
    setShowErrorStatus(false);
    setLoad(true);
    let status = {
      value: item.value,
      label: nameStatus === "" ? item.label : nameStatus,
    };
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.RH_STATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: status,
    })
      .then((response) => {
        let _status = {};
        _status = dataStatus.map((s) => (s.value === item.value ? status : s));

        setShowMessageStatus(true);
        setMessageStatus({
          edit: "particular has been modified successfully.",
        });
        setDataStatus(_status);
        onCancel();
        setTimeout(() => setShowMessageStatus(false), 3000);
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          setErrorsStatus(errors);
        }
        console.error("There was an error!", error);
        setLoad(false);
        setShowErrorStatus(true);
        setTimeout(() => setShowErrorStatus(false), setErrorsStatus(null), 3000);
      });
  };

  const onEditFunction = (item) => {
    setInEditFunction({
      status: true,
      rowKey: item.value,
    });
    setNameFunction(item.label);
    setIsDisable(true);
  };
  const onEditStatus = (item) => {
    setInEditStatus({
      status: true,
      rowKey: item.value,
    });
    setNameStatus(item.label);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditFunction({
      status: false,
      rowKey: null,
    });
    setInEditStatus({
      status: false,
      rowKey: null,
    });
    setNameStatus("");
    setNameFunction("");
    setIsDisable(false);
  };

  const renderListFunction = () => {
    if (!dataFunctions) return null;
    return dataFunctions.map((dataFunction, i) => (
      <tr key={i}>
        <td> {dataFunction.value}</td>
        <td>
          {" "}
          {inEditFunction.status && inEditFunction.rowKey === dataFunction.value ? (
            <input
              value={nameFunction}
              required
              className="form-control"
              onChange={(event) => setNameFunction(event.target.value)}
            />
          ) : (
            dataFunction.label
          )}
        </td>
        <td>
          {inEditFunction.status && inEditFunction.rowKey === dataFunction.value ? (
            <>
              <a
                style={{ margin: 5 }}
                className={"icon-size"}
                onClick={() => updateFunction(dataFunction)}
              >
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEditFunction(dataFunction)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => confirmationDeleteFunction(dataFunction.value)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };
  const renderListStatus = () => {
    if (!dataStatus) return null;
    return dataStatus.map((status, i) => (
      <tr key={i}>
        <td> {status.value}</td>
        <td>
          {" "}
          {inEditStatus.status && inEditStatus.rowKey === status.value ? (
            <input
              value={nameStatus}
              required
              className="form-control"
              onChange={(event) => setNameStatus(event.target.value)}
            />
          ) : (
            status.label
          )}
        </td>
        <td>
          {inEditStatus.status && inEditStatus.rowKey === status.value ? (
            <>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => updateStatus(status)}>
                <ImCheckmark
                  style={{
                    color: "green",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
              <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                <ImCross
                  style={{
                    color: "red",
                    fontSize: 18,
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => onEditStatus(status)}
                className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
              >
                <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
              </a>
              <a
                onClick={() => confirmationDeleteStatus(status.value)}
                title="Delete"
                className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
              >
                <BsTrash style={{ color: "red", fontSize: 24 }} />
              </a>
            </>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="row">
      <div className="container-function plan">
        <h1 className="page-title-translation">{t("list of functions")}</h1>
        <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
          {showMessageFunction &&
            messageFunction &&
            Object.entries(messageFunction).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showErrorFunction &&
            errorFunction &&
            Object.entries(errorFunction).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>

        <div style={{ marginTop: 10, overflow: "auto" }}>
          {dataFunctions ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "5%" }}>
                    <span style={{ display: "block" }}>{t("#")}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "25%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("name of function"))}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{renderListFunction()}</tbody>
            </table>
          ) : (
            <div
              style={{
                marginTop: "6%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactLoading type={"bars"} color="#2fa2d9" width="70px" />
            </div>
          )}
        </div>
      </div>
      <div className="container-particulier plan">
        <h1 className="page-title-translation">{t("list of particulars")}</h1>
        <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
          {showMessageStatus &&
            messageStatus &&
            Object.entries(messageStatus).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showErrorStatus &&
            errorsStatus &&
            Object.entries(errorsStatus).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        <div style={{ marginTop: 10, overflow: "auto" }}>
          {dataStatus ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "5%" }}>
                    <span style={{ display: "block" }}>{t("#")}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "25%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("name of particular"))}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{renderListStatus()}</tbody>
            </table>
          ) : (
            <div
              style={{
                marginTop: "6%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactLoading type={"bars"} color="#2fa2d9" width="70px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenListStatusFunction);
