import React, { useState, useEffect, Suspense } from "react";
import "./index.css";
import Prediction7 from "../screens/screenEmergencyPred7";
import Prediction3 from "../screens/screenEmergencyPred3";
import Trend from "../screens/screenEmergencyTrend24";
import Covid from "../screens/screenEmergencyCovid";
import Achieved from "../screens/screenEmergencyRealise";
import Performance from "../screens/screenEmergencyPerformance";
import AccesDenied from "../../components/not-found/accesDenied";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import * as Permission from "../../utils/permissions";
import { SuspenseLoader } from "../../components/loader";

const HeaderEmergency = (props) => {
  const [screen, setScreen] = useState("screen emergency pred 7");
  const { t } = useTranslation("emergency");

  const redirection = () => {
    let screen;
    if (Permission.hasRole("EMERGENCY PRED 7", props.roles)) screen = "screen emergency pred 7";
    else if (Permission.hasRole("EMERGENCY COVID", props.roles)) screen = "screen emergency covid";
    else if (Permission.hasRole("EMERGENCY ACHIEVED", props.roles))
      screen = "screen emergency achieved";
    else if (Permission.hasRole("EMERGENCY PRED 3", props.roles))
      screen = "screen emergency pred 3";
    else if (Permission.hasRole("EMERGENCY TREND 24", props.roles))
      screen = "screen emergency trend 24";
    else if (Permission.hasRole("EMERGENCY PERFORMANCE", props.roles))
      screen = "screen emergency performance";
    setScreen(screen);
  };

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      if (props.location.state === undefined) {
        redirection();
      } else setScreen(props.location.state && props.location.state.hash);
    } else {
      if (
        props.location.state === null ||
        props.location.state === "" ||
        props.location.state === undefined
      )
        redirection();
      else setScreen(props.location.state);
    }
  }, [props.location.state]);

  const renderScreen = (name = "") => {
    if (props.screensData.loaded) {
      let useName = name == "" ? screen : name;
      switch (useName) {
        case "screen emergency pred 3":
          return (
            <Prediction3
              ErWaitTime={props.screensData["PredictionJ0ToJ4_V3"]["ErWaitTime"]}
              DistributionHosp={props.screensData["DistributionHosp"]}
              ufBedCount={props.screensHospData["ufBedCount"]}
              data={props.screensData["PredictionJ0ToJ4_V3"]}
            />
          );
        case "screen emergency pred 7":
          return <Prediction7 data={props.screensData} />;
        case "screen emergency covid":
          return <Covid activeUser={props.activeUser} data={props.screensData} />;
        case "screen emergency achieved":
          return <Achieved data={props.screensData} />;
        case "screen emergency trend 24":
          return (
            <Trend
              ErWaitTime={props.screensData["PredictionJ0ToJ4_V3"]["ErWaitTime"]}
              data={props.screensData["PredictionJ0ToJ4_V3"]}
            />
          );
        case "screen emergency performance":
          return <Performance data={props.screensData} />;
        default:
          return null;
      }
    } else {
      return (
        <div
          className="row"
          style={{
            justifyContent: "center",
            minHeight: 250,
            alignItems: "flex-end",
          }}
        >
          <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
        </div>
      );
    }
  };
  if (Permission.hasAtLeastOneRole(Permission.roles["emergency"], props.roles)) {
    return props.setScreen != undefined ? (
      <SuspenseLoader>{renderScreen(props.setScreen)}</SuspenseLoader>
    ) : (
      <div className="main-screen flux-urg">
        <h2 className="h2">{t("emergency")}</h2>

        <ul className="nav nav-tabs">
          {Permission.hasRole("EMERGENCY PRED 7", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen emergency pred 7")}
                className={`nav-link ${
                  screen === "screen emergency pred 7" ? "active disabled" : ""
                } `}
                href="#"
              >
                {t("prediction 7").toUpperCase()}
              </a>
            </li>
          )}
          {Permission.hasRole("EMERGENCY PRED 3", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen emergency pred 3")}
                className={`nav-link ${
                  screen === "screen emergency pred 3" ? "active disabled" : ""
                } `}
                href="#"
              >
                {t("prediction 3").toUpperCase()}
              </a>
            </li>
          )}
          {Permission.hasRole("EMERGENCY COVID", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen emergency covid")}
                className={`nav-link ${
                  screen === "screen emergency covid" ? "active disabled" : ""
                } `}
                href="#"
              >
                {t("covid").toUpperCase()}
              </a>
            </li>
          )}
          {Permission.hasRole("EMERGENCY ACHIEVED", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen emergency achieved")}
                className={`nav-link ${
                  screen === "screen emergency achieved" ? "active disabled" : ""
                } `}
                href="#"
              >
                {t("achieved").toUpperCase()}
              </a>
            </li>
          )}
          {Permission.hasRole("EMERGENCY PERFORMANCE", props.roles) && (
            <li className="nav-item">
              <a
                onClick={() => setScreen("screen emergency performance")}
                className={`nav-link ${
                  screen === "screen emergency performance" ? "active disabled" : ""
                } `}
                href="#"
              >
                {t("performance").toUpperCase()}
              </a>
            </li>
          )}
        </ul>
        <SuspenseLoader>{renderScreen()}</SuspenseLoader>
      </div>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
  screensData: state.screensEmergData,
  screensHospData: state.screensHospData,
});

export default connect(mapStateToProps, null)(HeaderEmergency);
