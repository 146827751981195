import React from "react";

import { useTranslation } from "react-i18next";

import "./index.css";

const HomePage = (props) => {
  const { t } = useTranslation("home");

  return (
    <div className="row ">
      <h2 className="h2 homePage">{t("Welcome to the calyps saniia dashboard")}.</h2>
      <div className="body">
        <p className="p">
          {t("entirely designed and developed by") + " "}
          <a href="https://calyps.ch" target="_blank">
            CALYPS
          </a>
          ,
          {" " +
            t(
              "the calyps saniia solution predicts unscheduled admissions, their impact on the flow of emergencies and the resulting hospitalisations",
            )}
          {", "}
          {t(
            "It enables better actvities' coordination between service and prescribes the best possible allocation of resources",
          )}{" "}
          (
          {t("medical team, para-medical team, beds, rooms, technical platforms, consumables, etc")}
          .).
        </p>
        <p className="p">
          {t(
            "modules and indicators are available according to user rights. they can be accessed by clicking on the menu at the top right of the screen",
          )}
          .
        </p>
        <p className="p">
          {t(
            "access to the calyps saniia is strictly limited to duly authorised persons. access is maintained as long as the browser is not closed",
          )}
          .
        </p>
        <p className="p">
          {t("in case of problems (technical or other), please send an email to support@calyps.ch")}
          .
        </p>
        <p className="p">
          {t("© 2020 calyps")} <br />
          {t("all rights reserved")}
        </p>
      </div>
    </div>
  );
};
export default HomePage;
