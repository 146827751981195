import React, { useState, useEffect } from "react";
import "./index.css";
import AccesDenied from "../../components/not-found/accesDenied";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Permission from "../../utils/permissions";
import HomeScreenJourney from "../homeScreenJourney";
import HomeScreenEmergency from "../homeScreenEmergency";
import axios from "axios";
import { API } from "../../utils/api";
import ReactLoading from "react-loading";

const HeaderRh = (props) => {
  const [screen, setScreen] = useState("");
  const [load, setload] = useState(false);
  const [data, setData] = useState(null);
  const [optionsFunctions, setOptionsFunctions] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const { t, i18n } = useTranslation(["cockpit"]);

  const redirection = () => {
    let screen = "TENDANCE 24h";
    setScreen(screen);
  };

  useEffect(() => {
    redirection();
  }, []);

  const renderScreen = () => {
    switch (screen) {
      case "TENDANCE 24h":
        return (
          <div className="main container-fluid">
            <HomeScreenEmergency setScreen="screen emergency trend 24" {...props} />
          </div>
        );
      case "PATIENTS":
        return (
          <div className="main container-fluid">
            <HomeScreenJourney setScreen="screen journey patients" {...props} />
          </div>
        );
      default:
        return null;
    }
  };

  if (Permission.hasAtLeastOneRole(Permission.roles["info"], props.roles)) {
    return (
      <>
        <div className="main-screen container-rh">
          <h2 className="h2">{t("informationScreen_title")}</h2>

          <ul className="nav nav-tabs rh-menu">
            {Permission.hasRole("INFO TENDANCE 24H", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("TENDANCE 24h")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "TENDANCE 24h" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("informationScreen_tab_urgence24h").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("INFO PATIENTS", props.roles) && (
              <li className="nav-item">
                <a
                  onClick={() => setScreen("PATIENTS")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen === "PATIENTS" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("informationScreen_tab_parcoursPatiens").toUpperCase()}
                </a>
              </li>
            )}
          </ul>

          {renderScreen()}
        </div>
      </>
    );
  } else {
    return <AccesDenied />;
  }
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderRh);
