/**
 * @ De: Theo Bensaci
 * @ Date: 13:52 17.05.2024
 * @ Description: script use to générate a random number seeded
 */

const m = 2147483648;
const a = 65539;
const b = 0;

const numberOfDigit = 5;        // nomber of digit the number can have

/**
 * Generate a array of random number seeded
 * @param {*} seed seed
 * @param {*} n number of number to generate
 * @returns generate array
 */
export function generateArrayOfRandom(seed, n){
    let returnData=[];
    for (let index = 0; index < n; index++) {
        let xn = index==0?seed:returnData[index-1];
        let number=(a*xn + b)%m;
        returnData[index]=parseInt(number.toString().slice(0,numberOfDigit));
    }
    return returnData;
}
