import React, { useState, useEffect } from "react";
import "./index.css";
import { API } from "../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross, ImDownload } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import { Capitalize } from "../../utils/functions";

const ListTranslate = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [globalTranslationsData, setGlobalTranslationsData] = useState([]);
  const [dataTranslationsFilter, setDataTranslationsFilter] = useState([]);
  const [optionScreens, setOptionScreens] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadExport, setLoadExport] = useState(false);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [keyFr, setKeyFr] = useState("");
  const [keyEng, setKeyEng] = useState("");
  const [keyDe, setKeyDe] = useState("");
  const [keyIt, setKeyIt] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [keyFilter, setKeyFilter] = useState("");
  const [keyFrFilter, setKeyFrFilter] = useState("");
  const [keyEngFilter, setKeyEngFilter] = useState("");
  const [keyItFilter, setKeyItFilter] = useState("");
  const [keyDeFilter, setKeyDeFilter] = useState("");
  const [selectedOptionScreen, setOptionScreenSelected] = useState({
    value: 0,
    label: "TOUT",
  });
  const [selectedOptionScreenEdit, setOptionScreenEditSelected] = useState({
    value: 0,
    label: "TOUT",
  });
  const [screensName, setscreensName] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);

  const loadingOptions = (Data) => {
    let optionsScreens = [{ value: 0, label: t("all") }];
    let dataTranslations = {};
    let screensName = {};
    Data.map((screen) => {
      screensName[screen.id] = screen;
      optionsScreens = [...optionsScreens, { value: screen.id, label: screen.name.toUpperCase() }];
      dataTranslations[screen.id] = screen["Translations"];
    });
    setscreensName(screensName);
    setOptionScreens(optionsScreens);
    setGlobalTranslationsData(dataTranslations);
  };

  useEffect(() => {
    axios
      .get(
        `${API.ENDPOINT + API.TRANSLATIONS_ALLSCREENS}`,

        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setLoad(true);
        loadingOptions(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const filterDataByScreen = () => {
    if (selectedOptionScreen.value === 0) {
      setDataTranslationsFilter(globalTranslationsData);
    } else {
      let dataTranslations = {};
      dataTranslations[selectedOptionScreen.value] =
        globalTranslationsData[selectedOptionScreen.value];
      setDataTranslationsFilter(dataTranslations);
    }
  };

  useEffect(() => {
    filterDataByScreen();
  }, [selectedOptionScreen, globalTranslationsData]);

  useEffect(() => {
    if (
      keyFilter === "" &&
      keyDeFilter === "" &&
      keyEngFilter === "" &&
      keyFrFilter === "" &&
      keyItFilter === ""
    ) {
      filterDataByScreen();
    } else {
      let dataTranslations = {};
      if (selectedOptionScreen.value == 0) dataTranslations = globalTranslationsData;
      else
        dataTranslations[selectedOptionScreen.value] =
          globalTranslationsData[selectedOptionScreen.value];

      let translation = {};
      Object.entries(dataTranslations).map((screen) => {
        translation[screen[0]] = screen[1].filter((translationValue) => {
          return (
            translationValue.key.toLowerCase().includes(keyFilter.toLowerCase()) &&
            translationValue.fr.toLowerCase().includes(keyFrFilter.toLowerCase()) &&
            translationValue.en.toLowerCase().includes(keyEngFilter.toLowerCase()) &&
            translationValue.de.toLowerCase().includes(keyDeFilter.toLowerCase()) &&
            translationValue.it.toLowerCase().includes(keyItFilter.toLowerCase())
          );
        });
      });
      setDataTranslationsFilter(translation);
    }
  }, [keyFilter, keyDeFilter, keyEngFilter, keyFrFilter, keyItFilter]);

  const confiramtionDeleteKey = (id, screen) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this key?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteTranslation(id, screen),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const importFiles = () => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to import the translations?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => importTo(),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const exportFiles = () => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to export the translations?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => exportIn(),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteTranslation = (id, screen) => {
    setLoad(false);

    axios
      .delete(`${API.ENDPOINT + API.TRANSLATIONS_DELETETRANSLATION}` + id, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        let translations = {};
        Object.entries(globalTranslationsData).map((translation) => {
          if (translation[0] === screen) {
            translations[translation[0]] = translation[1].filter((v) => v.id != id);
            return;
          }
          translations[translation[0]] = translation[1];
        });
        setShowMessage(true);
        setMessage({ edit: "the key has been successfully removed" });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalTranslationsData(translations);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
        // console.log(e)
      });
  };
  const onEdit = (trasnlation, screen, val) => {
    setInEditMode({
      status: true,
      rowKey: trasnlation.id,
      screenId: screen,
    });
    setOptionScreenEditSelected({ value: screen, label: val });
    setKeyFr(trasnlation.fr);
    setKeyEng(trasnlation.en);
    setKeyDe(trasnlation.de);
    setKeyIt(trasnlation.it);
    setIsDisable(true);
  };
  const onSave = ({ id, key, nameFr, nameEn, nameDe, nameIt, id_screens }) => {
    let translation = {
      id: id,
      key: key,
      fr: nameFr,
      en: nameEn,
      de: nameDe,
      it: nameIt,
      id_screens: id_screens,
    };

    let token = props.activeUSer.user.token;

    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.TRANSLATIONS_UPDATETRANSLATION}`,
      data: translation,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        let data = {};
        Object.entries(globalTranslationsData).map((trasnlation) => {
          if (trasnlation[0] === inEditMode.screenId) {
            data[trasnlation[0]] = trasnlation[1].map((v) => (v.id === id ? translation : v));
            return;
          }
          data[trasnlation[0]] = trasnlation[1];
        });
        setShowMessage(true);
        setMessage({ edit: "the translations have been successfully updated" });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalTranslationsData(data);
        onCancel();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setOptionScreenEditSelected({ value: 0, label: "TOUT" });

    setIsDisable(false);
    setKeyFr("");
    setKeyEng("");
    setKeyDe("");
  };

  const importTo = () => {
    let token = props.activeUSer.user.token;

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.TRANSLATIONS_IMPORT}`,
      data: {},
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        loadingOptions(response["data"]);
        setShowMessage(true);
        setMessage({ import: "import successful" });
        setTimeout(() => setShowMessage(false), 3000);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const exportIn = () => {
    let token = props.activeUSer.user.token;
    setLoadExport(true);

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.TRANSLATIONS_EXPORT}`,
      data: {},
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        setShowMessage(true);
        window.location.reload();
        setMessage({ import: "successful export" });
        setTimeout(() => setShowMessage(false), 3000);
        setLoad(false);
        console.log(response);
      })
      .catch((error) => {
        setLoad(false);
        setMessage({ import: "There was an error!" });
        console.error("There was an error!", error);
      });
  };

  const renderList = () => {
    if (!dataTranslationsFilter) return null;
    return Object.entries(dataTranslationsFilter).map((val, index) => {
      return val[1].map((trasnlation, i) => (
        <tr key={i}>
          <td>
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <Select
                value={selectedOptionScreenEdit}
                className="selectJ1 form-select"
                isSearchable={false}
                onChange={(selectedOptionScreenEdit) =>
                  setOptionScreenEditSelected(selectedOptionScreenEdit)
                }
                options={optionScreens}
              />
            ) : screensName[val[0]].id === parseInt(val[0]) ? (
              screensName[val[0]].name.toUpperCase()
            ) : (
              ""
            )}
          </td>
          <td>{trasnlation.key}</td>
          <td>
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <input
                value={keyFr}
                required
                className="form-control"
                onChange={(event) => setKeyFr(event.target.value)}
              />
            ) : (
              trasnlation.fr
            )}
          </td>
          <td>
            {" "}
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <input
                value={keyEng}
                required
                className="form-control"
                onChange={(event) => setKeyEng(event.target.value)}
              />
            ) : (
              trasnlation.en
            )}
          </td>
          <td>
            {" "}
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <input
                value={keyDe}
                required
                className="form-control"
                onChange={(event) => setKeyDe(event.target.value)}
              />
            ) : (
              trasnlation.de
            )}
          </td>
          <td>
            {" "}
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <input
                value={keyIt}
                required
                className="form-control"
                onChange={(event) => setKeyIt(event.target.value)}
              />
            ) : (
              trasnlation.it
            )}
          </td>
          <td>
            {/* <a href={`/translations/${trasnlation.id}/edit`} title="Editer" className="btn update"><BsPencilSquare style={{color:'' , fontSize:24}} /></a> */}
            {inEditMode.status && inEditMode.rowKey === trasnlation.id ? (
              <>
                <a
                  style={{ margin: 5 }}
                  className={"icon-size"}
                  onClick={() =>
                    onSave({
                      id: trasnlation.id,
                      key: trasnlation.key,
                      nameFr: keyFr,
                      nameEn: keyEng,
                      nameDe: keyDe,
                      nameIt: keyIt,
                      id_screens: selectedOptionScreenEdit.value,
                    })
                  }
                >
                  <ImCheckmark
                    style={{
                      color: "green",
                      fontSize: 18,
                      marginTop: -2,
                      cursor: "pointer",
                    }}
                  />
                </a>
                <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                  <ImCross
                    style={{
                      color: "red",
                      fontSize: 18,
                      marginTop: -2,
                      cursor: "pointer",
                    }}
                  />
                </a>
              </>
            ) : (
              <>
                <a
                  onClick={() => onEdit(trasnlation, val[0], screensName[val[0]].name)}
                  className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
                >
                  <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
                </a>
                <a
                  onClick={() => confiramtionDeleteKey(trasnlation.id, val[0])}
                  title="Delete"
                  className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
                >
                  <BsTrash style={{ color: "red", fontSize: 24 }} />
                </a>
              </>
            )}
          </td>
        </tr>
      ));
    });
  };
  return (
    <div className="row mrg-user" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title-translation">{t("list of translations")}</h1>
        <div className="row">
          {loadExport ? (
            <ReactLoading className="loadingTrans" type={"bars"} color="#2fa2d9" width={"4%"} />
          ) : (
            <div
              className="col-12 d-flex justify-content-between flex-col"
              style={{ textAlign: "end", marginTop: 20, padding: 0 }}
            >
              <a href="/translations/new" className="btn btn-user-1 btn-primary">
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("new key")}
              </a>
              <a href="/translations/newScreen" className="btn btn-user-1 btn-primary">
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("new screen")}
              </a>
              <a href="/translations/allScreens" className="btn btn-user-1 btn-primary">
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("list of screens")}
              </a>

              <div style={{ display: "flex", flexDirection: "reverse-row" }} className="flex-col">
                {/* <a  style={{marginLeft:20}} onClick={() => importFiles()}  className="btn btn-user-1 btn-primary">
                        <ImDownload style={{color:'white' , fontSize:18, marginTop:-2,paddingRight:5}} />
                        {t('importer')}</a> */}
                <a onClick={() => exportFiles()} className="btn btn-user-1 btn-primary">
                  <ImDownload
                    style={{
                      color: "white",
                      fontSize: 18,
                      marginTop: -2,
                      paddingRight: 5,
                    }}
                  />
                  {t("export")}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="message" style={{ marginTop: 20, marginBottom: 20 }}>
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}{" "}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          {load && dataTranslationsFilter ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("modules"))}</span>
                    <Select
                      value={selectedOptionScreen}
                      className="selectJ1 form-select"
                      isSearchable={false}
                      onChange={(selectedOptionScreen) =>
                        setOptionScreenSelected(selectedOptionScreen)
                      }
                      options={optionScreens}
                    />
                  </th>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}>{t("keys")}</span>
                    <input
                      value={keyFilter}
                      className="form-control"
                      onChange={(event) => setKeyFilter(event.target.value)}
                    />
                  </th>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}>{t("fr")}</span>
                    <input
                      value={keyFrFilter}
                      className="form-control"
                      onChange={(event) => setKeyFrFilter(event.target.value)}
                    />
                  </th>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}> {t("en")}</span>
                    <input
                      value={keyEngFilter}
                      className="form-control"
                      onChange={(event) => setKeyEngFilter(event.target.value)}
                    />
                  </th>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}>{t("de")}</span>
                    <input
                      value={keyDeFilter}
                      className="form-control"
                      onChange={(event) => setKeyDeFilter(event.target.value)}
                    />
                  </th>
                  <th className="header" style={{ width: "15%" }}>
                    <span style={{ display: "block" }}>{t("it")}</span>
                    <input
                      value={keyItFilter}
                      className="form-control"
                      onChange={(event) => setKeyItFilter(event.target.value)}
                    />
                  </th>
                  <th className="header" style={{ width: "10%" }}>
                    <span style={{ display: "block" }}>{t("action")}</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                aligntrasnlations: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListTranslate);
