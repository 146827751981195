import React, { useState, useEffect, useId } from "react";
import "./index.css";
import { API } from "../../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import LoadingOverlay from "react-loading-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Capitalize } from "../../../utils/functions";
import { ArrowSmallLeftIcon, ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { pick } from "lodash";

import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";

function timeConvert(time){
  if(isNaN(time)){
    // si time stamp
    let a = time.split(":");
    if(a.length>1){
      return a[0]+":"+a[1];
    }
    return time;
  }

  let m= (time)%60;
  let h = (time-m)/60;
  return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

function hhmmToTime(value, fullTime=false){
  if(fullTime){
    return value+":00";
  }
  let split=value.split(":");
  let time=parseInt(split[0])*60+parseInt(split[1]);
  return time;
}


const Parameter = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [parameterBed, setParameterbed] = useState([]);
  const [maxHour, setMaxHour] = useState(0);
  const [hour, sethour] = useState(0);
  const [preMaxHour, setPreMaxHour] = useState(0);
  const [sentStartHours, setSentStartHours] = useState(0);
  const [sentEndHours, setSentEndHours] = useState(0);
  const [sentPatien, setSentPatien] = useState(false);
  const [fastAccomodation, setFastAccomodation] = useState(false);
  const [dispatchPriorities, setDispatchPriorities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  function changePriority({ id }, increase) {
    const delta = increase ? +1 : -1;
    const current = dispatchPriorities.find((d) => d.id == id);

    if (!current) {
      return;
    }

    const other = dispatchPriorities.find((d) => d.priority == Number(current.priority) + delta);

    setDispatchPriorities((dispatches) =>
      dispatches.map((dispatch) =>
        dispatch.id === current.id
          ? { ...dispatch, priority: String(Number(dispatch.priority) + delta) }
          : other && dispatch.id === other.id
          ? { ...dispatch, priority: String(Number(dispatch.priority) + delta * -1) }
          : dispatch,
      ),
    );
  }

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${API.ENDPOINT + API.PARAMETERBED_GETPARAMETERBED}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        console.log(res);
        loadingParameter(res.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, []);
  const loadingParameter = ({ parameterBed, dispatchPriorities }) => {
    setParameterbed(parameterBed);
    setPreMaxHour(
      parameterBed.maxHourLatePreadmission == undefined ? 0 : parameterBed.maxHourLatePreadmission,
    );
    setSentStartHours(
      timeConvert(parameterBed.start_direct_alloc_alt == undefined ? 0 : parameterBed.start_direct_alloc_alt),
    );
    setSentEndHours(
      timeConvert(parameterBed.end_direct_alloc_alt == undefined ? 0 : parameterBed.end_direct_alloc_alt),
    );
    setSentPatien(
      parameterBed.noWaitingBefHospitalisation == undefined
        ? false
        : parameterBed.noWaitingBefHospitalisation,
    );
    sethour(parameterBed.guaranteeBed === undefined ? 0 : parameterBed.guaranteeBed);
    setMaxHour(parameterBed.numberHourMax === undefined ? 0 : parameterBed.numberHourMax);
    setFastAccomodation(parameterBed.fastAccomodation ?? false);
    setDispatchPriorities(dispatchPriorities ?? []);
  };
  const handleOnChangeHour = (value) => {
    sethour(value);
  };
  const ChangeHourMax = (value) => {
    setMaxHour(value);
  };
  const Valid = () => {
    setLoading(true);

    let token = props.activeUSer.user.token;
    let newParameterBed = {
      index: parameterBed.index,
      guaranteeBed: hour,
      numberHourMax: maxHour,
      maxHourLatePreadmission: preMaxHour,
      noWaitingBefHospitalisation: sentPatien,
      start_direct_alloc_alt: hhmmToTime(sentStartHours,true),
      end_direct_alloc_alt: hhmmToTime(sentEndHours,true),
      fastAccomodation: fastAccomodation,
      dispatchPriorities: dispatchPriorities.map((dispatch) => pick(dispatch, "id", "priority")),
    };


    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.PARAMETERBED_PARAMETERBED}`,
      data: newParameterBed,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        setMessage(true);
        setTimeout(() => setMessage(false), 3000);
      })
      .catch((error) => {
        if (error.response.status === "400") {
          let errors = {};
          error.response.data.errors.map((err) => {
            errors[err.param.split(".")[1]] = err.msg;
          });
          setError(errors);
        }
        console.error("There was an error in insert ! ", error);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      })
      .finally(function () {
        setLoading(false);
      });
  };

  //#region input function
  function onDirectAllocationInputChange(value, callback) {
    let text = value;
    if (text == null || text == "") {
      callback(text);
      return;
    }

    let pattern = /\d+/i;
    let result = text.match(pattern);
    let int = parseInt(result);
    console.log(int < 24);
    if (result[0].length > 2 || int > 23) return;

    callback(result);
  }
  //#endregion

  const sortedDispatchPriorities = dispatchPriorities.sort(
    (a, b) => Number(a.priority) - Number(b.priority),
  );

  return (
    <LoadingOverlay active={loading} spinner>
      <div className="row" style={{ marginTop: 50 }}>
        <div className="container-user">
          <h1 className="page-title-translation">{t("parameter")}</h1>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
            {message ? (
              <div className="alert alert-success w-85" role="alert">
                {" "}
                {t("the parameter bed has been successfully modified")}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row ">
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">
                  {t("guaranteed bed allocation threshold for pre-admissions")}
                </p>
              </div>
              <Slider
                value={hour}
                min={0}
                max={96}
                step={3}
                orientation="horizontal"
                onChange={(hour) => handleOnChangeHour(hour)}
              />
              <div className="value-text">
                {t("guaranteed bed allocation") + " : "}
                <span className="value">{hour + "h"}</span>
              </div>
            </div>
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">
                  {t(
                    "maximum number of hours that a patient can wait before inclusion in an accommodation uf",
                  )}
                </p>
              </div>
              <Slider
                value={maxHour}
                min={0}
                max={10}
                step={1}
                orientation="horizontal"
                onChange={(maxHour) => ChangeHourMax(maxHour)}
              />
              <div className="value-text">
                {t("maximum number of hours") + " : "}
                <span className="value">{maxHour + "h"}</span>
              </div>
            </div>
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">{t("maxHourLatePreadmission_description")}</p>
              </div>
              <Slider
                value={preMaxHour}
                min={0}
                max={120}
                step={1}
                orientation="horizontal"
                onChange={(preMaxHour) => setPreMaxHour(preMaxHour)}
              />
              <div className="value-text">
                {t("maximum number of hours") + " : "}
                <span className="value">{preMaxHour + "h"}</span>
              </div>
            </div>
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">{t("directAllocation_description")}</p>
              </div>
              <div className="hoursSelection">
                <div id="container">
                  <div>
                    <input
                      type="time"
                      value={sentStartHours}
                      required
                      className="form-control timeValue"
                      onChange={(event) =>
                        {
                          //onDirectAllocationInputChange(event.target.value, setSentStartHours)
                          setSentStartHours(event.target.value);
                        }
                      }
                    />
                  </div>
                  <p>{t("to")}</p>
                  <div>
                    <input
                      type="time"
                      value={sentEndHours}
                      required
                      className="form-control timeValue"
                      onChange={(event) =>
                        setSentEndHours(event.target.value)
                        //onDirectAllocationInputChange(event.target.value, setSentEndHours)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">{t("noWaitingBefHospitalisation_description")}</p>
              </div>
              <div className="directSendCheckBoxInput">
                <label htmlFor="directSendId">{t("noWaitingBefHospitalisation_inputText")}</label>
                <input
                  onChange={() => {
                    setSentPatien(!sentPatien);
                  }}
                  type="checkbox"
                  checked={sentPatien}
                  id="directSendId"
                />
              </div>
            </div>
            <div className="col-md-6 col-12 border border-primary padding-20">
              <div>
                <p className="text-bed">{t("fastAccomodation_description")}</p>
              </div>
              <div className="directSendCheckBoxInput">
                <label htmlFor="fastAccomodationId">{t("fastAccomodation_inputText")}</label>
                <input
                  onChange={() => setFastAccomodation((v) => !v)}
                  type="checkbox"
                  checked={fastAccomodation}
                  id="fastAccomodationId"
                />
              </div>
            </div>
            {sortedDispatchPriorities.length > 0 ? (
              <div className="col-12 border border-primary padding-20">
                <div>
                  <p className="text-bed">{t("dispatchPriorities_description")}</p>
                </div>
                <div className="row" style={{ gap: 15 }}>
                  {sortedDispatchPriorities.map(function (dispatch, index, dispatches) {
                    const onArrow = {
                      color: "#008fbb",
                      cursor: "pointer",
                    };
                    const offArrow = {
                      color: "gray",
                    };

                    const isFirst = index === 0;
                    const isLast = index === dispatches - 1;

                    return (
                      <div
                        key={dispatch.id}
                        className="col-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          border: "2px solid #e2e2e2",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            color: "black",
                            fontSize: 16,
                            fontWeight: "bold",
                            padding: 5,
                            height: 60,
                          }}
                        >
                          {t(`dispatchPriorities_type_${dispatch.type}`)}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 15,
                          }}
                        >
                          <ArrowSmallLeftIcon
                            style={{ width: 30, height: 30, ...(isFirst ? offArrow : onArrow) }}
                            onClick={function () {
                              if (!isFirst) {
                                changePriority(dispatch, false);
                              }
                            }}
                          />
                          <div style={{ fontWeight: "bold", fontSize: 24 }}>
                            #{Number(dispatch.priority) + 1}
                          </div>
                          <ArrowSmallRightIcon
                            style={{
                              width: 30,
                              height: 30,
                              ...(isLast ? offArrow : onArrow),
                            }}
                            onClick={function () {
                              if (!isLast) {
                                changePriority(dispatch, true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="col-12 d-flex justify-content-center " style={{ marginTop: 30 }}>
              <button onClick={() => Valid()} className="btn btn-primary">
                {t("valid")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(Parameter);
