import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { API } from "../../../utils/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Capitalize } from "../../../utils/functions";
import moment from "moment/min/moment-with-locales";
import Select from "react-select";

const AccommodationAlternatives = (props) => {
  const { t, i18n } = useTranslation(["administration"]);
  const [globalAlternative, setGlobalAlternative] = useState([]);
  const [filterglobalAlternative, setFilterGlobalAlternative] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [optionsStatut, setOptionsStatut] = useState([
    { value: 0, key: "automatique", label: t("automatique") },
    { value: 1, key: "fixed", label: t("fixed") },
  ]);
  const [selectedStatut, setSelectedStatut] = useState({
    value: null,
    key: "statut",
    label: t("statut"),
  });
  const [alternativeFixed1, setalternativeFixed1] = useState(0);
  const [alternativeFixed2, setalternativeFixed2] = useState(0);
  const [alternativeFixed3, setalternativeFixed3] = useState(0);
  const [alternativeFixed4, setalternativeFixed4] = useState(0);
  const [alternativeFixed5, setalternativeFixed5] = useState(0);
  const [errorBorder, setErrorBorder] = useState([]);

  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  const groupUiUf = useRef(null);

  function getGroupUfUiApi() {
    axios({
      method: "get",
      url: `${API.ENDPOINT + API.GET_ALL_GROUP_UF_UI}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        groupUiUf.current = res.data;
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getGroupUfUi(uf) {
    for (const iterator of groupUiUf.current) {
      if (iterator.uf == uf) return iterator;
    }
    return null;
  }
  //#endregion

  useEffect(() => {
    axios({
      method: "get",
      url: `${API.ENDPOINT + API.PARAMETERBED_ALLALTERNATIVEACCOMMODATIONS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalAlternative(res.data);
        setFilterGlobalAlternative(res.data);
        getGroupUfUiApi();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const onSave = (alternative) => {
    let token = props.activeUSer.user.token;
    setShowError(false);
    if (selectedStatut.value === null) {
      setErrorBorder(["choiceStatut"]);
      setShowError(true);
      setError({ type: "please select a statut" });
      setTimeout(() => setError(false), 3000);
      return;
    }
    let newAlternative = {
      index: alternative.index,
      ufConcerné: alternative.ufConcerné,
      hebergementAlternatifFixed1:
        alternativeFixed1 === "" ? alternative.hebergementAlternatif1 : alternativeFixed1,
      hebergementAlternatifFixed2:
        alternativeFixed2 === "" ? alternative.hebergementAlternatif2 : alternativeFixed2,
      hebergementAlternatifFixed3:
        alternativeFixed3 === "" ? alternative.hebergementAlternatif3 : alternativeFixed3,
      hebergementAlternatifFixed4:
        alternativeFixed4 === "" ? alternative.hebergementAlternatif4 : alternativeFixed4,
      hebergementAlternatifFixed5:
        alternativeFixed5 === "" ? alternative.hebergementAlternatif5 : alternativeFixed5,
      statut:
        alternative.statut != selectedStatut.value ? selectedStatut.value : alternative.statut,
      date: alternative.date === null ? moment().format("YYYY-MM-DD HH:mm") : alternative.date,
    };
    axios({
      method: "PUT",
      url: `${API.ENDPOINT + API.PARAMETERBED_ALTERNATIVEACCOMMODATION}`,
      data: newAlternative,
      headers: {
        "x-auth-token": token,
      },
    })
      .then((response) => {
        let dataAlternative = {};
        let update_alt = {
          index: alternative.index,
          ufConcerné: alternative.ufConcerné,
          hebergementAlternatif1: alternative.hebergementAlternatif1,
          hebergementAlternatif2: alternative.hebergementAlternatif2,
          hebergementAlternatif3: alternative.hebergementAlternatif3,
          hebergementAlternatif4: alternative.hebergementAlternatif4,
          hebergementAlternatif5: alternative.hebergementAlternatif5,
          hebergementAlternatifFixed1: newAlternative.hebergementAlternatifFixed1,
          hebergementAlternatifFixed2: newAlternative.hebergementAlternatifFixed2,
          hebergementAlternatifFixed3: newAlternative.hebergementAlternatifFixed3,
          hebergementAlternatifFixed4: newAlternative.hebergementAlternatifFixed4,
          hebergementAlternatifFixed5: newAlternative.hebergementAlternatifFixed5,
          statut: newAlternative.statut,
          date: newAlternative.date,
        };
        dataAlternative = globalAlternative.map((alt) =>
          parseInt(alt.ufConcerné) == parseInt(alternative.ufConcerné) ? update_alt : alt,
        );

        setShowMessage(true);
        setMessage({
          edit: "the alternative accommodation has been changed successfully",
        });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalAlternative(dataAlternative);
        onCancel();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            setError(errors);
          } else if (error.response.status === "401") {
            setError({
              date: "impossible to modify this alternative accommodation",
            });
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const onEdit = (alternative) => {
    setInEditMode({
      status: true,
      rowKey: alternative.ufConcerné,
    });
    setSelectedStatut({
      value: alternative.statut,
      label: parseInt(alternative.statut) == 0 ? "automatique" : "fixed",
    });
    setalternativeFixed1(alternative.hebergementAlternatifFixed1);
    setalternativeFixed2(alternative.hebergementAlternatifFixed2);
    setalternativeFixed3(alternative.hebergementAlternatifFixed3);
    setalternativeFixed4(alternative.hebergementAlternatifFixed4);
    setalternativeFixed5(alternative.hebergementAlternatifFixed5);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setSelectedStatut({ value: null, label: "statut" });
    setalternativeFixed1("");
    setalternativeFixed2("");
    setalternativeFixed3("");
    setalternativeFixed4("");
    setalternativeFixed5("");
    setIsDisable(false);
  };

  const renderList = () => {
    if (!globalAlternative) return null;

    return (
      Array.isArray(globalAlternative) &&
      globalAlternative.map((alternative, i) => {
        let groupUfUi = getGroupUfUi(alternative.ufConcerné);

        return (
          <tr key={i}>
            <td>
              {" "}
              {(groupUfUi == null ? "" : t(groupUfUi.name)) +
                (groupUfUi == null
                  ? parseInt(alternative.ufConcerné)
                  : " (" + parseInt(alternative.ufConcerné) + ")")}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <input
                  value={alternativeFixed1}
                  required
                  className="form-control"
                  onChange={(event) => setalternativeFixed1(event.target.value)}
                />
              ) : parseInt(alternative.statut) === 0 ? (
                alternative.hebergementAlternatif1
              ) : (
                alternative.hebergementAlternatifFixed1
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <input
                  value={alternativeFixed2}
                  required
                  className="form-control"
                  onChange={(event) => setalternativeFixed2(event.target.value)}
                />
              ) : parseInt(alternative.statut) == 0 ? (
                alternative.hebergementAlternatif2
              ) : (
                alternative.hebergementAlternatifFixed2
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <input
                  value={alternativeFixed3}
                  required
                  className="form-control"
                  onChange={(event) => setalternativeFixed3(event.target.value)}
                />
              ) : parseInt(alternative.statut) == 0 ? (
                alternative.hebergementAlternatif3
              ) : (
                alternative.hebergementAlternatifFixed3
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <input
                  value={alternativeFixed4}
                  required
                  className="form-control"
                  onChange={(event) => setalternativeFixed4(event.target.value)}
                />
              ) : parseInt(alternative.statut) == 0 ? (
                alternative.hebergementAlternatif4
              ) : (
                alternative.hebergementAlternatifFixed4
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <input
                  value={alternativeFixed5}
                  required
                  className="form-control"
                  onChange={(event) => setalternativeFixed5(event.target.value)}
                />
              ) : parseInt(alternative.statut) == 0 ? (
                alternative.hebergementAlternatif5
              ) : (
                alternative.hebergementAlternatifFixed5
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <Select
                  value={selectedStatut}
                  className={`selectJ1 form-select ${
                    showError && errorBorder.includes("choiceStatut") ? "error" : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedStatut) => setSelectedStatut(selectedStatut)}
                  options={optionsStatut}
                />
              ) : parseInt(alternative.statut) == 0 ? (
                t("automatique")
              ) : (
                t("fixed")
              )}
            </td>
            <td>
              {inEditMode.status &&
              parseInt(inEditMode.rowKey) == parseInt(alternative.ufConcerné) ? (
                <>
                  <a
                    style={{ margin: 5 }}
                    className={"icon-size"}
                    onClick={() => onSave(alternative)}
                  >
                    <ImCheckmark
                      style={{
                        color: "green",
                        fontSize: 18,
                        marginTop: -2,
                        cursor: "pointer",
                      }}
                    />
                  </a>
                  <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                    <ImCross
                      style={{
                        color: "red",
                        fontSize: 18,
                        marginTop: -2,
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </>
              ) : (
                <>
                  <a
                    onClick={() => onEdit(alternative)}
                    className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
                  >
                    <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
                  </a>
                </>
              )}
            </td>
          </tr>
        );
      })
    );
  };
  return (
    <div className="row" style={{ marginTop: 50 }}>
      <div className="container-user">
        <h1 className="page-title-translation">
          {t("priority of accommodation alternatives for emergencies")}
        </h1>
        <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showError &&
            error &&
            Object.entries(error).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        <div className="overflow" style={{ marginTop: 10 }}>
          {load && globalAlternative ? (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr style={{ background: "#007bff", color: "white" }}>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("uf concerned"))}</span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("alternative accommodation")) + " 1"}
                    </span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("alternative accommodation")) + " 2"}
                    </span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("alternative accommodation")) + " 3"}
                    </span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("alternative accommodation")) + " 4"}
                    </span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>
                      {Capitalize(t("alternative accommodation")) + " 5"}
                    </span>{" "}
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("status"))}</span>
                  </th>
                  <th className="header" style={{ width: "10%", background: "#007bff" }}>
                    <span style={{ display: "block" }}>{Capitalize(t("action"))}</span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(AccommodationAlternatives);
