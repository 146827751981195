/**
 * @ By: Theo Bensaci
 * @ Date: 05.03.2022
 * @ Description: console log (tkt)
 */

import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

const SINGS = {
  log: "🌳",
  err: "🧨",
  acc: "☕",
  spe: "⭐",
};

const ScreenConsole = (props) => {
  let log;
  const [logOptionShow, setLogOptionShow] = useState("log");
  const [loading, setLoading] = useState(true);
  const [logOnPage, setLogOnPage] = useState([]);
  const [requestShow, setRequestShow] = useState("");
  const logOption = useRef("log");

  useEffect(() => {
    loadLogs();
  }, []);

  //#region Function

  /**
   * Load all log off the type of logOption
   */
  function loadLogs() {
    setLoading(true);
    axios
      .get(`${API.ENDPOINT + API.GET_LOG}`, {
        params: { logType: logOption.current },
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        log = res.data.data;
        setLoading(false);
        genLogsOnPage();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  /**
   * Generate all ellements of logs on page
   */
  function genLogsOnPage() {
    let newLog = [];
    let prefix = SINGS[logOption.current];
    if (log.length > 0) {
      for (let index = 0; index < log.length; index++) {
        newLog.push(
          <p>
            <div>
              {prefix}
              {log[index]}
            </div>
          </p>,
        );
      }
    } else {
      newLog.push(
        <p>
          <div className="NoLog">
            <div>{prefix+prefix+prefix+prefix+prefix+prefix+prefix}  Nothing to see here, for the now...  {prefix+prefix+prefix+prefix+prefix+prefix+prefix}</div>
          </div>
        </p>,
      );
    }
    setLogOnPage(newLog);
    let element = document.getElementById("console");
    element.scrollTop = element.scrollHeight;
  }

  /**
   * on select change
   * @param {object} event Caller object
   */
  function handleChange(event) {
    let value = event.target.value;
    setLogOptionShow(value);
    logOption.current = value;
    loadLogs();
  }

  function customeRequest(request) {
    axios
      .get(`${API.ENDPOINT + request}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  /**
   * Fake function for load log page, its a rickroll because i'm the funniest person ever
   */
  function GoToLogPage() {
    window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
    alert("no");
  }


  //#endregion
  return props.activeUSer == null &&
    (props.activeUSer.user.group.name=="super admin") ? (
    <div className="WrongCustomeConsole">
      <h1>Server log page</h1>
      <h4>This page is for developers and developers only, you are not allowed, sorry...</h4>
      <h6>(The developer)</h6>
      <button
        hidden
        onClick={() => {
          GoToLogPage();
        }}
      >
        Go To page
      </button>
    </div>
  ) : (
    <div className="CustomeConsole">
      <div id="titel">
        <h1>Server log</h1>
      </div>
      <h5 className="endpoint">ENDPOINT : {(process.env.REACT_APP_API_URL)}</h5>
      <div className="toolBar">
        <div className="requestInput">
          <input
            placeholder="custome request"
            id="requestInput"
            value={requestShow}
            onChange={(event) => {
              setRequestShow(event.target.value);
            }}
          />
          <button
            onClick={() => {
              customeRequest(requestShow);
              loadLogs();
            }}
          >
            Send Request
          </button>
          <button
            onClick={() => {
              loadLogs();
            }}
          >
            Refresh
          </button>
        </div>
        <select value={logOptionShow} onChange={handleChange}>
          <option value="default" disabled hidden>
            Choose your car
          </option>
          <option value="log">
            {SINGS["log"]} Log {SINGS["log"]}
          </option>
          <option value="spe">
            {SINGS["spe"]} Special Log {SINGS["spe"]}
          </option>
          <option value="err">
            {SINGS["err"]} Error {SINGS["err"]}
          </option>
          <option value="acc">
            {SINGS["acc"]} Access Log {SINGS["acc"]}
          </option>
        </select>
      </div>
      <div className="console" id="console">
        {loading ? (
          <div className="loadingContainer">
            <div className="loading">
              <div>🔎 loading... 🔎</div>
            </div>
          </div>
        ) : (
          logOnPage
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null)(ScreenConsole);
