import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./index.css";
const Circle = (props) => {
  return (
    <CircularProgressbar
      value={props.percentage}
      text={props.text}
      strokeWidth={props.week ? 0 : 12}
      background={true}
      backgroundPadding={0}
      className="progress-cirle"
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)

        textColor: props.textColor || "black",
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "butt",
        // Text size
        textSize: "24px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,
        pathColor: "#165185",
        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        trailColor: "white",
        backgroundColor: props.color,
      })}
    />
  );
};
export default Circle;
