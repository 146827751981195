import React, { forwardRef, useMemo } from "react";
import GroupsSelector from "../../../../../components/groups-selector";
import Select from "react-select";
import UfsSelector from "../../../../../components/ufs-selector";
import "./style.scss";

export function AccommodationFilters({ filters, setFilters, iepOptions, ageOptions,dateOption }) {
  const sexeOptions = {
    M: { value: "M", label: "Masculin" },
    F: { value: "F", label: "Feminin" },
  };

  return (
    <div className="accommodation-filters">
      <div className="accommodation-filter accommodation-filters-ieps">
        <Select
          value={filters.ieps.map((iep) => iepOptions[iep])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder="NOM Prenom"
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              ieps: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(iepOptions)}
        />
      </div>
      <div className="accommodation-filter accommodation-filters-ages">
        <Select
          value={filters.ages.map((age) => ageOptions[age])}
          className="selectJ1"
          isSearchable
          isMulti
          styles={{ fontSize: 20 }}
          placeholder="Age"
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              ages: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(ageOptions)}
        />
      </div>
      <div className="accommodation-filter accommodation-filters-sexe">
        <Select
          value={filters.sexes.map((sexe) => sexeOptions[sexe])}
          isMulti
          className="selectJ1"
          styles={{ fontSize: 20 }}
          placeholder="Sexe"
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              sexes: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(sexeOptions)}
        />
      </div>
      <div className="accommodation-filter accommodation-filters-groups">
        <GroupsSelector
          groups={filters.groups}
          onSelect={(options) =>
            setFilters((filters) => ({
              ...filters,
              groups: options?.map((option) => option.value) ?? [],
            }))
          }
          isSearchable
          isMulti
        />
      </div>
      <div className="accommodation-filter accommodation-filters-ufs">
        <UfsSelector
          ufs={filters.ufs}
          groups={filters.groups}
          onSelect={(options) =>
            setFilters((filters) => ({
              ...filters,
              ufs: options?.map((option) => option.value) ?? [],
            }))
          }
          isSearchable
          isMulti
        />
      </div>
      <div className="accommodation-filter accommodation-filters-date">
        <Select
          value={filters.dates.map((date) => dateOption[date])}
          isMulti
          className="selectJ1"
          styles={{ fontSize: 20 }}
          placeholder="Date entré"
          onChange={(options) =>
            setFilters((filters) => ({
              ...filters,
              dates: options?.map((option) => option.value) ?? [],
            }))
          }
          options={Object.values(dateOption)}
        />
      </div>
    </div>
  );
}
