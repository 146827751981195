/**
 * @ By: Theo Bensaci
 * @ Date: 11:10:25 27.06.2022
 * @ Description: Screen Emerger stats
 */

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import ReactLoading from "react-loading";
import moment from "moment/min/moment-with-locales";
import { dateLogout } from "../../../utils/functions";
import { bemPatientName } from "../../../utils/bem-patient-name";
import { p2pStatus } from "../../../utils/p2p-status";

function createColor(r, g, b, a) {
  return { r: r, g: g, b: b, a: a };
}

const ScreenBedManagementCells = (props) => {
  //#region variable
  const { t, i18n } = useTranslation("administration");
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState(null);
  const [tableContent, setTableContent] = useState(null);
  const data = useRef();

  const tabelContentBackColorGradient = [
    createColor(3, 252, 86, 1),
    createColor(253, 223, 3, 1),
    createColor(252, 169, 3, 1),
    createColor(252, 3, 78, 1),
  ];
  const maxWaitHours = 5;

  //#endregion
  //#region Api Function

  /**
   * request emerge Stats data to api
   */
  function requestData() {
    setIsLoading(true);
    // request
    axios
      .get(`${API.ENDPOINT + API.PARAMETERBED_ALLBEDDISPATCHBEDMANAGEMENT}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        data.current = res.data;
        //let fakeData=generateRandomData(100);

        generateTableContent(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  //#endregion

  //#region table generation function

  function generateTableHeader() {
    let headers = [];

    headers.push(
      <th className="tableHeader">
        {t("prenom")} {t("nom")} / IEP
      </th>,
    );

    headers.push(<th className="tableHeader">{t("age")}</th>);
    headers.push(<th className="tableHeader">{t("sex")}</th>);
    headers.push(<th className="tableHeader">{t("emergArriveDate")}</th>);
    headers.push(<th className="tableHeader">{t("idealOrientationUF")}</th>);
    headers.push(<th className="tableHeader">{t("bedManagerStatus")}</th>);

    setTableHeader(<tr>{headers}</tr>);
  }

  function lerp(v0, v1, t) {
    return (1 - t) * v0 + t * v1;
  }

  function lerpColor(color0, color1, t) {
    return createColor(
      lerp(color0.r, color1.r, t),
      lerp(color0.g, color1.g, t),
      lerp(color0.b, color1.b, t),
      lerp(color0.a, color1.a, t),
    );
  }

  function generateTableContent(data) {
    let contents = [];
    let actualMoment = moment();

    if (data != null && data.lenght != 0) {
      let sortData = data.sort((a, b) => {
        return moment(a.date_in).diff(moment(b.date_in));
      });

      for (const iterator of sortData) {
        // create table element
        let newItem = [];
        newItem.push(
          <td className="tableContent">
            {iterator.bemPatient ? (
              <>
                {bemPatientName(iterator.bemPatient)}
                <br />
                {iterator.iep}
              </>
            ) : (
              iterator.iep
            )}
          </td>,
        );
        newItem.push(<td className="tableContent">{iterator.age}</td>);
        newItem.push(<td className="tableContent">{iterator.sex}</td>);
        newItem.push(
          <td className="tableContent">
            {moment(iterator.date_in).format("YYYY/MM/DD HH:mm:ss")}
          </td>,
        );
        newItem.push(
          <td className="tableContent">{t(`${iterator.uf} ${(iterator.buffer_uf)?("("+(iterator.buffer_uf)+")"):""}`)}</td>,
        );

        const status = p2pStatus(iterator);
        newItem.push(
          <td className="tableContent">{status?.label ?? "En attente de traitement"}</td>,
        );

        // find color
        let diff = Math.abs(actualMoment.diff(moment(iterator.date_in), "minutes"));

        let x = diff / (maxWaitHours * 60); // t in lerp
        let newColor = createColor(255, 255, 255, 1);

        // gradient
        if (tabelContentBackColorGradient.lenght < 2) {
          newColor = tabelContentBackColorGradient[0];
        } else {
          let gradientLenght = tabelContentBackColorGradient.length - 1;
          let gradientPar = 1 / gradientLenght;

          if (x < 1) {
            // find color
            for (let index = 0; index < gradientLenght; index++) {
              let a = gradientPar * (index + 1);
              if (x <= a) {
                /* get x scaled to actual gardient part exemple :
                                    the gardient part = k
                                    index = n
                                    if x = 0.8 and k = 0.25

                                    that is the gardient : 
                                    |------|------|----x-|
                                    0     0.25   0.75    1

                                    the object is to get reatio of that part
                                    **************|----x-|
                                                  ^      ^
                                    
                                    so if x = 0.8 and the part equale 0.25
                                    we expecte to find the following result : 0.8-0.75/0.25=0.2

                                    side note
                                    programming with 2 hours of sleep is not a very good idea, obviously, but I'm pretty dumb...
                                */
                let newX = (x - gradientPar * index) / gradientPar;

                newColor = lerpColor(
                  tabelContentBackColorGradient[index],
                  tabelContentBackColorGradient[index + 1],
                  newX,
                );
                break;
              }
            }
          } else {
            newColor = tabelContentBackColorGradient[gradientLenght];
          }
        }

        // push element
        contents.push(
          <tr style={{ background: `rgb(${newColor.r}, ${newColor.g}, ${newColor.b})` }}>
            {newItem}
          </tr>,
        );
      }
    } else {
      contents.push(
        <tr className="tableSubContent">
          <td colSpan={8} className="tableContent noData">
            {t("noDataMessage")}
          </td>
        </tr>,
      );
    }

    setTableContent(contents);
  }

  //#endregion

  useEffect(() => {
    requestData();
    generateTableHeader();
  }, []);

  // show the page or the loading page if loading
  return (
    <div className="bedManagementCells">
      <h1 className="title">{t("screenBedManagementCells_title")}</h1>
      {isLoading ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <div id="container">
          <table>
            {tableHeader}
            {tableContent}
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenBedManagementCells);
