import { USER_T } from "../actions/users/action-user-types";

const initialState = { loaded: false, data: null };

export default function reducerUsers(state = initialState, action) {
  switch (action.type) {
    case USER_T.READ_ALL:
      return { loaded: true, data: action.payload };
    case "RESET":
      return initialState;
  }
  return state;
}
