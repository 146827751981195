import React, { useEffect, useRef, useState, useQuery } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useUfLabels } from "../../../hooks/use-uf-labels";
import "./index.scss";
import moment from "moment/min/moment-with-locales";
import { ImArrowRight } from "react-icons/im";
import { FaBed } from "react-icons/fa";
import arrow_right from "../../../images/send.png";
import { Capitalize } from "../../../utils/functions";
import ReactTooltip from "react-tooltip";
import { defineLocale } from "moment";
import { bemPatientName } from "../../../utils/bem-patient-name";
import { compareDesc } from "date-fns";
import Select from "react-select";
import axios from "axios";
import { API } from "../../../utils/api";
import { connect } from "react-redux";
import { useRequest } from "../../../hooks/use-api";
import * as Permission from "../../../utils/permissions";

const ScreenJourneyTransit = (props) => {
  const request = useRequest();
  const { t, i18n } = useTranslation(['journey','common']);
  const ufLabels = useUfLabels();

  const [startHeadDate, setStartHeadDate] = useState(moment().subtract(1, "days").startOf("day"));
  const nb = 100 / 8;

  const transiControllData=useRef(null);

  const [dataTransit,setDataTransit] = useState(null);

  const [editBoxShowState, setEditBoxShowState]=useState(false);
  const [showclearDateBnt,setShowClearDateBnt]=useState(false);
  const [editboxData, setEditboxData]=useState({name:""});
  const [customBed, setCustomBed]=useState(false);
  const editboxRef=useRef(null);
  const actualBntData=useRef({element:null,className:""});

  const [editboxSliderValue, setEditboxSliderValue]=useState(0);

  const [showDellBox,setShowDellBox]=useState(false);
  const dellboxData=useRef(null);
  const dellboxRef=useRef(null);
  const [delBoxMessage,setDelBoxMessage]=useState("");

  const renderDates = () => {
    let days = [];
    for (let i = 0; i < 8; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <span className="date txt-font" key={i}>
        {moment(startHeadDate).add(day, "days").format("DD.MM")}{" "}
      </span>
    ));
  };

  useEffect(()=>{
    // set actual transi controll
    transiControllData.current=props.data.bedDispatchControl;
    showTransiDataRender();
  },[props]);

  useEffect(()=>{
    if(!customBed){
      loadBedOption(editboxData.bed,editboxRef.current.querySelector("#to").value);
    }
  },[customBed])

  /**
   * Render all transi render
   */
  function showTransiDataRender(){
    setDataTransit(renderDataTransit());
  }

  //#region data interaction

  function getEditPermision(){
    //Permission.hasRole("HOSPITALISATION TO COME", props.roles)
    //Bed manager 
    let value=props.activeUSer.user.group.name.toLowerCase();
    return (value=="super admin" || value=="admin" || value=="bed manager");
  }

  /**
   * get a ptah_ack data with the same iep
   * @param {string} iep
   */
  function getPtha_pack(iep) {
    for (const iterator of props.data.ptah_ack) {
      if (iterator.iep == iep) return iterator;
    }
    return null;
  }

  /**
   * get a bedDispatchBedManagement data with the same iep
   * @param {string} iep
   */
  function getBedDispatchBedManagement(iep) {
    for (const iterator of props.data.bedDispatchBedManagement) {
      if (iterator.iep == iep) return iterator;
    }
    return null;
  }

  function creatTransiControll_OnDataBase(data){
    axios.post(
      `${API.ENDPOINT + API.JOURNEY_CREATE_BEDDISPATCHCONTROL}`,data,
      {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      },
    )

    props.data.bedDispatchControl=transiControllData.current;
  }

  function getAvalidBed(uf,callback){
    request({
      method: "GET",
      path: API.V5_AVAILABLE_BEDS,
      params: {
        uf,
      },
    }).then((data)=>{
      callback(data);
    });
  }



  /**
   * Check if the transi has a patch by transi controll
   * @param {string} iep iep of the transi
   */
  function getTransiControllData(iep){
    if(transiControllData.current==null)return null;

    let d = null;
    for (const iterator of transiControllData.current) {
      if(iterator.iep==iep){
        if(d==null){
          d=iterator;
        }
        else if(iterator.date!=null){
          let m=moment(iterator.date);
          let maximumMoment =  moment.max([moment(d.date), m]); 
          if(maximumMoment==m){
            d=iterator;
          }
        }
      }
    }
    return d;
  }
  //#endregion


  //#region EditBox

  /**
   * Show edit box
   * @param {*} element 
   * @param {*} data 
   */
  function showEditBox(element,data){
    if(actualBntData.current.element!=null && element==actualBntData.current.element)return;

    setEditboxData(data);

    // change editbox parent    
    element.appendChild(editboxRef.current);

    // set input value
    for (let iterator of editboxRef.current.querySelectorAll('input')) {
      switch(iterator.name){
        case "startForm":
          iterator.value=data.orient;
          break;
        case "bed":
          iterator.value=data.bed;
          break;
        case "stretcheringDate":
          let end=null;
          if(data.branc_date!=null){
            end = moment(data.branc_date);
          }
          else if (data.date_in_urg && data.date_out_urg) {
            end = moment(data.date_out_urg);
          } else {
            end = moment(data.date_in);
          }

          if(data.branc_date!=null || data.branca_validation){
            iterator.value=end.add(2,'hours').toISOString().slice(0, -8);
            console.log(iterator.value)
            setShowClearDateBnt(true);
          }
          else{
            iterator.value=null;
            setShowClearDateBnt(false);
          }
          break;
        case "stretchering":
          let value=(data.branca_validation==null?1:(data.branca_validation)?2:0);
          iterator.value=value;
          setEditboxSliderValue(value);
          break;
      }
    }

  

    // set select value for edit box
    let select = editboxRef.current.querySelector("#to");

    // si les option n'ont pas été générer, le créer
    if(select.innerHTML==""){
      addSelectOption(select,t("editbox select uf null"),null);
      for (const iterator of Object.keys(ufLabels)) {
        addSelectOption(select,"UF"+iterator.toString()+" - "+ufLabels[iterator],iterator);
      }
    }

    select.value=data.uf;

    loadBedOption(data.bed,data.uf,(a)=>{setCustomBed(a)});

    // set actual bnt
    if(actualBntData.current.element!=null){
      actualBntData.current.element.className=actualBntData.current.className;
    }

    actualBntData.current.element=element;
    actualBntData.current.className=element.className;
    element.className+=" editBnt-use";

    // set edit box animation
    editboxRef.current.className="editbox editbox-show";
    editboxRef.current.className="editbox editbox-show editbox-anim";
    
    // move scrol to editbox 

    // si il faut scroll ver pour focus le top ou le bottom de la notif
    let focusTop=((window.scrollY+window.innerHeight)>editboxRef.current.getBoundingClientRect().bottom)
    let headerOffset = focusTop?50:-50;
    let elementPosition = focusTop?editboxRef.current.getBoundingClientRect().top:editboxRef.current.getBoundingClientRect().bottom;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    document.body.style.minHeight=offsetPosition+"px";

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });

    setEditBoxShowState(true);
  }


  /**
   * Ajoute une option a un select
   * @param {dom ellement} select select où ajouter l'ellement
   * @param {string} label label a ajouter
   * @param {any} value value a ajouter
   * @param {int} index index où le rajouter
   */
  function addSelectOption(select,label, value, index=null){
    let option = document.createElement("option");
    option.text = label;
    option.value=value;
    if(index!=null){
      select.add(option,index)
    }
    else{
      select.add(option);
    }
  }

  /**
   * clear les option a un select
   * @param {dom ellement} select select où ajouter l'ellement
   */
  function clearSelectOption(select){
    select.innerHTML="";
  }

  function setBedOption(data,actualbed){
    let element=editboxRef.current.querySelector("#bed");
    clearSelectOption(element);

    // add sanai room

    addSelectOption(element,t("editbox select bed null"),null);

    let orderByRoom=data.sort((a,b)=>{
      return parseInt(a.chambre)-parseInt(b.chambre);
    });

    let lastRoom="";

    for (const iterator of orderByRoom) {

      if(iterator.status!="OL" && iterator.status!="OO")continue;   // skip les lit qui sont pas OO et OL

      // add room séparator
      if(lastRoom!=iterator.chambre){
        let option = document.createElement("option");
        option.className="roomSeperator";
        option.text = t("room", { ns: 'common' }).toLowerCase()+" "+iterator.chambre;
        option.value = null;
        option.disabled = true;
        element.add(option);
        lastRoom=iterator.chambre;
      }


      let option = document.createElement("option");
      option.text = t("bed", { ns: 'common' }).toLowerCase()+" "+iterator.lit;
      option.value = iterator.lit+"|"+iterator.chambre;
      option.disabled = (iterator.status!= "OL" && actualbed!=iterator.lit);
      element.add(option);
    }
  }


  /**
   * charge les lit disponible
   */
  function loadBedOption(bed,uf,callback=(a)=>{}){
    let sel=editboxRef.current.querySelector("#bed");

    let customeInput=true;

    if(sel==undefined){
      callback(customeInput);
      return;
    }

    // loading animation
    sel.className="form-control bedSelector selectLoading";
    sel.disabled=true;

    // set bed list
    getAvalidBed(uf,(data)=>{
      if(data==null)return;
      setBedOption(data[0],bed);
      let option=sel.options;
      for (let index = 0; index < option.length; index++) {
        const value = option[index].value.split("|")[0];
        if(value==bed){
          sel.selectedIndex=index;
          customeInput=false;
          break;
        }
      }

      // disabel loading animation
      sel.className="form-control bedSelector";
      sel.disabled=false;
      callback(customeInput);
    });
  }

  /**
   * Save edit box, use form
   */
  function saveEditBox(){
    // get data
    let data={};
    for (const iterator of editboxRef.current.querySelectorAll('input')) {
      switch(iterator.name){
        case "stretchering":
          let value=(iterator.value==1?null:(iterator.value==2));
          data[iterator.name]=value;
          break;
        default:
            data[iterator.name]=(iterator.value!="")?iterator.value:null;
          break;
      }
    }


    data["to"]=editboxRef.current.querySelector("#to").value;

    if(!customBed){
      let bedRoomValue=editboxRef.current.querySelector("#bed").value.split("|");
      data["bed"]=bedRoomValue[0];
      data["room"]=bedRoomValue[1];
    }

    let d ={
      "iep": editboxData.iep,
      "date": moment().format(),
      "brancardage": data["stretchering"],
      "branc_date": moment(data["stretcheringDate"]).format(),
      "uf": parseInt(data["to"]),
      "bed": data["bed"],
      "room": data["room"],
      "active": true
    };
    transiControllData.current.push(d);

    creatTransiControll_OnDataBase({
      "iep": editboxData.iep,
      "date": moment().format(),
      "brancardage": data["stretchering"],
      "branc_date": moment(data["stretcheringDate"]).format(),
      "uf": parseInt(data["to"]),
      "bed": data["bed"],
      "room": data["room"],
      "active": true
    });


    showTransiDataRender();
    closeEditBox();
  }

  function closeEditBox(){
    setEditBoxShowState(false);

    if(actualBntData.current.element!=null){
      actualBntData.current.element.className=actualBntData.current.className;
      actualBntData.current.element=null;
    }

    editboxRef.current.className="editbox editbox-hide-anim";

    document.body.style.minHeight="auto";
  }


  //#endregion

  //#region delet box
  function showDelBox(e,data){

    document.body.appendChild(dellboxRef.current);
    dellboxRef.current.style.top=(window.scrollY)+"px"

    // set delet message
    let delmessage=t("deletbox_message");

    setDelBoxMessage(delmessage.replace("{iep}",data.iep));


    setShowDellBox(true);
    dellboxData.current=data;


    // disabel scroll
    document.body.style.overflowY="hidden";

    // fermet un edit box si il y en un
    closeEditBox();
  }

  /**
   * Save la del box
   */
  function confirmDelBox(){
    let d = {
      "iep": dellboxData.current.iep,
      "date": moment().format(),
      "brancardage": null,
      "branc_date": null,
      "uf": null,
      "bed": null,
      "room": null,
      "active": false
    };
    transiControllData.current.push(d);

    creatTransiControll_OnDataBase(d);
    
    showTransiDataRender();
    closeDelBox();
  }

  function closeDelBox(){

    dellboxRef.current.className="dellbox dellbox-hiden-anim";


    setTimeout(function () {
      setShowDellBox(false);
    }, 125);

    // enabel scroll
    document.body.style.overflowY="auto";
  }

  //#endregion

  const renderEmergency = (transit) => {
    if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) / 1440) *
      nb;
    let width =
      (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
        1440) *
      nb;
    let Los = parseInt(
      moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "hours"),
    );
    let devDeg = Math.abs(Los - transit.los_dev_hours) / (Los + transit.los_dev_hours);
    let width_dev = width + transit.los_dev * nb;

    return (
      <>
        <div
          className={`predicted-los-wrapper-dev b-radius text-font`}
          style={{
            background: `linear-gradient(to right, ${transit.sex === "M" ? "rgb(0, 195, 245)" : "rgb(235, 161, 245)"}, ${
              devDeg * 100
            }%,rgba(0,0,0,0))`,
            marginLeft: `${startLeft}%`,
            width: `${width_dev}%`,
          }}
        ></div>
        <div
          className={clsx(
            `predicted-los-wrapper padding-0 text-font`,
            transit.hebergement_flag == 1 ? "add-H" : undefined,
          )}
          style={{
            background: "transparent",
            border: "none",
            marginLeft: startLeft + `%`,
            width: `${100}%`,
          }}
        >
          {transit.ideal_uf_name}
        </div>
        <div
          data-tip
          data-for={`tooltip-${transit.iep}`}
          className={`predicted-los-wrapper b-radius text-font`}
          style={{ marginLeft: `${startLeft}%`, width: `${width}%` }}
        />
        {setPreAdmTooltip(transit)}
      </>
    );
  };

  const setLOSTooltip = (los) => {
    return (
      <ReactTooltip id={`tooltip-${los["iep"]}`} place="bottom" effect="solid" style={{ zIndex: 20 }}>
        <ul className="tooltip-list">
          <li style={{ color: "white" }}>
            {`${t("prenom")} : ${Capitalize(los.bemPatient?.prenom ?? "-")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("nom")} : ${los.bemPatient?.nom.toUpperCase() ?? "-"}`}{" "}
          </li>
          {los.bemPatient?.nom_mar ? (
            <li style={{ color: "white" }}>
              {`${t("nom_mar")} : ${los.bemPatient.nom_mar.toUpperCase()}`}{" "}
            </li>
          ) : null}
          <li style={{ color: "white" }}>{`${t("iep")} : ${los.iep} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>{`${t("sex")} : ${t(los.sexe)}`} </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${los.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>{`${t("origin")} : ${los.ModeEntree.description} `} </li>
          <li style={{ color: "white" }}>{`${t("diagnostic code")} : ${
            los.diag !== null && los.diag !== "" ? los.diag : "-"
          }`}</li>
          <li style={{ color: "white" }}>{`${t("sector")} : ${los["Beds"][0].secteur}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${los["Beds"][0].lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(los["date_entree"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted release date")} : ${moment(los["date_sortie_predite"]).format(
              "DD.MM.YY A",
            )}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted duration")} : ${Math.round(los["los_moy"])} ${t("days")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of error`)} : +/- ${los.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderLosPred = (transit) => {
    if (parseInt(moment.utc(transit["date_entree"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_entree"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_entree"]).diff(moment.utc(startHeadDate), "minutes")) /
        1440) *
      nb;
    let width =
      (parseInt(
        moment
          .utc(transit["date_sortie_predite"])
          .diff(moment.utc(transit["date_entree"]), "minutes"),
      ) /
        1440) *
      nb;
    let LoS = parseInt(
      moment.utc(transit["date_sortie_predite"]).diff(moment.utc(transit["date_entree"]), "hours"),
    );
    let devDeg = (LoS - transit.los_dev * 24) / (LoS + transit.los_dev * 24);
    let width_dev = width + transit.los_dev * nb;
    return (
      <>
        <div
          className={`predicted-los-wrapper padding-0 text-font`}
          style={{
            background: `linear-gradient(to right, ${transit.sex === "M" ? "rgb(0, 195, 245)" : "rgb(235, 161, 245)"}, ${
              devDeg * 100
            }%,rgba(0,0,0,0))`,
            border: "none",
            marginLeft: `${startLeft}%`,
            width: `${width_dev}%`,
          }}
        >
          {transit.bemPatient ? bemPatientName(transit.bemPatient) : transit.iep}
        </div>
        <div
          data-tip
          data-for={`tooltip-${transit.iep}`}
          className="predicted-los-wrapper b-radius text-font b-solid"
          style={{
            backgroundColor: "transparent",
            paddingLeft: 5,
            marginLeft: startLeft + "%",
            width: `${width}%`,
          }}
        />

        {setLOSTooltip(transit)}
      </>
    );
  };

  const renderPredAdm = (transits, iep) => {
    return transits.map((transit) => {
      if (transit.hebergement_flag !== 0 || transit.iep === iep) return;
      if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
        transit["date_in"] = moment.utc(startHeadDate);
      }
      let startLeft =
        (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) /
          1440) *
        nb;
      let width =
        (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
          1440) *
        nb;

      return (
        <>
          <div
            data-tip
            data-for={`tooltip-${transit.iep}`}
            className={`predicted-los-wrapper b-radius b-dahed text-font`}
            style={{
              backgroundColor: "white",
              paddingLeft: 5,
              marginLeft: `${startLeft}%`,
              width: `${width}%`,
            }}
          >
            {transit.bemPatient ? bemPatientName(transit.bemPatient) : transit.iep}
          </div>
          {setPreAdmTooltip(transit)}
        </>
      );
    });
  };

  const renderPassageEmergency = (transit) => {
    if (parseInt(moment.utc(transit["date_out_urg"]).diff(moment.utc(startHeadDate))) < 0) return;

    if (parseInt(moment.utc(transit["date_in_urg"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in_urg"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in_urg"]).diff(moment.utc(startHeadDate), "minutes")) /
        1440) *
      nb;
    let width =
      (parseInt(
        moment.utc(transit["date_out_urg"]).diff(moment.utc(transit["date_in_urg"]), "minutes"),
      ) /
        1440) *
      nb;

    return (
      <li
        style={{ gridColumn: `1/15`, display: "flex" }}
        data-tip
        data-for={`tooltip-${transit.iep}-2`}
      >
        <div
          className={`real-los-wrapper b-radius text-font `}
          style={{
            backgroundColor: "rgb(237 57 255)",
            border: "solid 2px black",
            marginLeft: startLeft + `%`,
            width: `${width}%`,
          }}
        />

        <div
          className={`predicted-los-wrapper b-radius text-font `}
          style={{
            marginLeft: startLeft + `%`,
            width: `${100}%`,
            border: "none",
          }}
        >
          {transit.group4_flag === 1 ? t("urg g4").toUpperCase() : t("urg").toUpperCase()}
        </div>
        <img
          src={arrow_right}
          style={{ marginLeft: `${startLeft + width}%` }}
          className="icon-arrow"
        />
      </li>
    );
  };
  const renderHebergement = (transit, withoutStep2 = false) => {
    if (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate))) < 0) {
      transit["date_in"] = moment.utc(startHeadDate);
    }
    let startLeft =
      (parseInt(moment.utc(transit["date_in"]).diff(moment.utc(startHeadDate), "minutes")) / 1440) *
      nb;
    let width =
      (parseInt(moment.utc(transit["date_out"]).diff(moment.utc(transit["date_in"]), "minutes")) /
        1440) *
      nb;

    return (
      <li
        style={{ gridColumn: `1/15`, display: "flex" }}
        data-tip
        data-for={`tooltip-${transit.iep}-2`}
      >
        <div
          className={`real-los-wrapper b-radius text-font `}
          style={{
            background: "orange",
            marginLeft: startLeft + `%`,
            width: `${width}%`,
          }}
        ></div>
        <div
          className={`predicted-los-wrapper padding-0 text-font`}
          style={{
            background: "transparent",
            border: "none",
            marginLeft: startLeft + `%`,
            width: `${100}%`,
          }}
        >
          {transit.ideal_uf_name}
        </div>
        {!withoutStep2 && (
          <img
            src={arrow_right}
            style={{ marginLeft: `${startLeft + width}%` }}
            className="icon-arrow"
          />
        )}

        {setPreAdmTooltip(transit, true)}
      </li>
    );
  };

  const setPreAdmTooltip = (bedDispatch, test) => {
    return (
      <ReactTooltip
        id={`tooltip-${bedDispatch["iep"]}${test ? "-2" : ""}`}
        place="bottom"
        effect="solid"
      >
        <ul className="tooltip-list" style={{ color: "white", zIndex: 20 }}>
          <li style={{ color: "white" }}>
            {`${t("prenom")} : ${Capitalize(bedDispatch.bemPatient?.prenom ?? "-")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("nom")} : ${bedDispatch.bemPatient?.nom.toUpperCase() ?? "-"}`}{" "}
          </li>
          {bedDispatch.bemPatient?.nom_mar ? (
            <li style={{ color: "white" }}>
              {`${t("nom_mar")} : ${bedDispatch.bemPatient.nom_mar.toUpperCase()}`}{" "}
            </li>
          ) : null}
          <li style={{ color: "white" }}>{`${t("iep")} : ${bedDispatch.iep}`} </li>
          <li style={{ color: "white" }}>
            {`${t("sex")} : ${
              bedDispatch.sex === "M"
                ? t("m").toUpperCase()
                : bedDispatch.sex === "F"
                ? t("f").toUpperCase()
                : "-"
            }  `}{" "}
          </li>
          <li style={{ color: "white" }}>{`${t("age")} : ${bedDispatch.age} ${t("ans")}`} </li>
          <li style={{ color: "white" }}>
            {`${t("origin")} : ${
              bedDispatch.preadm_flag == "0"
                ? Capitalize(t("urgence"))
                : Capitalize(t("préadmission"))
            } `}{" "}
          </li>
          {bedDispatch.preadm_flag == "0" && (
            <li style={{ color: "white" }}>{`${t("reason")} : ${bedDispatch["motif"]} `} </li>
          )}
          <li style={{ color: "white" }}>{`${t("sector")} : ${bedDispatch.ideal_area}`} </li>
          <li style={{ color: "white" }}>{`${t("bed")} : ${bedDispatch.lit}`} </li>
          <li style={{ color: "white" }}>
            {`${t("date of entry")} : ${moment(bedDispatch["date_in"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("discharge date")} : ${moment(bedDispatch["date_out"]).format("DD.MM.YY A")}`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t("predicted duration")} : ${Math.round(bedDispatch["los"])} ${
              Math.round(bedDispatch["los"]) > 1 ? t("days") : t("days")
            }`}{" "}
          </li>
          <li style={{ color: "white" }}>
            {`${t(`margin of error`)} : +/- ${bedDispatch.los_dev.toFixed(1)} ${t("days")}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderdata1 = (transit) => {
    return (
      <div className="gantt__row w-100 parcours">
        <div className="gantt__row-first mrg-2">
          <div className="row w-100">
            <div className="col-2">
              <span className="txt-font2" style={{ color: "black" }}>
                {bemPatientName(transit.bemPatient)}
                <br />
                {transit.iep}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.sex}
              </span>
            </div>
            <div className="col-1 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.age}
              </span>
            </div>
            <div className="col-2 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.motif}
              </span>
            </div>

            <div className="col-4 ">
              <span className="txt-font2" style={{ color: "black" }}>
                {transit.orient_ideale_name !== ""
                  ? transit.orient_ideale_name
                  : transit.ideal_uf_name}{" "}
              </span>
            </div>
            <div className="col-2 ">
              <div className="rel">
                <div className="abso-icons">
                  <FaBed
                    className="bed"
                    style={{
                      color: "black",
                      fontSize: "1.45vw",
                      textAlign: "right",
                    }}
                  ></FaBed>
                  <ImArrowRight
                    className="arrow2"
                    style={{
                      color: "black",
                      fontSize: "1.45vw",
                      textAlign: "left",
                    }}
                  ></ImArrowRight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderdata2 = (transit) => {
    return (
      <div className="gantt__row w-100 parcours">
        <ul className="gantt__row-bars parcours">
          {renderPassageEmergency(transit)}

          {props.data.iepUrgHeberg[transit.iep] &&
            renderHebergement(props.data.iepUrgHeberg[transit.iep])}
          <li style={{ gridColumn: `1/15`, display: "flex" }}>
            {renderEmergency(transit)}
            {props.data.bedUfLoss[transit.lit + "/" + transit.uf] &&
              renderLosPred(props.data.bedUfLoss[transit.lit + "/" + transit.uf])}
            {props.data.bedUfPreAdm[transit.lit + "/" + transit.uf] &&
              renderPredAdm(props.data.bedUfPreAdm[transit.lit + "/" + transit.uf], transit.iep)}
          </li>
        </ul>
      </div>
    );
  };

  const renderDataTransit = () => {
    const transitData = [
      ...props.data.bedDispatching_urgence,
      ...props.data.bedDispatching_urgence_pred,
      ...props.data.urg_herberg_without_step2,
    ]
      // INFO: transit with brancardage_ask first
      .sort(function (a, b) {
        try {
          if (a.brancardage_ask !== b.brancardage_ask) {
            return a.brancardage_ask ? -1 : 1;
          } else if (a.brancardage_ask) {
            return compareDesc(new Date(a.date_time), new Date(b.date_time));
          } else {
            return compareDesc(new Date(a.date_in), new Date(b.date_in));
          }
        } catch (e) {
          console.error("Error sorting transit", e);
          return 0;
        }
      })
      .filter((transit) => transit.orient_ideale_name !== "");

    if (transitData.length === 0) {
      return (
        <div role="alert">
          <h1 className="text-center w-100" style={{ marginTop: "5%" }}>
            {t("no transit journey")}...
          </h1>
        </div>
      );
    } else {
      return transitData.map(function (transitData, i) {

        let transit = transitData;

        const notOkColor = "rgb(230, 0, 76)";
        const okColor = "rgb(22, 230, 115)";
        const okColor2 = "rgb(0, 173, 108)";
        const waitColor = "rgb(255, 205, 0)";

        let bdbm = getBedDispatchBedManagement(transit.iep);
        let ptah = getPtha_pack(transit.iep);

        let transiControll=getTransiControllData(transit.iep);

        // si il y a un transi controlle
        if(transiControll!=null){
          // si pas active => transi suprimer
          if(transiControll.active==false)return null;

          transit.lit=(transiControll.bed!=null)?transiControll.bed:transit.lit;
          transit.uf=(transiControll.uf!=null)?transiControll.uf:transit.uf;
          transit.branca_validation=(transiControll.brancardage!=null)?transiControll.brancardage:transit.branca_validation;
          transit.branc_date=transiControll.branc_date;
        }

        let statue = "";

        let isIconShow = true;
        let statueInfoData = {
          name: "Statue",
          addInfo: "",
          color: notOkColor,
          color2: okColor,
          ratio: 100,
        };
        let errorMessageData = { text: "", color: "" };
        // determine statue
        if (ptah && ptah.fake==undefined) {
          switch (ptah.event) {
            case "ACPAT":
              statue = "Pat_BranOrAmb";
              break;
            case "ARPAT":
              statue = "Pat_Arrive";
              break;
            case "TFRDV":
              statue = "Pat_OriRens_WaitBran";
              break;
            case "DPPAT":
              statue = "Pat_Go";
              break;
            case "ARBLC": {
              statue = "Pat_Bloc";
              break;
            }
          }
        } else {
          if (transit.waiting_flag && transit.branca_validation) {
            statue = "Pat_OriRens_NoBran";
          } else if (!transit.branca_validation && transit.waiting_flag ) {
            statue = "Pat_NoBran";
          }
          else{;
            statue="Pat_OriRens_Bran";
          }
        }

        // set ratio
        switch (statue) {
          case "Pat_OriRens_NoBran":
            let ratio = 0;
            let end;
            let now = moment();
            if(transit.branc_date!=null){
              end = moment(transit.branc_date);
            }
            else if(transit.date_in_urg && transit.date_out_urg) {
              end = moment(transit.date_out_urg);
            } else {
              end = moment(transit.date_in);
            }


            console.log(end.format("HH:mm"));

            if(transiControll!=null){
              end=end.subtract(props.data.bedDispatchParameters.fulfillmentValue.total_time_to__hospit_from_emer.minutes,"minute").subtract(1,"hours");
            }

            let diff = end.diff(now);
            diff = diff > 0 ? diff : 0;

            statueInfoData = {
              name: "ATTENTE / ",
              addInfo: moment.utc(diff).format("HH:mm"),
              color: notOkColor,
              color2: okColor,
              ratio: 0,
            };
            isIconShow = false;
            break;
          case "Pat_OriRens_Bran":
            statueInfoData.name = "";
            isIconShow = true;
            break;
          case "Pat_OriRens_WaitBran":
            statueInfoData = {
              name: "CONF. / ",
              addInfo: moment(ptah.event_date).format("HH:mm"),
              color: okColor,
              color2: okColor,
              ratio: 100,
            };
            isIconShow = true;
            break;
          case "Pat_BranOrAmb":
            statueInfoData = {
              name: "PEC / ",
              addInfo: moment(ptah.event_date).format("HH:mm"),
              color: okColor,
              color2: okColor,
              ratio: 100,
            };
            isIconShow = true;
            break;
          case "Pat_Go":
            statueInfoData = {
              name: "DEP. PAT / ",
              addInfo: moment(ptah.event_date).format("HH:mm"),
              color: okColor,
              color2: okColor,
              ratio: 100,
            };
            isIconShow = true;
            break;
          case "Pat_Arrive":
            statueInfoData = {
              name: "ARR. PAT / ",
              addInfo: moment(ptah.event_date).format("HH:mm"),
              color: okColor2,
              color2: okColor2,
              ratio: 100,
            };
            isIconShow = true;
            break;
          case "Pat_Bloc":
            statueInfoData = {
              name: "ARR / ",
              addInfo: moment(ptah.event_date).format("HH:mm"),
              color: okColor2,
              color2: okColor2,
              ratio: 100,
            };
            isIconShow = true;
            break;
          case "Pat_NoBran":
            statueInfoData = {
              name: "pas de brancardage",
              addInfo:"",
              color: waitColor,
              color2: waitColor,
              ratio: 100,
            };
            isIconShow = false;
            break;
          default:
            statueInfoData.name = "";
            isIconShow = false;
            break;
        }
        

        if (statueInfoData.ratio > 99.5) {
          statueInfoData.ratio = 0;
          statueInfoData.color = statueInfoData.color2;
        }

        if (bdbm != null) {
          errorMessageData.text = t("brancaradge_erroreMessage");
          errorMessageData.color = "#E81A0C";
        }

        if(transit.waiting_for_bed){
          statueInfoData = {
            name: t("brancaradge_waitMessage"),
            addInfo: "",
            color: "#ffb803",
            color2: "#ffb803",
            ratio: 100,
          };
        }

        let canBeEdit=!transit.brancardage_ask;

        return (
          <div
            key={i}
            className="row div-gantt parcours"
            id="div-gantt2"
            style={{ backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white", maxWidth: "100%" }}
          >
            {getEditPermision()?
            <div className="editBntHolder">
              <div className={"editBnt editBnt-"+(canBeEdit?"enable":"disabel")} onClick={
                (canBeEdit)?
                  (e)=>{
                    showEditBox(e.currentTarget.parentElement,{
                      iep:transit.iep,
                      name:bemPatientName(transit.bemPatient),
                      sex:transit.sex,
                      age:transit.age,
                      bed:transit.lit,
                      orient:transit.orient_ideale_name,
                      uf:transit.uf,
                      brancardage_ask:transit.brancardage_ask,
                      branca_validation:transit.branca_validation,
                      date_in_urg:transit.date_in_urg,
                      date_out_urg:transit.date_out_urg,
                      date_in:transit.date_in,
                      date_out:transit.date_out,
                      branc_date:transit.branc_date
                    })}
                :null}>
                <div className="editIcon"></div>
              </div>
            </div>:null
            }
            {getEditPermision()?
            <div className={"dellBntHolder"}>
              <div className={"dellBnt"} onClick={(e)=>{showDelBox(e.currentTarget,{iep:transit.iep});}}>
                <div className="dellIcon"></div>
              </div>
            </div>:null
            }
            <div className="gantt__row gantt__row--months-parcours" style={{zIndex:"auto"}}>
              <div className="gantt__row-first">
                <table className="w-100 h-100">
                  <thead>
                    <tr>
                      <th className="txt-font" style={{ flex: "5 0 auto" }}>
                        {t("prenom")} {t("nom")} / {t("iep")}
                      </th>
                      <th className="txt-font" style={{ flex: "5 0 auto" }}>
                        {t("sex")}
                      </th>
                      <th className="txt-font" style={{ flex: "5 0 auto" }}>
                        {t("age")}
                      </th>
                      {transit.lit ? (
                        <th className="txt-font" style={{ flex: "5 0 auto" }}>
                          {t("lit")}
                        </th>
                      ) : null}
                      <th className="txt-font" style={{ flex: "5 0 auto" }}>
                        {t("urg adm reason")}
                      </th>
                      <th className="txt-font" style={{ flex: "5 0 auto" }}>
                        {t("ideal ward")}
                      </th>
                      {transit.uf_admin ? (
                        <th className="txt-font" style={{ flex: "5 0 auto" }}>
                          {t("UFH")}
                        </th>
                      ) : null}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="txt-font2" style={{ color: "black" }}>
                        {bemPatientName(transit.bemPatient)} <br /> {transit.iep}
                      </td>
                      <td className="txt-font2" style={{ color: "black" }}>
                        {transit.sex}
                      </td>
                      <td className="txt-font2" style={{ color: "black" }}>
                        {transit.age}
                      </td>
                      {transit.lit ? (
                        <td className="txt-font2" style={{ color: "black" }}>
                          {transit.lit}
                        </td>
                      ) : null}
                      <td className="txt-font2" style={{ color: "black" }}>
                        {transit.motif}
                      </td>
                      <td className="txt-font2" style={{ color: "black" }}>
                        {transit.orient_ideale_name !== ""
                          ? transit.orient_ideale_name
                          : transit.spec_envisagee_name}{" "}
                      </td>
                      {transit.uf_admin ? (
                        <td className="txt-font2" style={{ color: "black" }}>
                          {ufLabels[transit.uf] ?? transit.uf}
                        </td>
                      ) : null}
                      <td>
                        <div className="rel">
                          {isIconShow ? (
                            <div className="abso-icons iconHolder">
                              <FaBed
                                className="bed"
                                style={{
                                  color: "black",
                                  fontSize: "1.45vw",
                                  textAlign: "right",
                                }}
                              ></FaBed>
                              <ImArrowRight
                                className="arrow2"
                                style={{
                                  color: "black",
                                  fontSize: "1.45vw",
                                  textAlign: "left",
                                }}
                              ></ImArrowRight>
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <div className="gantt__row--lines" data-month="5">
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                  <span className="marker2"></span>
                </div>
                <div className="gantt__row-second">{renderDates()}</div>
                <ul className="gantt__row-bars parcours">
                  {renderPassageEmergency(transit)}

                  {transit["withoutStep2"] === true
                    ? renderHebergement(transit, true)
                    : props.data.iepUrgHeberg[transit.iep]
                    ? renderHebergement(props.data.iepUrgHeberg[transit.iep])
                    : null}
                  {transit["withoutStep2"] !== true && (
                    <li style={{ gridColumn: `1/15`, display: "flex" }}>
                      {renderEmergency(transit)}
                      {props.data.bedUfLoss[transit.lit + "/" + transit.uf] &&
                        renderLosPred(props.data.bedUfLoss[transit.lit + "/" + transit.uf])}
                      {props.data.bedUfPreAdm[transit.lit + "/" + transit.uf] &&
                        renderPredAdm(
                          props.data.bedUfPreAdm[transit.lit + "/" + transit.uf],
                          transit.iep,
                        )}
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="gantt__row gantt__row--months-parcours" style={{zIndex:0}}>
              <div className="gantt__row-first">
                <div className="row w-100">
                  <div className="statueShower">
                    {errorMessageData.text != "" ? (
                      <label
                        className="statueShower_error"
                        style={{ backgroundColor: errorMessageData.color }}
                      >
                        {t(errorMessageData.text)}
                      </label>
                    ) : (
                      <div></div>
                    )}
                    {(statueInfoData.name) != "" ? (
                      <div
                        className="statueShower_statue"
                        style={{ backgroundColor: statueInfoData.color }}
                      >
                        <div
                          id="progressBar"
                          style={{
                            width: statueInfoData.ratio + "%",
                            backgroundColor: statueInfoData.color2,
                          }}
                        ></div>
                        <label>{t(statueInfoData.name) + statueInfoData.addInfo}</label>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  /*
  const renderDataTransitResponsive = () => {
    let transitData = [
      ...props.data.bedDispatching_urgence,
      ...props.data.bedDispatching_urgence_pred,
      ...props.data.urg_herberg_without_step2,
    ].filter((transit) => transit.orient_ideale_name !== "");
    if (transitData.length === 0) {
      return (
        <div role="alert">
          <h2 className="text-center " style={{ marginTop: "5%" }}>
            {t("no transit journey")}...
          </h2>
        </div>
      );
    } else {
      return transitData.map((transit, i) => {
        return (
          <div
            key={i}
            className="row div-gantt parcours"
            id="div-gantt2"
            style={{ backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white" }}
          >
            <div className="gantt__row gantt__row--months-parcours">
              <div className="gantt__row-first">
                <div className="row w-100">
                  <div className="col-1">
                    <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                      {bemPatientName(transit.bemPatient)}
                      <br />
                      {t("iep")}{" "}
                    </span>
                  </div>
                  <div className="col-1 ">
                    <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                      {t("sex")}{" "}
                    </span>
                  </div>
                  <div className="col-1 ">
                    <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                      {t("age")}{" "}
                    </span>
                  </div>
                  <div className="col-2 ">
                    <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                      {t("urg adm reason")}{" "}
                    </span>
                  </div>

                  <div className="col-4 ">
                    <span className="txt-font" style={{ display: "block", flex: "5 0 auto" }}>
                      {t("ideal ward")}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {renderdata1(transit)}

            <div className="gantt w-100">
              <div className="gantt__row gantt__row--dates"> {renderDates()}</div>
              <div className="gantt__row gantt__row--lines" data-month="5">
                <span className=""></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
              </div>
              {renderdata2(transit)}
            </div>
          </div>
        );
      });
    }
  };
  */

  return (
    <div className="row mrg-t">
      <div ref={editboxRef} className={"editbox editbox-hide"}>
        {editboxData!=null?
        <div className="content">
            <div className="base-info">
              <div className="info">
                <h2>{t("prenom", { ns: 'common' })} <h2 className="name">{t("nom", { ns: 'common' })}</h2></h2>
                <label>
                  {editboxData.name}
                </label>
              </div>
              <div className="info">
                <h2>{t("sex", { ns: 'common' })}</h2>
                <label>
                  {editboxData.sex}
                </label>
              </div>
              <div className="info">
                <h2>{t("age", { ns: 'common' })}</h2>
                <label>
                  {editboxData.age}
                </label>
              </div>
            </div>
            <div className="form">
              <div className="value">
                <label>{t("editbox to")}</label>
                <select onChange={(e)=>{
                  if(customBed)return;

                  let val=e.target.value;
                  if(val==null){

                    // donne comme option uniquement l'option null
                    let element=editboxRef.current.querySelector("#bed");
                    clearSelectOption(element);
                
                    addSelectOption(element,t("editbox select bed null"),null);
                  }
                  else{
                    loadBedOption(null,val);
                  }
                }} id="to" offset={-50}>
                </select>
              </div>
              {customBed?
              <div className="value">
                <label>{t("editbox room")}</label>
                <input className="form-control bedSelector" name="room" placeholder={t("room placeHolder")}/>
              </div>:null}
              {!customBed?<div className="value">
                <label>{t("bed", { ns: 'common' })}</label>
                <div className="bedSelectContainer">
                  <select className="form-control bedSelector" offset={-50} id="bed" name="bed" />
                  <div className="customeInputToggle">
                    <input type="checkbox" value={customBed} onChange={(e)=>setCustomBed(true)}/>
                    <span class="checkmark"></span>
                  </div>
                </div>
              </div>:
              <div className="value">
                <label>{t("bed", { ns: 'common' })}</label>
                <div className="bedSelectContainer">
                  <input className="form-control bedSelector" name="bed" placeholder={t("bed placeHolder")}/>
                  <div className="customeInputToggle">
                    <input type="checkbox" value={customBed} onChange={(e)=>{
                      setCustomBed(false);
                    }}/>
                    <span class="checkmark"></span>
                  </div>
                </div>
              </div>
              }
              <div className="value double-value">
                <div style={{width:"35%"}}>
                  <label>{t("editbox stretchering")}</label>
                  <div className="hasStretchering">
                    <div className="hasStretchering-text">
                      <label id="hasStretchering-text-1" className={(editboxSliderValue==0)?"activeLabel":""}>{t("editbox stretchering false")}</label>
                      <label id="hasStretchering-text-2" className={(editboxSliderValue==1)?"activeLabel":""}>{t("editbox stretchering null")}</label>
                      <label id="hasStretchering-text-3" className={(editboxSliderValue==2)?"activeLabel":""}>{t("editbox stretchering true")}</label>
                    </div>
                    <input onChange={(e)=>{setEditboxSliderValue(e.target.value)}} className="form-control customRange" type="range" min="0" max="2" name="stretchering" />
                  </div>
                </div>
                <div style={{width:"40%"}}>
                  <label>{t("editbox date stretchering")}</label>
                  <div className="DataInputHolder">
                    <input style={{marginTop:"10px"}} onInput={(e)=>{
                      setShowClearDateBnt(e.target.value!="");
                    }} onClick={(e)=>{
                      //e.target.value=(e.target.value=="")?moment().add(1,'hours').toISOString().slice(0, -8):e.target.value;
                    }} className="form-control" type="datetime-local" name="stretcheringDate" />
                    
                    <div onClick={()=>{
                      editboxRef.current.querySelector('input[name="stretcheringDate"]').value="";
                      setShowClearDateBnt(false);
                    }} hidden={!showclearDateBnt} className="clearBnt"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="option">
              <button onClick={()=>{saveEditBox()}}>{t("save", { ns: 'common' })}</button>
              <button className="cancel" onClick={()=>{closeEditBox()}}>{t("cancel", { ns: 'common' })}</button>
            </div>
          </div>
        :null}
      </div>
      <div ref={dellboxRef} className={"dellbox "+((showDellBox)?"dellbox-show":"dellbox-hiden")}>
        <div className="box">
          <div className="icon"></div>
          <div className="text">
            {delBoxMessage}
          </div>
          <div className="option">
            <button className="ok" onClick={confirmDelBox}>
              {t("comfirm", { ns: 'common' })}
            </button>
            <button className="notOk" onClick={closeDelBox}>
              {t("cancel", { ns: 'common' })}
            </button>
          </div>
        </div>
      </div>
      <div className="container-user" style={{ marginTop: 20 }}>
        <div className="row"></div>
        <div className="col-12 scroll-gant" style={{ marginTop: "2%" }}>
          {dataTransit}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenJourneyTransit);
