import React, { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import { confirmAlert } from "react-confirm-alert";

const ListDesiderataValidated = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [load, setLoad] = useState(false);
  const [globalAllDesideratassData, setGlobalAllDesideratassData] = useState(props.desiderata);
  const [filterDesideratasData, setFilterDesideratasData] = useState(props.desiderata);
  const [daysValidated, setDaysValidated] = useState(props.daysValidated);
  const [optionsDoctors, setoptionsDoctors] = useState([]);
  const [selectedOptionDoctor, setoptionsDoctorselected] = useState({
    value: null,
    label: t("all doctors"),
  });
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    let doctors = [];

    globalAllDesideratassData.map((doctor) => {
      doctors.push({
        value: doctor.index,
        label: doctor.nom.toUpperCase() + " " + doctor.prenom.toUpperCase(),
      });
    });
    setoptionsDoctors([{ value: null, label: t("all doctors") }, ...doctors]);
  }, []);

  useEffect(() => {
    let desideratas = [];

    globalAllDesideratassData.map((d) => {
      if (
        selectedOptionDoctor &&
        selectedOptionDoctor.value !== null &&
        selectedOptionDoctor.value !== d.index
      )
        return;
      desideratas = [...desideratas, d];
    });

    setFilterDesideratasData(desideratas);
  }, [selectedOptionDoctor, globalAllDesideratassData]);
  const confirmationValidDesiderata = (desiderata) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to valid this desiderata ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => validDesiderata(desiderata),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const validDesiderata = (desiderata) => {
    let desiderataValidated = {
      index: desiderata.index,
      cause: "",
      status: "validated",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderataValidated,
    })
      .then((response) => {
        let _plan = response.data;
        if (_plan.Types.denomination === "Vacance") {
          daysValidated[desiderata.member_id] =
            daysValidated[desiderata.member_id] +
            moment(_plan.endDate).diff(moment(_plan.startDate), "days") +
            1;

          setDaysValidated(daysValidated);
        }
        props.editStatusDesiderata(_plan);
        setShowMessage(true);
        setMessage({ edit: "the desiderata was successfully validated." });
        setTimeout(() => setShowMessage(false), 3000);
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const renderTableDesiderata = () => {
    return (
      <div>
        <div className="overflow" style={{ marginTop: 10 }}>
          <table
            className="table table-translation table-striped table-bordered table-scroll"
            style={{ width: "100%" }}
          >
            <thead className="fix-header">
              <tr>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("doctors")}</span>
                </th>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("types")}</span>
                </th>

                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("date start desederata")}</span>
                </th>
                <th className="header" style={{ width: "11%" }}>
                  <span style={{ display: "block" }}> {t("date end desederata")}</span>
                </th>
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>
                    {t("vacation day already granted during the year")}
                  </span>
                </th>
                {/* <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>
                    {t("number of current requests over the period")}
                  </span>
                </th> */}
                <th className="header" style={{ width: "12%" }}>
                  <span style={{ display: "block" }}>{t("desederata status")}</span>
                </th>
                <th className="header" style={{ width: "8%" }}>
                  <span style={{ display: "block" }}>{t("action")}</span>
                </th>
              </tr>
            </thead>
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    );
  };
  const renderList = () => {
    if (!filterDesideratasData) return null;

    return filterDesideratasData.map((member) => {
      return (
        member.Planning &&
        member.Planning.length > 0 &&
        member.Planning.map(
          (desiderata, i) =>
            desiderata.status === "canceled" && (
              <tr key={i}>
                <td className="txt-center-middle">
                  {member.nom} {member.prenom}
                </td>
                <td className="txt-center-middle">{desiderata.Types.denomination}</td>
                <td className="txt-center-middle">
                  {moment(desiderata.startDate).format("YYYY-MM-DD")}
                  <br />
                  {desiderata.startShift === null || desiderata.startShift === ""
                    ? "-"
                    : desiderata.startShift}
                </td>
                <td className="txt-center-middle">
                  {moment(desiderata.endDate).format("YYYY-MM-DD")}
                  <br />
                  {desiderata.endShift === null || desiderata.endShift === ""
                    ? "-"
                    : desiderata.endShift}
                </td>
                <td className="txt-center-middle">
                  {daysValidated[member.index] ? daysValidated[member.index] : 0}
                </td>
                <td className="txt-center-middle">{t("status on cancel")}</td>
                <td style={{ backgroundColor: "white" }}>
                  <a
                    onClick={() => confirmationValidDesiderata(desiderata)}
                    className="icon-size btn btn-success"
                  >
                    {t("validated")}
                  </a>
                </td>
              </tr>
            ),
        )
      );
    });
  };

  const editStatus = (status) => {
    setIsEdited(false);

    let desiderata = {
      startDate: moment().format("YYYY-MM-DD HH:mm"),
      endDate: moment().format("YYYY-MM-DD HH:mm"),
      description: "",
      type_id: 1,
      member_id: 14,
      status: status,
    };
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div>
          <div
            className="col-12 d-flex justify-content-between flex-col"
            style={{
              textAlign: "end",
              marginTop: 20,
              flexWrap: "wrap",
              padding: 0,
            }}
          >
            <button
              onClick={() => props.setPage("listDesideratasongoing")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
            >
              {t("desiderata ongoing")}
            </button>
            <button
              onClick={() => props.setPage("listDesideratasValidated")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
            >
              {t("desiderata validated")}
            </button>
            <button
              onClick={() => props.setPage("listDesideratasCanceled")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
              disabled={true}
            >
              {t("desiderata canceled")}
            </button>
            <div className="col-lg-4 col-md-6 col-12" style={{ zIndex: 6 }}>
              <Select
                value={selectedOptionDoctor}
                className="selectJ1 form-select"
                isSearchable={false}
                onChange={(selectedOptionDoctor) => setoptionsDoctorselected(selectedOptionDoctor)}
                options={optionsDoctors}
              />
            </div>
          </div>
        </div>
        <div
          className="message"
          style={{
            marginTop: 20,
            marginBottom: 20,
            height: "50px",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          {showMessage &&
            message &&
            Object.entries(message).map((msg) => (
              <div className="alert alert-success w-85" role="alert">
                <h3>{t(msg)}</h3>
              </div>
            ))}
          {showError &&
            error &&
            Object.entries(error).map((err) => (
              <div className="alert alert-danger w-85" role="alert">
                <h3>{t(err)} </h3>
              </div>
            ))}
        </div>
        {renderTableDesiderata()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListDesiderataValidated);
