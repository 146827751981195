import React, { useState, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import "chartjs-plugin-annotation";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/nl";
import axios from "axios";
import { API } from "../../../utils/api";
import { Capitalize } from "../../../utils/functions";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Table from "./table";

Moment.globalTimezone = "Europe/Paris";

let orderType = {
  Arrivée: 1,
  Triage: 2,
  "Examen médical": 3,
  Laboratoire: 4,
  Imagerie: 5,
  "Fin de PEC Médicale": 6,
  avisdemande: 7,
  out: 8,
};

const ScreenJourneyPerformance = (props) => {
  const { t, i18n } = useTranslation(["journey"]);

  const [dataPerformance, setDataPerformance] = useState(null);
  const [globalData, setGlobalData] = useState(null);
  const [firstData, setFirstData] = useState(null);
  const [First, setFirst] = useState(true);
  const [load, setLoad] = useState(false);
  const [loadB, setLoadB] = useState(false);
  const [search, setSearch] = useState("");
  const [intervalRequest, setInterv] = useState(1500);

  const [indicator, setIndicator] = useState(null);
  const [timer, setTimer] = useState(null);
  const [lock, setLock] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchData = (startDate = null, endDate = null, load = false) => {
    let data;
    setLoad(true);
    if (startDate && endDate) {
      data = {
        startDate: moment(startDate).format("YYYY-MM-DD 10:00"),
        endDate: moment(endDate).format("YYYY-MM-DD 10:00"),
      };
    }
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.SCREEN_JOURNEY_FLUX}`,
      headers: {
        "x-auth-token": props.activeUser.user.token,
      },
      data: data ? data : null,
    })
      .then((res) => {
        setGlobalData(res.data.data);
      })
      .catch((err) => {
        // console.log('err:',err)
      });
  };

  useEffect(() => {
    fetchData();
  }, [1]);

  const reset = () => {
    fetchData();

    setEndDate(null);
    setStartDate(null);
  };

  useEffect(() => {
    if (!globalData) return;
    sortData("");
    if (First) {
      setFirst(false);
      setFirstData(globalData);
    }
  }, [globalData]);

  const sortData = (Iep = "") => {
    setLoad(true);
    setLock(true);

    let dataPerformances = [];

    if (Iep !== "") {
      globalData.map((item) => {
        if (item.iep.toString().includes(Iep)) dataPerformances = [...dataPerformances, item];
      });
    } else {
      dataPerformances = [...globalData];
    }
    dataPerformances.sort((a, b) => sort(a, b));
    setDataPerformance(dataPerformances);

    let indicator = { h: 0, s: 0, t: 0 };
    let i = 0;
    dataPerformances.map((item) => {
      indicator["h"] += getTime(item.SeqPredPerf.timing_score);
      indicator["s"] += item.SeqPredPerf.seq_score;
      indicator["t"] += item.SeqPredPerf.er_out_score;
      i++;
    });
    indicator["h"] = indicator["h"] / i;
    indicator["s"] = indicator["s"] / i;
    indicator["t"] = indicator["t"] / i;
    setIndicator(indicator);
    setLoad(false);
    setLock(false);
  };

  const sort = (a, b) => {
    let e1 = getEvents(a.SeqPredEvent);
    let e2 = getEvents(b.SeqPredEvent);

    if (!e1["event"]["Arrivée"]) return 1;
    if (!e2["event"]["Arrivée"]) return -1;

    return moment(e1["event"]["Arrivée"]["event_time"]).diff(
      moment(e2["event"]["Arrivée"]["event_time"]),
    ) > 0
      ? 1
      : -1;
  };

  const renderTable = () => {
    if (!dataPerformance || load) {
      return (
        <div className="col-12">
          <div
            className="row"
            style={{
              justifyContent: "center",
              minHeight: 150,
              alignItems: "flex-end",
            }}
          >
            <ReactLoading className="" type={"bars"} color="#2fa2d9" width="5%" />
          </div>
        </div>
      );
    } else {
      return <Table page={1} data={dataPerformance} indicator={indicator} />;
    }
  };

  const getTime = (time) => {
    if (!time) return 0;
    let hour = 0;
    hour +=
      (time.days ? time.days * 24 : 0) +
      (time.hours ? time.hours : 0) +
      (time.minutes ? time.minutes / 60 : 0) +
      (time.seconds ? time.seconds / 3600 : 0);
    return hour;
  };

  const getEvents = (events) => {
    let data = events.filter(
      (item) =>
        item.event_type.includes("RX standard") ||
        item.event_type.includes("Fin de PEC Médicale") ||
        item.event_type.includes("Arrivée") ||
        item.event_type.includes("uf") ||
        item.event_type.includes("avisdemande") ||
        item.event_type.includes("out") ||
        item.event_type.includes("Triage") ||
        item.event_type.includes("Imagerie autre") ||
        item.event_type.includes("Examen médical") ||
        item.event_type.includes("Laboratoire"),
    );

    let prog = 0;
    let test = false;

    const arrayToObject = (data) =>
      data.reduce((obj, item) => {
        if (item.event_type.includes("out") && !test) {
          obj["out"] = { ...item };

          if (item.confidence === 1) prog = orderType["out"];
        } else if (item.event_type.includes("uf")) {
          obj["out"] = { ...item };
          let split = item.event_type.split("_");
          obj["type_out"] = split[1] === "other" ? "UF Autre" : `UF ${split[1]}`;
          test = true;
        } else if (item.event_type.includes("Arrivée")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Arrivée"] > prog) prog = orderType["Arrivée"];
        } else if (item.event_type.includes("Triage")) {
          obj[item.event_type] = item;

          if (item.confidence === "1" && orderType["Triage"] > prog) prog = orderType["Triage"];
        } else if (item.event_type.includes("Examen médical")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Examen médical"] > prog)
            prog = orderType["Examen médical"];
          if (item.confidence < 1 && orderType["Examen médical"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("Laboratoire")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Laboratoire"] > prog)
            prog = orderType["Laboratoire"];
          if (item.confidence < 1 && orderType["Laboratoire"] === prog) prog = prog - 1;
        } else if (
          item.event_type.includes("RX standard") ||
          item.event_type.includes("Imagerie autre")
        ) {
          obj["Imagerie"] = item;
          if (item.confidence === 1 && orderType["Imagerie"] > prog) prog = orderType["Imagerie"];
          if (item.confidence < 1 && orderType["Imagerie"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("Fin de PEC Médicale")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["Fin de PEC Médicale"] > prog)
            prog = orderType["Fin de PEC Médicale"];
          if (item.confidence < 1 && orderType["Fin de PEC Médicale"] === prog) prog = prog - 1;
        } else if (item.event_type.includes("avisdemande")) {
          obj[item.event_type] = item;
          if (item.confidence === 1 && orderType["avisdemande"] > prog)
            prog = orderType["avisdemande"];
          if (item.confidence < 1 && orderType["avisdemande"] === prog) prog = prog - 1;
        }

        return obj;
      }, {});

    let result = arrayToObject(data);

    return { event: result, events: data, prog: prog };
  };

  const handleSearch = (e) => {
    let s = e.target.value;

    setSearch(s);
    clearTimeout(timer);

    setLock(true);
    setTimer(
      setTimeout(() => {
        sortData(s);
      }, intervalRequest),
    );
  };

  const resetSearch = () => {
    let s = "";

    setSearch(s);
    clearTimeout(timer);
    setLock(true);
    setTimer(
      setTimeout(() => {
        sortData(s);
      }, intervalRequest),
    );
  };

  return (
    <div className="row">
      <div className="w-100 first-row" style={{ marginTop: "1%" }}>
        <div className="row row0">
          <div
            className="col-12 col-md-3 col-lg-3"
            style={{
              paddingRight: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <input
              style={{ width: "80%" }}
              placeholder="IEP"
              value={search}
              onChange={handleSearch}
              className="form-control"
            />
            {lock ? (
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="12%" />
            ) : (
              <button
                disabled={search.length === 0}
                onClick={resetSearch}
                style={{ width: "120px", marginLeft: "20px", height: "40px" }}
                type="button"
                className="btn btn-info"
              >
                {Capitalize(t("reset"))}
              </button>
            )}
          </div>
          <div className="col-8 offset-1 col-md-3 col-lg-2" style={{ zIndex: 9 }}>
            <DatePicker
              style={{ borderColor: "" }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              isClearable
              placeholderText={t("from")}
              dateFormat="dd/MM/yyyy"
              maxDate={endDate ? endDate : new Date(moment().subtract(1, "day"))}
            />
          </div>
          <div className="col-8 offset-1 col-md-3 col-lg-2" style={{ zIndex: 9 }}>
            <DatePicker
              selected={endDate}
              isClearable
              placeholderText={t("to")}
              onChange={(date) => setEndDate(date)}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
            />
          </div>
          {(startDate || endDate) && (
            <div
              className="col-8 col-md-3 col-lg-2 offset-1"
              style={{
                zIndex: 9,
                display: "flex",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              {loadB ? (
                <ReactLoading className="" type={"bars"} color="#2fa2d9" width="70px" />
              ) : (
                <>
                  <button
                    onClick={() => fetchData(startDate, endDate)}
                    style={{ width: "120px", height: "40px" }}
                    type="button"
                    className="btn btn-primary"
                  >
                    {t("go")}
                  </button>
                  <button
                    onClick={reset}
                    style={{
                      width: "120px",
                      marginLeft: "20px",
                      height: "40px",
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    {Capitalize(t("reset"))}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {renderTable()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenJourneyPerformance);
