import React from "react";

const NotFound = (props) => {
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <h1 style={{ marginTop: 300 }}>{props.message ? props.message : "Page not found"}</h1>
    </div>
  );
};

export default NotFound;
