import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import moment from "moment/min/moment-with-locales";
import Select from "react-select";
import { API } from "../../../utils/api";
import { connect } from "react-redux";

const ScreenGuardPlannings = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [globalGuardData, setGlobalGuardData] = useState([]);
  const [guardData, setGuardData] = useState([]);
  const [filterGuardData, setFilterGuardData] = useState([]);
  const [optionDoctors, setOptionDoctors] = useState([]);
  const [selectedOptionDoctor, setOptionDoctorselected] = useState([]);
  const [startHeadDate, setStartHeadDate] = useState(moment().startOf("isoWeek"));
  const [load, setLoad] = useState(false);
  const [listDates, setListDates] = useState([]);
  const [listDates2, setListDates2] = useState([]);
  const [filterDoctors, setfilterDoctors] = useState([]);
  const [isCureent, setIsCurrent] = useState(true);

  useEffect(() => {
    let endHeadDate = moment(startHeadDate).add(14, "days").startOf("day");
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLGUARDS}`,
        {
          startDate: startHeadDate,
          endDate: endHeadDate,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        initialOption(res.data);
        setGlobalGuardData(res.data);
        setFilterGuardData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const initialOption = (Data) => {
    let guardByPost = {},
      doctors = [];
    let obj = { doctor: [] };
    Data.map((member) => {
      member.Recommandations.map((r) => {
        if (r.Members) {
          if (!obj.doctor.includes(r.Members.nom)) {
            doctors = [
              ...doctors,
              {
                value: r.Members.index,
                label: r.Members.nom.trim() + " " + r.Members.prenom.trim(),
              },
            ];
            obj.doctor = [...obj.doctor, r.Members.nom.trim()];
          }
          let date = moment(r.startDate).startOf("day").format("YYYY-MM-DD");
          let end = moment(r.endDate).startOf("day").diff(moment(date), "days");
          let i = 0;
          if (guardByPost[r.post_id + "_" + moment(date).format("YYYY-MM-DD")]) return;
          if (end <= 0) {
            guardByPost[r.post_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
          } else {
            while (i <= end) {
              guardByPost[r.post_id + "_" + moment(date).format("YYYY-MM-DD")] = r;
              date = moment(date).add(1, "day");
              i++;
            }
          }
        }
      });
    });

    setfilterDoctors(guardByPost);
    setOptionDoctors(doctors);
    setGuardData(guardByPost);
  };
  useEffect(() => {
    let days = [],
      days2 = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, moment(startHeadDate).add(i, "days").format("YYYY-MM-DD")];
      days2 = [
        ...days2,
        moment(startHeadDate)
          .add(i + 7, "days")
          .format("YYYY-MM-DD"),
      ];
    }
    setListDates(days);
    setListDates2(days2);
  }, [startHeadDate]);

  useEffect(() => {
    if (
      (selectedOptionDoctor && selectedOptionDoctor.length === 0) ||
      selectedOptionDoctor === null
    ) {
      setFilterGuardData(globalGuardData);
      initialOption(globalGuardData);
    } else {
      let guards = [];

      let listDoctor = selectedOptionDoctor.map((member) => {
        return member.value;
      });
      guards = Object.entries(guardData).filter(([i, member]) =>
        listDoctor.includes(member.member_id),
      );
      const dataObject = Object.fromEntries(guards);
      setfilterDoctors(dataObject);
    }
  }, [selectedOptionDoctor, globalGuardData]);

  const headDates = () => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days = [...days, i];
    }
    return days.map((day, i) => (
      <th key={i} className="header bg-cl" style={{ width: "12%" }}>
        {moment(startHeadDate).add(day, "days").locale("Fr").format("ddd")}{" "}
        {moment(startHeadDate).add(day, "days").format("DD.MM")}{" "}
      </th>
    ));
  };
  const headDates2 = () => {
    let days = [];
    for (let i = 7; i < 14; i++) {
      days = [...days, i];
    }
    return days.map((day, i) => (
      <th key={i} className="header bg-cl" style={{ width: "12%" }}>
        {moment(startHeadDate).add(day, "days").locale("Fr").format("ddd")}{" "}
        {moment(startHeadDate).add(day, "days").format("DD.MM")}{" "}
      </th>
    ));
  };

  const renderList = () => {
    if (!filterGuardData) return null;
    return filterGuardData.map((guard, index) => {
      if (guard.abreviation !== "R")
        return (
          <tr
            key={index}
            style={
              index % 2 === 0
                ? { backgroundColor: "white" }
                : { backgroundColor: "rgba(0,0,0,.05)" }
            }
          >
            <td className="td-gardes">{guard.abreviation}</td>
            {listDates.map((d, i) => {
              return filterDoctors && filterDoctors[guard.index + "_" + d] ? (
                <td key={i} className="name-doctor">
                  {filterDoctors[guard.index + "_" + d].Members.nom}
                </td>
              ) : (
                <td key={i}>-</td>
              );
            })}
          </tr>
        );
    });
  };

  const renderList1 = () => {
    if (!filterGuardData) return null;

    return filterGuardData.map((guard, index) => {
      if (guard.abreviation !== "R")
        return (
          <tr
            key={index}
            style={
              index % 2 === 0
                ? { backgroundColor: "white" }
                : { backgroundColor: "rgba(0,0,0,.05)" }
            }
          >
            <td className="td-gardes">{guard.abreviation !== "R" && guard.abreviation}</td>
            {listDates2.map((d, i) => {
              return filterDoctors && filterDoctors[guard.index + "_" + d] ? (
                <td key={i} className="name-doctor">
                  {filterDoctors[guard.index + "_" + d].Members.nom}
                </td>
              ) : (
                <td key={i}>-</td>
              );
            })}
          </tr>
        );
    });
  };
  const next2Week = () => {
    let endDate = moment(startHeadDate).add(28, "days").startOf("day");
    let startDate = moment(startHeadDate).add(14, "days").startOf("day");
    setStartHeadDate(moment(startDate).startOf("day"));
    setIsCurrent(false);
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLGUARDS}`,
        {
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        initialOption(res.data);
        setGlobalGuardData(res.data);
        setFilterGuardData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const current2Week = () => {
    let endDate = moment(startHeadDate).subtract(1, "days").startOf("day");
    let startDate = moment(startHeadDate).subtract(14, "days").startOf("day");
    setStartHeadDate(moment(startDate).startOf("day"));
    setLoad(false);
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLGUARDS}`,
        {
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        setIsCurrent(true);

        initialOption(res.data);
        setGlobalGuardData(res.data);
        setFilterGuardData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="row">
      <div className="container-user">
        <div className="row">
          <div className="w-100 justify-content-start" style={{ marginTop: 20 }}>
            <a
              onClick={() => props.setPage("recommandations")}
              style={{ marginLeft: 20 }}
              className="btn btn-user-1 btn-primary"
            >
              {t("recommendation")}
            </a>
            <a
              onClick={() => props.setPage("postAvaibility")}
              style={{ marginLeft: 20 }}
              className="btn btn-user-1 btn-primary"
            >
              {t("Post_Avaibility")}
            </a>
            <div
              className="form-group inline-block col-lg-4 col-md-6 col-12"
              style={{ zIndex: 8, marginBottom: 10 }}
            >
              <Select
                value={selectedOptionDoctor}
                className="selectJ1 form-select"
                onChange={(selectedOptionDoctor) => setOptionDoctorselected(selectedOptionDoctor)}
                options={optionDoctors}
                isMulti={true}
                isSearchable={true}
                styles={{ fontSize: 20 }}
                placeholder={t("all doctors")}
              />
            </div>
            {isCureent ? (
              <a
                style={{ marginLeft: 20 }}
                onClick={() => next2Week()}
                className="btn btn-user-1 btn-primary"
              >
                {t("next 2 weeks")}
              </a>
            ) : (
              <a
                style={{ marginLeft: 20 }}
                onClick={() => current2Week()}
                className="btn btn-user-1 btn-primary"
              >
                {t("2 current weeks")}
              </a>
            )}
          </div>
          <h1 className="page-title">{t("guard schedule")}</h1>
        </div>
        <div className="overflow-min" style={{ marginTop: 10 }}>
          {!load ? (
            <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
          ) : (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th
                    className="header"
                    style={{
                      width: "12%",
                      backgroundColor: "white",
                      border: "none",
                    }}
                  ></th>
                  {headDates()}
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          )}
        </div>
        <div className="overflow-min" style={{ marginTop: 60 }}>
          {!load ? (
            <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
          ) : (
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th
                    className="header"
                    style={{
                      width: "12%",
                      backgroundColor: "white",
                      border: "none",
                    }}
                  ></th>
                  {headDates2()}
                </tr>
              </thead>
              <tbody>{renderList1()}</tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenGuardPlannings);
