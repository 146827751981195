import { useQuery } from "@tanstack/react-query";
import { useRequest } from "./use-api";
import { API } from "../utils/api";

export function useUfDms(ufs) {
  const request = useRequest();
  const {
    data: [dms],
  } = useQuery(
    ["uf-dms", ...ufs],
    () =>
      request({
        method: "POST",
        path: API.V5_DMS,
        data: { ufs },
      }),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );

  return dms;
}
