import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useUfs(groups = []) {
  const groupes = useSelector((state) => state.screensHospData.groupes);
  const ufs = useMemo(
    () =>
      Object.values(groupes)
        .filter((groupe) => !groups || groups.length === 0 || groups.includes(groupe.name))
        .flatMap(({ data }) => data.map(([id]) => id)),
    [groupes],
  );

  return ufs;
}
