import React, { useState, useEffect } from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { editUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import ReactLoading from "react-loading";
import { API } from "../../utils/api";
import { Capitalize } from "../../utils/functions";
import Select from "react-select";
import { UtilsRegex } from "../../utils/regex";

const EditUser = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };
  const handleSelect = (e) => {
    changeLanguage(e.target.value);
  };

  const [local] = useState(i18n.language);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);
  const [errorGlobal, setErrorGlobal] = useState(null);

  const [role, setRole] = useState([]);
  const [showError, setShowError] = useState(true);
  const [load, setLoad] = useState(false);
  const [roles, setRoles] = useState(null);
  const [optionGroups, setOptionGroups] = useState([]);
  const [selectedOptionGroup, setOptionGroupSelected] = useState({
    value: "",
    label: t("select a group"),
  });
  const [optionsScreenDefault, setOptionsScreenDefault] = useState([]);
  const [filterOptionsScreenDefault, setFilterOptionsScreenDefault] = useState([]);
  const [selectedOptionScreenDefault, setOptionsScreenDefaultSelected] = useState({
    value: "",
    label: t("select a default screen"),
  });
  const [selectedOptionScreenDefaultGlobal, setOptionsScreenDefaultSelectedGlobal] = useState({});
  const [user, setUser] = useState(null);
  const [id, setID] = useState(useParams().user);
  const [admins, setAdmins] = useState([]);
  const [userID, setUserID] = useState([]);

  const loadingOptions = (Data) => {
    let screensName = [],
      adminArray = [],
      userId;
    Data.map((item) => {
      if (
        item.name.toUpperCase() != "HOME PAGE" &&
        item.name.toUpperCase() != "ADMIN" &&
        item.name.toUpperCase() != "SUPER ADMIN"
      )
        screensName = [
          ...screensName,
          { value: item.id, label: ("screen " + item.name).toUpperCase() },
        ];
      else if (item.name.toUpperCase() != "HOME PAGE") adminArray = [...adminArray, item.id];
      else userId = item.id;
    });
    setAdmins(adminArray);
    setUserID(userId);
    setOptionsScreenDefault(screensName);
    setFilterOptionsScreenDefault(screensName);
  };
  useEffect(() => {
    axios
      .get(API.ENDPOINT + API.USER_ALLROLES, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((response) => {
        setRoles(response.data);
        loadingOptions(response.data);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);

  const loadingOptionGroups = (Data) => {
    let groups = [];
    Data.map((group) => {
      groups = [
        ...groups,
        {
          value: group.index,
          label: group.name.toUpperCase(),
          roles: group.Roles,
        },
      ];
    });
    setOptionGroups(groups);
  };

  useEffect(() => {
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.USER_ALLGROUPS}`,
      data: {},
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        // setLoad(true)
        console.log(res.data);
        loadingOptionGroups(res.data);
      })
      .catch((error) => {
        console.error("There was an error inshow !", error);
      });
  }, []);

  useEffect(() => {
    if (!id || id === props.activeUSer.user.id) {
      history.push("/");
      return;
    }
    axios
      .get(API.ENDPOINT + API.USER_GETUSER + id, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((response) => {
        setUser(response.data);
        let roles = [],
          rolesName = {};
        setEmail(response.data.email);
        response.data.Group &&
          response.data.Group.Roles.map((role) => {
            if (
              response.data.screen_default &&
              response.data.screen_default.toLowerCase() === "screen " + role.name.toLowerCase()
            ) {
              rolesName = {
                value: role.id,
                label: response.data.screen_default,
              };
            }

            roles = [...roles, `${role.id}`];
          });

        if (response.data.Group) {
          let group = response.data.Group;
          setOptionGroupSelected({ value: group.index, label: group.name });
        }

        if (response.data.screen_default === "" || response.data.screen_default === null) {
          setOptionsScreenDefaultSelected({
            value: "",
            label: t("select a default screen"),
          });
        } else {
          setOptionsScreenDefaultSelected({
            value: rolesName.value,
            label: response.data.screen_default,
          });
          setOptionsScreenDefaultSelectedGlobal({
            value: rolesName.value,
            label: response.data.screen_default,
          });
        }

        setRole(roles);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);

  useEffect(() => {
    if (role === null || optionsScreenDefault === null) return;
    if (role.length === 0 || optionsScreenDefault.length === 0) return;

    let screens = [];

    if (role.includes(userID) || role.length === 0) setFilterOptionsScreenDefault([]);
    else if (
      admins &&
      admins.some((a) => role.includes(`${admins[1]}`) || role.includes(`${admins[0]}`))
    ) {
      setFilterOptionsScreenDefault(optionsScreenDefault);
    } else {
      optionsScreenDefault.map((screen) => {
        if (!role.includes(`${screen.value}`)) return;
        screens = [...screens, screen];
      });
      setFilterOptionsScreenDefault(screens);
    }
  }, [role, optionsScreenDefault]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    let errors = {};
    let test = false;
    const strongRegex = new RegExp(UtilsRegex.Password);

    if (password != "" && !strongRegex.test(password)) {
      errors = {
        ...errors,
        password: t(
          "your password must contain at least 8 characters, with at least one upper case letter, one lower case letter, one number and one special character",
        ),
      };
      test = true;
    }

    if (repeatPassword != "" && password != repeatPassword) {
      errors = {
        ...errors,
        repeatPassword: t("your confirmation password does not match the registered password"),
      };
      test = true;
    }
    if (selectedOptionGroup.value === "" || selectedOptionGroup.value === null) {
      errors = { ...errors, groupSelected: t("Select a group") };
      test = true;
    }

    if (test) {
      setError(errors);
      setLoad(false);
      setShowError(true);
      return;
    }
    const screen_default =
      !selectedOptionScreenDefault ||
      selectedOptionScreenDefault.label === "select a default screen"
        ? ""
        : selectedOptionScreenDefault.label.replace("SCREEN ", "").toLowerCase();
    const id_group = selectedOptionGroup.value;
    props
      .editUser(
        { email, password, repeatPassword, role, screen_default, id_group },
        id,
        props.activeUSer,
        history,
      )
      .catch((error) => {
        // console.log('erorrr',error)
        if (error.response) {
          if (error.response.status === "401") {
            setError({ email: "email déja utilisé" });
          }
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });

            setError(errors);
          }
          setErrorGlobal(t("we are sorry, but an error has occurred"));
        }
        setLoad(false);
        setShowError(true);
      });
  };
  const selectedGroup = (selectedOptionGroup) => {
    let groupRoles = [],
      screenName;
    setOptionGroupSelected(selectedOptionGroup);

    selectedOptionGroup.roles.map((role) => {
      if (role.name.toUpperCase() == "ADMIN" || role.name.toUpperCase() == "SUPER ADMIN")
        groupRoles = optionsScreenDefault;
      else
        groupRoles = [
          ...groupRoles,
          { value: role.id, label: ("screen " + role.name).toUpperCase() },
        ];
    });
    if (selectedOptionScreenDefault !== undefined && selectedOptionScreenDefault.value !== "") {
      groupRoles.map((role) => {
        if (role.id !== selectedOptionScreenDefault.value) {
          screenName = { value: "", label: t("select a default screen") };
        }
      });
      setOptionsScreenDefaultSelected(screenName);
    }

    setFilterOptionsScreenDefault(groupRoles);
  };

  const renderForm = () => {
    return (
      <LoadingOverlay active={load} spinner>
        <div className="row mrg-user" style={{ marginTop: 20 }}>
          <div className="container-user">
            <h1 className="page-title">{t("edit a user profile")}</h1>

            <div className="row">
              <div className="col-10 offset-1">
                <hr></hr>
                <form onSubmit={handleSubmit} className="form-user-new">
                  <div style={{ textAlign: "center" }}>
                    {showError &&
                      error &&
                      Object.entries(error).map((err) => <div className="errors">{t(err)} </div>)}
                  </div>
                  <div className="form-group block">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="EditUser"
                      className="fadeIn form-control"
                      name="_username"
                      placeholder={t("email")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="password"
                      value={password}
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="fadeIn form-control"
                      name="_password"
                      placeholder={t("password")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="password"
                      value={repeatPassword}
                      autoComplete="off"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      id="repeatPassword"
                      className="fadeIn form-control"
                      name="_repeatPassword"
                      placeholder={t("confirm new password")}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: "0%" }}>
                    <p style={{ fontSize: 20, textAlign: "center" }}>{t("groups")}:</p>
                    <div className="form-group block" style={{ marginTop: 20 }}>
                      <Select
                        value={selectedOptionGroup}
                        className="selectJ1 form-select"
                        isSearchable={false}
                        onChange={(selectedOptionGroup) => selectedGroup(selectedOptionGroup)}
                        options={optionGroups}
                      />
                    </div>
                  </div>
                  <p style={{ fontSize: 20, textAlign: "center" }}>{t("default screen")}:</p>

                  <div className="form-group block" style={{ marginTop: 20 }}>
                    <Select
                      value={selectedOptionScreenDefault}
                      className="selectJ1 form-select"
                      isSearchable={false}
                      onChange={(selectedOptionScreenDefault) =>
                        setOptionsScreenDefaultSelected(selectedOptionScreenDefault)
                      }
                      options={filterOptionsScreenDefault}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: 20 }}>
                    <a
                      title="retour"
                      className="btn"
                      style={{ textDecoration: "underline" }}
                      href="/users"
                    >
                      {Capitalize(t("back"))}
                    </a>

                    <input
                      type="submit"
                      disabled={email !== "" ? false : true}
                      style={{ textAlign: "center" }}
                      className="fadeIn fourth btn"
                      value={Capitalize(t("save"))}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  };
  const renderWait = () => {
    return (
      <div
        className="row"
        style={{
          justifyContent: "center",
          minHeight: 150,
          alignItems: "flex-end",
        }}
      >
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      </div>
    );
  };

  if (user === null) {
    return renderWait();
  } else {
    return renderForm();
  }
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ editUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
