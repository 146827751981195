import React, { useState, useEffect } from "react";
import "./index.css";
import { bindActionCreators } from "redux";

import { useTranslation } from "react-i18next";
import { loginUser, addUser } from "../../actions/users/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import ReactLoading from "react-loading";
import { API } from "../../utils/api";
import { Capitalize } from "../../utils/functions";
import Select from "react-select";
import { UtilsRegex } from "../../utils/regex";

const Login = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation("administration");
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("Calai@123!");
  const [repeatPassword, setRepeatPassword] = useState("Calai@123!");
  const [error, setError] = useState(null);
  const [errorGlobal, setErrorGlobal] = useState(null);
  const [optionScreenDefault, setOptionScreenDefault] = useState([]);
  const [optionGroups, setOptionGroups] = useState([]);
  const [selectedOptionGroup, setOptionGroupSelected] = useState({
    value: "",
    label: t("select a group"),
  });
  const [filteroptionScreenDefault, setFilteroptionScreenDefault] = useState([]);
  const [selectedOptionScreenDefault, setOptionScreenDefaultSelected] = useState({
    value: "",
    label: t("select a default screen"),
  });
  const [role, setRole] = useState([]);
  const [showError, setShowError] = useState(true);
  const [load, setLoad] = useState(false);
  const [roles, setRoles] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [userID, setUserID] = useState([]);

  const loadingData = (Data) => {
    let screensName = [],
      adminArray = [],
      userId;
    Data.map((screen) => {
      if (
        screen.name.toUpperCase() != "HOME PAGE" &&
        screen.name.toUpperCase() != "ADMIN" &&
        screen.name.toUpperCase() != "SUPER ADMIN"
      )
        screensName = [
          ...screensName,
          { value: screen.id, label: ("screen " + screen.name).toUpperCase() },
        ];
      else if (screen.name.toUpperCase() != "HOME PAGE") adminArray = [...adminArray, screen.id];
      else userId = screen.id;
    });

    setAdmins(adminArray);
    setUserID(userId);
    setOptionScreenDefault(screensName);
    setFilteroptionScreenDefault(screensName);
  };
  const loadingOptionGroups = (Data) => {
    let groups = [];
    Data.map((group) => {
      groups = [
        ...groups,
        {
          value: group.index,
          label: group.name.toUpperCase(),
          roles: group.Roles,
        },
      ];
    });
    setOptionGroups(groups);
  };

  useEffect(() => {
    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.USER_ALLGROUPS}`,
      data: {},
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        loadingOptionGroups(res.data);
      })
      .catch((error) => {
        console.error("There was an error inshow !", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(API.ENDPOINT + API.USER_ALLROLES, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((response) => {
        setRoles(response.data);

        loadingData(response.data);

        let listRolesID, roles;
        roles = [...response.data].filter((role) => role.name == "HOME PAGE");

        listRolesID = [`${roles[0].id}`];
        setRole(listRolesID);
      })
      .catch((err) => {
        // console.log('erreuuur:',err)
      });
  }, []);

  const newUser = (event) => {
    event.preventDefault();
    setLoad(true);
    setShowError(false);
    setError(null);
    let errors = {};
    let test = false;
    const strongRegex = new RegExp(UtilsRegex.Password);

    if (password && !strongRegex.test(password)) {
      errors = {
        password: t(
          "your password must contain at least 8 characters, with at least one upper case letter, one lower case letter, one number and one special character",
        ),
      };
      test = true;
    }

    if (repeatPassword && password !== repeatPassword) {
      errors = {
        ...errors,
        repeatPassword: t("your confirmation password does not match the registered password"),
      };
      test = true;
    }
    if (selectedOptionGroup.value === "" || selectedOptionGroup.value === null) {
      errors = { ...errors, groupSelected: t("Select a group") };
      test = true;
    }

    if (test) {
      setError(errors);
      setLoad(false);
      setShowError(true);
      return;
    }
    const screen_default =
      selectedOptionScreenDefault.value === ""
        ? ""
        : selectedOptionScreenDefault.label.replace("SCREEN ", "").toLowerCase();
    const id_group = selectedOptionGroup.value;

    //console.log("user",email, password, repeatPassword, screen_default, id_group)
    props
      .addUser(
        { email, password, repeatPassword, screen_default, id_group },
        props.activeUSer,
        history,
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setError({ email: t("email already used") });
          }
          if (error.response.status == 400) {
            console.log("errorr");
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = t(err.msg);
            });
            // console.log(errors)
            console.log("errorr", errors);

            setError(errors);
          }
          setErrorGlobal(t("we are sorry, but an error has occurred"));
        } else setErrorGlobal(t("we are sorry, but an error has occurred"));

        setShowError(true);
        setLoad(false);
      });
  };
  const selectedGroup = (selectedOptionGroup) => {
    let groupRoles = [],
      screenName;
    setOptionGroupSelected(selectedOptionGroup);

    selectedOptionGroup.roles.map((role) => {
      if (role.name.toUpperCase() == "ADMIN" || role.name.toUpperCase() == "SUPER ADMIN")
        groupRoles = optionScreenDefault;
      else
        groupRoles = [
          ...groupRoles,
          { value: role.id, label: ("screen " + role.name).toUpperCase() },
        ];
    });
    if (selectedOptionScreenDefault.value !== "") {
      groupRoles.map((role) => {
        if (role.id !== selectedOptionScreenDefault.value) {
          screenName = { value: "", label: t("select a default screen") };
        }
      });
      setOptionScreenDefaultSelected(screenName);
    }
    setFilteroptionScreenDefault(groupRoles);
  };

  const renderForm = () => {
    return (
      <LoadingOverlay active={load} spinner>
        <div className="row mrg-user" style={{ marginTop: 20 }}>
          <div className="container-user">
            <h1 className="page-title">{t("new user")}</h1>

            <div className="row">
              <div className="col-10 offset-1">
                <hr></hr>
                <form onSubmit={newUser} className="form-user-new">
                  <div style={{ textAlign: "center" }}>
                    {showError &&
                      error &&
                      Object.values(error).map((err) => <div className="errors">{err} </div>)}
                  </div>
                  <div className="form-group block">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="login"
                      className="fadeIn form-control"
                      name="_username"
                      placeholder={t("email")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="text"
                      required
                      value={password}
                      autocomplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="fadeIn form-control"
                      name="_password"
                      placeholder={t("password")}
                    />
                  </div>
                  <div className="form-group block">
                    <input
                      type="text"
                      required
                      value={repeatPassword}
                      autocomplete="off"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      id="repeatPassword"
                      className="fadeIn form-control"
                      name="_repeatPassword"
                      placeholder={t("confirm new password")}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: "0%" }}>
                    <p style={{ fontSize: 20, textAlign: "center" }}>{t("groups")}:</p>
                    <div className="form-group block" style={{ marginTop: 20 }}>
                      <Select
                        value={selectedOptionGroup}
                        className="selectJ1 form-select"
                        isSearchable={false}
                        onChange={(selectedOptionGroup) => selectedGroup(selectedOptionGroup)}
                        options={optionGroups}
                      />
                    </div>
                  </div>
                  <p style={{ fontSize: 20, textAlign: "center" }}>{t("default screen")}:</p>
                  <div className="form-group block" style={{ marginTop: 20 }}>
                    <Select
                      value={selectedOptionScreenDefault}
                      className="selectJ1 form-select"
                      isSearchable={false}
                      onChange={(selectedOptionScreenDefault) =>
                        setOptionScreenDefaultSelected(selectedOptionScreenDefault)
                      }
                      options={filteroptionScreenDefault}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: 20 }}>
                    <a
                      title="retour"
                      className="btn"
                      style={{ textDecoration: "underline" }}
                      href="/users"
                    >
                      {Capitalize(t("back"))}
                    </a>
                    <input
                      type="submit"
                      disabled={
                        email !== "" && password !== "" && repeatPassword !== "" ? false : true
                      }
                      style={{ textAlign: "center" }}
                      className="fadeIn fourth btn"
                      value={Capitalize(t("save"))}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  };
  const renderWait = () => {
    return (
      <div
        className="row"
        style={{
          justifyContent: "center",
          minHeight: 150,
          alignItems: "flex-end",
        }}
      >
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      </div>
    );
  };

  if (roles === null) {
    return renderWait();
  } else {
    return renderForm();
  }
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ addUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
