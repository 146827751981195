import { CONFIG } from "../../config";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { logoutUser, setErrorSocket } from "../../actions/users/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./index.css";
import socketIOClient from "socket.io-client";
import { AddLog } from "../../actions/mlcLog";
import { AddDataEmerg, AddDataHosp } from "../../actions/Screens";
import { API } from "../../utils/api";
import moment from "moment-timezone";
import LogoImg from "../../images/logo_header.png";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import * as Permission from "../../utils/permissions";
import { dateLogout } from "../../utils/functions";
import { updateUser } from "../../actions/users/index";
import Axios from "axios";

const ENDPOINT = API.ENDPOINT;

const Header = (props) => {
  const reconnectRef = useRef(null);
  const { t, i18n } = useTranslation([
    "header",
    "emergency",
    "hospitalisation",
    "administration",
    "home",
    "error",
    "cockpit",
    "rh",
    "journey",
  ]);

  const [Log] = useState(0);

  const [show, setShow] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [Local, setLocal] = useState(t(i18n.language.toUpperCase()));
  const [date] = useState(moment("2021-05-28T14:36:00"));
  const [_socket, setSocket] = useState(null);

  useEffect(() => {
    if (
      props.activeUser.user.group === null ||
      props.activeUser.user.active == 0 ||
      props.activeUser.dateLogout !== dateLogout
    ) {
      handleLogout();
    }
  }, [props.activeUser]);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, { secure: true, upgrade: false });
    setSocket(socket);
  }, []);

  useEffect(() => {
    if (!_socket || !props.mlcLog.loaded) return;
    _socket.emit(
      "userMlcLog",
      props.mlcLog.loaded && parseInt(moment().diff(moment(props.activeUser.dateLogin), "m")) > 1
        ? props.mlcLog.log
        : null,
    );
  }, [props.mlcLog]);
  useEffect(() => {
    if (!_socket) return;
    _socket.on("connect", () => {
      if (reconnectRef.current) {
        window.clearInterval(reconnectRef.current);
      }

      if (props.activeUser.isErrorSocket == true) props.setErrorSocket(props.activeUser, false);

      _socket
        .on("authenticated", function () {})
        .on("getMlcLog", (data) => {
          if (data) props.AddLog(data);
          else console.log("errerur no data log:", data);
        })
        .on("getEmergencyScreens", (data) => {
          if (!data) {
            console.log("errerur no getEmergencyScreens:", data);
            return;
          }
          props.AddDataEmerg(data);
        })
        .on("getScreensHosp", (data) => {
          if (!data) {
            console.log("errerur no getScreensHosp:", data);
            return;
          }
          props.AddDataHosp(data);
        })
        .on("getUserInfo", (user) => {
          if (user) props.updateUser(user);

          Axios({
            method: "PUT",
            url: `${API.ENDPOINT + API.USER_CHECK_USER}`,
            data: { id: props.activeUser.user.id },
            headers: {
              "x-auth-token": props.activeUser.user.token,
            },
          })
            .then((res) => {
              //  console.error("res ", res);
            })
            .catch((error) => {
              // console.error("There was an error !", error);
            });
        })
        .on("logout", (data) => {
          props.logoutUser(history);
        });
      _socket.emit("authenticate", { token: props.activeUser.user.token, test: "amine" });
    });

    _socket.on("error", function (error) {
      if (error.type === "UnauthorizedError" || error.code === "invalid_token") {
        props.logoutUser(history);
      }
    });
    _socket.on("unauthorized", function (error, callback) {
      if (error.data.type === "UnauthorizedError" || error.data.code === "invalid_token") {
        props.logoutUser(history);
      }
    });

    _socket.on("disconnect", (reason) => {
      console.log("disconnect");

      reconnectRef.current = setInterval(function () {
        _socket.connect();
      }, 5000);
    });

    _socket.on("reconnect_attempt", () => {
      console.log("Attempting to reconnect...");
    });

    _socket.on("connect_error", () => {
      _socket.disconnect();
      // if(props.activeUser.isErrorSocket===false)
      // props.setErrorSocket(props.activeUser,true)
    });
  }, [_socket, props.activeUser.isErrorSocket]);

  let history = useHistory();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  const handleSelect = (e) => {
    changeLanguage(e.target.value);
  };

  const handleLogout = () => {
    props.logoutUser(history);
  };

  const renderMlcLog = () => {
    if (props.mlcLog.loaded === true) {
      if (window.location.pathname === "/Demo") {
        return `Demo Sania / 3 / ${moment(date).format("DD.MM.YY")} / ${moment(date).format(
          "HH:mm",
        )}`;
      } else
        return  `${CONFIG.VERSION} / ${moment(props.mlcLog.log.end_date)
          .tz("Europe/Paris")
          .format("DD.MM.YY")} / ${moment(props.mlcLog.log.end_date)
          .tz("Europe/Paris")
          .format("HH:mm")}`;
    }
    else{
      return <div className="versionLoadingAnimation"></div>
    }
  };

  const ChangeLanguage = (langue) => {
    changeLanguage(langue);
    setShowSelect(false);
    setLocal(t(langue.toUpperCase()));
  };

  const nav = [
    // Urgences
    {
      show:
        Permission.isSuperAdmin(props.roles) ||
        Permission.isAdmin(props.roles) ||
        Permission.hasAtLeastOneRole(Permission.roles["emergency"], props.roles),
      href: "/Emergency",
      label: t("emergency"),
      children: [
        {
          show: Permission.hasRole("EMERGENCY PRED 7", props.roles),
          hash: "screen emergency pred 7",
          label: t("prediction 7"),
        },
        {
          show: Permission.hasRole("EMERGENCY PRED 3", props.roles),
          hash: "screen emergency pred 3",
          label: t("prediction 3"),
        },

        {
          show: Permission.hasRole("EMERGENCY COVID", props.roles),
          hash: "screen emergency covid",
          label: t("covid"),
        },

        {
          show: Permission.hasRole("EMERGENCY ACHIEVED", props.roles),
          hash: "screen emergency achieved",
          label: t("achieved"),
        },
        {
          show: Permission.hasRole("EMERGENCY TREND 24", props.roles),
          hash: "screen emergency trend 24",
          label: t("trend 24"),
        },
        {
          show: Permission.hasRole("EMERGENCY PERFORMANCE", props.roles),
          hash: "screen emergency performance",
          label: t("performance"),
        },
      ],
    },
    /*
    // Demo
    {
      show: Permission.hasRole("DEMO GLOBAL", props.roles),
      href: "/Demo",
      label: t("saniia platform demo"),
    },
    */
    // Parcours
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["journey"], props.roles),
      href: "/Journey",
      label: t("journey"),
      children: [
        {
          show: Permission.hasRole("JOURNEY TRANSITT", props.roles),
          hash: "screen journey transit",
          label: t("transit"),
        },
        {
          show: Permission.hasRole("JOURNEY EMERGENCY", props.roles),
          hash: "screen journey emergency",
          label: t("emergency"),
        },
        {
          show: Permission.hasRole("JOURNEY PATIENTS", props.roles),
          hash: "screen journey patients",
          label: t("patients"),
        },
        {
          show: Permission.hasRole("JOURNEY PERFORMANCE", props.roles),
          hash: "screen journey performance",
          label: t("performances"),
        },
      ],
    },
    // Hospitalisation
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["hospitalisation"], props.roles),
      href: "/Hospitalisation",
      label: t("hospitalisation"),
      children: [
        {
          show: Permission.hasRole("HOSPITALISATION TO COME", props.roles),
          hash: "screen hospitalisation to come",
          label: t("coming soon"),
        },
        {
          show: Permission.hasRole("HOSPITALISATION IN PROGRESS", props.roles),
          hash: "screen hospitalisation in progress",
          label: t("in progress"),
        },
        {
          show: Permission.hasRole("HOSPITALISATION ACHIEVED", props.roles),
          hash: "screen hospitalisation achieved",
          label: t("achieved"),
        },
      ],
    },
    // Cockpit
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["cockpit"], props.roles),
      href: "/Cockpit",
      label: t("beds"),
      children: [
        {
          show: Permission.hasRole("COCKPIT DECOMPTE", props.roles),
          hash: "screen cockpit decompte",
          label: t("decompte"),
        },
        {
          show: Permission.hasRole("COCKPIT EMERGER STATS", props.roles),
          hash: "screen Cockpit Emeger Stats",
          label: t("Screen Emerger Stats"),
        },
      ],
    },
    // Gestion des lits
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["bedManager"], props.roles),
      href: "/BedManager",
      label: "BED MANAGEMENT",
      children: [
        {
          show: Permission.hasRole("BED_MANAGER PATIENTS WITH NO BED", props.roles),
          hash: "patients with no bed",
          label: "Patients",
        },
      ],
    },
    // Information
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["info"], props.roles),
      href: "/info",
      label: t("screen_info"),
      children: [],
    },
    // Allocation des lits
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["bedManager"], props.roles),
      href: "/parameterBed",
      label: t("parameter beds"),
      children: [],
    },
    // Planification RH
    {
      show: Permission.hasAtLeastOneRole(Permission.roles["rh"], props.roles),
      href: "/Rh",
      label: t("hr planner"),
      children: [
        {
          show: Permission.hasRole("RH LIFELINE", props.roles),
          hash: "screen rh lifeline",
          label: t("lifeline"),
        },
        {
          show: Permission.hasRole("RH ASSIGNMENT", props.roles),
          hash: "screen rh assignment",
          label: t("assignment"),
        },
        {
          show: Permission.hasRole("RH TIMETABLE", props.roles),
          hash: "screen rh timetable",
          label: t("timetable"),
        },
        {
          show: Permission.hasRole("RH DESIDERATA FOLLOWUP", props.roles),
          hash: "screen rh desiderata follow-up",
          label: t("desiderata follow-up"),
        },
        {
          show: Permission.hasRole("RH DESIDERATA MANAGEMENT", props.roles),
          hash: "screen rh desiderata management",
          label: t("desiderata management"),
        },
        {
          show: Permission.hasRole("RH PLANNING LAUNCH", props.roles),
          hash: "screen rh planning launch",
          label: t("planning launch"),
        },
        {
          show: Permission.hasRole("RH PLANNING CONTRAINTS", props.roles),
          hash: "screen rh planning contraints",
          label: t("planning contraints"),
        },
      ],
    },
  ];

  const renderNavScreen = () => {
    const isDesktop = window.innerWidth > 767.98;
    const shownNav = nav.filter((item) => item.show);

    return (
      <>
        {shownNav.map(function (item, index, navRef) {
          const shownChildren = item.children.filter((child) => child.show);

          if (isDesktop || shownChildren.length === 0) {
            return (
              <a key={item.href} className="dropdown-item" href={item.href}>
                {item.label.toUpperCase()}
              </a>
            );
          } else {
            return (
              <Fragment key={item.href}>
                {index > 0 ? <div className="dropdown-divider"></div> : null}
                <a className="dropdown-item disabled" href>
                  {item.label.toUpperCase()}
                </a>
                {shownChildren.map((child) => (
                  <Link
                    key={child.hash}
                    onClick={() => setShow(false)}
                    className="dropdown-item"
                    style={{ marginLeft: 10 }}
                    to={{
                      pathname: item.href,
                      state: { hash: child.hash },
                    }}
                  >
                    {child.label.toUpperCase()}
                  </Link>
                ))}
              </Fragment>
            );
          }
        })}
        <div className="dropdown-divider"></div>
      </>
    );
  };

  return (
    <div className="row row-header">
      <div className="col-6">
        <a href="/">
          <img className="logo" src={LogoImg} alt="Calyps" title="Calyps" />
        </a>
      </div>
      <div className="col-6">
        <div className="col-12">
          <DropdownButton
            key={window.innerWidth > 767.98 ? "left" : ""}
            drop={window.innerWidth > 767.98 ? "left" : ""}
            title={t("menu")}
            variant="dropdown_header"
            onClick={() => setShow(!show)}
            show={show}
          >
            <a className="dropdown-item disabled" href>
              {t("screens").toUpperCase()}
            </a>
            <div className="dropdown-divider"></div>
            {renderNavScreen()}

            {Permission.isSuperAdmin(props.roles) || Permission.isAdmin(props.roles) ? (
              <>
                <a className="dropdown-item disabled" href>
                  {t("administration").toUpperCase()}
                </a>
                <div className="dropdown-divider"></div>
              </>
            ) : (
              <></>
            )}
            <a className="dropdown-item" href="/users/edit">
              {t("edit my profile").toUpperCase()}
            </a>
            {Permission.isSuperAdmin(props.roles) || Permission.isAdmin(props.roles) ? (
              <>
                <a className="dropdown-item" href="/users">
                  {t("list of users").toUpperCase()}
                </a>
                <a className="dropdown-item" href="/users/new">
                  {t("add a user").toUpperCase()}
                </a>
                {Permission.isSuperAdmin(props.roles) && (
                  <a className="dropdown-item" href="/users/roles">
                    {t("list of roles ").toUpperCase()}
                  </a>
                )}
                <a className="dropdown-item" href="/users/groups">
                  {t("list of groups").toUpperCase()}
                </a>

                <div className="dropdown-divider"></div>
              </>
            ) : (
              <></>
            )}

            {Permission.isSuperAdmin(props.roles) && (
              <>
                <a className="dropdown-item disabled" href>
                  {t("translations").toUpperCase()}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/translations">
                  {t("list of translations").toUpperCase()}
                </a>
                <div className="dropdown-divider"></div>
              </>
            )}

            <a href onClick={handleLogout} className="dropdown-item">
              {t("logout").toUpperCase()}
            </a>
            <div className="dropdown-divider"></div>

            <DropdownButton
              key={window.innerWidth > 767.98 ? "" : ""}
              drop={window.innerWidth > 767.98 ? "left" : "down"}
              title={Local}
              onMouseOver={window.innerWidth > 767.98 && (() => setShowSelect(true))}
              onMouseLeave={window.innerWidth > 767.98 && (() => setShowSelect(false))}
              onClick={() => setShowSelect(!showSelect)}
              show={showSelect}
              variant="select"
            >
              <Dropdown.Item onClick={() => ChangeLanguage("fr")}>
                {t("fr").toUpperCase()}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => ChangeLanguage("en")}>
                {t("en").toUpperCase()}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => ChangeLanguage("de")}>
                {t("de").toUpperCase()}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => ChangeLanguage("it")}>
                {t("it").toUpperCase()}
              </Dropdown.Item>
            </DropdownButton>
          </DropdownButton>
        </div>
      </div>
      <div
        className="col-12"
        style={{
          display: "flex",
          fontWeight: "bold",
          fontSize: "1vw",
          flexDirection: "row-reverse",
          paddingRight: 40,
          paddingTop: 10,
        }}
      >
        {renderMlcLog()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
  mlcLog: state.mlcLog,
  screensData: state.screensEmergData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { logoutUser, AddLog, AddDataEmerg, AddDataHosp, setErrorSocket, updateUser },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
