import React, { useState, useEffect, useMemo } from "react";
import { sumBy } from "lodash";
import moment from "moment/min/moment-with-locales";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment-timezone";
import m from "moment-timezone";
import "./index.css";
import "chartjs-plugin-annotation";
import Line from "../../../components/chartV2/lineBar";
import { Capitalize, percentageToHslGreenToBlack } from "../../../utils/functions";
import Clock from "react-live-clock";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../utils/api";
import { useRequest } from "../../../hooks/use-api";
import { useUfs } from "../../../hooks/use-ufs";

Moment.globalTimezone = "Europe/Paris";

function useSummary() {
  const request = useRequest();
  const allUfs = useUfs();
  const {
    data: [capacitaire],
  } = useQuery(
    ["emergency", "pred3", ...allUfs],
    () =>
      request({
        method: "POST",
        path: API.V5_CAPACITAIRE,
        data: {
          ufs: allUfs,
        },
      }),
    {
      staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
      refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
      refetchIntervalInBackground: true,
    },
  );
  return useMemo(
    () => ({
      libresSoinsMedecine: sumBy(
        capacitaire.filter((uf) => uf.groupe === "MEDECINE"),
        (uf) => uf.disponibles,
      ),
      libresSoinsChirurgie: sumBy(
        capacitaire.filter((uf) => uf.groupe === "CHIRURGIE"),
        (uf) => uf.disponibles,
      ),
      libresSoinsCritiques: sumBy(
        capacitaire.filter((uf) => uf.groupe === "SOINS CRITIQUES"),
        (uf) => uf.disponibles,
      ),
      libresSoinsSpecifiques: sumBy(
        capacitaire.filter((uf) => uf.groupe === "SOINS SPECIFIQUES"),
        (uf) => uf.disponibles,
      ),
    }),
    [capacitaire],
  );
}

const ScreenEmergencyPred3 = (props) => {
  const summary = useSummary();
  const [hour, setHour] = useState(m().tz("Europe/Paris").format("HH"));
  const { t, i18n } = useTranslation(["emergency"]);
  const [patient, setPatient] = useState("");
  const [discharges, setDischarges] = useState("");
  const [hospitalisation, setHospitalisation] = useState(null);
  const [bed, setBed] = useState(null);
  const [admission, setAdmission] = useState("");
  const [load, setLoad] = useState(false);
  const [indicator, setIndicator] = useState({});

  const [ColorAdmission, setColorAdmission] = useState(null);
  const [ColorPatient, setColorPatient] = useState(null);
  const [numberRedColorAdmission, setNumberRedColorAdmission] = useState(0);
  const [numberRedColorPatient, setNumberRedColorPatient] = useState(0);

  const handleHour = (date) => {
    if (date.split(":")[0] !== hour) setHour(date.split(":")[0]);
  };
  useEffect(() => {
    let DataColorAdmission = props.data["ColorBound"].filter(
      (color) => color.variableName == "Admissions",
    );
    let DataColorPresent = props.data["ColorBound"].filter(
      (color) => color.variableName == "Patients",
    );

    setColorAdmission(DataColorAdmission);
    setColorPatient(DataColorPresent);
    let colorRedAdmission =
      DataColorAdmission[0].hourUpperations -
      (DataColorAdmission[0].hourUpperations - DataColorAdmission[0].hourLower) * 0.2;
    let colorRedPatient =
      DataColorPresent[0].hourUpperations -
      (DataColorPresent[0].hourUpperations - DataColorPresent[0].hourLower) * 0.2;
    setNumberRedColorAdmission(colorRedAdmission);
    setNumberRedColorPatient(colorRedPatient);
  }, []);

  useEffect(() => {
    let bed = 0;
    let hospitalisation = 0;

    let criticalCare = 0;
    let surgery = 0;
    let medecine = 0;
    let specificCare = 0;
    props.DistributionHosp &&
      props.DistributionHosp["J0"] &&
      Object.values(props.DistributionHosp["J0"]).map((item) => {
        hospitalisation = hospitalisation + item["todayPrediction"];
      });
    props.ufBedCount &&
      props.ufBedCount.map((item) => {;
        if (item.uf <= 0) return;
        if (
          item["UfGroup"]["group"] === "SOINS CRITIQUES" ||
          item["UfGroup"]["group"] === "SOINS SPECIFIQUES" ||
          item["UfGroup"]["group"] === "CHIRURGIE" ||
          item["UfGroup"]["group"] === "MEDECINE"
        )
          bed = bed + item.libres;

        criticalCare =
          item["UfGroup"]["group"] === "SOINS CRITIQUES"
            ? criticalCare + item.libres
            : criticalCare + 0;
        specificCare =
          item["UfGroup"]["group"] === "SOINS SPECIFIQUES"
            ? specificCare + item.libres
            : specificCare + 0;
        surgery = item["UfGroup"]["group"] === "CHIRURGIE" ? surgery + item.libres : surgery + 0;
        medecine = item["UfGroup"]["group"] === "MEDECINE" ? medecine + item.libres : medecine + 0;
      });
    setIndicator({ criticalCare, surgery, medecine, specificCare });

    setHospitalisation(hospitalisation);

    setBed(bed);
  }, [props.ufBedCount]);

  useEffect(() => {
    if (props.data) {
      let patient = 0;
      let val = 0;
      let Discharges = 0;
      let entree = 0;

      let hour3 = props.data["Hours_3_moy"];

      setPatient(hour3["Patients"]["moy"] / hour3["Patients"]["i"]);
      setDischarges(hour3["Discharges"]["moy"] / hour3["Discharges"]["i"]);
      setAdmission(hour3["Admissions"]["moy"] / hour3["Admissions"]["i"]);

      setLoad(true);
    }
  }, [hour, props.data]);

  const renderHours = () => {
    let h2;
    let h3;
    let H = parseInt(hour);
    if (H <= 19) h2 = H + 4;
    else h2 = H - 20;

    if (H <= 18) h3 = H + 5;
    else h3 = H - 19;

    if (h3 < 10) h3 = "0" + h3;
    if (h2 < 10) h2 = "0" + h2;
    return h2 + ":00 - " + h3 + ":00";
  };

  const renderChart = (type, title) => {
    return (
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-12 row-line height houres">
          {props.data ? (
            <Line
              annotations={true}
              traduction="emergency"
              box={true}
              timeSeries={true}
              titleSize={26}
              language={i18n.language}
              J0={true}
              data={props.data["J0"][type]}
              hours={props.data["Hours_3"][type]}
              latestReal={props.data["LatestReal"][type]}
              hour={hour}
              title={`${title} ${moment().format("DD.MM")}`}
              data3H={true}
            />
          ) : (
            <div
              className="row"
              style={{
                justifyContent: "center",
                minHeight: 150,
                alignItems: "flex-end",
              }}
            >
              <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="text-center w-100 div-header-s2">
          <h1 className="page-header" style={{ color: "black" }}>
            {" "}
            <Clock
              format={"HH:mm"}
              onChange={(date) => handleHour(date.output)}
              ticking={true}
              timezone={"Europe/Paris"}
            />
          </h1>
        </div>

        <div className="col-12 respo-tendace">
          <fieldset className="field-tendance ">
            <legend>{Capitalize(t("trend"))}</legend>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-sm-8  col-md-6  col-lg-4 ">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-8  prediction-index">
                    <div className="prediction-header"></div>
                    {load ? (
                      <p
                        id="current-admissions"
                        style={{
                          background: percentageToHslGreenToBlack(
                            admission,
                            ColorAdmission && ColorAdmission[0].hourLower,
                            ColorAdmission && ColorAdmission[0].hourUpperations,
                          ),
                          marginTop: "30px",
                        }}
                      >
                        {" "}
                        <span
                          className="day-prediction-indicator "
                          style={{
                            color: admission <= numberRedColorAdmission ? "black" : "white",
                          }}
                        >
                          {Capitalize(t("admissions"))}
                        </span>
                      </p>
                    ) : (
                      <div id="current-admissions" className="w-100" style={{ marginLeft: "40%" }}>
                        {" "}
                        <ReactLoading
                          className=""
                          type={"balls"}
                          color="#2fa2d9"
                          width="20%"
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                    <div className="row">
                      <div className="col-md-4 col-4  col-lg-2 p-color">
                        <div className="color-bar"></div>
                      </div>
                      <div className=" p-color-number" style={{ marginTop: 2, textAlign: "left" }}>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourUpperations)}</p>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourMedian)}</p>
                        <p>{ColorAdmission && Math.round(ColorAdmission[0].hourLower)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-md-6 col-lg-4">
                <div className="row">
                  <div className="col-8  prediction-index">
                    <div className="prediction-header"></div>
                    {load ? (
                      <p
                        id="current-admissions"
                        style={{
                          background: percentageToHslGreenToBlack(
                            patient,
                            ColorPatient && ColorPatient[0].hourLower,
                            ColorPatient && ColorPatient[0].hourUpperations,
                          ),
                          marginTop: "30px",
                        }}
                      >
                        {" "}
                        <span
                          className="day-prediction-indicator "
                          style={{
                            color: patient >= numberRedColorPatient ? "white" : "black",
                          }}
                        >
                          {Capitalize(t("present"))}
                        </span>
                      </p>
                    ) : (
                      <div id="current-admissions" className="w-100" style={{ marginLeft: "40%" }}>
                        {" "}
                        <ReactLoading
                          className=""
                          type={"balls"}
                          color="#2fa2d9"
                          width="20%"
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                    <div className="row">
                      <div className="col-md-4 col-4  coPREDICTIONl-lg-2 p-color">
                        <div className="color-bar"></div>
                      </div>
                      <div className=" p-color-number" style={{ marginTop: 2, textAlign: "left" }}>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourUpperations)}</p>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourMedian)}</p>
                        <p>{ColorPatient && Math.round(ColorPatient[0].hourLower)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div
                  className="row row justify-content-center align-items-center"
                  style={{ marginTop: "45px" }}
                >
                  <div className="d-flex flex-column ">
                    <h2 className="screen2-title-tendance">
                      {" "}
                      {Capitalize(t("critical care free beds"))}
                    </h2>
                    <p className="screen2-indicator" style={{ color: "gray" }}>
                      {indicator && indicator.criticalCare}
                    </p>
                  </div>

                  <div className="d-flex flex-column">
                    <h2 className="screen2-title-tendance">
                      {" "}
                      {Capitalize(t("surgery free beds"))}
                    </h2>
                    <p className="screen2-indicator" style={{ color: "gray" }}>
                      {indicator && indicator.surgery}
                    </p>
                  </div>

                  <div className="d-flex flex-column">
                    <h2 className="screen2-title-tendance">
                      {" "}
                      {Capitalize(t("free beds medicine"))}
                    </h2>
                    <p className="screen2-indicator" style={{ color: "gray" }}>
                      {indicator && indicator.medecine}
                    </p>
                  </div>

                  <div
                    className="d-flex flex-column"
                    style={{ justifyContent: "center", width: "100%" }}
                  >
                    <h2 className="screen2-title-tendance">
                      {" "}
                      {Capitalize(t("specific care free beds"))}
                    </h2>
                    <p className="screen2-indicator" style={{ color: "gray" }}>
                      {indicator && indicator.specificCare}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        {/*end partie tablette_mobile */}

        <div className="row" style={{ display: "flex" }}>
          <div className="col-12 col-lg-8">
            {renderChart("Admissions", Capitalize(t("admission")))}
            {renderChart("Patients", Capitalize(t("patients")))}
            {renderChart("Hospitalisations", t("hospitalisations"))}
          </div>
          <div className="col-12 col-lg-4">
            <fieldset className="field-tendance show-lap">
              <legend>{Capitalize(t("trend"))}</legend>

              <div
                className="row  pastille-pred3"
                style={{
                  justifyContent: "center",
                  height: 400,
                  alignItems: "center",
                }}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-8  prediction-index">
                      <div className="prediction-header"></div>

                      {load ? (
                        <p
                          id="current-admissions"
                          style={{
                            background: percentageToHslGreenToBlack(
                              admission,
                              ColorAdmission && ColorAdmission[0].hourLower,
                              ColorAdmission && ColorAdmission[0].hourUpperations,
                            ),
                          }}
                        >
                          {" "}
                          <span
                            className="day-prediction-indicator "
                            style={{
                              color: admission >= numberRedColorAdmission ? "white" : "black",
                            }}
                          >
                            {Capitalize(t("admissions"))}
                          </span>
                        </p>
                      ) : (
                        <div
                          id="current-admissions"
                          className="w-100"
                          style={{ marginLeft: "40%" }}
                        >
                          <ReactLoading className="" type={"balls"} color="#2fa2d9" width="20%" />
                        </div>
                      )}
                    </div>
                    <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                      <div className="row">
                        <div className="col-md-4 col-4  col-lg-2 p-color">
                          <div className="color-bar"></div>
                        </div>
                        <div
                          className=" p-color-number"
                          style={{ marginTop: 2, textAlign: "left" }}
                        >
                          <p>{ColorAdmission && Math.round(ColorAdmission[0].hourUpperations)}</p>
                          <p>{ColorAdmission && Math.round(ColorAdmission[0].hourMedian)}</p>
                          <p>{ColorAdmission && Math.round(ColorAdmission[0].hourLower)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row pastille-pred3"
                style={{
                  justifyContent: "center",
                  height: 400,
                  alignItems: "center",
                }}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-8  prediction-index">
                      <div className="prediction-header"></div>

                      {load ? (
                        <p
                          id="current-admissions"
                          style={{
                            background: percentageToHslGreenToBlack(
                              patient,
                              ColorPatient && ColorPatient[0].hourLower,
                              ColorPatient && ColorPatient[0].hourUpperations,
                            ),
                          }}
                        >
                          {" "}
                          <span
                            className="day-prediction-indicator "
                            style={{
                              color: patient >= numberRedColorPatient ? "white" : "black",
                            }}
                          >
                            {Capitalize(t("present"))}
                          </span>
                        </p>
                      ) : (
                        <div
                          id="current-admissions"
                          className="w-100"
                          style={{ marginLeft: "40%" }}
                        >
                          {" "}
                          <ReactLoading
                            className=""
                            type={"balls"}
                            color="#2fa2d9"
                            width="20%"
                          />{" "}
                        </div>
                      )}
                    </div>
                    <div id="predictions-index" className="col-lg-4 col-4 col-md-4">
                      <div className="row">
                        <div className="col-md-4 col-4  col-lg-2 p-color">
                          <div className="color-bar"></div>
                        </div>
                        <div
                          className=" p-color-number"
                          style={{ marginTop: 2, textAlign: "left" }}
                        >
                          <p>{ColorPatient && Math.round(ColorPatient[0].hourUpperations)}</p>
                          <p>{ColorPatient && Math.round(ColorPatient[0].hourMedian)}</p>
                          <p>{ColorPatient && Math.round(ColorPatient[0].hourLower)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="row pastille-pred3"
                style={{
                  justifyContent: "space-between",
                  height: 450,
                  alignItems: "center",
                }}
              >
                <div className="col-12">
                  <div className="row d-flex" style={{ justifyContent: "space-between" }}>
                    <div className="d-flex flex-column w-50">
                      <h2 className="screen2-title-tendance">
                        {Capitalize(t("critical care free beds"))}
                      </h2>
                      <p className="screen2-indicator" style={{ color: "gray" }}>
                        {summary.libresSoinsCritiques}
                      </p>
                    </div>

                    <div className="d-flex flex-column w-50">
                      <h2 className="screen2-title-tendance">
                        {Capitalize(t("surgery free beds"))}
                      </h2>
                      <p className="screen2-indicator" style={{ color: "gray" }}>
                        {summary.libresSoinsChirurgie}
                      </p>
                    </div>

                    <div className="d-flex flex-column w-50">
                      <h2 className="screen2-title-tendance">
                        {Capitalize(t("free beds medicine"))}
                      </h2>
                      <p className="screen2-indicator" style={{ color: "gray" }}>
                        {summary.libresSoinsMedecine}
                      </p>
                    </div>

                    <div className="d-flex flex-column w-50">
                      <h2 className="screen2-title-tendance">
                        {Capitalize(t("specific care free beds"))}
                      </h2>
                      <p className="screen2-indicator" style={{ color: "gray" }}>
                        {summary.libresSoinsSpecifiques}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScreenEmergencyPred3;
