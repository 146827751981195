export const admissionColorMapper = (admission) => {
  let color = "green";
  if (admission >= 180) color = "green";
  if (admission >= 187) color = "#6eb700";
  if (admission >= 194) color = "#daec00";
  if (admission >= 200) color = "yellow";
  if (admission >= 207) color = "#ffc700";
  if (admission >= 214) color = "orange";
  if (admission >= 220) color = "red";
  if (admission >= 225) color = "#bf0000";
  if (admission >= 230) color = "black";

  return color;
};

export const admissionTendaceColorMapper = (admission) => {
  let color = "green";
  if (admission >= 0) color = "green";
  if (admission >= 5) color = "yellow";
  if (admission >= 10) color = "orange";
  if (admission >= 15) color = "red";
  if (admission >= 20) color = "black";

  return color;
};
// color green to black module emergency
export const percentageToHslGreenToBlack = (percentage, min, max, hue0 = 120, hue1 = 0) => {
  let numColorRed = max - (max - min) * 0.2;
  if (percentage <= numColorRed) {
    var newNum = (percentage - min) / (numColorRed - min);
    var hue = newNum * (hue1 - hue0) + hue0;
    return "hsl(" + hue + ", 100%, 50%)";
  } else {
    var newNum = (percentage - numColorRed) / (max - numColorRed);
    return "hsl(0, 100%, " + (50 - newNum * 50) + "%)";
  }
};
// color green to black module trend
export const percentageToHslGreenToBlackforTrendADmission = (percentage, hue0 = 120, hue1 = 0) => {
  if (percentage <= 20) {
    var newNum = (percentage - 0) / (20 - 0);
    var hue = newNum * (hue1 - hue0) + hue0;
    return "hsl(" + hue + ", 100%, 50%)";
  } else {
    var newNum = (percentage - 20) / (25 - 20);
    return "hsl(0, 100%, " + (50 - newNum * 50) + "%)";
  }
};
export const percentageToHslGreenToBlackforTrendTension = (percentage, hue0 = 120, hue1 = 0) => {
  if (percentage <= 70) {
    var newNum = (percentage - 30) / (70 - 30);
    var hue = newNum * (hue1 - hue0) + hue0;
    return "hsl(" + hue + ", 100%, 50%)";
  } else {
    var newNum = (percentage - 70) / (80 - 70);
    return "hsl(0, 100%, " + (50 - newNum * 50) + "%)";
  }
};

// color green to red
export const percentageToHsl = (percentage, hue0 = 120, hue1 = 0) => {
  var hue = percentage * (hue1 - hue0) + hue0;
  return "hsl(" + hue + ", 100%, 50%)";
};

export const tensionColorMapper = function (tension) {
  let color = "green";
  if (tension >= 30) color = "green";
  if (tension >= 40) color = "yellow";
  if (tension >= 50) color = "orange";
  if (tension >= 60) color = "red";
  if (tension >= 70) color = "black";

  return color;
};

export const sortieText = function (sortie) {
  let text = "";
  if (sortie >= 0) text = "0-5";
  if (sortie >= 5) text = "5-10";
  if (sortie >= 10) text = "10-15";
  if (sortie >= 15) text = "15-20";
  if (sortie >= 20) text = "20-25";

  return text;
};

export const calculateDeviation = (reel, predicted) => {
  let deviation = 100 * (1 - Math.abs(reel - predicted) / (reel + 0.0000001));

  return Math.round(deviation);
};

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const dateLogout = "15-12-2021";
