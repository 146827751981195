/**
 * @ By: Theo Bensaci
 * @ Date: 12:03:10 19.09.2022
 * @ Description: d3 export of a plot box diagrame
 * doc : https://github.com/react-d3-library/react-d3-library
 *       https://d3-graph-gallery.com/graph/boxplot_basic.html
 */

import * as d3 from "d3";
import { MyToolTips } from "../components/MyToolTips/myToolTips";

export const boxPlot = (
  data = null,
  width = 1000,
  height = 400,
  minScale = 0,
  maxScale = 0,
  mainColor = "#69b3a2",
  secondColor = "black",
) => {
  let plotBox = document.createElement("div");
  // set the dimensions and margins of the graph
  var margin = { top: 10, right: 30, bottom: 30, left: 40 };

  // style const
  const strokeWidth = 2;
  const boxWidth = 100;
  const boxMinMargin = 10; // tolal magrin (left + right)

  // tool tips

  let tooltips = MyToolTips.getInstance(0, 10);

  // append the svg object to the body of the page
  var svg = d3
    .select(plotBox)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  let useData = data ? data : [];

  let domain = [];

  let calMaxScale = 0;

  // get domain and get value max for scale
  for (const iterator of useData) {
    domain.push(iterator.key);

    // get the item heightes prop value
    let max = 0;
    let filltredDataProps = Object.getOwnPropertyNames(iterator);
    for (const prop of filltredDataProps) {
      if (parseInt(iterator[prop])) {
        max = iterator[prop] > max ? iterator[prop] : max;
      }
    }
    calMaxScale = max > calMaxScale ? max : calMaxScale;
  }

  let useMaxScale = maxScale != 0 ? maxScale : calMaxScale;

  // get box width

  // get box max theoric width
  let maxBoxWidth = width / useData.length;
  let calBoxWidth = maxBoxWidth < boxWidth + boxMinMargin ? maxBoxWidth - boxMinMargin : boxWidth;

  // Show X scale
  var x = d3.scaleBand().range([0, width]).domain(domain).paddingInner(1).paddingOuter(0.5);
  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x));

  // Show Y scale
  var y = d3.scaleLinear().domain([minScale, useMaxScale]).range([height, 0]).nice();
  svg.append("g").call(d3.axisLeft(y));

  // Show the main vertical line
  svg
    .selectAll("vertLines")
    .data(useData)
    .enter()
    .append("line")
    .attr("x1", function (d) {
      return x(d.key);
    })
    .attr("x2", function (d) {
      return x(d.key);
    })
    .attr("y1", function (d) {
      return y(d.min);
    })
    .attr("y2", function (d) {
      return y(d.max);
    })
    .attr("stroke", secondColor)
    .attr("stroke-width", strokeWidth + "px");

  // Show the box
  svg
    .selectAll("boxes")
    .data(useData)
    .enter()
    .append("rect")
    .attr("x", function (d) {
      return x(d.key) - calBoxWidth / 2;
    })
    .attr("y", function (d) {
      return y(d.q3);
    })
    .attr("height", function (d) {
      return y(d.q1) - y(d.q3);
    })
    .attr("width", calBoxWidth)
    .attr("stroke", secondColor)
    .attr("stroke-width", strokeWidth + "px")
    .style("fill", mainColor)
    .on("mouseover", (event, d) => {
      tooltips.show(
        `max : ${d.max}\nq1 : ${d.q3}\nmedian : ${d.median}\nq3 : ${d.q1}\nmin : ${d.min}`,
        event.target,
      );
    })
    .on("mousemove", (event) => {
      tooltips.updatePos(event);
    })
    .on("mouseout", (event) => {
      tooltips.unShow(event);
    });

  // show median, min and max horizontal lines
  const lineName = ["min", "median", "max"];

  for (const iterator of lineName) {
    svg
      .selectAll("medianLines")
      .data(useData)
      .enter()
      .append("line")
      .attr("x1", function (d) {
        return x(d.key) - calBoxWidth / 2;
      })
      .attr("x2", function (d) {
        return x(d.key) + calBoxWidth / 2;
      })
      .attr("y1", function (d) {
        return y(d[iterator]);
      })
      .attr("y2", function (d) {
        return y(d[iterator]);
      })
      .attr("stroke", secondColor)
      .attr("stroke-width", strokeWidth + "px")
      .style("width", strokeWidth);
  }

  return plotBox;
};
