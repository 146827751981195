/**
 * @ De: Theo Bensaci
 * @ Date: 15:33 19.02.2024
 * @ Description: Compeent qui permette d'avoir une notification des patiens sans lit
 */
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import axios from "axios";
import { API } from "../../utils/api";
import { connect } from "react-redux";

const NoBedNotification = (props) => {
    const [isLoading,setIsLoading]=useState(false);
    const [data,setData]=useState(null);
    const [notifClass, setNotifClass]=useState("");

    function requestData() {
        setIsLoading(true);
        // request
        axios
          .get(`${API.ENDPOINT + API.PARAMETERBED_ALLBEDDISPATCHBEDMANAGEMENT}`, {
            headers: {
              "x-auth-token": props.activeUSer.user.token,
            },
          })
          .then((res) => {
            setData(res.data.length);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    }

    useEffect(()=>{
        requestData();
        if(props.hide){
            setNotifClass("Notif-HideNoAnimation");
        }
        else{
            setNotifClass("Notif-Show"); 
        }
    },[])


    useEffect(()=>{
        if(props.hide){
            setNotifClass("Notif-Hide");
        }
        else{
            setNotifClass("Notif-Show"); 
        }
    },[props.hide])

    return (
      <div className="NoBedNotfifcation">
        <div className={"Notif "+notifClass + ((isLoading)?" Notif-Load":"")}>
            {(isLoading)?"?":data}
        </div>
        {props.element}
      </div>
    );
  };

const mapStateToProps = (state) => ({
activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null)(NoBedNotification);