import { SCREEN } from "../actions/Screens/actions";

const initialState = { loaded: false, data: null };
export default function reducerMlcLog(state = initialState, action) {
  switch (action.type) {
    case SCREEN.ADD_S_EMERG:
      return { loaded: true, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}
