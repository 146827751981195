import React from "react";

const AccesDenied = (props) => {
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <h1 style={{ marginTop: 300 }}>{props.message ? props.message : "Acces denied"}</h1>
    </div>
  );
};

export default AccesDenied;
