import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import ReactTooltip from "react-tooltip";

const ScreenDemoPlanning = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [screen, setScreen] = useState("planning");
  const [globalPlanningsData, setGlobalPlanningsData] = useState(props.data);
  const [filterPlanningsData, setFilterPlanningsData] = useState([]);
  const [planningData, setPlanningData] = useState([]);
  const [load, setLoad] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [optionsFunctions, setOptionsFunction] = useState(props.functions);
  const [selectedFunctionsOption, setOptionFunctionsSelected] = useState({
    value: null,
    label: t("all functions").toUpperCase(),
  });
  const [startHeadDate, setStartHeadDate] = useState(moment().subtract(6, "days").startOf("day"));
  const [loadPagination, setLoadPagination] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableBefore, setDisableBefore] = useState(true);
  const per = 100 / 14;
  const ref = useRef(null);

  const TransformData = (Data) => {
    let group = Data
      ? Data.reduce((planning, demoPlanning) => {
          planning[demoPlanning.nom + " " + demoPlanning.prenom + "|" + demoPlanning.fonction] = [
            ...(planning[
              demoPlanning.nom + " " + demoPlanning.prenom + "|" + demoPlanning.fonction
            ] || []),
            {
              index: demoPlanning.index,
              startDate: demoPlanning.debut,
              endDate: demoPlanning.fin,
              hour: demoPlanning.heure,
              plage: demoPlanning.plage,
              skill: demoPlanning.competence,
            },
          ];
          return planning;
        }, {})
      : [];
    setFilterPlanningsData(group);
    setPlanningData(group);
  };

  useEffect(() => {
    setOptionsFunction([{ value: null, label: t("all functions") }, ...optionsFunctions]);
    let screenDefault = "planning";
    setScreen(screenDefault);
    let endHeadDate = moment().add(7, "days");
    let start = moment(startHeadDate).format("YYYY-MM-DD");
    let end = moment(endHeadDate).format("YYYY-MM-DD");

    TransformData(globalPlanningsData);
  }, []);

  useEffect(() => {
    let plannings = [];
    globalPlanningsData.map((planning, i) => {
      if (
        selectedFunctionsOption &&
        selectedFunctionsOption.value != null &&
        selectedFunctionsOption.value != planning.fonction
      )
        return;
      plannings = [...plannings, planning];
    });
    TransformData(plannings);
  }, [selectedFunctionsOption, globalPlanningsData]);

  useEffect(() => {
    if (moment(endDate).diff(moment(startHeadDate), "days") + 1 > 12) setDisableNext(false);
    else setDisableNext(true);

    if (moment(startHeadDate).diff(moment(startDate), "days") + 1 > 7) setDisableBefore(false);
    else setDisableBefore(true);
  }, [startHeadDate]);

  const next = () => {
    setLoadPagination(true);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).add(7, "days"));
    }, 500);
  };
  const before = () => {
    setLoadPagination(true);
    setTimeout(() => {
      setStartHeadDate(moment(startHeadDate).subtract(7, "days"));
    }, 500);
  };

  const reset = () => {
    setLoad(false);
    setLoadPagination(false);
    setStartDate(null);
    setEndDate(null);
    setStartHeadDate(moment().subtract("6", "days").startOf("day"));
    setPlanningData(globalPlanningsData);
  };

  const getPlanningByDates = () => {
    if (!startDate && !endDate) return;
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    let data = { startDate: start, endDate: end };
    setLoad(false);
    axios
      .post(`${API.ENDPOINT + API.RH_PPLANNINGSBETWEEN2DATES}`, {
        data,
      })
      .then((res) => {
        setStartHeadDate(moment(startDate));
        setDisableBefore(true);

        setPlanningData(res.data);
        setFilterPlanningsData(res.data);
        setLoadPagination(true);
        setLoad(true);
      })
      .catch((err) => {
        // console.log('err:',err)
        setLoad(false);
      });
  };

  const renderDates = () => {
    let days = [];
    for (let i = 0; i < 14; i++) {
      days = [...days, i];
    }

    return days.map((day, i) => (
      <span key={i}>{moment(startHeadDate).add(day, "days").format("DD.MM")} </span>
    ));
  };

  const marginDays = (startDate, endDate) => {
    let date =
      moment(startDate).diff(moment(startHeadDate), "days") >= 0
        ? moment(startDate)
        : moment(startHeadDate);

    let start = parseInt(moment(date).diff(moment(startHeadDate), "minutes")) / 1440;

    let width = parseInt(moment(endDate).diff(moment(date), "minutes")) / 1440;

    return { marginLeft: start * per + "%", width: width * per + "%" };
  };

  const renderPlanning = () => {
    let end = 15;
    if (!filterPlanningsData) return null;

    return Object.entries(filterPlanningsData).map((demoPlanning, index) => (
      <div className="gantt__row w-100 planning" key={index}>
        <div className="gantt__row-first">
          <div className="row">
            <div className="col-8">{demoPlanning[0].split("|")[0]}</div>
            <div className="col-4">{demoPlanning[0].split("|")[1]}</div>
          </div>
        </div>
        <ul className="gantt__row-bars planning">
          <li style={{ gridColumn: `1/${end}` }}>
            <div
              className={`real-los-wrapper`}
              key={index}
              style={{
                backgroundColor: "green",
                zIndex: 1,
                marginLeft: `0%`,
                width: `${100}%`,
              }}
            ></div>

            {demoPlanning[1] &&
              demoPlanning[1].length > 0 &&
              demoPlanning[1].map((p, i) => (
                <>
                  {((moment(p.startDate).diff(moment(startHeadDate), "days") >= 0 &&
                    moment(p.startDate).diff(moment(startHeadDate).add(14, "days"), "days") < 0) ||
                    (moment(p.endDate).diff(moment(startHeadDate), "days") > 0 &&
                      moment(p.endDate).diff(moment(startHeadDate).add(14, "days"), "days") <=
                        0)) && (
                    <div
                      className="predicted-los-wrapper text-font-plan"
                      data-tip
                      data-for={`tooltip-planning-${p.index}`}
                      key={i}
                      style={{
                        backgroundColor: "red",
                        borderRadius: "0px",
                        zIndex: i + 2,
                        paddingLeft: 5,
                        ...marginDays(p.startDate, p.endDate),
                      }}
                    ></div>
                  )}

                  {setPlanningTooltip(p)}
                </>
              ))}
          </li>
        </ul>
      </div>
    ));
  };

  const setPlanningTooltip = (planning) => {
    return (
      <ReactTooltip id={`tooltip-planning-${planning.index}`} effect="solid">
        <ul className="tooltip-list" style={{ color: "white", zIndex: 5 }}>
          <li style={{ color: "white" }}>
            {`${t("skill")} : ${planning.skill == " " ? "-" : planning.skill}`}{" "}
          </li>
        </ul>
      </ReactTooltip>
    );
  };

  const renderScreenPlaning = () => {
    return (
      <>
        <div className="row plan-screen">
          <div className="col-12" style={{ zIndex: 2, padding: "0px 5px" }}>
            {loadPagination && (
              <div
                className="col-12 d-flex justify-content-center"
                style={{ zIndex: 6, marginTop: 20 }}
              >
                {/* {loadPagination? <ReactLoading className="" type={"bars"} color="#2fa2d9" width="50px" />  :
                <> */}
                <button
                  onClick={before}
                  style={{ width: "100px", height: "40px", margin: 5 }}
                  type="button"
                  disabled={disableBefore}
                  className="btn btn-secondary"
                >
                  {t("previous")}
                </button>
                <button
                  onClick={next}
                  style={{ width: "100px", height: "40px", margin: 5 }}
                  type="button"
                  disabled={disableNext}
                  className="btn btn-secondary"
                >
                  {t("next")}
                </button>
                {/* </> } */}
              </div>
            )}
          </div>
        </div>
        <div className="col-12 scroll-gant plan-screen" style={{ marginTop: "2%" }}>
          <div className="row div-gantt" id="div-gantt2">
            <div className=" gantt__row--months-planning" style={{ display: "grid" }}>
              <div className="gantt__row-first">
                <div className="row">
                  <div className="col-8">{t("caregiver")}</div>
                  <div className="col-4">{t("function")}</div>
                </div>
              </div>
              {renderDates()}
            </div>
            <div className="gantt w-100" style={{ maxHeight: 600, overflowY: "scroll" }}>
              <div></div>
              <div className="gantt__row gantt__row--lines" data-month="5">
                <span className=""></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
                <span className="marker2"></span>
              </div>
              {renderPlanning()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderScreen = () => {
    switch (screen) {
      case "planning":
        return renderScreenPlaning();
    }
  };

  return (
    <div className="row mrg-t">
      <div className="container-user">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-between flx-col padding-0"
            style={{ textAlign: "end", marginTop: 20 }}
          >
            {screen == "planning" ? (
              <>
                <div className="d-flex flex-colu col-lg-5 col-12 padding-0 mobile">
                  <div
                    className="col-12 col-md-4 col-lg-4 text-left date"
                    style={{ zIndex: 8 }}
                  ></div>
                  <div
                    className="col-12 col-md-4 col-lg-4 text-left date"
                    style={{ zIndex: 7 }}
                  ></div>
                  {(startDate || endDate) && (
                    <div
                      className="col-12 col-md-4 margin-w col-lg-3 text-left"
                      style={{
                        zIndex: 6,
                        display: "flex",
                        flexDirection: "row",
                        aligndemoPlannings: "start",
                        marginTop: 5,
                      }}
                    >
                      <>
                        <button
                          style={{ width: "120px", height: "40px" }}
                          type="button"
                          onClick={getPlanningByDates}
                          className="btn btn-primary"
                        >
                          {t("go")}
                        </button>
                        <button
                          style={{
                            width: "120px",
                            marginLeft: "20px",
                            height: "40px",
                          }}
                          type="button"
                          onClick={reset}
                          className="btn btn-secondary"
                        >
                          {t("reset")}
                        </button>
                      </>
                    </div>
                  )}
                </div>

                <div
                  className="col-12 col-lg-3 text-left col-md-6 mrg-10 padding-0"
                  style={{ zIndex: 5 }}
                >
                  <Select
                    value={selectedFunctionsOption}
                    isSearchable={true}
                    styles={{ fontSize: 20 }}
                    placeholder={t("all functions")}
                    className="selectJ1 form-select"
                    isSearchable={false}
                    onChange={(selectedOption) => setOptionFunctionsSelected(selectedOption)}
                    options={optionsFunctions}
                  />
                </div>
              </>
            ) : (
              <div className="col-lg-10 padding-0"></div>
            )}
          </div>
        </div>

        {renderScreen()}
      </div>
    </div>
  );
};
export default ScreenDemoPlanning;
