import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./index.css";
import { Capitalize, percentageToHsl } from "../../../utils/functions";

const ScreenHospitalisationComing = (props) => {
  const { t, i18n } = useTranslation(["hospitalisation"]);

  const head = [
    t("iep"),
    t("uf"),
    t("sex"),
    t("age"),
    t("origin"),
    t("reason"),
    t("delay (h)"),
    t("length of stay (days)"),
    t("type"),
    t("date of arrival"),
    t("time range"),
    t("planned sector"),
    t("recommended bed"),
    t("accommodation"),
  ];
  const head_pred = [
    t("iep"),
    t("uf"),
    t("sex"),
    t("age"),
    t("delay (h)"),
    t("length of stay"),
    t("type"),
    t("date of arrival"),
    t("time range"),
    t("planned sector"),
    t("recommended bed"),
    t("accommodation"),
  ];

  const [dataHospitalisation, setdataHospitalisation] = useState(props.data);
  const [indicator, setIndicator] = useState(null);

  useEffect(() => {
    setdataHospitalisation(props.data);
  }, [props.data]);

  useEffect(() => {
    let values = {
      "60m": 0,
      hospPred: 0,
      "120m": 0,
      "6h": 0,
      "12h": 0,
      "24h": 0,
      P24h: 0,
      predAdmission: [],
      ScheduledAdmissions: [],
    };
    let predAdmission = [];
    let ScheduledAdmissions = [];
    props.data["bedDispatching2"].map((bed) => {
      let diff = moment(bed["date_in"]).diff(moment(), "minutes");
      let diff2 = moment(bed["date_in"]).diff(moment().startOf("day"), "minutes");

      if (moment(bed["date_in"]).diff(moment(), "days") === "0" && bed.preadm_flag === "0")
        values["hospPred"] = values["hospPred"] + 1;

      if (diff2 >= 0) {
        // if(bed.preadm_flag==="0") predAdmission=[... predAdmission , bed]
        // else if(bed.preadm_flag==="1") ScheduledAdmissions=[... ScheduledAdmissions , bed]
        if (bed.preadm_flag === "0") predAdmission = [...predAdmission, bed];
        else if (bed.preadm_flag === "1") ScheduledAdmissions = [...ScheduledAdmissions, bed];
        if (diff <= 60) {
          if (bed.preadm_flag === "1") values["24h"] = values["24h"] + 1;

          if (bed.preadm_flag === "0") values["60m"] = values["60m"] + 1;
        } else if (diff <= 120) {
          if (bed.preadm_flag === "1") values["24h"] = values["24h"] + 1;
          if (bed.preadm_flag === "0") values["120m"] = values["120m"] + 1;
        } else if (diff <= 6 * 60) {
          if (bed.preadm_flag === "1") values["24h"] = values["24h"] + 1;
          values["6h"] = values["6h"] + 1;
        } else if (diff <= 12 * 60) {
          if (bed.preadm_flag === "1") values["24h"] = values["24h"] + 1;

          values["12h"] = values["12h"] + 1;
        } else if (diff <= 24 * 60) {
          if (bed.preadm_flag === "1") values["24h"] = values["24h"] + 1;
        } else if (diff <= 48 * 60) {
          if (bed.preadm_flag === "1") values["P24h"] = values["P24h"] + 1;
        }
      }
    });

    values["predAdmission"] = predAdmission
      .sort((a, b) => (moment(a["date_in"]).diff(moment(b["date_in"])) > 0 ? 1 : -1))
      .filter((item) => item.orient_ideale_name !== "");
    values["ScheduledAdmissions"] = ScheduledAdmissions.sort((a, b) =>
      moment(a["date_in"]).diff(moment(b["date_in"])) > 0 ? 1 : -1,
    );

    setIndicator(values);
  }, [props.data]);

  const backgroundColor = (valueColor) => {
    let color = "white";
    if (valueColor > 0 && valueColor <= 16) color = "yellow";
    if (valueColor > 16 && valueColor <= 32) color = "#f9da01";
    if (valueColor > 32 && valueColor <= 48) color = "orange";
    if (valueColor > 48 && valueColor <= 64) color = "darkorange";
    if (valueColor > 64 && valueColor <= 80) color = "red";
    if (valueColor > 80) color = "#bb0303";

    return color;
  };
  const renderIndicators = () => {
    let predHosp =
      props.screensData["DistributionHosp"] && props.screensData["DistributionHosp"]["J0"]
        ? props.screensData["DistributionHosp"]["J0"][props.group]["todayPrediction"]
        : "-";
    return (
      <div className="row w-100" style={{ marginTop: 30, marginBottom: 10 }}>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          <h4 className="screen3-title-indicator">
            {t("hosp").toUpperCase() + " " + moment().format("DD.MM")}
          </h4>
          <p className="screen3-indicator" style={{ color: "gray" }}>
            {" "}
            {dataHospitalisation && dataHospitalisation["todayLoss"].length}{" "}
          </p>{" "}
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          {" "}
          <h4 className="screen3-title-indicator">{`${t(
            "hosp predictions",
          ).toUpperCase()} ${moment().format("DD.MM")}`}</h4>{" "}
          <p className="screen3-indicator"> {predHosp}</p>{" "}
        </div>
        <div
          className="d-flex flex-column col-md-2 col-4 border-mobile"
          style={{ border: "solid 2px", borderRight: "none", paddingTop: 30 }}
        >
          {" "}
          <h4 className="screen3-title-indicator">{Capitalize(t("adm. in 60"))}</h4>{" "}
          <p className="screen3-indicator">{indicator && indicator["60m"]} </p>{" "}
        </div>
        <div
          className="d-flex flex-column col-md-2 col-4 border-mobile"
          style={{ border: "solid 2px", borderLeft: "none", paddingTop: 30 }}
        >
          <h4 className="screen3-title-indicator">{Capitalize(t("adm. in 120"))}</h4>{" "}
          <p className="screen3-indicator"> {indicator && indicator["120m"]} </p>{" "}
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          <h4 className="screen3-title-indicator">{Capitalize(t("adm. scheduled (24h)"))}</h4>
          <p className="screen3-indicator"> {indicator && indicator["24h"]} </p>{" "}
        </div>
        <div className="d-flex flex-column col-md-2 col-4" style={{ paddingTop: 30 }}>
          {" "}
          <h4 className="screen3-title-indicator">{Capitalize(t("Adm. scheduled (48h)"))}</h4>{" "}
          <p className="screen3-indicator"> {indicator && indicator["P24h"]}</p>{" "}
        </div>
      </div>
    );
  };
  const renderAdmissionToCome = () => {
    return (
      <div className="col-12 title-lit" style={{ marginTop: 30 }}>
        <h3 style={{ color: "black" }}>{Capitalize(t(`upcoming pre-admissions`))}</h3>
        {indicator["predAdmission"].length <= 0 && (
          <p style={{ marginBottom: 50 }}> {Capitalize(t("no admission"))}</p>
        )}
        {indicator["predAdmission"].length > 0 && (
          <table
            className={`table table-bordered ${window.innerWidth <= 992 && "table-responsive"}`}
            style={{ alingSelf: "center" }}
          >
            <thead className="thead-theme">
              <tr>
                {head.map((item, i) => {
                  return (
                    <th className="s3th" scope="col" key={i}>
                      {item.toUpperCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {indicator["predAdmission"].map((predAdmission, index) => {
                //           return <tr className={` trS3 ${index<2 && 'trS3M1'}  ${index>=2 && index <5 && 'trS3M2'} `}>
                let nextHour = moment().add("1", "hours").format("YYYY-MM-DD HH");
                let next2Hour = moment().add("2", "hours").format("YYYY-MM-DD HH");
                let test = moment(predAdmission["date_in"])
                  .add(predAdmission["delay_hours"], "hours")
                  .format("YYYY-MM-DD HH");

                return (
                  <tr
                    className={` trS3 ${test === nextHour && "trS3M1"}  ${
                      test === next2Hour && "trS3M2"
                    } `}
                    key={index}
                  >
                    <td>
                      {predAdmission.iep
                        .toString()
                        .replace(/\s?/g, "")
                        .replace(/(\d{3})/g, "$1 ")
                        .trim()}
                    </td>

                    <td>{props.screensHospData["ufs"][predAdmission.uf]}</td>
                    <td>{`${
                      predAdmission.sex === "M"
                        ? t("m").toUpperCase()
                        : predAdmission.sex === "F"
                        ? t("f").toUpperCase()
                        : "-"
                    }`}</td>
                    <td>{predAdmission.age ? predAdmission.age : "NAN"}</td>
                    <td>{t("emergency")}</td>
                    <td>{predAdmission.motif && predAdmission.motif.split("*")[1]}</td>
                    <td>{predAdmission.delay_hours}</td>

                    <td>{Math.round(predAdmission["los_hours"] / 24)}</td>
                    <td>{t("hospitalisation")}</td>
                    <td>{moment(predAdmission["date_in"]).format("DD-MM-YYYY")}</td>
                    <td>{`${moment(predAdmission["date_in"]).format("HH")}:00 / ${moment(
                      predAdmission["date_in"],
                    )
                      .add(1, "hours")
                      .format("HH")}:00`}</td>
                    <td>{predAdmission["ideal_area"]}</td>
                    <td>{predAdmission["lit"]}</td>
                    <td>
                      {predAdmission["hebergement_flag"] === "0" && t("no")}
                      {predAdmission["hebergement_flag"] === "1" && t("yes")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };
  const renderAdmissionPreAdmission = () => {
    return (
      <div className="col-12 title-lit" style={{ marginTop: 30 }}>
        <h3 style={{ color: "black" }}>{Capitalize(t(`scheduled admissions`))}</h3>
        {indicator["ScheduledAdmissions"].length <= 0 && <p> {Capitalize(t("no admission"))}</p>}
        {indicator["ScheduledAdmissions"].length > 0 && (
          <table
            className={`table table-bordered ${window.innerWidth <= 992 && "table-responsive"}`}
            style={{ alingSelf: "center" }}
          >
            <thead className="thead-theme">
              <tr>
                {head_pred.map((item, i) => {
                  return (
                    <th className="s3th2" scope="col" key={i}>
                      {item.toUpperCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {indicator["ScheduledAdmissions"].map((ScheduledAdmissions, index) => {
                return (
                  <tr className={` trS32`} key={index}>
                    <td>
                      {ScheduledAdmissions.iep
                        .toString()
                        .replace(/\s?/g, "")
                        .replace(/(\d{3})/g, "$1 ")
                        .trim()}
                    </td>
                    <td>{props.screensHospData["ufs"][ScheduledAdmissions.uf]}</td>
                    <td>{`${
                      ScheduledAdmissions.sex === "M"
                        ? t("m").toUpperCase()
                        : ScheduledAdmissions.sex === "F"
                        ? t("f").toUpperCase()
                        : "-"
                    }`}</td>
                    <td>{ScheduledAdmissions.age ? ScheduledAdmissions.age : "NAN"}</td>

                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        backgroundColor: backgroundColor(ScheduledAdmissions.delay_hours),
                      }}
                    >
                      {ScheduledAdmissions.delay_hours}
                    </td>
                    <td>{Math.round(ScheduledAdmissions["los_hours"] / 24)}</td>
                    <td>{Capitalize(t("hospitalisation"))}</td>
                    <td>{moment(ScheduledAdmissions["date_in"]).format("DD.MM.YYYY")}</td>
                    <td>{`${moment(ScheduledAdmissions["date_in"]).format("HH")}:00 / ${moment(
                      ScheduledAdmissions["date_in"],
                    )
                      .add(1, "hours")
                      .format("HH")}:00`}</td>
                    <td>{ScheduledAdmissions["ideal_area"]}</td>
                    <td>{ScheduledAdmissions["lit"]}</td>
                    <td>
                      {ScheduledAdmissions["hebergement_flag"] === "0" && t("no")}
                      {ScheduledAdmissions["hebergement_flag"] === "1" && t("yes")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };
  return (
    <div className="row">
      {renderIndicators()}
      {indicator && renderAdmissionToCome()}
      {indicator && renderAdmissionPreAdmission()}
    </div>
  );
};
export default ScreenHospitalisationComing;
