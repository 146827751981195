import { Capitalize } from "./functions";
import { compact } from "lodash";

export function bemPatientName(bemPatient) {
  if (!bemPatient) {
    return "-";
  }

  const names = compact([
    Capitalize(bemPatient.prenom ?? ""),
    bemPatient.nom?.toUpperCase(),
    bemPatient.nom_mar?.toUpperCase(),
  ]);

  if (names.length === 0) {
    return "-";
  } else {
    return names.join(" ");
  }
}
