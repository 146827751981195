/**
 * By : Theo Bensaci
 * Date : 11.04.2022
 * Desc : Screen Rh Contraints
 */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../../utils/api";
import ReactLoading from "react-loading";
import moment from "moment/min/moment-with-locales";
import { post } from "jquery";
import userEvent from "@testing-library/user-event";

class postAvaibility {
  constructor(id, date) {
    this.ID = id;
    this.Date = date;
  }
}

const ScreenPostAvaibility = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [loading, setLoading] = useState(true);
  const [calendarDateHeader, setCalendarDateHeader] = useState(null);
  const [postAvaibilitySpot, setPostAvaibilitySpot] = useState(null);
  const startDate = useRef(null);
  const data = useRef([]);
  const posts = useRef();
  const postAvaibilityList = useRef([]);
  const N_DAY_CALENDARE = 7;

  //#region Data base request
  function getData() {
    axios
      .get(`${API.ENDPOINT + API.RH_GET_ALL_POSTS_AVAIBILITY}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        data.current = res.data;
        for (const iterator of res.data) {
          postAvaibilityList.current.push(new postAvaibility(iterator.post_id, iterator.date));
        }
        genCalendarDate();
        startGenPostAvability();
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function createNewPostAvability(date, postID) {
    axios.get(`${API.ENDPOINT + API.RH_CREATE_POSTS_AVAIBILITY}`, {
      params: { date: date, post_id: postID },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    });
  }

  function deleteNewPostAvability(date, postID) {
    axios.get(`${API.ENDPOINT + API.RH_DELET_POSTS_AVAIBILITY}`, {
      params: { date: date, post_id: postID },
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    });
  }

  //#endregion

  //#region Gen calendare
  function genCalendarDate() {
    const DAY_CONVERTOR = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    let actualday = moment(startDate.current).startOf("day");
    let dates = [];
    dates.push(<th className="calendarSpotHeaderDate"></th>);
    for (let index = 0; index < N_DAY_CALENDARE; index++) {
      let dayName = t(DAY_CONVERTOR[actualday.day()]);
      dates.push(
        <th className={"calendarSpotHeaderDate" + (index == 0 ? " thisDay" : "")}>
          {dayName.slice(0, 3) + " " + actualday.format("DD.MM.YYYY")}
        </th>,
      );
      actualday.add(1, "days");
    }
    setCalendarDateHeader(<tr>{dates}</tr>);
  }

  function startGenPostAvability() {
    if (posts.current == null && (post.posts == undefined || post.posts == null)) {
      setLoading(true);
      axios
        .get(`${API.ENDPOINT + API.RH_ALLPOSTS}`, {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        })
        .then((res) => {
          setLoading(false);
          posts.current = res.data;
          genPostAvability();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      genPostAvability();
    }
  }

  /**
   * On Calendar spot click
   * @param {*} event
   * @returns
   */
  const calendarSpotClick = (event) => {
    let a = event.currentTarget.id.split(" ");
    if (a.lenght < 2) return;
    let dateConvert = moment(a[0]);
    let id = parseInt(a[1]);

    if (hasPostAvability(dateConvert, id)) {
      let d = dateConvert.format("YYYY-MM-DD");
      let i = 0;
      let isOk = false;
      for (const iterator of postAvaibilityList.current) {
        let b = moment(iterator.Date).format("YYYY-MM-DD");
        if (d == b && iterator.ID == id) {
          isOk = true;
          break;
        }
        i++;
      }
      if (isOk) {
        postAvaibilityList.current.splice(i, 1);

        deleteNewPostAvability(dateConvert.add(1, "days").toISOString(), id);
      }
    } else {
      postAvaibilityList.current.push(new postAvaibility(id, dateConvert.format("YYYY-MM-DD")));
      createNewPostAvability(dateConvert.add(1, "days").toISOString(), id);
    }
    genPostAvability();
  };

  function genPostAvability() {
    //#region constante
    const dayConvertor = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    //#endregion

    let datas = [];
    for (const iterator of posts.current) {
      if (
        iterator.denomination == "R" ||
        iterator.denomination == "Formation" ||
        iterator.denomination == "Vacances"
      )
        continue;

      let postName = <th className="calendarSpotPostName">{t(iterator.denomination)}</th>;
      let post_dates = [];
      let d = moment(startDate.current).startOf("day");
      for (let index = 0; index < N_DAY_CALENDARE; index++) {
        let isOn = hasPostAvability(d, iterator.index);

        // get if post is by default set to this day
        let dayNumberInWeek = d.day();

        let isLock = !iterator[dayConvertor[dayNumberInWeek]];

        let addClass = isOn
          ? isLock
            ? "calendarSpot-force"
            : "calendarSpot-On"
          : isLock
          ? "calendarSpot-lock"
          : "";

        post_dates.push(
          <th className={"calendarSpot " + addClass}>
            <a
              id={d.format("YYYY-MM-DD") + " " + iterator.index}
              onClick={calendarSpotClick}
              className="bnt"
            >
              {isLock && isOn ? "✔" : "X"}
            </a>
          </th>,
        );
        d.add(1, "days");
      }
      datas.push(
        <tr>
          {postName}
          {post_dates}
        </tr>,
      );
    }
    setPostAvaibilitySpot(datas);
  }
  //#endregion

  //#region DataFunction
  function hasPostAvability(date, id) {
    let a = date.format("YYYY-MM-DD");
    for (const iterator of postAvaibilityList.current) {
      let b = moment(iterator.Date).format("YYYY-MM-DD");
      if (a == b && iterator.ID == id) return true;
    }
    return false;
  }
  //#endregion

  useEffect(() => {
    startDate.current = moment().startOf("isoWeek");
    getData();
  }, []);

  return (
    <div className="PostAvaibilityContainer">
      {loading ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <div className="PostAvaibility">
          <h2 className="page-title">{t("Post_Avaibility")}</h2>
          <div className="header">
            <div className="navButtons">
              <a
                onClick={() => {
                  startDate.current.subtract(7, "days");
                  genCalendarDate();
                  genPostAvability();
                }}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                {t("befor week")}
              </a>
              <a
                onClick={() => {
                  startDate.current.add(7, "days");
                  genCalendarDate();
                  genPostAvability();
                }}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                {t("next week")}
              </a>
            </div>
          </div>
          <div className="mainContent">
            <table>
              {calendarDateHeader}
              {postAvaibilitySpot}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
export default connect(mapStateToProps, null)(ScreenPostAvaibility);
