import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import AddCategory from "./addCategory";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { API } from "../../../utils/api";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";

const ListCategories = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listCategories");
  const [globalCategoriesData, setGlobalCategoriesData] = useState([]);
  const [load, setLoad] = useState(false);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [abreviation, setAbbreviation] = useState("");
  const [denomination, setDenomination] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API.ENDPOINT + API.RH_ALLCATEGORIES}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
    })
      .then((res) => {
        setGlobalCategoriesData(res.data);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const confiramtionDeleteCategory = (id) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("are you sure you want to delete this category?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteCategory(id),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const deleteCategory = (id) => {
    setLoad(true);
    axios
      .post(
        `${API.ENDPOINT + API.RH_DELETECATEGORY}`,
        { index: id },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        let categories = globalCategoriesData.filter((category) => category.index !== id);
        setShowMessage(true);
        setMessage({ edit: "the category was deleted successfully." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalCategoriesData(categories);
        setLoad(true);
      })
      .catch((e) => {
        setLoad(false);
        //    console.log(e)
      });
  };
  const updateCategory = (category) => {
    let newCategory = {
      index: category.index,
      denomination: denomination === "" ? category.denomination : denomination,
      abreviation: abreviation === "" ? category.abreviation : abreviation,
    };

    axios({
      method: "put",
      url: `${API.ENDPOINT + API.RH_CATEGORY}`,

      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },

      data: newCategory,
    })
      .then((response) => {
        let categories = {};
        categories = globalCategoriesData.map((cat) =>
          cat.index === category.index ? newCategory : cat,
        );
        setShowMessage(true);
        setMessage({ edit: "the category was successfully modified." });
        setTimeout(() => setShowMessage(false), 3000);
        setGlobalCategoriesData(categories);
        onCancel();
      })
      .catch((error) => {
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const onEdit = (category) => {
    setInEditMode({
      status: true,
      rowKey: category.index,
    });
    setDenomination(category.denomination);
    setAbbreviation(category.abreviation);
    setIsDisable(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setAbbreviation("");
    setDenomination("");
    setIsDisable(false);
  };
  const addCategory = (category) => {
    let categories = [...globalCategoriesData, category];
    setGlobalCategoriesData(categories);
  };

  const renderListCategories = () => {
    if (!globalCategoriesData) return null;

    return (
      globalCategoriesData &&
      globalCategoriesData.map((category, index) => (
        <tr key={index}>
          <td className="text-capitalize">
            {inEditMode.status && inEditMode.rowKey === category.index ? (
              <input
                value={denomination}
                required
                className="form-control"
                onChange={(event) => setDenomination(event.target.value)}
              />
            ) : (
              category.denomination
            )}
          </td>
          <td>
            {inEditMode.status && inEditMode.rowKey === category.index ? (
              <input
                value={abreviation}
                required
                className="form-control"
                onChange={(event) => setAbbreviation(event.target.value)}
              />
            ) : (
              category.abreviation
            )}
          </td>
          <td>
            {inEditMode.status && inEditMode.rowKey === category.index ? (
              <>
                <a
                  style={{ margin: 5 }}
                  className={"icon-size"}
                  onClick={() => updateCategory(category)}
                >
                  <ImCheckmark
                    style={{
                      color: "green",
                      fontSize: 18,
                      marginTop: -2,
                      cursor: "pointer",
                    }}
                  />
                </a>
                <a style={{ margin: 5 }} className={"icon-size"} onClick={() => onCancel()}>
                  <ImCross
                    style={{
                      color: "red",
                      fontSize: 18,
                      marginTop: -2,
                      cursor: "pointer",
                    }}
                  />
                </a>
              </>
            ) : (
              <>
                <a
                  onClick={() => onEdit(category)}
                  className={` ${isDisable ? "disable icon-size" : "icon-size"}`}
                >
                  <BsPencilSquare style={{ color: "", fontSize: 24, cursor: "pointer" }} />
                </a>
                <a
                  onClick={() => confiramtionDeleteCategory(category.index)}
                  title="Delete"
                  className={` ${isDisable ? "btn icon-size disable" : "btn icon-size"}`}
                >
                  <BsTrash style={{ color: "red", fontSize: 24 }} />
                </a>
              </>
            )}
          </td>
        </tr>
      ))
    );
  };

  if (page === "listCategories") {
    return (
      <div className="row">
        <div className="container-user">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end flex-col"
              style={{ textAlign: "end", marginTop: 20 }}
            >
              <a
                onClick={() => setPage("addpost")}
                style={{ marginLeft: 20 }}
                className="btn btn-user-1 btn-primary"
              >
                <ImPlus
                  style={{
                    color: "white",
                    fontSize: 15,
                    marginTop: -2,
                    paddingRight: 5,
                  }}
                />
                {t("add category")}
              </a>
            </div>
          </div>
          <div className="message" style={{ marginTop: 20, marginBottom: 20, height: "50px" }}>
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
          <div className="overflow" style={{ marginTop: 10 }}>
            {load && globalCategoriesData ? (
              <table
                className="table table-translation table-striped table-bordered table-scroll"
                style={{ width: "100%" }}
              >
                <thead className="fix-header">
                  <tr>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("denomination")}</span>
                    </th>
                    <th className="header" style={{ width: "12%" }}>
                      <span style={{ display: "block" }}>{t("abbreviation")}</span>
                    </th>
                    <th className="header" style={{ width: "8%" }}>
                      <span style={{ display: "block" }}>{t("action")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderListCategories()}</tbody>
              </table>
            ) : (
              <ReactLoading className="loading" type={"bars"} color="#2fa2d9" width="4%" />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <AddCategory addCategory={addCategory} setPage={setPage} />;
  }
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListCategories);
