import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import GroupsSelector from "../../../../components/groups-selector";
import UfsSelector from "../../../../components/ufs-selector";

function optionsFromValues(values) {
  return values.reduce(function (acc, [key, value]) {
    if (!key) {
      return acc;
    }

    if (acc[key]) {
      return acc;
    }

    acc[key] = {
      value: key,
      label: value,
    };

    return acc;
  }, {});
}

export function Filters({ filters, setFilters, rows }) {
  const { t } = useTranslation(["hospitalisation"]);

  const sectorOptions = useMemo(
    () => optionsFromValues(rows.map((row) => [row.data.sector, row.data.sector?.toUpperCase()])),
    [rows],
  );
  const originOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap(
          (row) =>
            row.cells.map((cell) => [cell.meta.origin, cell.meta.origin?.toUpperCase()]) ?? [],
        ),
      ),
    [rows],
  );
  const diagOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap((row) => row.cells.map((cell) => [cell.meta.diag, cell.meta.diag]) ?? []),
      ),
    [rows],
  );
  const iepOptions = useMemo(
    () =>
      optionsFromValues(
        rows.flatMap((row) => row.cells.map((cell) => [cell.meta.iep, cell.label]) ?? []),
      ),
    [rows],
  );
  const bedStatusOptions = optionsFromValues([
    ["OL", "Ouvert / Libre"],
    ["OO", "Ouvert / Occupé"],
    ["FT", "Fermé / Temporaire"],
    ["FD", "Fermé / Définitif"],
  ]);

  const stayTypeOptions = optionsFromValues([
    ["ongoing", t("current patients")],
    ["incoming", t("patients to come")],
  ]);

  return (
    <div className="hip-filters-wrapper">
      <div className="hip-filters">
        <div>
          <GroupsSelector
            groups={filters.groups}
            onSelect={(options) =>
              setFilters((filters) => ({
                ...filters,
                groups: options?.map((option) => option.value) ?? [],
              }))
            }
            isSearchable
            isMulti
          />
        </div>
        <div>
          <UfsSelector
            ufs={filters.ufs}
            groups={filters.groups}
            onSelect={(options) =>
              setFilters((filters) => ({
                ...filters,
                ufs: options?.map((option) => option.value) ?? [],
              }))
            }
            isSearchable
            isMulti
          />
        </div>
      </div>
      <div className="hip-filters">
        <div>
          <Select
            value={filters.sectors.map((sector) => sectorOptions[sector])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={"Secteur"}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                sectors: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(sectorOptions)}
          />
        </div>
        <div>
          <Select
            value={filters.origins.map((origin) => originOptions[origin])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={"Origine"}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                origins: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(originOptions)}
          />
        </div>
        <div>
          <Select
            value={filters.diags.map((diag) => diagOptions[diag])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={"Diagnostique"}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                diags: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(diagOptions)}
          />
        </div>
        <div>
          <Select
            value={filters.ieps.map((iep) => iepOptions[iep])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={"Nom"}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                ieps: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(iepOptions)}
          />
        </div>
        <div>
          <Select
            value={filters.bedStatus.map((bedStatus) => bedStatusOptions[bedStatus])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={"Lits"}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                bedStatus: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(bedStatusOptions)}
          />
        </div>
        <div>
          <Select
            value={filters.stayTypes.map((stayType) => stayTypeOptions[stayType])}
            className="selectJ1"
            isSearchable
            isMulti
            styles={{ fontSize: 20 }}
            placeholder={t("all patients")}
            onChange={(options) =>
              setFilters((filters) => ({
                ...filters,
                stayTypes: options?.map((option) => option.value) ?? [],
              }))
            }
            options={Object.values(stayTypeOptions)}
          />
        </div>
      </div>
    </div>
  );
}
