/**
 * @ De: Theo Bensaci
 * @ Date: 16:03 04.03.2024
 * @ Description: use to extract us from beds (idk in witch screen i gnna put this, so let's go new utils file)
 */

/**
 * Get all us from a set of data
 * @param {*} data 
 */
export function getUS(data){
    console.log(data);
    if(data.lenght==0)return [];
    let uss=[]
    for (const iterator of data) {
        if(iterator.Beds==undefined || iterator.Beds==null || iterator.Beds==[])continue;
        let bed = (iterator.Beds[0]==undefined)?iterator.Beds:iterator.Beds[0]
        let us = {uf:bed.uf,secteur:bed.secteur};
        if(us.secteur==null || checkUsInclude(uss,us))continue;

        uss.push(us);
    }
    return uss;
}
  
/**
 * Check if a us is in us liste
 * @param {*} usList list of us
 * @param {*} us {uf:string, secteur:string}
 * @returns 
 */
export function checkUsInclude(usList,us){
    for (const iterator of usList) {
        if(iterator.uf==us.uf && iterator.secteur==us.secteur)return true;
    }
    return false;
}