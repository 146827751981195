import React, { Suspense, forwardRef } from "react";
import ReactLoading from "react-loading";

export function Loader({ height = 300 }) {
  return (
    <div
      style={{
        height,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading type="spin" className="ReactLoading" color="#2fa2d9" width="5%" />
    </div>
  );
}

export function SuspenseLoader({ height, children }) {
  return <Suspense fallback={<Loader height={height} />}>{children}</Suspense>;
}

export function withSuspenseLoader(WrappedComponent, loaderProps) {
  return forwardRef(function WithLoader(props, ref) {
    return (
      <SuspenseLoader {...loaderProps}>
        <WrappedComponent ref={ref} {...props} />
      </SuspenseLoader>
    );
  });
}
