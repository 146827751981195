import { useEffect, useState } from "react";

export function useNow(refreshInterval = Infinity) {
  const [now, setNow] = useState(new Date());

  useEffect(function () {
    const id = window.setInterval(() => setNow(new Date()), refreshInterval);

    return () => window.clearInterval(id);
  }, []);

  return now;
}
