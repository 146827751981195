import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { connect } from "react-redux";
import { Capitalize } from "../../utils/functions";

function GroupsSelector({ groups, onSelect, isSearchable = false, isMulti = false, groupes }) {
  const { t } = useTranslation("cockpit");

  const groupOptions = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(groupes)
          .map(([id, { name }]) => ({ value: id, label: name }))
          .map((option) => [option.value, option]),
      ),
    [groupes],
  );

  return (
    <Select
      value={groups.map((value) => groupOptions[value])}
      className="selectJ1"
      isSearchable={isSearchable}
      isMulti={isMulti}
      styles={{ fontSize: 20 }}
      placeholder={Capitalize(t("group"))}
      onChange={onSelect}
      options={Object.values(groupOptions)}
    />
  );
}

const mapStateToProps = (state) => ({
  groupes: state.screensHospData.groupes,
});

export default connect(mapStateToProps, null)(GroupsSelector);
