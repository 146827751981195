/**
 * @ By: Theo Bensaci
 * @ Date: 15:51:23 19.09.2022
 * @ Description: jpp de d3 en react du je crée mon propre sankey, let's gong
 * doc : https://github.com/react-d3-library/react-d3-library
 *       https://d3-graph-gallery.com/graph/sankey_basic.html
 */

import React, { useEffect, useRef, useState } from "react";
import { SankeyData, SankeyDataLink, SankeyDataNode } from "./SankeyData";
import { MyToolTips } from "../../components/MyToolTips/myToolTips";
import { dragEnable, link, style } from "d3";
import "./style.css";
import { toLength } from "lodash";

/**
 * prend un object et set de nouvel donner dedans, mais uniquement si l'objet d'orgine posédé deja les entré
 * @param {Object} origine orrigine object
 * @param {Object} newData new object
 */
function setObjectData(origine, newData) {
  if (origine == undefined || newData == undefined) return;

  let props = Object.getOwnPropertyNames(newData);
  for (const iterator of props) {
    if (origine[iterator] != undefined) {
      origine[iterator] = newData[iterator];
    }
  }
}

/**
 * Class use for manage the sankey
 */
class Sankey {
  #node_defaultColorSet; // variable use for conatine all default color

  //#region style
  #linkOpacity = 0.25;
  //#endregion

  /**
   * Constructor
   * @param {DOM element} svg svg html element ref
   * @param {[]} data sankey data
   */
  constructor(svg, data, nodeColor = []) {
    /** 
            data :
            array of array object like [@param {string} nodeA_Name,@param {string} nodeB_Name,@param {float} linkWeight]
            like google chart data format (https://developers.google.com/chart/interactive/docs/gallery/sankey)

            data format exemple : 
            [
                [ 'A', 'X', 5 ],
                [ 'A', 'Y', 7 ],
                [ 'A', 'Z', 6 ],
                [ 'B', 'X', 2 ],
                [ 'B', 'Y', 9 ],
                [ 'B', 'Z', 4 ]
            ]
        */
    this.data = data;
    this.sankeyData = null;
    this.convertData(data);
    this.svg = svg;
    this.nodeColors = nodeColor;
    this.actualNodeHightlight=null;
    this.nodesSVG = [];
    this.linksSVG = [];

    this.#node_defaultColorSet = [
      "#00FFD4",
      "#0DA5FF",
      "#6500FF",
      "#FF004F",
      "#E8170C",
      "#FFA40D",
      "#4EE80C",
    ];
  }

  //#region data traitement

  /**
   * Convert the data in to sankey data
   * @param {[]} data
   *      array of array object like [@param {string} nodeA_Name,@param {string} nodeB_Name,@param {float} linkWeight]
   *      like google chart data format (https://developers.google.com/chart/interactive/docs/gallery/sankey)
   *
   *     data format exemple :
   *      [
   *          [ 'A', 'X', 5 ],
   *          [ 'A', 'Y', 7 ],
   *          [ 'A', 'Z', 6 ],
   *          [ 'B', 'X', 2 ],
   *          [ 'B', 'Y', 9 ],
   *          [ 'B', 'Z', 4 ]
   *      ]
   */
  convertData(data) {
    if (data == undefined || data == null) return;

    this.sankeyData = new SankeyData();
    if (data.length == 0) return;

    for (let y = 0; y < data.length; y++) {
      const element = data[y];
      if (element.length != 3) continue;
      this.sankeyData.createLink(element[0], element[2], element[1]);
    }
  }

  /**
   * generate node layer
   * @param {[]} nodeLayers list of all layer
   * @param {SankeyData} sankeyData Sankey data
   */
  generateNodeLayer(nodeLayers, sankeyData) {
    let newLayer = [];
    let isEnd = true;
    for (const iterator of nodeLayers[nodeLayers.length - 1]) {
      if (iterator.rightLinks.length != 0) {
        isEnd = false;
      }

      for (const link of iterator.rightLinks) {
        let newNode = sankeyData.getNode(link.nodeB);
        if (!newLayer.includes(newNode)) {
          newLayer.push(sankeyData.getNode(link.nodeB));
        }
      }
    }
    if (isEnd) {
      return;
    } else {
      nodeLayers.push(newLayer);
      this.generateNodeLayer(nodeLayers, sankeyData);
    }
  }

  /**
   * Get random color from this.#node_defaultColorSet
   * @returns random color
   */
  getRandomNodeColor() {
    return this.#node_defaultColorSet[
      Math.floor(Math.random() * (this.#node_defaultColorSet.length - 1))
    ];
  }

  //#endregion

  //#region svg generation

  /**
   * Generate all link svg
   */
  generateLink() {
    for (let index = 0; index < this.linksSVG.length; index++) {
      this.linksSVG[index].generate(this.svg);
    }
  }

  /**
   * Generate all node svg
   */
  generateNode() {
    for (let index = 0; index < this.nodesSVG.length; index++) {
      this.nodesSVG[index].generate(this.svg);
    }
  }

  /**
   * clear sankey svg
   */
  clearSVG() {
    this.svg.innerHTML = "";
  }

  /**
   * generate graph
   */
  generate() {
    if (this.data == undefined || this.data == null) return;

    this.clearSVG();

    // sort methods
    let nodeSortMethod = (a, b) => {
      return a.getWieght() < b.getWieght();
    };

    let nodeLayer = [];
    let nodeFirstLayer = this.sankeyData.nodes
      .filter((element) => {
        return element.leftLinks.length == 0;
      })
      .sort(nodeSortMethod);
    nodeLayer.push(nodeFirstLayer);

    this.generateNodeLayer(nodeLayer, this.sankeyData);

    // apply sort
    for (let layer of nodeLayer) {
      layer = layer.sort(nodeSortMethod);
    }

    // generate node svg
    let nodeSize = { x: 10, y: 10 };
    let margin = 20;

    let xSpace = this.svg.clientWidth / (nodeLayer.length - 1); // X space bewteen node

    let magrinLeft = (this.svg.clientWidth - (xSpace * (nodeLayer.length - 1) + nodeSize.x)) / 2; // margin left

    let maxSpace = 0; // max space take by column

    let actualLinkInCreation = []; // array of all link in generation
    let newLinks = []; // new link create who need to be completed
    let nodes = []; // liste of all node

    // find total space
    for (let x = 0; x < nodeLayer.length; x++) {
      const layer = nodeLayer[x];
      let totalWieght = 0;
      for (let y = 0; y < layer.length; y++) {
        totalWieght += layer[y].getWieght();
      }

      let totalSpace = totalWieght * nodeSize.y + margin * layer.length;
      if (maxSpace < totalSpace) maxSpace = totalSpace;
    }

    let ySpace = this.svg.clientHeight / maxSpace;

    for (let x = 0; x < nodeLayer.length; x++) {
      const layer = nodeLayer[x];

      // get y space

      let totalWieght = 0;
      for (let y = 0; y < layer.length; y++) {
        totalWieght += layer[y].getWieght();
      }

      let plusSpaceY =
        this.svg.clientHeight - (totalWieght * ySpace * nodeSize.y + margin * layer.length); // space note use by the graph on y

      if (plusSpaceY < 0) {
        margin += plusSpaceY / layer.length;
        plusSpaceY =
          this.svg.clientHeight - (totalWieght * ySpace * nodeSize.y + margin * layer.length);
      }

      let marginTop = plusSpaceY / 2;

      let nodeTopPlace = 0;

      let posX = magrinLeft + xSpace * x;

      for (let y = 0; y < layer.length; y++) {
        //#region Gen node
        const node = layer[y];

        let ySize = node.getWieght() * ySpace * nodeSize.y;

        let pourcentage = node.getWieght() / totalWieght;

        let posY = nodeTopPlace + marginTop + margin * y;

        // find node color
        let nodeColor =
          this.nodeColors.length == 0 || this.nodeColors[node.name] == undefined
            ? this.getRandomNodeColor()
            : this.nodeColors[node.name];

        let nodeSvg = new SankeyNode(
          posX,
          posY,
          ySize,
          nodeSize.x,
          node,
          { color: nodeColor, textMargin: 3 },
          this,
          pourcentage,
        );

        this.nodesSVG.push(nodeSvg);

        nodeTopPlace += ySize;

        //#endregion

        //#region Gen links
        let linkTopPlace = 0;
        if (node.leftLinks.length > 0) {
          // sort link by prev layer oder
          let listOfLink = [];
          for (let d = 0; d < nodeLayer[x - 1].length; d++) {
            const el = nodeLayer[x - 1][d];
            for (const iterator of node.leftLinks) {
              if (iterator.nodeA == el.name) {
                listOfLink.push(iterator);
              }
            }
          }

          for (let index = 0; index < listOfLink.length; index++) {
            const element = listOfLink[index];

            let linkIndex = -1;
            // find index
            for (let z = 0; z < actualLinkInCreation.length; z++) {
              const link = actualLinkInCreation[z];
              if (link.nodeB == element.nodeB && link.nodeA == element.nodeA) {
                linkIndex = z;
                break;
              }
            }

            if (linkIndex != -1) {
              let link = newLinks[linkIndex];
              link.endX = posX + nodeSize.x / 2;

              link.endY = posY + link.weight / 2 + linkTopPlace;
              linkTopPlace += link.weight;
              link.endColor = nodeColor;

              nodeSvg.addLink(link);

              actualLinkInCreation.splice(linkIndex, 1);

              // generate link
              this.linksSVG.push(newLinks[linkIndex]);

              newLinks.splice(linkIndex, 1);
            }
          }
        }

        linkTopPlace = 0;

        if (node.rightLinks.length > 0) {
          // sort link by next layer oder
          let listOfLink = [];
          for (let d = 0; d < nodeLayer[x + 1].length; d++) {
            const el = nodeLayer[x + 1][d];
            for (const iterator of node.rightLinks) {
              if (iterator.nodeB == el.name) {
                listOfLink.push(iterator);
              }
            }
          }

          for (let index = 0; index < listOfLink.length; index++) {
            const element = listOfLink[index];
            let width = element.weight * ySpace * nodeSize.y;
            let newLink = new SankeyLink(
              posX + nodeSize.x / 2,
              posY + width / 2 + linkTopPlace,
              undefined,
              undefined,
              width,
              nodeColor,
              null,
              this.#linkOpacity,
            );
            nodeSvg.addLink(newLink);

            actualLinkInCreation.push(element);
            newLinks.push(newLink);

            linkTopPlace += width;
          }
        }

        //#endregion
      }
    }

    // generate all node in svg
    this.generateLink();
    this.generateNode();
  }

  //#endregion

  //#region interaction
  /**
   * HieghtLinght link on link list
   * @param {sankeyLink} links all link to heightlight
   */
  heightLightLink(links) {
    let hasHeightLightLink = links.length > 0;
    for (let index = 0; index < this.linksSVG.length; index++) {
      const linkElement = this.linksSVG[index];
      let el = document.getElementById(linkElement.id);
      let isHeightLight = false;
      for (const link of links) {
        if (link.id == linkElement.id) {
          isHeightLight = true;
          break;
        }
      }
      el.style.opacity = isHeightLight ? 0.7 : hasHeightLightLink ? 0.05 : this.#linkOpacity;
    }
  }

  heightlightNode(n){
    if(n==this.actualNodeHightlight){
      n.heightlightLink(false);
      this.actualNodeHightlight=null;
      return;
    }

    if(this.actualNodeHightlight!=null)this.actualNodeHightlight.heightlightLink(false);
    n.heightlightLink(true);
    this.actualNodeHightlight=n;
  }

  //#endregion
}

/**
 * Classe use for manage sankey node
 */
class SankeyNode {
  constructor(x, y, height, width, data, style, sankey, percentage) {
    this.x = x;
    this.y = y;
    this.height = height;
    this.width = width;
    this.data = data;
    this.percentage = percentage;
    this.linkList = [];
    this.sankey = sankey;
    this.style = {
      color: "#666f",
      strockWidth: 0,
      strockColor: "",
      textSize: 20,
      textMargin: 3,
    };

    this.nodeId = "node_" + Math.random().toString();
    this.titleID = "title_" + Math.random().toString();
    this.tooltipID = "tooltip_" + Math.random().toString();

    this.svg = null;

    setObjectData(this.style, style);
  }

  /**
   * Add link to link liste
   * @param {*} link
   */
  addLink(link) {
    link.nodes.push(this);
    this.linkList.push(link);
  }

  /**
   * Heightlinght all node link
   * @param {*} state
   */
  heightlightLink(state) {
    this.sankey.heightLightLink(state ? this.linkList : []);

    // blod text
    this.heightlighNode(state);
    this.showPourcentage(!state);

    // show oder node
    for (let index = 0; index < this.linkList.length; index++) {
      const link = this.linkList[index];
      for (let y = 0; y < link.nodes.length; y++) {
        const node = link.nodes[y];
        if(node!=this){
          node.heightlighNode(state);
          node.showPourcentage(true,state?this.getLinkWeightPourcentage(link):node.percentage);
        }
        
      }
    }
  }

  /**
   * Heightlinght this node
   * @param {*} state 
   */
  heightlighNode(state){
    let title = document.getElementById(this.titleID);
    title.setAttribute("font-weight", state ? "bold" : "normal");
    title.setAttribute("class","interactible sankeyText"+((state)?" sankeyTextHeight":""));
  }

  /**
   * Give link weight pourcentage
   * @param {*} link link to check
   * @returns wieght/totalwieght
   */
  getLinkWeightPourcentage(link){
    let totalweight=0;
    for (let index = 0; index < this.linkList.length; index++) {
      const element = this.linkList[index];
      totalweight+=element.weight;
    }
    return (link.weight/totalweight);
  }

  /**
   * show node pourcentage
   * @param {bool} state is pourcentage showed
   * @param {float} pourcentage pourcenatge to show, base = pourcentage on graph
   */
  showPourcentage(state,pourcentage = null){
    let title = document.getElementById(this.titleID);
    let percentage = ((pourcentage==null)?this.percentage:pourcentage) * 100;
    title.innerHTML = state
      ? this.data.name + " (" + Math.round(percentage * 10) / 10 + "%)"
      : this.data.name;
  }


  //#region tooltips

  moveTooltip(evt) {
    let tooltip = document.getElementById(this.tooltipID);
    var CTM = this.svg.getScreenCTM();
    var mouseX = (evt.clientX - CTM.e) / CTM.a;
    var mouseY = (evt.clientY - CTM.f) / CTM.d;
    tooltip.setAttribute(
      "x",
      mouseX + (10 * (tooltip.getAttribute("text-anchor") == "end" ? -1 : 1)) / CTM.a,
    );
    tooltip.setAttribute("y", mouseY + 10 / CTM.d);
  }

  showToolTip(state) {
    let tooltip = document.getElementById(this.tooltipID);
    tooltip.setAttribute("visibility", state ? "visible" : "hidden");
  }

  //#endregion

  generate(svg) {
    this.svg = svg;

    // rectangle
    let multiplyer = this.x + this.width > svg.clientWidth ? -1 : 1;

    let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");

    let posX=this.x + this.width * 0.5 * multiplyer

    rect.setAttribute("width", this.width);
    rect.setAttribute("height", this.height);
    rect.setAttribute("x", posX);
    rect.setAttribute("y", this.y);
    rect.setAttribute("class", "sankey_tooltip interactible");
    rect.style.stroke = this.style.strockColor;
    rect.style.strokeWidth = this.style.strockWidth;
    rect.style.fill = this.style.color;


    // add tool tips

    let tooltip = document.createElementNS("http://www.w3.org/2000/svg", "text");
    tooltip.id = this.tooltipID;
    tooltip.setAttribute("visibility", "hidden");
    tooltip.setAttribute("text-anchor", multiplyer == 1 ? "start" : "end");
    //tooltip.innerHTML=(Math.floor(this.data.getWieght()*100)/100).toString();
    tooltip.style.backgroundColor = "#000";

    // add mouse over
    this.addEventToElement(rect);

    // title
    let textInterne=-1;      // si le text est en interne ou externe du sankey (-1=externe et 1 = interne)
    let title = document.createElementNS("http://www.w3.org/2000/svg", "text");
    title.setAttribute(
      "x",
      this.x +
        (this.width * multiplyer * textInterne + (multiplyer == 1 ? this.width / 2 : 0)) +
        this.style.textMargin * multiplyer * textInterne,
    );
    title.setAttribute("y", this.y + this.height / 2 + this.style.textSize / 3);
    title.setAttribute("font-size", this.style.fontSize);
    title.setAttribute("text-anchor", multiplyer == textInterne ? "start" : "end");
    title.setAttribute("class","interactible sankeyText");
    title.innerHTML = this.data.name +  " (" + Math.round((this.percentage*100) * 10) / 10 + "%)";
    title.id = this.titleID;
    rect.id = this.nodeId;

    this.addEventToElement(title);

    // hitbox
    let hitbox=document.createElementNS("http://www.w3.org/2000/svg", "rect");
    let wd = this.width * 5;
    hitbox.setAttribute("width", wd);
    hitbox.setAttribute("height", this.height);
    hitbox.setAttribute("x", posX - ((multiplyer==-1)?wd-this.width:0));
    hitbox.setAttribute("y", this.y);
    hitbox.setAttribute("class", "hitbox interactible");
    
    this.addEventToElement(hitbox);

    svg.appendChild(rect);
    svg.appendChild(hitbox);
    svg.appendChild(title);
    svg.appendChild(tooltip);
  }


  addEventToElement(el){
    
    el.addEventListener("mouseover", (event) => {
      this.heightlightLink(true);
    });

    el.addEventListener("mousemove", (event) => {});

    el.addEventListener("mouseout", (event) => {
      this.heightlightLink(false);
    });

  }
}

/**
 * Class use for manage sankey link
 */
class SankeyLink {
  constructor(startX, startY, endX, endY, weight, startColor, endColor, opacity = 0.4) {
    this.startX = startX;
    this.startY = startY;
    this.endX = endX;
    this.endY = endY;
    this.weight = weight;
    this.startColor = startColor;
    this.endColor = endColor;
    this.opacity = opacity;
    this.svg = null;
    this.nodes=[];
    this.id = "link_" + Math.random().toString();
  }

  generate(svg) {
    // add gradient
    let grdiantid = "gradiant_" + Math.random().toString();
    svg.innerHTML += `
        <defs>
            <linearGradient id="${grdiantid}" gradientUnits="userSpaceOnUse">
            <stop offset="${(this.startX / svg.clientWidth) * 100}%" style="stop-color:${
      this.startColor
    };stop-opacity:1" />
            <stop offset="${(this.endX / svg.clientWidth) * 100}%" style="stop-color:${
      this.endColor
    };stop-opacity:1" />
            </linearGradient>
        </defs>
        `;
    let line = document.createElementNS("http://www.w3.org/2000/svg", "path");
    line.setAttribute("stroke", `url(#${grdiantid})`);
    line.setAttribute("fill", "transparent");
    line.setAttribute("stroke-width", this.weight);
    line.setAttribute(
      "d",
      `M ${this.startX} ${this.startY} C ${this.startX + (this.endX - this.startX) / 3} ${
        this.startY
      }, ${this.endX - (this.endX - this.startX) / 3} ${this.endY}, ${this.endX} ${this.endY}`,
    );
    line.addEventListener("mouseover", (event) => {});
    this.svg = line;
    svg.appendChild(line);
    this.svg.style.opacity = this.opacity;
    this.svg.id = this.id;
  }
}

/**
 * Function use for export the sankey in react
 * @param {*} props
 * @returns
 */
export const ReactSankey = (props) => {
  const containerRef = useRef(null); // container ref
  const sankey = useRef(null);

  //#endregion
  useEffect(() => {
    sankey.current = new Sankey(
      containerRef.current,
      props.data,
      props.nodeColors != undefined ? props.nodeColors : [],
    );
    sankey.current.generate();
  }, [props.data, props.nodeColor]);

  return (
    <div className="reactSankey">
      <svg className="sankey" width={props.width} height={props.height} ref={containerRef}></svg>
    </div>
  );
};
