import { CONFIG } from "../config";

export const API = {
  ENDPOINT: window.location.href.includes("calyps.ch") ? "" : CONFIG.ENDPOINT,
  LOGIN: "/api/login",

  SCREEN_EMERGENCY_PERF_DAY: "/api/prediction/screen_emergency_perf_day",
  SCREEN_EMERGENCY_PERF_HOUR: "/api/prediction/screen_emergency_perf_hour",
  SCREEN_HOSP_BY_DATES: "/api/prediction/screen_hosp_by_dates",
  SCREEN_COVID: "/api/prediction/screens_emergency/covid",
  SCREEN_HOSP_BY_UF: "/api/prediction/screen_hosp_by_uf",
  BED_DISPATCHING_HOSP_BY_UF: "/api/prediction/screen_hosp_by_uf_BedDispatching",
  SCREEN_JOURNEY_FLUX: "/api/prediction/screen_journey_flux",
  SCREENPARCOURS: "/api/prediction/screens_parcours",
  SCREENPARCOURSDEMO: "/api/prediction/screens_parcours_demo",
  SCREENPREDDEMO: "/api/prediction/screens_pred_demo",
  SCREEN_EMERG_STATS: "/api/prediction/Get_all_Tft_emerg_stats",
  SCREEN_EMERG_STATS_BY_DATE: "/api/prediction/Get_all_Tft_emerg_stats_by_date",
  SCREEN_EMERG_BJML: "/api/prediction/Get_all_Tft_emerg_bjml",
  SCREEN_EMERG_HOSPIT_BY_UF: "/api/prediction/Get_all_Emerg_hospit_by_uf",
  SCREEN_EMERG_FLOW: "/api/prediction/Get_all_emerge_flow",
  GET_ALL_GROUP_UF_UI: "/api/prediction/Get_All_Group_UI",
  BED_PREDICTION_MIDNIGHT:"/api/prediction/bedPredictionMidnight",

  RH_ALLMEMBERS: "/api/rh/allMembers",
  RH_MEMBER: "/api/rh/member",
  RH_DELETEMEMBER: "/api/rh/deleteMember",
  RH_GETFUNCTIONSSTATUS: "/api/rh/getFunctionsStatus",
  RH_STATUS: "/api/rh/status",
  RH_DELETESTATUS: "/api/rh/deleteStatus",
  RH_FUNCTION: "/api/rh/function",
  RH_DELETEFUNCTION: "/api/rh/deleteFunction",
  RH_ALLTYPES: "/api/rh/allTypes",
  RH_TYPE: "/api/rh/type",
  RH_DELETETYPE: "/api/rh/deleteType",
  RH_ALLPLANNINGS: "/api/rh/allPlannings",
  RH_ALLMYDESIDERATAS: "/api/rh/AllMyDesideratas",
  RH_ALLDESIDERATAS: "/api/rh/AllDesideratas",
  RH_DAYSVACATIONVALIDATED: "/api/rh/getDaysVacationValidated",
  RH_NUMBERDOCTORVALIDATED: "/api/rh/getNumberDesideratasValidated",
  RH_PPLANNINGSBETWEEN2DATES: "/api/rh/PlanningsBetweenDate",
  RH_PLANNING: "/api/rh/Planning",
  RH_PLANNINGSTATUS: "/api/rh/PlanningStatus",
  RH_DELETEPLANNING: "/api/rh/deletePlanning",
  RH_ALLCATEGORIES: "/api/rh/allCategories",
  RH_CATEGORY: "/api/rh/category",
  RH_DELETECATEGORY: "/api/rh/deleteCategory",
  RH_ALLPOSTS: "/api/rh/allPosts",
  RH_POST: "/api/rh/post",
  RH_DELETEPOST: "/api/rh/deletePost",
  RH_ALLRECOMMANDATIONS: "/api/rh/allRecommandations",
  RH_ALLMYRECOMMANDATIONS: "/api/rh/allMyRecommandations",
  RH_RECOMMANDATION: "/api/rh/Recommandation",
  RH_DELETERECOMMANDATION: "/api/rh/deleteRecommandation",
  RH_ALLGUARDS: "/api/rh/allgardes",
  RH_PREVISION_WEEK: "/api/rh/previsionByWeek",
  RH_ALLPLANNINGSLAUNCHINGS: "/api/rh/allPlanningsLaunching",
  RH_LAUNCHPLANNING: "/api/rh/launchPlanning",
  RH_ALL_LAUNCH_PLANNING: "/api/rh/AlllaunchPlanning",
  RH_LAUNCH_PLANNING_BY_ID: "/api/rh/LaunchPlanningById",
  RH_CONSTRAINTS_HIERARCHY: "/api/rh/AllRhConstraintsHierarchy",
  RH_UPDATE_CONSTRAINTS_HIERARCHY: "/api/rh/UpdateRhConstraintsHierarchy",
  RH_GET_ALL_POSTS_AVAIBILITY: "/api/rh/GetAllPostsAvaibility",
  RH_GET_ALL_POSTS_AVAIBILITY_DATE: "/api/rh/GetAllPostsAvaibility_Date",
  RH_CREATE_POSTS_AVAIBILITY: "/api/rh/CreatePostAvaibility",
  RH_DELET_POSTS_AVAIBILITY: "/api/rh/DeletPostAvaibility",

  USER_EDITPROFIL: "/api/user/editProfil",
  USER_CHANGE_PASSWORD: "/api/user/changePassword",
  USER_CHECK_USER: "/api/user/checkUser",
  USER_ALLUSERS: "/api/user/all",
  USER_DELETEUSER: "/api/user/delete",
  USER_GETUSER: "/api/user/",
  USER_USER: "/api/user/",
  USER_EDITUSER: "/api/user/editUser",
  USER_STATUS: "/api/user/userStatus",
  USER_ALLROLES: "/api/admin/roles",
  USER_NEWROLE: "/api/user/newRole",
  USER_EDITROLE: "/api/user/editRole",
  USER_DELETEROLE: "/api/user/deleteRole",
  USER_ALLGROUPS: "/api/user/groups",
  USER_NEWGROUP: "/api/user/newGroup",
  USER_EDITGROUP: "/api/user/editGroup",
  USER_DELETEGROUP: "/api/user/deleteGroup",
  USER_GETGROUP: "/api/user/getGroup",

  TRANSLATIONS_ALLSCREENS: "/api/translation/screens",
  TRANSLATIONS_NEWSCREEN: "/api/translation/screen",
  TRANSLATIONS_NEWTRANSLATE: "/api/translation/translation",
  TRANSLATIONS_GETTRANSLATION: "/api/translation/",
  TRANSLATIONS_UPDATETRANSLATION: "/api/translation/",
  TRANSLATIONS_DELETETRANSLATION: "/api/translation/delete/",
  TRANSLATIONS_IMPORT: "/api/translation/import",
  TRANSLATIONS_EXPORT: "/api/translation/export",
  TRANSLATIONS_UPDATESCREEN: "/api/translation/editScreen",
  TRANSLATIONS_DELETESCREEN: "/api/translation/deleteScreen",

  PARAMETERBED_ALLALTERNATIVEACCOMMODATIONS: "/api/parameterBed/allAlternativeAccommodations",
  PARAMETERBED_ALTERNATIVEACCOMMODATION: "/api/parameterBed/alternativeAccommodation",
  PARAMETERBED_GETPARAMETERBED: "/api/parameterBed/getParametersbed",
  PARAMETERBED_PARAMETERBED: "/api/parameterBed/parameterbed",
  PARAMETERBED_ALLPARAMETERSUF: "/api/parameterBed/allParametersUF",
  PARAMETERBED_PARAMETERUF: "/api/parameterBed/parameterUF",
  PARAMETERBED_ALLBEDDISPATCHBEDMANAGEMENT: "/api/parameterBed/getAllBedDispatchBedManagement",

  GET_LOG: "/api/customeConsole/GetLog",

  V5_CAPACITAIRE: "/api/v5/capacitaire",
  V5_PREVISIONNEL: "/api/v5/previsionnel",
  V5_PATIENTS_TO_TREAT: "/api/v5/patients-to-treat",
  V5_P2PMOVEMENT: "/api/v5/p2p-movement",
  V5_RESTITUTE: "/api/v5/restitute",
  V5_ACCOMMODATIONS: "/api/v5/accommodations",
  V5_AVAILABLE_BEDS: "/api/v5/available-beds",
  V5_UF_STRUCTURE_PDF: "/api/v5/uf-structure-pdf",
  V5_HOSPITALISATION_IN_PROGRESS: "/api/v5/hospitalisation-in-progress",
  V5_UPDATE_BED_STATUS: "/api/v5/update-bed-status",
  V5_JOURNEYS: "/api/v5/journeys",
  V5_BEM_PATIENTS_SEARCH: (search) => `/api/v5/bem-patients/search?s=${encodeURIComponent(search)}`,
  V5_DMS: "/api/v5/dms",

  JOURNEY_CREATE_BEDDISPATCHCONTROL:"/api/prediction/stretchering_modify",
};
