/**
 * @ De: Theo Bensaci
 * @ Date: 16:00 30.11.2023
 * @ Description: card use for bed disaplay vue and bed display vue it self, damn
 */

import React, { useState, useEffect,useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./bedCardStyle.css";
import { Pagination } from "../pagination";
import moment from "moment";
import { Filters } from "../filters";

/**
 * Bed card 
 * @param {*} props 
 */
export function BedCard(props){
    const { t } = useTranslation(["hospitalisation"]);
    const [patienDatas,setPatienDatas]=useState([]);
    const [cell, setCell]=useState(null);
    const [canBeClick,setcanBeClick]=useState(false);
    const [badges,setBadges]=useState([]);

    const [preAdminFlag,setPreAdminFlag]=useState(false);

    const ref=useRef(null);

    const preadm_flag=useRef(false);

    const toolTipsData=useRef(null);
  
    useEffect(()=>{
      showPatienData();

      if(props.anim){
        ref.current.className="bedCard "+getClassByBedStatue(props.data.meta.status);
        void ref.current.offsetWidth;
        ref.current.className="bedCard bedCardShow"+(props.direction==0?"":props.direction==1?"-forward":"-backward")+" "+getClassByBedStatue(props.data.meta.status);
      }
    },[props])


    useEffect(()=>{
      if(cell==null)return;
      getBadges();
    },[cell])


    function getBadges(){
      let returnVal=[];

      if(cell.isAccommodation){
        returnVal.push(
          <div className="cart-badge badge-round badge-accommodation">H</div>
        )
      }
      
      if(cell.isRestitution){
        returnVal.push(
          <div className="cart-badge badge-round badge-restitution">R</div>
        )
      }
      
      if(cell.isDoubleRoom){
        returnVal.push(
          <div className="cart-badge badge-round badge-doubleroom">
            {cell.isDoubleRoom.toUpperCase()}
          </div>
        )
      }

      if(cell.isBedblocker){
        returnVal.push(
          <div className="cart-badge badge-round badge-bedblocker">!</div>
        )
      }

      if(cell.isMoving){
        returnVal.push(
          <div className="cart-badge badge-round badge-moving">➡</div>
        )
      }
      
      if(cell.isLeave24h){
        returnVal.push(
          <div className="cart-badge badge-round badge-leave24h">24</div>
        )
      }

      if(true || cell.isPres24h){
        returnVal.push(
          <div className="cart-badge badge-square badge-pres24h">24</div>
        )
      }

      if(cell.isLeave48h){
        returnVal.push(
          <div className="cart-badge badge-round badge-leave48h">48</div>
        )
      }


      setBadges(returnVal);
    }

    function showPatienData(){
      let renderData=[];

      // si netoyage
      // si le lit est en netoyage
      if(props.data.data.cleaning){
        setcanBeClick(false);
        setPatienDatas([]);
        return;
      }


      if(props.data.cells.length>0){
        let cell=props.data.cells[0];
        setCell(cell);
        
        // set patien data
        renderData.push(<div className="iep">{cell.iep}</div>);
        renderData.push(<div className="name">{cell.name}</div>);
        renderData.push(<div className="addData">{cell.sex} / {cell.age}</div>);
        renderData.push(<div className="enter">{moment(cell.from).format('DD/MM/YYYY')}</div>);

        preadm_flag.current=(props.data.meta.status=="OL" && cell.meta.isPreAdmin && cell.meta.isPreAdmin=="1");

        toolTipsData.current=cell.tooltip.data;
      }
      else{
        preadm_flag.current=false;
      }
      

      // can be click 
      switch(props.data.meta.status){
        case "OL":
          if(props.data.cells.length>0){
            setcanBeClick(true);
            break;
          }
          setcanBeClick(false);
          break;
        case "OO":
          if(props.data.cells.length>0){
            setcanBeClick(true);
            break;
          }
          setcanBeClick(false);
          break;
        case "FD":
          setcanBeClick(false);
          break;
        case "FT":
          setcanBeClick(false);
          break;
      }

      setPatienDatas(renderData);
    }

    function getClassByBedStatue(statue){
      let preadmin=(props.data.cells.length>0 && props.data.meta.status=="OL" && props.data.cells[0].meta.isPreAdmin && props.data.cells[0].meta.isPreAdmin=="1")?" BedCard-Preadmin":"";
      // si le lit est en netoyage
      if(props.data.data.cleaning){
        return "BedCard-Cleaning";
      }

      switch(statue){
        case "OL":
          if(props.data.cells.length>0){
            return ("BedCard-OpenLibre-Occupe "+preadmin);
          }
          return "BedCard-OpenLibre-Libre";
        case "OO":
          if(props.data.cells.length>0){
            return "BedCard-OpenOccupe-Occupe";
          }
          return "BedCard-OpenOccupe-Libre";
        case "FD":
          return "BedCard-FermerDef"
        case "FT":
          return "BedCard-FermerTemp"
      }
    }

    

    return (
      <div className={"bedCard "+getClassByBedStatue(props.data.meta.status)} ref={ref} onClick={(e)=>{
          if(canBeClick)props.onClick(ref.current,toolTipsData.current);
        }}>
        <div className="bedData">
          <div className="uf">#{props.data.data.uf}</div>
          <div className="bedName">{props.data.data.bed}</div>
        </div>
        <div className="patienData">
          {patienDatas}
        </div>
        {preadm_flag.current?
          <div className="preadminFlag">
            {t("pre-admission").toUpperCase()}
          </div>
        :null}
        <div className="badges">
          {cell==null || !canBeClick?null:
          <div className={(preadm_flag.current)?"badgesPreadimistion":""}>
            {badges}
          </div>
          }
        </div>
      </div>
    );
};

/**
 * Display bed cards
 * @param {*} props 
 */
export function BedCardRender(props){
  const numberOfCardParePage=50;
  const [renderedBedCards,setRenderedBedCards]=useState([]);
  const [page,setPage]=useState(0);
  const lastPage=useRef(0);
  const [bedCardToolData,setBedCardToolData]=useState([]);
  const bedCardToolTips=useRef(null);
  const actualCardSelected=useRef(null);
  const data=useRef([]);

  const [filters, setFilters] = useState({
    sorting: "byEntryDateDesc",
    sectors: [],
    origins: [],
    diags: [],
    ieps: [],
    bedStatus: [],
    stayTypes: [],
    anomalie: "all",
  });

  function filteredRows(data){
    let dataFilter=data.map((d)=>{
      if (filters.sectors.length > 0 && !filters.sectors.includes(d.data.sector)) {
        return undefined;
      }
      // disabel fd bed
      if(d.meta.status=="FD") return undefined;
      return d;
    })
    return dataFilter.filter((d)=>{return !(d==undefined)});
  }



  const maxPage=useMemo(()=>{
    let data = filteredRows(props.data);
    let a=data.length % numberOfCardParePage;
    return (data.length-a)/numberOfCardParePage;
  },[props.data])

  useEffect(()=>{
    setRenderedBedCards(renderBedCards(filteredRows(props.data),page));
  },[props, filters])

  
  useEffect(()=>{
    setRenderedBedCards(renderBedCards(filteredRows(props.data),page,true,(lastPage.current>page)?1:2));
    HideToolTips();
  },[page])

  useEffect(()=>{
    setRenderedBedCards(renderBedCards(filteredRows(props.data),page,true));
  },[filters, props.us])


  function orderData(d){
    let d_order=d.sort((a,b)=>{
      return a.data.uf-b.data.uf;
    }).sort((a,b)=>{
      return convertStatue(a.meta.status)-convertStatue(b.meta.status);
    }).sort((a,b)=>{
      return a.meta.bed-b.meta.bed;
    });
    return d_order;
  }

  function convertStatue(statue){
    switch(statue){
      case "OL":
        return 0;
      case "OO":
        return 0;
      case "FD":
        return 1;
      case "FT":
        return 2;
    }
  }


  /**
   * Render card
   * @param {*} data Data to render
   * @param {*} page actual page
   * @param {*} anim if the card are animated
   * @param {*} direction direction of animation, 0 = neutre, 1 = forward, 2 = backward
   * @returns 
   */
  function renderBedCards(data, page, anim=false, direction=0){

    console.log(data);

    let returnValue=[];

    for (let index = 0; index < numberOfCardParePage; index++) {
      let i = page*numberOfCardParePage+index;

      if(i>data.length)break;
      if(data[i]==null || data[i]==undefined)continue;

      returnValue.push(<BedCard data={data[i]} anim={anim} direction={direction} onClick={ShowToolTips}/>);
    }
    return returnValue;
  }


  function ShowToolTips(ellement,data){
    // reste zindex 
    if(actualCardSelected.current!=null)actualCardSelected.current.style.zIndex="auto";

    if(ellement==actualCardSelected.current){
      HideToolTips();
      return;
    }
    ellement.style.zIndex="10";
    actualCardSelected.current=ellement;
    ellement.appendChild(bedCardToolTips.current);
    bedCardToolTips.current.className="bedCardtoolTips bedCardtoolTips-show";

    let toolTipsData=[];
    for (const iterator of data) {
      toolTipsData.push(<label>{iterator.label} : {iterator.value}</label>);
    }
    setBedCardToolData(toolTipsData);
  }

  function HideToolTips(){
    bedCardToolTips.current.className="bedCardtoolTips bedCardtoolTips-hide";
    actualCardSelected.current=null;
  }


  return (
    <div className="bedCardRender">
      <Filters filters={filters} setFilters={setFilters} rows={props.rows} activeFilter={[true,false,false,false,false,false,false,false]}/>
      <Pagination rows={filteredRows(props.data)} pageMax={maxPage} page={page} setPage={(a)=>{lastPage.current=page;setPage(a);}}/>
      <div className="bedCards">
        {renderedBedCards}
      </div>
      <div ref={bedCardToolTips} className="bedCardtoolTips" onClick={()=>{ShowToolTips(actualCardSelected.current,[])}}>
        {bedCardToolData}
      </div>
    </div>
  );
}
