import React, { useState, useEffect } from "react";
import "./index.css";
import ScreenParameter from "../screens/screenParameter";
import ScreenAccommodationAlternatives from "../screens/screenAccommodationAlternatives";
import ScreenUfsRelatedParameters from "../screens/screenUfsRelatedParameters";
import ScreenBedManagementCells from "../screens/screenBedManagementCells";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../utils/api";
import ReactLoading from "react-loading";
import * as Permission from "../../utils/permissions";
import AccesDenied from "../../components/not-found/accesDenied";

const HeaderParameterBeds = (props) => {
  const [screen, setScreen] = useState("emergency");
  const [load, setLoad] = useState(false);

  const { t, i18n } = useTranslation(["administration"]);

  useEffect(() => {
    if (window.innerWidth <= 767.98) {
      setScreen(props.location.state && props.location.state.hash);
    } else {
      let screenDefault = "screen parameter";

      setScreen(screenDefault);
    }
  }, [props.location.state]);

  const renderScreen = (name = "") => {
    let useName = name == "" ? screen : name;
    switch (useName) {
      case "screen parameter":
        return <ScreenParameter />;
        break;
      case "screen accommodation alternatives":
        return <ScreenAccommodationAlternatives />;
        break;
      case "screen ufs related parameters":
        return <ScreenUfsRelatedParameters />;
        break;
      case "screenBedManagementCells":
        return <ScreenBedManagementCells />;
    }
  };

  if (Permission.hasAtLeastOneRole(Permission.roles["bedManager"], props.roles)) {
    return props.setScreen != undefined ? (
      renderScreen(props.setScreen)
    ) : (
      <>
        <div className="main-screen container-rh">
          <div className="demo">
            <h2 className="h2">{t("parameter bed")}</h2>
          </div>

          <ul className="nav nav-tabs">
            {Permission.hasRole("BED_MANAGER PARAMETER", props.roles) && (
              <li className="nav-item d-flex">
                <a
                  onClick={() => setScreen("screen parameter")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen == "screen parameter" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("parameter").toUpperCase()}
                </a>
              </li>
            )}
            {Permission.hasRole("BED_MANAGER ACCOMODATION ALTERNATIVES", props.roles) && (
              <li className="nav-item d-flex">
                <a
                  onClick={() => setScreen("screen accommodation alternatives")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen == "screen accommodation alternatives" ? "active disabled" : ""
                  }`}
                  href="#"
                >
                  {t("accommodation alternatives").toUpperCase()}
                </a>
              </li>
            )}

            {Permission.hasRole("BED_MANAGER UFS RELATED PARAMETERS", props.roles) && (
              <li className="nav-item d-flex">
                <a
                  onClick={() => setScreen("screen ufs related parameters")}
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    screen == "screen ufs related parameters" ? "active disabled" : ""
                  } `}
                  href="#"
                >
                  {t("ufs related parameters").toUpperCase()}
                </a>
              </li>
            )}
          </ul>

          {renderScreen()}
        </div>
      </>
    );
  } else return <AccesDenied />;
};

const mapStateToProps = (state) => ({
  screensData: state.screensEmergData,
  screensHospData: state.screensHospData,
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(HeaderParameterBeds);
