import React, { useMemo } from "react";
import "./style.scss";
import moment from "moment";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

function Capacitaire({ ufs, capacitaire }) {
  const today = useMemo(() => moment(), []);
  const { t } = useTranslation(["hospitalisation"]);

  const filteredUfs = useMemo(
    () => capacitaire.filter(({ uf }) => ufs.includes(uf) ?? false),
    [ufs, capacitaire],
  );

  const summary = useMemo(
    () => ({
      ouverts: sumBy(filteredUfs, (uf) => uf.ouverts),
      fermes: sumBy(filteredUfs, (uf) => uf.fermes),
      taux_occupation:
        filteredUfs.length > 0
          ? sumBy(filteredUfs, (uf) => uf.taux_occupation) / filteredUfs.length
          : null,
      libresSoinsMedecine: sumBy(
        filteredUfs.filter((uf) => uf.groupe === "MEDECINE"),
        (uf) => uf.disponibles,
      ),
      libresSoinsChirurgie: sumBy(
        filteredUfs.filter((uf) => uf.groupe === "CHIRURGIE"),
        (uf) => uf.disponibles,
      ),
      libresSoinsCritiques: sumBy(
        filteredUfs.filter((uf) => uf.groupe === "SOINS CRITIQUES"),
        (uf) => uf.disponibles,
      ),
      libresSoinsSpecifiques: sumBy(
        filteredUfs.filter((uf) => uf.groupe === "SOINS SPECIFIQUES"),
        (uf) => uf.disponibles,
      ),
    }),
    [filteredUfs],
  );

  return (
    <>
      <div
        className="row capacitaire-summary"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "30px",
          marginBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <fieldset>
          <legend className="titleIndicators">Capacitaire du {today.format("DD.MM.YYYY")}</legend>
          <div
            className="col-12 decompte-indicators"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "15px",
            }}
          >
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Ouverts")}</span>
              </h5>
              <p className="screen3-indicator">{summary.ouverts}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Fermés")}</span>
              </h5>
              <p className="screen3-indicator">{summary.fermes}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Taux occupation")}</span>
              </h5>
              <p className="screen3-indicator">
                {summary.taux_occupation ? `${(summary.taux_occupation * 100).toFixed(2)}%` : "-"}
              </p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Lits libres soins critiques")}</span>
              </h5>
              <p className="screen3-indicator">{summary.libresSoinsCritiques}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Lits libres soins spécifiques")}</span>
              </h5>
              <p className="screen3-indicator">{summary.libresSoinsSpecifiques}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Lits libres chirurgie")}</span>
              </h5>
              <p className="screen3-indicator">{summary.libresSoinsChirurgie}</p>
            </div>
            <div className="d-flex flex-column indicator-gray-title-black">
              <h5 className="screen3-title-indicator">
                <span style={{ fontSize: 16 }}>{t("Lits libres médecine")}</span>
              </h5>
              <p className="screen3-indicator">{summary.libresSoinsMedecine}</p>
            </div>
          </div>
        </fieldset>
      </div>
      <div
        className="col-12 tableFixHead"
        style={{ overflow: "auto", height: "43vh", marginTop: 10, paddingLeft: 0, paddingRight: 0 }}
      >
        <table className="table table-bordered table-capacitaire" style={{ alingSelf: "center" }}>
          <thead className="thead-theme">
            <tr>
              <th className="thLits" scope="col" style={{ textAlign: "right", maxWidth: 200 }}>
                {t("designation")}
              </th>
              <th className="thLits" scope="col">
                #
              </th>
              <th className="thLits" scope="col">
                {t("dotation")}
              </th>
              <th className="thLits" scope="col">
                {t("ouverts")}
              </th>
              <th className="thLits" scope="col">
                {t("fermes")}
              </th>
              <th className="thLits" scope="col">
                {t("occupes")}
              </th>
              <th className="thLits" scope="col">
                {t("reserves")}
              </th>
              <th className="thLits" scope="col">
                {t("hebergement")}
              </th>
              <th className="thLits" scope="col">
                {t("disponibles")}
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredUfs.map((uf) => {
              return (
                <tr className="trLits" key={uf.uf}>
                  <td
                    className=""
                    title={uf.designation ?? undefined}
                    style={{ textAlign: "right", maxWidth: 200 }}
                  >
                    <div className="truncate">{uf.designation ?? "-"}</div>
                  </td>
                  <td>{uf.uf ?? "-"}</td>
                  <td>{uf.dotation ?? "-"}</td>
                  <td>{(uf.dotation-uf.fermes) ?? "-"}</td>
                  <td>{uf.fermes ?? "-"}</td>
                  <td>{uf.occupes ?? "-"}</td>
                  <td>{uf.reserves ?? "-"}</td>
                  <td>{uf.hebergement ?? "-"}</td>
                  <td>{uf.disponibles ?? "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Capacitaire;
