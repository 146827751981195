import React, { forwardRef, useMemo, useEffect } from "react";
import { groupBy } from "lodash";
import Select from "react-select";
import { useRequest } from "../../hooks/use-api";
import { API } from "../../utils/api";
import { withSuspenseLoader } from "../../components/loader";
import { useQuery } from "@tanstack/react-query";

export default withSuspenseLoader(
  forwardRef(function BedsSelector(
    { uf, beds, onSelect, isSearchable = false, isMulti = false, ...props },
    ref,
  ) {
    const request = useRequest();

    const {
      data: [allBeds],
    } = useQuery(
      ["beds-selector", "available-beds", uf],
      () =>
        request({
          method: "GET",
          path: API.V5_AVAILABLE_BEDS,
          params: {
            uf,
          },
        }),
      {
        staleTime: 5 * 60 * 1000, // données mises en cache pendant 5 minutes
        refetchInterval: 5 * 60 * 1000, // rafraîchissement toutes les 5 minutes
        refetchIntervalInBackground: true,
      },
    );

    const values = useMemo(
      () =>
        Object.fromEntries(
          allBeds
            ?.filter((bed) => bed.status === "OL")
            .map((bed) => [
              `${bed.lit}/${bed.chambre}`,
              { value: `${bed.lit}/${bed.chambre}`, label: `Lit ${bed.lit}` },
            ]) ?? [],
        ),
      [allBeds],
    );

    useEffect(
      function () {
        if (Object.keys(values).length > 0 && beds && beds.length > 0) {
          const validBeds = beds.filter((bed) => Boolean(values[bed]));

          if (beds.length !== validBeds.length) {
            onSelect(validBeds);
          }
        }
      },
      [values, beds],
    );

    const bedOptions = useMemo(
      function () {
        const ufBedsPerRoom = groupBy(allBeds, "chambre");

        return Object.entries(ufBedsPerRoom).map(([chambre, chambreBeds]) => ({
          label: `Chambre ${chambre}`,
          options: chambreBeds.map((bed) => ({
            value: `${bed.lit}/${bed.chambre}`,
            label: `Lit ${bed.lit}`,
            isDisabled: bed.status !== "OL",
          })),
        }));
      },
      [allBeds],
    );

    return (
      <Select
        key={uf}
        ref={ref}
        {...props}
        value={beds.map((bed) => values[bed])}
        className="selectJ1"
        isSearchable={isSearchable}
        isMulti={isMulti}
        styles={{ fontSize: 20 }}
        onChange={(options) =>
          onSelect(
            isMulti
              ? options?.map((option) => option.value) ?? []
              : options?.value
              ? [options.value]
              : [],
          )
        }
        options={bedOptions}
      />
    );
  }),
  { height: 45 },
);
