import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { Line } from "react-chartjs-2";
import "./index.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import moment from "moment";
const ChartLine = (props) => {
  const [annotation, setAnnotation] = useState({});

  const { t, i18n } = useTranslation([`${props.traduction}`]);

  useEffect(() => {
    if (props.annotations === true && props.data !== null) {
      let testValue = props.data["labels"].indexOf(moment().format("YYYY-MM-DD"));

      setAnnotation({
        type: "line",
        id: "vline" + 0,
        mode: "vertical",
        scaleID: "x-axis-0",
        value: testValue,
        borderDash: [3, 3],
        borderColor: "black",
        borderWidth: 1,
        label: {
          enabled: false,
        },
      });
    }
  }, [props.data, props.language]);

  const renderChart = () => {
    if (!props.data) return null;

    let yAxes;
    if (props.hasTwoAxeY) {
      yAxes = [
        {
          display: true,
          position: "left",
          id: "first-y-axis",
          type: "linear",
          scaleLabel: {
            display: true,
            labelString: props.labelY1,
            fontSize: 18,
          },
          ticks: {
            beginAtZero: true,
          },
        },
        {
          display: props.labelY2 && props.labelY2 !== false ? true : false,
          position: "right",
          id: "second-y-axis",
          type: "linear",
          scaleLabel: {
            display: true,
            labelString: props.labelY2,
            fontSize: 18,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ];
    } else {
      yAxes = [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: props.labelY,
            fontSize: 18,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ];
    }
    let size,
      sizeLabel = 15;
    window.innerWidth >= 2400
      ? (size = 22)
      : window.innerWidth <= 767
      ? (size = 16)
      : (size = props.titleSize);
    return (
      <Line
        redraw={props.update}
        data={props.data}
        height={props.height && props.height !== "" ? props.height : 450}
        // width="100%"
        options={{
          plugins: {
            datalabels: {
              display: false,
            },
          },

          maintainAspectRatio: false,
          title: {
            display: true,
            text: props.title,
            fontSize: size,
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: window.innerWidth >= 2400 ? sizeLabel : 12,
            },
          },

          annotation: props.annotations === true ? { annotations: [annotation] } : {},

          scales: {
            yAxes: yAxes,
            xAxes: [
              {
                barThickness: 15,
                ticks: {
                  callback: function (value, index, values) {
                    let v = moment(value);
                    return v.locale(i18n.language).format("DD MMM");
                  },
                },
              },
            ],
          },

          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: props.annotations
              ? {
                  label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || "";
                    if (label) {
                      label += ": ";
                    }
                    label +=
                      tooltipItem.yLabel % 1 === 0
                        ? tooltipItem.yLabel
                        : tooltipItem.yLabel.toFixed(2);
                    return label;
                  },

                  labelColor: function (tooltipItem, chart) {
                    if (
                      chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === t("real")
                    ) {
                      return {
                        borderColor: "#909090",
                        backgroundColor: "#909090",
                      };
                    } else {
                      return {
                        borderColor:
                          chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                        backgroundColor:
                          chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                      };
                    }
                  },
                }
              : {
                  labelColor: function (tooltipItem, chart) {
                    if (
                      chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === t("real")
                    ) {
                      return {
                        borderColor: "#909090",
                        backgroundColor: "#909090",
                      };
                    } else {
                      return {
                        borderColor:
                          chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                        backgroundColor:
                          chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                      };
                    }
                  },
                },
          },
          elements: {
            point: {
              radius: 1,
            },
          },
        }}
      />
    );
  };

  return renderChart();
};

export default ChartLine;
