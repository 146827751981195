import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enGB, fr, it, de } from "date-fns/locale";

const locales = {
  en: enGB,
  fr: fr,
  it: it,
  de: de,
};

export function useFormat() {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = locales[language];

  return (date, pattern) => format(date, pattern, { locale });
}
