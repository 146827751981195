import React, { useState, useEffect } from "react";
import "./index.css";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API } from "../../../utils/api";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";

const NewMember = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [taux, setTaux] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [errorBorder, setErrorBorder] = useState(null);
  const [optionsStatus, setOptionsStatus] = useState(props.status);
  const [selectedOptionStatus, setOptionSelectedStatus] = useState(props.status[0]);
  const [optionFunctions, setOptionFunctions] = useState(props.functions);
  const [selectedOptionFunction, setOptionSelectedFunction] = useState(props.functions[0]);
  const [message, setMessage] = useState(false);
  const [categories, setCategories] = useState(props.categories);
  const [categoriesID, setCategoriesID] = useState([]);

  const addMember = (event) => {
    event.preventDefault();

    setLoad(true);
    setShowError(false);

    if (selectedOptionFunction.value === 0) {
      setErrorBorder(["choiceFunction"]);
      setShowError(true);
      setError({ function: "please select a function" });
      return;
    }

    if (parseInt(taux) < 0 || parseInt(taux) > 100) {
      setErrorBorder(["taux"]);
      setShowError(true);
      setError({ function: "incorrect rate" });
      return;
    }

    let member = {
      nom: lastName,
      prenom: name,
      taux: taux,
      startDate: startDate === "" ? "" : startDate,
      endDate: endDate === "" ? "" : endDate,
      description: description === "" ? "" : description,
      function_id: selectedOptionFunction.value === 0 ? null : selectedOptionFunction.value,
      status_id: selectedOptionStatus.value === 0 ? null : selectedOptionStatus.value,
    };

    axios({
      method: "POST",
      url: `${API.ENDPOINT + API.RH_MEMBER}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: { member, categoriesID },
    })
      .then((response) => {
        setMessage(true);
        setName("");
        setLastName("");
        setTaux("");
        setEndDate(null);
        setStartDate(null);
        setOptionSelectedStatus(props.status[0]);
        setOptionSelectedFunction(props.functions[0]);
        props.addMember(response.data);

        setTimeout(() => setMessage(false), 3000);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === "401") {
            setError(error.response.data);
          }
          if (error.response.status === "400") {
            let errors = {};
            error.response.data.errors.map((err) => {
              errors[err.param.split(".")[1]] = err.msg;
            });
            setError(errors);
          }
        }
        console.error("There was an error in insert ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const handleCheckBoxCategories = (e) => {
    let catID;

    if (e.target.checked) {
      catID = [...categoriesID, e.target.id];
    } else {
      catID = categoriesID.filter((cat) => cat !== e.target.id);
    }
    if (catID.length == 0) {
      catID = [`${categoriesID[0]}`];
    }
    setCategoriesID(catID);
  };

  return (
    <div className="row" style={{ marginTop: 20 }}>
      <div className="container-user">
        <h1 className="page-title">{t("add member")}</h1>
        <div className="row">
          <div className="col-10 offset-1">
            <hr></hr>
            <form onSubmit={addMember} className="form-user-new">
              <div style={{ textAlign: "center", height: "50px" }}>
                {showError &&
                  error &&
                  Object.entries(error).map((err, i) => (
                    <div key={i} className="alert alert-danger w-85" role="alert">
                      <h3>{t(err)} </h3>
                    </div>
                  ))}
                {message ? (
                  <div className="alert alert-success w-85" role="alert">
                    {" "}
                    {t("the member has been added successfully.")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group inline-block col-lg-6">
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  className="fadeIn form-control"
                  name="_name"
                  placeholder={t("first name")}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <input
                  type="text"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastname"
                  className="fadeIn form-control"
                  name="_lastname"
                  placeholder={t("last name")}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <Select
                  value={selectedOptionFunction}
                  className={`selectJ1 form-select ${
                    showError && errorBorder && errorBorder.includes("choiceFunction")
                      ? "error"
                      : ""
                  }`}
                  isSearchable={false}
                  onChange={(selectedOption) => setOptionSelectedFunction(selectedOption)}
                  options={optionFunctions}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <input
                  type="text"
                  required
                  value={taux}
                  onChange={(e) => setTaux(e.target.value)}
                  id="taux"
                  className="fadeIn form-control"
                  name="_taux"
                  placeholder={t("rate")}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <DatePicker
                  style={{ borderColor: "" }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  isClearable
                  placeholderText={t("from")}
                  dateFormat="dd/MM/yyyy"
                  maxDate={endDate ? endDate : new Date(moment().subtract(1, "day"))}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <DatePicker
                  selected={endDate}
                  isClearable
                  placeholderText={t("to")}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={startDate}
                />
              </div>
              <div className="form-group inline-block col-lg-6">
                <Select
                  value={selectedOptionStatus}
                  className="selectJ1 form-select"
                  isSearchable={false}
                  onChange={(selectedOptionStatus) => setOptionSelectedStatus(selectedOptionStatus)}
                  options={optionsStatus}
                />
              </div>
              <div className="form-group inline-block col-lg-12">
                <textarea
                  className="fadeIn form-control"
                  style={{ width: "93%", margin: "auto" }}
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t("description")}
                >
                  {description}
                </textarea>
              </div>
              <div style={{ width: "85%", marginLeft: "10%" }}>
                <p style={{ fontSize: 20, textAlign: "center" }}>{t("categories")}:</p>
                <div className="row">
                  {categories &&
                    categories.map((category, i) => {
                      return (
                        <div
                          className="custom-control col-lg-3 col-md-6 col-12 custom-control-user custom-checkbox"
                          key={i}
                        >
                          <input
                            onChange={handleCheckBoxCategories}
                            type="checkbox"
                            checked={categoriesID.includes(`${category.index}`) ? true : false}
                            className="custom-control-input"
                            id={category.index}
                          />
                          <label
                            style={{ fontSize: 16 }}
                            className="custom-control-label"
                            htmlFor={category.index}
                          >
                            {category.denomination}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="form-group" style={{ marginTop: 20 }}>
                <a
                  title="retour"
                  className="btn"
                  style={{ textDecoration: "underline" }}
                  onClick={() => props.setPage("listmembers")}
                >
                  {t("back")}
                </a>
                <input
                  type="submit"
                  disabled={name !== "" ? false : true}
                  style={{ textAlign: "center" }}
                  className="fadeIn fourth btn"
                  value={t("save")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(NewMember);
