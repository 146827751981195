import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "./index.css";
import { useTranslation } from "react-i18next";

const ChartBar = (props) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [max, setMax] = useState(null);

  const { t } = useTranslation([`${props.traduction}`]);

  useEffect(() => {
    if (update === true) setUpdate(false);
  }, [update]);

  useEffect(() => {
    let max = 0;
    props.data &&
      Object.values(props.data).map((item) => {
        max += item["data"][0];
      });
    setMax(max);

    setData({
      labels: [""],
      datasets: props.data && Object.values(props.data),
    });

    setUpdate(true);
  }, [props.data, props.language]);

  const renderChart = () => {
    return (
      <Bar
        redraw={update}
        data={data}
        width={60}
        options={{
          maintainAspectRatio: false,

          plugins: {
            datalabels: {
              display: false,
            },
          },
          layout: {
            padding: 0,
          },
          scales: {
            xAxes: [
              {
                stacked: true,

                barThickness: 25,
                gridLines: {
                  zeroLineColor: "transparent",
                  drawBorder: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  max: max,
                },
                display: false,
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
          tooltips: {
            caretSize: 0,
            bodySpacing: 0,
            titleSpacing: 0,
            xPadding: 2,
            yPadding: 2,
            cornerRadius: 2,
            titleMarginBottom: 2,
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";
                if (label) {
                  label += ": ";
                }
                label += Math.round(tooltipItem.yLabel);
                return label + "%";
              },
            },
          },
        }}
      />
    );
  };

  return (
    <div className={data && props.box ? "row row-chart-box stacked" : "row row-chart stacked"}>
      {data && renderChart()}
    </div>
  );
};

export default ChartBar;
