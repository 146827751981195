import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { Line, Bar } from "react-chartjs-2";
import "./index.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import moment from "moment";

const LineArea = (props) => {
  //#region variable

  //#endregion

  //#region Chart Js Stuff
  const options = {
    responsive: true,
    scales: {
      xAxes: [
        {
          type: "time",
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
      datalabels: {
        display: false,
      },
      tooltips: {
        mode: "index",
        intersect: false,
        interaction: {
          mode: "index",
        },
      },
    },
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [1, 2, 3, 4, 5, 6, 7, 3, 4, 6, 2, 3, 4],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132)",
        fill: true,
        borderWidth: 0,
        pointRadius: 0,
        pointStyle: "rectRot",
      },
      {
        label: "Dataset 2",
        data: [2, 3, 4, 4, 3, 2, 1, 3, 4, 6, 2, 3, 4],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: true,
        borderWidth: 0,
        pointRadius: 0,
        pointStyle: "rectRot",
      },
    ],
  };

  //#endregion

  //#region React Stuff

  //#endregion
  return (
    <Line
      height={props.height}
      width={props.width}
      options={options}
      data={props.data == null ? data : props.data}
    />
  );
};

export default LineArea;
