import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import DetailDesiderata from "./detailDesiderata";
import ListDesiderataValidated from "./listDesideratasValidated";
import ListDesiderataCanceled from "./listDesideratasCanceled";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import { ImPlus, ImCheckmark, ImCross } from "react-icons/im";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import { confirmAlert } from "react-confirm-alert";
import ShowModal from "./modalCanceld";

const ListDesideratasOnGoing = (props) => {
  const { t, i18n } = useTranslation(["rh"]);
  const [page, setPage] = useState("listDesideratasongoing");
  const [load, setLoad] = useState(false);
  const [globalAllDesideratasData, setGlobalAllDesideratasData] = useState([]);
  const [desiderata, setDesiderata] = useState([]);
  const [plannings, setPlannings] = useState([]);
  const [unavailableDoctors, setUnavailableDoctors] = useState([]);
  const [daysValidated, setDaysValidated] = useState([]);
  const [filterDesideratasData, setFilterDesideratasData] = useState([]);
  const [optionsDoctors, setoptionsDoctors] = useState([]);
  const [selectedOptionDoctor, setoptionsDoctorselected] = useState({
    value: null,
    label: t("all doctors"),
  });
  const [cause, setCause] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let page = "listDesideratasongoing";
    setPage(page);
    let start = moment().startOf("month").format("YYYY-MM-DD");
    let member_id = 1;
    axios
      .post(
        `${API.ENDPOINT + API.RH_ALLDESIDERATAS}`,
        {
          startDate: start,
          index: member_id,
        },
        {
          headers: {
            "x-auth-token": props.activeUSer.user.token,
          },
        },
      )
      .then((res) => {
        console.log(res.data);
        setGlobalAllDesideratasData(res.data.planning);
        setFilterDesideratasData(res.data.planning);
        setUnavailableDoctors(res.data.unavailable);
        setDaysValidated(res.data.daysValidated);
        loadingDoctors(res.data.planning);
        setLoad(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  useEffect(() => {
    let desideratas = [];

    globalAllDesideratasData.map((d) => {
      if (
        selectedOptionDoctor &&
        selectedOptionDoctor.value !== null &&
        selectedOptionDoctor.value !== d.index
      )
        return;
      desideratas = [...desideratas, d];
    });

    setFilterDesideratasData(desideratas);
  }, [selectedOptionDoctor, globalAllDesideratasData]);

  const loadingDoctors = (Data) => {
    let doctors = [];
    Data.map((doctor) => {
      doctors.push({
        value: doctor.index,
        label: doctor.nom.toUpperCase() + " " + doctor.prenom.toUpperCase(),
      });
    });
    setoptionsDoctors([{ value: null, label: t("all doctors") }, ...doctors]);
  };

  const confirmationCancelDesiderata = (desiderata) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("Are you sure you want to cancel this desiderata ?"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => PopUpCancelDesiderata(desiderata),
        },
        {
          label: t("no"),
        },
      ],
    });
  };

  const PopUpCancelDesiderata = (desiderata) => {
    setDesiderata(desiderata);
    ref.current.PopUpCancelDesiderata();
  };
  const confirmationValidDesiderata = (desiderata) => {
    confirmAlert({
      title: t("confirmation"),
      message: t("Are you sure you want to valid this desiderata"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => validDesiderata(desiderata),
        },
        {
          label: t("no"),
        },
      ],
    });
  };
  const validDesiderata = (desiderata) => {
    let desiderataValidated = {
      index: desiderata.index,
      cause: "",
      status: "validated",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderataValidated,
    })
      .then((response) => {
        let _plan = response.data;
        if (_plan.Types.denomination === "Vacance") {
          daysValidated[desiderata.member_id] =
            daysValidated[desiderata.member_id] +
            moment(_plan.endDate).diff(moment(_plan.startDate), "days") +
            1;

          setDaysValidated(daysValidated);
        }
        setShowMessage(true);
        editStatusDesiderata(_plan);
        setMessage({ edit: "the desiderata was successfully validated." });
        setTimeout(() => setShowMessage(false), 3000);
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };
  const cancelDesiderata = (desiderata) => {
    let desiderataCanceled = {
      index: desiderata.index,
      cause: desiderata.cause,
      status: "canceled",
    };

    axios({
      method: "PATCH",
      url: `${API.ENDPOINT + API.RH_PLANNINGSTATUS}`,
      headers: {
        "x-auth-token": props.activeUSer.user.token,
      },
      data: desiderataCanceled,
    })
      .then((response) => {
        let _plan = response.data;

        setShowMessage(true);
        setMessage({ edit: "the desiderata was successfully canceled." });
        setTimeout(() => setShowMessage(false), 3000);
        editStatusDesiderata(_plan);
      })
      .catch((error) => {
        console.error("There was an error in update ! ", error);
        setLoad(false);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      });
  };

  const toDetail = (member, desiderata) => {
    setPlannings({ member, desiderata });
    setPage("detailDesiderata");
  };

  const renderList = () => {
    if (!filterDesideratasData) return null;
    return filterDesideratasData.map((member) => {
      return (
        member.Planning &&
        member.Planning.length > 0 &&
        member.Planning.map(
          (desiderata, i) =>
            desiderata.status === "in_progress" && (
              <tr key={i}>
                <td className="txt-center-middle">
                  {member.nom} {member.prenom}
                </td>
                <td className="txt-center-middle">{desiderata.Types.denomination}</td>
                <td className="txt-center-middle">
                  {moment(desiderata.startDate).format("YYYY-MM-DD")}
                  <br />
                  {desiderata.startShift === null || desiderata.startShift === ""
                    ? "-"
                    : desiderata.startShift}
                </td>
                <td className="txt-center-middle">
                  {moment(desiderata.endDate).format("YYYY-MM-DD")}
                  <br />
                  {desiderata.endShift === null || desiderata.endShift === ""
                    ? "-"
                    : desiderata.endShift}
                </td>
                <td className="txt-center-middle">
                  {daysValidated[member.index] ? daysValidated[member.index] : 0}
                </td>
                <td className="txt-center-middle">
                  {unavailableDoctors[desiderata.index + "-" + member.index]}
                </td>
                <td className="txt-center-middle">{t("status on going")}</td>
                <td>
                  <>
                    <a
                      style={{ margin: 5 }}
                      className={"icon-size"}
                      onClick={() => confirmationValidDesiderata(desiderata)}
                    >
                      <ImCheckmark
                        className="icon-action"
                        style={{
                          color: "green",
                          fontSize: 18,
                          marginTop: -2,
                          cursor: "pointer",
                        }}
                      />
                    </a>
                    <a
                      style={{ margin: 5 }}
                      className={"icon-size"}
                      onClick={() => confirmationCancelDesiderata(desiderata)}
                    >
                      <ImCross
                        className="icon-action"
                        style={{
                          color: "red",
                          fontSize: 18,
                          marginTop: -2,
                          cursor: "pointer",
                        }}
                      />
                    </a>
                    <div>
                      <a onClick={() => toDetail(member, desiderata)} className="btn-detail">
                        {t("detail")}
                      </a>
                    </div>
                  </>
                </td>
              </tr>
            ),
        )
      );
    });
  };

  const editStatusDesiderata = (desiderata) => {
    let desideratas = [];
    globalAllDesideratasData.map((plan, i) => {
      if (plan.index === desiderata.member_id) {
        let _desiderata = [];
        plan.Planning.map((p) => {
          if (p.index === desiderata.index) {
            _desiderata = [..._desiderata, desiderata];
          } else {
            _desiderata = [..._desiderata, p];
          }
        });
        plan.Planning = _desiderata;
        desideratas = [...desideratas, plan];
      } else desideratas = [...desideratas, plan];
    });
    setGlobalAllDesideratasData(desideratas);
  };

  const displayScreenDesiderata = () => {
    switch (page) {
      case "listDesideratasongoing":
        return ScreenDesederatasOngoing();
      case "listDesideratasValidated":
        return (
          <ListDesiderataValidated
            setPage={setPage}
            doctors={optionsDoctors}
            desiderata={globalAllDesideratasData}
            editStatusDesiderata={editStatusDesiderata}
            daysValidated={daysValidated}
          />
        );
      case "listDesideratasCanceled":
        return (
          <ListDesiderataCanceled
            setPage={setPage}
            doctors={optionsDoctors}
            desiderata={globalAllDesideratasData}
            editStatusDesiderata={editStatusDesiderata}
            daysValidated={daysValidated}
          />
        );
      case "detailDesiderata":
        return (
          <DetailDesiderata
            setPage={setPage}
            page={"listDesideratasongoing"}
            editStatusDesiderata={editStatusDesiderata}
            plannings={plannings}
            daysValidated={daysValidated}
          />
        );
    }
  };

  const ScreenDesederatasOngoing = () => {
    return (
      <div className="row mrg-t">
        <div className="container-user">
          <div
            className="col-12 d-flex justify-content-between flex-col"
            style={{
              textAlign: "end",
              marginTop: 20,
              flexWrap: "wrap",
              padding: 0,
            }}
          >
            <button
              onClick={() => setPage("listDesideratasongoing")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
              disabled={true}
            >
              {t("desiderata ongoing")}
            </button>
            <button
              onClick={() => setPage("listDesideratasValidated")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
            >
              {t("desiderata validated")}
            </button>
            <button
              onClick={() => setPage("listDesideratasCanceled")}
              style={{ marginBottom: 10 }}
              className="btn btn-user-1 btn-primary desederatasNavBnt"
            >
              {t("desiderata canceled")}
            </button>
            <div className="col-lg-4 col-md-6 col-12 align-items-end p-0" style={{ zIndex: 6 }}>
              <Select
                value={selectedOptionDoctor}
                className="selectJ1 form-select"
                isSearchable={false}
                onChange={(selectedOptionDoctor) => setoptionsDoctorselected(selectedOptionDoctor)}
                options={optionsDoctors}
              />
            </div>
          </div>
          <div
            className="message"
            style={{
              marginTop: 20,
              marginBottom: 20,
              height: "50px",
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            {showMessage &&
              message &&
              Object.entries(message).map((msg) => (
                <div className="alert alert-success w-85" role="alert">
                  <h3>{t(msg)}</h3>
                </div>
              ))}
            {showError &&
              error &&
              Object.entries(error).map((err) => (
                <div className="alert alert-danger w-85" role="alert">
                  <h3>{t(err)} </h3>
                </div>
              ))}
          </div>
          <div className="overflow" style={{ marginTop: 10 }}>
            <table
              className="table table-translation table-striped table-bordered table-scroll"
              style={{ width: "100%" }}
            >
              <thead className="fix-header">
                <tr>
                  <th className="header" style={{ width: "12%" }}>
                    <span style={{ display: "block" }}>{t("doctors")}</span>
                  </th>
                  <th className="header" style={{ width: "12%" }}>
                    <span style={{ display: "block" }}>{t("types")}</span>
                  </th>
                  <th className="header" style={{ width: "12%" }}>
                    <span style={{ display: "block" }}>{t("date start desederata")}</span>
                  </th>
                  <th className="header" style={{ width: "12%" }}>
                    <span style={{ display: "block" }}> {t("date end desederata")}</span>
                  </th>
                  <th className="header" style={{ width: "8%" }}>
                    <span style={{ display: "block" }}>
                      {t("vacation day already granted during the year")}
                    </span>
                  </th>
                  <th className="header" style={{ width: "8%" }}>
                    <span style={{ display: "block" }}>
                      {t("staff unavailable during the period")}
                    </span>
                  </th>
                  {/*
                  <th className="header" style={{ width: "8%" }}>
                    <span style={{ display: "block" }}>
                      {t("number of current requests over the period")}
                    </span>
                  </th>*/}
                  <th className="header" style={{ width: "12%" }}>
                    <span style={{ display: "block" }}>{t("desederata status")}</span>
                  </th>
                  <th className="header" style={{ width: "8%" }}>
                    <span style={{ display: "block" }}>{t("validation")}</span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
              <div style={{ zIndex: 7 }}>
                <ShowModal
                  ref={ref}
                  cancelDesiderata={cancelDesiderata}
                  desiderata={desiderata}
                  setCause={setCause}
                />
              </div>
            </table>
          </div>
        </div>
      </div>
    );
  };
  return <div>{displayScreenDesiderata()}</div>;
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ListDesideratasOnGoing);
