/**
 * By : ???
 * Modify : Theo Bensaci
 * Creation Date : ???
 * Modification Date : 17.03.2022
 * Description : Screen for manage Launched planning
 */

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import axios from "axios";
import { connect } from "react-redux";
import { API } from "../../../utils/api";
import ReactLoading from "react-loading";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment/min/moment-with-locales";

const ScreenPlanningsLaunch = (props) => {
  //#region variable
  const { t, i18n } = useTranslation(["rh"]);
  const [isLoading, setIsLoading] = useState(false);
  const [planningShowed, setPlanningShowed] = useState([]);
  const data = useRef();
  const actualSort = useRef("dateAsc");

  /**
   * all sort methods
   * @param {object} item1
   * @param {object} item2
   */
  const SORT_METHODS = {
    dateAsc: (item1, item2) => {
      return new Date(item1.date) - new Date(item2.date);
    },
    dateDec: (item1, item2) => {
      return new Date(item2.date) - new Date(item1.date);
    },
    statutAsc: (item1, item2) => {
      if (
        item2.flag_in_progress < item1.flag_in_progress ||
        item2.flag_to_launch < item1.flag_to_launch
      ) {
        return 1;
      }
      return new Date(item1.date) - new Date(item2.date);
    },
    statutDec: (item1, item2) => {
      if (
        item1.flag_in_progress < item2.flag_in_progress ||
        item1.flag_to_launch < item2.flag_to_launch
      ) {
        return 1;
      }
      return new Date(item1.date) - new Date(item2.date);
    },
  };
  let sortData = null;

  //#endregion
  //#region Interne function

  /**
   * Load page data
   */
  function LoadPage() {
    loadLaunchPlaning();
    /*actualData=testData;
    UpdateSort(actualSort.current);*/
  }

  /**
   * Update Page data
   */
  function UpdatePage() {
    genLaunchPlanningItem(sortData);
  }

  /**
   * Update the sort methods and sort data
   * @param {string} sortType sort type
   */
  function UpdateSort(sortType) {
    let sortMethods = SORT_METHODS[sortType];
    actualSort.current = sortType;
    sortData = data.current.sort(sortMethods);
    genLaunchPlanningItem(sortData);
  }

  /**
   * Request launch planing to the server
   */
  function loadLaunchPlaning() {
    setIsLoading(true);
    axios
      .get(`${API.ENDPOINT + API.RH_ALL_LAUNCH_PLANNING}`, {
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        data.current = res.data;
        UpdateSort(actualSort.current);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  /**
   * launche planning by id
   * @param {int} index id of the planning
   */
  function launchPlanning(index) {
    // request
    axios
      .get(`${API.ENDPOINT + API.RH_LAUNCH_PLANNING_BY_ID}`, {
        params: { id: index },
        headers: {
          "x-auth-token": props.activeUSer.user.token,
        },
      })
      .then((res) => {
        //console.log(res);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    LoadPage();
  }

  /**
   * Generate Launch Planning Item
   * @param {object[]} data array of all launch planning to launch
   */
  function genLaunchPlanningItem(data) {
    let showPlanningItem = [];
    for (let item of data) {
      // status text
      let isInProgress = !(item.flag_in_progress == 0 && item.flag_to_launch == 0);
      let statusText = isInProgress
        ? "In Progress"
        : item.iterations == 0
        ? "Not calculed"
        : "Calculed";
      let date = new Date(item.date);
      showPlanningItem.push(
        <tr class="launchPlanningItem">
          <th>
            {padTo2Digits(date.getDate()) +
              "." +
              padTo2Digits(date.getMonth() + 1) +
              "." +
              date.getFullYear()}
          </th>
          <th>{t(statusText)}</th>
          <th>
            <button
              id={item.index}
              className="actionBnt"
              disabled={isInProgress}
              onClick={actionButtonOnClick}
            >
              {t(isInProgress ? "In Progress" : item.iterations == 0 ? "calcule" : "re-calcule")}
            </button>
          </th>
        </tr>,
      );
    }
    setPlanningShowed(showPlanningItem);
  }

  /**
   * Padding to 2 digit a number
   * @param {int} num number
   * @returns string of the number padding
   */
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  //#endregion

  //#region Button function
  function actionButtonOnClick(e) {
    e.target.disabled = true;
    e.target.innerText = "In Progress";
    launchPlanning(e.target.id);
  }
  //#endregion

  useEffect(() => {
    LoadPage();
  }, []);

  // show the page or the loading page if loading
  return (
    <div className="launchPlanning">
      {isLoading ? (
        <ReactLoading className="" type={"bars"} color="#2fa2d9" width="4%" />
      ) : (
        <div className="planningTable">
          <select
            onChange={(event) => {
              UpdateSort(event.target.value);
            }}
          >
            <option value="default" disabled selected>
              {t("Choose sort methods")}
            </option>
            <option value="dateAsc">{t("dateAsc")}</option>
            <option value="dateDec">{t("dateDec")}</option>
            <option value="statutAsc">{t("statutAsc")}</option>
            <option value="statutDec">{t("statutDec")}</option>
          </select>
          <table className="table table-translation table-striped table-bordered table-scroll customeTable">
            <thead class="fix-header">
              <tr>
                <th>{t("date")}</th>
                <th>{t("status")}</th>
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>{planningShowed}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});

export default connect(mapStateToProps, null)(ScreenPlanningsLaunch);
