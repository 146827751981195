import React from "react";

export function Pagination({ rows, page, pageMax, setPage }) {
  return (
    <div
      className="row w-100"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "center",
      }}
    >
      <p className="d-flex">
        Résultats : {rows.length} , Pages : {pageMax + 1}
      </p>
      <div
        className="d-flex"
        style={{
          justifyContent: "flex-start",
          marginLeft: 10,
        }}
      >
        <button
          disabled={page === 0}
          type="button"
          className="btn btn-outline-primary"
          style={{ width: 50 }}
          onClick={() => setPage((page) => page - 1)}
        >
          &lt;&lt;
        </button>
        <button disabled type="button" className="btn btn-primary" style={{ marginLeft: "2%" }}>
          {page + 1}
        </button>
        <button
          disabled={page === pageMax}
          type="button"
          className="btn btn-outline-primary"
          style={{ marginLeft: "2%", width: 50 }}
          onClick={() => setPage((page) => page + 1)}
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  );
}
