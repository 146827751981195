import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import NotFound from "./components/not-found";
import ErrorBundler from "./components/errorBundler";
import Login from "./container/screenLogin";
import Logout from "./container/screenLogout";
import HomeDemo from "./container/homeScreenDemo";
import homeScreenEmergency from "./container/homeScreenEmergency";
import homeScreenHospitalisation from "./container/homeScreenHospitalisation";
import HomeScreenBeds from "./container/homeScreenBeds";
import HomeParameterBed from "./container/homeParameterBed";
import HomeScreenInfo from "./container/homeScreenInfo";
import HomePage from "./container/homePage";
import Header from "./container/header";
import HomeScreenRh from "./container/homeScreenRh";
import ScreenEhl from "./container/homeScreenEhl";
import HomeScreenJourney from "./container/homeScreenJourney";
import ChangePassword from "./container/screenChangePassword";
import Users from "./container/screenUsers/listUsers";
import Roles from "./container/screenUsers/listRoles";
import newUser from "./container/screenUsers/newUser";
import newGroup from "./container/homeGroupRoles/addGroup";
import editGroup from "./container/homeGroupRoles/editGroup";
import Groups from "./container/homeGroupRoles/listGroups";
import newRole from "./container/screenUsers/newRole";
import editUser from "./container/screenUsers/editUser";
import editProfil from "./container/screenUsers/editProfil";
import Translations from "./container/screenTranslations/listTranslations";
import newTranslation from "./container/screenTranslations/newKey";
import newScreen from "./container/screenTranslations/addScreen";
import allScreen from "./container/screenTranslations/listScreens";
import ScreenConsole from "./container/screens/screenConsole";
import BedManagerScreen from "./container/screens/bedManagerScreen";
import * as Permission from "./utils/permissions";
import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import { bindActionCreators } from "redux";
import { logoutUser } from "./actions/users";
import { useHistory } from "react-router-dom";
import { SuspenseLoader } from "./components/loader";

const Routes = (props) => {
  let history = useHistory();
  const ProtectedRoute = ({
    component: Component,
    user,
    change,
    roles,
    pass,
    location,
    ...rest
  }) => {
    if (props.activeUSer.isErrorSocket) {
      return (
        <>
          <Header roles={props.activeUSer.user.group && props.activeUSer.user.group.Roles} />
          <div className="main container-fluid">
            <NotFound message="ERROR 500" />
          </div>
        </>
      );
    }

    if (
      props.activeUSer.isAuthenticated &&
      !Permission.hasAtLeastOneRole(
        roles,
        props.activeUSer.user.group && props.activeUSer.user.group.Roles,
      )
    ) {
      if (!pass)
        return (
          <>
            <Header roles={props.activeUSer.user.group && props.activeUSer.user.group.Roles} />
            <div className="main container-fluid">
              <NotFound message="ERROR 403 , Access denied" />
            </div>
          </>
        );
    }

    let firstlogin;
    if (props.activeUSer.user) firstlogin = props.activeUSer.user.firstlogin;

    return (
      <Route
        {...rest}
        render={(props) =>
          user.isAuthenticated === true ? (
            firstlogin === 1 ? (
              <ChangePassword />
            ) : (
              <>
                {" "}
                <Header roles={user.user.group && user.user.group.Roles} />
                <div className="main container-fluid">
                  <Component roles={user.user.group && user.user.group.Roles} {...props} />
                </div>
              </>
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };
  const EhlRoute = ({ component: Component, user, change, roles, pass, location, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          user.isAuthenticated === true ? (
            <>
              <div className="main container-fluid">
                <Component roles={user.user.group && user.user.group.Roles} {...props} />
              </div>
            </>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div>
        <Header roles={props.activeUSer.user.group && props.activeUSer.user.group.Roles} />
        <ErrorBundler resetErrorBoundary={resetErrorBoundary} />
      </div>
    );
  }
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SuspenseLoader>
          <Switch>
            <ProtectedRoute
              pass={true}
              roles={[]}
              user={props.activeUSer}
              exact
              path="/"
              component={HomePage}
            />
            <ProtectedRoute
              location={props.location}
              roles={Permission.roles["emergency"]}
              user={props.activeUSer}
              exact
              path="/Emergency"
              component={homeScreenEmergency}
            />
            <ProtectedRoute
              location={props.location}
              roles={Permission.roles["demo"]}
              user={props.activeUSer}
              exact
              path="/Demo"
              component={HomeDemo}
            />
            <ProtectedRoute
              roles={Permission.roles["hospitalisation"]}
              user={props.activeUSer}
              exact
              path="/Hospitalisation"
              component={homeScreenHospitalisation}
            />
            <ProtectedRoute
              roles={Permission.roles["cockpit"]}
              user={props.activeUSer}
              exact
              path="/Cockpit"
              component={HomeScreenBeds}
            />
            <ProtectedRoute
              roles={Permission.roles["info"]}
              user={props.activeUSer}
              exact
              path="/Info"
              component={HomeScreenInfo}
            />
            <ProtectedRoute
              roles={Permission.roles["rh"]}
              user={props.activeUSer}
              exact
              path="/Rh"
              component={HomeScreenRh}
            />
            <ProtectedRoute
              roles={Permission.roles["journey"]}
              user={props.activeUSer}
              exact
              path="/Journey"
              component={HomeScreenJourney}
            />
            <EhlRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/school"
              component={ScreenEhl}
            />
            {/* <LivRoute    roles={Permission.roles['screenLiv']} user={props.activeUSer} exact path="/liv" component={ScreenLiv}/> */}
            <ProtectedRoute
              roles={Permission.roles["bedManager"]}
              user={props.activeUSer}
              exact
              path="/parameterBed"
              component={HomeParameterBed}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users"
              component={Users}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/new"
              component={newUser}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/:user/edit"
              component={editUser}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/roles"
              component={Roles}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/newRole"
              component={newRole}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/groups"
              component={Groups}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/newGroup"
              component={newGroup}
            />
            <ProtectedRoute
              roles={Permission.roles["administration"]}
              user={props.activeUSer}
              exact
              path="/users/group/:group/edit"
              component={editGroup}
            />
            <ProtectedRoute
              user={props.activeUSer}
              exact
              path="/users/edit"
              component={editProfil}
            />
            <ProtectedRoute
              roles={Permission.roles["screenTranslate"]}
              user={props.activeUSer}
              exact
              path="/translations"
              component={Translations}
            />
            <ProtectedRoute
              roles={Permission.roles["screenTranslate"]}
              user={props.activeUSer}
              exact
              path="/translations/new"
              component={newTranslation}
            />
            <ProtectedRoute
              roles={Permission.roles["screenTranslate"]}
              user={props.activeUSer}
              exact
              path="/translations/newScreen"
              component={newScreen}
            />
            <ProtectedRoute
              roles={Permission.roles["screenTranslate"]}
              user={props.activeUSer}
              exact
              path="/translations/allScreens"
              component={allScreen}
            />
            <ProtectedRoute
              roles={Permission.roles["screenTranslate"]}
              user={props.activeUSer}
              exact
              path="/log"
              component={ScreenConsole}
            />
            <ProtectedRoute
              roles={Permission.roles["bedManager"]}
              user={props.activeUSer}
              exact
              path="/BedManager"
              component={BedManagerScreen}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <ProtectedRoute
              pass={true}
              roles={[]}
              user={props.activeUSer}
              path="*"
              component={NotFound}
            />
          </Switch>
        </SuspenseLoader>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  activeUSer: state.activeUser,
});
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ logoutUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
